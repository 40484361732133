/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn", "no-return-assign": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BigLoader from 'common/BigLoader'

class DataContainer extends React.Component {
  static propTypes = {
    selector: PropTypes.func,
    render: PropTypes.func,
    reload: PropTypes.bool,
    load: PropTypes.func,
    loadingElement: PropTypes.element,
    initialVars: PropTypes.object,
    callback: PropTypes.func
  };

  static defaultProps = {
    reload: true,
    loadingElement: <BigLoader/>,
    initialVars: {}
  };

  constructor (props) {
    super(props)
    const { data } = props
    const loading = data === null || data === undefined

    this.state = {
      loading,
      fetching: false,
      loaded: false
    }
  }

  componentWillMount () {
    this.doFetch(this.props.initialVars)
  }

  refetch (fn) {
    const vars = fn(this.state.vars)
    this.doFetch(vars, true)
  }

  doFetch (vars, refetch) {
    if (this.state.fetching) return

    const { data, reload } = this.props
    if (refetch || (!data || reload)) {
      this.setState({ loading: !this.state.loaded, fetching: true, vars })
      this.props.load(vars)
        .then(
          res => {
            this.setState({ loading: false, loaded: true, fetching: false, error: res.error })
            this.props.callback && this.props.callback(res.error, vars)
          },
          e => {
            this.setState({ loading: false, loaded: true, fetching: false, error: e })
          }
        )
    }
  }

  render () {
    const { loading, error } = this.state
    const { loadingElement, data, state } = this.props

    if (loading)
      return loadingElement

    return this.props.render({
      error,
      props: { data, state }
    })
  }
}

DataContainer = connect(
  (state, props) => ({
    data: props.selector(state),
    state
  }),

  (dispatch, props) => ({
    load: bindActionCreators(props.load, dispatch)
  }),
  null,
  { withRef: true }
)(DataContainer)

class WrappedContainer extends React.Component {
  getRef = ref => this.ct = ref;

  refetch () {
    const inst = this.ct.getWrappedInstance()
    return inst.refetch.apply(inst, arguments)
  }

  render () {
    return <DataContainer ref={this.getRef} {...this.props}/>
  }
}

export default WrappedContainer
