import PropTypes from 'prop-types'
import React from 'react'

class RichDropdown extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    buttonName: PropTypes.string
  };

  state = {
    open: false
  };

  componentDidMount () {
    document.body.classList.add('force-show-scrollbars')
  }

  componentWillUnmount () {
    document.body.classList.remove('force-show-scrollbars')
  }

  render () {
    var items = this.props.items

    const itemList = items.map(function (item, idx) {
      return (
        <li data-item={item.id} key={idx} onClick={this.props.onSelect && this.props.onSelect.bind(this, item)}>
          <h3>{item.header}</h3>
          <div>{item.content}</div>
        </li>
      )
    }, this)

    return (
      <div className='btn-group rich-dropdown'>
        <button type='button' className='rich-dropdown-btn btn btn-default dropdown-toggle' data-toggle='dropdown'>
          {this.props.buttonHtml || ''}
        </button>
        <ul className='dropdown-menu' role='menu'>
          {itemList}
        </ul>
      </div>
    )
  }
}

export default RichDropdown
