import React, { useEffect, useMemo } from 'react'
import Neighborhood from 'propertydata/components/Neighborhood'
import { connect } from 'react-redux'
import { getNeighbors, getCustomNeighbors } from 'propertydata/selectors'
import { fetchNeighbors, fetchCustomNeighbors, setLandlord } from 'propertydata/actions'
import { useSearchScreenProps } from 'search/SearchContext'
import { Card, Box } from '@realsoftworks/decor'
import BigLoader from 'common/BigLoader'
import BigError from 'common/BigError'

const mapStateToProps = state => {
  const neighborsState = getNeighbors(state)
  return {
    neighbors: neighborsState && neighborsState.result,
    customNeighbors: getCustomNeighbors(state)
  }
}

const mapActions = {
  fetchNeighbors,
  fetchCustomNeighbors,
  setLandlord
}

const withConnect = connect(mapStateToProps, mapActions)

const WrappedNeighborhood = withConnect(({
  setLandlord,
  fetchNeighbors,
  ...props
}) => {
  const { lead, setLead } = useSearchScreenProps()
  const neighborsStatus = lead.neighborsStatus
  const neighborsError = lead.neighborsError

  /**
   * On UNMOUNT, set isLandlord to false to prevent messing with the calls
   * outside <WrappedNeighborhood> which aren't concerned with landlord filters
   */
  useEffect(() => () => setLandlord(false), [])

  // Property data in the shape expected by <Neighborhood />
  const address = lead && lead.propertyDetails && lead.propertyDetails.address
  const coords = address && address.coordinates && { lat: address.coordinates.latitude, lon: address.coordinates.longitude }
  const property = useMemo(() =>
    address && { result: { address } },
  [address])

  // Fetch neighbors if we haven't yet for the current lead/property
  useEffect(() => {
    if (
      ['LOADING', 'COMPLETED', 'ERROR'].includes(neighborsStatus) ||
      !address
    ) return

    setLead(l => ({ ...l, neighborsStatus: 'LOADING' }))
    fetchNeighbors(coords)
      .then(() =>
        setLead(l => ({ ...l, neighborsStatus: 'COMPLETED' })))
      .catch(neighborsError =>
        setLead(l => ({ ...l, neighborsStatus: 'ERROR', neighborsError })))
  }, [address, neighborsStatus, setLead, fetchNeighbors])

  return (
    <Box minHeight='100%' display='flex' flexWrap='wrap' p={2}>
      <Card minHeight='100%' width='100%' p={1} bg='white' boxShadow={1} borderRadius={3}>
        {(neighborsStatus === 'COMPLETED' && property) ? (
          <Neighborhood property={property} {...props} />
        ) : neighborsStatus === 'ERROR' ? (
          <Box
            display='flex'
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <BigError error={neighborsError} />
          </Box>
        ) : (
          <Box
            display='flex'
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <BigLoader />
          </Box>
        )}
      </Card>
    </Box>
  )
})

export default WrappedNeighborhood
