import { combineReducers } from 'redux'

import * as c from './constants'
import trialPromo from './trialPromo/reducer'

const info = (state = null, { payload, type }) => {
  switch (type) {
    case c.FETCH_INFO_SUCCESS:
      return payload.billing_info || null

    case c.UPDATE_SUCCESS:
      return payload.billing_info
  }

  return state
}

const subscription = (state = null, { payload, type }) => {
  switch (type) {
    case c.CANCEL_PLAN_SUCCESS:
    case c.CHANGE_PLAN_SUCCESS:
    case c.FETCH_INFO_SUCCESS:
      return payload.subscription
  }

  return state
}

const plans = (state = null, { payload, type }) => {
  switch (type) {
    case c.FETCH_PLANS_SUCCESS:
      return payload.items
  }
  return state
}

const customer = (state = null) => state

const retrial = (state = null) => state

const proAlt = (state = null) => state

const states = (state = {
  canceling: false,
  changing: false,
  isUpgrading: false
}, { type }) => {
  switch (type) {
    case c.CHANGE_PLAN:
      return { ...state, changing: true }
    case c.CHANGE_PLAN_FAILURE:
    case c.CHANGE_PLAN_SUCCESS:
      return { ...state, changing: false }

    case c.CANCEL_PLAN:
      return { ...state, canceling: true }
    case c.CANCEL_PLAN_FAILURE:
    case c.CANCEL_PLAN_SUCCESS:
      return { ...state, canceling: false }

    case c.END_TRIAL:
      return { ...state, isUpgrading: true }
    case c.END_TRIAL_SUCCESS:
    case c.END_TRIAL_FAILURE:
      return { ...state, isUpgrading: false }
  }

  return state
}

export default combineReducers({
  info,
  subscription,
  plans,
  retrial,
  customer,
  states,
  proAlt,
  trialPromo
})
