import React from 'react'
import CMACoverageMap from './CMACoverageMap'
import { Box, Text } from '@realsoftworks/decor'
import { INCOMING_COLOR, COVERED_COLOR } from './CMACoverageMap/CMACoverageMap'

const CMACoverage = () => (
  <Box px={7} pt={7}>
    <Box borderRadius={16}>
      <Text as='div' fontSize={4} mb={6} textAlign='center'>
          Looks like we do not have coverage in this area. Please see the map below for our current coverage.
        {' '}<Text fontSize={4} color={COVERED_COLOR} fontWeight={600}>Green</Text>
        {' '}are areas currently covered, and
        {' '}<Text fontSize={4} color={INCOMING_COLOR} fontWeight={600}>yellow</Text>
        {' '}are areas we are currently working on for expansion.
      </Text>
      <CMACoverageMap />
    </Box>
  </Box>
)

export default CMACoverage
