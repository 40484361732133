/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn", "react/jsx-key": "warn", "no-class-assign": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import PickerField from '../../../views/form/PickerField.react'
import ContactHeader from '../../contacts/components/ContactHeader.react'

import { connect } from 'react-redux'
import { selectors as leadSelectors } from '../index.js'
import ContactModal from '../../contacts/components/ContactModal.react'
import { updateContactRole } from '../actions'
import { startEdit, read } from '../../contacts/actions'

class LeadContacts extends React.Component {
  static propTypes = {
    leadId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    editable: PropTypes.bool
  };

  static defaultProps = {
    value: []
  };

  state = {
    open: false,
    pickerValue: [],
    modalIsOpen: false,
    contactId: null
  };

  render () {
    var body = null
    var editable = this.props.editable
    const contactId = this.state.contactId ? this.state.contactId : null
    const title = contactId ? 'Edit Contact' : 'Create Contact'
    const modal = (<div id='overlay-target'>
      <ContactModal contactId={contactId} isOpen={this.state.modalIsOpen} title={title} onRequestClose={this.onCloseModal}/>
    </div>)

    if (this.state.open)
      body = (
        <div className=''>
          <PickerField
            placeholder='Search Contacts'
            model='contact'
            action='search'
            displayKey='name'
            argName='param'
            value={this.state.pickerValue}
            onChange={this.onPickerChange}
            ref='picker'
          />
          <button type='button' className='btn btn-default btn-add-contact' onClick={this.onAddContact}>Create Contact</button>
        </div>
      )
    else
      body = (
        editable ? <button type='button' className='btn btn-default btn-add' onClick={this.open}>Add/Remove Contacts</button> : ''
      )

    let list = []
    if (this.props.value) {
      var values = this.props.value || []
      list = values.map(function (v, i) {
        if (!v) return null
        const meta = v.contact && v.contact.id ? this.props.meta[v.contact.id] : null
        return (
          <ContactCard key={v.contact.id} startEditContact={this.props.startEditContact} onOpenModal={this.onOpenModal.bind(this, i)}
            contact={v.contact} meta={meta} onRoleEdit={this.onRoleEdit.bind(this, i)}/>
        )
      }, this)
    }

    return (
      <div className='lead-contacts'>
        {list.length == 0 && !this.props.editable ? <div className='display field'>---</div> : list}
        {body}
        {modal}
      </div>
    )
  }

  onCloseModal = contact => {
    const pickerValue = this.state.pickerValue
    if (contact)
      pickerValue.push(contact)

    this.setState({
      modalIsOpen: false,
      pickerValue,
      contactId: null
    })
  };

  onOpenModal = (i, v) => {
    var c = this.props.value[i]
    const contactId = c.contact.id
    this.props.read(contactId)
    this.setState({
      contactId,
      modalIsOpen: true
    })
  };

  onAddContact = () => {
    this.setState({
      modalIsOpen: true,
      contactId: null
    })
  };

  onRoleEdit = (i, v) => {
    var c = this.props.value[i]
    this.props.updateContactRole(this.props.leadId, c.contact.id, v)
  };

  onPickerChange = v => {
    this.setState({ pickerValue: v })
  };

  onPickerBlur = () => {
    this.setState({ open: false })
  };

  open = () => {
    const pickerValue = this.props.value.map(c => ({
      id: c.contact.id,
      name: c.contact.values.name
    }))
    this.setState({ open: true, pickerValue })
  };

  onCreate = data => {
    var pv = this.state.pickerValue
    data = Object.assign({ id: data.id }, data.values)

    pv = [].concat(pv)
    pv.push(data)
    this.setState({ pickerValue: pv })
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState.open && !this.state.open) { // did close
      this.props.onChange([].concat(this.state.pickerValue))
    } else if (this.state.open && !prevState.open) { // did open
      this.refs.picker && this.refs.picker.focus()
      document.addEventListener('click', this.checkClick)
    }
  }

  checkClick = e => {
    if (!$(e.target).is('.btn-add-contact') && this.refs.picker && this.refs.picker.checkBlur(e) && !this.state.modalIsOpen && !$(e.target).closest('.token').length) {
      document.removeEventListener('click', this.checkClick)
      this.setState({ open: false })
    }
  };
}

class ContactCard extends React.Component {
  state = {
    editing: false,
    roleinput: ''
  };

  onEditClick = e => {
    if (e)
      e.preventDefault()

    const id = this.props.contact.id
    this.props.startEditContact(id)
    this.props.onOpenModal()
  };

  onRoleEdit = e => {
    e.preventDefault()
    this.setState({ editing: true, roleinput: this.props.meta.role || '' }, function () {
      this.refs.input && this.refs.input.focus()
    })
  };

  onBlur = e => {
    var v = e.target.value
    this.setState({ editing: false, roleinput: '' }, function () {
      this.props.onRoleEdit(v)
    })
  };

  onChange = e => {
    this.setState({ roleinput: e.target.value })
  };

  onClick = e => {
    if ($(e.target).is('.contact-role') || $(e.target).is('.add-role'))
      this.onRoleEdit(e)
  };

  render () {
    var c = this.props.contact
    var v = c.values

    return (
      <div className='contact-card'>
        {this.state.editing
          ? <div className='edit-role' key='role'>
            <input type='text' ref='input' className='form-control' onChange={this.onChange} onBlur={this.onBlur} value={this.state.roleinput} placeholder='How is this person related to the lead?'/>
          </div>
          : ''
        }

        <ContactHeader key='header' full={true} contact={this.props.contact} role={this.props.meta.role} onClick={this.onClick} blankRole={[' - ', <a href='#' className='add-role'>Add Role</a>]}/>
        {v.notes
          ? <div className='contact-notes'>
            {v.notes}
          </div> : ''
        }
        <div className='dropdown edit' key='dropdown'>
          <button title='Edit' className='btn btn-default dropdown-toggle' data-toggle='dropdown'><span className='icon'></span></button>
          <ul className='dropdown-menu' role='menu'>
            <li role='presentation'><a role='menuitem' tabIndex='-1' href='#' onClick={this.onEditClick}>Edit Contact</a></li>
            <li role='presentation'><a role='menuitem' tabIndex='-1' href='#' onClick={this.onRoleEdit}>Edit Role</a></li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { leadId, editable } = props
  const lead = leadSelectors.getLead(state, leadId)
  const meta = lead && lead.values ? lead.values.leadcontactmeta : {}
  const contacts = leadSelectors.getContactsByLead(state, leadId)

  return {
    meta,
    value: contacts,
    leadId,
    editable
  }
}
const mapDispatchToProps = {
  updateContactRole,
  startEditContact: startEdit,
  read
}
LeadContacts = connect(mapStateToProps, mapDispatchToProps)(LeadContacts)
export default LeadContacts
