import React, { useEffect, useState } from 'react'
// import CMACoverageMap from './CMACoverageMap'
import { Box, Text } from '@realsoftworks/decor'
import { INCOMING_COLOR, COVERED_COLOR, CMACoverageMapDumb } from './CMACoverageMap/CMACoverageMap'
import fetchCoverage from 'mls/reducer/coverage/fetchCoverage'
import extractErrorMessage from 'common/extractErrorMessage'

const CMACoveragePublic = () => {
  const [error, setError] = useState<Error | null>(null)
  const [fips, setFips] = useState<string[]>([])

  useEffect(() => {
    fetchCoverage()
      .then(resp => {
        const coverage = resp?.coverage

        if (Array.isArray(coverage)) {
          setFips(coverage)
        } else {
          const error = extractErrorMessage(resp)
          setError(new Error(error))
        }
      })
      .catch(setError)
  }, [])

  if (error)
    return (
      <Box px={7} pt={7}>
        <Box borderRadius={16}>
          <Text as='div' fontSize={4} mb={6} textAlign='center'>
            <Text color='red.500'>Failed to load CMA Coverage Map:</Text>
            <br />{error.message}
            <br />
            <Text as='div' fontSize={2}>Please try again later</Text>
          </Text>
        </Box>
      </Box>
    )

  return (
    <Box px={7} pt={7} maxWidth='1200px' mx='auto'>
      <Box borderRadius={16}>
        <Box as='img' src='/img/logo-email.png' mx='auto' display='block' />
        <Text as='div' fontSize={4} mt={9} mb={8} textAlign='center'>
          Please see the map below for our current CMA coverage.
          {' '}<Text fontSize={4} color={COVERED_COLOR} fontWeight={600}>Green</Text>
          {' '}are areas currently covered, and
          {' '}<Text fontSize={4} color={INCOMING_COLOR} fontWeight={600}>yellow</Text>
          {' '}are areas we are currently working on for expansion.
        </Text>
        <CMACoverageMapDumb coverageHasLoaded={fips.length > 0} coveredFips={fips} />
      </Box>
    </Box>
  )
}

export default CMACoveragePublic
