export const selectCoverage = (state: any): string[] | null =>
  (state.mls.coverage.hasLoaded && Array.isArray(state.mls.coverage.value))
    ? state.mls.coverage.value
    : null

export const selectCoverageHasLoaded = (state: any): boolean | null =>
  state.mls.coverage.hasLoaded

export const selectCoverageIsLoading = (state: any): string[] | null =>
  state.mls.coverage.isLoading

export const selectCoverageError = (state: any): string[] | null =>
  state.mls.coverage.error
