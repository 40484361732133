import React, { Component } from 'react'

import BigChart from './BigChart'

import DataContainer from './DataContainer'
import BigFatalError from 'common/BigFatalError'

import { fetchCrossVisitors } from '../actions'
import { getCrossVisitors } from '../selectors'

class CrossSiteVisitorChart extends Component {
  render () {
    return (
      <DataContainer
        load={fetchCrossVisitors}
        selector={getCrossVisitors}
        render={({ error, props }) => {
          if (error) return <BigFatalError />
          return <BigChart data={props.data} yKey='visitors' />
        }}
      />
    )
  }
}

CrossSiteVisitorChart.propTypes = {}
CrossSiteVisitorChart.defaultProps = {}

export default CrossSiteVisitorChart
