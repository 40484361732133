import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ROUTE_REQUIREMENTS } from '../../const'
import { selectIsReqMet } from 'common/selectors'

const mapState = state => ({
  isReqMet: reqs => selectIsReqMet(state, reqs)
})

const withConnnect = connect(mapState)

const HomeRedirect = withConnnect(({ isReqMet }) => {
  const { to } = PREFERRED_REDIR_ROUTES.find(r =>
    !r.reqs || isReqMet(r.reqs))
  return <Redirect to={to} />
}, [])

export default HomeRedirect

export const PREFERRED_REDIR_ROUTES = [
  ROUTE_REQUIREMENTS.SEARCH,
  ROUTE_REQUIREMENTS.SEARCH_HISTORY,
  ROUTE_REQUIREMENTS.LIST_BUILDER,
  ROUTE_REQUIREMENTS.SITES,
  ROUTE_REQUIREMENTS.DRIVING,
  ROUTE_REQUIREMENTS.REGIONAL_LEADS,
  ROUTE_REQUIREMENTS.DEALALERTS,
  ROUTE_REQUIREMENTS.BILLING,
  ROUTE_REQUIREMENTS.USER
]
