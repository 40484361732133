import { combineReducers } from 'redux'
import records from './records'
import counts from './counts'
import propertyTypes from './propertytypes'
import counties from './counties'
import filters from './filters'

export default combineReducers({
  records,
  counts,
  propertyTypes,
  counties,
  filters
})
