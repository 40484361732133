import React from 'react'
import { Box, Input, Card } from '@realsoftworks/decor'
import styled from 'styled-components'

const Adornment = ({ ...props }) => <Box px={3} display='flex' alignItems='center' {...props} />

const InputEl = styled(Input)`
  border: none;
  border-radius: initial;
`

const AdornedInput = ({
  before,
  after,
  width,
  height,
  onChange,
  placeholder,
  value,
  inputComponent,
  textAlign,
  inputProps,
  ...props
}) => (
  <Card borderRadius={4} bg='neutral.100' display='inline-flex' width={width} height={height} border='medium' overflow='hidden' {...props}>
    {before && <Adornment>{before}</Adornment>}

    <InputEl
      as={inputComponent}
      flex='1 1 auto'
      minWidth={0}
      width={1}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      textAlign={textAlign}
      {...inputProps}
    />

    {after && <Adornment>{after}</Adornment>}
  </Card>
)

AdornedInput.defaultProps = {
  width: '194px',
  inputComponent: 'input'
}

export default AdornedInput
