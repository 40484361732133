
import analytics from 'common/analytics'

export const TRACK_EVENT = 'middleware_track_event'

export default store => next => action => {
  const eventProps = action[TRACK_EVENT]
  if (typeof eventProps !== 'undefined') {
    const { name, properties, options } = eventProps
    analytics.track(name, properties, options)
  }
  return next(action)
}
