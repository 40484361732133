import React from 'react'
import { Heading, Card } from '@realsoftworks/decor'
import DataView from 'search/components/DataView'
import { Monetary } from 'common/format'
import { orNull } from 'common/util/renderHelpers'

const TaxAssessmentInfoSection = ({ property }) => {
  const taxDetail = (property && property.taxDetail) || {}

  return (
    <Card
      p={3}
      pb={4}
      as='section'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexWrap='wrap'
      bg='white'
      boxShadow={1}
      borderRadius={3}
    >
      <Heading
        as='h2'
        color='primary.500'
        textAlign='center'
        pb={1}
        style={{ fontSize: 16 }}
      >
        Tax Assessment Information
      </Heading>

      <DataView
        shouldRemoveEmptyData data={{
          'Tax Year': orNull(taxDetail.tax?.year),
          'Property Tax': orNull(taxDetail.tax?.billed, v => (
            <Monetary decimals={0}>{v}</Monetary>)),
          'Tax Jurisdiction Code': orNull(taxDetail.tax?.jurisdictionCode),
          'Assessment Year': orNull(taxDetail.assessed?.year),
          'Assessed Total Value': orNull(taxDetail.assessed?.value?.total, v => (
            <Monetary decimals={0}>{v}</Monetary>)),
          'Assessed Land Value': orNull(taxDetail.assessed?.value?.land, v => (
            <Monetary decimals={0}>{v}</Monetary>)),
          'Assessed Improvement Value': orNull(
            taxDetail.assessed?.value?.improvements,
            v => (<Monetary decimals={0}>{v}</Monetary>)),
          'Market Total Value': orNull(taxDetail.marketValue.total, v => (
            <Monetary decimals={0}>{v}</Monetary>)),
          'Market Land Value': orNull(taxDetail.marketValue?.land, v => (
            <Monetary decimals={0}>{v}</Monetary>)),
          'Market Improvement Value': orNull(
            taxDetail.marketValue?.improvements,
            v => (<Monetary decimals={0}>{v}</Monetary>))
        }}
      />
    </Card>
  )
}

export default TaxAssessmentInfoSection
