/* eslint "react/no-find-dom-node": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import Ripple from './Ripple'

var Dom = {

  isDescendant (parent, child) {
    let node = child.parentNode

    while (node !== null) {
      if (node === parent) return true
      node = node.parentNode
    }

    return false
  },

  offset (el) {
    const rect = el.getBoundingClientRect()
    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    }
  }

}

function calcDiag (a, b) {
  return Math.sqrt((a * a) + (b * b))
}

class TouchRipple extends React.Component {
  static propTypes = {
    centerRipple: PropTypes.bool
  };

  state = {
    ripples: [],
    nextKey: 0
  };

  start = event => {
    var ripples = this.state.ripples.concat([])
    ripples.push(
      <Ripple key={this.state.nextKey}
        style={ this.props.centerRipple ? {} : this._getRippleStyle(event)}/>
    )
    this.setState({
      ripples: ripples,
      nextKey: this.state.nextKey + 1
    })
  };

  end = () => {
    var ripples = this.state.ripples.concat([])
    ripples.shift()
    this.setState({ ripples })
  };

  _getRippleStyle = event => {
    const style = {}
    const el = ReactDOM.findDOMNode(this.tg)
    const elHeight = el.offsetHeight
    const elWidth = el.offsetWidth
    const offset = Dom.offset(el)
    const isTouchEvent = event.touches && event.touches.length
    const pageX = isTouchEvent ? event.touches[0].pageX : event.pageX
    const pageY = isTouchEvent ? event.touches[0].pageY : event.pageY
    const pointerX = pageX - offset.left
    const pointerY = pageY - offset.top
    const topLeftDiag = calcDiag(pointerX, pointerY)
    const topRightDiag = calcDiag(elWidth - pointerX, pointerY)
    const botRightDiag = calcDiag(elWidth - pointerX, elHeight - pointerY)
    const botLeftDiag = calcDiag(pointerX, elHeight - pointerY)
    const rippleRadius = Math.max(
      topLeftDiag, topRightDiag, botRightDiag, botLeftDiag
    )
    const rippleSize = rippleRadius * 2
    const left = pointerX - rippleRadius
    const top = pointerY - rippleRadius

    style.height = `${rippleSize}px`
    style.width = `${rippleSize}px`
    style.top = `${top}px`
    style.left = `${left}px`

    return style
  };

  _handleMouseDown = event => {
    // only listen to left clicks
    if (event.button === 0) this.start(event, false)
  };

  _handleMouseUp = () => {
    this.end()
  };

  _handleMouseLeave = () => {
    this.end()
  };

  _handleTouchStart = () => {
  };

  _handleTouchEnd = () => {
    this.end()
  };

  render () {
    return null
  }
}

export default TouchRipple
