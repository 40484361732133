import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes, faExternalLinkAlt, faUndo } from '@fortawesome/free-solid-svg-icons'

import PermissionComponent from 'users/components/PermissionComponent'

import ProTable from 'decor/ProTable'
import { Monetary } from 'common/format'

import columns from 'mls/util/columns'
import { Box, IconButton } from '@realsoftworks/decor'

export default class DealAlertListingTable extends Component {
  getRowClass = row => row.excluded ? 'excluded' : '';

  render () {
    const { listings, alert, sort, onSortChange, toggle, addLead, statuses, metadata } = this.props

    // take cma columns and remove the sold related ones
    var mlsColumns = columns(metadata.fields)
    mlsColumns = mlsColumns.filter(v => ['address', 'address.subdivision', 'close_price', 'close_date', 'stories', 'dollars_per_foot', 'pool'].indexOf(v.id) === -1)

    // add address as a link
    const listingColumns = [{
      id: 'tools',
      header: false,
      type: 'actions',
      render: (value, row) => {
        var { id } = row
        var detail = statuses[id]
        return <TableButtonBar row={row} detail={detail} addLead={addLead} toggle={toggle} />
      }

    }, {
      id: 'address',
      label: 'Address',
      render: (value, row) => <Link to={`/dealalerts/${alert.id}/${row.id}`}>{[row.address.line1, row.address.city].join(', ')}</Link>,
      sortable: false
    }]
      .concat(mlsColumns)
      .concat([
        {
          id: 'dollars_per_foot',
          header: '$/sqft',
          type: 'monetary',
          headerClassName: 'number-column',
          render: (value, row) => (<Monetary decimals={2}>{row.list_price / row.sqft}</Monetary>),
          sortable: false
        },
        {
          id: 'estimated_value',
          header: 'Estimated Value',
          type: 'monetary',
          headerClassName: 'number-column',
          render: (value, row) => (<Monetary>{row.valuation.estimated_value}</Monetary>)
        },
        {
          id: 'equity_dollars',
          header: 'Estimated Equity',
          headerClassName: 'number-column',
          primer: (value, row) => row.valuation.equity_dollars,
          render: (value, row) => (
            <Fragment>
              <Monetary>{row.valuation.equity_dollars}</Monetary>
              {' '}({(row.valuation.equity_percent * 100).toFixed(0)}%)
            </Fragment>
          )
        }
      ])

    return (
      <Box overflowX='auto'>
        <Box width='100%' minWidth='1200px'>
          <ProTable
            className='deal-alert-list table-striped'
            columns={listingColumns}
            data={listings}
            sort={sort}
            onSortChange={onSortChange}
            getRowClass={ this.getRowClass }
          />
        </Box>
      </Box>
    )
  }
}

const TableButtonBar = ({ addLead, toggle, row, detail }) => <Box display='flex' alignItems='center'>
  {
    row.lead_id
      ? <SizedIconBtn
        title='View Lead'
        as={Link}
        to={`/search/history/${row.lead_id}`}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </SizedIconBtn>
      : <PermissionComponent action='create' model='lead'>
        <SizedIconBtn
          variant='primary'
          disabled={detail && detail.favoriting}
          title='Add as Lead'
          onClick={() => addLead(row)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </SizedIconBtn>
      </PermissionComponent>
  }
  {
    row.excluded
      ? <SizedIconBtn
        disabled={detail && detail.excluding}
        title='Undo'
        onClick={() => toggle(row.id, 0)}
      >
        <FontAwesomeIcon icon={faUndo} />
      </SizedIconBtn>
      : <SizedIconBtn
        variant='danger'
        disabled={detail && detail.excluding}
        title='Hide Listing'
        onClick={() => toggle(row.id, 1)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </SizedIconBtn>
  }
</Box>

const SizedIconBtn = props => (
  <IconButton
    height='42px'
    width='42px'
    display='flex'
    alignItems='center'
    justifyContent='center'
    {...props}
  />
)
