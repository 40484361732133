import qs from 'qs'
import api from 'common/api'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF } from '../../const'
import { normalize } from 'normalizr'
import cleanObject from 'common/util/cleanObject'
import flow from 'lodash/function/flow'
import set from 'common/util/set'
import get from 'lodash/object/get'
import identity from 'lodash/utility/identity'

const callApi = async (dispatch, { url, body, method = 'get', types, meta, schema, payload = f => f }) => {
  try {
    if (types.start)
      dispatch({ type: types.start, meta })

    const result = await api[method.toLowerCase()](url, body)
    if (result.error && types.error)
      return dispatch({
        type: types.error,
        payload: result.error,
        meta,
        error: true
      })

    const transformed = schema ? normalize(result, schema) : payload(result)
    if (types.success)
      dispatch({ type: types.success, meta, payload: transformed })

    return transformed
  } catch (e) {
    toast.error(GENERIC_ERROR_NOTIF)
    if (types.error)
      return dispatch({
        type: types.error,
        payload: { type: 'request_error' },
        meta
      })
  }
}

const appendParams = params => {
  if (Object.keys(params).length)
    return `&${qs.stringify(params)}`

  return ''
}

const formatSellerParams = (params = {}, { isPaginationIncluded } = {}) => {
  const { limit, offset, newOnly, ...rest } = params
  const isHighEquityChecked = get(rest, 'equityType.highEquity.isChecked')
  const isLowEquityChecked = get(rest, 'equityType.lowEquity.isChecked')
  const isUpsideDownChecked = get(rest, 'equityType.upsideDown.isChecked')

  const formattedParams = cleanObject({
    ...(!isPaginationIncluded && {
      limit: limit || 50,
      offset: offset || 0
    }),
    newOnly,
    location: formatGeoFilters(rest),
    filters: flow(
      // Remove these "isChecked" keys which are only relevant in the client
      set('equityType.highEquity.isChecked', undefined),
      set('equityType.lowEquity.isChecked', undefined),
      set('equityType.upsideDown.isChecked', undefined),

      // Remove equity info whose parent checkboxes aren't checked
      isHighEquityChecked ? identity : set('equityType.highEquity', undefined),
      isLowEquityChecked ? identity : set('equityType.lowEquity', undefined),
      isUpsideDownChecked ? identity : set('equityType.upsideDown', undefined)
    )(rest)
  })

  // Endpoint throws without this
  if (!formattedParams.filters) formattedParams.filters = {}

  return formattedParams
}

const formatGeoFilters = params =>
  ({
    city: (params?.city?.length) ? params.city.map(c => c.id) : undefined,
    county: (params?.county?.length) ? params.county.map(c => c.id) : undefined,
    zip: (params?.zip?.length) ? params.zip.map(c => c.id) : undefined
  })

const endpoint = '/national-leads/v1'
const newEndpoint = '/parcels/v1'

export const FETCH = 'leadlists/fetch'
export const FETCH_SUCCESS = `${FETCH}_SUCCESS`
export const FETCH_FAILURE = `${FETCH}_FAILURE`

export const fetchSellers = params => async dispatch => {
  const { offset = 0, limit = 50 } = params
  return callApi(dispatch, {
    url: `${newEndpoint}/listbuilder/seller`,
    method: 'POST',
    meta: { params: { offset, limit, ...params }, ts: Date.now(), filterType: 'sellers' },
    body: formatSellerParams(params),
    types: {
      start: FETCH,
      success: FETCH_SUCCESS,
      error: FETCH_FAILURE
    }
  })
}

export const FETCH_COUNT_SELLERS = 'leadlists/fetch_count_sellers'
export const FETCH_COUNT_SELLERS_SUCCESS = `${FETCH_COUNT_SELLERS}_SUCCESS`
export const FETCH_COUNT_SELLERS_FAILURE = `${FETCH_COUNT_SELLERS}_FAILURE`

export const fetchSellerCounts = params => async dispatch => callApi(dispatch, {
  url: `${newEndpoint}/listbuilder/seller/count`,
  method: 'POST',
  meta: { params, ts: Date.now() },
  body: formatSellerParams(params, { isPaginationIncluded: true }),
  types: {
    start: FETCH_COUNT_SELLERS,
    success: FETCH_COUNT_SELLERS_SUCCESS,
    error: FETCH_COUNT_SELLERS_FAILURE
  }
})

export const fetchBuyers = ({ offset = 0, limit = 50, ...rest }) => async dispatch => callApi(dispatch, {
  url: `${endpoint}/cashbuyers/?offset=${offset}&limit=${limit}${appendParams(formatParams(rest))}`,
  method: 'GET',
  meta: { params: { offset, limit, ...rest }, ts: Date.now(), filterType: 'buyers' },
  types: {
    start: FETCH,
    success: FETCH_SUCCESS,
    error: FETCH_FAILURE
  }
})

export const FETCH_COUNT_BUYERS = 'leadlists/fetch_count_buyers'
export const FETCH_COUNT_BUYERS_SUCCESS = `${FETCH_COUNT_BUYERS}_SUCCESS`
export const FETCH_COUNT_BUYERS_FAILURE = `${FETCH_COUNT_BUYERS}_FAILURE`

export const fetchBuyerCounts = params => async dispatch => callApi(dispatch, {
  url: `${endpoint}/cashbuyers/count?${qs.stringify(formatParams(params))}`,
  method: 'GET',
  meta: { params, ts: Date.now() },
  types: {
    start: FETCH_COUNT_BUYERS,
    success: FETCH_COUNT_BUYERS_SUCCESS,
    error: FETCH_COUNT_BUYERS_FAILURE
  }
})

export const fetchLenders = ({ offset = 0, limit = 50, ...rest }) => async dispatch => callApi(dispatch, {
  url: `${endpoint}/privatelenders/?offset=${offset}&limit=${limit}${appendParams(formatParams(rest))}`,
  method: 'GET',
  meta: { params: { offset, limit, ...rest }, ts: Date.now(), filterType: 'lenders' },
  types: {
    start: FETCH,
    success: FETCH_SUCCESS,
    error: FETCH_FAILURE
  }
})

export const FETCH_COUNT_LENDERS = 'leadlists/fetch_count_lenders'
export const FETCH_COUNT_LENDERS_SUCCESS = `${FETCH_COUNT_LENDERS}_SUCCESS`
export const FETCH_COUNT_LENDERS_FAILURE = `${FETCH_COUNT_LENDERS}_FAILURE`

export const fetchLenderCounts = params => async dispatch => callApi(dispatch, {
  url: `${endpoint}/privatelenders/count?${qs.stringify(formatParams(params))}`,
  method: 'GET',
  meta: { params, ts: Date.now() },
  types: {
    start: FETCH_COUNT_LENDERS,
    success: FETCH_COUNT_LENDERS_SUCCESS,
    error: FETCH_COUNT_LENDERS_FAILURE
  }
})

export const FETCH_PROPERTY_TYPES = 'leadlists/fetch_property_types'
export const FETCH_PROPERTY_TYPES_SUCCESS = `${FETCH_PROPERTY_TYPES}_SUCCESS`
export const FETCH_PROPERTY_TYPES_FAILURE = `${FETCH_PROPERTY_TYPES}_FAILURE`

export const fetchPropertyTypes = () => async dispatch => callApi(dispatch, {
  url: `${newEndpoint}/parcels/types`,
  method: 'GET',
  meta: { params: {}, ts: Date.now() },
  types: {
    start: FETCH_PROPERTY_TYPES,
    success: FETCH_PROPERTY_TYPES_SUCCESS,
    error: FETCH_PROPERTY_TYPES_FAILURE
  }
})
export const CLEAR = 'leadlists/fetchclear'

export const clearPrevious = () => ({ type: CLEAR })

export const EXPORT = 'leadlists/export'
export const EXPORT_SUCCESS = `${EXPORT}_success`
export const EXPORT_FAILURE = `${EXPORT}_failure`

export const exportLeads = (type, name, params) => async dispatch => callApi(dispatch, {
  url: type === 'sellers'
    ? `${newEndpoint}/listbuilder/seller/export?${qs.stringify({ name })}`
    : `${endpoint}/${type}/export?${qs.stringify(formatParams(params))}`,

  method: 'POST',

  body: type === 'sellers'
    ? formatSellerParams(params, { isPaginationIncluded: false })
    : { name },

  meta: { params, type, name, ts: Date.now() },
  types: {
    start: EXPORT,
    success: EXPORT_SUCCESS,
    error: EXPORT_FAILURE
  }
})

export const SEARCH_COUNTIES = 'leadlists/searchCounties'
export const SEARCH_COUNTIES_SUCCESS = `${SEARCH_COUNTIES}_success`
export const SEARCH_COUNTIES_FAILURE = `${SEARCH_COUNTIES}_failure`

export const searchCounties = (name, state) => async dispatch => callApi(dispatch, {
  url: `${endpoint}/counties?${qs.stringify({ name, state })}`,
  method: 'GET',
  meta: { name, ts: Date.now() },
  types: {
    start: SEARCH_COUNTIES,
    success: SEARCH_COUNTIES_SUCCESS,
    error: SEARCH_COUNTIES_FAILURE
  }
})

export const SEARCH_CITIES = 'leadlists/searchCities'
export const SEARCH_CITIES_SUCCESS = `${SEARCH_CITIES}_success`
export const SEARCH_CITIES_FAILURE = `${SEARCH_CITIES}_failure`

export const searchCities = (name, state, type) => async dispatch => callApi(dispatch, {
  url: type === 'sellers'
    ? `${newEndpoint}/cities?${qs.stringify({ name, state })}`
    : `${endpoint}/cities?${qs.stringify({ name, state })}`,
  method: 'GET',
  meta: { name, ts: Date.now() },
  types: {
    start: SEARCH_CITIES,
    success: SEARCH_CITIES_SUCCESS,
    error: SEARCH_CITIES_FAILURE
  }
})

const formatParams = params =>
  cleanObject({ ...params, ...formatGeoFilters(params) })
