/* eslint "eqeqeq": "warn" */

import qs from 'qs'
import { Schema, arrayOf, normalize } from 'normalizr'

import { actions as mlsActions } from '../mls'

import { CALL_API_LEGACY } from '../../middleware/api'
import apifetch from 'common/fetch'

const ENDPOINT = '/dealalerts'

const searchSchema = new Schema('dealalerts', {})
const arrayOfSearchSchema = arrayOf(searchSchema)

const listingSchema = new Schema('dealalertlistings', {})
const arrayOfListingSchema = arrayOf(listingSchema)

export const DEALALERT_LIST_FETCH = 'DEALALERT_LIST_FETCH'
export const DEALALERT_LIST_FETCH_SUCCESS = 'DEALALERT_LIST_FETCH_SUCCESS'
export const DEALALERT_LIST_FETCH_FAILURE = 'DEALALERT_LIST_FETCH_FAILURE'

export function fetchAll () {
  return {
    [CALL_API_LEGACY]: {
      endpoint: ENDPOINT,
      types: [DEALALERT_LIST_FETCH, DEALALERT_LIST_FETCH_SUCCESS, DEALALERT_LIST_FETCH_FAILURE],
      schema: arrayOfSearchSchema
    }
  }
}

export const DEALALERT_FETCH = 'DEALALERT_FETCH'
export const DEALALERT_FETCH_SUCCESS = 'DEALALERT_FETCH_SUCCESS'
export const DEALALERT_FETCH_FAILURE = 'DEALALERT_FETCH_FAILURE'

export function fetch (id) {
  return (dispatch, getState) => {
    if (getState().entities.dealalerts[id])
      return

    dispatch({

      meta: { id },

      [CALL_API_LEGACY]: {

        endpoint: `${ENDPOINT}/${id}`,
        types: [DEALALERT_FETCH, DEALALERT_FETCH_SUCCESS, DEALALERT_FETCH_FAILURE],
        schema: searchSchema
      }
    })
  }
}

export const DEALALERT_FETCH_COUNT = 'DEALALERT_FETCH_COUNT'
export const DEALALERT_FETCH_COUNT_SUCCESS = 'DEALALERT_FETCH_COUNT_SUCCESS'
export const DEALALERT_FETCH_COUNT_FAILURE = 'DEALALERT_FETCH_COUNT_FAILURE'

export function fetchCount (data) {
  return {

    [CALL_API_LEGACY]: {
      endpoint: `${ENDPOINT}/count`,
      method: 'POST',
      single: true,
      debounce: 500,
      data,
      types: [DEALALERT_FETCH_COUNT, DEALALERT_FETCH_COUNT_SUCCESS, DEALALERT_FETCH_COUNT_FAILURE]
    }
  }
}

export const DEALALERT_CREATE = 'DEALALERT_CREATE'
export const DEALALERT_CREATE_SUCCESS = 'DEALALERT_CREATE_SUCCESS'
export const DEALALERT_CREATE_FAILURE = 'DEALALERT_CREATE_FAILURE'

export function create (data) {
  return {
    [CALL_API_LEGACY]: {
      endpoint: ENDPOINT,
      method: 'POST',
      data,
      types: [DEALALERT_CREATE, DEALALERT_CREATE_SUCCESS, DEALALERT_CREATE_FAILURE],
      schema: searchSchema
    }
  }
}

export const DEALALERT_UPDATE = 'DEALALERT_UPDATE'
export const DEALALERT_UPDATE_SUCCESS = 'DEALALERT_UPDATE_SUCCESS'
export const DEALALERT_UPDATE_FAILURE = 'DEALALERT_UPDATE_FAILURE'

export function update (id, data) {
  return {

    meta: { id },

    [CALL_API_LEGACY]: {
      endpoint: `${ENDPOINT}/${id}`,
      method: 'POST',
      data,
      types: [DEALALERT_UPDATE, DEALALERT_UPDATE_SUCCESS, DEALALERT_UPDATE_FAILURE],
      schema: searchSchema
    }
  }
}

export const DEALALERT_DESTROY = 'DEALALERT_DESTROY'
export const DEALALERT_DESTROY_SUCCESS = 'DEALALERT_DESTROY_SUCCESS'
export const DEALALERT_DESTROY_FAILURE = 'DEALALERT_DESTROY_FAILURE'

export function destroy (id) {
  return {

    meta: { id },

    [CALL_API_LEGACY]: {
      endpoint: `${ENDPOINT}/${id}`,
      method: 'DELETE',
      types: [DEALALERT_DESTROY, DEALALERT_DESTROY_SUCCESS, DEALALERT_DESTROY_FAILURE]
    }
  }
}

export const DEALALERT_FETCH_LISTINGS = 'DEALALERT_FETCH_LISTINGS'
export const DEALALERT_FETCH_LISTINGS_INTERRUPTED = 'DEALALERT_FETCH_LISTINGS_INTERRUPTED'
export const DEALALERT_FETCH_LISTINGS_SUCCESS = 'DEALALERT_FETCH_LISTINGS_SUCCESS'
export const DEALALERT_FETCH_LISTINGS_FAILURE = 'DEALALERT_FETCH_LISTINGS_FAILURE'

export function fetchListings (id, params = {}) {
  return async (dispatch, getState) => {
    var { list } = getState().dealalerts.listings
    var defaults = list.id == id ? list.params : { offset: 0, limit: 50 }

    params = Object.assign({}, defaults, params)

    dispatch({ type: DEALALERT_FETCH_LISTINGS, meta: { params, id } })
    try {
      var urlParams = qs.stringify(params)

      var result = await apifetch(`${ENDPOINT}/listings/${id}?${urlParams}`)
      var json = await result.json()

      if (result.status == 403) {
        dispatch({ type: DEALALERT_FETCH_LISTINGS_INTERRUPTED, meta: { params, id } })
        return dispatch(mlsActions.setSourceRequirements(json))
      }

      var payload = normalize(json, { items: arrayOfListingSchema })
      dispatch({ type: DEALALERT_FETCH_LISTINGS_SUCCESS, payload, meta: { params, id } })
    } catch (e) {
      dispatch({ type: DEALALERT_FETCH_LISTINGS_FAILURE, payload: e, meta: { params, id } })
    }
  }
}

export const DEALALERT_FETCH_LISTING_DETAIL = 'DEALALERT_FETCH_LISTING_DETAIL'
export const DEALALERT_FETCH_LISTING_DETAIL_SUCCESS = 'DEALALERT_FETCH_LISTING_DETAIL_SUCCESS'
export const DEALALERT_FETCH_LISTING_DETAIL_FAILURE = 'DEALALERT_FETCH_LISTING_DETAIL_FAILURE'

export function fetchListing (id, listingId) {
  return {
    meta: { id, listingId },
    [CALL_API_LEGACY]: {
      endpoint: `${ENDPOINT}/listing/${id}/${listingId}`,
      types: [DEALALERT_FETCH_LISTING_DETAIL, DEALALERT_FETCH_LISTING_DETAIL_SUCCESS, DEALALERT_FETCH_LISTING_DETAIL_FAILURE],
      schema: listingSchema
    }
  }
}

export const DEALALERT_TOGGLE_EXCLUDE = 'DEALALERT_TOGGLE_EXCLUDE'
export const DEALALERT_TOGGLE_EXCLUDE_SUCCESS = 'DEALALERT_TOGGLE_EXCLUDE_SUCCESS'
export const DEALALERT_TOGGLE_EXCLUDE_FAILURE = 'DEALALERT_TOGGLE_EXCLUDE_FAILURE'

export function toggleExcluded (id, listingId, excluded) {
  var method = (excluded ? 'exclude' : 'include')
  var endpoint = `${ENDPOINT}/${method}listing/${id}/${listingId}/`
  return {
    meta: { id, listingId },
    [CALL_API_LEGACY]: {
      endpoint,
      types: [DEALALERT_TOGGLE_EXCLUDE, DEALALERT_TOGGLE_EXCLUDE_SUCCESS, DEALALERT_TOGGLE_EXCLUDE_FAILURE],
      schema: listingSchema
    }
  }
}

export const DEALALERT_CREATE_LEAD = 'DEALALERT_CREATE_LEAD'
export const DEALALERT_CREATE_LEAD_SUCCESS = 'DEALALERT_CREATE_LEAD_SUCCESS'
export const DEALALERT_CREATE_LEAD_FAILURE = 'DEALALERT_CREATE_LEAD_FAILURE'

export function createLead (id, listing) { // TODO use listing_id instead
  var data = {
    id,
    listing_id: listing.id
  }

  return {

    meta: { listingId: listing.id, id },

    [CALL_API_LEGACY]: {
      endpoint: `${ENDPOINT}/lead`,
      method: 'POST',
      data,
      types: [DEALALERT_CREATE_LEAD, DEALALERT_CREATE_LEAD_SUCCESS, DEALALERT_CREATE_LEAD_FAILURE],
      schema: listingSchema
    }
  }
}

export const DEALALERT_MAP_UPDATED = 'DEALALERT_MAP_UPDATED'

export function cacheMapProps (props) {
  return {
    type: DEALALERT_MAP_UPDATED, payload: props
  }
}
