/* eslint "react/display-name": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import BigLoader from 'common/BigLoader'
import ProTable from 'decor/ProTable'
import PagingToolbar from '../../../views/common/paging-toolbar'
import { Monetary } from 'common/format'

import DateHelper from '../../../core/date'

import types from '../types'

import Card from 'common/Card'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'

const listingColumns = [{
  id: 'date',
  header: 'Date',
  render: value => DateHelper.format(value.split('.')[0])
}, {
  id: 'county',
  label: 'County',
  render: (value, row) => row.county
}, {
  id: 'type',
  header: 'Type',
  render: (value, row) => types[row.type]
}, {
  id: 'address',
  header: 'Address',
  render: (value, row) => (
    <AddressPreviewLink
      fontSize={2}
      address={{
        line1: row && row.property && row.property.line1,
        city: row && row.property && row.property.city,
        state: row && row.property && row.property.state,
        zip: row && row.property && row.property.zip
      }}
    />
  )
}, {
  id: 'zip',
  header: 'Zip',
  render: (value, row) => row.property.zip
}, {
  id: 'appraised_value',
  header: 'Tax Appraisal',
  headerClassName: 'number-col',
  className: 'number-col',
  render: (value, row) => value ? <Monetary>{value}</Monetary> : '---'
}]

class LeadList extends React.Component {
  static propTypes = {
    selectedLeads: PropTypes.array.isRequired,
    leads: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    onToggleRow: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired
  };

  state = {
    isLoading: true
  };

  render () {
    const { params, leads } = this.props

    return (
      <div className='lead-list-component'>
        <BigLoader loading={this.props.isLoading}>
          {leads && leads.length > 0
            ? <Card>
              <ProTable
                sortable={false}
                className='lead-list table-striped'
                columns={listingColumns}
                data={leads}
              />
              <PagingToolbar count={this.props.count} params={params} onPageClick={this.props.onPageClick}/>
            </Card>
            : <EmptyMessage/> }
        </BigLoader>
      </div>
    )
  }
}

const EmptyMessage = props =>
  <Card className='no-results'>
    <h1>Nothing matched your search</h1>
    <p>Try changing your criteria above..</p>
  </Card>

export default LeadList
