import { SEARCH_LEADS_SUCCESS, LEADS_UPDATE_SUCCESS, READ_SUCCESS, UPDATE_GEO_SUCCESS, UPDATE_CONTACT_ROLE_SUCCESS, CREATE_SUCCESS, READ, READ_FAILURE } from '../actions'
import mapValues from 'lodash/object/mapValues'
import get from 'lodash/object/get'
import set from 'lodash/object/set'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_LEADS_SUCCESS:
    case LEADS_UPDATE_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_GEO_SUCCESS:
    case READ_SUCCESS: {
      if (action.payload && action.payload.entities && action.payload.entities.lead) {
        const updatedLeads = mapValues(
          action.payload.entities.lead,
          lead => {
            const existingLead = state[lead.id]

            /**
             * When doing LEADS_UPDATE_SUCCESS, the returned leads would lose it's
             * `property` property and others, which is needed by search screens.
             *
             * To work around this, let's copy the properties from pre-update leads
             * to the post-update leads.
             */
            const newProperty = lead.property || {}
            const existingProperty = existingLead && existingLead.property
            const property = { ...existingProperty, ...newProperty }

            return {
              ...lead,

              property,

              /**
               * If it has fetched all details in the existing lead, even
               * if the last fetch has no details, it doesn't matter as we
               * keep those details.
               */
              hasFetchedAllDetails: (existingLead &&
                existingLead.hasFetchedAllDetails) ||
                get(
                  lead,
                  'hasFetchedAllDetails'
                ),

              hasPropertyDataInDb: get(
                lead,
                'hasPropertyDataInDb',
                get(state, `${lead.id}.hasPropertyDataInDb`)
              ),

              /**
               * Lets components know whether a read has been performed or not.
               * Useful for preventing unnecessary / subsequent reads.
               */
              readStatus: action.type === READ_SUCCESS
                ? 'success'
                // Retrieve old value
                : get(state, `${lead.id}.readStatus`, 'unstarted'),

              /**
               * Depending on request, we sometime lose `values` subproperties.
               * To work around that, spread it instead of replacing entirely
               */
              values: {
                ...(get(state, `${lead.id}.values`) || {}),
                ...(lead && lead.values)
              }
            }
          }
        )

        return {
          ...state,
          ...updatedLeads
        }
      }
      return state
    }

    case READ: {
      const leadId = get(action, 'payload.id')
      const updatedLeads = set(state, `${leadId}.readStatus`, 'loading')
      return updatedLeads
    }

    case READ_FAILURE: {
      const leadId = get(action, 'meta.params.id')
      const updatedLeads = set(state, `${leadId}.readStatus`, 'failed')
      return updatedLeads
    }

    case UPDATE_CONTACT_ROLE_SUCCESS:
      if (action.params) {
        const { leadId, role, contactId } = action.params
        if (state[leadId].values.leadcontactmeta[contactId]) {
          const lead = { ...state[leadId] }
          const newState = {
            ...state,
            [leadId]: {
              ...lead,
              values: {
                ...lead.values,
                leadcontactmeta: {
                  ...lead.values.leadcontactmeta,
                  [contactId]: {
                    ...lead.values.leadcontactmeta[contactId],
                    role
                  }
                }
              }
            }
          }
          return newState
        } else { return state }
      }
      return state
  }
  return state
}
