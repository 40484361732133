/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'

import BigLoader from 'common/BigLoader'
import BigFatalError from 'common/BigFatalError'
import SupportLink from 'common/SupportLink'
import { getSubscription, getBillingInfo, getPlans, getCustomer, getSubscriptionStates, getProAlt } from '../selectors'
import { changePlan, cancelPlan, fetchPlans } from '../actions'
import { Heading, Button, Box, Paragraph, Link } from '@realsoftworks/decor'
import InvoiceInfo from './InvoiceInfo'
import { Monetary, DateTime } from 'common/format'
import { fetchTrialPromoInfo } from 'billing/trialPromo/actions'

class SettingsPlanInfo extends React.Component {
  state = { loadingPlans: true };

  componentWillMount () {
    this.props.fetchPlans().then(() => this.setState({ loadingPlans: false }))
  }

  reactivate = () => {
    this.upgrade(this.props.subscription.plan.id)
  }

  upgrade = plan => {
    this.setState({ error: false })
    this.props.changePlan(plan)
      .then(({ error }) => {
        if (error) return this.setState({ error })
        return this.props.fetchTrialPromoInfo()
      })
  };

  render () {
    const { subscription, billinginfo, plans, customer, canceling, changing, proAlt } = this.props
    const { error, loadingPlans } = this.state
    const saving = canceling || changing

    const hasInfo = !!billinginfo
    const canChange = hasInfo || subscription.status == 'trialing'

    return (
      <div className='plan-info'>
        <BigLoader loading={loadingPlans}>
          <React.Fragment>
            <Heading size={4} mb={2}>Choose Plan</Heading>
            {plans
              ? <React.Fragment>
                <PlanGrid onClick={this.upgrade} plans={plans} disabled={!canChange || saving} subscription={subscription}/>
                {error && <div className='alert alert-danger'>{error.message}</div>}
                {!canChange && <div className='alert alert-warning'>Enter your billing info to change plans</div>}
              </React.Fragment>
              : <BigFatalError/>
            }
            {proAlt && <ProAltSwitcher changePlan={this.upgrade} altPlanId={proAlt} loading={changing} subscription={subscription}/>}
            <Cancellation customer={customer} subscription={subscription} cancel={this.props.cancelPlan} loading={canceling} reactivate={this.reactivate}/>
            {subscription.next_invoice_amount &&
              <InvoiceInfo mt={4}/>
            }
          </React.Fragment>
        </BigLoader>
      </div>

    )
  }
}

class ProAltSwitcher extends React.Component {
  onLinkClick = () => {
    if (this.props.loading) return
    this.props.changePlan(this.props.altPlanId)
  }

  render () {
    const { altPlanId, subscription } = this.props
    let planId
    if (subscription && subscription.plan)
      planId = subscription.plan.id

    if (planId === altPlanId) return null

    return (
      <Box>
        <Paragraph>Looking for the old Texas probate leads? <Link onClick={this.onLinkClick}>Click here to switch to Pro Regional</Link> (same price as Pro)</Paragraph>
      </Box>
    )
  }
}

class PlanGrid extends React.Component {
  render () {
    const { disabled, plans, subscription } = this.props
    const { plan } = subscription
    const isSpecialSelectedPlan = !plans.map(v => v.id).includes(plan.id)

    return (
      <div className='plan-grid'>

        {plans.sort((a, b) => Number(a.amount) - Number(b.amount)).map(v => (
          <PlanBlock key={v.id} onClick={this.props.onClick} disabled={disabled} plan={v} selected={v.id === plan.id} subscription={subscription}/>
        ))}

        {/**
         * These plans (e.g. Pro Regional) are only displayed here if it is
         * already selected so onClick (for selecting the plan) is unnecessary
         */}
        {isSpecialSelectedPlan && plan.id !== 'free' && (
          <PlanBlock
            disabled={disabled}
            legacy={true}
            plan={plan}
            selected={true}
            subscription={subscription}
            onClick={() => {}}
          />
        )}
      </div>
    )
  }
}

const PlanBlock = ({ disabled, plan, legacy, selected, subscription, onClick }) => (
  <div className={'plan-grid-plan ' + (plan.id.indexOf('pro') == 0 ? 'primary' : 'secondary') + ' ' + (selected ? 'selected' : '') + ' ' + (legacy ? 'legacy' : '') }>
    <h4>{(plan.name || '').replace(' (Monthly)', '')} <span>${Number(plan.amount)}/mo</span> {legacy && <span className='legacy-text'>Locked in!</span>}</h4>
    <ul>
      {plan.metadata.features.includes('driving') && <li>Driving for Dollars</li>}
      {plan.metadata.features.includes('sites') && <li>Unlimited Webites</li>}
      {plan.metadata.features.includes('cma') && <li>MLS Comps</li>}
      {/* {plan.metadata.features.includes('dealalerts') && <li>MLS Deal Alerts</li>} */}
      {plan.metadata.features.includes('leads') && <li>National Lists</li>}
      {plan.metadata.features.includes('premiumleads') && <li>Regional Leads Lists</li>}
      {plan.metadata.credit_price && <li>Credits: <Monetary decimals={2}>{plan.metadata.credit_price / 100}</Monetary></li>}
    </ul>
    <Button variant='secondary' disabled={disabled} onClick={() => onClick(plan.id)}>{selected ? 'Your Plan' : 'Choose'}</Button>
  </div>
)

class Cancellation extends React.Component {
  componentDidMount () {
    const { externalId } = this.props.customer

    if (!window.barecancel) {
      window.barecancel = { created: !0 }
      var a = document.createElement('script')
      a.src = 'https://baremetrics-barecancel.baremetrics.com/js/application.js'
      a.async = !0
      var b = document.getElementsByTagName('script')[0]
      b.parentNode.insertBefore(a, b)

      window.barecancel.params = {
        access_token_id: '16b452d4-cc5d-4c52-9393-00f950dd1959',
        customer_oid: externalId,
        callback_send: this.props.cancel
      }
    }
  }

  render () {
    const { loading, subscription } = this.props
    const canceled = subscription.cancel_at_period_end

    if (subscription.plan.id == 'free')
      return null

    let text

    if (canceled)
      text = (
        <div>
          <p>Your account has been cancelled but you still have access until <DateTime format='MMMM D, YYYY'>{subscription.active_until}</DateTime></p>
          <p>After that, you can still use the CRM to manage your leads but you won't have any premium features.</p>
        </div>
      )
    else
      text = <p>Not happy with Propelio? Please <SupportLink>let us know</SupportLink> first! We want to help.</p>

    return (
      <div className='cancellation'>
        <div className='cancellation-text'>
          <Heading size={5}>Cancellation</Heading>
          {text}
        </div>
        { canceled && <Button variant='primary' onClick={this.props.reactivate} disabled={loading}>Reactivate</Button> }
        <a id='barecancel-trigger' className='cancellation-link' disabled={loading} style={{ display: canceled ? 'none' : 'inline' }}>Cancel Plan</a>
      </div>
    )
  }
}

SettingsPlanInfo = connect(
  state => ({
    subscription: getSubscription(state),
    billinginfo: getBillingInfo(state),
    plans: getPlans(state),
    customer: getCustomer(state),
    proAlt: getProAlt(state),
    ...getSubscriptionStates(state)
  }),
  {
    changePlan,
    cancelPlan,
    fetchPlans,
    fetchTrialPromoInfo
  }
)(SettingsPlanInfo)

export default SettingsPlanInfo
