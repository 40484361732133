import get from 'lodash/object/get'

export const selectAvailable = state => state.marketing.credits.available
export const selectPrice = state => state.marketing.credits.price
export const selectRefillSettings = state => state.marketing.refill
export const selectServices = state => state.marketing.services
export const selectMinimum = state => state.marketing.credits.minimum
export const selectOrder = (state, id) => state.marketing.orders[id]
export const selectOrdersByRef = (state, referenceId) => {
  const ids = state.marketing.ordersByRef[referenceId]
  if (!ids)
    return ids

  return ids.map(id => selectOrder(state, id))
}
export const selectServicePrice = (state, service) => get(selectServices(state), service)
