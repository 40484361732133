import createFetchReducer from 'common/createFetchReducer'
import {
  FETCH_SETTINGS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
  UPDATE_SETTINGS_SUCCESS
} from '../actions'

const settings =
  createFetchReducer(
    FETCH_SETTINGS,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILURE,
    {},
    (state, action, fetchReducer) => {
      const { type, payload } = action
      switch (type) {
        // Override fetchReducer so that it doesn't clear existing value on fetch
        case FETCH_SETTINGS:
          return { ...state, isLoading: true, error: null }
        case UPDATE_SETTINGS_SUCCESS:
          return { ...state, value: payload.value }
        default:
          return fetchReducer(state, action)
      }
    }
  )

export default settings
