import moment from 'moment-timezone'
import React, { FC } from 'react'
import { Box } from '@realsoftworks/decor'

type Organization = {
  id: string
  name: string
  /** Url to logo */
  logo: string
  /** @NOTE This is an html of the disclosure that must be rendered */
  disclosure: string
}

type Props = {
  organizations: Organization[]
  startDate: string,
  asOfDate: string,
}

const organizationsList = (organizations: Organization[], conjunction = 'and', additional: string[] = []): string => {
  const names = [...additional, ...organizations.map(o => o.name)]

  if (names.length === 1) {
    return organizations[0].name
  }

  const lastEl = names.length - 1

  names[lastEl] = `${conjunction} ${names[lastEl]}`

  return names.join(', ')
}

const MLSNextDisclosures: FC<Props> = ({ organizations, startDate: startDateString, asOfDate: asOfDateString }) => {
  /**
   * Try to determine the user's timezone, fallback to Central time on old browsers
   */
  const timezone = (
    Intl?.DateTimeFormat &&
    Intl.DateTimeFormat().resolvedOptions &&
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ) || 'America/Chicago'

  const startDate = moment(startDateString).tz(timezone)
  const asOfDate = moment(asOfDateString).tz(timezone)
  const dateFormat = 'M/D/YYYY'
  const timeFormat = 'M/D/YYYY h:mm:ss A zz';

  if (organizations && organizations.length) {
    return (
      <Box mx={3} my={5}>
        <Box>
          Based on MLS listing data from the {organizationsList(organizations)} for the period {startDate.format(dateFormat)} through {asOfDate.format(dateFormat)} as of {asOfDate.tz(timezone).format(timeFormat)}.
        </Box>
        <Box mt={3}>
          This data is only for consumers/clients of Propelio Realty, LLC. If you have an exclusive representation agreement with another broker, please let us know so we may discontinue this service.
        </Box>
        <Box mt={3}>
          We do not independently verify the currency, completeness, accuracy, or authenticity of the data contained herein. The data may be subject to transcription and transmission errors. Accordingly, the data is provided on an ‘as is, as available’ basis only, including all faults, with all properties subject to prior sale or withdrawal and may not reflect all real estate activity in the market. Neither {organizationsList(organizations, 'nor', ['Propelio Realty', 'the Board of REALTORS®'])} guarantees or is in any way responsible for its accuracy.
        </Box>
        <Box mt={3}>
          Data provided is exclusively for personal, non-commercial use and may not be redistributed or reproduced.
        </Box>
        <Box mt={3}>
          The listing broker’s offer of compensation is made only to participants of the MLS where the listing is filed.
        </Box>
        {organizations.map(org => (
          <Box mt={3} key={org.id}>
            The compilation of MLS listings and each individual MLS listing marked with
            <Box as='img' src={org.logo} mx={2} height={32} />
            are © {asOfDate.format('YYYY')} by {org.name}. All Rights Reserved.
          </Box>
        ))}
      </Box>
    )
  }

  return <Box/>
}

export default MLSNextDisclosures
