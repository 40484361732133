import React from 'react'
import { Box, Select, Option, NumberInput, Label, DateInput } from '@realsoftworks/decor'
import getCoordinatesFromProperty from 'common/util/getCoordinatesFromProperty'

class CashSalesOptions extends React.Component {
  componentDidMount () {
    this.fetch()
  }

  fetch = () => {
    // add radius
    const { range, minDeals, recentDeal } = this.props.cashSalesOpts
    const coords = getCoordinatesFromProperty(this.props.property?.result)
    if (!coords) return null
    const radius = [coords.lat, coords.lng, range]
    const params = { radius, include: ['properties'] }
    if (minDeals) {
      params.minDeals = minDeals
      params.recentDeal = recentDeal
    }

    this.props.fetchCustomNeighbors(params, 'CashSales')
  }

  onChangeRadius = e => {
    this.props.setCashSalesOpts({ range: e.target.value }, this.fetch)
  }

  /**
   * On change, we try not to accept empty or 0 or less minDeals value.

   * However NumberInput doesn't actually display the value we pass (it keeps
   * state of its own).
   *
   * So we are able to "guard" our filter's minDeals state, but not what the
   * input is showing (its own internal state).
   *
   * To work around that, we'll just save the displayed minDeals value, and
   * do some hacks in another event handler (onBlurMinDeals)
   */
  displayedMinDeals = null
  minDealsInputKey = 0
  onChangeMinDeals = e => {
    const newVal = e.target.value && Number(e.target.value)
    const currVal = Number(this.props.cashSalesOpts.minDeals)
    const isNewValAcceptable = newVal && newVal > 0

    this.displayedMinDeals = newVal

    if (newVal === currVal) return

    if (isNewValAcceptable)
      this.props.setCashSalesOpts({ minDeals: newVal }, this.fetch)
    else
      this.props.setCashSalesOpts({ minDeals: currVal })
  }

  /**
   * On blur, if the displayed minDeals value is not acceptable, we'll force-
   * -rerender the minDeals by incrementing its key -- forcing the display of
   * the last acceptable minDeals value that we've keeping in our state.
   */
  onBlurMinDeals = () => {
    const isDisplayedValAcceptable = this.displayedMinDeals &&
      this.displayedMinDeals > 0

    if (isDisplayedValAcceptable) return

    this.displayedMinDeals = this.props.cashSalesOpts.minDeals
    this.props.setCashSalesOpts(s => ({
      ...s,
      minDealsInputKey: s.minDealsInputKey + 1
    }))
  }

  onChangeRecentDeal = e => {
    this.props.setCashSalesOpts({ recentDeal: e.target.value }, this.fetch)
  }

  render () {
    return (
      <Box display='flex' justifyContent='flex-start' flexWrap='wrap' m={-2} mt={0}>
        <Box p={2} display='flex' flexDirection='column'>
          <Label
            fontWeight={100}
            mb={2}
          >
            Range
          </Label>
          <Select
            width={120}
            value={this.props.cashSalesOpts.range}
            onChange={this.onChangeRadius}
          >
            <Option value={0.5}>0.5 miles</Option>
            <Option value={1.0}>1.0 miles</Option>
            <Option value={1.5}>1.5 miles</Option>
            <Option value={2.0}>2.0 miles</Option>
            <Option value={2.5}>2.5 miles</Option>
            <Option value={3.0}>3.0 miles</Option>
            <Option value={3.5}>3.5 miles</Option>
            <Option value={4.0}>4.0 miles</Option>
            <Option value={4.5}>4.5 miles</Option>
            <Option value={5.0}>5.0 miles</Option>
          </Select>
        </Box>
        <Box p={2} display='flex' flexDirection='column'>
          <Label
            fontWeight={100}
            mb={2}
          >
            Min. Deals
          </Label>
          <NumberInput
            key={this.props.cashSalesOpts.minDealsInputKey}
            width={120}
            value={this.props.cashSalesOpts.minDeals}
            onChange={this.onChangeMinDeals}
            onBlur={this.onBlurMinDeals}
          />
        </Box>
        <Box p={2} display='flex' flexDirection='column' zIndex={3}>
          <Label
            fontWeight={100}
            mb={2}
          >
            Date of Sale
          </Label>
          <DateInput width={120} value={this.props.cashSalesOpts.recentDeal} onChange={this.onChangeRecentDeal} />
        </Box>
      </Box>
    )
  }
}

export default CashSalesOptions
