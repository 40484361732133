/* eslint "eqeqeq": "warn" */
import PropTypes from 'prop-types'
import React from 'react'

import Task from './Task.js'
import moment from 'moment'
import DateHelper from '../../../../core/date.js'

class TaskGroupHeader extends React.Component {
  render () {
    return (<h5 className='task-group-header'>{this.props.children}</h5>)
  }
}

class TaskList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
    grouping: 'due_date',
    details: true,
    emptyMsg: 'Nothing to see here... ',
    showRefs: true
  };

  state = { openId: null };

  getTasksByDate = tasks => {
    var groups = [
      [<TaskGroupHeader key='overdue'>Overdue</TaskGroupHeader>],
      [<TaskGroupHeader key='today'>Today</TaskGroupHeader>],
      [<TaskGroupHeader key='tomorrow'>Tomorrow</TaskGroupHeader>],
      [<TaskGroupHeader key='thisweek'>This Week</TaskGroupHeader>],
      [<TaskGroupHeader key='nextweek'>Next Week</TaskGroupHeader>],
      [<TaskGroupHeader key='later'>Later</TaskGroupHeader>]
    ]

    var none = []

    var cutoffs = [
      moment().endOf('day').add('d', -1), // overdue
      moment().endOf('day'), // today
      moment().endOf('day').add('d', 1), // tomorrow
      moment().endOf('week'), // this week
      moment().endOf('week').add('w', 1) // next week
    ]

    var laterIndex = groups.length - 1
    this.props.tasks.forEach(function (task, i) {
      var t = this.getTask(task)

      // get date
      var dt = task.values.due_date
      if (!dt) {
        none.push(this.getTask(task))
        return
      }

      dt = DateHelper.getMoment(dt)

      var found = false
      cutoffs.forEach(function (cutoff, index) {
        if (!found && !dt.isAfter(cutoff)) {
          groups[index].push(t)
          found = true
        }
      }, this)

      if (!found)
        groups[laterIndex].push(t)
    }, this)

    if (none.length > 0) {
      var laterGroup = groups[laterIndex]
      groups[laterIndex] = laterGroup.concat(none)
    }

    var ret = []
    groups.forEach(function (group) {
      if (group.length > 1)
        ret = ret.concat(group)
    })

    return ret
  };

  getTask = task => (
    <Task
      showRefs={this.props.showRefs}
      dispatch={this.props.dispatch}
      key={'task-' + task.id}
      task={task}
      editable={this.props.editable}
      detailShown={this.state.openId == task.id}
      onClick={this.openTask.bind(this, task.id)}
      currentUser={this.props.currentUser}
    />
  );

  openTask = id => {
    if (this.props.details) {
      var oid = this.state.openId
      if (oid == id)
        id = null

      this.setState({ openId: id })
    }
  };

  render () {
    var tasks = []

    if (this.props.grouping == 'due_date')
      tasks = this.getTasksByDate(this.props.tasks)

    return (
      <div className='task-list'>
        {tasks}
        {tasks.length == 0 ? <div className='empty-msg'>{this.props.emptyMsg}</div> : ''}
      </div>
    )
  }
}

export default TaskList
