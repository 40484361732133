import { SELECT_MARKET, SEARCH_MARKET_LEADS_SUCCESS } from '../actions'

export default (state = { selectedMarket: null }, action) => {
  switch (action.type) {
    case SELECT_MARKET:
      if (action.meta && action.meta.params) {
        const { marketId } = action.meta.params
        return {
          ...state,
          selectedMarket: marketId
        }
      }
      return state
    case SEARCH_MARKET_LEADS_SUCCESS:
      if (action.payload && action.payload.result) {
        const marketLeadCount = action.payload.result.count || null
        return {
          ...state,
          marketLeadCount
        }
      }
      return state
  }
  return state
}
