import React from 'react'

class LoadingMask extends React.Component {
  render () {
    return (
      <div className='loading-mask'>
        {this.props.children}
      </div>
    )
  }
}

export default LoadingMask
