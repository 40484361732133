import React from 'react'
import debounce from 'lodash/function/debounce'
import IntroLayout from './IntroLayout'
import ResultLayout from './ResultLayout'
import NewListDialog from './NewListDialog'
import { withRouter, Switch, Route } from 'react-router-dom'
import CTA from './CallToAction'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF_CONTENT } from 'const'
import logError from 'common/logError'
import get from 'lodash/object/get'

const CTAwithRouter = withRouter(CTA)

const ensureGeoFilter = filters => {
  const { county = [], city = [], zip = [] } = filters
  const count = [county, city, zip].reduce((acc, v) => acc + v.length, 0)
  return count > 0
}

const CriteriaMode = ({ filterComponent: FilterComponent, filters, onChange, onChangeFilter, debouncedSearch, counts, data, onChangeMode, propertyTypes }) => (
  <IntroLayout>
    <CTAwithRouter />
    <FilterComponent
      filters={filters}
      onChange={onChange}
      onChangeFilter={onChangeFilter}
      onSearch={debouncedSearch}
      counts={counts}
      data={data}
      showResults={() => onChangeMode('preview')}
      propertyTypes={propertyTypes}
    />
  </IntroLayout>
)

const ResultsMode = ({ resultComponent: ResultComponent, exportLeads, type, filters, loading, counts, data, paging, onChangePage, onChangeMode }) => {
  const [exportedListId, setExportedListId] = React.useState(1)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [exporting, setExporting] = React.useState(false)

  const createList = React.useCallback(name => {
    setExporting(true)
    exportLeads(type, name, filters).then(res => {
      setExporting(false)
      setExportedListId(res.marketingListId)
    })
  }, [filters])

  const openDialog = React.useCallback(() => {
    setExportedListId(null)
    setDialogOpen(true)
  })

  return (
    <>
      <NewListDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSaveClick={createList}
        saving={exporting}
        listId={exportedListId}
        counts={counts}
        type={type}
      />
      <ResultLayout
        loading={loading}
        resultComponent={
          <ResultComponent
            filters={filters}
            data={data.items}
            loading={data.loading}
            counts={counts}
            paging={paging}
            onChangePage={onChangePage}
            onSaveClick={openDialog}
            onChangeMode={onChangeMode}
          />
        }
      />
    </>
  )
}

const TwoPartSearch = ({
  history,
  match,
  fetch,
  exportLeads,
  type,
  data,
  paging,
  fetchCounts,
  counts,
  fetchPropertyTypes = () => {},
  propertyTypes = {},
  filterComponent: FilterComponent,
  resultComponent: ResultComponent,
  filter
}) => {
  const [loading, setLoading] = React.useState(false)
  const [filters, setFilters] = React.useState({})
  const cachedFilters = JSON.stringify(filter)

  React.useEffect(() => {
    try {
      const _filters = JSON.parse(cachedFilters)
      setFilters(_filters)
    } catch (error) {
      // Do nothing
    }
  }, [cachedFilters])

  React.useEffect(() => {
    Promise.all([
      // fetchCounts(),
      fetchPropertyTypes()
    ]).then(() => {})
  }, [])

  const search = filters => {
    Promise.all([
      fetchCounts(filters),
      fetch(filters)
    ]).then(([countRes, listRes]) => {
      const countErr = get(countRes, 'error')
      const listErr = get(listRes, 'error')

      if (countErr || listErr) {
        if (countErr) logError(countErr)
        if (listErr) logError(listErr)

        toast.error({
          title: listErr
            ? 'List building failed'
            : 'Failed to request total results count',
          content: GENERIC_ERROR_NOTIF_CONTENT
        })
      }
    })
      .finally(() => {
        setLoading(false)
      })
  }
  const debouncedSearch = React.useCallback(debounce(search, 800), [])

  const onChangePage = React.useCallback(offset => {
    search({ ...filters, offset })
  }, [search, filters])
  const onChange = React.useCallback(values => {
    const newFilters = { ...filters, ...values }
    setFilters(newFilters)
    setLoading(true)
    if (ensureGeoFilter(newFilters))
      debouncedSearch(newFilters)
  }, [filters, setFilters, setLoading, ensureGeoFilter, debouncedSearch])
  const onChangeFilter = React.useCallback((field, value) => {
    onChange({ [field]: value })
  }, [onChange])

  const onChangeMode = React.useCallback(newMode => {
    if (newMode === 'criteria')
      history.push(match.path)
    else
      history.push(`${match.path}/${newMode}`)
  }, [])

  return (
    <Switch>
      <Route path={`${match.path}`} exact render={() => (
        <CriteriaMode
          filterComponent={FilterComponent}
          filters={filters}
          onChange={onChange}
          onChangeFilter={onChangeFilter}
          debouncedSearch={debouncedSearch}
          counts={counts}
          data={data}
          onChangeMode={onChangeMode}
          propertyTypes={propertyTypes}
        />
      )}/>
      <Route path={`${match.path}/preview`} render={() => (
        <ResultsMode
          resultComponent={ResultComponent}
          exportLeads={exportLeads}
          type={type}
          filters={filters}
          loading={loading}
          counts={counts}
          data={data}
          paging={paging}
          onChangePage={onChangePage}
          onChangeMode={onChangeMode}
        />
      )} />
    </Switch>
  )
}

export default TwoPartSearch
