import { SEARCH_TYPES_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TYPES_SUCCESS:
      if (action.payload) {
        const types = action.payload
        return {
          ...state,
          ...types
        }
      }
      return state
  }
  return state
}
