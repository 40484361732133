/* eslint "eqeqeq": "warn", "camelcase": "warn", "react/no-deprecated": "warn" */
import React from 'react'
import { Heading, NumberInput } from '@realsoftworks/decor'
import Form, { FormInput, Field } from 'decor/form'

import CheckList from '../../../../views/common/check-list'
import LoadingButton from 'common/LoadingButton.js'
import ButtonBar from '../../../../views/common/button-bar'

import CriteriaSummary from './CriteriaSummary'

import Picker from 'decor/form/Picker'
import get from 'lodash/object/get'

export default class SearchForm extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (v) {
    const fixed = {
      ...v,
      year_built_max: v.year_built_max === '' ? null : v.year_built_max,
      year_built_min: v.year_built_min === '' ? null : v.year_built_min
    }
    this.props.onChange(fixed)
  }

  onSubmit () {
    this.props.onSubmit(this.props.value)
  }

  render () {
    const { metadata, value, saving } = this.props
    const propertTypes = get(metadata, 'sales.single.property_category')

    return (
      <Form className='search-form' value={value} onChange={ this.onChange} onSubmit={ this.onSubmit }>

        {propertTypes &&
          <Field>
            <Heading mb={3}>Property Types</Heading>
            <FormInput type={PropertyType} types={propertTypes} name='property_type' />
          </Field>}

        <div className='top'>
          <div className='property-facts'>
            <Heading mb={3}>Property Stats</Heading>
            <Field>
              <label>Beds</label>
              <div className='range'>
                <FormInput type='number' name='beds_min' placeholder='Any'/>
                <span className='spacer'>to</span>
                <FormInput type='number' name='beds_max' placeholder='Any'/>
              </div>
            </Field>

            <Field>
              <label>Baths (Full)</label>
              <div className='range'>
                <FormInput type='number' name='baths_full_min' placeholder='Any'/>
                <span className='spacer'>to</span>
                <FormInput type='number' name='baths_full_max' placeholder='Any'/>
              </div>
            </Field>

            <Field>
              <label>Sqft</label>
              <div className='range'>
                <FormInput type='number' name='sqft_min' placeholder='Any'/>
                <span className='spacer'>to</span>
                <FormInput type='number' name='sqft_max' placeholder='Any'/>
              </div>
            </Field>

            <Field>
              <label>Year Built</label>
              <div className='range'>
                <FormInput name='year_built_min' placeholder='Any' inputMask={/[0-9]/}/>
                <span className='spacer'>to</span>
                <FormInput name='year_built_max' placeholder='Any' inputMask={/[0-9]/}/>
              </div>
            </Field>
          </div>

          <div className='geography'>
            <Heading mb={3}>Geography</Heading>
            <Field>
              <label>Counties</label>
              <FormInput type={CountyPicker} name='geo.counties' data={metadata.counties} state={metadata.state} />
            </Field>

            <Heading>Financials</Heading>
            <Field>
              <label>List Price</label>
              <div className='range'>
                <FormInput type='number' name='list_price_min' placeholder='Any'/>
                <span className='spacer'>to</span>
                <FormInput type='number' name='list_price_max' placeholder='Any'/>
              </div>
            </Field>

            <hr/>
            <Field>
              <label>Equity</label>
              <div className='equity-field'>
                <div className='group-wrap'>
                  <div className='input-group dollar-group'>
                    <span className='input-group-addon'>$</span>
                    <FormInput type='number' name='equity_dollars' />
                  </div>
                </div>

                and

                <div className='group-wrap'>
                  <div className='input-group percent-group'>
                    <FormInput type={PercentInput} name='equity_percent' />
                    <span className='input-group-addon'>%</span>
                  </div>
                </div>
              </div>
              <p className='equity-help'>If both Dollars and Percent are provided, a listing must meet both conditions before it becomes a Deal Alert</p>

            </Field>

          </div>
        </div>

        <Field>
          <label>Status</label>
          <FormInput type={PropertyStatus} values={metadata.sales.statuses} name='status' />
        </Field>

        <hr/>

        <Heading mb={3}>Criteria Summary</Heading>
        <CriteriaSummary loading={this.props.loadingCount} count={this.props.count} criteria={value}/>

        <hr/>

        <Field>
          <label>Alert Name</label>
          <FormInput name='name' placeholder='Ex: 30% equity flips'/>
        </Field>

        <ButtonBar>
          <LoadingButton
            type='submit'
            className='btn btn-success'
            text='Save'
            loadingText='Saving...'
            loading={saving}
          />
        </ButtonBar>

      </Form>
    )
  }
}

class PropertyType extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange = (e, val) => {
    var id = e.target.name

    val = {
      ...this.props.value,

      [id]: val
    }

    var count = Object.keys(val).reduce((c, v) => c + val[v].length, 0)
    if (count == 0)
      val = null

    if (this.props.onChange) this.props.onChange(val)
  };

  render () {
    var { types } = this.props
    var value = this.props.value || {}

    return (
      <div className='search-form-property-types'>
        {types.map(property_category => {
          var { id, description, property_type } = property_category

          var options = property_type.map(type => {
            if (typeof type !== 'string')
              return { value: type.id, label: type.description }

            return type
          })

          return (
            <Field key={id} className='search-form-property-category'>
              <label>{description || id}</label>
              <CheckList name={id} options={options} value={value[id]} onChange={this.onChange}/>
            </Field>
          )
        })}
      </div>
    )
  }
}

class PropertyStatus extends React.Component {
  render () {
    var searchables = this.props.values.reduce((ret, v) => {
      if (v.searchable)
        ret.push(v.label)

      return ret
    }, [])

    return (
      <CheckList options={searchables} {...this.props} />
    )
  }
}

const PercentInput = ({ value, onChange, ...rest }) =>
  <NumberInput
    {...rest}
    value={value ? (value * 100).toFixed(0) : value}
    onChange={ e => onChange(e.target.value ? (e.target.value / 100) : e.target.value) }
  />

const makeCountyLabeler = state => item => {
  if (typeof item === 'object' && state && item.state) {
    state = state || state.code
    return item.state !== state ? (item.state + '-' + item.name) : item.name
  }

  return item.name
}

class CountyPicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = { ...this.getConfig(props) }
  }

  getConfig (props) {
    var displayField, searchFields

    var multi = props.state && typeof props.state !== 'string'

    if (multi) {
      displayField = makeCountyLabeler(props.state)
      searchFields = ['name']
    }

    return { displayField, searchFields }
  }

  componentWillReceiveProps (props) {
    if (props.state !== this.props.state)
      this.setState(this.getConfig(props))
  }

  render () {
    var { displayField, searchFields } = this.state

    return <Picker {...this.props} displayField={displayField} searchFields={searchFields}/>
  }
}
