/* eslint "no-class-assign": "warn" */
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import themeGet from '@styled-system/theme-get'
import { Button, Tooltip } from '@realsoftworks/decor'
import { selectors as usersSelectors } from 'users'

const BetaNoticeButton = styled(Button)`
  color: white;
  background-color: ${themeGet('colors.red.500')};
  border-color: ${themeGet('colors.red.500')};

  &:hover, &:active {
    background-color: ${themeGet('colors.red.400')};
    border-color: ${themeGet('colors.red.400')};
    color: white;
  }

  &:visited {
    color: white;
  }
`

class BetaNotice extends React.Component {
  render () {
    const { route, isBeta, ...props } = this.props
    const days = 2;

    return (
      <Tooltip content='You are using the legacy version of Propelio. Click here to return to the new version.'>
        <span style={{ display: 'inline-block' }}>
          <BetaNoticeButton variant='custom' as={'a'} href={window.App.routes.genesis} {...props}>
            <FontAwesomeIcon icon={faSignInAlt}/>Exit Legacy App
          </BetaNoticeButton>
        </span>
      </Tooltip>
    )
  }
}

BetaNotice = connect(state => ({
  isBeta: usersSelectors.getIsBeta(state),
}))(BetaNotice)

export default BetaNotice
