/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn", "react/no-deprecated": "warn" */
import React from 'react'
import createReactClass from 'create-react-class'
import DateFieldMixin from './util/DateFieldMixin.react'
import $ from 'jquery'
import 'jquery-ui/datepicker'

var DateField = createReactClass({
  displayName: 'DateField',
  mixins: [DateFieldMixin],

  getInitialState: function () {
    var v = this.props.value
    if (v) {
      v = this.parseValue(v)
      v = this.formatDisplay(v)
    }

    return {
      editingValue: v
    }
  },

  render: function () {
    const { minDate, maxDate, ...rest } = this.props

    var v = this.state.editingValue

    return (
      <div className='date-field' onClick={this.onClick}>
        <input {...rest} ref='input' type='text' value={v} onChange={this.onChange} className='date-entry form-control' onBlur={false} />
      </div>
    )
  },

  onClick: function () {
    this.focus()
  },

  onChange: function (e) {
    let value = e.target.value
    this.setState({ editingValue: value })

    value = $(this.refs.input).datepicker('getDate')
    if (value)
      value = this.formatValue(value)

    this.props.onChange(value)
  },

  componentDidMount: function () {
    $(this.refs.input).datepicker({
      onSelect: this.onPickSelect,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate
    })
  },

  onPickSelect: function (value) {
    this.setState({ editingValue: value })
    value = this.formatValue(value)
    this.props.onChange(value)
  },

  checkBlur: function (e) {
    if (e.target == this.refs.input)
      return false

    return !$(e.target).closest('#global-date-picker').length && !$(e.target).closest('.ui-datepicker-header').length
  },

  focus: function () {
    this.refs.input.focus()
  },

  getValue: function () {
    return this.refs.input.value
  }
})

export default DateField
