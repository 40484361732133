import React, { useState, useCallback, useEffect } from 'react'
import { arrayOf, object, number, bool, func } from 'prop-types'
import FetchHandler from 'common/FetchHandler'
import ListMemberTable from 'lists/components/ListDetail/components/ListMemberTable.js'

const ITEM_LIMIT = 50

// Consumer provide the props for these components,
// usually using redux's connect to provide leads
// and other props listed below. This component creator
// allows you to create functional LeadsTable with
// different sources of leads (e.g. drives and lists)
const LeadsTable = ({
  leads,
  count,
  isLoading,
  error,
  fetchLeads,
  ...props
}) => {
  const {
    hasLoaded,
    offset,
    onChangePage
  } = leadsTableHook({ fetchLeads })

  const memberInfo = {
    offset,
    count,
    items: leads,
    limit: ITEM_LIMIT
  }

  return (
    <FetchHandler
      error={error}
      isLoading={!hasLoaded || isLoading}
      isEmpty={false}
      emptyText=''
    >
      <ListMemberTable
        memberInfo={memberInfo}
        onChangePage={onChangePage}
        {...props}
      />
    </FetchHandler>
  )
}

export default LeadsTable

LeadsTable.propTypes = {
  leads: arrayOf(object).isRequired,
  count: number,
  isLoading: bool.isRequired,
  error: object,
  fetchLeads: func.isRequired
}

const leadsTableHook = ({ fetchLeads }) => {
  const [offset, setOffset] = useState(0)
  const [hasLoaded, setHasLoaded] = useState(false)

  const onChangePage = useCallback(
    nextOffset => {
      fetchLeads({ limit: ITEM_LIMIT, offset: nextOffset })
      setOffset(nextOffset)
    },
    [offset]
  )

  useEffect(() => {
    (async () => {
      try {
        await fetchLeads({ limit: ITEM_LIMIT, offset })
        setHasLoaded(true)
      } catch (e) {}
    })()
  }, [])

  return { hasLoaded, offset, onChangePage }
}
