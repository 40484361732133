import React, { createContext, useMemo, useCallback, useContext, useState, useEffect } from 'react'
import { search } from 'leads/actions'
import { createGetLeadsBySortOrder } from 'leads/selectors'
import { connect } from 'react-redux'

const PAGE_LIMIT = 20

const STATE_LABELS = {
  search: 'Search',
  pending: 'Follow Up',
  contract: 'Under Contract',
  portfolio: 'Purchased',
  listing: 'For Sale',
  sold: 'Completed'
}

export const STATE_OPTS = [
  { label: STATE_LABELS.search, value: 'search' },
  { label: STATE_LABELS.pending, value: 'pending' },
  { label: STATE_LABELS.contract, value: 'contract' },
  { label: STATE_LABELS.portfolio, value: 'portfolio' },
  { label: STATE_LABELS.listing, value: 'listing' },
  { label: STATE_LABELS.sold, value: 'sold' }
]

export const ARCHIVED_OR_DELETED_OPTS = [
  {
    label: 'Active',
    value: {
      payload: { active: true, deleted: false },
      status: 'active'
    }
  },
  {
    label: 'Archived',
    value: {
      payload: { active: false, deleted: false },
      status: 'archived'
    }
  },
  {
    label: 'Deleted',
    value: {
      payload: { active: false, deleted: true },
      status: 'deleted'
    }
  }
]

export const FILTER_OPTS = [
  [
    { label: 'No Filter', filter: { state: undefined, show: 'active' } },
    { label: STATE_LABELS.search, filter: { state: 'search', show: 'active' } },
    { label: STATE_LABELS.pending, filter: { state: 'pending', show: 'active' } },
    { label: STATE_LABELS.contract, filter: { state: 'contract', show: 'active' } },
    { label: STATE_LABELS.portfolio, filter: { state: 'portfolio', show: 'active' } },
    { label: STATE_LABELS.listing, filter: { state: 'listing', show: 'active' } },
    { label: STATE_LABELS.sold, filter: { state: 'sold', show: 'active' } }
  ]
]

export const DEFAULT_FILTER = FILTER_OPTS[0][0].filter

export const SORT_OPTS = [
  [
    { label: 'Follow up Date, soonest first', sort: 'next_follow_up-asc' },
    { label: 'Follow up Date, later first', sort: 'next_follow_up-desc' }
  ],
  [
    { label: 'Updated Date, newest first', sort: 'modified-desc' },
    { label: 'Updated Date, oldest first', sort: 'modified-asc' }
  ],
  [
    { label: 'Created Date, newest first', sort: 'created-desc' },
    { label: 'Created Date, oldest first', sort: 'created-asc' }
  ],
  [
    { label: 'Street Name, A to Z', sort: 'address_street-asc' },
    { label: 'Street Name, Z to A', sort: 'address_street-desc' }
  ],
  [
    { label: 'Street Number, lowest first', sort: 'address_number-asc' },
    { label: 'Street Number, highest first', sort: 'address_number-desc' }
  ]
]

export const DEFAULT_SORT = SORT_OPTS[1][0].sort

const INIT_PAGINATION_DATA = {
  offset: 0,
  limit: PAGE_LIMIT,
  length: 0,
  count: 0
}

const INIT_CTX_VALUE = {
  isLoading: true,
  sort: null,
  filter: DEFAULT_FILTER,
  creator: undefined,
  history: [],
  fetchHistory: () => {},
  setSortAndfetchHistory: () => {},
  setFilterAndfetchHistory: () => {},
  setQueryAndfetchHistory: _query => {},
  pagination: {
    ...INIT_PAGINATION_DATA,
    loading: false,
    onChangePage: () => {}
  }
}

const ctx = createContext(INIT_CTX_VALUE)

const getLeadsBySortOrder = createGetLeadsBySortOrder({ includeContacts: true })
const mapState = state =>
  ({ history: getLeadsBySortOrder(state) || [] })
const mapDispatch = { fetchHistory: search }
const withConnect = connect(mapState, mapDispatch)

export const HistoryProvider = withConnect(({ history, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [sort, setSort] = useState(DEFAULT_SORT)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [creator, setCreator] = useState([])
  const [paginationData, setPaginationData] = useState(INIT_PAGINATION_DATA)
  const [searchHistoryQuery, setSearchHistoryQuery] = useState('')

  // Adjust pagination when new history item is added due to new search
  useEffect(() => {
    const hasNewHistoryItem = history &&
      paginationData &&
      history.length > paginationData.length
    if (hasNewHistoryItem)
      setPaginationData(p => ({
        ...p,
        count: p.count + 1,
        length: p.length + 1
      }))
  }, [history, paginationData.length])

  const fetchHistory = useCallback(passedParams => {
    const params = {
      limit: PAGE_LIMIT,
      ...filter,
      ...(sort && { sort }),
      ...(creator && { creator }),
      ...(passedParams || {}),
      ...(searchHistoryQuery && { address: searchHistoryQuery })
    }

    setIsLoading(true)
    return props.fetchHistory(params)
      .then(payload => {
        const { result: { count, models } = {} } = payload || {}
        setPaginationData(p => ({
          ...p,
          count: count || 0,
          length: (models || []).length
        }))

        return payload
      })
      .finally(() => setIsLoading(false))
  }, [props.fetchHistory, sort, filter, creator, searchHistoryQuery])

  const setSortAndfetchHistory = useCallback(sort => {
    setPaginationData(p => ({ ...p, offset: 0 }))
    setSort(sort)
    fetchHistory({ sort })
  }, [fetchHistory])

  const setFilterAndfetchHistory = useCallback(filter => {
    setPaginationData(p => ({ ...p, offset: 0 }))
    setFilter(filter)
    fetchHistory({ ...filter })
  }, [fetchHistory])

  const setQueryAndfetchHistory = useCallback(query => {
    setPaginationData(p => ({ ...p, offset: 0 }))
    setSearchHistoryQuery(query)
    fetchHistory(query ? { address: query } : {})
  }, [fetchHistory])

  const onChangePage = useCallback(offset => {
    setPaginationData(p => ({ ...p, offset }))
    fetchHistory({ offset })
  }, [fetchHistory])

  const ctxValue = useMemo(() => ({
    isLoading,
    sort,
    filter,
    history,
    creator,
    fetchHistory,
    setSortAndfetchHistory,
    setFilterAndfetchHistory,
    setQueryAndfetchHistory,
    setCreator,
    pagination: {
      ...paginationData,
      loading: isLoading,
      onChangePage
    }
  }), [
    isLoading,
    sort,
    filter,
    history,
    paginationData,
    onChangePage,
    fetchHistory,
    setSortAndfetchHistory,
    setFilterAndfetchHistory,
    setQueryAndfetchHistory
  ])

  return (<ctx.Provider value={ctxValue} {...props} />)
})

export const useHistory = () => useContext(ctx)
