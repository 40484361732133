import React from 'react'
import { FormInput } from '../../form'

class CMAStringField extends React.Component {
  render () {
    var { type, label, params, ...rest } = this.props
    return (
      <div className='form-group'>
        <label className='control-label'>{label}</label>
        <div className='field'>
          <FormInput type={type} value={params[this.props.name] || ''} {...rest} placeholder='value' onChange={this.onChange.bind(this)} />
        </div>
      </div>
    )
  }

  onChange (e) {
    var v = e.target ? e.target.value : ''
    this.props.onChange(this.props.name, v)
  }
}
export default CMAStringField
