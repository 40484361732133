/* eslint "react/no-string-refs": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import Button from '../Button'
import FontIcon from '../FontIcon/FontIcon'
import Tooltip from '../internal/Tooltip'

class IconButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    tooltip: PropTypes.node,

    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseOut: PropTypes.func

  };

  state = {
    tooltip: false
  };

  showTooltip () {
    if (this.props.tooltip) this.setState({ tooltip: true })
  }

  hideTooltip () {
    if (this.props.tooltip) this.setState({ tooltip: false })
  }

  onMouseEnter = e => {
    this.showTooltip()
    if (this.props.onMouseEnter) this.props.onMouseEnter(e)
  };

  onMouseLeave = event => {
    this.hideTooltip()
    if (this.props.onMouseLeave) this.props.onMouseLeave(event)
  };

  onMouseOut = event => {
    if (this.props.disabled) this.hideTooltip()
    if (this.props.onMouseOut) this.props.onMouseOut(event)
  };

  render () {
    const { iconClassName, className, tooltip, children, ...rest } = this.props

    let fontIcon
    if (iconClassName)
      fontIcon = (
        <FontIcon className={iconClassName}/>
      )

    let tooltipElement
    if (tooltip)
      tooltipElement = (
        <Tooltip
          show={this.state.tooltip}
        >
          {tooltip}
        </Tooltip>
      )

    var classNames = classnames('icon-button', 'decorIconButton', className)

    return (
      <Button
        {...rest}
        className={classNames}
        ref='button'
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
        onMouseOut={this.onMouseOut}
        centerRipple={true}
      >
        {tooltipElement}
        {fontIcon}
        {children}
      </Button>
    )
  }
}

export default IconButton
