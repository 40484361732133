import React, { createContext, useContext, useEffect, useMemo, useState, useRef, useCallback, Ref } from 'react'
import useMouseEnter from 'common/util/hooks/useMouseEnter'

type OnSyncHover = (
  isHovered: boolean,
  opts: { isSelf: boolean, hoverRef: Ref<HTMLElement | undefined> }
) => void

type SyncedMapListContext = {
  subsRef: {
    current: {
      [key: string]: {
        subbedId: string
        onSyncHover: OnSyncHover
        hoverRef: Ref<HTMLElement | undefined>
      }
    }
  }
  syncHoveredIdRef: { current: string | null }
}

const ctx = createContext<SyncedMapListContext>({
  subsRef: { current: {} },
  syncHoveredIdRef: { current: null }
})

export const SyncedMapListProvider = props => {
  const subsRef = useRef({})
  const syncHoveredIdRef = useRef(null)

  const ctxValue = useMemo(() =>
    ({ subsRef, syncHoveredIdRef }),
  [subsRef, syncHoveredIdRef])

  return (
    <ctx.Provider value={ctxValue} {...props} />
  )
}

/**
 * Returns true if hook caller or other hook caller with same groupId and
 * memberId is hovered. Otherwise returns false
 */
let idCounter = 0
export const useSyncedIsHovered = ({
  groupId = 'nogroup',
  memberId,
  onSyncHover: onSyncHoverFromProps = noop
}: {
  groupId?: string
  memberId?: string
  onSyncHover?: OnSyncHover
}) => {
  const hoverRef = useRef<HTMLElement | undefined>()
  const hookCallerId = `${groupId}:${memberId}`
  const { subsRef, syncHoveredIdRef } = useContext(ctx)
  const isInitiallySyncHovered = syncHoveredIdRef.current === hookCallerId
  const [isSyncHovered, setIsSyncHovered] = useState(isInitiallySyncHovered)
  const isHovered = useMouseEnter(hoverRef)
  const selfSymbRef = useRef<string | undefined>()

  useEffect(() => {
    // Save symbol for other function's reference
    const subsKey = `${idCounter}:${hookCallerId}`
    idCounter++
    selfSymbRef.current = subsKey

    // Subscribe by add callback to subscribers
    const onSyncHover = (isHovered, meta) => {
      setIsSyncHovered(isHovered)
      onSyncHoverFromProps(isHovered, meta)
    }

    subsRef.current[subsKey] = {
      subbedId: hookCallerId,
      onSyncHover,
      hoverRef
    }

    // Remove callback from subscribers on unmount or id change
    return () => {
      delete subsRef.current[subsKey]
    }
  }, [hookCallerId, onSyncHoverFromProps])

  useEffect(() => {
    // Call all subs whose ID is hovered/dehovered
    Object.keys(subsRef.current)
      .forEach(key => {
        const { subbedId, onSyncHover, hoverRef } = subsRef.current[key]
        const hasUnhovered = !isHovered && subbedId === syncHoveredIdRef.current
        const hasHovered = isHovered && subbedId === hookCallerId
        const hasActivity = hasHovered || hasUnhovered
        const isSelf = selfSymbRef.current === key
        if (hasActivity) onSyncHover(hasHovered, { isSelf, hoverRef })
      })

    // Update active id
    syncHoveredIdRef.current = isHovered ? hookCallerId : null
  }, [isHovered])

  return { isSyncHovered, hoverRef }
}

const noop = () => {}

/**
 * Note: set `position: relative;` on scroll parent otherwise autoscroll
 *   won't work correctly
 */
export const useSyncedIsHoveredWithAutoScroll = ({
  scrollParentRef,
  memberId,
  groupId
}) => {
  const scrollToViewOnPartnerHover = useCallback((
    isHovered,
    { isSelf, hoverRef: scrollTgtRef }
  ) => {
    if (scrollParentRef && scrollParentRef.current) {
      const isPartnerHovered = !isSelf && isHovered
      const hasCompleteRefs = scrollTgtRef.current && scrollParentRef.current

      // Scroll parent to scroll target position
      if (isPartnerHovered && hasCompleteRefs) {
        const tgtTopPos = scrollTgtRef.current.offsetTop
        scrollParentRef.current.scrollTop = tgtTopPos
      }
    }
  }, [])

  return useSyncedIsHovered({
    memberId,
    groupId,
    onSyncHover: scrollToViewOnPartnerHover
  })
}
