/* eslint "camelcase": "warn", "eqeqeq": "warn", "react/no-deprecated": "warn" */
import React from 'react'
import styled from 'styled-components'
import StateButtons from './StateButtons.react'
import ProTable from 'decor/ProTable'
import { Box, TableFoot, TableCell, TableRow, Text } from '@realsoftworks/decor'
import classnames from 'classnames'

import buildColumns from 'mls/util/columns'

const CompLink = ({ onClick: propsOnClick, listing, ...props }) => {
  const onClick = React.useCallback(e => {
    e.preventDefault()
    propsOnClick(listing.key || listing.id)
  }, [propsOnClick, listing])

  return (
    <a href='#' onClick={onClick} {...props}/>
  )
}

const CompStates = ({ onChange: propsOnChange, listing, saving, state }) => {
  const onChange = React.useCallback((e, value) => {
    propsOnChange(listing.key || listing.id, value)
  }, [propsOnChange, listing])

  return (
    <StateButtons onChange={onChange} saving={saving} state={state}/>
  )
}

const stateColumnProps = {
  width: '110px',
  flex: 'none',
  css: `
    @media print {
      width: 30px;
    }
  `
}

const CompTableFoot = styled(TableFoot)`
  @media print {
    display: block;
  }
`

const Wrapper = styled(Box)`
  font-size: 14px;

  @media print {
    font-size: 12px;
  }
`

const identity = v => v
const CompFooter = ({ columns, data, shouldHideOnPrint }) => {
  var fields = ['beds', 'baths_full', 'baths_half', 'year_built', 'garage', 'dom', 'calculated_dom', 'cdom', 'sqft', 'list_price', 'close_price', 'dollars_per_foot']
  var stats = Array.prototype.reduce.call(data, function (ret, c) {
    fields.forEach(function (field) {
      var comp_value = c[field]

      if (!comp_value && field == 'dollars_per_foot' && c.sqft && (c.close_price || c.list_price))
        comp_value = (c.close_price || c.list_price) / c.sqft

      if (comp_value === null || comp_value === undefined)
        return ret

      ret.min[field] = Math.min(ret.min[field] === undefined ? Infinity : ret.min[field], comp_value)
      ret.max[field] = Math.max(ret.max[field] || null, comp_value)
      ret.total[field] = (ret.total[field] || 0) + comp_value
    })

    return ret
  }, { min: {}, max: {}, avg: {}, total: {} })

  var count = data.length
  fields.forEach(function (field) {
    const total = stats.total[field]
    let avg = null

    if (total === 0)
      avg = 0
    else if (total !== undefined)
      avg = total / count

    if (avg !== null) {
      if (field != 'dollars_per_foot')
        avg = Math.round(avg)

      stats.avg[field] = avg
    }
  })

  var minRow = []; var maxRow = []; var avgRow = []
  let label = false
  for (var i = 0, len = columns.length; i < len; i++) {
    var col = columns[i]
    var id = col.id || col.accessor
    var render = col.render || identity
    const rawProps = col.props || {}

    // Remove caption for columns that aren't applicable to min/avg/max row
    // like "address" and "status"
    const COLS_NOT_APPLICABLE = [
      'address',
      'original_status',
      'address.subdivision'
    ]
    const props = COLS_NOT_APPLICABLE.includes(id)
      ? { ...rawProps, caption: '' }
      : rawProps

    if (id !== 'property_facts' && fields.indexOf(id) === -1) {
      const isStateCell = i === 0
      const cellJsx = (
        <TableCell
          {...props}
          className={classnames(
            props.className,
            isStateCell && 'print-hide'
          )}
        />
      )

      minRow.push(cellJsx)
      maxRow.push(cellJsx)
      avgRow.push(cellJsx)
      continue
    }

    if (!label) {
      const prevProps = columns[i - 1].props || {}
      minRow.pop()
      minRow.push(<TableCell key='minlabel' {...prevProps} textAlign='right'><Text fontWeight='bold'>Min</Text></TableCell>)
      maxRow.pop()
      maxRow.push(<TableCell key='maxlabel' {...prevProps} textAlign='right'><Text fontWeight='bold'>Max</Text></TableCell>)
      avgRow.pop()
      avgRow.push(<TableCell key='avglabel' {...prevProps} textAlign='right'><Text fontWeight='bold'>Avg</Text></TableCell>)
      label = true
    }

    var min = stats.min[id]
    var max = stats.max[id]
    var avg = stats.avg[id]

    if (id == 'property_facts') {
      min = stats.min
      max = stats.max
      avg = stats.avg
    }

    minRow.push(<TableCell key={id} {...props}>{render(min, stats.min)}</TableCell>)
    maxRow.push(<TableCell key={id} {...props}>{render(max, stats.max)}</TableCell>)
    avgRow.push(<TableCell key={id} {...props}>{render(avg, stats.avg)}</TableCell>)
  }

  return (
    <CompTableFoot className={shouldHideOnPrint ? 'print-hide' : ''}>
      <TableRow>{minRow}</TableRow>
      <TableRow>{maxRow}</TableRow>
      <TableRow>{avgRow}</TableRow>
    </CompTableFoot>
  )
}

class CompList extends React.Component {
  static defaultProps = {
    states: {},
    link: true,
    savingStates: {},
    showStates: true,
    onStateChange: () => {}
  };

  constructor (props, context) {
    super(props, context)
    const columns = this.getUpdatedColumns(props)

    this.state = {
      columns
    }
  }

  componentWillReceiveProps (newProps) {
    const columns = this.getUpdatedColumns(newProps)
    this.setState({ columns })
  }

  buildColumns = () => {
    var { metadata } = this.props
    var fields = null; var suggested = null
    if (metadata) {
      fields = metadata.fields
      if (metadata.ui && metadata.ui.suggested_columns)
        suggested = metadata.ui.suggested_columns
    }
    var columns = buildColumns(fields, suggested)
    return columns
  };

  getUpdatedColumns = props => {
    let columns = props.columns
    if (!columns)
      columns = this.buildColumns()

    if (this.props.link) {
      var address = columns.find(v => v.id == 'address')
      if (address) address.render = (v, listing) => <CompLink listing={listing} onClick={this.onNameClick}>{v}</CompLink>
    }

    if (this.props.showStates) {
      var stateColumn = {
        id: 'states',
        header: 'Include in ARV',
        className: 'states states-column print-hide',
        headerClassName: 'states states-column',
        render: (v, row) =>
          <CompStates
            listing={row}
            onChange={this.props.onStateChange}
            saving={this.props.savingStates[row.key || row.id]}
            state={this.props.states[row.key || row.id]}
          />,
        props: stateColumnProps
      }
      columns = [stateColumn].concat(columns)
    }
    return columns
  };

  getRowClass = row => {
    const state = this.props.states[row.key || row.id]
    const isIncluded = state === 'included'

    // Only show included properties on comps print
    const className = `print-white-bg ${isIncluded ? '' : 'print-hide'}`
    return className
  }

  render () {
    var columns = this.state.columns
    const isAnyIncluded = this.props.comps.map(c => this.props.states[c.key])
      .includes('included')

    return (
      <Wrapper mb={5}>
        <Box overflow='auto' className='print-normalize-container'>
          <Box minWidth='1200px' className='print-normalize-container'>
            <ProTable
              responsive
              spacing='dense'
              columns={columns}
              data={this.props.comps}
              footer={(
                <CompFooter
                  shouldHideOnPrint={!isAnyIncluded}
                  data={this.props.comps}
                  columns={columns}
                />
              )}
              getRowClass={this.getRowClass}
            />
          </Box>
        </Box>
      </Wrapper>
    )
  }

  onStateChange = (key, state) => {
    this.props.onStateChange(key, state)
  };

  onNameClick = key => {
    this.props.onCompClick(key)
  };
}

export default CompList
