import { oneOfType, number, string } from 'prop-types'
import { format } from 'common/util/number'

const Numeric = ({ children, blank = null, decimals = 0 }) => {
  if (!children && children !== 0) return null

  const value = Number(children)

  if (Number.isNaN(value)) return blank

  return (
    format(value, { decimals, forceZeros: decimals > 0 })
  )
}

Numeric.propTypes = {
  children: oneOfType([
    number,
    string
  ]),
  decimals: number
}

export default Numeric
