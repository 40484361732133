import api from 'common/api'
import get from 'lodash/object/get'
import { toast } from '@realsoftworks/decor'
import { getDnsRecords } from './selectors'
import set from 'common/util/set'

export const GET_RECORDS = 'dnsrecords/GET_RECORDS'
export const GET_RECORDS_SUCCESS = 'dnsrecords/GET_RECORDS_SUCCESS'
export const GET_RECORDS_FAILED = 'dnsrecords/GET_RECORDS_FAILED'
export const UPDATE_RECORDS = 'dnsrecords/UPDATE_RECORDS'
export const UPDATE_RECORDS_SUCCESS = 'dnsrecords/UPDATE_RECORDS_SUCCESS'
export const UPDATE_RECORDS_FAILED = 'dnsrecords/UPDATE_RECORDS_FAILED'
export const USE_MX_PRESET = 'dnsrecords/USE_MX_PRESET'
export const USE_MX_PRESET_SUCCESS = 'dnsrecords/USE_MX_PRESET_SUCCESS'
export const USE_MX_PRESET_FAILED = 'dnsrecords/USE_MX_PRESET_FAILED'

export const DOMAIN_NOT_FOUND_ERRMSG = 'Domain not found.'
const HTTP_STATUS_OKAY = 200
const HTTP_STATUS_NOT_FOUND = 404

export const fetchDnsRecords = domain => dispatch => {
  dispatch({ type: GET_RECORDS })
  return api.get(`/domains/${domain}/records`)
    .then(res => {
      const maybeStatus = get(res, 'status')
      const maybeItems = get(res, 'items')
      const items = Array.isArray(maybeItems)
        ? maybeItems
        : maybeStatus === HTTP_STATUS_OKAY
          ? []
          : null

      if (!items)
        throw new Error(maybeStatus === HTTP_STATUS_NOT_FOUND
          ? DOMAIN_NOT_FOUND_ERRMSG
          : 'Fetch DNS Records failed')

      dispatch({ type: GET_RECORDS_SUCCESS, payload: { dnsRecords: items } })
      return res
    })
    .catch(err => {
      dispatch({ type: GET_RECORDS_FAILED })
      throw err
    })
}

const UPDATE_RECORDS_FAILED_NOTIF = {
  title: 'Failed to update DNS records',
  content: 'Sorry, please check your connection, try again, or contact support.'
}

export const updateDnsRecords = (domain, dnsRecords) => dispatch => {
  dispatch({ type: UPDATE_RECORDS })

  return api.put(`/domains/${domain}/records`, dnsRecords)
    .then(res => {
      const isOk = get(res, 'status') === 'OK'
      const errMsg = get(
        res,
        'error.message',
        UPDATE_RECORDS_FAILED_NOTIF.message)
      if (!isOk) throw new Error(errMsg)
      dispatch({ type: UPDATE_RECORDS_SUCCESS, payload: { dnsRecords } })
      return res
    })
    .catch(err => {
      toast.error({
        ...UPDATE_RECORDS_FAILED_NOTIF,
        content: err.toString()
      })
      dispatch({ type: UPDATE_RECORDS_FAILED })
      throw err
    })
}

export const addDnsRecord = (domain, dnsRecord) => (dispatch, getState) => {
  const records = [...getDnsRecords(getState()), dnsRecord]
  return updateDnsRecords(domain, records)(dispatch)
}

export const editDnsRecord = (domain, dnsRecord, recordIndex) => (dispatch, getState) => {
  const records = getDnsRecords(getState())
  const updatedRecords = set([recordIndex], dnsRecord, records)
  return updateDnsRecords(domain, updatedRecords)(dispatch)
}

export const deleteDnsRecord = (domain, recordIndex) => (dispatch, getState) => {
  const records = getDnsRecords(getState())
  const toDelete = records[recordIndex]
  const updatedRecords = records.filter(r => r !== toDelete)
  return updateDnsRecords(domain, updatedRecords)(dispatch)
}

export const useMXPreset = (domain, preset) => dispatch => {
  dispatch({ type: USE_MX_PRESET })
  const FALLBACK_ERR = 'Failed to use MX record preset. Please check your connection, try again, or contact support.'
  return api.put(`/domains/${domain}/mail/${preset}`)
    .then(({ status }) => {
      if (status === 'OK')
        return api.get(`/domains/${domain}/records`)
      else
        throw new Error(FALLBACK_ERR)
    })
    .then(res => {
      const maybeStatus = get(res, 'status')
      const maybeItems = get(res, 'items')
      const items = Array.isArray(maybeItems)
        ? maybeItems
        : maybeStatus === HTTP_STATUS_OKAY
          ? []
          : null

      const FALLBACK_ERR = 'Failed to use MX record preset. Please check your connection, try again, or contact support.'

      if (!items)
        throw new Error(maybeStatus === HTTP_STATUS_NOT_FOUND
          ? DOMAIN_NOT_FOUND_ERRMSG
          : FALLBACK_ERR)

      dispatch({ type: USE_MX_PRESET_SUCCESS, payload: { dnsRecords: items } })
      return res
    })
    .catch(err => {
      dispatch({ type: USE_MX_PRESET_FAILED })
      throw err
    })
}
