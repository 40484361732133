/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "no-return-assign": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import EventListener from 'react-event-listener'

import Portal from '../internal/Portal'
import BodyScrollLock from '../internal/BodyScrollLock'

import Mask from '../Mask'
import Paper from '../Paper'
import Toolbar from '../Toolbar'

import './dialog.less'

class Dialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    className: PropTypes.string,
    title: PropTypes.any,
    modal: PropTypes.bool,
    onRequestClose: PropTypes.func,
    toolbar: PropTypes.any // TODO
  };

  static defaultProps = {
    modal: false
  };

  constructor (props) {
    super(props)

    this.state = { open: props.open }
  }

  componentDidMount () {
    if (this.props.open)
      this.open()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.open == this.props.open) return

    if (nextProps.open)
      this.open()
    else
      this.close()
  }

  open () {
    this.setState({ open: true, hasOpened: false })
    setTimeout(() => this.setState({ hasOpened: true }))
  }

  close () {
    this.setState({ hasOpened: false, closing: true })
    setTimeout(() => this.setState({ open: false, closing: false }), 500)
  }

  componentDidUpdate () {
    this.position()
  }

  handleResize = () => {
    this.position()
  };

  position = () => {};

  handleMaskClick = () => {
    if (this.props.modal) return

    this.props.onRequestClose && this.props.onRequestClose()
  };

  renderDialog = () => {
    const { title, toolbar, className } = this.props
    const { open, closing, hasOpened } = this.state

    let ToolbarEl
    if (toolbar)
      ToolbarEl = (Array.isArray(toolbar) && toolbar.length > 0) ? <Toolbar>{toolbar}</Toolbar> : toolbar

    let TitleEl
    if (React.isValidElement(title))
      TitleEl = React.cloneElement(title, {
        className: classNames('decor', 'decorDialog-title', title.props.className)
      })
    else
      TitleEl = (<div className='decor decorDialog-title'>{title}</div>)

    return (
      <div ref={ ct => this.ct = ct} className={classNames('decor decorDialog-ct', className, { 'decorDialog-open': open && !closing })}>

        {open &&
          <EventListener
            target='window'
            onResize={this.handleResize}
          />
        }

        {(open && !closing) && <BodyScrollLock/>}

        <Mask onClick={this.handleMaskClick}/>
        {this.state.open &&
          <div ref={ dialog => this.dialog = dialog} className={classNames('decor decorDialog', { 'decorDialog-hasTitle': title, 'decorDialog-hasOpened': hasOpened })}>
            <Paper zDepth={2}>
              {title && TitleEl}

              <div ref={ db => this.dialogBody = db} className='decor decorDialog-body' onResize={this.handleResize}>
                {this.props.children}
              </div>

              {toolbar && ToolbarEl}
            </Paper>
          </div>
        }
      </div>
    )
  };

  render () {
    return (
      <Portal
        open={true}
        render={this.renderDialog}
      />
    )
  }
}

export default Dialog
