import React from 'react'
import { Heading, Box, Text, Button } from '@realsoftworks/decor'
import DataView from 'search/components/DataView'
import { Numeric, DateTime } from 'common/format'
import { orNull } from 'common/util/renderHelpers'

const LowerDetails = ({ property }) => {
  const summary = property?.summary || {}
  const buildingDetail = property?.buildingDetail || {}
  const landDetail = property?.landDetail || {}
  const lastSale = property?.lastSale
  const distressed = summary?.distressedCriteria || {}
  const lsRecInfo = lastSale?.recordingInfo
  const hasLSBuyerOrSeller = !!(lastSale && (lastSale.buyer || lastSale.seller))
  const lsBuyer = lastSale?.buyer || {}
  const lsBuyerNames = lsBuyer?.names || []
  const lsSeller = lastSale?.seller
  const lsSellerNames = lsSeller?.names || []
  const ownerInfo = [
    distressed.isVacant,
    distressed.isHomestead,
    distressed.isSenior
  ]
  const hasOwnerInfoTruthyValue = ownerInfo.some(oi => oi)

  return (
    <Box py={2} display='flex' flexWrap='wrap' width='100%'>
      {hasOwnerInfoTruthyValue && (
        <Box
          width='100%'
          display='flex'
          flexWrap='wrap'
          alignItems='center'
          justifyContent='center'
          p={1}
        >
          <Text p={2} color='primary.500' fontSize={1}>
            Ownership Info:
          </Text>
          <Box p={1} display='flex' flexWrap='wrap' justifyContent='center'>
            {[
              [distressed.isVacant, 'Vacant'],
              [distressed.isHomestead, 'Homestead'],
              [distressed.isSenior, 'Senior']
            ]
              .filter(([value]) => value)
              .map(([value, label]) => (
                <Box p={1} key={label}>
                  <Button
                    as='div'
                    sizeVariant='small'
                    variant='primary'
                    css='cursor: default;'
                  >
                    {label}
                  </Button>
                </Box>
              ))}
          </Box>
        </Box>
      )}

      <Box width='100%' display='flex' flexWrap='wrap'>
        {/* Property Characteristics */}
        <Box
          as='section'
          p={3}
          flex='1 1 33.333%'
          minWidth='240px'
          maxWidth='480px'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Heading
            as='h2'
            color='primary.500'
            textAlign='center'
            pb={1}
            style={{ fontSize: 16 }}
          >
            Property Characteristics
          </Heading>

          <DataView
            shouldRemoveEmptyData data={{
              'Total Rooms': buildingDetail.totalRooms,
              Bedrooms: summary.beds,
              'Baths Full': summary.baths?.full,
              'Baths Half': summary.baths?.half,
              'Garage Spaces': buildingDetail.garageSpaces,
              'Plumbing Fixtures Count': buildingDetail.plumbingFixtures,
              Fireplaces: buildingDetail.fireplaces,
              'Heating Type': buildingDetail.heatingType,
              'Heating Fuel Type': buildingDetail.heatingFuelType,
              'Interior Wall Type': buildingDetail.interiorWallType,
              'Basement Type': buildingDetail.basementType,
              'Roof Type': buildingDetail.roofType,
              'Roof Cover Type': buildingDetail.roofCoverType,
              'Sewer Type': buildingDetail.sewerType,
              'Water Source': buildingDetail.waterSource,
              'No. of Buildings': buildingDetail.numberOfBuildings,
              'No. of Stories': buildingDetail.numberOfStories
            }}
          />
        </Box>

        {/* Land Info */}
        <Box
          as='section'
          p={3}
          flex='1 1 33.333%'
          minWidth='240px'
          maxWidth='480px'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Heading
            as='h2'
            color='primary.500'
            textAlign='center'
            pb={1}
            style={{ fontSize: 16 }}
          >
            Land Info
          </Heading>

          <DataView
            shouldRemoveEmptyData data={{
              APN: summary.APN,
              'Property Class': landDetail.propertyClass,
              'Property Type': landDetail.propertyType,
              'Land Use Code': landDetail.countyLandUseCode,
              'Census Tract': landDetail.censusTract,
              'Lot Size Acres': orNull(landDetail.lotAreaAcres, v =>
                parseFloat(v).toFixed(3)),
              'Lot Area Sqft': orNull(landDetail.lotArea, v => (
                <Numeric>{parseFloat(v).toFixed(0)}</Numeric>)),
              Block: landDetail.block,
              Lot: landDetail.lot,
              'Legal Unit': landDetail.legalUnit,
              Subdivision: landDetail.subdivision
            }}
          />
        </Box>

        {/* Building Size */}
        <Box
          as='section'
          p={3}
          flex='1 1 33.333%'
          minWidth='240px'
          maxWidth='480px'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Heading
            as='h2'
            color='primary.500'
            textAlign='center'
            pb={1}
            style={{ fontSize: 16 }}
          >
            Building Size
          </Heading>

          <DataView
            shouldRemoveEmptyData data={{
              'Building Area': orNull(buildingDetail.buildingArea, v => (
                <Numeric>{v}</Numeric>)),
              'Living Area': orNull(buildingDetail.livingArea, v => (
                <Numeric>{v}</Numeric>)),
              'Basement Area': orNull(buildingDetail.basementArea, v => (
                <Numeric>{v}</Numeric>)),
              'Garage Area': orNull(buildingDetail.garageArea, v => (
                <Numeric>{v}</Numeric>))
            }}
          />
        </Box>

        {/* Last Arm's Length Sale Information */}
        {lastSale && (
          <Box
            as='section'
            p={3}
            flex='1 1 33.333%'
            minWidth='300px'
            maxWidth='480px'
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <Heading
              as='h2'
              color='primary.500'
              textAlign='center'
              pb={1}
              style={{ fontSize: 16 }}
            >
              Last Arm&rsquo;s Length Sale Information
            </Heading>

            <DataView
              shouldRemoveEmptyData data={{
                'Sale Date': orNull(lastSale.saleDate, v => (
                  <DateTime format='MM/DD/YYYY'>{v}</DateTime>)),
                'Transaction Type': orNull(lastSale.transactionType)
              }}
            />

            {lsRecInfo && (
              <>
                <Text pt={3} pb={1} fontSize={0} textAlign='center'>
                  Recording Information
                </Text>
                <DataView
                  shouldRemoveEmptyData data={{
                    'Recording Date': orNull(lsRecInfo.recordingDate, v => (
                      <DateTime format='MM/DD/YYYY'>{v}</DateTime>)),
                    'Document Type': orNull(lsRecInfo.documentType),
                    'Document Number': orNull(lsRecInfo.documentNumber)
                  }}
                />
              </>
            )}

            {hasLSBuyerOrSeller && (
              <>
                <Text pt={3} pb={1} fontSize={0} textAlign='center'>
                  Buyer/Seller Information
                </Text>
                <DataView
                  shouldRemoveEmptyData data={{
                    ...(lsBuyerNames.reduce((acc, n, i) =>
                      ({
                        ...acc,
                        [`Buyer ${i + 1} Name`]: orNull(n)
                      }), {})),
                    'Buyer Mailing Address': orNull(lsBuyer.address, v => {
                      const { line1, city, state, zip } = v
                      if (!line1 && !city && !state && !zip) return null
                      return (
                        <>
                          {line1}{line1 && <br />}
                          {[city, state, zip].join(' ')}
                        </>
                      )
                    }),
                    ...(lsSellerNames.reduce((acc, n, i) =>
                      ({
                        ...acc,
                        [`Seller ${i + 1} Name`]: orNull(n)
                      }), {})),
                    'Seller Mailing Address': orNull(lsSeller.address, v => {
                      const { line1, city, state, zip } = v
                      if (!line1 && !city && !state && !zip) return null
                      return (
                        <>
                          {line1}{line1 && <br />}
                          {[city, state, zip].join(' ')}
                        </>
                      )
                    })
                  }}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default LowerDetails
