import React from 'react'
import { Box } from '@realsoftworks/decor'
import styled from 'styled-components'
import { SIDEBAR_WIDTH } from 'app/TopBar/Menu/Sidebar'
import { LISTS_SIDEBAR_WIDTH } from './Dashboard/ListsSidebar'
import { connect } from 'react-redux'
import { selectLists } from 'lists/selectors'

const FixedContainer = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
width: ${p => p.hasList
    ? `calc(100% - ${LISTS_SIDEBAR_WIDTH + SIDEBAR_WIDTH}px)`
    : `calc(100% - ${SIDEBAR_WIDTH}px)`};
bottom: 0;
right: 0%;
background-color: white;
box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const withConnect = connect(state => ({ lists: selectLists(state) }))
const FixedFooter = withConnect(({ lists, children, ...props }) => (
  <FixedContainer hasList={lists && lists.length} {...props}>
    {children}
  </FixedContainer>
))

export default FixedFooter
