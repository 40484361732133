import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchLists } from 'lists/actions'
import { selectLists } from 'lists/selectors'
import { LISTS } from 'drivingfordollars/propTypes'
import curry from 'lodash/function/curry'
import BigLoader from 'common/BigLoader'

import {
  bool,
  object,
  func,
  number
} from 'prop-types'

const withLists = curry((
  opts,
  BaseComponent
) => {
  const { params = {} } = opts || {}

  const WrappedComponent = ({
    lists,
    count,
    error,
    fetchLists,
    ...props
  }) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      setIsLoading(true)
      fetchLists({ ...params, type: 'drive' }).then(() => {
        setIsLoading(false)
      })
    }, [])

    return (
      <BigLoader loading={isLoading}>
        <BaseComponent
          lists={lists}
          count={count}
          isLoading={isLoading}
          error={error}
          {...props}
        />
      </BigLoader>
    )
  }

  BaseComponent.propTypes = {
    ...listsPropTypes,
    ...(BaseComponent.propTypes || {})
  }

  return withState(WrappedComponent)
}, 2)

export default withLists

const listsPropTypes = {
  count: number,
  isLoading: bool.isRequired,
  hasLoaded: bool.isRequired,
  error: object,
  fetchLists: func.isRequired,
  lists: LISTS
}

const withState = connect(
  state => {
    const lists = selectLists(state, 'drive')

    return { lists }
  },
  { fetchLists }
)
