import React, { useCallback, useMemo } from 'react'
import { NumberInput, Label, Box, DateInput } from '@realsoftworks/decor'
import FilterFooter from '../../FilterFooter'
import MonetaryRange from 'leadlists/components/filters/MonetaryRange'
import AccordionFilterSection, { useAccordionFilterSection } from 'leadlists/components/AccordionFilterSection'
import GeoSection from 'leadlists/components/Sellers/components/SellerFilters/GeoSection'
import MiscSection from 'leadlists/components/Sellers/components/SellerFilters/MiscSection'

export default function LenderFilters ({ filters, onChange, onChangeFilter, showResults, counts, data }) {
  const {
    minLent,
    maxLent,
    minLoans,
    recentLoan
  } = filters

  const onChangeCount = useCallback((e, v) => onChangeFilter('minLoans', e.target.value), [onChange])
  const onChangeRecentLoan = useCallback((e, v) => onChangeFilter('recentLoan', v), [onChange])
  const {
    openSection,
    toggleSection,
    toggleSectionMemoedFactory
  } = useAccordionFilterSection()

  return (
    <>
      <Box maxWidth={1000} pb={160}>
        <GeoSection onChange={onChange} />

        <AccordionFilterSection
          mt={5}
          heading='Financials'
          toggleSection={toggleSection('financials')}
          isSectionOpen={openSection === 'financials'}
        >
          <Box display='flex' mx={-3}>
            <Box flex='1' mx={3} mb={2}>
              <Box mb={4}>
                <Label mr={2}>Lent Amount</Label>
                <MonetaryRange
                  value={useMemo(() =>
                    ({ min: minLent, max: maxLent }),
                  [minLent, maxLent])}
                  onChange={useCallback(value => {
                    onChange({
                      minLent: value?.min,
                      maxLent: value?.max
                    })
                  }, [onChange])}
                  placeholder='Any'
                />
              </Box>
              <Box mb={4}>
                <Label mr={2}>Min Loan Count</Label>
                <NumberInput onChange={onChangeCount} value={minLoans} width='80px' placeholder='Any' />
              </Box>
            </Box>
            <Box flex='2' mx={4}>
              <Box display='flex' flexDirection='column' mb={2}>
                <Label>Recent Loan After</Label>
                <DateInput value={recentLoan} onChange={onChangeRecentLoan} width='130px' placeholder='Any' />
              </Box>
            </Box>
          </Box>
        </AccordionFilterSection>

        <MiscSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSectionFactory={toggleSectionMemoedFactory}
        />

        <FilterFooter
          filters={filters}
          data={data}
          counts={counts}
          showResults={showResults}
          setFilters={onChange}
        />
      </Box>
    </>
  )
}
