import React from 'react'
import { useDebounce } from './util/hooks'

const DebounceInput = ({ children, value, onChange, ...props }) => {
  const [inputValue, setInputValue] = React.useState(value)
  const onInputChange = React.useCallback(e => setInputValue(e.target ? e.target.value : e), [setInputValue])
  const debouncedValue = useDebounce(inputValue)

  // finish debounce
  React.useEffect(
    () => {
      if (debouncedValue !== value)
        onChange(debouncedValue)
    },
    [debouncedValue, onChange]
  )

  return React.cloneElement(children, { value: inputValue, onChange: onInputChange })
}

export default DebounceInput
