/* eslint "eqeqeq": "warn" */

'use strict'

import $ from 'jquery'

function AjaxError (jqXHR, textStatus, errorThrown) {
  this.name = 'AjaxError'
  this.message = textStatus
  this.jqXHR = jqXHR
  this.errorThrown = errorThrown
}
AjaxError.prototype = new Error()
AjaxError.prototype.constructor = AjaxError;

// Wrap jQuery ajax in a promise
(function ($) {
  function decorateAsJQuery (promise) {
    promise.done = function (fn) {
      return decorateAsJQuery(promise.then(fn))
    }
    promise.fail = function (fn) {
      return decorateAsJQuery(promise.then(null, fn))
    }
    promise.complete = function (fn) {
      return decorateAsJQuery(promise.then(fn, fn))
    }
    promise.always = function (fn) {
      return decorateAsJQuery(promise.then(fn, fn))
    }
    return promise
  }
  var jqAjax = $.ajax
  $.ajax = function ajax () {
    var ts = Date.now()

    var args = Array.prototype.slice.call(arguments)
    var jqPromise = jqAjax.apply(this, args)
    var promise = new Promise(function (resolve, reject) {
      jqPromise.then(function (data, textStatus, jqXHR) {
        data._timestamp = ts
        resolve(data)
      }, function (jqXHR, textStatus, errorThrown) {
        reject(new AjaxError(jqXHR, textStatus, errorThrown))
      })
    })
    return decorateAsJQuery(promise)
  }
})($)

var csrf = null
function setXSRFHeader (config) {
  if (!csrf)
    csrf = App.token

  config.headers = config.headers || {}
  config.headers['X-XSRF-TOKEN'] = csrf
}

function ajax (url, config) {
  setXSRFHeader(config)

  return $.ajax(url, config).catch(function (error) {
    if (error.jqXHR.status == 401)
      Router.doSessionTimeout()

    var ret = error.jqXHR.responseJSON
    if (error.jqXHR.status == 500)
      ret.failed = true

    throw ret || error
  })
}

ajax.get = function (url, config) {
  config = config || {}
  config.dataType = config.dataType || 'json'
  config.type = 'GET'
  return ajax(url, config)
}

ajax.post = function (url, config) {
  config = config || {}
  config.dataType = config.dataType || 'json'
  config.type = 'POST'
  return ajax(url, config)
}

ajax.put = function (url, config) {
  config = config || {}
  config.dataType = config.dataType || 'json'
  config.type = 'PUT'
  return ajax(url, config)
}

export default ajax
