/* eslint "eqeqeq": "warn", "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import { selectors as leadListSelectors } from '../index.js'
import RichDropdown from './RichDropdown'

class MarketSelector extends React.Component {
  getList = markets => {
    const listItems = markets.map(marketData => {
      let iteration = 0
      const counties = marketData.counties.reduce(function (a, b) {
        iteration++
        if (iteration === 1)
          return a + b.name
        else
          return a + ', ' + b.name
      }, '')
      return {
        id: marketData._id,
        header: marketData.name + ', ' + marketData.state[0],
        content: counties
      }
    })
    return listItems
  };

  onSelect = ({ id }) => {
    this.props.onChange(this.props.markets.find(v => (v.id || v._id) == id))
  };

  render () {
    const markets = this.props.markets
    const listItems = this.getList(markets)

    const marketName = this.props.market ? this.props.market.name + ', ' + this.props.market.state.join('/') : 'SELECT MARKET'
    return (
      <div className='market-selector'>
        <span className='market-text'>MARKET:</span><span className='selected-market-name'>{marketName}</span>
        <RichDropdown buttonHtml={<i className='fa fa-sort-desc fa-2x' aria-hidden='true'/>} items={listItems} onSelect={this.onSelect}/>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  markets: leadListSelectors.getMarkets(state)
})
MarketSelector = connect(mapStateToProps)(MarketSelector)
export default MarketSelector
