import get from 'lodash/object/get'

/**
 * Try doing lodash get on each path of paths array, return the first success
 * get, otherwise return the fallback
 * @param {Object} object
 * @param {string[]} paths
 * @param {*} fallback
 */
const getOneOf = (object, [path, ...paths], fallback) => {
  const maybeValue = get(object, path)
  return maybeValue === undefined && paths.length > 0
    ? getOneOf(object, paths, fallback)
    : maybeValue === undefined
      ? fallback
      : maybeValue
}

export default getOneOf
