import React from 'react'
import { arrayOf, shape, func, oneOfType, string, bool } from 'prop-types'
import { Box, Card, Paragraph, Button } from '@realsoftworks/decor'

const StepContent = ({ steps, onboarding }) =>
  steps.map(({ custom, text, cta = {}, isCompleted }, i) => renderCustom(custom, { key: i, onboarding }) || (
    <Card key={i} display='flex' alignItems='flex-start' flexWrap='flex' pt={i && 5}>
      <Box alignSelf='center' flex={[undefined, 1]} pr={[3, 5]} width={['100%', 'auto']}>
        {renderCustom(text.custom) || (
          <Paragraph mb={0}>{text}</Paragraph>
        )}
      </Box>
      <Box width={160}>
        {(cta.custom && !isCompleted)
          ? renderCustom(cta.custom, { isCompleted, onboarding })
          : (cta.url || isCompleted)
            ? (
              isCompleted
                ? (<Button disabled variant='primary' width='100%'>Completed</Button>)
                : (
                  <Button href={cta.url} variant='primary' width='100%' as='a'>
                    {cta.text}
                  </Button>
                )
            )
            : null}
      </Box>
    </Card>
  ))

export default StepContent

StepContent.propTypes = {
  steps: arrayOf(shape({
    custom: func,
    text: oneOfType([
      string,
      shape({ custom: func })
    ]),
    cta: shape({
      custom: func,
      url: string,
      text: string
    }),
    isCompleted: bool
  })).isRequired
}

const renderCustom = (Custom, props = {}) => Custom && (<Custom {...props} />)
