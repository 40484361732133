import { combineReducers } from 'redux'
import { FETCH_DOMAINS_SUCCESS, FETCH_DOMAIN_SUCCESS, FETCH_DOMAIN_PRODUCTS_SUCCESS, FETCH_ACCOUNT_SUCCESS, UPDATE_DOMAIN_SUCCESS } from '../actions'
import manage from './manage'

const list = (state = null, { type, meta, payload }) => {
  switch (type) {
    case FETCH_DOMAINS_SUCCESS:
      return payload.result.items
  }

  return state
}

const entities = (state = {}, { type, meta, payload }) => {
  switch (type) {
    case FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        ...payload.entities.domain
      }

    case FETCH_DOMAIN_SUCCESS:
    case UPDATE_DOMAIN_SUCCESS:
      return {
        ...state,
        [payload.id]: payload
      }
  }

  return state
}

const orders = (state = null) =>
  state

const products = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_DOMAIN_PRODUCTS_SUCCESS:
      return payload
  }

  return state
}

const account = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_ACCOUNT_SUCCESS:
      return payload
  }

  return state
}

export default combineReducers({
  list,
  entities,
  orders,
  products,
  account,
  manage
})
