import React from 'react'
import Pill from '@realsoftworks/decor/dist/components/Pill'

const SmallPill = props => (
  <Pill
    size='small'
    variant='default'
    css={`
      min-height: 20px;
      border-radius: 9999px;
      font-size: 13px;
      ${props.css || ''}
    `}
    {...props}
  />
)

export default SmallPill
