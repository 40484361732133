import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { Box } from '@realsoftworks/decor'
import * as api from 'common/api'
import BigLoader from 'common/BigLoader'
import { DRIVES_ENDPOINT, LISTS_ENDPOINT } from './const'
import Empty from './pages/Empty'
import Dashboard from './pages/Dashboard'
import ListDetails from './pages/ListDetails'
import AllDrives from './pages/AllDrives'
import DriveDetails from './pages/DriveDetails'
import throwAny from 'common/util/throwAny'

const RouteHandler = ({ match }) => {
  const { isEmpty, isLoading } = routeHandlerHook()

  return (
    <DocumentTitle title='Driving for Dollars'>
      <>
        {!isLoading && isEmpty && <Empty />}

        {isLoading && (
          <Box display='flex' justifyContent='center' py={6}>
            <BigLoader />
          </Box>
        )}

        <Box display={(isLoading || isEmpty) ? 'none' : ''}>
          <Switch>
            <Route path={match.url} exact component={Dashboard} />
            <Route path={`${match.url}/drives`} exact component={AllDrives} />
            <Route path={`${match.url}/drives/:id`} exact component={DriveDetails} />
            <Route path={`${match.url}/lists`} component={ListDetails} />
          </Switch>
        </Box>
      </>
    </DocumentTitle>
  )
}

export default RouteHandler

const routeHandlerHook = () => {
  const [isEmpty, setIsEmpty] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const [drives, lists] = await Promise.all([
          api.get(`${DRIVES_ENDPOINT}?limit=1`)
            .catch(error => ({ error })),
          api.get(`${LISTS_ENDPOINT}?limit=1&type=drive`)
            .catch(error => ({ error }))
        ])

        const isEmpty = !(drives.count || lists.count)
        const error = drives.error || lists.error

        if (isEmpty && error)
          throwAny(error)

        // We only show empty when both drives and drive lists
        // are empty. If either request has error, we're not
        // 100% sure so let's just let the route view handle
        // respective error/empty data
        setIsEmpty(isEmpty)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return { isEmpty, isLoading }
}
