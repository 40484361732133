import React from 'react'
import { Box, Heading, Paragraph } from '@realsoftworks/decor'

import BigLoader from 'common/BigLoader'

export default function ResultLayout ({ loading, resultComponent }) {
  return (
    <BigLoader loading={loading}>
      <Box mx='auto' flex='1 1 0' maxWidth={1010} mb={9}>
        <Heading size={3} pb={3}>Preview List</Heading>
        <Paragraph>Previewing the first page of results. Save the list to view the rest.</Paragraph>
        {resultComponent}
      </Box>
    </BigLoader>
  )
}
