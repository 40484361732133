import React from 'react'
import styled from 'styled-components'
import { Box, Tooltip } from '@realsoftworks/decor'

const ProviderIcon = styled(Box)`
  display: inline-block;
  width: 24px;
  height: 24px;

  ${props => props.opacity && `opacity: ${props.opacity};`}

  img {
    width: 100%;
  }
`

const SkipGenieIcon = ({ ...props }) => (
  <Tooltip content='SkipGenie'>
    <ProviderIcon {...props}>
      <img src='https://propelio-cloudfront.s3-us-west-2.amazonaws.com/skip-genie-icon.png' />
    </ProviderIcon>
  </Tooltip>
)

const BatchSkipIcon = ({ ...props }) => (
  <Tooltip content='Batch Skiptrace'>
    <ProviderIcon opacity={0.5} {...props}>
      <img src='https://propelio-cloudfront.s3-us-west-2.amazonaws.com/bst-logo-teal.png' />
    </ProviderIcon>
  </Tooltip>
)

const ProviderIcons = ({ skiptraces, ...props }) => {
  if (!skiptraces) return null

  const providers = skiptraces.reduce((accum, curr) => {
    if (curr.provider)
      accum[curr.provider] = true

    return accum
  }, {})

  return (
    <Box {...props}>
      <Box display='flex' mx={-1}>
        {(providers.skipGenie || providers.legacy) && <SkipGenieIcon mx={1} />}
        {providers.reiGroup && <BatchSkipIcon mx={1} />}
      </Box>
    </Box>
  )
}

export default ProviderIcons

export { SkipGenieIcon, BatchSkipIcon }
