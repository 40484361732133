/* eslint "react/display-name": "warn" */
export default ({ source }) => {
  var img

  switch (source) {
    case 'ntreis':
    case 'ntreis-v2':
      img = '/img/ntreis.jpg'
      break

    case 'maris':
      img = 'https://s3-us-west-2.amazonaws.com/propelio-cloudfront/MarisIcon16.jpg'
      break

    case 'gtar':
      img = 'https://propelio-cloudfront.s3-us-west-2.amazonaws.com/gtar-logo.png'
      break

    case 'gmar':
      img = 'https://propelio-cloudfront.s3-us-west-2.amazonaws.com/gmar-logo-web-black-sm.png'
      break
  }

  if (!img)
    // TODO react 15 - https://github.com/facebook/react/issues/5355
    return <span />

  return <img src={img} className='mls-logo' />
}
