
export const FETCH_INFO = 'billing/FETCH_INFO'
export const FETCH_INFO_SUCCESS = 'billing/FETCH_INFO_SUCCESS'
export const FETCH_INFO_FAILURE = 'billing/FETCH_INFO_FAILURE'

export const FETCH_PLANS = 'billing/FETCH_PLANS'
export const FETCH_PLANS_SUCCESS = 'billing/FETCH_PLANS_SUCCESS'
export const FETCH_PLANS_FAILURE = 'billing/FETCH_PLANS_FAILURE'

export const CHANGE_PLAN = 'billing/CHANGE_PLAN'
export const CHANGE_PLAN_SUCCESS = 'billing/CHANGE_PLAN_SUCCESS'
export const CHANGE_PLAN_FAILURE = 'billing/CHANGE_PLAN_FAILURE'

export const CANCEL_PLAN = 'billing/CANCEL_PLAN'
export const CANCEL_PLAN_SUCCESS = 'billing/CANCEL_PLAN_SUCCESS'
export const CANCEL_PLAN_FAILURE = 'billing/CANCEL_PLAN_FAILURE'

export const UPDATE = 'billing/UPDATE'
export const UPDATE_SUCCESS = 'billing/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'billing/UPDATE_FAILURE'

export const FEATURES = {
  DEALALERTS: 'dealalerts',
  LEADS: 'leads',
  CMA: 'cma',
  SITES: 'sites',
  DRIVING: 'driving',
  PREMIUMLEADS: 'premiumleads'
}

export const PLAN_PRO = 'pro_post_launch_monthly'
export const END_TRIAL = 'billing/END_TRIAL'
export const END_TRIAL_SUCCESS = 'billing/END_TRIAL_SUCCESS'
export const END_TRIAL_FAILURE = 'billing/END_TRIAL_FAILURE'
