import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Tooltip } from '@realsoftworks/decor'
import { withRouter } from 'react-router-dom'
import { useCallback } from 'react'

const Link = styled.a`
  color: white;
  height: 32px;
  padding: 0px 12px;
  text-decoration: none;
  line-height: 32px;
  font-size: 24px;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: white;
  }

  &:hover {
    background-color: ${themeGet('colors.teal.100')};
    color: ${themeGet('colors.teal.900')};
  }
`

const TopLink = ({ history, isRouterLink, to, content, ...props }) => (
  <Tooltip content={content}>
    <Link
      href={isRouterLink ? '#' : to}
      onClick={useCallback(ev => {
        if (isRouterLink) {
          ev.preventDefault()
          history.push(to)
        }
      }, [isRouterLink])}
      {...props}
    />
  </Tooltip>
)

export default withRouter(TopLink)
