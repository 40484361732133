/* eslint "react/display-name": "warn" */
import React from 'react'
import qs from 'qs'
import Container from './Container'

const getBackPath = path => {
  const parts = path.split('/')
  parts.pop()
  return parts.join('/')
}

const backToIndex = (history, match) => {
  history.replace(getBackPath(match.path))
}

export default ({ match, history, location }) => {
  const goBack = React.useCallback(() => {
    backToIndex(history, match)
  })

  const queries = qs.parse(location.search.replace(/^\?/, ''))

  return <Container listId={match.params.id} selItemsId={queries.slist} onDelete={goBack} />
}
