/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import { selectors as contactSelectors } from '..'
import { connect } from 'react-redux'
import Rolodex from './Rolodex.react'
import ContactModal from './ContactModal.react'
import ContactTagManager from './ContactTagManager.react'
import { searchTags, clear } from '../actions'
import React from 'react'

class ContactsHandler extends React.Component {
  state = {
    ready: false,
    modalIsOpen: false
  };

  componentWillMount () {
    this.props.dispatch(searchTags())
    this.props.dispatch(clear())
    this.setState({ ready: false })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ ready: nextProps.isLoaded })
  }

  onStartCreate = () => {
    this.setState({
      modalIsOpen: true
    })
  };

  onEndCreate = () => {
    this.setState({
      modalIsOpen: false
    })
  };

  render () {
    const contactId = this.props.editContactId ? this.props.editContactId : null
    const title = contactId ? 'Edit Contact' : 'Create Contact'
    const modal = (<div id='overlay-target'>
      <ContactModal isOpen={this.state.modalIsOpen} title={title} contactId={contactId} onRequestClose={this.onEndCreate}/>
    </div>)
    return (
      <div id='contacts' className='index'>

        <div className='row'>
          <div className='col-md-9'>
            <div id='all-contacts' className='contact-list main-content'>
              <Rolodex isReady={this.state.ready} contacts={this.props.contacts} count={this.props.count} dispatch={this.props.dispatch}/>
            </div>
          </div>
          <div className='col-md-3 sidebar'>
            <div className='block block-create'>
              <div className='toolbar clearfix'>
                <a title='Edit Template' className='btn btn-primary btn-edit' href='/templates/view/contact'><span className='icon icon-template'/></a>
                <a
                  href='#' onClick={this.onStartCreate}
                  className='btn btn-success btn-lg btn-block'>
                  <span className='icon icon-plus'/> Create Contact
                </a>
              </div>
            </div>

            <div className='block block-manage-tags'>
              <h2>Tags</h2>
              <div id='contact-tags'>
                <ContactTagManager tags={this.props.tags} dispatch={this.props.dispatch}/>
              </div>
            </div>
          </div>
        </div>

        <div id='modal-contact' className='modal fade'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal' aria-hidden='true'>&times;</button>
                <h4 className='modal-title'><span className='action'>Edit</span> Contact</h4>
              </div>
              <div className='modal-body'>

              </div>
              <div className='modal-footer'>

              </div>

            </div>
          </div>
        </div>

        {modal}

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const tags = contactSelectors.getAllTags(state)
  const editContactId = null // selector
  const contacts = contactSelectors.getContactsWithTagsByReference(state, 'active')
  const count = contactSelectors.getCount(state)
  const isLoaded = contactSelectors.getIsLoaded(state)

  return {
    tags,
    editContactId,
    contacts,
    count,
    isLoaded
  }
}

ContactsHandler = connect(mapStateToProps, null)(ContactsHandler)
export default ContactsHandler
