/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn" */
import React from 'react'
import mapLoader from 'common/mapLoader'
import MapUtil from 'common/geocode'

import $ from 'jquery'

import stateList from 'common/states'

class AddressField extends React.Component {
  static defaultProps = {
    value: {
      line1: '',
      city: '',
      state: '',
      zip: ''
    },

    autocomplete: false
  };

  focus = () => {
    this.refs.line1.focus()
  };

  getValue = () => this.props.value || {};

  onFieldChange = e => {
    var v = Object.assign({}, this.getValue())
    var name = e.target.name
    var value = e.target.value
    v[name] = value

    if (this.props.onChange)
      this.props.onChange(v)
  };

  onBlur = e => {
    if (!this.blurring) {
      e.ignoreBlur = true
      if (e.target.name == 'state') {
        var dom = this.refs.state
        $(dom).typeahead('close')
      }
    } else { this.blurring = false }
  };

  onKeyDown = e => {
    if (e.key == 'Tab')
      if (e.shiftKey == true && e.target.name == 'line1')
        this.blurring = true
      else if (e.target.name == 'zip')
        this.blurring = true
  };

  render () {
    var value = this.getValue()

    return (
      <div className='address-field' onBlur={this.onBlur} onKeyDown={this.onKeyDown}>
        <div className='form-group'>
          <input type='text' className='form-control' name='line1' ref='line1' value={value.line1} onChange={this.onFieldChange} placeholder='Street Address'/>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-md-5'>
              <input type='text' className='form-control' name='city' value={value.city} onChange={this.onFieldChange} placeholder='City'/>
            </div>
            <div className='col-md-3'>
              <input type='text' ref='state' className='form-control' name='state' value={value.state} onChange={this.onFieldChange} placeholder='State'/>
            </div>
            <div className='col-md-4'>
              <input type='text' className='form-control' name='zip' value={value.zip} onChange={this.onFieldChange} placeholder='Zip'/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount () {
    var states = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // `states` is an array of state names defined in "The Basics"
      local: $.map(stateList, function (state) { return { value: state } })
    })

    // kicks off the loading/processing of `local` and `prefetch`
    states.initialize()

    var dom = this.refs.state
    $(dom).typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    },
    {
      name: 'states',
      displayKey: 'value',
      source: states.ttAdapter()
    })

    if (this.props.autocomplete)
      this.setupAutocomplete()
  }

  setupAutocomplete = () => {
    mapLoader().then(() => {
      var node = this.refs.line1
      this.autocomplete = new google.maps.places.Autocomplete(node, {
        fields: ['address_component', 'geometry']
      })
      this.autocomplete.addListener('place_changed', () => {
        var value = MapUtil.parsePlace(this.autocomplete.getPlace())
        if (value)
          this.props.onChange && this.props.onChange(value)
      })
    })
  };
}

export default AddressField
