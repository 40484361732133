import get from 'lodash/object/get'

const initState = {
  value: {},
  count: null,
  isLoading: true,
  error: null,
  hasLoaded: false
}

const justUseFetchReducer = (state, action, fetchReducer) =>
  fetchReducer(state, action)

export default (
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILED,
  extensionInitState = {},
  overridableReducer = justUseFetchReducer
) =>
  (state = { ...initState, ...extensionInitState }, action) => {
    const fetchReducer = (state, action) => {
      switch (action.type) {
        case FETCH:
          return {
            ...state,
            value: {},
            isLoading: true,
            hasLoaded: false,
            error: null
          }
        case FETCH_SUCCESS:
          return {
            ...state,
            value: action.payload.value,
            count: get(action.payload, ['count'], null),
            hasLoaded: true,
            isLoading: false
          }
        case FETCH_FAILED:
          return {
            ...state,
            isLoading: false,
            hasLoaded: false,
            error: action.payload.error
          }
        default:
          return state
      }
    }

    return overridableReducer(state, action, fetchReducer)
  }
