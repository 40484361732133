import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { func, shape, number, bool, instanceOf } from 'prop-types'
import get from 'lodash/object/get'
import { Card, Heading, Text } from '@realsoftworks/decor'
import { Numeric } from 'common/format'
import { fetchDrivesStats, fetchListsStats } from 'drivingfordollars/actions'
import { selectDrivesStats, selectListsStats } from 'drivingfordollars/selectors'
import FetchHandler from 'common/FetchHandler'
import Stats from 'drivingfordollars/components/Stats'
import Duration from 'drivingfordollars/components/Duration'

const DrivesStatsSection = ({
  fetchDrivesStats,
  fetchListsStats,
  distance,
  duration,
  trips,
  leadsCount,
  isLoading,
  error,
  ...props
}) => {
  const { hasLoaded } = drivesStatsSectionHook({
    fetchDrivesStats,
    fetchListsStats
  })

  const miles = get(distance, 'miles')

  return (
    <Card variant='padded' {...props}>
      <FetchHandler
        error={error}
        isLoading={!hasLoaded || isLoading}
        isEmpty={false}
        emptyText=''
      >
        <Heading textAlign='center'>
          Lifetime Statistics
        </Heading>

        <Stats
          mt={3} data={{
            distance: typeof miles !== 'number' ? '--' : (
              <>
                <Numeric decimals={0}>{miles}</Numeric>
                {' '}
                <Text color='neutral.500'>mi</Text>
              </>
            ),
            duration: <Duration>{duration}</Duration>,
            trips: typeof trips === 'number' ? trips : '--',
            leads: typeof leadsCount === 'number' ? leadsCount : '--'
          }}
        />
      </FetchHandler>
    </Card>
  )
}

DrivesStatsSection.propTypes = {
  fetchDrivesStats: func.isRequired,
  fetchListsStats: func.isRequired,
  distance: shape({ miles: number.isRequired }),
  duration: number,
  trips: number,
  leadsCount: number,
  isLoading: bool,
  error: instanceOf(Error)
}

const withState = connect(
  state => {
    const drivesStats = selectDrivesStats(state)
    const { distance, duration, trips } = drivesStats.value || {}

    const listsStats = selectListsStats(state)
    const leadsCount = get(listsStats.value, 'sources.drive')

    return {
      distance,
      duration,
      trips,
      leadsCount,
      isLoading: drivesStats.isLoading || listsStats.isLoading,
      error: drivesStats.error || listsStats.error
    }
  },
  { fetchDrivesStats, fetchListsStats }
)

export default withState(DrivesStatsSection)

const drivesStatsSectionHook = ({ fetchDrivesStats, fetchListsStats }) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          fetchDrivesStats(),
          fetchListsStats()
        ])

        setHasLoaded(true)
      } catch (e) {}
    })()
  }, [])

  return { hasLoaded }
}
