import React from 'react'
import AdornedInput from './AdornedInput'
import { NumberInput } from '@realsoftworks/decor'

const IDENTITY = () => {}

export default function DealValueInput ({ value, onChange = IDENTITY, ...props }) {
  const wrappedOnChange = React.useCallback(e => {
    let v = e.target.value
    if (v) {
      v *= 1000
      e.target.value = v
    }

    onChange(v)
  }, [onChange])

  let inputValue = value
  if (inputValue)
    inputValue /= 1000

  return (
    <AdornedInput
      width='140px'
      before='$'
      after='k'
      textAlign='right'
      inputComponent={NumberInput}
      value={inputValue}
      onChange={wrappedOnChange}
      {...props}
    />
  )
}
