/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe
} from 'react-stripe-elements'

import { Button, Box, Input, Text, toast } from '@realsoftworks/decor'
import { toSingleLine } from 'common/util/address'

import { getBillingInfo, isTrialing as getIsTrialing } from 'billing/selectors'
import { updateBillingInfo, fetchBillingInfo } from 'billing/actions'

import './billinginfodetail.less'

import LoadingIcon from 'common/LoadingIcon'
import { TrialText, EndTrialButton } from 'lists/components/ListDetail/components/TrialNotice'
import LoadingButton from 'common/LoadingButton'
import throwAny from 'common/util/throwAny'

class BillingInfo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editing: false,
      stripe: null,
      fetching: !props.billinginfo
    }
  }

  onEditClick = () => this.setState({ editing: true });
  onCancelClick = () => this.setState({ editing: false });
  update = card => this.props.update({ card })
    .then(action => {
      this.setState({ editing: false })
      if (!action.error && this.props.onSave)
        this.props.onSave()
    });

  componentWillMount () {
    if (!this.props.billinginfo)
      this.props.fetch()
        .catch(error => this.setState({ error }))
        .then(() => this.setState({ fetching: false }))

    if (!window.stripeJsCreated) {
      const stripeJs = document.createElement('script')
      stripeJs.src = 'https://js.stripe.com/v3/'
      stripeJs.async = true
      stripeJs.onload = () => {
        this.setState({
          stripe: window.Stripe(window.STRIPE_PUBLISH_KEY)
        })
      }

      if (document.body)
        document.body.appendChild(stripeJs)
    }
  }

  render () {
    const { billinginfo, isUpgradeCta, isTrialing, listId } = this.props
    const { editing, fetching } = this.state

    return (
      <Fragment>
        <div className='billing-info'>
          <StripeProvider stripe={this.state.stripe}>
            {fetching ? (
              <div className='loading-info'><LoadingIcon size={2}/></div>
            ) : !billinginfo ? (
              <BillingInfoForm
                update={this.update}
                isUpgradeCta={isTrialing && isUpgradeCta}
                billinginfo={billinginfo}
                listId={listId}
              />
            ) : editing ? (
              <BillingInfoForm
                onCancelClick={this.onCancelClick}
                update={this.update}
                isUpgradeCta={isTrialing && isUpgradeCta}
                billinginfo={billinginfo}
                listId={listId}
              />
            ) : (
              <Detail
                info={billinginfo}
                onEditClick={this.onEditClick}
                isUpgradeCta={isTrialing && isUpgradeCta}
                listId={listId}
              />
            )}
          </StripeProvider>
        </div>
      </Fragment>
    )
  }
}

BillingInfo = connect(
  state => ({
    billinginfo: getBillingInfo(state),
    isTrialing: getIsTrialing(state)
  }),
  {
    update: updateBillingInfo,
    fetch: fetchBillingInfo
  }
)(BillingInfo)

const Detail = ({ info, onEditClick, isUpgradeCta, listId }) => (
  <Fragment>
    <div className='billing-info-detail'>
      <Button css='float: right;' onClick={onEditClick}><i className='fa fa-gear'/> Edit</Button>
      <div className={'billing-info-detail-icon billing-info-detail-icon-' + info.brand.toLowerCase() }>
        <i className='fa fa-2x fa-credit-card'/>
      </div>
      <div>
        <span className='billing-info-detail-card'>{info.brand} •••• {info.last4}</span> <span className='billing-info-detail-exp'>Expires: {info.expiration}</span>
      </div>
      <div className='billing-info-detail-name'>{info.name}</div>
      <div className='billing-info-detail-address'>{toSingleLine(info.address)}</div>
    </div>
    {isUpgradeCta && <TrialText pt={3} listId={listId} />}
    <Box pt={3} display='flex' justifyContent='flex-end'>
      {isUpgradeCta && <EndTrialButton listId={listId} />}
    </Box>
  </Fragment>
)

const BillingInfoForm = ({
  onCancelClick,
  update,
  isUpgradeCta,
  billinginfo,
  listId
}) => (
  <div className='billing-info-form'>
    <Elements>
      <BillingInfoFormImpl
        onCancelClick={onCancelClick}
        update={update}
        isUpgradeCta={isUpgradeCta}
        billinginfo={billinginfo}
        listId={listId}
      />
    </Elements>
  </div>
)

class BillingInfoFormImpl extends React.Component {
  state = { complete: false };

  onUpdate = e => {
    this.setState({ error: e.error?.message || '' })
    this.setState({ complete: e.complete })
  };

  onNameChange = e => {
    this.setState({ name: e.target.value })
  };

  onSubmit = () =>
    this.saveBillingInfo()
      .catch(() => {
        toast.error({
          title: 'Failed to save payment info',
          content: 'Sorry, something went wrong while processing your request. Please make sure that you have entered a valid info, or get in touch with us by clicking the support button on lower right.'
        })
      })

  // Saves billing info and updates component's state accordingly. It also
  // ensures that it returns promise rejection on error to allow either this
  // component or EndTrialButton to handle error however they want.
  saveBillingInfo = () => {
    this.setState({ saving: true })
    return this.props.stripe.createToken({ name: this.state.name })
      .then(({ token, error }) => {
        if (error) throwAny(error)
        return this.props.update(token.id)
      })
      .then(() => this.setState({ saving: false }))
      .catch(error => {
        this.setState({ error, saving: false })
        throwAny(error)
      })
  }

  render () {
    const { onCancelClick, billinginfo, isUpgradeCta, listId } = this.props
    const isSaveDisabled = !this.state.complete ||
      !this.state.name ||
      this.state.saving
    const isCancelVisible =
      (isUpgradeCta && billinginfo && onCancelClick) ||
      onCancelClick

    return (
      <div className='billing-info-form-impl'>
        <form>
          <Box display='flex' mx={-2} flexWrap='wrap'>
            <Box flex='1 0 300px' px={2} py={1}>
              <Box css={`
                .propelio-card-element {
                  border: 1px solid #D3CEC4;
                  border-radius: 3px;
                  padding: 7px 8px;
                  height: 36px;
                }
              `}>
                {this.props.stripe ? (
                  <CardElement
                    className='propelio-card-element'
                    onChange={this.onUpdate}
                    hidePostalCode={true}
                    style={{
                      base: {
                        backgroundColor: 'white',
                        fontSize: '16px',
                        lineHeight: 1.25
                      }
                    }}
                  />
                ) : (
                  <div className='StripeElement loading'></div>
                )}
              </Box>
            </Box>
            <Box flex='1 0 300px' px={2} py={1}>
              <Input
                name='name'
                placeholder='Cardholder Name'
                onChange={this.onNameChange}
                lineHeight={1.25}
                width='100%'
              />
            </Box>
          </Box>
          {this.state.error && (
            <Text color='red.500' display='block' py={3}>
              {err2str(this.state.error)}
            </Text>
          )}
        </form>

        {isUpgradeCta && <TrialText pt={3} listId={listId} />}

        <Box display='flex' mt={3}>
          {isCancelVisible && (
            <Button
              disabled={this.state.saving}
              onClick={this.props.onCancelClick}
            >
              Cancel
            </Button>
          )}

          <Box flex='1 1 auto' />

          {isUpgradeCta ? (
            <EndTrialButton
              willAddBillingInfo
              onBeforeRequest={this.saveBillingInfo}
              listId={listId}
              disabled={isSaveDisabled}
            />
          ) : (
            <LoadingButton
              variant='primary'
              ml='auto'
              disabled={isSaveDisabled}
              onClick={this.onSubmit}
              loading={this.state.saving}
            >
              {this.state.saving ? 'Saving' : 'Save'}
            </LoadingButton>
          )}
        </Box>
      </div>

    )
  }
}

BillingInfoFormImpl = injectStripe(BillingInfoFormImpl)

export default BillingInfo

const err2str = e =>
  !e
    ? 'Encountered an error while processing your request.'
    : typeof e === 'string'
      ? e
      : typeof e.message === 'string'
        ? e.message
        : JSON.stringify(e)
