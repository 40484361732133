/* eslint "camelcase": "warn" */
import { combineReducers } from 'redux'

import property from './property'
import neighbors from './neighbors'
import custom_neighbors from './custom_neighbors'
import custom_neighbors_paging from './custom_neighbors_paging'
import sharedFilters from './sharedFilters'
export default combineReducers({
  property,
  neighbors,
  customNeighbors: custom_neighbors,
  customNeighborsPaging: custom_neighbors_paging,
  sharedFilters
})
