export const GENERIC_SEARCH_ERROR_NOTIF = {
  title: 'Search failed',
  content: 'Sorry, our system encountered an error while processing your request. Please try searching with another keyword.'
}

export const GENERIC_ERROR_NOTIF = {
  title: 'Something went wrong',
  content: 'Sorry, something may not work and it’s our fault. If you can’t access certain features, please try refreshing the page.'
}

export const VIEW_PROPERTY_ERROR_NOTIF = {
  title: 'Loading property failed',
  content: 'Sorry, our system encountered an error while processing your request.'
}

export const FAILED_TO_INIT_SEARCHINPUT_ERROR = {
  title: 'Failed to Load Search Input',
  content: 'Search feature may not work. Please try again later.'
}

export const PROPERTY_NOT_FOUND_NOTIF = {
  title: 'Property not found',
  content: 'We can’t find any property in our system that matches this address.'
}

export const INVALID_ADDRESS_ERROR_NOTIF = {
  title: 'Failed to lookup an address',
  content: 'That address doesn\'t appear to be valid.'
}

export const INIT_MAP_ERROR = {
  title: 'Failed to Load Map',
  content: 'Sorry, our system encountered an error while loading the map. Please try again later.'
}
