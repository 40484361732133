import React from 'react'
import FilterPill from '../FilterPill'
import ensureArray from 'common/util/ensureArray'
import { faCity, faEnvelope, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Text } from '@realsoftworks/decor'

const GeoPills = ({ color, filters, setFilters, county, city, zip }: {
  color: string
  filters?: { [key: string]: unknown }
  setFilters?: (filters: { [key: string]: unknown }) => void
  county?: string[]
  city?: string[]
  zip?: string[]
}) => {
  const geoFilters = [
    ...ensureArray(county).map(value => ({ value, category: 'county' })),
    ...ensureArray(city).map(value => ({ value, category: 'city' })),
    ...ensureArray(zip).map(value => ({ value, category: 'zip' })),
  ]

  return (
    <>
      {geoFilters.map(({ value, category }) => {
        const icon = category === 'county'
          ? faUniversity
          : category === 'city'
          ? faCity
          : category === 'zip'
          ? faEnvelope
          : null

        return (
          <Box p='2px'>
            <FilterPill
              color={color}
              onDeleteFilter={
                typeof setFilters === 'function'
                  ? () => {
                    setFilters({
                      ...filters,
                      [category]: ensureArray(filters?.[category]).filter(v => v !== value),
                    })
                  }
                  : undefined
              }
            >
              {icon && <Box><FontAwesomeIcon icon={icon} />{' '}</Box>}
              <Text display='inline-block' ml={icon ? 1 : 0}>
                {category === 'county'
                  ? `${value.details.name}, ${value.details.state}`
                  : value.id}
              </Text>
            </FilterPill>
          </Box>
        )
      })}
    </>
  )
}

export default GeoPills
