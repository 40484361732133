/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "new-cap": "warn", "react/no-string-refs": "warn", "no-class-assign": "warn" */

import React from 'react'
import { connect } from 'react-redux'

import FormInput from './form/FormInput.react'
import Cursores from '../lib/cursores'
import Avatar from './common/Avatar.react'
import classnames from 'classnames'

import { fetchTeam } from 'users/actions'
import { getTeam } from 'users/selectors'

class CommentForm extends React.Component {
  state = { open: false, text: '', users: [], cursor: 0, selectedIndex: null };

  componentWillMount () {
    this.props.fetchUsers()
  }

  onFocus = () => {
    if (!this.state.open)
      this.setState({ open: true })
  };

  onChange = e => {
    var text = e.target.value
    var selectedIndex = null

    if (text && text.indexOf('@') == -1) {
      this.setState({ users: false, text, selectedIndex })
      return
    }

    // check to see if they are typing in a word that starts with '@'
    const cursor = new Cursores.startsWith('@')
    const token = cursor.token(this.refs.input.getInputDOMNode())
    if (!token.value) {
      this.setState({ users: false, text, selectedIndex })
      return
    }
    const name = token.value.slice(1)

    const users = this.props.users.filter(function (u) {
      return u.name.startsWith(name) || u.username.startsWith(name)
    })

    if (users.length > 0) {
      var currentSelected = this.state.selectedIndex
      if (currentSelected == null || // no current selection
          users.filter(u => u.id == users[currentSelected].id).length == 0) // selected user is still not a valid choice anymore
        selectedIndex = 0
      else
        selectedIndex = currentSelected // still a valid selection
    }

    this.setState({ users: users, text, selectedIndex })
  };

  onAdd = () => {
    this.props.onAdd(this.state.text)
    this.setState({ text: '', open: false })
  };

  render () {
    var cx = classnames({
      'comment-form': true,
      open: this.state.open
    })

    return (
      <div className={cx}>
        <div className='input-wrap'>
          <FormInput
            type='text' multiline={true}
            placeholder='Add a comment. Use @ to tag a team mate.'
            onFocus={this.onFocus}
            value={this.state.text}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            ref='input'
          />

          {this.state.users && <TaggableUsers users={this.state.users} selectedIndex={this.state.selectedIndex} onSelect={this.onUserSelect}/>}
        </div>
        <div className='toolbar'>
          <button type='button' className='btn btn-success' onClick={this.onAdd}>Add Comment</button>
        </div>

      </div>
    )
  }

  onKeyDown = e => {
    var key = e.keyCode
    var count = this.state.users.length

    let index = null

    if (count == 0)
      return

    switch (key) {
      case 38: // up

        index = (this.state.selectedIndex || 0) - 1
        if (index < 0)
          index = count - 1

        this.setState({ selectedIndex: index })
        e.preventDefault()
        break
      case 40: // down

        index = (this.state.selectedIndex || 0) + 1
        if (index >= count)
          index = 0

        this.setState({ selectedIndex: index })
        e.preventDefault()
        break

      case 13:
        if (this.state.selectedIndex != null) {
          e.preventDefault()
          this.onUserSelect(this.state.users[this.state.selectedIndex])
        }

        break
    }
  };

  onUserSelect = u => {
    const cursor = this.getCursor()

    const { text } = this.state
    const input = this.refs.input.getInputDOMNode()
    const position = input.selectionStart
    const token = cursor.token(input)
    const { username } = u

    var value = token.value
    var lengthDiff = username.length - value.length

    let newText = cursor.replace(text, position, '@' + username)

    if (newText.endsWith(u.username))
      newText += ' '

    this.setState({ text: newText, users: [], selectedIndex: null }, () => {
      input.focus()
      input.selectionStart = input.selectionEnd = input.selectionStart + lengthDiff + 1 // move carret to after new username
    })
  };

  getCursor = () => new Cursores.startsWith('@');
}

class TaggableUsers extends React.Component {
  render () {
    const users = this.props.users || []

    return (
      <div className={'taggable-users ' + (users.length > 0 ? '' : ' hide ')}>
        <div className='title'>Team Members</div>
        <ul>
          {
            users.map((u, index) => (
              <li className={index == this.props.selectedIndex ? 'selected' : ''} key={u.id} onClick={this.onClick.bind(this, u)}><Avatar user={u}/> <span className='name'>{u.name}</span> <span className='username'>(@{u.username})</span></li>
            ), this)
          }
        </ul>
      </div>
    )
  }

  onClick = u => {
    this.props.onSelect(u)
  };
}

CommentForm = connect(
  state => ({
    users: getTeam(state)
  }),
  {
    fetchUsers: fetchTeam
  }
)(CommentForm)

export default CommentForm
