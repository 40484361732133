/* eslint "eqeqeq": "warn" */

import moment from 'moment'
import { Schema, arrayOf, normalize } from 'normalizr'

import api from 'common/api'
import { TRACK_EVENT } from '../../middleware/analytics'
import throwAny from 'common/util/throwAny'
import get from 'lodash/object/get'

export const SEARCH = 'SITES_SEARCH'
export const SEARCH_SUCCESS = 'SITES_SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SITES_SEARCH_FAILURE'

const SchemaSite = new Schema('site')
const SchemaSiteList = arrayOf(SchemaSite)

const SchemaMessage = new Schema('sitemessage')
const SchemaMessageFetch = {
  items: arrayOf(SchemaMessage)
}

const SchemaDomain = new Schema('domain')
const SchemaDomainList = { items: arrayOf(SchemaDomain) }

export const search = () => dispatch => {
  dispatch({ type: SEARCH })
  return api
    .get('/sites')
    .then(json => {
      if (json.error)
        return dispatch({ type: SEARCH_FAILURE, error: json.error })

      const payload = normalize(json, SchemaSiteList)
      return dispatch({ type: SEARCH_SUCCESS, payload })
    })
}

export const CREATE = 'SITES_CREATE'
export const CREATE_SUCCESS = 'SITES_CREATE_SUCCESS'
export const CREATE_FAILURE = 'SITES_CREATE_FAILURE'

export const create = props => dispatch => {
  const meta = { props }

  dispatch({ type: CREATE, meta })
  return api
    .post('/sites', props)
    .then(json => {
      const status = get(json, 'status')
      const error = (!status || status < 200 || status > 299)
        ? (json.error || 'Failed to create site')
        : json.error

      if (error) {
        dispatch({ type: CREATE_FAILURE, error, meta })
        if (error.code == 'rest_missing_callback_param')
          return { error: { type: 'invalid_request_error', param: 'name', message: 'This field is required' } }

        throwAny(error)
      }

      return dispatch({ type: CREATE_SUCCESS, payload: json, meta })
    })
}

export const FETCH = 'SITES_FETCH'
export const FETCH_SUCCESS = 'SITES_FETCH_SUCCESS'
export const FETCH_FAILURE = 'SITES_FETCH_FAILURE'

export const fetch = id => dispatch => {
  const meta = { id }

  dispatch({ type: FETCH, meta })
  return api
    .get(`/sites/${id}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_FAILURE, error: json.error, meta })

      const payload = normalize(json, SchemaSite)
      return dispatch({ type: FETCH_SUCCESS, payload, meta })
    })
}

export const UPDATE = 'SITES_UPDATE'
export const UPDATE_SUCCESS = 'SITES_UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'SITES_UPDATE_FAILURE'

export const update = (id, props) => dispatch => {
  const meta = { id, props }
  dispatch({ type: UPDATE, meta })
  return api
    .put(`/sites/${id}`, props)
    .then(json => {
      if (json.error)
        return dispatch({ type: UPDATE_FAILURE, meta, error: json.error })

      const payload = normalize(json, SchemaSite)
      return dispatch({ type: UPDATE_SUCCESS, meta, payload })
    })
}

export const UPDATE_SITE_DOMAIN = 'SITES_UPDATE_SITE_DOMAIN'
export const UPDATE_SITE_DOMAIN_SUCCESS = 'SITES_UPDATE_SITE_DOMAIN_SUCCESS'
export const UPDATE_SITE_DOMAIN_FAILURE = 'SITES_UPDATE_SITE_DOMAIN_FAILURE'

export const updateSiteDomain = (id, domain) => dispatch => {
  const meta = { id, domain }
  dispatch({ type: UPDATE_SITE_DOMAIN, meta })

  const promise = domain
    ? api.put(`/sites/${id}/link/${domain}?force=true`)
    : api.destroy(`/sites/${id}/link`)

  return promise.then(json => {
    if (json.error)
      return dispatch({ type: UPDATE_SITE_DOMAIN_FAILURE, meta, error: json.error })

    dispatch({ type: UPDATE_SITE_DOMAIN_SUCCESS, meta, [TRACK_EVENT]: { name: 'site.domain.updated' } })

    return fetch(id)(dispatch)
  })
}

export const FETCH_SETTINGS = 'SITES_FETCH_SETTINGS'
export const FETCH_SETTINGS_SUCCESS = 'SITES_FETCH_SETTINGS_SUCCESS'
export const FETCH_SETTINGS_FAILURE = 'SITES_FETCH_SETTINGS_FAILURE'

export const fetchSettings = id => dispatch => {
  const meta = { id }
  dispatch({ type: FETCH_SETTINGS, meta })
  return api
    .get(`/sites/${id}/settings`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_SETTINGS_FAILURE, error: json.error, meta })

      return dispatch({ type: FETCH_SETTINGS_SUCCESS, payload: json, meta })
    })
}

export const UPDATE_SETTINGS = 'SITES_UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'SITES_UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_FAILURE = 'SITES_UPDATE_SETTINGS_FAILURE'

export const updateSettings = (id, settings) => dispatch => {
  const meta = { id }
  dispatch({ type: UPDATE_SETTINGS, meta, payload: settings })
  return api
    .put(`/sites/${id}/settings`, settings)
    .then(json => {
      if (json.error)
        return dispatch({ type: UPDATE_SETTINGS_FAILURE, error: json.error, meta })

      dispatch({ type: UPDATE_SETTINGS_SUCCESS, payload: json, meta })
    })
}

export const FETCH_MESSAGES = 'SITES_FETCH_MESSAGES'
export const FETCH_MESSAGES_SUCCESS = 'SITES_FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAILURE = 'SITES_FETCH_MESSAGES_FAILURE'

export const fetchMessages = (opts = { limit: 10, offset: 0 }) => dispatch => {
  const { limit, offset } = opts
  const meta = { limit, offset }

  dispatch({ type: FETCH_MESSAGES, meta })
  return api
    .get(`/sites/leads?skip=${offset}&limit=${limit}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_MESSAGES_FAILURE, meta, error: json.error })

      const payload = normalize(json, SchemaMessageFetch)
      return dispatch({ type: FETCH_MESSAGES_SUCCESS, meta, payload })
    })
}

export const FETCH_SITE_MESSAGES = 'SITES_FETCH_SITE_MESSAGES'
export const FETCH_SITE_MESSAGES_SUCCESS = 'SITES_FETCH_SITE_MESSAGES_SUCCESS'
export const FETCH_SITE_MESSAGES_FAILURE = 'SITES_FETCH_SITE_MESSAGES_FAILURE'

export const fetchSiteMessages = (id, opts = { limit: 10, offset: 0 }) => dispatch => {
  const { limit, offset } = opts
  const meta = { id, limit, offset }

  dispatch({ type: FETCH_SITE_MESSAGES, meta })
  return api
    .get(`/sites/${id}/leads?skip=${offset}&limit=${limit}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_SITE_MESSAGES_FAILURE, error: json.error, meta })

      const payload = normalize(json, SchemaMessageFetch)
      return dispatch({ type: FETCH_SITE_MESSAGES_SUCCESS, payload, meta })
    })
}

export const FETCH_MESSAGES_STATS = 'SITES_FETCH_MESSAGES_STATS'
export const FETCH_MESSAGES_STATS_SUCCESS = 'SITES_FETCH_MESSAGES_STATS_SUCCESS'
export const FETCH_MESSAGES_STATS_FAILURE = 'SITES_FETCH_MESSAGES_STATS_FAILURE'

export const fetchMessageStats = () => dispatch => {
  dispatch({ type: FETCH_MESSAGES_STATS })

  return api
    .get('/sites/leads/stats')
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_MESSAGES_STATS_FAILURE, error: json.error })

      return dispatch({ type: FETCH_MESSAGES_STATS_SUCCESS, payload: json })
    })
}

export const DESTROY_MESSAGE = 'SITES_DESTROY_MESSAGE'
export const DESTROY_MESSAGE_SUCCESS = 'SITES_DESTROY_MESSAGE_SUCCESS'
export const DESTROY_MESSAGE_FAILURE = 'SITES_DESTROY_MESSAGE_FAILURE'

export const destroyMessage = id => dispatch => {
  const meta = { id }
  dispatch({ type: DESTROY_MESSAGE, meta })

  return api
    .request(`/sites/leads/${id}`, { method: 'DELETE' })
    .then(json => {
      if (json.error)
        return dispatch({ type: DESTROY_MESSAGE_FAILURE, meta, error: json.error })

      return dispatch({ type: DESTROY_MESSAGE_SUCCESS, meta, payload: json })
    })
}

export const bulkDestroyMessages = ids => async dispatch => {
  for (let i = 0; i < ids.length; i++)
    await dispatch(destroyMessage(ids[i]))
}

export const FETCH_CROSS_VISITORS = 'SITES_FETCH_CROSS_VISITORS'
export const FETCH_CROSS_VISITORS_SUCCESS = 'SITES_FETCH_CROSS_VISITORS_SUCCESS'
export const FETCH_CROSS_VISITORS_FAILURE = 'SITES_FETCH_CROSS_VISITORS_FAILURE'

export const fetchCrossVisitors = params => (dispatch, getState) => {
  dispatch({ type: FETCH_CROSS_VISITORS })

  const start = moment(params.start_date || moment().add(-31, 'days')).format('YYYY-MM-DD')
  const end = moment(params.end_date || moment().add(-1, 'days')).format('YYYY-MM-DD')

  return api
    .get(`/sites/analytics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_CROSS_VISITORS_FAILURE, error: json.error })

      return dispatch({ type: FETCH_CROSS_VISITORS_SUCCESS, payload: json })
    })
}

export const FETCH_SITE_VISITORS = 'SITES_FETCH_SITE_VISITORS'
export const FETCH_SITE_VISITORS_SUCCESS = 'SITES_FETCH_SITE_VISITORS_SUCCESS'
export const FETCH_SITE_VISITORS_FAILURE = 'SITES_FETCH_SITE_VISITORS_FAILURE'

export const fetchVisitors = (id, params = {}) => (dispatch, getState) => {
  const start = moment(params.start_date || moment().add(-31, 'days')).format('YYYY-MM-DD')
  const end = moment(params.end_date || moment().add(-1, 'days')).format('YYYY-MM-DD')
  const meta = { id, params }
  dispatch({ type: FETCH_SITE_VISITORS, meta })

  return api
    .get(`/sites/${id}/analytics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_SITE_VISITORS_FAILURE, meta, error: json.error })

      return dispatch({ type: FETCH_SITE_VISITORS_SUCCESS, meta, payload: json })
    })
}

export const FETCH_CROSS_CONVERSIONS = 'SITES_FETCH_CROSS_CONVERSIONS'
export const FETCH_CROSS_CONVERSIONS_SUCCESS = 'SITES_FETCH_CROSS_CONVERSIONS_SUCCESS'
export const FETCH_CROSS_CONVERSIONS_FAILURE = 'SITES_FETCH_CROSS_CONVERSIONS_FAILURE'

export const fetchCrossConversions = (params = {}) => (dispatch, getState) => {
  dispatch({ type: FETCH_CROSS_CONVERSIONS })

  const start = moment(params.start_date || moment().add(-31, 'days')).format('YYYY-MM-DD')
  const end = moment(params.end_date || moment().add(-1, 'days')).format('YYYY-MM-DD')

  return api
    .get(`/sites/leads/metrics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_CROSS_CONVERSIONS_FAILURE, error: json.error })

      return dispatch({ type: FETCH_CROSS_CONVERSIONS_SUCCESS, payload: json })
    })
}

export const FETCH_SITE_CONVERSIONS = 'SITES_FETCH_SITE_CONVERSIONS'
export const FETCH_SITE_CONVERSIONS_SUCCESS = 'SITES_FETCH_SITE_CONVERSIONS_SUCCESS'
export const FETCH_SITE_CONVERSIONS_FAILURE = 'SITES_FETCH_SITE_CONVERSIONS_FAILURE'

export const fetchConversions = (id, params = {}) => (dispatch, getState) => {
  const meta = { id, params }
  dispatch({ type: FETCH_SITE_CONVERSIONS, meta })

  const start = moment(params.start_date || moment().add(-31, 'days')).format('YYYY-MM-DD')
  const end = moment(params.end_date || moment().add(-1, 'days')).format('YYYY-MM-DD')

  return api
    .get(`/sites/${id}/leads/metrics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_SITE_CONVERSIONS_FAILURE, meta, error: json.error })

      return dispatch({ type: FETCH_SITE_CONVERSIONS_SUCCESS, meta, payload: json })
    })
}

export const FETCH_ROW_VISITORS = 'SITES_FETCH_ROW_VISITORS'
export const FETCH_ROW_VISITORS_SUCCESS = 'SITES_FETCH_ROW_VISITORS_SUCCESS'
export const FETCH_ROW_VISITORS_FAILURE = 'SITES_FETCH_ROW_VISITORS_FAILURE'

export const fetchRowVisitors = id => (dispatch, getState) => {
  const start = moment().add(-8, 'days').format('YYYY-MM-DD')
  const end = moment().add(-1, 'days').format('YYYY-MM-DD')

  const meta = { id }

  dispatch({ type: FETCH_ROW_VISITORS, meta })

  return api
    .get(`/sites/${id}/analytics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_ROW_VISITORS_FAILURE, meta, error: json.error })

      return dispatch({ type: FETCH_ROW_VISITORS_SUCCESS, meta, payload: json })
    })
}

export const FETCH_ROW_CONVERSIONS = 'SITES_FETCH_ROW_CONVERSIONS'
export const FETCH_ROW_CONVERSIONS_SUCCESS = 'SITES_FETCH_ROW_CONVERSIONS_SUCCESS'
export const FETCH_ROW_CONVERSIONS_FAILURE = 'SITES_FETCH_ROW_CONVERSIONS_FAILURE'

export const fetchRowConversions = id => (dispatch, getState) => {
  const start = moment().add(-8, 'days').format('YYYY-MM-DD')
  const end = moment().add(-1, 'days').format('YYYY-MM-DD')

  const meta = { id }

  dispatch({ type: FETCH_ROW_CONVERSIONS, meta })

  return api
    .get(`/sites/${id}/leads/metrics?start_date=${start}&end_date=${end}`)
    .then(json => {
      if (json.error)
        return dispatch({ type: FETCH_ROW_CONVERSIONS_FAILURE, meta, error: json.error })

      return dispatch({ type: FETCH_ROW_CONVERSIONS_SUCCESS, meta, payload: json })
    })
}

export const PROMOTE_MESSAGE = 'SITES_PROMOTE_MESSAGE'
export const PROMOTE_MESSAGE_SUCCESS = 'SITES_PROMOTE_MESSAGE_SUCCESS'
export const PROMOTE_MESSAGE_FAILURE = 'SITES_PROMOTE_MESSAGE_FAILURE'

export const promoteMessage = id => dispatch => {
  const meta = { id }
  dispatch({ type: PROMOTE_MESSAGE, meta })

  return api
    .get(`/sites/leads/${id}/promote`)
    .then(json => {
      if (json.error)
        return dispatch({ type: PROMOTE_MESSAGE_FAILURE, meta, error: json.error })

      return dispatch({ type: PROMOTE_MESSAGE_SUCCESS, meta, payload: json })
    })
}

export const bulkPromoteMessages = ids => async dispatch => {
  for (let i = 0; i < ids.length; i++)
    await dispatch(promoteMessage(ids[i]))
}

export const DESTROY = 'SITES_DESTROY'
export const DESTROY_SUCCESS = 'SITES_DESTROY_SUCCESS'
export const DESTROY_FAILURE = 'SITES_DESTROY_FAILURE'

export const destroy = id => async dispatch => {
  const meta = { id }
  dispatch({ type: DESTROY, meta })

  const json = await api.request(`/sites/${id}`, { method: 'DELETE' })
  if (json.error)
    return dispatch({ type: DESTROY_FAILURE, meta, error: json.error })

  return dispatch({ type: DESTROY_SUCCESS, meta, payload: json })
}

export const FETCH_DOMAINS = 'SITES_FETCH_DOMAINS'
export const FETCH_DOMAINS_SUCCESS = 'SITES_FETCH_DOMAINS_SUCCESS'
export const FETCH_DOMAINS_FAILURE = 'SITES_FETCH_DOMAINS_FAILURE'

export const fetchDomains = () => async dispatch => {
  dispatch({ type: FETCH_DOMAINS })

  const json = await api.get('/domains?limit=1000')
  if (json.error)
    return dispatch({ type: FETCH_DOMAINS_FAILURE, error: json.error })

  const payload = normalize(json, SchemaDomainList)
  return dispatch({ type: FETCH_DOMAINS_SUCCESS, payload })
}

export const FETCH_DOMAIN = 'SITES_FETCH_DOMAIN'
export const FETCH_DOMAIN_SUCCESS = 'SITES_FETCH_DOMAIN_SUCCESS'
export const FETCH_DOMAIN_FAILURE = 'SITES_FETCH_DOMAIN_FAILURE'

export const fetchDomain = id => async dispatch => {
  const meta = { id }
  dispatch({ type: FETCH_DOMAIN, meta })

  const json = await api.get(`/domains/${id}`)
  if (json.error)
    return dispatch({ type: FETCH_DOMAIN_FAILURE, meta, error: json.error })

  dispatch({ type: FETCH_DOMAIN_SUCCESS, meta, payload: json })
  return json
}

export const FETCH_ORDERS = 'SITES_FETCH_ORDER'
export const FETCH_ORDER_SUCCESS = 'SITES_FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'SITES_FETCH_ORDER_FAILURE'

export const fetchOrders = () => async dispatch => {
  dispatch({ type: FETCH_ORDERS })

  const json = await api.get('/domains/orders')
  if (json.error)
    return dispatch({ type: FETCH_ORDER_FAILURE, error: json.error })

  return dispatch({ type: FETCH_ORDER_SUCCESS, payload: json })
}

export const DOMAIN_AVAILABLE = 'SITES_DOMAIN_AVAILABLE'
export const DOMAIN_AVAILABLE_SUCCESS = 'SITES_DOMAIN_AVAILABLE_SUCCESS'
export const DOMAIN_AVAILABLE_FAILURE = 'SITES_DOMAIN_AVAILABLE_FAILURE'

export const checkAvailability = (domain, forTransfer) => async dispatch => {
  dispatch({ type: DOMAIN_AVAILABLE })

  const query = forTransfer ? '?forTransfer=true' : ''
  const json = await api.get(`/domains/${domain}/available${query}`)
  if (json.error)
    return dispatch({ type: DOMAIN_AVAILABLE_FAILURE, error: json.error })

  dispatch({ type: DOMAIN_AVAILABLE_SUCCESS, payload: json })
  return json
}

export const FETCH_DOMAIN_PRODUCTS = 'SITES_FETCH_DOMAIN_PRODUCTS'
export const FETCH_DOMAIN_PRODUCTS_SUCCESS = 'SITES_FETCH_DOMAIN_PRODUCTS_SUCCESS'
export const FETCH_DOMAIN_PRODUCTS_FAILURE = 'SITES_FETCH_DOMAIN_PRODUCTS_FAILURE'

export const fetchDomainProducts = domain => async (dispatch, getState) => {
  if (getState().sites.domains.products)
    return

  dispatch({ type: FETCH_DOMAIN_PRODUCTS })

  const json = await api.get('/domains/tlds')
  if (json.error)
    return dispatch({ type: FETCH_DOMAIN_PRODUCTS_FAILURE, error: json.error })

  return dispatch({ type: FETCH_DOMAIN_PRODUCTS_SUCCESS, payload: json })
}

export const FETCH_AGREEMENTS = 'SITES_FETCH_AGREEMENTS'
export const FETCH_AGREEMENTS_SUCCESS = 'SITES_FETCH_AGREEMENTS_SUCCESS'
export const FETCH_AGREEMENTS_FAILURE = 'SITES_FETCH_AGREEMENTS_FAILURE'

export const fetchAgreements = (domains, forTransfer) => async dispatch => {
  dispatch({ type: FETCH_AGREEMENTS })

  if (!Array.isArray(domains))
    domains = [domains]

  const json = await api.post('/domains/agreements', { domains, privacy: false, forTransfer: !!forTransfer })
  if (json.error)
    return dispatch({ type: FETCH_AGREEMENTS_FAILURE, error: json.error })

  dispatch({ type: FETCH_AGREEMENTS_SUCCESS, payload: json })
  return json
}

export const CREATE_ORDER = 'SITES_CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'SITES_CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'SITES_CREATE_ORDER_FAILURE'

export const createOrder = (domain, agreements, contact, renewalQty, authCode) => async dispatch => {
  let order = { domains: [{ domain, qty: 1 }] }
  if (renewalQty) { // renewal order
    order = { renewals: [{ domain, qty: renewalQty }] }
  } else { // purchase/transfer order
    order.agreements = agreements
    order.contact = contact
  }

  if (authCode) // transfer order
    order.domains[0].authCode = authCode

  dispatch({ type: CREATE_ORDER, meta: order })

  const resp = await api.post('/domains/orders', order)
  if (resp.error) {
    dispatch({ type: CREATE_ORDER_FAILURE, meta: order, error: resp.error })
    throwAny(resp.error)
  }

  if (resp._id) resp.id = resp._id

  dispatch({ type: CREATE_ORDER_SUCCESS, meta: order, payload: resp })
  return resp
}

export const PAY_ORDER = 'SITES_PAY_ORDER'
export const PAY_ORDER_SUCCESS = 'SITES_PAY_ORDER_SUCCESS'
export const PAY_ORDER_FAILURE = 'SITES_PAY_ORDER_FAILURE'

export const payOrder = id => async dispatch => {
  const meta = { id }
  dispatch({ type: PAY_ORDER, meta })

  const resp = await api.post(`/domains/orders/${id}`)
  if (resp.error) {
    dispatch({ type: PAY_ORDER_FAILURE, meta, error: resp.error })
    throwAny(resp.error)
  }

  dispatch({ type: PAY_ORDER_SUCCESS, meta, payload: resp, [TRACK_EVENT]: { name: 'domain.purchase' } })
  return resp
}

export const FETCH_ACCOUNT = 'SITES_FETCH_ACCOUNT'
export const FETCH_ACCOUNT_SUCCESS = 'SITES_FETCH_ACCOUNT_SUCCESS'
export const FETCH_ACCOUNT_FAILURE = 'SITES_FETCH_ACCOUNT_FAILURE'

export const fetchAccount = () => async dispatch => {
  dispatch({ type: FETCH_ACCOUNT })

  const json = await api.get('/domains/account')
  if (json.error) {
    dispatch({ type: FETCH_ACCOUNT_FAILURE, error: json.error })
    throwAny(json.error)
  }

  dispatch({ type: FETCH_ACCOUNT_SUCCESS, payload: json })
  return json
}

export const MANAGE_DOMAIN = 'SITES_MANAGE_DOMAIN'
export const MANAGE_DOMAIN_SUCCESS = 'SITES_MANAGE_DOMAIN_SUCCESS'
export const MANAGE_DOMAIN_FAILURE = 'SITES_MANAGE_DOMAIN_FAILURE'

export const manageDomain = id => async dispatch => {
  dispatch({ type: MANAGE_DOMAIN, meta: { id } })

  let json
  let error
  try {
    json = await api.put(`/domains/${id}/manage`)
    error = json.error
  } catch (e) {
    error = e
  }
  if (error) {
    dispatch({ type: MANAGE_DOMAIN_FAILURE, meta: { id }, error })
    throwAny(json.error)
  }

  dispatch({ type: MANAGE_DOMAIN_SUCCESS, payload: json, meta: { id } })

  return fetchDomain(id)(dispatch)
}

export const FETCH_MAIL_SETTINGS = 'SITES_FETCH_MAIL_SETTINGS'
export const FETCH_MAIL_SETTINGS_SUCCESS = 'SITES_FETCH_MAIL_SETTINGS_SUCCESS'
export const FETCH_MAIL_SETTINGS_FAILURE = 'SITES_FETCH_MAIL_SETTINGS_FAILURE'

export const fetchMail = id => async dispatch => {
  dispatch({ type: FETCH_MAIL_SETTINGS, meta: { id } })

  const json = await api.get(`/domains/${id}/mail`)
  if (json.error) {
    dispatch({ type: FETCH_MAIL_SETTINGS_FAILURE, meta: { id }, error: json.error })
    throwAny(json.error)
  }

  dispatch({ type: FETCH_MAIL_SETTINGS_SUCCESS, payload: json, meta: { id } })

  return json
}

export const UPDATE_MAIL_SETTINGS = 'SITES_UPDATE_MAIL_SETTINGS'
export const UPDATE_MAIL_SETTINGS_SUCCESS = 'SITES_UPDATE_MAIL_SETTINGS_SUCCESS'
export const UPDATE_MAIL_SETTINGS_FAILURE = 'SITES_UPDATE_MAIL_SETTINGS_FAILURE'
export const updateMail = (id, type, data) => async dispatch => {
  const meta = { type, data }
  dispatch({ type: UPDATE_MAIL_SETTINGS, meta })
  let resp
  if (type == 'google')
    resp = await api.put(`/domains/${id}/mail/${type}`)
  else
    resp = await api.put(`/domains/${id}/mail`, data)

  if (resp.error) {
    dispatch({ type: UPDATE_MAIL_SETTINGS_FAILURE, meta, error: resp.error })
    return
  }

  dispatch({ type: UPDATE_MAIL_SETTINGS_SUCCESS, meta, payload: resp, [TRACK_EVENT]: { name: 'domain.email.updated' } })
  await fetchDomain(id)(dispatch)
  return resp
}

export const UPDATE_DOMAIN = 'SITES_UPDATE_DOMAIN'
export const UPDATE_DOMAIN_SUCCESS = 'SITES_UPDATE_DOMAIN_SUCCESS'
export const UPDATE_DOMAIN_FAILURE = 'SITES_UPDATE_DOMAIN_FAILURE'

export const updateDomain = (id, autoRenew) => async dispatch => {
  const meta = { id, autoRenew }
  dispatch({ type: UPDATE_DOMAIN, meta })
  const resp = await api.post(`/domains/${id}`, { autoRenew })
  if (resp.error)
    return dispatch({ type: UPDATE_DOMAIN_FAILURE, meta, error: resp.error })

  dispatch({ type: UPDATE_DOMAIN_SUCCESS, meta, payload: resp })
  return resp
}
