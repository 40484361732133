/* eslint "react/display-name": "warn", "react/no-unescaped-entities": "warn" */
import React from 'react'
import { Text } from '@realsoftworks/decor'

export default class extends React.Component {
  render () {
    const customErrorMessage = this.props.errorMessage
    return (
      <div className='big-fatal-error'>
        <span className='big-fatal-icon fa-stack fa-5x'>
          <i className='fa fa-cloud fa-stack-2x' />
          <i className='fa fa-bolt fa-inverse fa-stack-1x' />
        </span>
        <h2>
          {customErrorMessage
            ? 'We’ve encountered an error'
            : 'Whoops - Our mistake!'}
        </h2>

        {customErrorMessage && (
          <Text display='block' color='red.500' py={2}>
            {customErrorMessage}
          </Text>
        )}

        <p>
          {customErrorMessage
            ? 'Don’t worry though - we’re probably already looking into it. Still, if you wouldn’t mind letting us know with the help icon in the bottom right, we’d appreciate it!'
            : 'Don’t worry though - it wasn’t your fault and we’re probably already looking into it. Still, if you wouldn’t mind letting us know with the help icon in the bottom right, we’d appreciate it!'}
        </p>
      </div>
    )
  }
}
