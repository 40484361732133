/* eslint "react/jsx-no-target-blank": "warn" */
import React from 'react'
import { Link } from 'react-router-dom'

export const Dashboard = ({ site, ...rest }) => !(site && site.id) ? null : (
  <Link {...rest} to={`/sites/${site.id}`} />
)

export const Settings = ({ site, ...rest }) => !(site && site.id) ? null : (
  <Link {...rest} to={`/sites/${site.id}/settings`} />
)

export const Content = ({ site, ...rest }) => !(site && site.path) ? null : (
  <a {...rest} href={`${site.path}/wp-admin/edit.php?post_type=page`} />
)

export const Design = ({ site, ...rest }) => !(site && site.path) ? null : (
  <a {...rest} href={`${site.path}/wp-admin/customize.php`} />
)

export const Front = ({ site, ...rest }) => !(site && site.path) ? null : (
  <a {...rest} href={`${site.path}`} target='_blank' />
)

export const openSite = site => window.open(site.path, '_blank')

export default { Dashboard, Settings, Content, Design, Front, openSite }
