/* eslint "react/jsx-no-target-blank": "warn", "react/no-string-refs": "warn", "eqeqeq": "warn", "standard/no-callback-literal": "warn", "no-useless-escape": "warn" */
// TODO this connects directly to the server whereas it should probably go through a store

// TODO this connects directly to the server whereas it should probably go through a store

import React from 'react'
import createReactClass from 'create-react-class'
import Model from '../../core/model.js'
import { toast } from '@realsoftworks/decor'

class Link extends React.Component {
  render () {
    var v = this.props.link

    var c = null
    switch (v.type) {
      case 'youtube':
        c = (
          <div className='embed-responsive embed-responsive-4by3'>
            <iframe className='embed-responsive-item' src={'//www.youtube.com/embed/' + v.url} allowFullScreen />
          </div>
        )
        break

      default:
        c = <a href={v.url} target='_blank'>{v.url}</a>
        break
    }

    return (
      <div className=''>
        {c}
      </div>
    )
  }
}

var LinksField = createReactClass({
  displayName: 'LinksField',

  getInitialState: function () {
    return {
      open: false,
      text: ''
    }
  },

  render: function () {
    let values = this.state.editingValue || this.props.value
    values = Array.isArray(values) ? values : []
    values = values.filter(v => v)

    return (
      <div className='links-field'>
        {Array.prototype.map.call(values, function (v, i) {
          return (
            <div className='link'>
              <Link link={v.values} />
              {this.props.editable ? <button type='button' className='close' onClick={this.onCloseClick.bind(this, i)}><span aria-hidden='true'>&times;</span><span className='sr-only'>Close</span></button> : ''}
            </div>
          )
        }, this)}

        {
          this.state.open
            ? <input
              type='text' ref='input' className='form-control'
              value={this.state.text}
              placeholder='Enter a web address'
              onChange={this.onFieldChange}
              onKeyDown={this.onKeyDown}
              onBlur={this.onBlur}
            />
            : (this.props.editable ? <button type='button' className='btn btn-default' onClick={this.open}>Add new link</button> : '')

        }
      </div>
    )
  },

  open: function () {
    this.setState({ open: true, editingValue: this.props.value }, this.onOpen)
  },

  close: function () {
    this.setState({ text: '', open: false, editingValue: null })
  },

  onOpen: function () {
    this.refs.input.focus()
  },

  onFieldChange: function (e) {
    this.setState({ text: e.target.value })
  },

  onKeyDown: function (e) {
    var key = e.key
    if (key == 'Enter')
      this.onAdd(e.target.value)
  },

  onAdd: function (value, cb) {
    if (!this.isValid(value)) {
      if (cb) cb(false)
      return false
    }

    this.setState({ text: '' })
    var me = this
    Model('link').create({ url: value }).then(function (data) {
      var result = data
      me.addNew(result)
      if (cb) cb(true)
    })
  },

  addNew: function (data) {
    var ev = [].concat(this.state.editingValue)
    ev.push(data)
    this.setState({ editingValue: ev })
    var v = ev
      .filter(v => v)
      .map(v => v.id)
      .filter(v => v)
    this.props.onChange(v)
  },

  onBlur: function (e) {
    this.onAdd(e.target.value, shouldClose => {
      if (shouldClose)
        this.close()
    })
  },

  onCloseClick: function (i) {
    var v = this.props.value || []
    v = Array.prototype.map.call(v, function (value) {
      return value.id
    })

    v.splice(i, 1)
    this.props.onChange(v)
  },

  isValid: function (url) {
    var regex = /^((ht|f)tps?:\/\/)?[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/
    if (url && !url.match(regex)) {
      if (this.closeOpenToast)
        this.closeOpenToast()

      this.closeOpenToast = toast.error({ title: 'Error', content: 'Invalid URL' })
      return false
    }

    return !!url
  }
})

export default LinksField
