/* eslint "no-class-assign": "warn" */
import React, { Component } from 'react'
import Select from 'decor/form/Select'
import { connect } from 'react-redux'
import { getList } from '../selectors'

class SiteSelect extends Component {
  render () {
    const { sites, ...rest } = this.props

    return (
      <Select
        {...rest}
        labelKey='name'
        valueKey='id'
        options={sites}
      />
    )
  }
}

SiteSelect.propTypes = {}
SiteSelect.defaultProps = {}

SiteSelect = connect(
  state => ({
    sites: getList(state)
  })
)(SiteSelect)

export default SiteSelect
