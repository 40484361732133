import React from 'react'
import classNames from 'classnames'

import './select.less'

export default function createSelect (WrappedComponent) {
  return class Select extends React.Component {
    render () {
      const { className, ...rest } = this.props

      return (
        <WrappedComponent
          className={classNames('decor', 'decorSelect', className)}
          {...rest}
        />
      )
    }
  }
}
