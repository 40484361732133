/* eslint "react/no-string-refs": "warn" */
// TODO connect this to redux contact actions
// TODO make sure that createTag returns a promise that resolves to the created tag
// TODO modify FieldFactory so that this field is used where type=picker and props.model=contacttag

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import PickerField from './PickerField.react'
import { connect } from 'react-redux'
import { createTag } from '../../modules/contacts/actions.js'

import { toast } from '@realsoftworks/decor'
import { TAG_EXISTING_NOTIF } from 'const'
class TagPicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array,
    createTag: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.onAddTag = this.onAddTag.bind(this)
  }

  focus () {
    this.picker.focus()
  }

  checkBlur (e) {
    return this.picker.checkBlur(e)
  }

  onAddTag (text) {
    var { onChange, createTag, value } = this.props

    const existingTag = value.find(t => t.name === text)
    if (existingTag) return toast.error(TAG_EXISTING_NOTIF)

    createTag({ name: text }).then(tag => {
      const value = this.props.value || []
      const existingTag = value.find(t => t.id === tag.id)
      if (existingTag) return toast.error(TAG_EXISTING_NOTIF)

      var newValue = [...value, tag]
      onChange && onChange(newValue)
    })
  }

  render () {
    const props = this.props
    return (
      <PickerField {...props} ref={picker => { this.picker = picker }} model='contacttag' onAdd={this.onAddTag}/>
    )
  }
}

const mapStateToProps = (state, props) => ({})
const mapDispatchToProps = {
  createTag
  // onFocus
}
// normal connect params except the options object at the end
var ConnectedTagPicker = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(TagPicker)

export default class ConnectedTagPickerWithFocus extends Component {
  focus () {
    // because we passed withRef true, we can call a new method: getWrappedInstance
    this.refs.picker.getWrappedInstance().focus()
  }

  checkBlur (e) {
    return this.refs.picker.getWrappedInstance().checkBlur(e)
  }

  render () {
    // render the component returned from connect
    return (
      <ConnectedTagPicker ref='picker' {...this.props}/>
    )
  }
}
