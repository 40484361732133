import React, { useEffect, useRef, useState } from 'react'
import { Box, Paragraph, Heading, Text } from '@realsoftworks/decor'
import OptionButtons from './OptionButtons'
import OrderTable from 'marketing/components/OrderTable'
import BigLoader from 'common/BigLoader'
import { Numeric } from 'common/format'
import SkipOptions from './SkipOptions'
import { PROVIDER_REIGROUP, PROVIDER_SKIPGENIE } from 'const'

const EmptyMessage = ({ ...props }) => (
  <Box {...props}>
    <Heading textAlign='center' size={3} mb={4} color='secondary'>Nothing to skip</Heading>
    <Paragraph>None of the records you selected are available to skip with the selected options: they have already been skipped or there is a pending order to skip them.</Paragraph>
  </Box>
)

const SkiptraceOrderTable = ({
  provider,
  totalCount,
  skippableCount,
  skipOption,
  ...props
}) => {
  const unskippable = totalCount - skippableCount

  return (
    <Box {...props}>

      {skippableCount === 0
        ? <EmptyMessage mt={5} />
        : <>
          <OrderTable
            items={[
              {
                name: provider === PROVIDER_SKIPGENIE
                  ? 'Skip Genie Lookup'
                  : provider === PROVIDER_REIGROUP
                    ? 'Batch Skiptrace Lookup'
                    : 'Skiptrace Lookup',
                service: `skiptrace.${provider}`,
                quantity: skippableCount
              }
            ]}
          />
          {!!unskippable &&
            <Box my={3}>
              <Text fontSize={1} color='secondary'>
                Note: You <strong>excluded <Numeric>{unskippable}</Numeric> records</strong> which were either already skipped or have a pending order.
                {skipOption === false ? (
                  <>{' '}You opted to <strong>not reskip anything</strong>.</>
                ) : skipOption?.differentProvider && typeof skipOption?.olderThanXDays === 'number' ? (
                  <>
                    {' '}You opted to reskip items that were skipped using a
                    {' '}<strong>different provider</strong>
                    {' '}and/or items with skiptrace data
                    {' '}<strong>
                      older than
                      {' '}{skipOption.olderThanXDays}
                      {' '}day{skipOption.olderThanXDays === 1 ? '' : 's'}.
                    </strong>
                  </>
                ) : skipOption?.differentProvider ? (
                  <>
                    {' '}You opted to reskip items that were skipped using a
                    {' '}<strong>different provider</strong>.
                  </>
                ) : typeof skipOption?.olderThanXDays === 'number' ? (
                  <>
                    {' '}You opted to reskip items with skiptrace data
                    {' '}<strong>
                      older than
                      {' '}{skipOption.olderThanXDays}
                      {' '}day{skipOption.olderThanXDays === 1 ? '' : 's'}.
                    </strong>
                  </>
                ) : null}
              </Text>
            </Box>}
        </>}
    </Box>
  )
}

const ShoppingCart = ({
  provider,
  onProviderChange,
  totalCount,
  skippableCount,
  skippedCount,
  shouldForceHideOptions,
  shouldForceHideProviders,
  skipOption,
  onSkipOptionChange,
  ...props
}) => {
  // False or truthy values means skip option is set
  const isSkipOptsSet = skipOption !== undefined && skipOption !== null
  const isSkipOptsSetOrUnnecessary = skippedCount === 0 || isSkipOptsSet
  const isLoadingSkippedCount = provider && !isSkipOptsSetOrUnnecessary && typeof skippedCount !== 'number'
  const isLoadingSkippableCount = provider && isSkipOptsSetOrUnnecessary && typeof skippableCount !== 'number'

  const [isSkipOptBeingApplied, setIsSkipOptBeingApplied] = useState(false)

  // When skip options changes, we should set isSkipOptBeingApplied to true
  // so that the table gets hidden immediately (and only let it reappear only
  // after latest response is available)
  const isFirstRenderRef = useRef(true)
  useEffect(() => {
    // First render doesn't count as "skip option changing"
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false
      return
    }

    setIsSkipOptBeingApplied(true)
  }, [skipOption])

  useEffect(() => {
    const wasPreviouslyLoading = !isLoadingSkippableCount
    if (wasPreviouslyLoading && isSkipOptBeingApplied)
      setIsSkipOptBeingApplied(false)
  }, [isLoadingSkippableCount])

  return (
    <Box {...props}>
      {!shouldForceHideProviders && (
        <OptionButtons value={provider} onChange={onProviderChange} />
      )}

      {!shouldForceHideOptions && provider && skippedCount > 0 && (
        <>
          <Text fontSize={2} mt={shouldForceHideProviders ? 0 : 5} display='block'>
            {skippedCount} of these records have already beeen skipped. What would you like to do?
          </Text>
          <SkipOptions mt={2} value={skipOption} onChange={onSkipOptionChange} />
        </>
      )}

      {provider && isSkipOptsSetOrUnnecessary && !isLoadingSkippableCount && !isSkipOptBeingApplied && (
        <SkiptraceOrderTable
          provider={provider}
          totalCount={totalCount}
          skippableCount={skippableCount}
          skipOption={skipOption}
        />
      )}

      {(isLoadingSkippedCount || isLoadingSkippableCount) && (
        <BigLoader loading={skippableCount === null} />
      )}
    </Box>
  )
}

export default ShoppingCart
