import React from 'react'
import { Heading, Box, Paragraph, Label, NumberInput, Caption, Button } from '@realsoftworks/decor'
import Card from 'common/Card'

import DebounceInput from 'common/DebounceInput'
import LoadingIcon from 'common/LoadingIcon'
import { Numeric } from 'common/format'

import DealValueInput from '../../DealValueInput'
import CountyPicker from '../../CountyPicker'
import CityPicker from '../../CityPicker'
import ZipPicker from '../../ZipPicker'

export default function LenderIntro ({ onSearch, counts, fetchCounts }) {
  const [filters, setFilters] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const mergeFilter = React.useCallback(
    (name, value) => {
      const newfilters = { ...filters, [name]: value }
      setFilters(newfilters)
      fetchCounts(newfilters)
    },
    [setFilters, filters]
  )

  React.useEffect(() => {
    if (typeof counts === 'number')
      setLoading(false)
  }, [counts])

  const handleSearchClick = React.useCallback(() => onSearch(filters), [onSearch, filters])

  const onChangeCount = React.useCallback(e => mergeFilter('minLoans', (e && e.target) ? e.target.value : e))
  const onChangeMin = React.useCallback(e => mergeFilter('minLent', (e && e.target) ? e.target.value : e))
  const onChangeMax = React.useCallback(e => mergeFilter('maxLent', (e && e.target) ? e.target.value : e))

  const { county, city, zip, minLoans, minLent, maxLent } = filters

  return (
    <Card borderTop='12px solid' borderColor='red.500'>
      <Heading textAlign='center' size={2} mb={3}>Private Lenders</Heading>
      <Paragraph mb={5}>Private money can make a huge difference on your ROI at the closing table. Browse money partners in your area to fund your next deal.</Paragraph>

      <Box display='flex' mx={-3}>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>County</Label>
          <CountyPicker
            value={county}
            onChange={value => mergeFilter('county', value)}
          />
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>City</Label>
          <CityPicker
            value={city}
            onChange={value => mergeFilter('city', value)}
          />
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Zipcode</Label>
          <ZipPicker
            value={zip}
            onChange={value => mergeFilter('zip', value)}
          />
        </Box>
      </Box>

      <Box display='flex' mx={-3}>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Deal Size</Label>
          <DebounceInput value={minLent} onChange={onChangeMin}>
            <DealValueInput
              placeholder='min'
              mb={2}
            />
          </DebounceInput>
          <DebounceInput value={maxLent} onChange={onChangeMax}>
            <DealValueInput
              placeholder='max'
              mb={2}
            />
          </DebounceInput>
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Minimum Deal Count</Label>
          <DebounceInput value={minLoans} onChange={onChangeCount}>
            <NumberInput width={80} />
          </DebounceInput>
        </Box>
        <Box flex='1' mx={3} mb={5}>
          {/* <Label display="block">Most Recent Deal</Label>
          <NumberInput/> */}
        </Box>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center'>
        <Box mx={4}>
          <Caption color='red.500'>Potential Lenders</Caption>
          <Heading textAlign='center' size={3} color='red.900'>
            {!loading
              ? <Numeric>{counts}</Numeric>
              : <LoadingIcon />}
          </Heading>
        </Box>
        <Button mx={4} onClick={handleSearchClick} sizeVariant='large' variant='primary'>Show me the Leads</Button>
      </Box>
    </Card>
  )
}
