/* eslint "react/no-unescaped-entities": "warn", "react/no-string-refs": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'

import Card from 'common/Card'
import { Dialog } from '@realsoftworks/decor'

import UpgradeDialog from 'billing/components/UpgradeDialog'

import PermissionComponent from 'users/components/PermissionComponent'

import { actions as billingActions, selectors } from 'billing'
import logError from './logError'

class CMAUpgradeCTA extends Component {
  state = {
    modalShown: false,
    videoShown: false,
    valid: true,
    values: {}
  };

  closeModal = () => this.setState({ modalShown: false });

  render () {
    const { modalShown, videoShown } = this.state

    return (
      <Card className='cma-upgrade-cta'>

        <UpgradeDialog
          open={modalShown}
          onRequestClose={this.closeModal}
        />

        {
          videoShown
            ? <Dialog
              open={true}
              onClose={this.hideModals}
              width={970}
              maxWidth='100%'
              contentProps={{ borderRadius: 0, p: 0 }}
            >
              <div className='embed-responsive embed-responsive-16by9'>
                <ReactPlayer
                  url='https://www.youtube.com/watch?v=4Oa6ndyNUrQ'
                  height='100%'
                  width='100%'
                />
              </div>
            </Dialog>
            : null
        }

        <div className='row'>
          <div className='col-md-5'>
            <h1>You're just one step away from running comps!</h1>
            <p>Upgrade to a Pro account and get access to real estate comparables for your leads.
              Start running comps today!</p>
            <img src='/img/texcel-big.png' className='broker-logo'/>
          </div>
          <div className='col-md-7'>
            <div className='teaser' onClick={this.showVideo}></div>
          </div>
        </div>

        <div className='features'>
          <ul>
            <li>Unlimited CMA Reports</li>
            <li>Property Lists</li>
            <li><span>Priority</span> Support</li>
            <li>Unlimited Users</li>
          </ul>
        </div>
        <PermissionComponent model='admin' action='company'>
          <a className='btn btn-upgrade' onClick={this.showModal}>
            <div className='row'>
              <div className='col-md-6 col-md-offset-2'>
                <div className='title'>
                  Upgrade to Pro!
                  <small>Month-to-month - <em>Cancel Any time!</em></small>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='price-box'>
                  <div className='price'>
                    <span className='price-amount'>$98</span><small>Per <br/>Month</small>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </PermissionComponent>

        <PermissionComponent not={true} model='admin' action='company'>
          <h2 style={{ textAlign: 'center' }}>Ask your team leader to upgrade your account</h2>
        </PermissionComponent>

      </Card>
    )
  }

  showModal = e => {
    e.preventDefault()
    this.setState({ modalShown: true })
  };

  showVideo = e => {
    e.preventDefault()
    this.setState({ videoShown: true })
  };

  hideModals = () => {
    this.setState({ modalShown: false, videoShown: false })
  };

  onValidationChange = valid => {
    this.setState({ valid })
  };

  onError (error) {
    if (this.props.onError)
      this.props.onError(error)
  }

  save = () => {
    if (this.refs.form.validate()) {
      var { values } = this.state
      this.props.changePlan('pro', values)
    }
  };

  onChange = values => {
    this.setState({ values })
  };

  componentDidMount () {
    if (window.analytics && window.analytics.track)
      try {
        analytics.track('visited upgrade')
      } catch (e) {
        logError(e)
      }
  }
}

const mapStateToProps = state => ({
  paid: selectors.isPaid(state),
  upgrading: selectors.isUpgrading(state),
  error: selectors.getUpgradeError(state)
})

const mapDispatchToProps = {
  changePlan: billingActions.changePlan
}

CMAUpgradeCTA = connect(mapStateToProps, mapDispatchToProps)(CMAUpgradeCTA)

export default CMAUpgradeCTA
