import { useState, useEffect, memo } from 'react'
import { bool, arrayOf, number, func, object } from 'prop-types'
import { useMapApi } from 'common/Map'
import useListenerAutoCleaner from 'common/useListenerAutoCleaner'
import Polygon from './Polygon'
import theme from '../../../../theme'
import { isGeoJsonArrClosed, geoJsonToLatLngLiteral } from './utils'

// NOTE: don't recreate the polygon and styles objects if not
//   necessary to prevent recalculations due to props change
const PolygonDrawer = memo(({
  drawing,
  polygon = [],
  onFinish,
  markerStyles = {},
  markerDisabledStyles = {},
  lineStyles = {},
  planeStyles = {},
  ...props
}) => {
  const { map, maps } = useMapApi()
  const [marker, setMarker] = useState()
  const cleanListenerLater = useListenerAutoCleaner()
  const [internalPolygon, setInternalPolygon] = useState(polygon)

  useEffect(() => {
    if (internalPolygon !== polygon) setInternalPolygon(polygon)
  }, [polygon])

  initiateDoneButton({
    map,
    marker,
    maps,
    markerStyles,
    setMarker
  })

  updateDoneButton({
    map,
    marker,
    maps,
    cleanListenerLater,
    onFinish,
    markerStyles,
    markerDisabledStyles,
    internalPolygon
  })

  updateMapClickHandler({
    map,
    marker,
    drawing,
    onFinish,
    cleanListenerLater,
    internalPolygon,
    setInternalPolygon
  })

  updateCursor({
    map,
    marker,
    drawing,
    internalPolygon
  })

  return (
    <Polygon
      polygon={internalPolygon}
      lineStyles={lineStyles}
      planeStyles={planeStyles}
      {...props}
    />
  )
})

export default PolygonDrawer

PolygonDrawer.propTypes = {
  drawing: bool.isRequired,
  polygon: arrayOf(arrayOf(number.isRequired)),
  onFinish: func.isRequired,
  markerStyles: object,
  markerDisabledStyles: object
}

/* Subprocedures */

const initiateDoneButton = ({
  map,
  marker: existingMarker,
  maps,
  markerStyles,
  setMarker
}) => {
  useEffect(() => {
    // Note that we create marker only once per instance
    if (!map || existingMarker) return

    // Serves as "Done" button -- a clickable first point
    const marker = new maps.Marker({
      map: map,
      cursor: 'pointer',
      icon: {
        ...DEFAULT_CIRCLE_STYLES({ maps }),
        ...markerStyles
      }
    })

    setMarker(marker)
  }, [map, existingMarker])
}

const updateDoneButton = ({
  map,
  marker,
  maps,
  cleanListenerLater,
  onFinish,
  markerStyles,
  markerDisabledStyles,
  internalPolygon
}) => {
  useEffect(() => {
    if (!map || !marker) return

    if (isGeoJsonArrClosed(internalPolygon)) {
      marker.setMap(null)
      return
    }

    const pathCount = internalPolygon.length

    // Add disabled done button if it has 1 or 2 points
    if (pathCount === 1 || pathCount === 2) {
      const firstPoint = internalPolygon[0]

      const listener = marker.addListener('click', () => {})
      cleanListenerLater('done', listener)

      marker.setOptions({
        cursor: 'not-allowed',
        icon: {
          ...DEFAULT_CIRCLE_STYLES({ maps }),
          ...DEFAULT_CIRCLE_DISABLED_STYLES({ maps }),
          ...markerStyles,
          ...markerDisabledStyles
        }
      })

      marker.setPosition(geoJsonToLatLngLiteral(firstPoint))
      marker.setMap(map)

      // Add enabled done button if it has 3 or more points
    } else if (pathCount >= 3) {
      const firstPoint = internalPolygon[0]

      const listener = marker.addListener('click', () => {
        // call callback with closed, simple path
        onFinish([...internalPolygon, internalPolygon[0]])
      })

      marker.setOptions({
        cursor: 'pointer',
        icon: {
          ...DEFAULT_CIRCLE_STYLES({ maps }),
          ...markerStyles
        }
      })

      marker.setPosition(geoJsonToLatLngLiteral(firstPoint))
      marker.setMap(map)

      cleanListenerLater('done', listener)

      // Otherwise remove the done button
    } else { marker.setMap(null) }
  }, [map, marker, onFinish, internalPolygon])
}

const updateMapClickHandler = ({
  map,
  marker,
  drawing,
  onFinish,
  cleanListenerLater,
  internalPolygon,
  setInternalPolygon
}) => {
  useEffect(() => {
    if (!map || !marker) return

    const listener = map.addListener('click', ({ latLng }) => {
      if (!drawing || isGeoJsonArrClosed(internalPolygon)) return

      // Add point to marker
      setInternalPolygon(p => ([...p, [latLng.lat(), latLng.lng()]]))
    })

    cleanListenerLater('map', listener)
  }, [map, marker, drawing, onFinish, internalPolygon])
}

const updateCursor = ({
  map,
  marker,
  drawing,
  internalPolygon
}) => {
  useEffect(() => {
    if (!map || !marker) return

    map.setOptions({
      draggableCursor: isGeoJsonArrClosed(internalPolygon)
        ? null
        : drawing
          ? 'crosshair'
          : null
    })
  }, [map, marker, drawing, internalPolygon])
}

/* Constants */

const { colors } = theme

const DEFAULT_CIRCLE_STYLES = ({ maps }) =>
  ({
    path: maps.SymbolPath.CIRCLE,
    scale: 6,
    strokeWeight: 0,
    fillColor: colors.yellow[500],
    fillOpacity: 1
  })

const DEFAULT_CIRCLE_DISABLED_STYLES = ({ maps }) =>
  ({
    path: maps.SymbolPath.CIRCLE,
    scale: 6,
    strokeWeight: 0,
    fillColor: colors.yellow[300],
    fillOpacity: 1
  })
