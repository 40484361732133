import React from 'react'
import { Text } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/free-regular-svg-icons'

const SmallFatalError = () => (
  <Text fontSize={4} color='neutral.400'><FontAwesomeIcon icon={faFrown} /></Text>
)

export default SmallFatalError
