import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faThumbsDown,
  faThumbsUp,
  faQuestion,
  faHome,
  faCog,
  faCheck,
  faTimes,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faThumbsDown,
  faThumbsUp,
  faQuestion,
  faHome,
  faCog,
  faCheck,
  faTimes,
  faTrashAlt
)
