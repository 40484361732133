/* eslint "no-prototype-builtins": "warn" */
import React, { Component } from 'react'
import CMAMarker from 'common/MapboxMLSMarker'
import styled from 'styled-components'
import { Box, Card } from '@realsoftworks/decor'
import MapboxReact from '../../../../modules/common/Map/components/MapboxReact'
import { Marker } from 'react-map-gl'

function getKey ({ lat, lon }) {
  return `${lat},${lon}`
}

const Wrap = styled(Box)`
  overflow: hidden;
`

const CenterMarker = styled(Card)``

export default class DealAlertMap extends Component {
  render () {
    const { defaultCenter, defaultZoom, listings } = this.props
    const includedListings = listings.filter(v => !v.hasOwnProperty('excluded') || !v.excluded)

    const coordMap = {}
    let lat = 0
    let lon = 0

    includedListings.forEach(c => {
      const geoKey = getKey({ lat: c.address_lat, lon: c.address_lon })

      if (!coordMap[geoKey])
        coordMap[geoKey] = { lat: c.address_lat, lon: c.address_lon, listings: [] }

      lat += c.address_lat
      lon += c.address_lon

      coordMap[geoKey].listings.push(c)
    })

    let center = defaultCenter
    if (!center)
      center = { lat: lat / listings.length, lon: lon / listings.length }

    let zoom = defaultZoom
    if (!zoom)
      zoom = 15

    const markerCoordinates = () => {
      const markers = []
      Object.keys(coordMap).forEach(key => {
        const { lat, lon } = coordMap[key]
        let markerJson = {}
        markerJson = {
          id: 'dealsAlertMarker',
          propertyAddressLat: lat,
          propertyAddressLon: lon
        }
        markers.push(markerJson)
      })
      return markers
    }

    var listingMarkers = Object.keys(coordMap).map(key => {
      var { listings, lat, lon } = coordMap[key]

      return <Marker
        key={key}
        latitude={lat}
        longitude={lon}
        offsetLeft={-15}
        offsetTop={-22}
      >
        <div style={{ position: 'absolute' }}>
          <CenterMarker border='2px solid' borderColor='transparent' bg='transparent' borderRadius={100} width={32} height={39}>
            <CMAMarker
              key={key}
              lat={lat}
              lng={lon}
              listings={listings}
              onClick={this.props.onMarkerClick}
            />
          </CenterMarker>
        </div>
      </Marker>
    })

    return (
      <Wrap height={400} mx={-20} my={-20}>
        <MapboxReact
          multipleResults={markerCoordinates()}
          cmaMarkers={listingMarkers}
          isDealAlertsDashboard={true}
        />
      </Wrap>
    )
  }
}
