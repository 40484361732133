import { get } from 'common/api'
import { PROPERTY_ENDPOINT } from './const'
import qs from 'qs'
import { selectProperty } from './selectors'
import { toast } from '@realsoftworks/decor'

export const FETCH_PROPERTY = 'propertypreviews/FETCH_PROPERTY'
export const FETCH_PROPERTY_SUCCESS = 'propertypreviews/FETCH_PROPERTY_SUCCESS'
export const FETCH_PROPERTY_FAILED = 'propertypreviews/FETCH_PROPERTY_FAILED'

export const fetchProperty = (address, propertyId) => (dispatch, getState) => {
  const id = propertyId || [address.line1, address.city, address.state, address.zip]
    .join(':')

  const existingProp = selectProperty(getState(), id)

  if (existingProp && existingProp.status !== 'FAILED') return Promise.resolve()

  dispatch({
    type: FETCH_PROPERTY,
    payload: { id }
  })

  const data = qs.stringify({
    ...address,
    ...(propertyId && { id: propertyId }),
    limit: 1
  })

  let errorType = ERROR_TYPES.GENERIC

  return get(`${PROPERTY_ENDPOINT}?${data}`)
    .then(resp => {
      if (resp && !resp.summary) errorType = ERROR_TYPES.NOT_FOUND
      return resp
    })
    .then(resp => {
      if (!resp.summary) throw new Error('Failed to fetch property')

      return resp
    })
    .then(property =>
      dispatch({
        type: FETCH_PROPERTY_SUCCESS,
        payload: { id, property }
      }))
    .catch(error => {
      const notif = ERROR_NOTIFS[errorType]
      toast.error(notif)
      dispatch({
        type: FETCH_PROPERTY_FAILED,
        payload: { id, error }
      })
    })
}

const ERROR_TYPES = {
  GENERIC: Symbol('ERROR_TYPE.GENERIC'),
  NOT_FOUND: Symbol('ERROR_TYPE.GENERIC')
}

const ERROR_NOTIFS = {
  [ERROR_TYPES.GENERIC]: {
    title: 'Failed to load property preview',
    content: 'We’re sorry. Please try again or check your internet connection.'
  },
  [ERROR_TYPES.NOT_FOUND]: {
    title: 'Property details not available',
    content: 'Sorry, our system doesn’t have the additional details for this property yet.'
  }
}
