import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './scripts'
import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import Router from './router'
import configureStore from './store'
import saga from './sagas'
import { Provider as ReduxProvider } from 'react-redux'
import { Provider as ThemeProvider } from '@realsoftworks/decor'
import theme from './theme'
import { initErrorReporter } from './app/errorReporter'
import './polyfill'
import '../less/styles.less'
import './icons'

import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js'
import 'blueimp-file-upload/js/jquery.iframe-transport.js'
import 'blueimp-file-upload/js/jquery.fileupload.js'
import 'blueimp-file-upload/js/jquery.fileupload-image.js'

// http://madhatted.com/2013/6/16/you-do-not-understand-browser-history
$(window).unload(function () {}) // Does nothing but break the bfcache

// fixes styles if users x out of streetview when it's maximized
function checkFullscreen () {
  var isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement

  if (isFullscreen)
    $(document.body).addClass('fullscreen')
  else
    $(document.body).removeClass('fullscreen')
}

$(document).on('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', checkFullscreen)

var initedStore = false
window.initStore = () => {
  if (initedStore) return

  const store = configureStore(App.initialState)
  store.runSaga(saga)

  window.REDUX_STORE = store

  initedStore = true
}

window.initApp = () => {
  const { initialState } = window.App
  const store = configureStore(initialState)
  store.runSaga(saga)
  initErrorReporter({ store })

  ReactDOM.render(
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ReduxProvider>
    , document.getElementById('app')
  )
}
