export const isGeoJsonArrClosed = path => {
  const firstPt = path[0]
  const lastPt = path[path.length - 1]

  if (path.length < 2) return false

  return (firstPt[0] === lastPt[0]) &&
    (firstPt[1] === lastPt[1])
}

export const geoJsonToLatLngLiteral = ([lat, lng]) => ({ lat, lng })

export const geoJsonArrToLatLngLiteralArr = path => path.map(geoJsonToLatLngLiteral)
