import React, { useState } from 'react'
import { connect } from 'react-redux'
import { string, number, bool, array } from 'prop-types'
import get from 'lodash/object/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Text, Icon, Heading } from '@realsoftworks/decor'
import { getSetting } from 'users/selectors'
import Collapsable from 'common/Collapsable'
import StepContent from './StepContent'

import {
  faChevronDown,
  faChevronUp,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

const TaskRow = ({
  id,
  title,
  estimatedTime,
  stepsCount,
  isCompleted,
  steps,
  isLast,
  onboarding,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box {...props}>
      <Box
        pl={[0, 4]}
        pr={[0, 5]}
        py={3}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        css='cursor: pointer;'
        role='button'
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={id}
      >
        <Box display='flex' alignItems='center' minWidth={16}>
          <Icon fontSize='1'>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </Icon>

          <Box flex={1} display='flex' alignItems='center' flexWrap='wrap' px={[4, 0]}>
            <Box flex={[undefined, 1]} px={[0, 3]} pb={[1, 0]} width={['100%', undefined]}>
              <Heading
                color={isCompleted ? 'neutral.500' : 'black'}
                size={6}
                id={`${id}-accordion-title`}
              >
                {title}
              </Heading>
            </Box>

            {estimatedTime && (
              <Text fontSize={2} color='neutral.500'>
                {estimatedTime}
              </Text>
            )}

            {typeof stepsCount === 'number' && (
              <Box display='flex' justifyContent='flex-end' minWidth={['auto', 80]} pl={[3, 0]}>
                <Text fontSize={2} color='neutral.500'>
                  {stepsCount} step{stepsCount > 1 && 's'}
                </Text>
              </Box>
            )}
          </Box>

          <Box pl={[0, 6]}>
            <Icon fontSize='4' variant='primary' style={{ visibility: isCompleted ? 'visible' : 'hidden' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </Icon>
          </Box>
        </Box>
      </Box>

      <Collapsable open={isOpen}>
        <Box
          id={id}
          role='region'
          aria-labelledby={`${id}-accordion-title`}
          px={[0, 4]}
          pt={2}
          pb={isLast ? 0 : 5}
        >
          <StepContent steps={steps} onboarding={onboarding} />
        </Box>
      </Collapsable>
    </Box>
  )
}

TaskRow.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  estimatedTime: string,
  stepsCount: number,
  isCompleted: bool,
  steps: array.isRequired,
  isLast: bool.isRequired
}

const withState = connect(
  (state, ownProps) => {
    const onboarding = getSetting(state, 'onboarding') || {}
    const isCompleted = ownProps.steps.map(step => step.completionKey)
      .filter(key => key)
      .every(key => get(onboarding, key))

    const steps = ownProps.steps.map(step =>
      ({
        ...step,
        isCompleted: !!(step.completionKey &&
          onboarding &&
          get(onboarding, step.completionKey))
      }))

    return { steps, isCompleted, onboarding }
  }
)

export default withState(TaskRow)
