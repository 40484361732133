import React from 'react'
import Container from 'drivingfordollars/components/Container'
import DrivesMapSection from './DrivesMapSection'
import DrivesListSection from './DrivesListSection'
import ListsListSection from './ListsListSection'
import { Heading } from '@realsoftworks/decor'

const Dashboard = () => (
  <Container mx='auto'>
    <Heading size={5}>Driving for Dollars</Heading>
    <DrivesMapSection mt={2} />

    <Heading mt={7} size={4}>Recent Drives</Heading>
    <DrivesListSection mt={2} />

    <Heading mt={7} size={4}>Marketing Lists</Heading>
    <ListsListSection mt={2} />
  </Container>
)

export default Dashboard
