import { combineReducers } from 'redux'
import {
  FETCH_BALANCE_SUCCESS,
  PURCHASE_CREDITS_SUCCESS,
  UPDATE_REFILL_SUCCESS,
  DELETE_REFILL_SUCCESS,
  FETCH_ORDERS_SUCCESS
} from './actions'

const credits = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_BALANCE_SUCCESS:
      return payload

    case PURCHASE_CREDITS_SUCCESS:
      return {
        ...state,
        available: payload.available
      }
  }

  return state
}

const refill = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_BALANCE_SUCCESS:
      return payload.refill

    case UPDATE_REFILL_SUCCESS:
      return payload

    case DELETE_REFILL_SUCCESS:
      return null
  }

  return state
}

const services = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_BALANCE_SUCCESS:
      return payload.services
  }

  return state
}

const orders = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        ...payload.entities.order
      }
  }
  return state
}

const ordersByRef = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ORDERS_SUCCESS: {
      if (meta.params.referenceId)
        return {
          ...state,
          [meta.params.referenceId]: payload.result.items
        }
    }
  }

  return state
}

export default combineReducers({
  credits,
  refill,
  services,
  orders,
  ordersByRef
})
