import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Menu, IconButton, toast, Checkbox, Button } from '@realsoftworks/decor'
import { useHistory } from 'search/HistoryContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { fetchTeamOnceAtATime } from 'users/actions'
import { connect } from 'react-redux'
import { getTeam } from 'users/selectors'
import logError from 'common/logError'
import Box from '@realsoftworks/decor/dist/components/Box'
import LoadingIcon from 'common/LoadingIcon'
import { selectIsReqMet } from 'common/selectors'

const withConnect = connect(
  state => ({
    team: Array.isArray(getTeam(state)) ? getTeam(state) : [],
    canViewAllTeamLeads: selectIsReqMet(
      state,
      [{ model: 'lead', action: 'view' }])
  }),
  { fetchTeam: fetchTeamOnceAtATime }
)

const HistoryUserFilter = withConnect(({
  team,
  fetchTeam,
  canViewAllTeamLeads
}) => {
  const { creator, setCreator, fetchHistory } = useHistory()
  const [internalCreator, setInternalCreator] = useState([])
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState('LOADING')
  const isAllChecked = internalCreator.length &&
    internalCreator.length === team.length
  const isActive = creator && creator.length

  const handleChange = newCreator => () =>
    setInternalCreator(cs =>
      cs.includes(newCreator)
        ? cs.filter(c => c !== newCreator)
        : [...cs, newCreator])

  const handleToggleIsOpen = useCallback(() => {
    // Reset checkboxes value to match context value
    if (isOpen) setInternalCreator(creator)
    setIsOpen(!isOpen)
  }, [isOpen, creator])

  const handleApply = useCallback(() => {
    setCreator(internalCreator)
    fetchHistory({ creator: internalCreator })
    setIsOpen(false)
  }, [internalCreator, setCreator, fetchHistory])

  const handleAllToggle = useCallback(() =>
    setInternalCreator(isAllChecked
      ? []
      : team.map(u => u.id)),
  [isAllChecked, team])

  // Fetch team/users, which are options for this filter
  useEffect(() => {
    if (!canViewAllTeamLeads) return
    fetchTeam()
      .then(() => setStatus('READY'))
      .catch(e => {
        logError(e)
        toast.error(FETCH_TEAM_FAILED_NOTIF)
        setStatus('ERROR')
      })
  }, [])

  if (!canViewAllTeamLeads) return null

  return (
    <>
      <IconButton
        fontSize='22px'
        color={isActive ? 'blue.500' : 'secondary'}
        ref={ref}
        onClick={handleToggleIsOpen}
      >
        <FontAwesomeIcon icon={faUser} />
      </IconButton>
      <Menu
        target={ref.current}
        open={isOpen}
        onClose={() => setIsOpen(null)}
        variant='dense'
      >
        {status === 'ERROR' ? null
          : status === 'LOADING' ? (
            <Box px={3} display='flex' justifyContent='center'>
              <LoadingIcon size={3} />
            </Box>
          ) : (
            <Box
              px={2}
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
              <Checkbox
                checked={isAllChecked}
                label='By Anyone'
                onClick={handleAllToggle}
              />

              {team.map(({ id, name }) => (
                <Checkbox
                  key={id}
                  checked={internalCreator.includes(id)}
                  label={`By ${name}`}
                  onClick={handleChange(id)}
                />
              ))}

              <Button
                variant='primary'
                sizeVariant='small'
                onClick={handleApply}
              >
              Apply Filters
              </Button>
            </Box>
          )}
      </Menu>
    </>
  )
})

export default HistoryUserFilter

const FETCH_TEAM_FAILED_NOTIF = {
  title: 'Failed to fetch lead creators',
  content: 'Sorry, we encountered issues while loading users. Filter by lead creators won’t work. Please try reloading the page.'
}
