import React from 'react'
import Avatar from '../common/Avatar.react'
import DateHelper from '../../core/date.js'
import UserName from 'common/UserName'

class Comment extends React.Component {
  render () {
    const c = this.props.comment
    const { user } = c

    return (
      <div className='comment'>
        <div className='comment-header'>
          <span className='byline'>
            <Avatar user={user} />
            <UserName user={user} />
          </span>
          <span className='text-muted'>{DateHelper.timeAgo(c.created)}</span>
        </div>
        <div className='comment-message'>
          {c.activity_data
            .split(/(\n)/)
            .map((t, i) =>
              t === '\n'
                ? <br key={i} />
                : t)}
        </div>
      </div>
    )
  }
}

export default Comment
