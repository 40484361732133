// TODO put modalIsOpen variable from contacts.js here

import {
  CONTACT_READ_SUCCESS, CLOSE_CONTACT_MODAL,
  SEARCH_CONTACTS_SUCCESS, SEARCH_CONTACTS, CLEAR_CONTACTS
} from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_CONTACTS_SUCCESS:
      return { ...state, isLoaded: true }
    case SEARCH_CONTACTS:
      return { ...state, isLoaded: false }
    case CLEAR_CONTACTS:
      return { ...state, isLoaded: false, isDetailsLoaded: false }
    case CONTACT_READ_SUCCESS:
      return { ...state, isDetailsLoaded: true }
    case CLOSE_CONTACT_MODAL:
      return { ...state, isDetailsLoaded: false }
  }
  return state
}
