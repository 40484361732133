/**
 * @module core/EntityFieldTypes
 */

var fieldtypes = [
  {
    name: 'text',
    label: 'Text',
    properties: [
      { type: 'radio', name: 'multiline', values: [{ label: 'Single Line', value: false }, { label: 'Multi Line', value: true }] }
    ],

    defaults: {
      multiline: false
    }
  },

  {
    name: 'number',
    label: 'Number'
  },
  {
    name: 'monetary',
    label: 'Monetary'
  },
  {
    name: 'date',
    label: 'Date',
    properties: [
      { type: 'radio', name: 'time', values: [{ label: 'No time entry', value: false }, { label: 'Include time entry', value: true }] }
    ]
  },
  {
    name: 'contacts',
    label: 'Contacts',
    hidden: true
  },
  {
    name: 'bool',
    label: 'Yes/No'
  },
  {
    name: 'address',
    label: 'Address'
  },
  {
    name: 'links',
    label: 'Web Link'
  },
  {
    name: 'files',
    label: 'Attachments'
  },
  {
    name: 'reference',
    label: 'Reference',
    hidden: true
  }
]

const EntityFieldTypes = {
  /**
   * @returns {Array} Full list of available model field types along with their configuration option
   */
  getFieldTypes: function (includeHidden) {
    var ret = fieldtypes.filter(function (f) {
      return !f.hidden || (includeHidden && f.hidden)
    })

    return ret
  },

  /**
   * @returns {Object} Like getFieldTypes but indexed by the "name" property
   */
  getFieldsByType: function (visibleOnly) {
    var fts = {}
    var fieldTypes = EntityFieldTypes.getFieldTypes(visibleOnly)
    fieldTypes.forEach(function (f) {
      fts[f.name] = f
    })

    return fts
  }
}

export default EntityFieldTypes
