import React, { useRef, useEffect } from 'react'
import { arrayOf, shape, string, bool, oneOfType, number, array, func } from 'prop-types'
import { connect } from 'react-redux'
import { Card, Box, Text, List, ListItem } from '@realsoftworks/decor'
import { RelativeDateTime } from 'common/format'
import { toggleNotif } from '../actions'

const NotificationsList = ({
  notifications,
  borderRadius: borderRadiusFromProps,
  toggleNotif,
  isSmall
}) => {
  const borderRadius = Array.isArray(borderRadiusFromProps)
    ? borderRadiusFromProps
    : typeof borderRadiusFromProps === 'number'
      ? [borderRadiusFromProps, borderRadiusFromProps, borderRadiusFromProps, borderRadiusFromProps]
      : [0, 0, 0, 0]

  return (
    <List variant='dense' py={0}>
      {notifications.map((n, i) => (
        <Card
          key={n.id}
          borderColor='neutral.200'
          borderTop={i === 0 ? '' : '1px solid'}
          onClick={() => { toggleNotif(n.id) }}
          bg={n.values.read ? 'white' : 'teal.100'}
          py={1}
          css={`
            border-top-right-radius: ${i === 0 ? borderRadius[0] : 0}px;
            border-bottom-right-radius: ${i === notifications.length - 1 ? borderRadius[1] : 0}px;
            border-bottom-left-radius: ${i === notifications.length - 1 ? borderRadius[2] : 0}px;
            border-top-left-radius: ${i === 0 ? borderRadius[3] : 0}px;
          `}
        >
          <ListItem display='flex'>
            <RenderHtml
              alignSelf='center'
              flex='1'
              fontSize={isSmall ? 0 : 1}
              html={n.values.text}
            />
            <Box alignSelf='flex-start' display='flex' flexDirection='column' alignItems='flex-end'pl={4}>
              <Text color='neutral.500' fontSize={0}>
                <RelativeDateTime>{n.created}</RelativeDateTime>
              </Text>
              <Card
                mt={1}
                width={12}
                height={12}
                borderRadius={6}
                bg={n.values.read ? 'neutral.300' : 'red.500'}
                css='cursor: pointer;'
              />
            </Box>
          </ListItem>
        </Card>
      ))}
    </List>
  )
}

NotificationsList.propTypes = {
  notifications: arrayOf(shape({
    id: string.isRequired,
    created: string.isRequired,
    values: shape({
      read: bool.isRequired,
      text: string.isRequired
    }).isRequired
  })).isRequired,
  borderRadius: oneOfType([number, array]),
  toggleNotif: func.isRequired,
  isSmall: bool
}

const withActions = connect(null, { toggleNotif })

export default withActions(NotificationsList)

const RenderHtml = ({ html, ...props }) => {
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return

    const links = ref.current.getElementsByTagName('a')
    if (!links || !links.length) return

    for (const link of links)
      link.onclick = ev => ev.stopPropagation()
  }, [])

  return (
    <Text ref={ref} dangerouslySetInnerHTML={{ __html: html }} {...props} />
  )
}
