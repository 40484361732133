import PropTypes from 'prop-types'
import React from 'react'

class FontIcon extends React.Component {
    static propTypes = {
      className: PropTypes.string.isRequired
    };

    render () {
      var { className, ...rest } = this.props

      return (
        <i className={'decorFontIcon font-icon ' + className} {...rest}/>
      )
    }
}

export default FontIcon
