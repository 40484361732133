import { useEffect, useRef, useMemo, useState } from 'react'
import once from 'lodash/function/once'

// TODO: listen to unblur too but prevent both handler from running at the same time
const useOnClickOutside = (callback, callbackDeps) => {
  const [listenCount, setListenCount] = useState(0)
  const clickAreaRef = useRef()

  // We want to do it `once` otherwise click and focusin could call it consecutively
  const memoCallback = useMemo(() => once(callback), [...callbackDeps, listenCount])

  useEffect(() => {
    const handler = ev => {
      const clickAreaEl = clickAreaRef.current

      if (clickAreaEl && !clickAreaEl.contains(ev.target) && ev.target !== clickAreaEl)
        memoCallback()
      else
        // When clicked/focusined inside again, we want to reset the `once` limit on callback so
        // that it runs again on next clicked/focusined outside
        setListenCount(curr => curr + 1)
    }

    window.addEventListener('click', handler)
    window.addEventListener('focusin', handler)

    return () => {
      window.removeEventListener('click', handler)
      window.removeEventListener('focusin', handler)
    }
  }, [memoCallback])

  return clickAreaRef
}

export default useOnClickOutside
