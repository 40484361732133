/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-find-dom-node": "warn", "react/no-string-refs": "warn" */

import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import moment from 'moment'
import $ from 'jquery'
import DateFieldMixin from './util/DateFieldMixin.react'
import BasicField from './util/BasicField.react'
import 'jquery-ui/datepicker'
import 'jquery-ui/position'

var SmartDateField = createReactClass({
  displayName: 'SmartDateField',
  mixins: [DateFieldMixin, BasicField],

  componentWillMount: function () {
    this.options = [{
      name: 'No due date',
      value: null
    }, {
      name: 'Today',
      value: moment().endOf('day')
    }, {
      name: 'Tomorrow',
      value: moment().endOf('day').add('d', 1)
    }, {
      name: 'Friday',
      value: moment().day(5)
    }, {
      name: 'Next Monday',
      value: moment().day(8)
    }, {
      name: 'Custom Date',
      value: 'custom'
    }]
  },

  onClick: function (val) {
    if (val != 'custom')
      this.setValue(val)
    else
      this.pickDate()

    return false
  },

  setValue: function (val) {
    if (this.props.onChange)
      this.props.onChange(this.formatValue(val))
  },

  pickDate: function () {
    var $pickerCt = $('#global-date-picker')
    if (!this.picker)
      this.picker = $pickerCt.datepicker()

    this.picker.datepicker('option', 'onSelect', this.onPickSelect)
    $pickerCt.show()
    $pickerCt.position({
      my: 'left top',
      at: 'left bottom',
      of: ReactDOM.findDOMNode(this)
    })

    $(document.body).on('click.smartdatefield', this.onBodyClick)
  },

  onPickSelect: function (value) {
    this.setValue(value)
    this.onFinish()
  },

  onBodyClick: function (e) {
    if (this.checkBlur(e)) {
      this.onFinish()
      $(document.body).off('click.smartdatefield')
    }
  },

  checkBlur: function (e) {
    return !$(e.target).closest('#global-date-picker').length && !$(e.target).closest('.ui-datepicker-header').length && !$(e.target).closest('.smart-date-field').length
  },

  onFinish: function () {
    var $pickerCt = $('#global-date-picker')
    $pickerCt.hide()
  },

  render: function () {
    var value = this.parseValue(this.props.value)
    var display = this.formatDisplay(value)

    if (!display)
      display = 'No due date'

    return (
      <div className='btn-group btn-block due-date-field smart-date-field' style={this.props.style}>
        <button type='button' className='btn btn-default dropdown-toggle btn-block' data-toggle='dropdown'>
          <span ref='display'>{display}</span> <span className='caret' />
        </button>
        <input type='hidden' name={this.props.name} value={this.props.value} />
        <ul className='dropdown-menu' role='menu'>
          {this.options.map(function (opt) {
            return (
              <li key={opt.value}><a href='#' onClick={this.onClick.bind(this, opt.value)}>{opt.name}</a></li>
            )
          }, this)}
        </ul>
      </div>
    )
  }
})

export default SmartDateField
