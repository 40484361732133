import React, { useState, useCallback } from 'react'
import { Card, Box, Text } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Monetary, DateTime } from 'common/format'
import { orDash } from 'common/util/renderHelpers'
import EmptyTableMsg from './EmptyTableMsg'
import RecordDetails from './RecordDetails'
import get from 'lodash/object/get'

const COL_WIDTHS = [36, 140, 140, 140, 140, 140, 140]
const COLS_SUM = COL_WIDTHS.reduce((a, b) => a + b, 0)
const BORDER_WIDTH = 2

const RecordTable = ({ data }) => {
  // To be used in the future if we make columns sortable
  const [sortBy, _setSortBy] = useState('foreclosure.recordingDate') // eslint-disable-line no-unused-vars

  const sortTable = useCallback((l, r) => new Date(get(l, sortBy, 0)) > new Date(get(r, sortBy, 0)) ? -1 : 1, [sortBy])

  return data && data.length ? (
    <Card
      width='100%'
      borderWidth={BORDER_WIDTH}
      borderColor='blue.200'
      borderStyle='solid'
      css='overflow: auto;'
    >
      <Box as='table' width='100%' minWidth={COLS_SUM}>
        <colgroup>
          {COL_WIDTHS
            .map((width, i) => (
              <col key={i} span='1' style={{ width }} />
            ))}
        </colgroup>

        <Box as='thead'>
          <Box as='tr'>
            {[
              '', // for expand/collapse icon
              'Recording Date',
              'Type',
              'Default Amount',
              'Judgement Amount',
              'Auction Date',
              'Opening Bid'
            ].map((label, i) => (
              <Text
                as='th'
                p={2}
                key={i}
                bg='blue.200'
                fontWeight='bold'
                fontSize={0}
              >
                {label}
              </Text>
            ))}
          </Box>
        </Box>
        <Box as='tbody'>
          {data.sort(sortTable).map((h, i) => (
            <RecordTableRow isEven={!!((i + 1) % 2)} key={i} {...h} />
          ))}
        </Box>
      </Box>
    </Card>
  ) : (
    <EmptyTableMsg>No Preforeclosure Activity</EmptyTableMsg>
  )
}

export default RecordTable

const RecordTableRow = data => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggleCollapse = useCallback(() => setIsCollapsed(v => !v), [])
  const {
    foreclosure: {
      defaultAmount,
      judgementAmount,
      type,
      recordingDate
    },
    auction: {
      date,
      openingBid
    },
    isEven
  } = data

  return (
    <>
      <Box as='tr'>
        <RecordTableCell
          colWidth={COL_WIDTHS[0]}
          isEven={isEven}
          textAlign='center'
          pl={3}
          pr={1}
          onClick={toggleCollapse}
          css='cursor: pointer;'
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[1]} isEven={isEven}>
          {orDash(recordingDate, v => (
            <DateTime format='MM/DD/YYYY'>{v}</DateTime>
          ))}
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[2]} isEven={isEven}>
          {orDash(type)}
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[3]} isEven={isEven}>
          {orDash(defaultAmount, v => (
            <Monetary>{v}</Monetary>
          ))}
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[4]} isEven={isEven}>
          {orDash(judgementAmount, v => (
            <Monetary>{v}</Monetary>
          ))}
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[5]} isEven={isEven}>
          {orDash(date, v => (
            <DateTime format='MM/DD/YYYY'>{v}</DateTime>
          ))}
        </RecordTableCell>

        <RecordTableCell colWidth={COL_WIDTHS[6]} isEven={isEven}>
          {orDash(openingBid, v => (
            <Monetary>{v}</Monetary>
          ))}
        </RecordTableCell>
      </Box>

      {!isCollapsed && (
        <Box as='tr'>
          <Box as='td' p={2} bg={isEven ? 'neutral.100' : 'white'} colSpan='7'>
            <RecordDetails data={data} />
          </Box>
        </Box>
      )}
    </>
  )
}

const RecordTableCell = ({
  isEven,
  colWidth,
  children,
  ...props
}) => (
  <Text
    as='td'
    bg={isEven ? 'neutral.100' : 'white'}
    fontSize={0}
    p={2}
    {...props}
  >
    {children}
  </Text>
)
