/* eslint "react/no-find-dom-node": "warn" */
import ReactDOM from 'react-dom'

import $ from 'jquery'

var PinnedMixin = {

  getInitialState: function () {
    return {
      floating: false,
      top: 0
    }
  },

  componentWillUnmount: function () {
    $(window).off('scroll', this.onBodyScroll)
  },

  componentDidMount: function () {
    $(window).on('scroll', this.onBodyScroll)
  },

  onBodyScroll: function () {
    var top = $(window).scrollTop()

    if (!this.scrollTop)
      this.scrollTop = $(ReactDOM.findDOMNode(this)).offset().top

    if (top > this.scrollTop)
      this.setState({ floating: true, top: top - this.scrollTop })
    else
      this.setState({ floating: false, top: null })
  }
}

export default PinnedMixin
