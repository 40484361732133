/* eslint "eqeqeq": "warn", "react/jsx-key": "warn", "react/no-deprecated": "warn", "camelcase": "warn", "no-fallthrough": "warn" */
import React, { Fragment } from 'react'
import FieldFactory from '../FieldFactory.react'
import EditorInput from '../form/EditorInput.react'
import { Box, Text } from '@realsoftworks/decor'
import LoadingIcon from 'common/LoadingIcon'

class ActionToolbar extends React.Component {
  onClick = (action, subaction) => {
    this.props.onAction(action, subaction)
  };

  render () {
    var left = []
    var right = []

    var target = left
    this.props.actions.forEach(function (b) {
      var ret = null

      if (b == '->') {
        target = right
        return
      }

      if (b.options)
        ret = (
          <div className='btn-group dropleft'>
            <button type='button' title={b.title} className={'btn dropdown-toggle ' + (b.className || ('btn-' + b.type))} data-toggle='dropdown'>
              {b.icon ? <span className={'glyphicon glyphicon-' + b.icon}></span> : ''}
              {b.label}
            </button>
            <ul className='dropdown-menu' role='menu'>

              {b.options.map(function (o) {
                return (
                  <li className={o.className || o.type}><a title={o.title} data-toggle='tooltip' href='#' onClick={this.onClick.bind(this, b.type, o.type)}>{o.label}</a></li>
                )
              }, this)}
            </ul>
          </div>
        )
      else
        ret = (
          <button
            type='button'
            title={b.title}
            className={'btn ' + (b.className || ('btn-' + b.type))}
            onClick={this.onClick.bind(this, b.type)}
            data-toggle='tooltip'
          >
            {b.icon ? <span className={'glyphicon glyphicon-' + b.icon}></span> : ''} {b.label}
          </button>
        )

      target.push(ret)
    }, this)

    var style = { top: Math.min(this.state.top + 10, 600), right: 0 }
    if (this.state.floating) {
      style.position = 'absolute'
      style.width = '100%'
    }

    return (
      <div className='toolbar'>
        <div style={style}>
          <div className='right'>
            {right}
          </div>
          <div className='left'>
            {left}
          </div>
        </div>

      </div>
    )
  }
}

const loadingJsx = (<Fragment>{' '}<LoadingIcon size={1} /></Fragment>)

class ModelForm extends React.Component {
  static defaultProps = {
    fields: [],
    model: {}
  };

  constructor (props, context) {
    super(props, context)

    var state = this.getStateFromProps(props)
    state.editing = false
    state.openLookup = false
    this.state = state
  }

  onChange = (name, value) => {
    if (value && value.target)
      value = value.target.value

    if (this.props.mode !== 'editor') {
      var v = this.state.values
      v[name] = value
      this.setState({ values: v })
      this.props.onChange && this.props.onChange(name, value)
    } else {
      this.onFinishEdit(name)
      this.props.onChange && this.props.onChange(name, value)
    }
  };

  onValidationChange = (name, value) => {
    this.setState({ valid: value })
  };

  onFinishEdit = name => {
    if (this.state.editing === name)
      this.setState({ editing: false })
  };

  componentWillReceiveProps (nextProps) {
    this.setState(this.getStateFromProps(nextProps))
  }

  getStateFromProps = props => ({
    values: props.model || {}
  });

  getField = d => {
    var loading = this.props.loading || []
    var isLoading = loading.indexOf(d.name) != -1

    if (d.deleted) {
      var v = this.state.values[d.name]
      if (typeof v === 'undefined' || v == '' || v == null)
        return null
    }

    if (d.label == 'Address')
      return (
        <Box minWidth='160px' maxWidth='50%' width='100%' p={2} key={d.id}>
          <Text display='block' fontSize={0} fontWeight='bold'>
            {d.label}{isLoading && loadingJsx}
          </Text>
          <Box mt={1}>
            {this.getInput(d)}
          </Box>
        </Box>
      )

    return (
      <Box minWidth='160px' maxWidth='50%' width='100%' p={2} key={d.id}>
        <Text display='block' fontSize={0} fontWeight='bold'>
          {d.label}{isLoading && loadingJsx}
        </Text>
        <Box mt={1}>{this.getInput(d)}</Box>
      </Box>
    )
  };

  getInput = props => {
    // copy fields except ones used by editor template
    var { entity_type, deleted, group, ...d } = props
    d = Object.assign({}, d)

    d.value = this.state.values[d.name]
    d.onChange = this.onChange.bind(this, d.name)
    d.onValidationChange = this.onValidationChange.bind(this, d.name)
    d.editable = true // TODO

    if (d.type == 'files') // TODO get rid of this some how. We need a new ajax uploader
      d.formData = { entity_id: this.props.entityId, entity_type: this.props.entityType, entity_field_name: d.name }

    var Ctr = null
    if (this.props.mode !== 'editor')
      switch (d.type) {
        default:
          Ctr = FieldFactory.getField(d.type, d)
      }
    else
      switch (d.type) {
        case 'propertyfacts':
          Ctr = FieldFactory.getField(d.type, d)
          d.editing = this.state.editing
          d.onChange = this.onChange
          d.onClick = this.onFieldClick
          d.onFinish = this.onFinishEdit
          d.values = this.state.values
          break
        case 'leadcontacts':
        case 'contacts':
        case 'list':
        case 'links':
          Ctr = FieldFactory.getField(d.type, d)
          break
        case 'files':
          // add file upload metadata here
          Ctr = FieldFactory.getField(d.type, d)
          break

        case 'number': // TODO remove
        case 'monetary':
          d.validator = FieldFactory.getValidator(d.type)

        default:
          // d.defaultValue = "Click to edit";
          d.editing = this.state.editing == d.name
          d.onClick = this.onFieldClick.bind(this, d.name)
          d.onFinish = this.onFinishEdit.bind(this, d.name)

          Ctr = React.createFactory(EditorInput)
      }

    if (this.props.beforeField)
      this.props.beforeField(d)

    return Ctr(d)
  };

  onFieldClick = name => {
    if (this.state.valid === false)
      return

    this.setState({ editing: name })
  };

  render () {
    var fieldDefs = this.props.fields
    var fields = fieldDefs.map(this.getField, this)

    return (
      <div className='model-form'>
        <Box display='flex' flexWrap='wrap'>
          {fields}
        </Box>
        {this.props.actions ? <ActionToolbar actions={this.props.actions} onAction={this.onAction}/> : ''}
      </div>
    )
  }

  onAction = (type, subtype) => {
    var action = this.props.actions.find(function (a) {
      return a.type == type
    })

    if (typeof subtype === 'string')
      action = action.options.find(function (o) {
        return o.type == subtype
      })

    action.handler(this.state.values, action)
  };
}

export default ModelForm
