import React, { useState, useCallback, useMemo } from 'react'
import { Card, Heading, Box, IconButton, Input } from '@realsoftworks/decor'
import HistorySort from './HistorySort'
import HistoryFilter from './HistoryFilter'
import HistoryUserFilter from './HistoryUserFilter'
import { SIDEBAR_HEADER_HT } from './SearchSidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'search/HistoryContext'
import debounce from 'lodash/function/debounce'

const DEBOUNCE_RATE = 300
const PADDING_Y = 4

const SearchSidebarHeader = () => {
  const {
    query,
    isSearchingHistory,
    handleStartSearch,
    handleEndSearch,
    handleQueryChange
  } = useSearchHistoryLogic()

  return (
    <Card
      pl={1}
      display='flex'
      flexWrap='wrap'
      flexShrink={0}
      justifyContent='flex-end'
      alignItems='center'
      boxShadow={1}
      css='position: relative; z-index: 1;'
      placeholder='Type an address'
      minHeight={SIDEBAR_HEADER_HT}
    >
      {isSearchingHistory ? (
        <>
          <Box pr={1} py={`${PADDING_Y}px`} flexGrow='1'>
            <Input
              width='100%'
              height={`${SIDEBAR_HEADER_HT - (PADDING_Y * 2)}px`}
              value={query}
              onChange={handleQueryChange}
            />
          </Box>
          <Box p='2px' display='flex'>
            <IconButton
              fontSize='22px'
              color='secondary'
              onClick={handleEndSearch}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Box>
        </>
      ) : (
        <>
          <Heading px={2} py={1} flexGrow='1' as='h2' size={6}>Search History</Heading>
          <Box pl={1} display='flex'>
            <Box p='2px'>
              <IconButton
                fontSize='22px'
                color='secondary'
                onClick={handleStartSearch}
              >
                <FontAwesomeIcon icon={faSearch} />
              </IconButton>
            </Box>

            <Box p='2px'><HistoryUserFilter /></Box>
            <Box p='2px'><HistoryFilter /></Box>
            <Box p='2px'><HistorySort /></Box>
          </Box>
        </>
      )}
    </Card>
  )
}

export default SearchSidebarHeader

const useSearchHistoryLogic = () => {
  const [query, setQuery] = useState('')
  const [isSearchingHistory, setIsSearchingHistory] = useState(false)
  const { setQueryAndfetchHistory } = useHistory()
  const [hasTyped, setHasTyped] = useState(false)

  const handleStartSearch = useCallback(() => setIsSearchingHistory(true), [])

  const handleEndSearch = useCallback(() => {
    setIsSearchingHistory(false)
    setHasTyped(true)
    setQuery('')
    if (hasTyped) setQueryAndfetchHistory(null)
  }, [hasTyped])

  const handleQueryChange = useMemo(() => {
    const debouncedSearch = debounce(query => {
      if (!query) return
      setQueryAndfetchHistory(query)
    }, DEBOUNCE_RATE)

    return ev => {
      const query = ev.target.value
      setQuery(query || '')
      if (query) setHasTyped(true)
      debouncedSearch(query)
    }
  }, [])

  return {
    query,
    isSearchingHistory,
    handleStartSearch,
    handleEndSearch,
    handleQueryChange
  }
}
