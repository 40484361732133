import React, { useMemo } from 'react'
import { Text, Box } from '@realsoftworks/decor'
import { object } from 'prop-types'
import { isNonEmpty } from 'common/util/renderHelpers'

const DataView = ({ data, shouldRemoveEmptyData, tdProps = {}, ...props }) => {
  const entries = useMemo(() => data && Object.entries(data), [data])
  const finalEntries = shouldRemoveEmptyData
    ? entries.filter(([k, v]) => isNonEmpty(v))
    : entries

  if (!data) return null

  return (
    <Box as='table' mx='auto' {...props}>
      <tbody>
        {finalEntries.map(([label, value], i) => (
          <Box as='tr' key={i}>
            <Text
              as='td'
              px={2}
              py='2px'
              fontSize={0}
              textAlign='right'
              color='secondary'
              {...tdProps}
            >
              {label}
            </Text>

            <Text as='td' px={2} py='2px' fontSize={0} {...tdProps}>
              {value}
            </Text>
          </Box>
        ))}
      </tbody>
    </Box>
  )
}

DataView.propTypes = {
  data: object
}

export default DataView
