/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn" */
import React from 'react'
import Contact from './Contact.react'
import CategoryLinks from '../../../views/CategoryLinks.react'
import { search } from '../actions'
import LoadingIcon from 'common/LoadingIcon'
import debounce from 'lodash/function/debounce'

class ContactSearchBar extends React.Component {
  onChange = (name, v) => {
    if (v.target)
      v = v.target.value

    var changed = {}
    changed[name] = v

    var p = Object.assign({}, this.props.params, changed)
    this.props.onChange(p)
  };

  render () {
    var states = [{ label: 'active', value: 'active' }, { label: 'deleted', value: 'deleted' }]
    var selected = this.props.params.show

    return (
      <div className=''>
        <div className='search-bar'>
          <div className='text-wrap'>
            <input type='text' className='form-control contacts-search-field' placeholder='Search...' value={this.props.params.name} onChange={this.onChange.bind(this, 'param')}/>
            {!this.props.isReady ? <LoadingIcon /> : ''}
          </div>
          <CategoryLinks categories={states} selected={[selected]} onChange={this.onChange.bind(this, 'show')}/>
        </div>
        <h1><span className='no-text-wrap'>All Contacts</span></h1>
      </div>
    )
  }
}

class Rolodex extends React.Component {
  constructor (props) {
    super(props)
    const params = { count: true, offset: 0, limit: 50, show: 'active' }
    this.state = { params: params }
  }

  componentWillMount () {
    this.doSearch()
    this.search = debounce(this.startSearch, 500)
  }

  render () {
    let contacts = (<div></div>)

    if (contacts.length == 0 && this.props.count == 0)
      contacts = <h3 className='text-muted'>No contacts found</h3>
    else
      contacts = this.props.contacts.map(function (item) {
        return (<Contact key={'item' + item.id} link={true} contact={item}/>)
      }, this)

    return (

      <div className='rolodex'>
        <ContactSearchBar isReady={this.props.isReady} params={this.state.params} onChange={this.onParamsChange}/>
        <div className='list-view'>
          {contacts}
          {/* <RolodexLetters usedLetters={letters}/> */}
        </div>
        <PagingToolbar params={this.state.params} count={this.props.count} onPageClick={this.onPageClick}/>
      </div>

    )
  }

  onParamsChange = p => {
    var params = Object.assign({}, this.state.params, p)
    this.setState({ params: params }, this.search)
  };

  onPageClick = offset => {
    var params = this.state.params
    params.offset = offset

    this.setState({ params: params }, function () {
      this.doSearch()
    })
  };

  startSearch = () => {
    var p = this.state.params
    p.offset = 0
    this.setState({ params: p }, this.doSearch)
  };

  doSearch = () => {
    this.props.dispatch(search(this.state.params))
  };
}

// TODO remove this in favor of new one

class PagingToolbar extends React.Component {
  render () {
    var count = this.props.count
    var offset = this.props.params.offset
    var limit = this.props.params.limit

    var pageCount = Math.ceil(count / limit)
    var hidden = (pageCount <= 1)
    var currentPage = offset / limit

    var maxPages = 4
    var startPage = Math.max(0, Math.floor(currentPage - maxPages))
    var endPage = Math.min(pageCount, Math.floor(currentPage + maxPages + 1))

    if (startPage == 0)
      endPage = Math.min(pageCount, 2 * maxPages + 1)

    if (endPage == pageCount - 1)
      startPage = Math.max(0, endPage - (2 * maxPages + 1))

    var pages = []
    for (var i = startPage; i < endPage; i++) {
      var isCurrent = (i == currentPage)
      pages.push(
        <li key={i} className={isCurrent ? 'active' : ''}>
          <a href='#' onClick={!isCurrent ? this.onPageClick.bind(this, i) : function (e) { e.preventDefault() }}>{i + 1}</a>
        </li>
      )
    }

    if (startPage != 0)
      pages.splice(0, 0, <li key='spacer1'><span>...</span></li>)

    if (endPage != pageCount)
      pages.splice(pages.length, 0, <li key='spacer2'><span>...</span></li>)

    return (
      <div>
        <nav className={hidden ? 'hide' : ''}>
          <ul className='pagination'>
            <li key='first'><a href='#' onClick={this.onPageClick.bind(this, 0)}>&laquo;</a></li>
            <li key='prev'>{currentPage != 0 ? <a href='#' onClick={this.onPageClick.bind(this, currentPage - 1)}>&lt;</a> : <span>&lt;</span>}</li>
            {pages}
            <li key='next'>{currentPage != pageCount - 1 ? <a href='#' onClick={this.onPageClick.bind(this, currentPage + 1)}>&gt;</a> : <span>&gt;</span>}</li>
            <li key='last'><a href='#' onClick={this.onPageClick.bind(this, pageCount - 1)}>&raquo;</a></li>
          </ul>
        </nav>
      </div>
    )
  }

  onPageClick = (num, e) => {
    e.preventDefault()

    var oldoffset = this.props.params.offset
    var offset = num * this.props.params.limit

    if (oldoffset != offset)
      this.props.onPageClick(offset)
  };
}

export default Rolodex
