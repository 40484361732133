/* eslint "react/no-deprecated": "warn", "no-prototype-builtins": "warn", "eqeqeq": "warn", "react/no-string-refs": "warn" */
import PropTypes from 'prop-types'
import React from 'react'

import forIn from 'lodash/object/forIn'
import memoize from 'lodash/function/memoize'
import objectget from 'lodash/object/get'
import isEqual from 'lodash/lang/isEqual'

class Form extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onValidationChange: PropTypes.func,
    initialValues: PropTypes.object,
    defaultProps: PropTypes.object
  };

  static defaultProps = {
    defaultProps: {}
  };

  state = {
    errorTexts: {},
    values: this.props.initialValues || {}
  };

  componentWillMount () {
    this.allFields = {}
    this.bindFieldHandler = memoize(this.bindFieldHandler, (fn, name) => name + fn)
  }

  render () {
    const { className } = this.props

    return (
      <div className={'form ' + (className || '')}>
        {
          React.Children.map(this.props.children, this.getField)
        }
      </div>
    )
  }

  getField = c => {
    const props = Object.assign({}, this.props.defaultProps, c.props)
    const { name } = props
    let { value } = props

    if (typeof value === 'undefined')
      value = this.getValue(name)

    return React.cloneElement(c, {
      ...props,
      onChange: this.onChange.bind(this, name), // TODO this.bindFieldHandler(this.onChange, name),
      onBlur: this.onBlur.bind(this, name), // TODO this.bindFieldHandler(this.onBlur, name),
      errorText: this.state.errorTexts[name],
      value: value,
      ref: 'field_' + name,
      attachToForm: this.attach
    })
  };

  componentWillReceiveProps (newprops) {
    if (newprops.hasOwnProperty('values') && !isEqual(newprops.values && this.props.values))
      this.setState({ values: newprops.values })
  }

  getValue = name => objectget(this.state.values, name);

  bindFieldHandler = (fn, name) => fn.bind(this, name);

  onChange = (name, value) => {
    const { values, errorTexts } = this.state

    const newstate = {
      values: Object.assign({}, values, { [name]: value })
    }

    var newErrorTexts = this.validateIfEvent('change', name, value)
    if (newErrorTexts) { newstate.errorTexts = newErrorTexts } else if (!this.shouldValidateOn('change', name)) { // reset validation for fields that validate on blur
      newErrorTexts = Object.assign({}, errorTexts)
      delete newErrorTexts[name]

      newstate.errorTexts = newErrorTexts
    }

    this.setState(newstate)
    if (newErrorTexts)
      this.fireValidationEvents(newErrorTexts)

    this.props.onChange && this.props.onChange(newstate.values)
  };

  // return an object of new validation errors or false if no validation changed
  validateIfEvent = (event, name, value) => {
    if (this.shouldValidateOn(event, name)) {
      var { errorTexts } = this.state

      var errorMsg = this.validateField(name, value)
      if (errorMsg !== errorTexts[name]) {
        var newerrorTexts = Object.assign({}, errorTexts)

        if (errorMsg === true)
          delete newerrorTexts[name]
        else
          newerrorTexts[name] = errorMsg
      }

      return newerrorTexts
    }
    return false
  };

  onBlur = name => {
    var errorTexts = this.validateIfEvent('blur', name, this.state.values[name])
    if (errorTexts) {
      this.setState({ errorTexts })
      this.fireValidationEvents(errorTexts)
    }
  };

  fireValidationEvents = newTexts => {
    var valid = Object.keys(newTexts).reduce((valid, key) => {
      var value = newTexts[key]
      if (valid && value)
        return false

      return valid
    }, true)

    this.props.onValidationChange && this.props.onValidationChange(valid, newTexts)
    if (valid && this.props.onValid)
      this.props.onValid()
    else if (!valid && this.props.onInvalid)
      this.props.onInvalid(newTexts)
  };

  shouldValidateOn = (event, name) => {
    const validateEvents = this.getFieldProps(name).validateOn || 'blur'
    return validateEvents === event
  };

  validateField = (name, value) => {
    var { validator } = this.getFieldProps(name)

    if (!validator)
      return true

    if (!Array.isArray(validator))
      validator = [validator]

    var errorMsg = true

    for (var i = 0, len = validator.length; i < len; i++) {
      const f = validator[i]
      errorMsg = this.runValidator(f, value, this.state.values)
    }

    return errorMsg
  };

  validate = () => {
    var { values } = this.state
    var errorTexts = {}
    forIn(this.allFields, (value, name) => {
      var valid = this.validateField(name, values[name])
      if (valid !== true)
        errorTexts[name] = valid
    })

    this.setState({ errorTexts })
    this.fireValidationEvents(errorTexts)

    return Object.keys(errorTexts).length == 0
  };

  getFieldProps = name => this.refs['field_' + name].props;

  runValidator = (validator, value, allvalues) => {
    var validationResult = validator(value, allvalues)
    // result could be true or string/false
    validationResult = validationResult || 'This field is invalid'

    return validationResult
  };

  attach = field => {
    this.allFields[field.props.name] = field
  };
}

export default Form
