import get from 'lodash/object/get'

export const hasTrialed = state => getMy(state).has_trialed

export const getMy = state => state.enterprises.my

export const getCurrentEnterpriseId = state =>
  get(state, 'enterprises.my.id')

export const getPlanStart = state => {
  const my = getMy(state)
  return my.plan_start ? new Date(my.plan_start) : null
}
