'use strict'
import React from 'react'
import createReactClass from 'create-react-class'
import ModalLayerMixin from './ModalLayerMixin.react'

var Modal = createReactClass({
  displayName: 'Modal',
  mixins: [ModalLayerMixin],

  getDefaultProps () {
    return {
      show: false
    }
  },

  renderHeader: function () {
    var closeButton
    if (this.props.closeButton)
      closeButton = (
        <button type='button' className='close' aria-hidden='true' onClick={this.props.onRequestClose}>&times;</button>
      )

    return (
      <div className='modal-header'>
        {closeButton}
        {this.renderTitle()}
      </div>
    )
  },

  renderTitle: function () {
    return (
      React.isValidElement(this.props.title)
        ? this.props.title : <h4 className='modal-title'>{this.props.title}</h4>
    )
  },

  renderLayer () {
    return (
      <div className={'modal-dialog ' + (this.props.className || '')}>
        <div className='modal-content'>
          {this.props.title ? this.renderHeader() : null}
          {this.props.children}
        </div>
      </div>
    )
  }
})

export default Modal
