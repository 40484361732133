import React from 'react'
import { connect } from 'react-redux'
import { Box, Text, Heading, Caption } from '@realsoftworks/decor'
import BigLoader from 'common/BigLoader'
import formatLeadToHistory from 'search/formatLeadToHistory'
import { orDash, isNonEmpty, orNull, isEmpty } from 'common/util/renderHelpers'
import totalBaths from 'common/util/totalBaths'
import * as moment from 'moment-timezone'
import SituationsPill from 'leadlists/components/Sellers/components/SituationsPill'
import PropertyPill from 'search/components/PropertyPill'
import { selectProperty } from 'propertypreviews/selectors'
import { createSelector } from 'reselect'
import { Monetary, Numeric } from 'common/format'

const selectFormattedProperty = createSelector(
  selectProperty,
  ({ status, data } = {}) =>
    ({
      status: status || 'LOADING',
      property: formatLeadToHistory({ property: data })
    })
)

const withConnect = connect((state, ownProps) => {
  const { address, propertyId } = ownProps
  const id = propertyId || [address.line1, address.city, address.state, address.zip]
    .join(':')
  const { status, property } = selectFormattedProperty(state, id)
  return { status, property }
})

const PropertyPreview = withConnect(({
  status,
  property
}) => {
  if (status === 'LOADING') return <LoadingHandler />

  if (status === 'FAILED') return <ErrorHandler />

  return (
    <Box as='section'>
      {/* Property Address */}
      <Box m={-1} display='flex' flexWrap='wrap' alignItems='baseline'>
        <Heading p={1} as='h2' size={6}>
          {property.propertyAddressLine1}
        </Heading>
        <Gray p={1}>
          {property.propertyAddressCity},
          {' '}{property.propertyAddressState}
          {' '}{property.propertyAddressZip}
        </Gray>
      </Box>

      <Box m={-2} mt={-2} display='flex' flexWrap='wrap'>
        {/* Beds or Baths */}
        <Small p={2}>
          {orDash(property.beds)} <Gray>beds</Gray>
          {' '}/{' '}
          {orDash(totalBaths(property))} <Gray>baths</Gray>
        </Small>

        {/* Sqft or Year Built */}
        <Small p={2}>
          {orDash(property.sqft, v => <Numeric>{v}</Numeric>)} <Gray>sqft</Gray>
        </Small>

        {/* Sqft or Year Built */}
        <Small p={2}>
          {orDash(property.yearBuilt)} <Gray>build</Gray>
        </Small>
      </Box>

      <Box m={-2} pt={1} display='flex' flexWrap='wrap'>
        <Box p={2}>
          <GrayCap>EST. EQUITY</GrayCap>
          <Small mt='-2px'>
            {orDash(property.equity, v => <Monetary decimals={0}>{v}</Monetary>)}
          </Small>
        </Box>

        <Box p={2}>
          <GrayCap>EST. VALUE</GrayCap>
          <Small mt='-2px'>
            {orDash(property.value, v => <Monetary decimals={0}>{v}</Monetary>)}
          </Small>
        </Box>

        <Box p={2}>
          <GrayCap>LAST SOLD</GrayCap>
          <Small mt='-2px'>
            {orDash(property.lastSalePrice, v => <Monetary decimals={0}>{v}</Monetary>)}
            {isNonEmpty(property.lastSalePrice) &&
              isNonEmpty(property.lastSaleDate) &&
                <br />}
            {isEmpty(property.lastSalePrice) &&
              isNonEmpty(property.lastSaleDate) &&
              ' '}
            {orNull(property.lastSaleDate, v => {
              const diff = moment().diff(v, 'years')
              return diff < 1 ? 'less than a year ago' : `${diff} years ago`
            })}
          </Small>
        </Box>
      </Box>

      <Box pt={2}>
        <Box mx='-2px' display='flex' flexWrap='wrap' alignItems='flex-end'>
          <GrayCap as='h2' p='2px'>
            OWNER
          </GrayCap>

          {property.isIndividual !== null && (
            <Box p='2px'>
              <PropertyPill type={property.isIndividual ? 'individual' : 'entity'} />
            </Box>  
          )}

          {property.ownerType && (
            <Box p='2px'><PropertyPill type={property.ownerType} /></Box>
          )}
        </Box>
        <Small mt='-2px'>
          {orDash(property.name, n =>
            n ? n.split(',').join(', ') : null)}
        </Small>

        <Box py={1}>
          <SituationsPill
            property={property}
            pillProps={{
              size: 'small',
              style: {
                minHeight: '20px',
                borderRadius: '9999px',
                fontSize: '13px',
                border: '1px solid #2B959C'
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
})

export default PropertyPreview

const LoadingHandler = () => (
  <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
    <BigLoader />
  </Box>
)

const ErrorHandler = () => (
  <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
    <Text color='secondary' fontSize={1}>
      Failed to load property preview.
    </Text>
  </Box>
)

const Gray = p => <Text color='secondary' fontSize={0} {...p} />
const Small = p => <Text fontSize={0} {...p} />
const GrayCap = p =>
  <Caption display='block' color='secondary' fontSize='13px' {...p} />
