import * as api from 'common/api'
import { FETCH_TRIAL_PROMO_INFO, FETCH_TRIAL_PROMO_INFO_SUCCESS, EXPIRE_TRIAL_PROMO_INFO, FETCH_TRIAL_PROMO_INFO_FAILED } from './contants'
import get from 'lodash/object/get'
import throwAny from 'common/util/throwAny'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF } from 'const'
import { isTrialing as getIsTrialing } from 'billing/selectors'

export const expireTrialPromoInfo = () => ({ type: EXPIRE_TRIAL_PROMO_INFO })

export const fetchTrialPromoInfo = () => (dispatch, getState) => {
  const isTrialing = getIsTrialing(getState())
  if (!isTrialing) return
  dispatch({ type: FETCH_TRIAL_PROMO_INFO })
  let isNotFound = false
  return api.get('/billing')
    .then(resp => {
      const error = get(resp, 'error')
      if (error) throwAny(error)

      const promo = get(resp, 'subscription.trial_promotion')
      if (!promo) {
        isNotFound = true
        throwAny('There is no trial promo')
      }

      dispatch({ type: FETCH_TRIAL_PROMO_INFO_SUCCESS, payload: { promo } })
    })
    .catch(() => {
      dispatch({ type: FETCH_TRIAL_PROMO_INFO_FAILED })
      if (isNotFound) return
      toast.error(GENERIC_ERROR_NOTIF)
    })
}
