const anyToError = any => {
  if (any instanceof Error) return any
  const errMsg = any && any.message && typeof any.message === 'string'
    ? any.message
    : typeof any === 'object'
      ? JSON.stringify(any)
      : String(any)
  return new Error(errMsg)
}

export default anyToError
