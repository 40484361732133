/* eslint "eqeqeq": "warn" */
'use strict'

import PropTypes from 'prop-types'

import React from 'react'
import { complete, update, comment, read } from '../../actions'

import EditorInput from '../../../../views/form/EditorInput.react'
import CommentBox from '../../../../views/CommentBox.react'
import DateHelper from '../../../../core/date'

import Chip from '../../../../views/common/Chip.react'
import classnames from 'classnames'

import { Link } from 'react-router-dom'

class EditChip extends React.Component {
  render () {
    var { editable } = this.props
    return (
      <div className={classnames('edit-chip', { editable })}>
        <Chip>{this.props.children}</Chip>
        <div className='edit' onClick={this.props.onClick}>
          <div className='wrap'>
            <i className='fa fa-pencil'></i>
          </div>
        </div>
      </div>
    )
  }
}

class LeadLink extends React.Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
  };

  render () {
    return (

      <div className='leadlink'>
        <div className={(!this.props.editing && this.props.value) ? 'hide' : ''}>
          <EditorInput
            type='picker'
            name='reference_id'
            placeholder='Search Leads'
            emptyValue='Add Lead'
            model='task'
            action='references'
            displayKey='name'
            limit='1'
            editable={this.props.editable}
            editing={this.props.editing}
            value={this.props.value}
            onChange={this.props.onChange}
            onClick={!this.props.value ? this.props.onClick : null}
            onFinish={this.props.onFinish}
          />
        </div>

        {(this.props.value && !this.props.editing)
          ? <EditChip editable={this.props.editable} onClick={this.props.onClick}>
            <Link to={`/search/history/${this.props.value.id}`}>
              {this.props.value.name}
            </Link>
          </EditChip>
          : null
        }

      </div>
    )
  }
}

class ContactLink extends React.Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
  };

  render () {
    return (

      <div className='contactlink'>
        <div className={(!this.props.editing && this.props.value) ? 'hide' : ''}>
          <EditorInput
            type='picker'
            name='contact'
            placeholder='Search Contacts'
            model='contact'
            emptyValue='Add Contact'
            limit='1'
            editable={this.props.editable}
            editing={this.props.editing}
            value={this.props.value}
            onChange={this.props.onChange}
            onClick={!this.props.value ? this.props.onClick : null}
            onFinish={this.props.onFinish}
          />
        </div>

        {(this.props.value && !this.props.editing)
          ? <EditChip editable={this.props.editable} onClick={this.props.onClick}>
            <a href={ `/contacts/${this.props.value.id}`} onClick={e => { e.stopPropagation() }}>
              {this.props.value.name}
            </a>
          </EditChip>
          : null
        }

      </div>
    )
  }
}

class Task extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  state = {
    editing: false,
    open: false,
    loaded: false
  };

  render () {
    var values = this.props.task.values
    var { open } = this.state
    var { contact, complete } = values
    var dueDate = values.due_date ? DateHelper.calendarDays(values.due_date) : 'No due date'

    if (contact)
      contact = {
        id: contact.id,
        name: contact.values.name
      }

    var ownerUserId = values.owner.id
    var currentUserId = this.props.currentUser ? this.props.currentUser.id : null

    return (
      <div className={'task ' + (open ? ' open ' : '') + (complete ? ' complete ' : '')}>
        <div className='check' onClick={this.onCheckClick}></div>
        <div className='task-content' onClick={this.onContentClick}>
          <div className='expander'></div>
          <EditorInput
            type='text' multiline={true}
            name='title'
            value={values.title}
            editing={this.state.editing == 'title'}
            onChange={this.onChange.bind(this, 'title')}
            onClick={this.onFieldClick.bind(this, 'title')}
            onFinish={this.onFinishEdit}
            editable={open}/>

          <div className='task-references'>
            <div className='task-lead'>

              <LeadLink
                value={values.reference}
                editable={open}
                onChange={this.onChange.bind(this, 'reference')}
                onFinish={this.onFinishEdit}
                editing={this.state.editing == 'reference'}
                onClick={this.onFieldClick.bind(null, 'reference')}
              />
            </div>
            <div className='task-contact'>
              <ContactLink
                editable={open}
                value={contact}
                onChange={this.onChange.bind(this, 'contact')}
                onFinish={this.onFinishEdit}
                editing={this.state.editing == 'contact'}
                onClick={this.onFieldClick.bind(null, 'contact')}
                limit='1'/>
            </div>
          </div>

          <div className='task-meta'>
            Assigned to
            <div className={'task-owner ' + (ownerUserId == currentUserId ? 'me' : '')}>
              <EditorInput
                type='picker'
                name='owner'
                placeholder='Who should do it?'
                model='user'
                resource='task'
                action='possibleowners'
                displayKey='name'
                limit='1'
                editable={open}
                value={values.owner}
                editing={this.state.editing == 'owner'}
                onClick={this.onFieldClick.bind(this, 'owner')}
                onFinish={this.onFinishEdit}
                onChange={this.onChange.bind(this, 'owner')}/>
            </div>

            {values.complete ? <div className='task-complete-date'>Completed <span className='date-display'>{DateHelper.timeAgo(values.complete_date)}</span></div> : null}

            <div className='task-due-date'>
              <EditorInput type='date' smart='true'
                defaultValue='No due date'
                emptyValue='No due date'
                value={values.due_date}
                editable={open}
                editing={this.state.editing == 'due_date'}
                onChange={this.onChange.bind(this, 'due_date')}
                onFinish={this.onFinishEdit}
                onClick={this.onFieldClick.bind(this, 'due_date')}/>
              <span className='date-display'>{dueDate}</span>
            </div>
          </div>
          {open &&
            <div className='task-more'>

              <div className='task-comments'>
                <div className='title'>Comments</div>
                <CommentBox comments={values.activity} onComment={this.onCommentAdd}/>
              </div>

            </div>
          }
        </div>
      </div>
    )
  }

  onChange = (fieldname, value) => {
    var id = this.props.task.id

    var cb = function () {
      var updateObj = {}
      updateObj[fieldname] = value
      this.props.dispatch(update(id, updateObj))
    }
    setTimeout(this.setState.bind(this, { editing: false }, cb), 100)
  };

  onFieldClick = (fieldname, e) => {
    if (this.state.open)
      e.stopPropagation()
    else
      return

    this.setState({ editing: fieldname })
  };

  onFinishEdit = () => {
    // this is so that editing is still false when the onContentClick handler fires
    setTimeout(this.setState.bind(this, { editing: false }), 100)
  };

  onCheckClick = () => {
    this.props.dispatch(complete(this.props.task.id))
  };

  onContentClick = e => {
    if (this.state.editing)
      return

    if (e.target.closest('.comment-form'))
      return

    this.toggle()
  };

  onCommentAdd = commentObj => {
    this.props.dispatch(comment(this.props.task.id, commentObj))
  };

  toggle = () => {
    var open = !this.state.open
    var loaded = this.state.loaded

    if (open && !loaded) {
      // TaskActions.read(this.props.task.id);
      this.props.dispatch(read(this.props.task.id))
      loaded = true
    }

    this.setState({ open: open, loaded: loaded })
  };
}

export default Task
