/* eslint "handle-callback-err": "warn" */
import qs from 'qs'
import { arrayOf, normalize } from 'normalizr'

import fetch from 'common/fetch'

import { contactSchema, tag, tagSchema } from './schema.js'

const ENDPOINT = '/contacts'
const contactResultSchema = { result: contactSchema }
const arrayOfContacts = arrayOf(contactSchema)
const searchSchema = { result: { models: arrayOfContacts } }

// TAG ACTIONS ---------------------------------------------------
export const SEARCH = 'TAG_SEARCH'
export const SEARCH_SUCCESS = 'TAG_SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'TAG_SEARCH_FAILURE'

export function searchTags (params) {
  return dispatch => {
    dispatch({ type: SEARCH, meta: { params } })

    fetch(`${ENDPOINT}/tags`)
      .then(res => res.json())
      .then(payload => {
        payload = payload.result ? payload.result : {}
        payload = normalize(payload, tagSchema)
        dispatch({ type: SEARCH_SUCCESS, payload, meta: { params } })
      })
      .catch(error => dispatch({ type: SEARCH_FAILURE, payload: error, meta: { params } }))
  }
}

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS'

export function clear () {
  return dispatch => {
    dispatch({ type: CLEAR_CONTACTS })
  }
}

export const TAG_CREATE = 'TAG_CREATE'
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS'
export const TAG_CREATE_FAILURE = 'TAG_CREATE_FAILURE'

export function createTag (data) {
  return dispatch => {
    dispatch({ type: TAG_CREATE })
    const payload = {}
    return fetch(`${ENDPOINT}/createtag`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      // .then(json => normalize(json, tag))
      .then(json => {
        const result = json.result ? json.result : {}
        const payload = normalize(result, tag)
        // payload = normalize(val, tag);
        dispatch({ type: TAG_CREATE_SUCCESS, payload })
        return result || {}
      })
      .catch(error => dispatch({ type: TAG_CREATE_FAILURE, payload: payload }))
  }
}

export const TAG_UPDATE = 'TAG_UPDATE'
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS'
export const TAG_UPDATE_FAILURE = 'TAG_UPDATE_FAILURE'

export function updateTag (id, update) {
  return dispatch => {
    dispatch({ type: TAG_UPDATE })

    let payload = {}
    fetch(`${ENDPOINT}/updatetag/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(update)
      })
      .then(res => res.json())
      .then(json => normalize(json, tag))
      .then(json => {
        payload = json
        dispatch({ type: TAG_UPDATE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: TAG_UPDATE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const TAG_DELETE = 'TAG_DELETE'
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS'
export const TAG_DELETE_FAILURE = 'TAG_DELETE_FAILURE'

export function deleteTag (id) {
  return dispatch => {
    dispatch({ type: TAG_DELETE })

    let payload = {}
    fetch(`${ENDPOINT}/deletetag/${id}`,
      {
        method: 'DELETE'
      })
      .then(res => res.json())
      .then(json => {
        payload = json
        dispatch({ type: TAG_DELETE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: TAG_DELETE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}
// END TAG ACTIONS ---------------------------------------------------

// CONTACT ACTIONS------------------------------------------------------

export const SEARCH_CONTACTS = 'SEARCH_CONTACTS'
export const SEARCH_CONTACTS_SUCCESS = 'SEARCH_CONTACTS_SUCCESS'
export const SEARCH_CONTACTS_FAILURE = 'SEARCH_CONTACTS_FAILURE'

export function search (params) {
  return dispatch => {
    dispatch({ type: SEARCH_CONTACTS, meta: { params } })
    var urlParams = qs.stringify(params)

    fetch(`${ENDPOINT}?${urlParams}`)
      .then(res => res.json())
      .then(json => {
        const normal = normalize(json, searchSchema)
        return normal
      })
      .then(payload => {
        params.state_filter = 'active'
        dispatch({ type: SEARCH_CONTACTS_SUCCESS, payload, meta: { params } })
      })
      .catch(error => dispatch({ type: SEARCH_CONTACTS_FAILURE, payload: error, meta: { params } }))
  }
}

export const READ = 'CONTACT_READ'
export const CONTACT_READ_SUCCESS = 'CONTACT_READ_SUCCESS'
export const READ_FAILURE = 'CONTACT_READ_FAILURE'

export function read (id) {
  return dispatch => {
    dispatch({ type: READ })
    let payload = {}
    fetch(`${ENDPOINT}/${id}`,
      {
        method: 'GET'
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, contactSchema)
        dispatch({ type: CONTACT_READ_SUCCESS, payload, meta: { params: { id: id } } })
        return json
      })
      .catch(error => dispatch({ type: READ_FAILURE, payload, meta: { params: { id: id } } }))
  }
}

export const START_EDIT = 'CONTACT_START_EDIT'

export function startEdit (id) {
  return dispatch => {
    dispatch({ type: START_EDIT, meta: { id } })
  }
}

export const COMPLETE_CREATE = 'CONTACT_COMPLETE_CREATE'
export const COMPLETE_CREATE_SUCCESS = 'COMPLETE_CREATE_SUCCESS'
export const COMPLETE_CREATE_FAILURE = 'COMPLETE_CREATE_FAILURE'

export function completeCreate (data) {
  return (dispatch, getState) => {
    let contacts = {}
    const state = getState()
    if (state.contacts && state.contacts.contacts && state.contacts.contacts.contacts)
      contacts = state.contacts.contacts.contacts

    dispatch({ type: COMPLETE_CREATE })
    let payload = {}
    return fetch(`${ENDPOINT}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, contactResultSchema)
        // payload = normalize(json, resultSchema);
        dispatch({ type: COMPLETE_CREATE_SUCCESS, payload: payload, meta: { params: { contacts: contacts } } })
        const contact = payload.entities.contacts[payload.result.result]

        // Refetch tags on contact create
        dispatch(searchTags())
        return contact
      })
      .catch(error => dispatch({ type: COMPLETE_CREATE_FAILURE, payload: payload }))
  }
}

export const CLOSE_CONTACT_MODAL = 'CONTACT_CLOSE_MODAL'

export function closeModal () {
  return dispatch => {
    dispatch({ type: CLOSE_CONTACT_MODAL })
  }
}

export const UPDATE = 'CONTACT_UPDATE'
export const UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'CONTACT_UPDATE_FAILURE'

export function update (id, update) {
  return dispatch => {
    dispatch({ type: UPDATE })
    let payload = {}
    return fetch(`${ENDPOINT}/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(update)
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, contactSchema)
        dispatch({ type: UPDATE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: UPDATE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const DELETE = 'CONTACT_DELETE'
export const DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS'
export const DELETE_FAILURE = 'CONTACT_DELETE_FAILURE'

export function destroy (id) {
  return dispatch => {
    dispatch({ type: DELETE })

    let payload = {}
    fetch(`${ENDPOINT}/delete/${id}`,
      {
        method: 'DELETE'
      })
      .then(res => res.json())
      .then(json => {
        payload = json
        dispatch({ type: DELETE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: DELETE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
};
