import { SEARCH_SUCCESS } from '../actions'

export default (state = {}, action) => {
  const { meta } = action
  switch (action.type) {
    case SEARCH_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.tasks) {
        const ids = action.payload.result.result.models
        let newState = { ...state }
        ids.forEach(taskId => {
          const task = action.payload.entities.tasks[taskId]
          const show = meta.params.show
          const ids = pushToArray(task.id, newState, show)
          newState = {
            ...newState,
            [show]: ids
          }
        })
        return newState
      }
      return state
  }
  return state
}

function pushToArray (newId, state, show) {
  let ids = [newId]
  if (state[show])
    ids = [...new Set([...state[show], newId])]

  return ids
}
