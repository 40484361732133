import getOneOf from 'common/util/getOneOf'

/* Format data from `/property` to situation flags */
const formatSituations = prop => {
  const ownerType = getOneOf(
    prop,
    propKeys('summary.distressedCriteria.ownerSituation')
  )

  return {
    // Distress Criteria / Situations (Property response INCLUDE these)
    ownerType,

    isVacant: getSituation(prop, 'summary.distressedCriteria.isVacant'),
    isTaxDelinquent: getSituation(prop, 'summary.distressedCriteria.isTaxDelinquent'),
    isForeclosure: getSituation(prop, 'summary.distressedCriteria.isForeclosure'),

    isSenior: getSituation(
      prop,
      'summary.distressedCriteria.isSenior'
    ),

    isHomestead: getSituation(
      prop,
      'summary.distressedCriteria.isHomestead'
    ),

    isAbsenteeOwner: getSituation(
      prop,
      'summary.distressedCriteria.isAbsenteeOwner',
      ownerType === 'absentee' // Sometimes the key above is missing
    )

  }
}

export default formatSituations

/**
 * These would be true: true, 'true', 1, '1', 'anystring', [], {}
 * These would be false: false, 0, '0', 'false'
 */
const isTruthy = v => !!(v && v !== '0' && v !== 'false')

/**
 * Generate possible property accessor prefixes for all known response shapes
 */
const propKeys = key => [
  key, // for response from `/property`
  'property.' + key, // for response from `/leads
  'property.property.' + key // for response from `/lead/withaddress`
]

const getSituation = (object, path, fallback) =>
  isTruthy(getOneOf(object, propKeys(path), fallback))
