/* eslint "react/jsx-key": "warn", "react/no-find-dom-node": "warn", "eqeqeq": "warn" */
import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'

import $ from 'jquery'

import isObject from 'lodash/lang/isObject'

import Model from '../../core/model.js'
import PinnedMixin from '../util/PinnedMixin.react'
import EntityFieldTypes from '../../core/EntityFieldTypes.js'
import EntityFieldProperties from './EntityFieldProperties.react'
import { TOP_BAR_HEIGHT } from '../../app/TopBar'
import 'jquery-ui/draggable'
import 'jquery-ui/sortable'

class EntityFieldType extends React.Component {
  render () {
    var f = this.props.field

    return (
      <li data-field-name='new' data-field-type={f.name} className={'field-type field-type-' + f.name}>
        <span className={'icon icon-' + f.name}/> {this.props.field.label}
      </li>
    )
  }
}

var EntityFieldTypeList = createReactClass({
  displayName: 'EntityFieldTypeList',
  mixins: [PinnedMixin],

  render: function () {
    var fields = EntityFieldTypes.getFieldTypes()

    var style = { top: this.state.top + TOP_BAR_HEIGHT + 16 }
    if (this.state.floating) {
      style.position = 'absolute'
      style.width = '100%'
    }

    return (
      <div className='entity-fieldtypes' style={{ position: 'relative' }}>
        <div style={style}>
          <h3>Fields</h3>
          <ul>
            {fields.map(function (f) {
              return (<EntityFieldType field={f}/>)
            })}
          </ul>
        </div>
      </div>
    )
  },

  componentDidMount: function () {
    $(ReactDOM.findDOMNode(this)).find('.field-type').draggable({
      connectToSortable: '.entity-form-fields',
      helper: function (e) {
        var $t = $(e.target)
        if (!$t.is('.field-type'))
          $t = $t.parents('.field-type')

        var w = $t.width()

        $t = $t.clone().wrap("<div class='entityfield-drag-helper'></div>").parents('.entityfield-drag-helper')
        $t.width(w)

        $t.appendTo(document.body)

        return $t.get(0)
      }
    })
  }
})

class EntityField extends React.Component {
  getLabel = () => {
    var f = this.props.field
    var ft = this.props.fieldtype || {}
    var editable = this.props.field.editable !== false
    var hasOptions = ft.properties

    if (editable)
      return (
        <div className='field-label'>
          {hasOptions
            ? <div className='options btn-group'>

              <button className='btn btn-default dropdown-toggle' type='button' data-toggle='dropdown'>
                <span className={'icon icon-' + ft.name}/>
                <span className='caret'></span>
              </button>
              <div className='dropdown-menu dropdown'>
                <EntityFieldProperties properties={ft.properties} field={f} onChange={this.onOptionChange}/>
              </div>
            </div>
            : <div className='options btn-group'>
              <button className='btn btn-default' disabled='disabled' type='button' data-toggle='dropdown'>
                <span className={'icon icon-' + ft.name}/>
                <span className='caret' style={{ visibility: 'hidden' }}></span>
              </button>
            </div>
          }
          <div className=''>
            <input type='text' value={f.label} className='label-input' onChange={this.onLabelChange}/>
          </div>
        </div>
      )
    else
      return (
        <div className='field-label'>
          <div className='options btn-group'>
            <button className='btn btn-default' disabled='disabled' type='button' data-toggle='dropdown'>
              <span className={'icon icon-' + ft.name}/>
              <span className='caret' style={{ visibility: 'hidden' }}></span>
            </button>
          </div>
          <label className='label-static text-muted'>{this.props.field.label}</label>
        </div>
      )
  };

  render () {
    var f = this.props.field
    var editable = f.editable !== false

    return (
      <div data-field-name={f.name} className={'entity-field entity-field-' + f.type + ' ' + (f.deleted ? 'deleted' : '')} title={!editable ? "This field is required. You can move it but you can't change it." : ''}>
        {this.getLabel()}

        {this.props.field.editable !== false ? <button type='button' className='close' onClick={this.onCloseClick}><span aria-hidden='true'>&times;</span><span className='sr-only'>Close</span></button> : ''}
      </div>
    )
  }

  onLabelChange = e => {
    var label = e.target.value
    var p = this.props.field
    p.label = label

    this.props.onChange(this.props.field)
  };

  onOptionChange = (name, value) => {
    var p = this.props.field
    p[name] = value
    this.props.onChange(p)
  };

  onCloseClick = index => {
    var r = confirm('Are you sure you want to delete this field? Data for this field will not be deleted but the field will not appear on new leads.')
    if (r == true)
      this.props.onDelete(this.props.field)
  };
}

class EntityFieldGroup extends React.Component {
  render () {
    return (

      <div className='entity-field-group'>
        <h3>{this.props.title}</h3>
        <div className='field-list'>
          {this.props.children}
        </div>
      </div>

    )
  }
}

class EntityForm extends React.Component {
  constructor (props) {
    super(props)
    var fields = props.form.reduce(function (memo, value) {
      var g = value.group
      if (!memo[g])
        g = 'misc'

      memo[g].push(value)
      return memo
    }, { property: [], financials: [], misc: [] })

    this.state = {
      fields: fields
    }
  }

  render () {
    var fts = EntityFieldTypes.getFieldsByType(true)
    var fieldsByGroup = this.state.fields

    return (
      <div className='entity-form'>

        {
          this.props.entityType && this.props.entityType == 'lead'

            ? <div>
              <EntityFieldGroup title='Property Facts'>
                {this.renderGroup(fieldsByGroup, 'property', fts)}
              </EntityFieldGroup>

              <EntityFieldGroup title='Financials'>
                {this.renderGroup(fieldsByGroup, 'financials', fts)}
              </EntityFieldGroup>

              <EntityFieldGroup title='Misc'>
                {this.renderGroup(fieldsByGroup, 'misc', fts)}
              </EntityFieldGroup>
            </div>

            : <div>
              <EntityFieldGroup title='Template Fields'>
                {this.renderGroup(fieldsByGroup, 'misc', fts)}
              </EntityFieldGroup>
            </div>

        }

        <div className='toolbar clearfix'>
          <button className='btn btn-success pull-right' onClick={this.onSave}>Save Changes</button>
        </div>
      </div>
    )
  }

  renderGroup = (list, id, fts) => {
    list = list[id]
    return (
      <div className='entity-form-fields well' data-group={id}>
        {list.map(function (f, i) {
          var type = f.type

          if (f.hidden)
            return ''

          return (<EntityField key={f.name} field={f} fieldtype={fts[type]} onChange={this.onChange.bind(this, id, i)} onDelete={this.onDelete.bind(this, id, i)}/>)
        }, this)}
        {(list.length == 0) ? <div className='entity-field-placeholder'>Drag a field from the left to get started</div> : ''}
      </div>
    )
  };

  onChange = (group, index, field) => {
    var fields = this.state.fields
    fields[group][index] = field
    this.setState({ fields: fields })
  };

  onDelete = (group, index, field) => {
    field.deleted = true
    this.onChange(group, index, field)
  };

  componentDidMount () {
    $(ReactDOM.findDOMNode(this)).find('.entity-form-fields').sortable({
      stop: this.onSortStop,
      receive: this.onReceive,
      placeholder: 'entity-field ui-sortable-placeholder',
      connectWith: '.entity-form-fields'
    })
  }

  componentDidUpdate () {
    $(ReactDOM.findDOMNode(this)).find('.entity-form-fields').sortable('refresh')
  }

  getFieldById = () => {
    var originalFields = this.state.fields
    var fieldsById = {}
    for (var name in originalFields) {
      var originalList = originalFields[name]
      originalList.forEach(function (f) {
        fieldsById[f.name || f.id] = f
      })
    }
    return fieldsById
  };

  getNewField = (type, group) => {
    group = group || 'misc'
    var fts = EntityFieldTypes.getFieldsByType()
    var f = fts[type]

    var d = Object.assign({}, f.defaults)
    d.label = f.label
    d.type = f.name
    d.group = group
    d.name = 'field' + Date.now()
    return d
  };

  processListOnChange = (target, fieldsById) => {
    var fields = this.state.fields

    var $list = $(target)
    var group = $list.data('group')

    var fieldnames = $list.children().map(function (i, el) {
      var name = $(el).data('field-name')
      if (name == 'new')
        return { type: $(el).data('field-type') }

      return name
    }).get()

    var newform = fieldnames.map(function (field) {
      var f = isObject(field) ? this.getNewField(field.type, group) : fieldsById[field]
      f.group = group
      return f
    }, this)

    $(target).find('[data-field-name=new]').remove()

    fields[group] = newform

    return fields
  };

  onSortStop = (e, ui) => {
    if (!this.received) {
      var index = this.getFieldById()
      var fields = this.state.fields
      var me = this
      $(ReactDOM.findDOMNode(this)).find('.entity-form-fields').each(function () {
        fields = me.processListOnChange(this, index)
      })

      $(e.target).sortable('cancel')

      this.setState({ fields: fields })
    }
    this.received = false
  };

  onReceive = (e, ui) => {
    this.received = true
    var me = this
    var index = this.getFieldById()
    var fields = this.state.fields
    $(ReactDOM.findDOMNode(this)).find('.entity-form-fields').each(function () {
      fields = me.processListOnChange(this, index)
    })

    if ($(ui.sender).is('.entity-form-fields'))
      ui.sender.sortable('cancel')

    this.setState({ fields: fields })
  };

  onSave = () => {
    var fields = this.state.fields
    var allFields = fields.property.concat(fields.financials).concat(fields.misc)

    this.props.onSave(allFields)
  };
}

class EntityEditor extends React.Component {
  render () {
    return (
      <div className='entity-editor row'>
        <div className='col-md-3'>
          <div>
            <EntityFieldTypeList/>
          </div>
        </div>
        <div className='col-md-9'>
          <EntityForm form={this.props.form} entityType={this.props.entityType} onSave={this.onSave}/>
        </div>
      </div>
    )
  }

  onSave = form => {
    var me = this
    Model('template').execute('edit', this.props.entityType, { fields: JSON.stringify(form) }).then(function (data) {
      if (me.props.onSave)
        me.props.onSave(data)
    })
  };
}

export default EntityEditor
