import { combineReducers } from 'redux'

import ordering from './ordering'
import entityFields from './entityFields'
import entityTemplates from './entityTemplates'
import files from './files'
import leads from './leads'
import users from './users'
import ui from './ui'
import properties from './properties'

export default combineReducers({
  ordering,
  entityFields,
  entityTemplates,
  files,
  leads,
  users,
  ui,
  properties
})
