import React from 'react'
import { Heading, Box, Paragraph, Label, NumberInput, Caption, Button } from '@realsoftworks/decor'
import Card from 'common/Card'

import CountyPicker from '../../CountyPicker'
import CityPicker from '../../CityPicker'
import ZipPicker from '../../ZipPicker'
import DebounceInput from 'common/DebounceInput'

import DealValueInput from '../../DealValueInput'
import { Numeric } from 'common/format'
import LoadingIcon from 'common/LoadingIcon'

export default function BuyerIntro ({ onSearch, counts, fetchCounts }) {
  const [filters, setFilters] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const mergeFilter = React.useCallback(
    (name, value) => {
      const newfilters = { ...filters, [name]: value }
      setFilters(newfilters)
      setLoading(true)
      fetchCounts(newfilters).then(() => setLoading(false))
    },
    [setFilters, filters]
  )

  // this is used because the TwoPartSearch component fetches the first count
  React.useEffect(() => {
    if (typeof counts === 'number')
      setLoading(false)
  }, [counts])

  const handleSearchClick = React.useCallback(() => onSearch(filters), [onSearch, filters])

  const onChangeCount = React.useCallback(e => mergeFilter('minDeals', e))
  const onChangeMin = React.useCallback(e => mergeFilter('minSpend', e))
  const onChangeMax = React.useCallback(e => mergeFilter('maxSpend', e))

  const { county, city, zip, minDeals, minSpend, maxSpend } = filters

  return (
    <Card borderTop='12px solid' borderColor='blue.500'>
      <Heading textAlign='center' size={2} mb={3}>Cash Buyers</Heading>
      <Paragraph mb={5}>Building your buyers list has never been easier! Browse our massive database of buyers and keep your properties moving.</Paragraph>

      <Box display='flex' mx={-3}>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>County</Label>
          <CountyPicker
            value={county}
            onChange={value => mergeFilter('county', value)}
          />
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>City</Label>
          <CityPicker
            value={city}
            onChange={value => mergeFilter('city', value)}
          />
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Zipcode</Label>
          <ZipPicker
            value={zip}
            onChange={value => mergeFilter('zip', value)}
          />
        </Box>
      </Box>

      <Box display='flex' mx={-3}>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Deal Size</Label>
          <Box>
            <DebounceInput value={minSpend} onChange={onChangeMin}>
              <DealValueInput
                placeholder='min'
                mb={2}
              />
            </DebounceInput>
          </Box>
          <DebounceInput value={maxSpend} onChange={onChangeMax}>
            <DealValueInput
              placeholder='max'
              mb={2}
            />
          </DebounceInput>
        </Box>
        <Box flex='1' mx={3} mb={5}>
          <Label display='block'>Minimum Deal Count</Label>
          <DebounceInput value={minDeals} onChange={onChangeCount}>
            <NumberInput width={80} />
          </DebounceInput>
        </Box>
        <Box flex='1' mx={3} mb={5}>
        </Box>

      </Box>

      <Box display='flex' alignItems='center' justifyContent='center'>
        <Box mx={4}>
          <Caption color='blue.500'>Potential Buyers</Caption>
          <Heading textAlign='center' size={3} color='blue.900'>
            {!loading
              ? <Numeric>{counts}</Numeric>
              : <LoadingIcon />}
          </Heading>
        </Box>
        <Button mx={4} onClick={handleSearchClick} sizeVariant='large' variant='primary'>Show me the Leads</Button>
      </Box>
    </Card>
  )
}
