import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Button, Box, Dialog, Link, Tooltip, Paragraph, Icon, Card, Text, toast, Menu, ListItem } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { selectDownloads, selectMemberInfo, selectHasReachedLimit } from '../../../selectors'
import { createAndDownloadAndRefresh, download } from '../../../actions'
import ListRestrictionDialog from './ListRestrictionDialog'
import SkiptraceDownloadDialog from 'common/SkiptraceDownloadDialog'
import downloadListSkiptrace from 'lists/components/ListDetail/downloadListSkiptrace'
import get from 'lodash/object/get'
import capitalize from 'lodash/string/capitalize'

const calcLastRecord = downloads => {
  const lastCursor = downloads.length ? downloads[downloads.length - 1].cursor : { offset: 0, limit: 0 }
  return (lastCursor.offset + lastCursor.limit)
}

const canCreateForList = (downloads, count) => calcLastRecord(downloads) < count

const DownloadLink = styled(Link)`
  display: flex;
  padding: 16px;

  &:hover {
    background-color: ${themeGet('colors.blue.100')};
  }
`

const ListDownloadDialog = ({
  downloadInfo,
  download,
  onCreateClick,
  count,
  ...props
}) => {
  const { downloads } = downloadInfo
  const limitInfo = downloadInfo.limits || {}
  const limits = get(limitInfo, 'limits', {})
  const limitDownloads = get(limitInfo, 'downloads', {})
  const more = canCreateForList(downloads, count)
  const canCreate = downloadInfo.available > 0

  const lastRecord = calcLastRecord(downloads)

  return (
    <Dialog
      {...props}
      title='Download List Data'
      toolbar={({ close }) => (
        <>
          <Button onClick={close}>Close</Button>
          {more && <Button variant='primary' onClick={onCreateClick} disabled={!canCreate}>{lastRecord > 0 ? 'Download Next' : 'Download'}</Button>}
        </>
      )}
    >
      <Box mb={5} display='block'>
        <Box mb={3} ml='auto'>
          This type of list data has the following download limitations:
        </Box>
        <Text fontSize={2} as='ul'>
          {Object.entries(limits).map(([interval, limit]) => (
            <li key={interval}>
              <Text color='secondary'>{capitalize(interval)}:</Text>
              {' '}{get(limitDownloads, interval, 0)}
              {' '}/{' '}
              {limit}
            </li>
          ))}
        </Text>
      </Box>
      { lastRecord > 0 && !canCreate &&
        <Box mb={3} ml='auto'>
          But you may re-download any of the following previous downloads:
        </Box>
      }
      { lastRecord > 0 &&
        <Card border='1px solid' borderColor='blue.200'>
          {downloads.map((d, i) => (
            <DownloadLink
              key={d.id}
              onClick={() => {
                toastDownload()
                return download(d.id)
              }}
            >
              <span>
                {i + 1}) Download Records {d.cursor.offset + 1} to {d.cursor.offset + d.cursor.limit}
              </span>
              <Box ml='auto'>
                <FontAwesomeIcon icon={faDownload} />
              </Box>
            </DownloadLink>
          ))}
        </Card>
      }
      <Paragraph mt={2} textAlign='right' color='secondary'>So far, you have downloaded {lastRecord} of {count} items in this list.</Paragraph>

      {!canCreate &&
        <Box mt={5} mb={-3} display='flex'>
          <Box ml='auto'>
            <Icon><FontAwesomeIcon icon={faExclamationTriangle} /></Icon> You have exhausted your downloads for the time period above.
          </Box>
        </Box>}

    </Dialog>
  )
}

const useDialog = (initialOpen = false) => {
  const [open, setOpen] = React.useState(initialOpen)
  const onClose = () => setOpen(false)

  return [setOpen, { open, onClose }]
}

const toastDownload = () =>
  toast.info({ title: 'Download will start in a moment…' })

const ListDownload = ({
  list,
  downloadInfo,
  memberInfo,
  createAndDownloadAndRefresh: createAndDownloadAndRefreshProp,
  createDownload: createDownloadProp,
  download: downloadProp,
  disabled,
  hasReachedLimit,
  ...props
}) => {
  const importing = !!list.importProgress
  const createAndDownloadAndRefresh = React.useCallback(() => createAndDownloadAndRefreshProp(list.id), [list])
  const download = React.useCallback(downloadId => downloadProp(list.id, downloadId), [list])
  const [setOpen, dialogProps] = useDialog()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDownloadSkiptraceOpen, setIsDownloadSkiptraceOpen] = useState(false)
  const [isLimitReachedOpen, setIsLimitReachedOpen] = useState(false)
  const buttonMenuRef = useRef()
  const hasSkiptraceData = list?.memberStats?.skiptraced > 0

  if (importing)
    return (
      <Tooltip content='Please wait until your list is finished importing'>
        <Box display='flex' alignItems='center' {...props}>
          <Button variant='primary' disabled><FontAwesomeIcon icon={faDownload} /> Download</Button>
        </Box>
      </Tooltip>
    )

  if (!downloadInfo || !memberInfo)
    return null

  // we should download automatically if:
  // the list is an unrestricted type

  const restricted = downloadInfo.limit > 0

  const handleDownloadClick = () => {
    if (restricted) {
      setOpen(true)
    } else {
      toastDownload()
      return createAndDownloadAndRefresh(list.id)
    }
  }

  const handleDownloadSkiptraceData = () => {
    setIsDownloadSkiptraceOpen(true)
  }

  return (
    <Box display='flex' alignItems='center' {...props}>
      <ListDownloadDialog
        {...dialogProps}
        count={memberInfo.count}
        downloadInfo={downloadInfo}
        download={download}
        onCreateClick={() => {
          setOpen(false)
          toastDownload()
          return createAndDownloadAndRefresh()
        }}
      />

      <SkiptraceDownloadDialog
        isOpen={isDownloadSkiptraceOpen}
        download={format => downloadListSkiptrace({ format, listId: list.id })}
        onClose={() => setIsDownloadSkiptraceOpen(false)}
      />

      <ListRestrictionDialog
        listId={list.id}
        isOpen={isLimitReachedOpen}
        onClose={() => setIsLimitReachedOpen(false)}
      />

      { !disabled
        ? (
          <>
            <Button ref={buttonMenuRef} variant='primary' onClick={() => setIsMenuOpen(v => !v)}>
              <FontAwesomeIcon icon={faDownload} />{' '}
              Download
            </Button>

            <Menu
              target={buttonMenuRef.current}
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            >
              {hasSkiptraceData ? (
                <ListItem onClick={handleDownloadSkiptraceData}>
                  Skiptrace Data
                </ListItem>
              ) : (
                <Tooltip content='No skiptrace data in list'>
                  <ListItem color='secondary'>
                    Skiptrace Data
                  </ListItem>
                </Tooltip>
              )}
              <ListItem onClick={handleDownloadClick}>
                List Data
              </ListItem>
            </Menu>
          </>
        ) : (
          <Box>
            <Button variant='primary' disabled>
              <FontAwesomeIcon icon={faDownload} />{' '}
              Download
              {' '}<FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </Box>
        )
      }
    </Box>
  )
}

const mapStateToProps = (state, ownProps) => ({
  downloadInfo: selectDownloads(state, ownProps.list.id),
  memberInfo: selectMemberInfo(state, ownProps.list.id),
  hasReachedLimit: selectHasReachedLimit(state, ownProps.list.id)
})

const mapDispatchToProps = {
  createAndDownloadAndRefresh,
  download
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDownload)
