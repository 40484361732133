import React from 'react'
import { connect } from 'react-redux'

import TwoPartSearch from '../TwoPartSearch'
import LenderIntro from './components/LenderIntro'
import LenderFilters from './components/LenderFilters'
import LenderResult from './components/LenderResult'

import * as selectors from '../../selectors'
import { fetchLenders, clearPrevious, fetchLenderCounts, exportLeads } from '../../actions'

let Lenders = props => (
  <TwoPartSearch
    introComponent={LenderIntro}
    resultComponent={LenderResult}
    filterComponent={LenderFilters}
    type='privatelenders'
    {...props}
  />
)

Lenders = connect(
  state => ({
    data: selectors.getData(state),
    counts: selectors.getLenderCounts(state),
    filter: selectors.getFilter(state, 'lenders')
  }),
  {
    fetch: fetchLenders,
    clear: clearPrevious,
    fetchCounts: fetchLenderCounts,
    exportLeads
  }
)(Lenders)

export default Lenders
