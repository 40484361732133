export const getMarkets = state => {
  if (state.premiumleads && state.premiumleads.markets && state.premiumleads.markets.markets)
    return state.premiumleads.markets.markets

  return null
}

export const getLead = (state, id) => {
  let lead = null
  if (state.premiumleads && state.premiumleads.leads) lead = state.premiumleads.leads[id] ? state.premiumleads.leads[id] : null
  return lead
}

export const getLeadList = state => {
  if (state.premiumleads && state.premiumleads.leads && state.premiumleads.ordering && state.premiumleads.ordering.leads) {
    const leadsIdArray = state.premiumleads.ordering.leads
    const leads = leadsIdArray.map(leadId => {
      const lead = getLead(state, leadId)
      return lead
    })
    return leads
  }
  return null
}

export const getCustomLeads = (state, marketId) => {
  if (state.premiumleads && state.premiumleads.leads && state.premiumleads.ordering && state.premiumleads.ordering.customPackage && marketId) {
    const leadIds = state.premiumleads.ordering.customPackage[marketId] || []
    const leads = leadIds.map(leadId => {
      const lead = getLead(state, leadId)
      return lead
    })
    return leads
  }
  return null
}

export const getLeadListCount = state => {
  if (state.premiumleads && state.premiumleads.ui)
    return state.premiumleads.ui.marketLeadCount || 0

  return null
}

export const getCustomPackageCount = state => {
  if (state.premiumleads && state.premiumleads.ordering)
    return state.premiumleads.ordering.customPackage ? state.premiumleads.ordering.customPackage.length : 0

  return null
}

// ex: params={limit: 50, offset: 0}
export const getCustomPackage = (state, params) => {
  if (state.premiumleads && state.premiumleads.leads && state.premiumleads.ordering && state.premiumleads.ordering.customPackage) {
    let leadsIdArray = state.premiumleads.ordering.customPackage
    if (params && params.limit) {
      const offset = params.offset || 0
      leadsIdArray = leadsIdArray.slice(offset, offset + (params.limit || 50))
    }
    const leads = leadsIdArray.map(leadId => {
      const lead = getLead(state, leadId)
      return lead
    })
    return leads
  } else { return null }
}

export const getMarket = (state, id) => {
  if (state.premiumleads && state.premiumleads.markets && state.premiumleads.markets.markets) {
    const market = state.premiumleads.markets.markets.find(function (market, idx) {
      return market._id === id
    })
    return market
  }
  return null
}

export const getSelectedMarket = state => {
  if (state.premiumleads && state.premiumleads.ui && state.premiumleads.ui.selectedMarket)
    return getMarket(state, state.premiumleads.ui.selectedMarket)

  return null
}

export const getCounties = state => state.premiumleads.types

export const getTypes = (state, counties) => {
  const allcounties = getCounties(state)
  if (allcounties) {
    let types = []
    counties.forEach(county => {
      if (state.premiumleads.types[county])
        types = types.concat(state.premiumleads.types[county])
    })
    return types
  }
  return null
}

export const getMarketError = state => state.premiumleads.markets.error

export const getLeadListError = state => state.premiumleads.ordering.error
