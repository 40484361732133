import get from 'lodash/object/get'

export const selectListIds = (state, type = 'all') => state.lists.lists[type]

export const selectLists = (state, type = 'all') => {
  const ids = selectListIds(state, type)
  if (!ids) return ids
  return ids.map(id => selectList(state, id))
}

export const selectList = (state, id) => state.lists.entities[id]

export const selectMemberInfo = (state, id) => state.lists.members[id]

export const selectDownloads = (state, id) => state.lists.downloads[id]

export const selectHasReachedLimit = (state, id) => {
  const limitInfo = selectDownloads(state, id)
  const limits = get(limitInfo, 'limits', {})
  const downloads = get(limitInfo, 'downloads', {})
  const hasReachedLimit = Object.entries(limits)
    .some(([interval, limit]) => get(downloads, interval, 0) >= limit)
  return hasReachedLimit
}

export const selectSkippableCounts = (state, id) => state.lists.skipableCounts[id]

export const selectSkippableCountsForSkippedCountDerivation = (state, id) => state.lists.skippableCountsForSkippedCountDerivation[id]
export const selectSkippedCount = (state, { id, selectedCount: maybeSelectedCount }) => {
  const maybeSkippableCount = selectSkippableCountsForSkippedCountDerivation(state, id)
  if (typeof maybeSkippableCount !== 'number') return null

  const selectedCount = typeof maybeSelectedCount === 'number' && maybeSelectedCount > 0
    ? maybeSelectedCount
    : typeof selectList(state, id)?.memberStats?.total === 'number'
      ? selectList(state, id)?.memberStats?.total
      : null

  if (selectedCount === null) return null

  const skippedCount = selectedCount - maybeSkippableCount
  return skippedCount
}

export const selectSelectedItems = (state, id) => state.lists.selectedItems[id] || []
