import React, { useCallback } from 'react'
import ListDetail from './ListDetail'
import { connect } from 'react-redux'
import BigLoader from 'common/BigLoader'
import { fetchList, updateList, fetchMembers, deleteMembers, deleteList, download, fetchDownloads, pollImport, pollSkiptraceOrder, setSelectedListItems } from '../../actions'
import { selectList, selectMemberInfo, selectSelectedItems } from '../../selectors'
import { fetchOrders } from 'marketing/actions'
import { isTrialing } from 'billing/selectors'

let Container = ({
  onDelete = () => {},
  list,
  selItemsId,
  selectedItems,
  setSelectedListItems,
  update,
  deleteMembers,
  deleteList,
  download,
  memberInfo,
  fetchMembers,
  fetchDownloads,
  pollImport,
  pollSkiptraceOrder,
  fetchOrders,
  isTrialing
}) => {
  React.useEffect(() => {
    fetchOrders({ referenceId: list.id })
  }, [list.id])

  React.useEffect(() => {
    fetchDownloads(list.id)
  }, [list.id, isTrialing])

  React.useEffect(() => {
    if (list && list.importProgress)
      pollImport(list.id)

    if (list && list.pendingSkiptraces)
      pollSkiptraceOrder(list.id)
  }, [list.id])

  // Wrap callbacks so ListDetail doesn't need to pass back list id
  const deleteListWrapped = useCallback(() => deleteList(list.id).then(onDelete), [list])

  const downloadWrapped = useCallback(() => download(list.id), [list])

  const updateWrapped = useCallback(name => update(list.id, { name }), [list])

  const deleteMembersWrapped = useCallback(members => deleteMembers(list.id, members), [list])

  const setSelectedListItemsWrapped = useCallback(items => setSelectedListItems(selItemsId, items), [selItemsId])

  return (
    <ListDetail
      mb={9}
      key={list.id}
      selItemsId={selItemsId}
      list={list}
      update={updateWrapped}
      download={downloadWrapped}
      deleteList={deleteListWrapped}
      deleteMembers={deleteMembersWrapped}
      setSelectedListItems={setSelectedListItemsWrapped}
      memberInfo={memberInfo}
      fetchMembers={fetchMembers}
      selectedItems={selectedItems}
    />
  )
}

Container = connect((state, ownProps) => ({
  memberInfo: selectMemberInfo(state, ownProps.list.id),
  isTrialing: isTrialing(state)
}), {
  fetch: fetchList,
  update: updateList,
  deleteList,
  deleteMembers,
  fetchMembers,
  fetchList,
  fetchDownloads,
  download,
  pollImport,
  pollSkiptraceOrder,
  fetchOrders,
  setSelectedListItems
})(Container)

const ListLoader = ({ fetchList, onDelete, list, listId, selectedItems, selItemsId }) => {
  React.useEffect(() => {
    if (!list)
      fetchList(listId)
  }, [listId])

  return (
    <BigLoader loading={!list}>
      <Container list={list} selectedItems={selectedItems} selItemsId={selItemsId || listId} onDelete={onDelete}/>
    </BigLoader>
  )
}

export default connect(
  (state, ownProps) => ({
    list: selectList(state, ownProps.listId),
    selectedItems: selectSelectedItems(state, ownProps.selItemsId)
  }),
  {
    fetchList
  }
)(ListLoader)
