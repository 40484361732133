import React, { FC } from 'react'
import { RadioGroup, Radio, Text } from '@realsoftworks/decor'

enum SearchType {
  Wide = 'wide',
  Narrow = 'narrow',
}

type SkipGenieOptions = {
  searchType?: SearchType
}
const SkipGenieOptionsForm: FC<{
  value?: SkipGenieOptions
  onChange: (v: SkipGenieOptions) => void
}> = ({ value, onChange }) =>
  <>
    <Text fontSize={2} display='block' mb={4}>There are a couple different ways we can run these:</Text>

    <RadioGroup value={value?.searchType} onChange={(_e, v) => onChange({ searchType: v })}>
      <Radio value={SearchType.Narrow} color='blue' label='Narrow' />
      <Text display='block' color='secondary' pl='30px' mt='-6px' mb={1}>
        Runs the skiptraces specifically on the owner and their contact address. You’ll get fewer hits, but they’ll be more accurate.
      </Text>
      <Radio value={SearchType.Wide} label='Wide' />
      <Text display='block' color='secondary' pl='30px' mt='-6px' mb={1}>
        Runs the skiptraces only on the owner’s contact address. This will get you more hits such as other people also associated with that address
        but they could be previous residents and not in any way associated with the subject property.
      </Text>

    </RadioGroup>
  </>

export default SkipGenieOptionsForm
