/* eslint "eqeqeq": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

class Tabs extends React.Component {
  static propTypes = {
    initialSelectedIndex: PropTypes.number,
    onChange: PropTypes.func
  };

  static defaultProps = {
    initialSelectedIndex: 0
  };

  state = {
    selectedIndex: this.props.initialSelectedIndex
  };

  render () {
    const {
      selectedIndex
    } = this.state

    const {
      children
    } = this.props

    var tabCount = this.getTabCount()
    var tabWidth = 100 / tabCount

    var content = []

    const tabs = React.Children.map(children, (c, index) => {
      var selected = index == selectedIndex

      var children = c.props.children

      content.push(
        c.props.children
          ? React.createElement(TabPane, {
            selected
          },
          <div className='tab-content-wrap'>
            <h2 className='tab-print-label'>{c.props.label}</h2>
            {children}
          </div>)
          : null
      )

      return React.cloneElement(c, {
        key: index,
        selected,
        tabIndex: index,
        width: tabWidth + '%',
        onClick: this.onClick
      })
    })

    const indicator = (
      <div
        className='tab-indicator'
        style={{
          width: tabWidth + '%',
          left: (tabWidth * selectedIndex) + '%'
        }}/>
    )

    return (
      <div className='tabs'>
        <div className='tab-bar' role='tablist'>
          <div className='tab-container'>
            {tabs}
            {indicator}
          </div>

        </div>

        <div className='tab-content'>
          {content}
        </div>
      </div>
    )
  }

  onClick = e => {
    var tab = e.target
    var { tabIndex } = tab

    this.setState({
      selectedIndex: tabIndex
    })

    this.props.onChange && this.props.onChange(tabIndex)
  };

  getTabCount = () => React.Children.count(this.props.children);
}

export default Tabs

class TabPane extends React.Component {
  static propTypes = {
    selected: PropTypes.bool
  };

  render () {
    const classNames = classnames('tab-pane', { active: this.props.selected })

    return (
      <div className={classNames}>
        {this.props.children}
      </div>
    )
  }
}
