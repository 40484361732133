import { CHANGE_PLAN_SUCCESS } from 'billing/actions'
import { takeEvery } from 'redux-saga'
import { put } from 'redux-saga/effects'
import { fetchBalance } from './actions'

export default function * marketingSaga () {
  yield takeEvery(CHANGE_PLAN_SUCCESS, function * () {
    yield put(fetchBalance())
  })
}
