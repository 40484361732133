import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { Avatar } from '@realsoftworks/decor'
import { fetchTeamOnceAtATime as fetchTeam } from 'users/actions'
import { getTeamInfo } from 'users/selectors'

const UserAvatar = ({
  avatar,
  name = '',
  shouldFetch,
  fetchTeam,
  ...props
}) => {
  useEffect(() => {
    if (!shouldFetch) return;

    (async () => {
      try {
        fetchTeam()
      } catch (e) {}
    })()
  }, [shouldFetch])

  return <Avatar src={avatar} alt={name} name={name} {...props} />
}

UserAvatar.propTypes = {
  userId: string,
  avatar: string,
  name: string
}

const withState = connect((state, { userId }) => {
  if (!userId) return {}
  const { value: team, isLoading } = getTeamInfo(state)
  const shouldFetch = !isLoading && (!team || (team.length === 0))
  const user = team && team.find(u =>
    normalizeId(u.id) === normalizeId(userId))
  const { avatar, name } = user || {}

  return { avatar, name, shouldFetch }
}, { fetchTeam })

// Casts to string and removes preceding 0's
// e.g. from 0001 to 1
const normalizeId = passedId => {
  const id = String(passedId)
  return id.length === 1
    ? id
    : id[0] === '0'
      ? normalizeId(id.substr(1))
      : id
}

export default withState(UserAvatar)
