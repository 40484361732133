import React from 'react'
import Pill from '@realsoftworks/decor/dist/components/Pill'
import get from 'lodash/object/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faFileAlt, faUniversity, faPlane, faBuilding, faShieldAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import logError from 'common/logError'

const PropertyPill = ({ type }) => {
  const pill = PILLS[type]

  if (!pill) {
    logError(new Error(`Unexpected type in <PropertyPill>: ${type}`))
    return null
  }

  return pill
}

export default PropertyPill

const getColor = path => p => get(p.theme.colors, path)
const PILLS = {
  individual: (
    <Pill
      size='small'
      variant='default'
      css={`
        border: 1px solid ${getColor('teal.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
      `}
    >
      Individual
    </Pill>
  ),

  entity: (
    <Pill
      size='small'
      variant='default'
      css={`
        border: 1px solid ${getColor('teal.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
      `}
    >
      Entity
    </Pill>
  ),

  absentee: (
    <Pill
      size='small'
      css={`
        background: ${getColor('yellow.200')};
        border: 1px solid ${getColor('yellow.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('yellow.700')};
      `}
    >
      <FontAwesomeIcon color='#C99A2E' icon={faBriefcase} /> Absentee
    </Pill>
  ),

  outofstate: (
    <Pill
      size='small'
      css={`
        background: ${getColor('yellow.200')};
        border: 1px solid ${getColor('yellow.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('yellow.700')};
      `}
    >
      <FontAwesomeIcon color='#C99A2E' icon={faPlane} /> Out of State
    </Pill>
  ),

  outofcountry: (
    <Pill
      size='small'
      css={`
        background: ${getColor('yellow.200')};
        border: 1px solid ${getColor('yellow.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('yellow.700')};
      `}
    >
      <FontAwesomeIcon color='#C99A2E' icon={faPlane} /> Out of Country
    </Pill>
  ),

  military: (
    <Pill
      size='small'
      css={`
        background: #a5b646;
        border: 1px solid #656f2b;
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: #656f2b;
      `}
    >
      <FontAwesomeIcon color='#656f2b' icon={faShieldAlt} /> Military
    </Pill>
  ),

  owneroccupied: (
    <Pill
      size='small'
      css={`
        background: ${getColor('teal.200')};
        border: 1px solid ${getColor('teal.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('teal.600')};
      `}
    >
      <FontAwesomeIcon color='#1F878E' icon={faUser} /> Owner Occupied
    </Pill>
  ),

  trustowned: (
    <Pill
      size='small'
      css={`
        background: ${getColor('blue.200')};
        border: 1px solid ${getColor('blue.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('blue.600')};
      `}
    >
      <FontAwesomeIcon color='#2D83AE' icon={faFileAlt} /> Trust
    </Pill>
  ),

  bankowned: (
    <Pill
      size='small'
      css={`
        background: ${getColor('red.200')};
        border: 1px solid ${getColor('red.600')};
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: ${getColor('red.600')};
      `}
    >
      <FontAwesomeIcon color='#A61B1B' icon={faUniversity} /> Bank Owned
    </Pill>
  ),

  companyowned: (
    <Pill
      size='small'
      css={`
        background: #ffd8a8;
        border: 1px solid #fd7e14;
        min-height: 20px;
        border-radius: 9999px;
        font-size: 13px;
        color: #fd7e14;
      `}
    >
      <FontAwesomeIcon color='#fd7e14' icon={faBuilding} /> Corporate
    </Pill>
  )
}
