/* eslint "eqeqeq": "warn" */

import React from 'react'
import CommentForm from './CommentForm.react'
import CommentList from './comments/CommentList.react'

class CommentBox extends React.Component {
  static defaultProps = {
    comments: []
  };

  render () {
    return (
      <div className='comment-box'>
        <CommentList comments={this.props.comments}/>
        <CommentForm onAdd={this.onAdd}/>
      </div>
    )
  }

  onAdd = comment => {
    if (comment == '')
      return

    this.props.onComment(comment)
  };
}

export default CommentBox
