import React from 'react'
import { Card, Heading } from '@realsoftworks/decor'
import RecordTable from './RecordTable'

const Preforeclosures = ({ preforeclosures }) => (
  <Card p={3}>
    <Heading
      as='h2'
      pb={3}
      color='primary.500'
      size={5}
    >
        Preforeclosure History
    </Heading>

    <RecordTable data={preforeclosures} />
  </Card>
)

export default Preforeclosures
