import isNumber from './isNumber'

const ensureNumber = <F = number>(maybeNumber, fallback = 0): number | F =>
  isNumber(maybeNumber)
    ? maybeNumber
    : fallback

export default ensureNumber

export const ensureNumberOrUndefined = (
  maybeNumber: unknown
): number | undefined =>
  isNumber(maybeNumber)
    ? maybeNumber
    : undefined
