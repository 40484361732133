/* eslint "no-prototype-builtins": "warn" */
import React from 'react'
import { Text } from '@realsoftworks/decor'

import LoadingIcon from './LoadingIcon'
import branch from 'recompose/branch'
import renderComponent from 'recompose/renderComponent'
import Box from '@realsoftworks/decor/dist/components/Box'

const BigLoader = props => {
  // composition mode
  if (props.hasOwnProperty('loading')) {
    const { loading, children, ...rest } = props
    return loading ? <Loader {...rest} /> : children
  } else { return <Loader /> }
}

const Loader = ({ children, loading, ...props }) => (
  <Text as='div' textAlign='center' py={7} {...props}>
    <LoadingIcon size={5} />
  </Text>
)

BigLoader.whenLoading = isLoading =>
  branch(
    isLoading || (p => p.loading),
    renderComponent(BigLoader),
    t => t
  )

export default BigLoader

export const CenteredLoader = props => (
  <Box width='100%' display='flex' justifyContent='center' {...props}>
    <BigLoader />
  </Box>
)
