/* eslint "react/no-unescaped-entities": "warn" */
import React from 'react'
import { Heading, Box, Paragraph, Caption, Text, Button, Dialog, Card, NumberInput, Tooltip, Checkbox } from '@realsoftworks/decor'
import { connect } from 'react-redux'
import { hasBillingInfo } from 'billing/selectors'
import { selectAvailable, selectPrice, selectRefillSettings, selectServices } from 'marketing/selectors'
import { fetchBalance, purchaseCredits, updateRefillSettings, deleteRefillSettings } from 'marketing/actions'
import BigLoader from 'common/BigLoader'
import BigFatalError from 'common/BigFatalError'
import LoadingIcon from 'common/LoadingIcon'
import { Numeric, Monetary } from 'common/format'
import { useDialog } from 'common/util/hooks'
import AdornedInput from 'leadlists/components/AdornedInput'

const useInput = (initialValue, onChangeProp) => {
  const [value, setValue] = React.useState(initialValue)
  const onChange = React.useCallback(e => {
    const { value } = e.target
    onChangeProp && onChangeProp(value)
    setValue(value)
  })
  return [{ value, onChange }, value, setValue]
}

const MarketingCreditsDialog = ({
  onClose: propsOnClose,
  price,
  purchaseCredits,
  ...props
}) => {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [valid, setValid] = React.useState(true)
  const onChange = React.useCallback(v => setValid(v >= 100), [])

  const [qtyProps, value, setValue] = useInput(100, onChange)
  const onClose = () => {
    setValue(100)
    setValid(true)
    setError(null)
    propsOnClose()
  }

  const onPurchaseClick = () => {
    setError(null)
    setLoading(true)
    purchaseCredits(value)
      .then(result => {
        setLoading(false)
        if (result.error)
          setError(result.error.message)
      })
  }

  const onBeforeClose = () => !loading

  return (
    <Dialog
      onBeforeClose={onBeforeClose}
      onClose={onClose}
      toolbar={({ close }) => (
        <React.Fragment>
          <Button onClick={close}>Cancel</Button>
          <Button variant='primary' onClick={onPurchaseClick} disabled={!valid || loading}>{loading ? 'Please Wait' : 'Purchase'}</Button>
        </React.Fragment>
      )}
      {...props}
      title='Refill Wallet'
    >
      <Card display='flex' borderBottom='light'>
        <Caption color='secondary' flex='1'>Item</Caption>
        <Caption color='secondary' width='80px' textAlign='right'>Price</Caption>
        <Caption color='secondary' width='80px' ml={5} textAlign='right'>Qty</Caption>
      </Card>
      <Card display='flex' borderBottom='light' py={2} alignItems='center'>
        <Box flex='1'>Marketing Credits</Box>
        <Text width='80px' textAlign='right'>${price / 100}</Text>
        <NumberInput width='80px' textAlign='right' ml={5} {...qtyProps}/>
      </Card>
      <Card display='flex' mt={3}>
        <Text color={!valid ? 'red.500' : 'secondary'}>Minimum purchase is 100 credits</Text>
        <Card ml='auto' display='flex' alignItems='flex-end' flexDirection='column'>
          <Caption color='secondary'>Total</Caption>
          <Text fontSize='3'>
            <Monetary decimals={2}>{((value || 0) * price / 100)}</Monetary>
          </Text>
        </Card>
      </Card>
      {error && <Text color='red.500'>{error}</Text>}
    </Dialog>
  )
}

const AutoRefill = ({ refill, deleteRefill, updateRefill, ...props }) => {
  const [loading, setLoading] = React.useState(false)
  const [checked, setChecked] = React.useState(!!refill)
  const [balanceError, setBalanceError] = React.useState(false)
  const [refillError, setRefillError] = React.useState(false)
  const [lowBalanceAmount, setLowBalanceAmount] = React.useState(refill ? refill.lowBalanceAmount : null)
  const [refillAmount, setRefillAmount] = React.useState(refill ? refill.refillAmount : null)

  const onCheckChange = (e, checked) => {
    setChecked(checked)
    if (!checked) {
      setLoading(true)
      setBalanceError(false)
      setRefillError(false)
      setLowBalanceAmount(null)
      setRefillAmount(null)
      deleteRefill().then(() => setLoading(false))
    }
  }

  const onSaveClick = () => {
    if (!checked) return

    let error = false
    if (lowBalanceAmount < 10) {
      setBalanceError(true)
      error = true
    } else { setBalanceError(false) }

    if (refillAmount < 100) {
      setRefillError(true)
      error = true
    } else { setRefillError(false) }

    if (!error) {
      setLoading(true)
      updateRefill({ lowBalanceAmount, refillAmount }).then(() => setLoading(false))
    }
  }

  return (
    <Box {...props}>
      <Heading size='5' mb={2}>Auto-Refill</Heading>
      <p>Set up Auto-Refill and you never have to worry about running out of cash - we'll top you up every time you get low according to your instructions.</p>
      <Box mt={2} display='flex' alignItems='center'>
        <Checkbox label='Enable Auto-Refill' checked={checked} onChange={onCheckChange}/>
        { loading && <Box ml={4}><LoadingIcon/></Box> }
      </Box>
      <Box css={`opacity: ${checked ? 1 : 0.3}`}>
        <Box display='flex' mx={-4} alignItems='flex-end' flexWrap='wrap'>
          <Box flex='1' mx={4} mt={2}>
            <Paragraph>1) When balance dips below</Paragraph>
            <AdornedInput
              before='$'
              textAlign='right'
              inputComponent={NumberInput}
              width='100%'
              inputProps={{ width: '100%' }}
              placeholder='Min: 10'
              value={lowBalanceAmount}
              onChange={ e => setLowBalanceAmount(e.target.value)}
            />
            { balanceError && <Paragraph color='red.500'>Minimum of 10</Paragraph> }
          </Box>
          <Box flex='1' mx={4} mt={2}>
            <Paragraph>2) Purchase this many credits</Paragraph>
            <AdornedInput
              before='$'
              textAlign='right'
              inputComponent={NumberInput}
              width='100%'
              inputProps={{ width: '100%' }}
              placeholder='Min: 100'
              value={refillAmount}
              onChange={ e => setRefillAmount(e.target.value)}
            />
            { refillError && <Paragraph color='red.500'>Minimum of 100</Paragraph> }
          </Box>
          <Box display='flex' mx={4} mt={2}>
            <Button ml='auto' variant='secondary' onClick={onSaveClick}>Save</Button>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

const MarketingCredits = ({
  available,
  price,
  services,
  fetchBalance,
  hasBillingInfo,
  refill,
  updateRefill,
  deleteRefill,
  purchaseCredits: purchaseCreditsProp
}) => {
  const [loading, setLoading] = React.useState(true)
  const [setOpen, dialogProps] = useDialog()
  const purchaseCredits = React.useCallback(amount => purchaseCreditsProp(amount).then(v => {
    if (!v.error)
      setOpen(false)

    return v
  }))

  React.useEffect(() => {
    fetchBalance().then(() => setLoading(false))
  }, [])

  return (
    <React.Fragment>
      <BigLoader loading={loading}>
        { price
          ? <React.Fragment>
            <Heading size={4} mb={2}>Marketing Credits</Heading>
            <Paragraph>
                Our marketing services like Skip Tracing and Direct Mail are an additional cost. The cost of a skiptrace/postcard varies based on your plan, with higher level plans costing less per skiptrace/postcard
            </Paragraph>
            <Box display='flex' mt={5} alignItems='center' flexWrap='wrap'>
              <Box mb={4}>

              </Box>
              <Box display='flex' flex='1'></Box>
              <Box display='flex' mb={4}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Caption color='secondary'>Your Balance</Caption>
                  <Box>
                    <Heading size={3}>
                      <Numeric>{available}</Numeric>
                      <Text fontSize='14px' color='secondary' css='vertical-align: 8px'>
                        {' '}
                          credit
                        {available > 1 && 's'}
                      </Text>
                    </Heading>
                  </Box>
                </Box>
                <Box ml={5} mt={-1} display='flex' flexDirection='column' alignItems='center'>
                  {
                    hasBillingInfo
                      ? <Button variant='primary' mb={1} onClick={() => setOpen(true)}>Refill Wallet</Button>
                      : <Tooltip content='Enter your billing info first'>
                        <Box>
                          <Button disabled variant='primary' mb={1} onClick={() => setOpen(true)}>Refill Wallet</Button>
                        </Box>
                      </Tooltip>
                  }
                  <Text color='secondary'><Monetary decimals={2}>{price / 100}</Monetary> each</Text>
                </Box>
              </Box>

            </Box>
            <AutoRefill mt={4} refill={refill} updateRefill={updateRefill} deleteRefill={deleteRefill}/>
          </React.Fragment>
          : <BigFatalError/>
        }
      </BigLoader>
      <MarketingCreditsDialog price={price} purchaseCredits={purchaseCredits} {...dialogProps}/>
    </React.Fragment>
  )
}

export default connect(
  state => ({
    available: selectAvailable(state),
    price: selectPrice(state),
    refill: selectRefillSettings(state),
    services: selectServices(state),
    hasBillingInfo: hasBillingInfo(state)
  }),
  {
    fetchBalance,
    purchaseCredits,
    updateRefill: updateRefillSettings,
    deleteRefill: deleteRefillSettings
  }
)(MarketingCredits)
