import React from 'react'
import { Heading, Box, Paragraph as P } from '@realsoftworks/decor'
import styled from 'styled-components'
import Card from 'common/Card'

const Wrap = styled(Box)`
  max-width: 500px;
`

const CallToAction = () => (
  <Wrap mx='auto'>
    <Heading>Marketing Lists</Heading>
    <Card p={5} borderTop='12px solid' borderColor='teal.500'>
      <Box as='img' src='//propelio-cloudfront.s3.amazonaws.com/growth-man@2x.png' mx='auto' my={5} display='block' />
      <Heading size={3} textAlign='center'>Hit the Road!</Heading>
      <P>Download the Propelio mobile app to unlock powerful tools on the go!</P>
      <P>Create a Marketing List and Drive for Dollars using the app, then come back here and download your marketing lists.</P>
      <Box display='flex' my={4} justifyContent='space-evenly'>
        <img src='//propelio-cloudfront.s3.amazonaws.com/apple-badge.png' />
        <img src='//propelio-cloudfront.s3.amazonaws.com/google-play-badge.png' />
      </Box>
    </Card>
  </Wrap>
)

export default CallToAction
