/* eslint "no-class-assign": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import BasicInput from '../internal/BasicInput'
import formAware from '../internal/formAware'

class FormInput extends React.Component {
  static propTypes = {
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.element
    ]).isRequired

  };

  static defaultProps = {
    type: 'text'
  };

  render () {
    const { type } = this.props

    if (React.isValidElement(type))
      return React.cloneElement(type, this.props)

    const InputType = typeof type === 'string' ? (registry[type]) : type

    return <InputType {...this.props}/>
  }
}

const registry = {
  text: BasicInput,
  textarea: BasicInput
}

FormInput = formAware(FormInput)

FormInput.registerType = function (name, Cls) {
  registry[name] = Cls
}

export default FormInput
