import { SEARCH_PROPERTY_DATA_SUCCESS, SEARCH_PROPERTY_DATA } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        property: {
          result: action.payload,
          loading: false
        }
      }
    case SEARCH_PROPERTY_DATA:
      return {
        ...state,
        property: {
          loading: true
        }
      }
  }
  return state
}
