'use strict'

import React from 'react'
import createReactClass from 'create-react-class'
import { Dialogs } from '../../../views/common'

import { createTag, updateTag, deleteTag } from '../actions'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF, TAG_EXISTING_NOTIF } from 'const'

var TagListItem = createReactClass({
  displayName: 'TagListItem',

  getInitialState: function () {
    return {
      editing: false,
      tagname: ''
    }
  },

  render: function () {
    var count = this.props.tag.contact_id ? this.props.tag.contact_id.length : 0
    return (
      <li className='tag-list-item'>
        {this.state.editing
          ? <div className='edit-tagname' key='tagname'>
            <input
              type='text'
              ref={this.setEditInputRef}
              className='form-control'
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={this.state.tagname}
              placeholder='Tag Name'
            />
          </div>
          : <div>
            {this.props.tag.name} ({count})
            <div className='dropdown pull-right options'>
              <div className='dropdown-toggle btn-sm' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                <i className='icon icon-settings'></i> <span className='caret'></span>
              </div>
              <ul className='dropdown-menu' role='menu' aria-labelledby='dLabel'>
                <li><a href='#' onClick={this.onEdit}>Edit</a></li>
                <li><a href='#' onClick={this.onDelete.bind(null, this.props.tag && this.props.tag.id)}>Delete</a></li>
              </ul>
            </div>
          </div>
        }
      </li>
    )
  },

  onDelete (id) {
    if (!id) return toast.error(GENERIC_ERROR_NOTIF)
    Dialogs.confirm({ title: 'Delete Tag', message: 'Are you sure you want to delete this tag? This cannot be reversed.', callback: this.onDeleteConfirm.bind(this, id) })
  },

  onDeleteConfirm (id, yes) {
    if (yes)
      this.props.dispatch(deleteTag(id))
  },

  setEditInputRef (el) {
    this.editInputRef = el
  },

  onEdit (ev) {
    ev.preventDefault()
    this.setState({ editing: true, tagname: this.props.tag.name }, () => {
      this.editInputRef && this.editInputRef.focus()
    })
  },

  onChange (e) {
    this.setState({ tagname: e.target.value })
  },

  onBlur (e) {
    var v = e.target.value
    this.setState({ editing: false, tagname: '' }, function () {
      const tagId = this.props.tag && this.props.tag.id
      if (!tagId) return toast.error(GENERIC_ERROR_NOTIF)
      this.props.dispatch(updateTag(tagId, { name: v }))
    })
  }
})

class ContactTagManager extends React.Component {
  state = { text: '' };

  render () {
    let tagListItems = []
    if (this.props.tags && this.props.tags.length > 0) {
      const dispatch = this.props.dispatch
      tagListItems = this.props.tags
        .filter(t => t)
        .map(function (tag, index) {
          return (<TagListItem dispatch={dispatch} tag={tag} key={tag.id}/>)
        })
    }

    return (
      <div className='tag-manager'>
        <ul className='tag-list'>
          {tagListItems}
        </ul>
        <div className='input-group'>
          <input type='text' className='form-control' value={this.state.text} onChange={this.onChange} placeholder='Create a new tag...'/>
          <span className='input-group-btn'>
            <button className='btn btn-default' type='button' onClick={this.onAdd}>Add</button>
          </span>
        </div>
      </div>
    )
  }

  onAdd = () => {
    var text = this.state.text

    const existingTag = this.props.tags &&
      this.props.tags.find(t => t.name === text)

    if (existingTag) return toast.error(TAG_EXISTING_NOTIF)

    if (text) {
      this.setState({ text: '' })
      const data = { name: text }
      this.props.dispatch(createTag(data))
    }
  };

  onChange = e => {
    this.setState({ text: e.target.value })
  };
}

export default ContactTagManager
