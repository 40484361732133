/* eslint "react/display-name": "warn" */
import React, { useMemo } from 'react'
import CategoryPicker from 'common/CategoryPicker'
import { Icon } from '@realsoftworks/decor'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faUniversity, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { searchCounties, searchCities } from '../actions'
import { getCounty } from '../selectors'
import debounce from 'debounce-promise'

const MAX_SUGGESTION = 10
const GeoPicker = ({
  value,
  onChange,
  getCachedCounty,
  searchCounties,
  searchCities,
  type,
  ...props
}) => {
  const categories = [{
    id: 'county',
    label: 'County',
    getId: v => v.fips,
    icon: <Icon fontSize={2}><FontAwesomeIcon icon={faUniversity} /></Icon>,
    getLabel: v => <>{v.name}, {v.state}</>,
    filter: useDebouncePromiseFn(async text => {
      if (!text) return Promise.resolve([])
      const parts = text.split(',').map(v => v.trim())
      let [name, state] = parts
      if (state === '')
        state = undefined

      const counties = await searchCounties(name, state)
      return Array.isArray(counties) ? counties.slice(0, MAX_SUGGESTION) : []
    }),
    get: async v => getCachedCounty(v.id)
  }, {
    id: 'city',
    label: 'City',
    icon: <Icon fontSize={2}><FontAwesomeIcon icon={faCity} /></Icon>,
    filter: useDebouncePromiseFn(async text => {
      if (!text) return Promise.resolve([])
      const parts = text.split(',').map(v => v.trim())
      let [name, state] = parts
      if (state === '')
        state = undefined

      const cities = await searchCities(name, state, type)
      return Array.isArray(cities) ? cities.slice(0, MAX_SUGGESTION).map(v => v.id) : []
    })
  }, {
    id: 'zip',
    label: 'Zipcode',
    icon: <Icon fontSize={2}><FontAwesomeIcon icon={faEnvelope} /></Icon>,
    matches: text => (/\d{5}/).test(text) && text.length === 5
  }]

  return (
    <CategoryPicker
      placeholder='Search by county, city or zipcode'
      preFetch={false}
      categories={categories}
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}

const mapStateToProps = state => ({
  getCachedCounty: id => getCounty(state, id)
})

const mapDispatchToProps = {
  searchCounties,
  searchCities
}

export default connect(mapStateToProps, mapDispatchToProps)(GeoPicker)

const DEBOUNCE_RATE = 450
const useDebouncePromiseFn = fn =>
  useMemo(() =>
    debounce(fn, DEBOUNCE_RATE),
  [])
