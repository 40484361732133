/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn" */
import React from 'react'

class BooleanField extends React.Component {
  static defaultProps = {
    value: null
  };

  render () {
    var value = this.props.value
    if (value === true || value === 'true' || value === 1 || value === '1')
      value = '1'
    else if (value === null || typeof value === 'undefined' || value === 'null' || value === '')
      value = 'null'
    else
      value = '0'

    return (
      <div className='bool-field'>
        <select className='form-control' ref='input' value={value} onChange={this.onChange}>
          <option value='null'>---</option>
          <option value='1'>Yes</option>
          <option value='0'>No</option>
        </select>
      </div>
    )
  }

  focus = () => {
    this.refs.input.focus()
  };

  onChange = e => {
    var v = e.target.value
    if (v == 'null')
      v = null
    else if (v == '1')
      v = true
    else
      v = false

    this.props.onChange(v)
  };
}

export default BooleanField
