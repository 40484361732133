import React from 'react'
import { Card, Heading } from '@realsoftworks/decor'
import TaskRowsContainer from './TaskRowsContainer'
import TaskRow from './TaskRow'

const StepsCard = ({ title, tasks, ...props }) => (
  <Card variant='padded' {...props}>
    <Heading size={4}>{title}</Heading>
    <TaskRowsContainer pt={1}>
      {tasks.map((s, i) => (
        <TaskRow key={s.id} isLast={i === tasks.length - 1} {...s} />
      ))}
    </TaskRowsContainer>
  </Card>
)

export default StepsCard

// TODO: propTypes
