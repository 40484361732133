/* eslint "eqeqeq": "warn", "react/jsx-key": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { selectOrdersByRef } from 'marketing/selectors'
import { fetchOrders } from 'marketing/actions'
import { updateSettings } from 'users/actions'
import { getSetting } from 'users/selectors'
import { Box, Button, Text, Dialog, Tooltip, Caption, Pill } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'
import { DateTime, Numeric, Monetary } from 'common/format'
import { SkipGenieIcon, BatchSkipIcon } from './ProviderIcons'

const icons = {
  skipGenie: SkipGenieIcon,
  reiGroup: BatchSkipIcon
}

const OrderBox = styled(Box)`
  border-bottom: ${themeGet('borders.light')};
  padding-top: 24px;
  padding-bottom: 24px;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0px;
  }
`

const OrderRow = ({ order, ...props }) => {
  const perSkipCost = order.servicePrice * order.creditPrice / 100
  const perRecordCost = order.requestedServiceQty * perSkipCost
  const perHitCost = order.serviceQty * perSkipCost
  const savings = perRecordCost - perHitCost

  const Icon = icons[order.serviceProvider]

  return (
    <OrderBox {...props}>
      <Box display='flex' alignItems='center' flexWrap='wrap' mb={4}>
        <Pill size='small' mr={3}>{order.service}</Pill>
        {Icon && <Icon mr={3} />}
        <DateTime format='MM/DD/YYYY'>{order.createdAt}</DateTime>
        {(order.status === 'complete' && order.serviceQty > 0) && (
          <Box ml='auto'>
            <Tooltip
              content={
                <>
                  If you paid per record, like most other services,<br />
                  this order would have cost <Monetary>{perRecordCost}</Monetary>.<br /><br />
                  Instead it cost you <Monetary>{perHitCost}</Monetary> for a savings of <Monetary>{savings}</Monetary>.
                </>
              }
            >
              <Box>
                <Text color='secondary'><FontAwesomeIcon icon={faQuestionCircle} />{' '}Savings:</Text>{' '}
                <Text color='primary.700'><Monetary>{savings}</Monetary></Text>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box display='flex' alignItems='flex-start' flexWrap='wrap' mx={-4}>
        <Box mx={4} display='flex' flexDirection='column'>
          <Caption color='secondary'>Quantity</Caption>
          <Box>
            <Text fontSize={3}>{order.serviceQty}</Text> <Caption color='secondary'>hits / {order.requestedServiceQty}</Caption>
          </Box>
        </Box>
        <Box mx={4} display='flex' flexDirection='column' alignItems='center'>
          <Caption color='secondary'>Price</Caption>
          <Box>
            <Text fontSize={3}><Text color='secondary'>$</Text><Numeric decimals={2}>{order.servicePrice * order.creditPrice / 100}</Numeric></Text><Caption color='secondary'>/ea</Caption>
          </Box>
        </Box>
        {order.status === 'pending'
          ? <Box mx={4} flex='1'><Text color='secondary'>processing...</Text></Box>
          : <>
            <Box mx={4} display='flex' flexDirection='column' alignItems='center'>
              <Caption color='secondary'>Total</Caption>
              <Box>
                <Text fontSize={3}><Text color='secondary'>$</Text><Numeric decimals={2}>{order.creditCost * order.creditPrice / 100}</Numeric></Text>
              </Box>
            </Box>
            {order.chargeAmount && (
              <Box mr={4} ml='auto' display='flex' flexDirection='column' alignItems='flex-end'>
                <Caption color='secondary'>Charge</Caption>
                <Box>
                  {order.minEnforced && (
                    <Tooltip content='Minimum order amount. Any unused credit is added to your balance'>
                      <Text>
                        <Text color='blue.500'><FontAwesomeIcon icon={faExclamationTriangle} /></Text>{' '}
                      </Text>
                    </Tooltip>
                  )}
                  <Text fontSize={3}><Text color='secondary'>$</Text><Numeric decimals={2}>{order.chargeAmount / 100}</Numeric></Text>
                </Box>
              </Box>
            )}
          </>}
      </Box>
    </OrderBox>
  )
}

const UnreadIcon = styled(Box)`
  background-color: ${themeGet('colors.red.500')};
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 100px;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
`

const SkiptraceOrderHistoryButton = ({ list, orders, orderDt = 0, fetchOrders, updateSettings, location, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const openDialog = React.useCallback(() => {
    setOpen(true)
    updateSettings({ 'skiptrace.orderDt': Date.now() })
  })
  const closeDialog = React.useCallback(() => setOpen(false))

  React.useEffect(() => {
    setOpen(false)
  }, [location.search])

  if (orders == null || orders.length == 0) return null

  const unreadCount = orders.reduce((acc, v) => {
    if (v.settledAt) {
      const dt = new Date(v.settledAt).getTime()
      if (dt > orderDt)
        return acc + 1
    }

    return acc
  }, 0)

  return (
    <>
      <Tooltip content='Order History'>
        <Box style={{ position: 'relative' }} onClick={openDialog} {...props}>
          {unreadCount > 0 && <UnreadIcon>{unreadCount}</UnreadIcon>}
          <Button
            variant='secondary'
          >
            <FontAwesomeIcon icon={faShoppingCart} />
          </Button>
        </Box>
      </Tooltip>

      <Dialog
        open={open}
        title='Order History'
        onClose={closeDialog}
      >
        {orders.map(v => <OrderRow order={v} />)}
      </Dialog>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  orders: selectOrdersByRef(state, ownProps.list.id),
  orderDt: getSetting(state, 'skiptrace.orderDt')
})

const mapDispatchToProps = {
  fetchOrders,
  updateSettings
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SkiptraceOrderHistoryButton)
