/* eslint "react/no-deprecated": "warn", "react/no-string-refs": "warn", "eqeqeq": "warn", "react/jsx-key": "warn", "no-class-assign": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import without from 'lodash/array/without'

import uncontrollable from 'uncontrollable'

const identity = v => v
const makeGetter = fieldname => typeof fieldname === 'function' ? fieldname : v => v[fieldname]

class Picker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      focused: false,
      inputValue: ''
    }

    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.remove = this.remove.bind(this)
  }

  componentWillMount () {
    this.makeAccessors(this.props)
    this.resetEngine(this.props)
  }

  componentWillReceiveProps (newprops) {
    var resetList = false
    if (newprops.data !== this.props.data) {
      this.resetEngine(newprops)
      resetList = true
    }
    if (this.props.displayField !== newprops.displayField || this.props.valueField !== newprops.valueField) {
      this.close()
      this.makeAccessors(newprops)
      resetList = true
    }

    if (resetList)
      this.setState({ hasOpened: false })
  }

  makeAccessors (props) {
    const { displayField, valueField } = props
    this.getDisplay = displayField ? makeGetter(displayField) : identity
    this.getIdValue = valueField ? makeGetter(valueField) : identity
  }

  onClick () {
    this.focus()
  }

  onFocus () {
    this.focus()
    this.queueCheckFocus(true)
  }

  onBlur () {
    this.queueCheckFocus(false)
  }

  focus () {
    this.refs.input.focus()
  }

  queueCheckFocus (val) {
    clearTimeout(this.focusTimer)
    this.focusTimer = setTimeout(() => this.checkFocus(val), val ? 0 : 200) // TODO react 15
  }

  checkFocus (newfocus) {
    if (this.state.focus !== newfocus) {
      this.setState({ focused: newfocus })

      if (!newfocus)
        this.close()

      if (newfocus && !this.state.open)
        this.open()
    }
  }

  open () {
    if (!this.state.open) {
      var open = true; var options = this.props.data
      if (this.state.hasOpened)
        this.setState({ open, options })
      else
        // sort of hackish way to render the list so that it can animate on first open
        this.setState({ hasOpened: true, options }, () => setTimeout(() => this.setState({ open })))
    }
  }

  close () {
    if (this.state.open)
      this.setState({ open: false, inputValue: '' })
  }

  onInputChange (e) {
    var inputValue = e.target.value
    if (inputValue == '')
      this.setState({ inputValue, options: this.props.data })
    else
      this.engine.get(inputValue, options => {
        this.setState({ inputValue, options })
      })
  }

  clearInput () {
    this.setState({ inputValue: '', options: this.props.data })
  }

  filterOptions (options) {
    var valueItems = this.getValueItems()
    return without(options, ...valueItems)
  }

  onSelect (item) {
    this.focus()
    this.add(item)
    this.clearInput()
  }

  add (item) {
    var value = [...this.props.value, this.getIdValue(item)]
    this.props.onChange && this.props.onChange(value)
  }

  remove (item) {
    var value = without(this.props.value, item)
    this.props.onChange && this.props.onChange(value)
  }

  resetEngine (props) {
    if (this.engine) {
      this.engine.clear()
      delete this.engine
    }

    var { data, displayField, searchFields } = props

    var datumTokenizer
    if (searchFields)
      datumTokenizer = Bloodhound.tokenizers.obj.whitespace(searchFields)
    else if (displayField)
      datumTokenizer = Bloodhound.tokenizers.obj.whitespace(displayField)
    else
      datumTokenizer = Bloodhound.tokenizers.whitespace

    this.engine = new Bloodhound({
      local: data,
      limit: 99,
      datumTokenizer,
      queryTokenizer: Bloodhound.tokenizers.whitespace
    })

    this.engine.initialize()
  }

  componentWillUnmount () {
    if (this.engine) {
      this.engine.clear()
      delete this.engine
    }
  }

  getValueItems () {
    return this.props.data.reduce((ret, v) => {
      var id = this.getIdValue(v)
      if (this.props.value.indexOf(id) !== -1)
        ret.push(v)

      return ret
    }, [])
  }

  getTags () {
    var valueItems = this.getValueItems()

    return Array.prototype.map.call(valueItems, item => <Tag item={item} label={this.getDisplay(item)} onRemove={this.remove} />)
  }

  render () {
    var tags = this.getTags(this.props.value)

    const { hasOpened, open, options } = this.state

    var availableOptions = this.filterOptions(options)

    return (
      <div
        className={classnames('picker', { 'picker-open': open })}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onClick={this.onClick}
      >
        <div
          className={classnames('picker-input', { focus: this.state.focused })}
        >
          {tags}
          <input
            type='text'
            className=''
            autoComplete='off'
            value={this.state.inputValue}
            size={this.state.inputValue.length || 17}
            onChange={this.onInputChange}
            placeholder={this.props.placeholder}
            ref='input'
            onFocus={this.onInputFocus}
            onKeyDown={this.onKeyDown}
          />

        </div>
        {
          hasOpened &&
            <List
              options={availableOptions}
              displayAccessor={this.getDisplay}
              valueAccessor={this.getIdValue}
              onItemClick={this.onSelect}
              messages={this.props.messages}
            />
        }
      </div>

    )
  }
}

Picker.propTypes = {
  onChange: PropTypes.func,

  valueField: PropTypes.string,
  displayField: PropTypes.string,
  searchFields: PropTypes.array,

  data: PropTypes.array,

  disabled: PropTypes.bool,
  readonly: PropTypes.bool

}

Picker.defaultProps = {
  value: [],
  placeholder: 'Type to search...',
  messages: {
    empty: 'No items matched your search'
  }
}

const Tag = ({ label, item, onRemove }) => (
  <div className='picker-item'>{label} <a className='picker-item-remove' onClick={e => { e.preventDefault(); onRemove(item) }}>&times;</a></div>
)

const List = ({ options, onItemClick, displayAccessor, messages }) => (
  <div className='picker-list'>
    <div className='picker-list-container'>
      {options.map(v => (
        <ListItem
          item={v}
          label={displayAccessor(v)}
          onClick={onItemClick}
        />
      ))}
    </div>
    {options.length == 0 &&
        <span className='picker-list-empty'>{messages.empty}</span>}
  </div>
)

// TODO recompose?
const ListItem = ({ onClick, item, label }) => (
  <div className='picker-list-item' onClick={e => onClick(item)}>{label}</div>
)
Picker = uncontrollable(Picker, { value: 'onChange' }, ['focus'])

export default Picker
