import React from 'react'
import styled from 'styled-components'
import Map from 'common/Map'
import { Box, Text } from '@realsoftworks/decor'
import { isGeoComplete, isStreetviewComplete } from 'common/util/address'
import geocode from 'common/geocode'
import { updateGeo } from 'leads/actions'
import { connect } from 'react-redux'
import * as leadSelectors from 'leads/selectors'
import BigLoader from 'common/BigLoader'

const MapHolder = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const MapLoading = () => (
  <Box
    width='100%'
    height='100%'
    display='flex'
    justifyContent='center'
    alignContent='center'
  >
    <BigLoader />
  </Box>
)

const MapError = () => (
  <MapHolder p={4}>
    <Text w='100%' textAlign='center' color='red.500'>There was a problem looking up this address</Text>
  </MapHolder>
)

const MapEmpty = () => (
  <MapHolder p={4}>
    <Text>Enter an address to see map view</Text>
  </MapHolder>
)

class SearchMap extends React.Component {
  state = { error: false }

  onGeocode = results => {
    const { leadId } = this.props
    this.props.updateGeo(leadId, results)
  }

  componentDidMount () {
    const { address } = this.props

    if (!address) return

    const isComplete = isGeoComplete(address) && isStreetviewComplete(address)

    if (isComplete) return

    geocode
      .getDataForAddress(address)
      .then(data => this.onGeocode(data))
      .catch(error => this.setState({ error }))
  }

  render () {
    const { address } = this.props
    const { error } = this.state
    const isComplete = isGeoComplete(address) && isStreetviewComplete(address)

    if (error)
      return <MapError/>
    else if (!address)
      return <MapEmpty/>
    else if (!isComplete)
      return <MapLoading/>
    else
      return (
        <MapHolder>
          <Map
            center={{ lat: address.lat, lon: address.lon }}
            zoom={15}
            streetviewControls
            defaultStreetview={{
              position: { lat: address.streetview_lat, lon: address.streetview_lon },
              heading: -address.streetview_heading // TODO why?
            }}
          />
        </MapHolder>
      )
  }
}

const mapState = (state, { leadId }) => {
  const lead = leadSelectors.getLead(state, leadId)
  const address = lead && lead.values && lead.values.address
  return { address }
}
const mapDispatch = { updateGeo }
const withConnect = connect(mapState, mapDispatch)

export default withConnect(SearchMap)
