import React from 'react'
import { Box, Text } from '@realsoftworks/decor'
import styled from 'styled-components'
import RelativeDate from 'common/RelativeDate'

const Title = styled(Text)``

const ListItem = ({ list }) => (
  <Box px={5} py={2} display='flex' alignItems='center'>
    <Box flex='1'>
      <Title color='#27241D'>{list.name}</Title>
    </Box>
    <Box>
      <Text as='div' textAlign='right' color='#27241D'>{list.memberStats.total}</Text>
      <Text fontSize={0} color='secondary' as='div' textAlign='right'><RelativeDate date={list.createdAt} /></Text>
    </Box>
  </Box>
)

export { Title }

export default ListItem
