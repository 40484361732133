
export default (state = {}, action) => {
  if (action.payload && action.payload.entities && action.payload.entities.tasks)
    // pull in all the tasks
    return {
      ...state,
      ...action.payload.entities.tasks
    }

  return state
}
