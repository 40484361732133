/* eslint "no-useless-escape": "warn" */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { arrayOf, string, shape, func, bool } from 'prop-types'
import { Box, Text, IconButton, toast } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingIcon from 'common/LoadingIcon'
import * as api from 'common/api'

import {
  faTrashAlt,
  faEnvelope,
  faPhone,
  faBriefcase,
  faStickyNote
} from '@fortawesome/free-solid-svg-icons'
import { GENERIC_ERROR_NOTIF } from 'const'

const Contact = ({
  children: contactIds,
  preloadedData,
  onDelete,
  isDeletable,
  ...props
}) => {
  if (!contactIds || !contactIds.length) return null

  const { data, isLoading } = useContactLogic({ preloadedData, contactIds })

  const idsWithData = contactIds
    .map(id => data.find(d => d.id === id))
    .filter(Boolean)

  return isLoading ? (
    <Box py={2} px={3} display='flex' justifyContent='center' {...props}>
      <LoadingIcon size={2} />
    </Box>
  ) : idsWithData.length ? (
    <Box mt={-1} mb={-1} {...props}>
      {idsWithData.map(c => {
        const v = c.values || {}
        const miscData = [
          [v.company_name, faBriefcase],
          [v.phone, faPhone],
          [v.email, faEnvelope],
          [v.notes, faStickyNote]
        ].filter(d => d[0])
        const NBSP = ' ' // 'Tis is no ordinary space

        return (
          <Box key={c.id} py={1} display='flex' alignItems='center'>
            <Box flex='1'>
              <Text fontSize={1} color='blue.900'>
                {`${v.firstname ? v.firstname : ''} ${v.lastname ? v.lastname : ''}`.trim() ||
                  v.name ||
                  `<id:${c.id}>`}
              </Text>
              <Box display='flex' flexWrap='wrap' mt={1}>
                {!!miscData.length && (
                  <Box display='flex' mx={-2}>
                    {miscData.map(([d, icon], i) => (
                      <Fragment key={i}>
                        <Text display='inline-block' pl={2} fontSize={0} color='neutral.500'>
                          <FontAwesomeIcon icon={icon} />
                        </Text>
                        &nbsp;
                        <Text pl={1} pr={2} fontSize={0}>{d.replace(/\ /g, NBSP)}</Text>
                      </Fragment>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            {isDeletable && (
              <IconButton ml={2} variant='danger' onClick={ev => {
                ev.stopPropagation()
                ev.preventDefault()
                onDelete(c.id)
              }}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            )}
          </Box>
        )
      })}
    </Box>
  ) : null
}

Contact.propTypes = {
  children: arrayOf(string),
  preloadedData: arrayOf(shape({
    id: string.isRequired,
    values: shape({
      name: string,
      firstname: string,
      lastname: string,
      company_name: string,
      phone: string,
      email: string,
      notes: string
    })
  })),
  onDelete: func,
  isDeletable: bool
}

export default Contact

const useContactLogic = ({ preloadedData: passedPreloadedData, contactIds }) => {
  const preloadedData = passedPreloadedData || []
  const [selfLoadedData, setSelfLoadedData] = useState([])
  const availableData = [...preloadedData, ...selfLoadedData]
  const idsLackingData = contactIds.filter(id =>
    !availableData.find(d => d.id === id))
  const [isLoading, setIsLoading] = useState(!!idsLackingData.length)

  const requestIdRef = useRef()
  requestIdRef.current = idsLackingData.join(',')

  useEffect(() => {
    if (!idsLackingData.length) {
      setIsLoading(false)
      return
    }

    const requestId = requestIdRef.current

    setIsLoading(true);

    (async () => {
      try {
        const resp = await Promise.all(
          idsLackingData.map(id =>
            api.get(`/contacts/${id}`)
              .catch(e => toast.error(GENERIC_ERROR_NOTIF)))
        )

        if (requestIdRef.current !== requestId) return

        setIsLoading(false)

        setSelfLoadedData(existing =>
          [...existing, ...resp.filter(r => r)]
            .filter(d => contactIds.includes(d.id)))
      } catch (e) {
        setIsLoading(false)
        toast.error(GENERIC_ERROR_NOTIF)
      }
    })()
  }, [requestIdRef.current])

  return {
    data: availableData,
    isLoading
  }
}
