/* eslint "react/display-name": "warn" */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ContactListHandler from './ContactsListHandler'
import ContactViewHandler from './ContactViewHandler'

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/`} exact component={ContactListHandler} />
    <Route path={`${match.path}/:id`} component={ContactViewHandler} />

  </Switch>
)
