import React from 'react'
import { Card, Text, Heading, Box } from '@realsoftworks/decor'

const IfLeadHasPropertyDataInDb = ({ hasPropertyDataInDb, children }) =>
  hasPropertyDataInDb ? (
    children
  ) : (
    <Box
      p={2}
      display='flex'
      flexGrow={1}
      alignItems='stretch'
    >
      <Card
        p={4}
        flexGrow={1}
        display='flex'
        flexWrap='wrap'
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        boxShadow={1}
        bg='white'
      >
        <Heading width='100%' as='h2' size={5} textAlign='center'>
          We can&rsquo;t find any property details
        </Heading>
        <Text
          pt={3}
          width='100%'
          maxWidth='420px'
          fontSize={1}
          textAlign='center'
          color='secondary'
        >
          We searched our system system but we found no match. However, you can use other features and manage this as your lead. You can still view <Black>Comparable Sales</Black>, check nearby <Black>Buyers</Black>, add <Black>Notes/Media</Black>, and manage <Black>Tasks</Black> with this address!
        </Text>
      </Card>
    </Box>
  )

export default IfLeadHasPropertyDataInDb

const Black = props => (
  <Text color='text' fontSize={0} {...props} />
)
