/* eslint "eqeqeq": "warn", "no-prototype-builtins": "warn", "react/no-deprecated": "warn" */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import objectGet from 'lodash/object/get'
import themeGet from '@styled-system/theme-get'

import { Card, TableCell, TableRow, TableHeaderCell, TableHead, TableBody, Table } from '@realsoftworks/decor'

const defaultRender = v => v

const capitalize = function (str) {
  return str.toLowerCase().split('_').map(s => s.replace(/\b\w/g, function (m) {
    return m.toUpperCase()
  })).join(' ')
}

const SortIcon = styled(Card)`
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: ${props => props.dir === 'asc' ? '-5px' : '-2px'};
  border-style: solid;
  border-width: 5px;
  border-color: transparent;
  border-bottom-color: ${themeGet('colors.neutral.500')};

  transform: rotate(${props => props.dir === 'asc' ? '0deg' : '180deg'});
`

class ProHeader extends Component {
  onClick = () => {
    this.props.onClick(this.props.column)
  };

  render () {
    const { column, children, sortable, sortCfg, ...rest } = this.props

    return (
      <TableHeaderCell
        {...rest}
        css={`cursor: ${sortable ? 'pointer' : 'default'}; position: relative;`}
        onClick={this.onClick}
      >
        {sortCfg && <SortIcon dir={sortCfg.dir}/>}
        {children}
      </TableHeaderCell>
    )
  }
}

const access = (colDef, row) => {
  var value = null

  var id = colDef.id
  var accessor = colDef.accessor

  if (accessor)
    if (typeof accessor === 'function')
      value = accessor(row)
    else
      value = objectGet(row, accessor)

  else
    value = objectGet(row, id)

  return value
}

export default class ProTable extends Component {
  constructor (props) {
    super(props)

    this.onHeaderClick = this.onHeaderClick.bind(this)

    this.state = {
      data: props.data,
      sort: props.sort || props.initialSort || []
    }
  }

  canSort (colDef) {
    const { sortable } = this.props
    const columnSortable = colDef.sortable === undefined ? true : colDef.sortable

    return sortable && columnSortable
  }

  getHeaders () {
    const { columns } = this.props
    const { sort } = this.state

    const colElements = columns.map((colDef, i) => {
      let header = colDef.header
      if (!header && header != '')
        header = capitalize(colDef.id)
      else if (typeof header === 'function')
        header = header()

      const sortable = this.canSort(colDef)
      const sortCfg = sort.find(s => s.id == colDef.id)

      const style = {}
      if (colDef.width)
        style.width = colDef.width

      let props = {}
      if (colDef.props)
        props = colDef.props

      return (
        <ProHeader
          onClick={this.onHeaderClick}
          column={colDef}
          key={colDef.id || i}
          sortable={sortable}
          sortCfg={sortCfg}
          style={style}
          {...props}
        >
          {header}
        </ProHeader>
      )
    })

    return colElements
  }

  onHeaderClick = column => {
    if (this.canSort(column))
      this.toggleSort(column.id)
  };

  toggleSort (id) {
    const { sort } = this.state
    let dir = 'asc'
    if (sort.length > 0 && sort[0].id == id)
      dir = sort[0].dir == 'asc' ? 'desc' : 'asc'

    const newSort = [{ id, dir }]

    this.props.onSortChange && this.props.onSortChange(newSort)

    if (!this.props.hasOwnProperty('sort'))
      this.doSort(newSort)
  }

  doSort (sort, data) {
    data = data || this.state.data
    data = [].concat(data)

    var sorter = getSorter(sort, this.props.columns)

    data.sort(sorter)
    this.setState({ data, sort })
  }

  getRows () {
    const { columns } = this.props
    const { data } = this.state

    return data.map((row, i) => {
      var colElements = columns.map(colDef => {
        if (colDef.show === false)
          return null

        var value = access(colDef, row)

        const render = colDef.render || defaultRender
        const classes = classNames(colDef.className)

        const style = {}
        if (colDef.width)
          style.width = colDef.width

        let props = {}
        if (colDef.props)
          props = colDef.props

        return <TableCell key={colDef.id || i} className={classes} style={style} {...props}>{render(value, row, colDef)}</TableCell>
      })

      var rowClass = this.props.getRowClass(row)
      return <TableRow key={i} className={rowClass}>{colElements}</TableRow>
    })
  }

  componentWillReceiveProps (nextProps) {
    var sort = (nextProps.hasOwnProperty('sort') && nextProps.sort !== this.props.sort) ? nextProps.sort : this.state.sort
    var data = nextProps.data !== this.props.data ? nextProps.data : this.props.data

    if (sort !== this.state.sort || data !== this.state.data)
      this.doSort(sort || [], data)
  }

  render () {
    const { className, ...rest } = this.props

    const classes = classNames('protable', className)

    return (
      <Table className={classes} {...rest}>
        <TableHead>
          {this.getHeaders()}
        </TableHead>
        <TableBody>
          {this.getRows()}
        </TableBody>
        {this.props.footer ? this.props.footer : null}
      </Table>
    )
  }
}

ProTable.propTypes = {
  onSortChange: PropTypes.func,
  getRowClass: PropTypes.func,
  data: PropTypes.array,
  footer: PropTypes.element,
  columns: PropTypes.array,
  columnOrder: PropTypes.array,
  sort: PropTypes.array,
  initialSort: PropTypes.array // TODO
}

ProTable.defaultProps = {
  sortable: true,
  getRowClass: row => ''
}

const defaultPrimer = v => v
const getSorter = function (sort, columns) {
  return function (recA, recB) {
    var result = 0
    for (var i = 0, len = sort.length; i < len; i++) {
      var f = sort[i]

      var id = f.id || f
      var dir = f.dir || 'asc'
      var col = columns.find(v => v.id == id)
      var primer = col.primer || defaultPrimer

      var valA = primer(access(col, recA), recA)
      var valB = primer(access(col, recB), recB)

      var reverse = (dir == 'desc') ? -1 : 1

      if (valA < valB) result = reverse * -1
      if (valA > valB) result = reverse * 1

      if (result !== 0) break
    };

    return result
  }
}
