/* eslint "eqeqeq": "warn", "react/display-name": "warn", "react/jsx-key": "warn" */

import React from 'react'
import isObject from 'lodash/lang/isObject'
import initial from 'lodash/array/initial'
import numeral from 'numeral'
import padRight from 'lodash/string/padRight'
import { toSingleLine } from 'common/util/address'
import TextField from './form/TextField.react'
import PickerField from './form/PickerField.react'
import TagPicker from './form/TagPicker'
import SmartDateField from './form/SmartDateField.react'
import AddressField from './form/AddressField.react'
import DateHelper from '../core/date.js'
import LeadContacts from '../modules/leads/components/LeadContacts.react'
import LinksField from './form/LinksField.react'
import FilesField from './form/FilesField.react'
import DateTimeField from './form/DateTimeField.react'
import NumberField from './form/NumberField.react'
import BooleanField from './form/BooleanField.react'
import EditorList from './EditorList.react'

var fieldTypes = {}

var groupSep = ','

var gre = new RegExp(groupSep, 'g')

function init () {
  fieldTypes = Object.assign(fieldTypes, {
    text: {
      field: only(TextField),
      format: function (val, props) {
        return val ? nl2br(val) : ''
      }
    },
    number: {
      field: only(NumberField),
      parse: function (val, props) {
        if (val === null || typeof val === 'undefined' || typeof val === 'number')
          return val

        val = val.replace(gre, '')
        return val
      },
      format: function (val, props) {
        props = props || { format: '0,000[.]00' }

        if (val === null || typeof val === 'undefined' || val === '')
          return ''

        if (props.format == false)
          return val

        if (typeof val === 'string')
          val = val.replace(gre, '')

        val = numeral(val).format(props.format)
        return val
      },
      validator: function (val) {
        return !isNaN(numeral(val).value())
      }
    },
    bool: {
      field: only(BooleanField),
      format: function (val, props) {
        if (val === true || val === 'true' || val === '1')
          return 'Yes'
        else if (val === null || typeof val === 'undefined' || val === '')
          return '---'

        return 'No'
      }
    },
    date: {
      field: function (props) {
        if (props.smart)
          return SmartDateField

        return DateTimeField
      },
      format: function (value, props) {
        if (!value)
          return ''

        if (!isObject(value) || (!('date' in value) && !('time' in value)))
          return DateHelper.formatDateStr(value)// old style dates

        if (props.range) {
          const ret = []
          if (value.date) {
            ret.push(DateHelper.formatDateStr(value.date))

            if (value.time)
              ret.push(DateHelper.formatTimeStr(value.time))
          } else { ret.push('--') }

          ret.push('to')

          if (value.enddate) {
            ret.push(DateHelper.formatDateStr(value.enddate))

            if (value.endtime)
              ret.push(DateHelper.formatTimeStr(value.endtime))
          } else { ret.push('--') }

          return ret.join(' ')
        } else {
          const ret = []
          if (value.date)
            ret.push(DateHelper.formatDateStr(value.date))

          if (value.time)
            ret.push(DateHelper.formatTimeStr(value.time))

          return ret.join(' ')
        }
      }
    },
    picker: {
      field: only(PickerField),
      format: function (value, props) {
        var type = props.model

        if (!value)
          return ''

        return (<span className={type + ' pickervalue'}>{value.name}</span>)
      }
    },

    reference: {
      field: function (props) {
        if (props.model == 'contacttag')
          return TagPicker

        return PickerField
      },
      format: function (value, props) {
        var type = props.model

        if (!value)
          return ''

        if (!Array.isArray(value))
          value = [value]

        if (value.length == 0)
          return ''

        return value.map(function (value) {
          return (<span className={type + ' pickervalue'}>{value.name}</span>)
        })
      }
    },
    address: {
      field: only(AddressField),
      format: function (value, props = {}) {
        const { multiline = true } = props
        if (!value)
          value = {}

        if (!multiline) return toSingleLine(value)

        const { line1, line2, city = '', state = '', zip = '' } = value
        const finalLine = `${city || ''}${(state || '') && ', '}${state || ''}${(zip || '') && ' '}${zip || ''}`

        if (!line1 && !line2 && !finalLine)
          return ''

        return (
          <>
            {line1 && <div>{line1}</div>}
            {line2 && <div>{line2}</div>}
            {finalLine && <div>{finalLine}</div>}
          </>
        )
      }
    },
    contacts: {
      field: only(LeadContacts)
    },
    files: {
      field: only(FilesField)
    },
    monetary: {
      field: only(NumberField),
      parse: function (value, props) {
        return FieldFactory.parse(value, 'number', props)
      },
      format: function (value, props = {}) {
        var decimals = typeof props.decimals !== 'undefined' ? props.decimals : 2

        var format = '0,000'
        if (decimals > 0)
          format += padRight('.', decimals, '0')

        value = FieldFactory.format(value, 'number', { format: format })
        if (value)
          value = '$' + value

        return value
      },
      validator: function (value) {
        return FieldFactory.validate(value, 'number')
      }
    },
    links: {
      field: only(LinksField),
      format: function (value) {
        if (!value)
          return ''

        if (!Array.isArray(value))
          value = [value]

        return value.filter(v => v).map(function (link) {
          return <div className='weblink'><a href={link.url}>{link.url}</a></div>
        })
      }
    },

    list: {
      field: only(EditorList),
      format: function (value, props) {
        if (!value)
          return ''

        var displayKey = props.displayKey

        var ret = value.map(function (o, i) {
          return <div key={i} className='editorlist-item'>{o[displayKey]}</div>
        })

        return ret
      }
    }
  })
}

function only (Ctr) {
  return function () {
    return Ctr
  }
}

function nl2br (str) {
  var breakTag = '<br>' // Adjust comment to avoid issue on phpjs.org display
  str = (str + '')
    .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')

  str = str.split('<br>')
  var ret = []
  str.forEach(function (s) {
    ret.push(s, <br />)
  })

  return initial(ret)
}

const FieldFactory = {

  getField: function (type, props) {
    init()

    return React.createFactory(fieldTypes[type].field(props))
  },

  format: function (value, type, props) {
    init()

    var formatter = fieldTypes[type].format
    if (!formatter)
      throw new Error('No formatter defined for field type ' + type)

    return fieldTypes[type].format(value, props)
  },

  parse: function (value, type, props) {
    var parser = fieldTypes[type].parse
    if (parser)
      value = parser(value, props)

    return value
  },

  getFormatter: function (type, props) {
    init()
    var format = fieldTypes[type].format
    return function (value) {
      return format(value, props)
    }
  },

  getValidator: function (type) {
    init()
    return fieldTypes[type].validator
  },

  validate: function (value, type) {
    return FieldFactory.getValidator(type)(value)
  },

  registerFieldType: function (type, def) {
    fieldTypes[type] = def
  }

}

export default FieldFactory
