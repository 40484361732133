import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@realsoftworks/decor'
import { FullScreenLayout, PageTitle } from 'common/layouts'
import BigLoader from 'common/BigLoader'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'

import { ListDetail } from 'lists'
import PermissionComponent from 'users/components/PermissionComponent'
import FeatureComponent from 'billing/components/FeatureComponent'
import { FEATURES } from 'billing/constants'

import { fetchLists } from 'lists/actions'
import { selectLists } from 'lists/selectors'

import CTA from 'leadlists/components/CallToAction'

import Sellers from 'leadlists/components/Sellers'
import Buyers from 'leadlists/components/Buyers'
import Lenders from 'leadlists/components/Lenders'
import ListsSidebar from './ListsSidebar'
import get from 'lodash/object/get'

const CTAwithRouter = withRouter(CTA)

const LeadListsCTA = () => (
  <Box display='flex' justifyContent='center' alignItems='center' mt={5}>
    <PageTitle textAlign='center'>
      Upgrade subscription to access this section
    </PageTitle>
  </Box>
)
const Dashboard = ({ match, fetchLists }) => {
  const { hasFetchedAtLeastOnce, pageData } = useLists({ fetchLists })

  return (
    <BigLoader loading={!hasFetchedAtLeastOnce}>
      <React.Fragment>
        <FeatureComponent feature={FEATURES.LEADS} alternative={<LeadListsCTA />}>
          <PermissionComponent action='manage' model='leadlists'>
            <FullScreenLayout>
              <Box display='flex' >
                <ListsSidebar pageData={pageData} />
                <Box flex='1' mx={4} mt={50}>
                  <Switch>
                    <Route path={`${match.path}`} exact component={CTAwithRouter}/>
                    <Route path={`${match.path}/sellers`} component={Sellers}/>
                    <Route path={`${match.path}/buyers`} component={Buyers}/>
                    <Route path={`${match.path}/lenders`} component={Lenders}/>
                    <Route path={`${match.path}/:id`} component={ListDetail}/>
                  </Switch>
                </Box>
              </Box>
            </FullScreenLayout>
          </PermissionComponent>
        </FeatureComponent>
      </React.Fragment>

    </BigLoader>
  )
}

const mapStateToProps = state => ({
  lists: selectLists(state)
})

const mapDispatchtoProps = {
  fetchLists
}

export default connect(mapStateToProps, mapDispatchtoProps)(Dashboard)

const PAGE_LIMIT = 50
const useLists = ({ fetchLists }) => {
  const [hasFetchedAtLeastOnce, setHasFetchedAtLeastOnce] = useState(false)

  const onChangePage = useCallback(offset => {
    setPageData(p => ({ ...p, loading: true }))
    fetchLists({ offset, limit: PAGE_LIMIT })
      .then(resp => {
        const count = get(resp, 'result.count')
        const length = get(resp, 'result.items.length')
        setPageData(p => ({ ...p, offset, count, length, loading: false }))
        setHasFetchedAtLeastOnce(true)
      })
      .catch(() => setPageData(p => ({ ...p, loading: false })))
  }, [fetchLists])

  const [pageData, setPageData] = useState({
    limit: PAGE_LIMIT,
    offset: 0,
    length: 0,
    count: 0,
    onChangePage
  })

  // Fetch list on mount
  useEffect(() => {
    const offset = 0
    onChangePage(offset)
  }, [])

  return {
    hasFetchedAtLeastOnce,
    pageData
  }
}
