import { FETCH_PROPERTY, FETCH_PROPERTY_SUCCESS, FETCH_PROPERTY_FAILED } from './actions'
import set from 'common/util/set'

const initState = {
  byId: {}
}

const reducer = (state = initState, { type, payload }) => {
  switch (type) {
    case FETCH_PROPERTY:
      return set(
        ['byId', payload.id],
        { status: 'LOADING' },
        state
      )

    case FETCH_PROPERTY_SUCCESS:
      return set(
        ['byId', payload.id],
        { status: 'SUCCESS', data: payload.property },
        state
      )

    case FETCH_PROPERTY_FAILED:
      return set(
        ['byId', payload.id],
        { status: 'FAILED' },
        state
      )

    default:
      return state
  }
}

export default reducer
