// TODO merge this with DealAlertListingsHandler as this is just a mess of props

import React, { Component } from 'react'

import { Disclosure as CMADisclosure } from '../../../../modules/mls/components'

import PagingToolbar from '../../../../views/common/paging-toolbar'

import DealAlertListingTable from './DealAlertListingTable'
import DealAlertMap from './DealAlertMap'
import DealAlertDisclosure from './DealAlertDisclosure'

import Card from 'common/Card'
import { Box } from '@realsoftworks/decor'

export default class DealAlertResult extends Component {
  render () {
    var { onClick, onPageClick, onSortChange, result, params, alert, mapProps, onMapChange, toggle, addLead, statuses, metadata } = this.props
    var { items, count, source } = result

    return (
      <div className='deal-alert-result'>
        <Card mb={5}>
          <DealAlertMap defaultZoom={mapProps.zoom} defaultCenter={mapProps.center} onChange={onMapChange} listings={items} onMarkerClick={onClick} />
        </Card>

        <Card variant='basic'>
          <Box ml={5}>
            <PagingToolbar count={count} params={params} onPageClick={onPageClick} />
          </Box>

          <DealAlertListingTable
            alert={alert}
            metadata={metadata}
            listings={items}
            sort={params.sort}
            onSortChange={onSortChange}
            toggle={toggle}
            addLead={addLead}
            statuses={statuses}
          />
          <Box ml={5}>
            <PagingToolbar count={count} params={params} onPageClick={onPageClick} />
          </Box>
          <Box p={5}>
            <DealAlertDisclosure />
            <CMADisclosure source={source} />
          </Box>
        </Card>
      </div>
    )
  }
}
