import React, { useMemo, FC, useCallback, useState, useEffect } from 'react'
import { Heading, Card } from '@realsoftworks/decor'
import GeoPicker from '../../../GeoPicker'
import ensureArray from 'common/util/ensureArray'

type GeoFilters = {
  city?: string[]
  county?: string[]
  zip?: string[]
}

const GeoSection: FC<{
  filters: GeoFilters,
  onChange: (filters: GeoFilters) => void,
  type?: string
}> = ({ filters, onChange, type }) => {
  const { geoValue, onGeoChange, setGeoValue} = useGeoLogic({ onChange })
  const filteredString = JSON.stringify(filters);

  // @HACK: Setting `filters` directly as a dependency would trigger a re-render
  // on each pass, as useEffect only does shallow compares.
  useEffect(() => {
    try {
      const { county = [], city = [], zip = [] } = JSON.parse(filteredString);

      setGeoValue([
        ...ensureArray(county),
        ...ensureArray(city),
        ...ensureArray(zip),
      ]);
    } catch (error) {
      // Send to sentry? Or just ignore it. Probably filters is undefined at this point.
    }
  }, [filteredString]);

  return useMemo(() =>
    <Card p={4} borderRadius='5px' bg='white' boxShadow={1}>
      <Heading size={4} mb={3} >Geography (Required)</Heading>
      <GeoPicker value={geoValue} onChange={onGeoChange} type={type} />
    </Card>,
  [geoValue, onGeoChange])
}

export default GeoSection

const useGeoLogic = ({ onChange }: {
  onChange: (filters: GeoFilters) => void
}) => {

  const [geoValue, setGeoValue] = useState<unknown[]>([])

  const onGeoChange = useCallback((_e, arrayForm, groupedForm) => {
    onChange(groupedForm)
    setGeoValue(arrayForm)
  }, [onChange])

  return {
    geoValue,
    onGeoChange,
    setGeoValue
  }
}
