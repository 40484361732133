import { connect } from 'react-redux'

import { selectors } from 'billing'

let FeatureComponent = ({ hasFeature, children, alternative = null }) => hasFeature ? children : alternative

const mapStateToProps = (state, props) => ({
  hasFeature: selectors.hasFeature(state, props.feature)
})

FeatureComponent = connect(mapStateToProps)(FeatureComponent)

export default FeatureComponent
