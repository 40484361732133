
import without from 'lodash/array/without'

import documents from './documents'
import terms from './terms'

import * as c from '../../actions'

import * as userActions from '../../../users/actions'

import { Schema, arrayOf, normalize } from 'normalizr'

var esignSchema = new Schema('esign')

var brokersrcSchema = new Schema('brokersrc')
brokersrcSchema.define({
  requirements: {
    esign: arrayOf(esignSchema)
  }
})

const initialState = {
  errors: {},
  authorizing: {},
  pendingSources: [],
  user: {},
  brokersBySource: {
  },

  brokerSources: {
  },

  documents: {

  },

  terms: {

  }
}

export default (state = initialState, action) => {
  var { payload, type, meta } = action
  switch (action.type) {
    case c.SOURCE_REQUIREMENTS:

      if (payload.broker)
        payload = { ...payload, brokers: [payload.broker] }

      payload = normalize(payload, { brokers: arrayOf(brokersrcSchema) })

      var normalizedAction = { type, meta, payload }

      return {
        ...state,
        pendingSources: [...state.pendingSources, payload.result.source],
        user: user(state.user, normalizedAction),
        brokersBySource: brokersBySource(state.brokersBySource, normalizedAction),
        brokerSources: brokerSources(state.brokerSources, normalizedAction),
        documents: documents(state.documents, normalizedAction),
        terms: terms(state.terms, normalizedAction)
      }

    case c.SIGN_DOCUMENT:
    case c.SIGN_DOCUMENT_CANCELLED:
    case c.SIGN_DOCUMENT_FAILURE:
      return {
        ...state,
        documents: documents(state.documents, action)
      }

    case c.SIGN_DOCUMENT_SUCCESS:

      // check all the requirements that reference this document
      return {
        ...state,
        documents: documents(state.documents, action)
      }

    case c.AUTH_CONNECT:
      return {
        ...state,
        authorizing: {
          ...state.authorizing,
          [meta.source]: true
        }
      }

    case c.AUTH_CONNECT_SUCCESS:
      return {
        ...state,
        pendingSources: without(state.pendingSources, meta.source),
        authorizing: {
          ...state.authorizing,
          [meta.source]: false
        }
      }

    case c.AUTH_CONNECT_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, [meta.source]: payload },
        authorizing: {
          ...state.authorizing,
          [meta.source]: false
        }
      }

    case userActions.USER_EMAIL_CONFIRMED:
    case c.MLS_ACCEPT_TOS_SUCCESS:
      return {
        ...state,
        user: user(state.user, action)
      }

    default: return state
  }
}

const brokersBySource = (state = {}, { type, payload, meta }) => {
  switch (type) {
    default:
      if (payload.entities && payload.entities.brokersrc) {
        var { source, brokers } = payload.result
        return {
          ...state,
          [source]: brokers
        }
      }

      return state
  }
}

const brokerSrcKey = (source, broker) => `${source}-${broker}`

const brokerSources = (state = {}, { type, payload, meta }) => {
  switch (type) {
    default:
      if (payload.entities && payload.entities.brokersrc) {
        var { source, brokers } = payload.result
        var toMerge = brokers.reduce((v, b) => {
          v[brokerSrcKey(source, b)] = payload.entities.brokersrc[b]
          return v
        }, {})
        return {
          ...state,
          ...toMerge
        }
      }

      return state
  }
}

const user = (state = {}, { type, payload, meta }) => {
  let updatedState = state

  switch (type) {
    case c.SOURCE_REQUIREMENTS:
      updatedState = Object.assign({}, state, {
        termsOfService: payload.result.termsOfService === true,
        termsOfServiceText: payload.result.termsOfService === true ? null : payload.result.termsOfService,
        emailConfirmed: payload.result.emailConfirmed
      })
      break

    case userActions.USER_EMAIL_CONFIRMED:
      updatedState = Object.assign({}, state, {
        emailConfirmed: true
      })
      break

    case c.MLS_ACCEPT_TOS_SUCCESS:
      updatedState = Object.assign({}, state, {
        termsOfService: true
      })
      break

    default:
      updatedState = state
  }

  return updatedState
}
