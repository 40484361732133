import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Text, Paragraph, Box, Card as BaseCard } from '@realsoftworks/decor'
import Card from 'common/Card'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearPrevious } from '../actions'

const PlainLink = styled(Link)`
  &:hover, &:visited, &:focus {
    text-decoration: none;
  }
`

const FlexHeading = styled(Heading)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  gap: 8px;
`

const StyledBaseCard = styled(BaseCard)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
`

const StyledText = styled(Text)`
  @media (max-width: 1145px) {
    width: min-content;
    text-align: left;
  }
`

const BallotIcon = ({ color }) => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M28.5 4.5H7.5C5.85 4.5 4.5 5.85 4.5 7.5V28.5C4.5 30.15 5.85 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5ZM27 12.75C27 11.9216 26.3284 11.25 25.5 11.25H21C20.1716 11.25 19.5 11.9216 19.5 12.75C19.5 13.5784 20.1716 14.25 21 14.25H25.5C26.3284 14.25 27 13.5784 27 12.75ZM27 23.25C27 22.4216 26.3284 21.75 25.5 21.75H21C20.1716 21.75 19.5 22.4216 19.5 23.25C19.5 24.0784 20.1716 24.75 21 24.75H25.5C26.3284 24.75 27 24.0784 27 23.25ZM7.5 25.5C7.5 27.1569 8.84315 28.5 10.5 28.5H25.5C27.1569 28.5 28.5 27.1569 28.5 25.5V10.5C28.5 8.84315 27.1569 7.5 25.5 7.5H10.5C8.84315 7.5 7.5 8.84315 7.5 10.5V25.5ZM16.5 12C16.5 10.3431 15.1569 9 13.5 9H12C10.3431 9 9 10.3431 9 12V13.5C9 15.1569 10.3431 16.5 12 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V12ZM10.5 12.75C10.5 13.9926 11.5074 15 12.75 15C13.9926 15 15 13.9926 15 12.75C15 11.5074 13.9926 10.5 12.75 10.5C11.5074 10.5 10.5 11.5074 10.5 12.75ZM16.5 22.5C16.5 20.8431 15.1569 19.5 13.5 19.5H12C10.3431 19.5 9 20.8431 9 22.5V24C9 25.6569 10.3431 27 12 27H13.5C15.1569 27 16.5 25.6569 16.5 24V22.5ZM10.5 23.25C10.5 24.4926 11.5074 25.5 12.75 25.5C13.9926 25.5 15 24.4926 15 23.25C15 22.0074 13.9926 21 12.75 21C11.5074 21 10.5 22.0074 10.5 23.25Z' fill={color}/>
  </svg>
)
const GiantButton = withRouter(({ path, match, children, color, icon, btnActive }) => (
  <PlainLink to={`/leadlists/${path}`}>
    <StyledBaseCard fontSize={4} bg={`${color}.500`} py={4} borderRadius={4} opacity={ btnActive === null ? 1 : (btnActive === path ? 1 : 0.5) }>
      <FlexHeading size={4} color='primary.100' textAlign='center'>
        <Text color={`${color}.400`} fontSize={5} lineHeight={0}>{icon}</Text>
        <StyledText>{children}</StyledText>
      </FlexHeading>
    </StyledBaseCard>
  </PlainLink>
))

class CTA extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    btnActive: this.props.location.pathname.split('/')[2] || null
  }

  handleClick = btnActive => () => {
    this.props.clear()
    this.setState({ btnActive })
  }

  render () {
    return (
      <Box maxWidth={1000} {...this.props} mx='auto' mb={50}>
        <Heading size={3}>New List</Heading>
        <Paragraph>Pick a list type below to browse leads and create a new marketing list</Paragraph>
        <Card>
          <Box display='flex' mx={-3}>
            <Box flex='1' mx={3} onClick={this.handleClick('sellers')}>
              <GiantButton path='sellers' btnActive={this.state.btnActive} color='teal' icon={<BallotIcon color='#56C5CC' />}>
                Property Lists
              </GiantButton>
            </Box>
            <Box flex='1' mx={3} onClick={this.handleClick('buyers')}>
              <GiantButton path='buyers' btnActive={this.state.btnActive} color='blue' icon={<BallotIcon color='#7CC1E4' />}>
                Cash Buyers
              </GiantButton>
            </Box>
            <Box flex='1' mx={3} onClick={this.handleClick('lenders')}>
              <GiantButton path='lenders' btnActive={this.state.btnActive} color='red' icon={<BallotIcon color='#E66A6A' />}>
                Private Lenders
              </GiantButton>
            </Box>
          </Box>
        </Card>
      </Box>
    )
  }
};

export default connect(null, { clear: clearPrevious })(CTA)
