/* eslint "react/no-unescaped-entities": "warn" */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { getRetrialStatus } from '../selectors'
import { Box, Heading, Text, Paragraph, Card } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrinStars, faChevronRight, faExclamationSquare } from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'react-router-dom'

const RetrialOptionContainer = styled(Card)`
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;

  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover, &:focus, &:visited {
    text-decoration: none;
    color: white;
  }

  &:hover {
    transform: scale(1.1) translateZ(0);
  }
`

const RetrailOptionButton = styled(Card)`
  width: 200px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;

  @media only screen and (max-width: 576px) {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
  }
`

RetrailOptionButton.defaultProps = {
  p: 5
}

RetrialOptionContainer.defaultProps = {
  as: Link
}

function RetrialBox ({ color, title, text, link, ...props }) {
  return (
    <RetrialOptionContainer bg={`${color}.500`} as={Link} to={link} {...props}>
      <RetrailOptionButton bg={`${color}.900`}>
        <Heading size={5} color={`${color}.200`}>{title}</Heading>
      </RetrailOptionButton>
      <Box display='flex' flex='1' p={4} alignItems='center'>
        <Text color={`${color}.200`}>
          {text}
        </Text>
      </Box>
      <Box display='flex' alignItems='center' p={2}>
        <Text color='white'>
          <FontAwesomeIcon icon={faChevronRight} />
        </Text>
      </Box>

    </RetrialOptionContainer>
  )
}

function RetrialError ({ message, ...props }) {
  return (
    <Box maxWidth='35em' m='0 auto;' {...props}>
      <Text color='secondary' textAlign='center'>
        <Box>
          <Text fontSize={7}>
            <FontAwesomeIcon icon={faExclamationSquare} />
          </Text>
        </Box>
        <Paragraph>{message}</Paragraph>
      </Text>
    </Box>
  )
}

function RetrialPage ({ result }) {
  return (
    <Box maxWidth={500} mx='auto'>
      {
        result.error
          ? <RetrialError mb={7} message={result.error.message} />
          : <Box>
            <Heading size='3' textAlign='center' mb={2}>
              <Text color='teal.500'>
                <FontAwesomeIcon icon={faGrinStars} />
              </Text>
              {' '}Welcome back!{' '}
              <Text color='teal.500'>
                <FontAwesomeIcon icon={faGrinStars} />
              </Text>
            </Heading>
            <Heading size={5} textAlign='center' mb={3}>
              We're so glad you decided to give us another shot!
            </Heading>
            <Box maxWidth='30em' mx='auto' mb={7}>

              <Paragraph>
              We feel like Propelio is an incredibly valuable
              tool to have on any investor's toolbelt and we hope during your new trial that you feel the same.
              </Paragraph>
            </Box>
            <Heading size='4' textAlign='center' mb={4}>
            Let's jump start your new trial:
            </Heading>
          </Box>
      }
      <Box maxWidth='500'>
        <RetrialBox
          link='/onboarding'
          color='teal'
          title='Getting Started'
          text='Learn about everything we can do for your business'
          mb={5}
        />

        <RetrialBox
          link='/leadlists'
          color='blue'
          title='Marketing Lists'
          text='Access millions of property lists, buyer and lender leads'
          mb={5}
        />

        <RetrialBox
          link='/search'
          color='red'
          title='Analyze Property'
          text='Add a lead and run a CMA in every major market across the nation'
          mb={5}
        />

        <RetrialBox
          link='/drivingfordollars'
          color='yellow'
          title='Drive for Dollars'
          text='Download the app to capture leads and market to them'
          mb={5}
        />
      </Box>

    </Box>
  )
};

const mapStateToProps = state => ({
  result: getRetrialStatus(state)
})

export default connect(mapStateToProps)(RetrialPage)
