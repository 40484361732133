
import get from 'lodash/object/get'
import formatLead from 'search/formatLead'
import getOneOf from 'common/util/getOneOf'
import formatSituations from 'common/util/formatSituations'

/**
 * Map history (leads) data for search history display.
 * Most of `getOneOf` paths below usually consists of:
 *   #1 path for leads from `/leads` (multiple)
 *   #2 path for lead from `/leads/withaddress` (single)
 *   #3 ones starting with `values.`, which are user defined lead values that
 *     serves as fallback to the values above (happens when lead has no
 *     corresponding national lead)
 */
const formatLeadToHistory = lead => {
  /**
   * Conditional path for #3 path described above. Returns the path only if
   * there is no `lead.property` (happens when lead has no corresponding
   * national lead)
   */
  const hasPropertyDataInDb = lead && lead.hasPropertyDataInDb
  const ifNoProp = path => hasPropertyDataInDb ? '' : path

  const lastSaleDateStr = getOneOf(lead, [
    'property.summary.lastSaleDate',
    'property.property.lastSaleDate'
  ])

  const formatted = {
    ...formatSituations(lead.property),
    hasPropertyDataInDb,

    propertyAddressLine1: getOneOf(lead, [
      'property.address.line1',
      ifNoProp('values.address.line1')
    ]),
    propertyAddressCity: getOneOf(lead, [
      'property.address.city',
      ifNoProp('values.address.city')
    ]),
    propertyAddressCounty: getOneOf(lead, [
      'property.address.county',
      ifNoProp('values.address.county')
    ]),
    propertyAddressLat: getOneOf(lead, [
      'property.address.coordinates.latitude',
      ifNoProp('values.address.lat')
    ]),
    propertyAddressLon: getOneOf(lead, [
      'property.address.coordinates.longitude',
      ifNoProp('values.address.lon')
    ]),
    propertyAddressState: getOneOf(lead, [
      'property.address.state',
      ifNoProp('values.address.state')
    ]),
    propertyAddressZip: getOneOf(lead, [
      'property.address.zip',
      ifNoProp('values.address.zip')
    ]),

    lead: formatLead(lead),
    id: get(lead, 'id'),
    hasDueTask: (get(lead, 'values.tasksdue') || []).length > 0,

    beds: getOneOf(lead, [
      'property.summary.beds',
      ifNoProp('values.beds')
    ]),
    bathsHalf: getOneOf(lead, [
      'property.summary.baths.half',
      ifNoProp('values.baths_half')
    ]),
    bathsFull: getOneOf(lead, [
      'property.summary.baths.full',
      ifNoProp('values.baths_full')
    ]),
    sqft: getOneOf(lead, [
      'property.summary.sqft',
      ifNoProp('values.sqft')
    ]),
    yearBuilt: getOneOf(lead, [
      'property.summary.yearBuilt',
      ifNoProp('values.year_built')
    ]),
    equity: getOneOf(lead, [
      'property.summary.equity',
      'property.loanModel.availableEquity'
    ]),
    value: getOneOf(lead, [
      'property.summary.value'
    ]),
    lastSaleDate: lastSaleDateStr && new Date(lastSaleDateStr),
    lastSalePrice: getOneOf(lead, [
      'property.summary.lastSaleAmount'
    ]),
    name: getOneOf(lead, [
      'property.summary.owners',
      ifNoProp('values.contacts')
    ], [])
      .map(c => getOneOf(c, ['name', 'values.name']))
      .join(', '),
    isIndividual: getOneOf(lead, [
      'property.summary.distressedCriteria.isIndividual'
    ]),
    loanToValue: getOneOf(lead, [
      'property.summary.loanToValue'
    ]),
    userData: {
      address: get(lead, 'values.address', {})
    }
  }

  return formatted
}

export default formatLeadToHistory
