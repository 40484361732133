import { useState, useEffect, memo } from 'react'
import { arrayOf, number, object } from 'prop-types'
import { useMapApi } from 'common/Map'
import theme from '../../../../theme'
import { isGeoJsonArrClosed, geoJsonArrToLatLngLiteralArr } from './utils'

// NOTE: don't recreate the polygon and styles objects if not
//   necessary to prevent recalculations due to props change
const Polygon = memo(({
  polygon = [],
  lineStyles = {},
  planeStyles = {}
}) => {
  const { map, maps } = useMapApi()
  const [mapObjects, setMapObjects] = useState({})

  initiateMapObjects({
    map,
    mapObjects,
    maps,
    lineStyles,
    planeStyles,
    setMapObjects
  })

  drawMapObjectsFromProps({
    map,
    mapObjects,
    polygon
  })

  return null
})

export default Polygon

Polygon.propTypes = {
  polygon: arrayOf(arrayOf(number.isRequired)),
  lineStyles: object,
  planeStyles: object
}

/* Subprocedures */

const initiateMapObjects = ({
  map,
  mapObjects,
  maps,
  lineStyles,
  planeStyles,
  setMapObjects
}) => {
  useEffect(() => {
    // Note that we create mapObjects only once per instance
    if (!map || mapObjects.line) return

    const line = new maps.Polyline({
      map,
      ...DEFAULT_LINE_STYLES,
      ...lineStyles
    })

    const plane = new maps.Polygon({
      map,
      ...DEFAULT_PLANE_STYLES,
      ...planeStyles
    })

    setMapObjects({ line, plane })
  }, [map, mapObjects])
}

const drawMapObjectsFromProps = ({
  map,
  mapObjects,
  polygon
}) => {
  useEffect(() => {
    if (!map || !mapObjects.line) return

    const mapObjCompatiblePath = geoJsonArrToLatLngLiteralArr(polygon)

    // Draw 'em line
    mapObjects.line.setPath(mapObjCompatiblePath)

    // Draw 'em plane if closed
    mapObjects.plane.setPath(isGeoJsonArrClosed(polygon)
      ? mapObjCompatiblePath
      : [])
  }, [map, mapObjects, polygon])
}

/* Constants */

const { colors } = theme

const DEFAULT_LINE_STYLES = {
  strokeColor: colors.yellow[500],
  strokeOpacity: 0.7,
  strokeWeight: 3
}

const DEFAULT_PLANE_STYLES = {
  strokeWeight: 0,
  fillColor: colors.yellow[500],
  fillOpacity: 0.3
}
