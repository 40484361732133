import React, { Component } from 'react'
import moment from 'moment-timezone'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts'

class BigChart extends Component {
  render () {
    const { data, yKey } = this.props
    return (
      <ResponsiveContainer width='100%' aspect={16 / 9}>
        <AreaChart data={data}>
          <XAxis dataKey='ts' tickLine={false} axisLine={false} tickFormatter={formatTick} />
          <YAxis dataKey={yKey} tickLine={false} axisLine={false} />
          <Tooltip labelFormatter={formatTick} />
          <CartesianGrid vertical={false} />
          <Area type='monotone' dataKey={yKey} stroke='#4a90e2' strokeWidth={3} dot={false} fill='#4a90e2' fillOpacity={0.2} />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

const formatTick = ts => moment(ts).tz('UTC').format('MMM D')

export default BigChart
