/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn", "react/no-deprecated": "warn", "react/no-find-dom-node": "warn" */
import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'

import DisplayField from './DisplayField.react'
import FormInput from '../form/FormInput.react'
import $ from 'jquery'
import classnames from 'classnames'

import FieldFactory from '../FieldFactory.react'

var EditorInput = createReactClass({
  displayName: 'EditorInput',

  getInitialState: function () {
    return { editingValue: null, valid: true }
  },

  getDefaultProps: function () {
    return {
      editable: true,
      emptyValue: 'Click to edit'
    }
  },

  render: function () {
    var { editing, onClick, onChange, id, onValidationChange, editable, onFinish, emptyValue, ...props } = this.props

    if (editing)
      props.value = this.state.editingValue

    var origEmptyValue = emptyValue
    props.emptyValue = origEmptyValue || 'Click to edit'
    props.onChange = this.onFieldChange
    props.ref = 'field'
    props.valid = this.state.valid

    var ctr = null
    if (!editable) {
      props.emptyValue = origEmptyValue || '---'
      ctr = DisplayField
    } else if (editing) { ctr = FormInput } else {
      props.title = props.displayTitle || 'Click to edit'
      ctr = DisplayField
    }

    var child = React.createElement(ctr, props)

    var classes = classnames({
      editor: true,
      field: true,
      editing,
      editable: this.props.editable
    })

    classes = [classes, this.props.type, 'editor-field editor-field-' + this.props.name].join(' ')

    return (
      <div className={classes} onClick={onClick} onKeyDown={this.onKeyDown}>
        {!editing ? <input type='text' tabIndex='0' className='focus-el' onFocus={onClick} /> : ''}
        {child}
      </div>
    )
  },

  onFieldChange: function (e) {
    var value = e
    if (value && e.target)
      value = e.target.value

    var valid = this.props.validator ? this.props.validator(value) : true

    this.setState({ editingValue: value, valid: valid }, function () {
      if (this.props.onValidationChange)
        this.props.onValidationChange(this.state.valid)
    })
  },

  onChange: function () {
    if (this.props.onChange)
      this.props.onChange.apply(null, arguments)
  },

  onFinish: function () {
    if (this.props.onFinish)
      this.props.onFinish.apply(null, arguments)
  },

  onKeyDown: function (e) {
    switch (e.key) {
      case 'Enter':
        if (this.props.type != 'text' && !this.props.multiline)
          this.stopEditing()

        break
    }
  },

  onBlur: function (e) {
    if (!this.refs.field.checkBlur || this.refs.field.checkBlur(e))
      this.stopEditing()
  },

  componentWillReceiveProps: function (nextProps) {
    var e = this.props.editing
    var willE = nextProps.editing

    if (e && willE && nextProps.value != this.props.value) {
      var value = nextProps.value

      // nw - this will cause value changes during editing
      // to be seen as real edits. ie: they will fail the v !== editingValue
      // check in hasChanged method.
      // so far the only place we do this is for adding new values from a picker, like contact tags
      if (Array.isArray(value))
        value = [].concat(value)

      this.setState({
        editingValue: value
      })
    }

    if (!e && willE && nextProps.editable !== false) { this.startEditing(nextProps.value) } else if (e && !willE) {
      $(document.body).off('click.editorfield')
      this.checkChanged()
    }
  },

  onBodyClick: function (e) {
    var t = e.target
    if (!$.contains(ReactDOM.findDOMNode(this), t))
      if (!this.refs.field.checkBlur || this.refs.field.checkBlur(e))
        this.stopEditing()
  },

  startEditing: function (value) {
    this.setState({ editingValue: value }, this.onStartEditing)
    $(document.body).on('click.editorfield', this.onBodyClick)
  },

  stopEditing: function () {
    if (this.state.valid === false)
      return

    this.onStopEditing()
    this.checkChanged()
  },

  hasChanged: function (v) {
    return v !== this.props.value
  },

  onStartEditing: function () {
    this.refs.field.focus()
  },

  onStopEditing: function () {
    $(document.body).off('click.editorfield')
  },

  checkChanged () {
    var v = this.state.editingValue
    var t = this.props.type
    v = FieldFactory.parse(v, t, this.props)

    if (this.hasChanged(v))
      this.onChange(v)
    else
      this.onFinish()
  }
})

export default EditorInput
