import PropTypes from 'prop-types'
import React from 'react'
import TouchRipple from '../internal/TouchRipple'
import classNames from 'classnames'
import logError from 'common/logError'

import './button.less'

class Button extends React.Component {
  static propTypes = {
    centerRipple: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    disableTouchRipple: PropTypes.bool,
    appearance: PropTypes.string,
    size: PropTypes.oneOf(['medium', 'large'])
  };

  static defaultProps = {
    disableTouchRipple: true,
    containerElement: 'button',
    appearance: 'default',
    size: 'medium'
  };

  render () {
    const {
      containerElement,
      disabled,
      link,
      centerRipple,
      disableTouchRipple,
      className,
      children,
      size,
      appearance,
      ...rest
    } = this.props

    const buttonChildren = !disabled && !disableTouchRipple
      ? <TouchRipple
        centerRipple={centerRipple}
      >
        {children}
      </TouchRipple>
      : children

    const classes = classNames('decor', 'decorButton', 'basic-button', `decorButton-size-${size}`, `decorButton-appearance-${appearance}`, className, { disabled: disabled })

    var buttonProps = {
      className: classes,
      disabled,
      ...rest
    }

    var ct

    if (React.isValidElement(containerElement)) {
      ct = React.cloneElement(containerElement, buttonProps, buttonChildren)
    } else if (link) {
      logError("'link' property of decor/Button is deprecated in favor of containerElement")
      ct = React.cloneElement(link, buttonProps, buttonChildren)
    } else {
      ct = React.createElement(containerElement, buttonProps, buttonChildren)
    }

    return (
      ct
    )
  }
}

export default Button
