/* eslint "react/display-name": "warn" */
import React from 'react'
import FullScreenLayout from './FullScreenLayout'
import { Box } from '@realsoftworks/decor'

export default ({ title, content, ...props }) => (
  <FullScreenLayout
    display='flex'
    flexDirection='column'
    {...props}
  >
    <Box>
      {title}
    </Box>
    <Box flex='1' css='overflow-y: auto'>
      {content}
    </Box>
  </FullScreenLayout>
)
