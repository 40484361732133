/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import PermissionComponent from 'users/components/PermissionComponent'
import { fetchListing, toggleExcluded, createLead } from '../actions'
import { getMetadataForSource } from 'mls/selectors'

import BigLoader from 'common/BigLoader'
import { FontIcon, Button } from '../../../views/common'
import Card from 'common/Card'
import { Crumb } from '../../../views/common/Breadcrumbs'

import { connect } from 'react-redux'

import DealAlertListingDetail from './listings/DealAlertListingDetail'

class DealAlertListingDetailHandler extends Component {
  componentWillMount () {
    var { match, dispatch } = this.props
    dispatch(fetchListing(match.params.id, match.params.listingId))
  }

  getTools () {
    var { deal, excluding, favoriting } = this.props
    if (!deal) return false

    var { excluded } = deal

    var ret = []

    if (deal.lead_id)
      ret.push(
        <Link
          to={`/search/history/${deal.lead_id}`}
          className='btn btn-link lead-link'
        >
          <FontIcon className='icon-lead-link fa fa-external-link'/> View Lead
        </Link>
      )
    else
      ret.push(
        <PermissionComponent action='create' model='lead'>
          <Button disabled={favoriting} onClick={this.addAsLead} className='btn'>
            <FontIcon className='icon-add fa fa-plus'/> Add as Lead
          </Button>
        </PermissionComponent>
      )

    ret.push(
      <Button disabled={excluding} onClick={this.toggle} className='btn'>
        {
          !excluded ? <FontIcon className='icon-delete fa fa-times'/> : <FontIcon className='icon-undo fa fa-undo'/>
        }
        {
          !excluded ? ' Hide Listing' : ' Undo Hide'
        }
      </Button>
    )

    return ret
  }

  addAsLead = () => {
    var { match, dispatch } = this.props
    dispatch(createLead(match.params.id, this.props.deal))
  };

  toggle = () => {
    var excluded = this.props.deal.excluded
    var { match, dispatch } = this.props
    dispatch(toggleExcluded(match.params.id, match.params.listingId, !excluded))
  };

  render () {
    const { deal, loading, metadata } = this.props

    if (!deal)
      return <BigLoader/>

    return (
      <Card className='deal-alert-detail-ct'>

        <Crumb
          title={`MLS# ${deal.mls}`}
          tools={this.getTools()}
        />

        <div className='detail-header'>

          <div className='detail-title'>
            {deal.address.line1}
          </div>
        </div>

        {(loading || !deal.listings) ? <BigLoader/> : <DealAlertListingDetail deal={deal} metadata={metadata}/>}

      </Card>
    )
  }
}

DealAlertListingDetailHandler = connect((state, props) => {
  const { listingId } = props.match.params

  var deal = state.entities.dealalertlistings[listingId]
  var detail = state.dealalerts.listings.detail[listingId] || { loading: true }
  var loading = !deal || detail.loading
  var metadata = deal && getMetadataForSource(state, deal.source)

  return {
    deal, loading, excluding: detail.excluding, favoriting: detail.favoriting, metadata
  }
})(DealAlertListingDetailHandler)

export default DealAlertListingDetailHandler
