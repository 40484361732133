/* eslint "react/no-unescaped-entities": "warn" */
import React from 'react'

const Big404 = () => (
  <div className='big-fatal-error'>
    <span className='big-fatal-icon fa-stack fa-5x'>
      <i className='fa fa-cloud fa-stack-2x' />
      <i className='fa fa-question fa-inverse fa-stack-1x' />
    </span>
    <h2>Nothing to see here</h2>
    <p>We weren't able to look up whatever it is you were looking for. If you think this is a mistake, let us know!</p>
  </div>
)

export default Big404
