import cloneDeep from 'lodash/lang/cloneDeep'
import get from 'lodash/object/get'

export const getAllTags = state => getTagsByReference(state, 'all', true)

export const getTag = (state, id) =>
  get(state, `contacts.tags.tags.${id}`, {})

export const getTagsWithContacts = getTag

export const getTagsByReference = (state, contactId) => {
  const tags = get(state, `contacts.contactTags.${contactId}`, [])
  if (!tags.length) return null

  return tags
    .map(tagId => getTag(state, tagId))
    .sort(sortFunction)
}

function sortFunction (a, b) {
  var nameA = a.name ? a.name.toUpperCase() : '' // ignore upper and lowercase
  var nameB = b.name ? b.name.toUpperCase() : '' // ignore upper and lowercase
  if (nameA < nameB)
    return -1

  if (nameA > nameB)
    return 1

  return 0
};

export const getCount = state => {
  if (state.contacts && state.contacts.contacts && state.contacts.contacts.count)
    return state.contacts.contacts.count

  return null
}

export const getContactsObj = state =>
  (state &&
  state.contacts &&
  state.contacts.contacts &&
  state.contacts.contacts.contacts) ||
  {}
export const getContact = (state, id) => {
  let contact = {}
  if (state.contacts && state.contacts.contacts && state.contacts.contacts.contacts)
    contact = cloneDeep(state.contacts.contacts.contacts[id])

  return contact
}

export const getContactsWithTags = (state, id) => {
  let contact = {}
  if (state.contacts && state.contacts.contacts && state.contacts.contacts.contacts) {
    contact = cloneDeep(state.contacts.contacts.contacts[id])
    const tags = contact.values.contacttag || []
    contact.values.contacttag = tags.map(tagId => getTag(state, tagId))
  }
  return contact
}

export const getContactsWithTagsByReference = (state, type) => {
  if (state.contacts && state.contacts.ordering && state.contacts.ordering[type] && state.contacts.ordering[type].length > 0) {
    const contactsIdArray = state.contacts.ordering[type]
    const contacts = contactsIdArray.map(contactId => getContactsWithTags(state, contactId))
    return contacts
  } else { return [] }
}

export const getContactsByReference = (state, type) => {
  if (state.contacts && state.contacts.ordering && state.contacts.ordering[type] && state.contacts.ordering[type].length > 0) {
    const contactsIdArray = state.contacts.ordering[type]
    const contacts = contactsIdArray.map(contactId => getContact(state, contactId))
    return contacts
  } else { return [] }
}

export const getIsLoaded = state => {
  if (state.contacts && state.contacts.ui)
    return state.contacts.ui.isLoaded ? state.contacts.ui.isLoaded : false

  return false
}

export const getIsDetailsLoaded = state => {
  if (state.contacts && state.contacts.ui)
    return state.contacts.ui.isDetailsLoaded ? state.contacts.ui.isDetailsLoaded : false

  return false
}
