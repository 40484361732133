import React, { useRef } from 'react'
import { Card, Box, Text } from '@realsoftworks/decor'
import { formatDate } from 'common/util/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import addressObjectToString from 'common/util/addressObjectToString'
import { useSearchScreenProps } from 'search/SearchContext'

const BATCH_SKIPTRACE_IMG = 'https://propelio-cloudfront.s3-us-west-2.amazonaws.com/bst-logo.png'
const SKIP_GENIE_IMG = 'https://propelio-cloudfront.s3-us-west-2.amazonaws.com/skip-genie.png'
const providerNames = {
  reiGroup: 'Batch Skiptrace',
  skipGenie: 'Skip Genie'
}

const SkiptraceEntry = ({ result, date, provider, index, total, ...props }) => {
  const hoverRef = useRef()
  const { specificSearch } = useSearchScreenProps() as any
  const providerImage = provider === 'skipGenie'
    ? SKIP_GENIE_IMG
    : provider === 'reiGroup'
      ? BATCH_SKIPTRACE_IMG
      : null

  return (
    <Card
      ref={hoverRef}
      shadow={1}
      borderRadius={4}
      position='relative'
      boxShadow={1}
      {...props}
    >
      {/* Header Bar */}
      <Box bg='teal.200' px={3} pt={1} pb='6px' display='flex' alignItems='center'>
        <Box flex='1 1 auto'>
          Result {index + 1} of {total}
          <Text fontSize={4} px={3}>·</Text>
          <span style={{ textTransform: 'capitalize' }}>
            {String(result.subjectName).trim() || 'N/A'}
          </span>
          <Text fontSize={4} px={3}>·</Text>
          DOB: {result.DOB ? result.DOB : 'N/A'}
        </Box>
        <Box flex='0 1 auto'>
          {providerNames[provider] || ''} Result
          <Text fontSize={4} px={3}>-</Text>
          {formatDate(date)}
        </Box>
      </Box>

      <Box css={{ position: 'relative' }} pb={3}>
        {/* Provider Logo */}
        {providerImage &&
          <Box css={{ position: 'absolute', bottom: 24, right: 24 }}>
            <Box position='relative'>
              <Box
                as='img'
                src={providerImage}
                width={100}
              />

              <Box bg='rgba(255, 255, 255, 0.75)' css={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} />
            </Box>
          </Box>}

        <Box display='flex' flexWrap='wrap' pt={2} css={{ position: 'relative', zIndex: 1 }}>
          {/* Address Column */}
          <Box p={3} flex='1 1 50%'>
            <Text fontWeight={700}>Address History</Text>
            {result.addressSearch
              .filter(a =>
                a &&
                (a.address || a.line1) &&
                a.city &&
                a.state &&
                a.zip)
              .map((_address, idx) =>
                <Box py={2} display='flex' key={`${index}-address-${idx}`}>
                  <Text
                    as={FontAwesomeIcon}
                    icon={faPhoneSquareAlt}
                    color={_address.isSkipped ? 'teal.500' : 'red.500'}
                    fontSize={4}
                  />

                  <Box pl={3} flexGrow={1} border='1px' borderColor='teal.400'>
                    <Text
                      as='a'
                      href='#'
                      color='primary.900'
                      css='text-transform: capitalize;'
                      onClick={e => {
                        e.preventDefault()
                        specificSearch({
                          address: {
                            line1: _address.address,
                            city: _address.city,
                            state: _address.state,
                            zip: String(_address.zip).substr(0, 5)
                          }
                        })
                      }}
                    >
                      {addressObjectToString(_address)}
                    </Text>
                    {_address.date ? (
                      <Text display='block'>
                        {formatDate(_address.date.from)}
                        {' to '}
                        {formatDate(_address.date.to)}
                      </Text>
                    ) : null}
                  </Box>
                </Box>)}
          </Box>

          {/* Phone Column */}
          <Box p={3} flex='1 1 50%'>
            <Text fontWeight={700}>Possible Phone Number</Text>
            {result.phones?.map((phone, idx) =>
              <Box py={2} display='flex' key={`${index}-phones-${idx}`}>
                <Box pl={3} flexGrow={1} border='1px' borderColor='teal.400'>
                  <Text display='block'>
                    <a href={`tel:${phone.number}`}>{phone.number}</a> {phone.type}
                  </Text>
                </Box>
              </Box>)}
          </Box>
        </Box>

        <Box display='flex' css={{ position: 'relative', zIndex: 1 }}>
          <Box display='flex' flex='1 1 auto'>
            {/* Email Column */}
            <Box p={3} flex='1 1 33%'>
              <Text fontWeight={700} fontize={0}>Possible Email Addresses</Text>
              {result.emails?.map((email, idx) => (
                <Box py={1} Text display='block' fontize={0} key={`${index}-email-${idx}`}>
                  {email}
                </Box>)
              )}
            </Box>

            {/* Relatives Column */}
            <Box p={3} flex='1 1 33%'>
              <Text fontWeight={700} fontize={0}>Possible Relatives</Text>
              {result.possibleRelatives?.map((relative, idx) => (
                <Box py={1} display='flex' key={`${index}-relatives-${idx}`}>
                  <Box pl={2} flexGrow={1}>
                    <Text fontize={0} as='a'>{relative.name}</Text> {relative.age}
                  </Box>
                </Box>)
              )}
            </Box>

            {/* Associates Column */}
            <Box p={3} flex='1 1 33%'>
              <Text fontWeight={700} fontize={0}>Possible Associates</Text>
              {result.possibleAssociates?.map((associate, idx) =>
                <Box py={1} display='flex' key={`${index}-assoc-${idx}`}>

                  <Box pl={2} flexGrow={1} fontize={0}>
                    <Text display='block' as='a'>{associate.name}</Text>
                  </Box>
                </Box>)}
            </Box>
          </Box>

          {/* Indicators Column */}
          <Box flex='0 0 240px'>
            <Box p={3} flex='1 1 33%'>
              <Text fontWeight={700} fontize={0}>Indicators</Text>
              <Text py={1} display='block' fontize={0}>
                Bankruptcies: {result.bankruptcies ?? 'Unknown'}
              </Text>
              <Text py={1} display='block' fontize={0}>
                Liens: {result.liens}
              </Text>
              <Text py={1} display='block' fontize={0}>
                Judgements: {result.judgements}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default SkiptraceEntry
