/* eslint "react/display-name": "warn", "react/jsx-key": "warn" */
import React, { useRef, useMemo } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TextTableCell,
  Text
} from '@realsoftworks/decor'
import { DateTime, Monetary } from 'common/format'
import { useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'
import { useLogError } from 'common/util/hooks/useLogError'

const CashSalesTable = React.memo(({
  data: cashSalesRaw,
  ...props
}) => {
  const cashSales = cashSalesRaw
    // Remove deals with null property
    .map(c =>
      ({
        ...c,
        deals: (Array.isArray(c.deals) ? c.deals : [])
          .filter(d => d.property)
      }))
    // Remove cash sales with NO deals (after removing null-property deals)
    .filter(c => c.deals.length)

  return (
    <Box width='100%' {...props}>
      <Box mb={4} width='100%'>
        <Text>
          {cashSales.length}
        </Text>
        <Text color='neutral.500'>
          {' '}results
        </Text>
      </Box>
      <CashSalesTableData data={cashSales} />
    </Box>
  )
})

const CashSaleTableRow = ({ scrollParentRef, ...buyer }) => {
  const { deals } = buyer
  const [first] = deals

  if (!first || !first.property) return null

  let extra = ''
  if (deals.length > 1)
    extra = ` +${deals.length - 1} more`

  useLogError({
    error: 'buyer.id is unexpectedly falsy in CashSaleTableRow',
    shouldLog: !buyer.id
  }, [!!buyer.id])

  const { isSyncHovered, hoverRef } = useSyncedIsHoveredWithAutoScroll({
    scrollParentRef,
    memberId: buyer.id,
    groupId: 'neighbor'
  })
  const cellBg = isSyncHovered ? 'blue.200' : ''

  const addressObj = useMemo(() =>
    ({
      line1: first.property.propertyAddressLine1,
      city: first.property.propertyAddressCity,
      state: first.property.propertyAddressState,
      zip: first.property.propertyAddressZip
    }),
  [
    first.property.propertyAddressLine1,
    first.property.propertyAddressCity,
    first.property.propertyAddressState,
    first.property.propertyAddressZip
  ])

  return (
    <Box ref={hoverRef}>
      <TableRow>
        <TextTableCell flex='15' bg={cellBg}>
          <AddressPreviewLink address={addressObj} />
          {extra}
        </TextTableCell>
        <TextTableCell flex='12' bg={cellBg}>
          {buyer.name}
        </TextTableCell>
        <TextTableCell flex='15' bg={cellBg}>
          {`${buyer.addressLine1} ${buyer.addressCity}, ${buyer.addressState} ${buyer.addressZip}`}
        </TextTableCell>
        <TextTableCell flex='12' bg={cellBg}>
          {buyer.latestDealSpend ? <Monetary>{buyer.latestDealSpend}</Monetary> : '--'}
          {buyer.latestDealDate ? <Text display='block'><DateTime format='MM/DD/YYYY'>{buyer.latestDealDate}</DateTime></Text> : ''}
        </TextTableCell>
      </TableRow>
    </Box>
  )
}

const CashSalesTableData = React.memo(({ data }) => {
  const scrollParentRef = useRef()

  return (
    <Table
      ref={scrollParentRef}
      width='100%'
      maxHeight='65vh'
      style={{ overflow: 'auto' }}
      css='font-size: 14px; position: relative;'
    >
      <TableHead width='720px'>
        <TableHeaderCell flex='15'>Address</TableHeaderCell>
        <TableHeaderCell flex='12'>Buyer</TableHeaderCell>
        <TableHeaderCell flex='15'>Buyer Address</TableHeaderCell>
        <TableHeaderCell flex='12'>Last Deal</TableHeaderCell>
      </TableHead>
      <TableBody width='720px'>
        {data.map(d => (
          <CashSaleTableRow scrollParentRef={scrollParentRef} {...d} />
        ))}
      </TableBody>
    </Table>
  )
})

export default CashSalesTable
