/* eslint "react/no-deprecated": "warn", "react/no-string-refs": "warn", "no-prototype-builtins": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import TouchRipple from './TouchRipple'

import './basicswitch.less'

class BasicSwitch extends React.Component {
  static propTypes = {
    centerRipple: PropTypes.bool, // force checkbox value
    checked: PropTypes.bool, // force checkbox value
    className: PropTypes.string,
    defaultSwitched: PropTypes.bool,
    name: PropTypes.string,
    onParentShouldUpdate: PropTypes.func.isRequired,
    onSwitch: PropTypes.func,

    tooltip: PropTypes.node,
    label: PropTypes.node,

    switchElement: PropTypes.element.isRequired,
    switchWrapClassName: PropTypes.string,
    switched: PropTypes.bool.isRequired,
    value: PropTypes.string
  };

  static defaultProps = {
    className: '',
    switchWrapClassName: ''
  };

  componentDidMount () {
    var inputChecked = this.isSwitched()
    if (inputChecked !== this.props.switched)
      this.props.onParentShouldUpdate(inputChecked)
  }

  componentWillReceiveProps (nextProps) {

  }

  isSwitched = () => this.refs.checkbox.checked;

  _handleChange = e => {
    var isSwitched = this.isSwitched()

    if (!this.props.hasOwnProperty('checked'))
      this.props.onParentShouldUpdate(isSwitched)

    this.props.onSwitch && this.props.onSwitch(e, isSwitched)
  };

  /**
   * Because both the internal and the checkbox input cannot share pointer
   * events, the checkbox input takes control of pointer events and calls
   * ripple animations manually.
   */
  _handleMouseDown = e => {
    // only listen to left clicks
    if (e.button === 0)
      this.refs.touchRipple.start(e)
  };

  _handleMouseUp = () => {
    this.refs.touchRipple.end()
  };

  _handleMouseLeave = () => {
    this.refs.touchRipple.end()
  };

  _handleTouchStart = e => {
    this.refs.touchRipple.start(e)
  };

  _handleTouchEnd = () => {
    this.refs.touchRipple.end()
  };

  render () {
    const {
      className,
      value,
      name,
      defaultSwitched,
      switchElement,
      switchWrapClassName,
      checked,
      label,
      centerRipple,
      switched,
      onParentShouldUpdate,
      onSwitch,
      ...other
    } = this.props

    const inputProps = {
      ref: 'checkbox',
      type: 'checkbox',
      className: 'hidden-input',
      checked,
      name,
      value,
      defaultChecked: defaultSwitched,
      onChange: this._handleChange,

      onMouseDown: this._handleMouseDown,
      onMouseUp: this._handleMouseUp,
      onMouseLeave: this._handleMouseLeave,
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd
    }

    const labelEl = label ? <label>{label}</label> : ''
    const switchWrap = (
      <div className={'basic-switch-wrap ' + switchWrapClassName}>
        {switchElement}
        <TouchRipple key='touchripples' ref='touchRipple' centerRipple={this.props.centerRipple}/>
      </div>
    )
    return (
      <div className={className + ' basic-switch'}>
        <input {...other} {...inputProps}/>
        {switchWrap}
        {labelEl}
      </div>
    )
  }
}

export default BasicSwitch
