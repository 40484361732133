import * as ActionTypes from '../actions'

const initialState = {}
const defaultValue = { loading: false, saving: false, destroying: false, error: null }

export default function (state = initialState, { type, payload, meta = {} }) {
  switch (type) {
    case ActionTypes.DEALALERT_FETCH:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: true, error: null })
      }

    case ActionTypes.DEALALERT_FETCH_SUCCESS:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: false, error: null })
      }

    case ActionTypes.DEALALERT_FETCH_FAILURE:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: false, error: payload })
      }

    case ActionTypes.DEALALERT_UPDATE:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: false, saving: true })
      }

    case ActionTypes.DEALALERT_UPDATE_SUCCESS:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: false, saving: false })
      }

    case ActionTypes.DEALALERT_UPDATE_FAILURE:
      return {
        ...state,
        [meta.id]: update(state[meta.id], { loading: false, saving: false, error: payload })
      }

    case ActionTypes.DEALALERT_DESTROY: {
      return {
        ...state,
        [meta.id]: update(state[meta.id], { destroying: true })
      }
    }

    case ActionTypes.DEALALERT_DESTROY_FAILURE:
    case ActionTypes.DEALALERT_DESTROY_SUCCESS: {
      return {
        ...state,
        [meta.id]: update(state[meta.id], { destroying: false })
      }
    }

    default:
      return state
  }
}

const update = (state = defaultValue, obj) => ({
  ...state,
  ...obj
})
