import React, { Component } from 'react'

import BigChart from './BigChart'

import DataContainer from './DataContainer'
import BigFatalError from 'common/BigFatalError'

import { fetchCrossConversions } from '../actions'
import { getCrossConversions } from '../selectors'

class CrossSiteConversionsChart extends Component {
  render () {
    return (
      <DataContainer
        load={fetchCrossConversions}
        selector={getCrossConversions}
        render={({ error, props }) => {
          if (error) return <BigFatalError />
          return <BigChart data={props.data} yKey='count' />
        }}
      />
    )
  }
}

CrossSiteConversionsChart.propTypes = {}
CrossSiteConversionsChart.defaultProps = {}

export default CrossSiteConversionsChart
