/* eslint "no-class-assign": "warn", "eqeqeq": "warn" */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { branch, renderComponent } from 'recompose'
import { destroy } from '../../actions'
import { FontIcon } from '../../../../views/common'
import Card from 'common/Card'
import ProTable from 'decor/ProTable'
import DeleteAlertDialog from '../DeleteAlertDialog'

const EditButtonDisabled = styled.div`
  cursor: not-allowed;
  opacity: .5;
`

class DealAlertList extends Component {
  state = { destroying: false, deleteId: false };

  openDialog = id => this.setState({ deleteId: id });
  closeDialog = () => this.setState({ deleteId: false });

  onDelete = () => {
    this.setState({ destroying: true })
    this.props.dispatch(destroy(this.state.deleteId))
      .then(() => {
        this.setState({ destroying: false, deleteId: false })
      })
  };

  isDealAlertsSupported = (sourceId) => this.props.markets[sourceId]?.dealAlerts

  renderEditButton = (source) => {
    if (this.isDealAlertsSupported(source.source)) {
      return <Link to={`/dealalerts/${source.id}/edit`} className='btn btn-flat'><FontIcon className='fa fa-gear'/> Edit</Link>
    } else {
      return <EditButtonDisabled className='btn btn-flat'><FontIcon className='fa fa-gear'/> Edit</EditButtonDisabled>
    }
  }

  render () {
    const { items } = this.props

    const columns = [
      {
        id: 'edit',
        render: (value, row) => this.renderEditButton(row),
        width: 80,
        header: ''
      },

      {
        id: 'delete',
        render: (value, row) => <button onClick={this.openDialog.bind(this, row.id)} className='btn btn-flat'><FontIcon className='fa fa-trash-o'/> Delete</button>,
        width: 80,
        header: ''
      },

      {
        id: 'name',
        render: (value, row) => <Link to={`/dealalerts/${row.id}`}> {row.name || '(no name)'}</Link>
      },

      {
        id: 'count',
        header: 'Matches',
        width: 100
      },

      {
        id: 'source',
        render: v => this.props.markets[v].description,
        header: 'Market'
      }
    ]

    const { destroying } = this.state

    return (
      <Card>
        <ProTable
          className='dealalert-list'
          columns={columns}
          data={items}
        />
        <DeleteAlertDialog
          open={!!this.state.deleteId}
          onDelete={this.onDelete}
          processing={destroying}
          onClose={this.closeDialog}
        />
      </Card>
    )
  }
}

function GettingStarted () {
  return (
    <Card className='getting-started'>
      <h1>One step away from finding deals...</h1>
      <p>To get started with MLS Deal Alerts, configure your settings and tell us what kind of properties you are interested in.</p>
      <Link to='/dealalerts/add' className='settings-btn'><FontIcon className='fa fa-gear'/> Create Alert</Link>
    </Card>
  )
}

// show GettingStarted is items is empty
DealAlertList = branch(
  props => props.items.length == 0,
  renderComponent(GettingStarted),
  c => c
)(DealAlertList)

export default DealAlertList
