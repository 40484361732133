/* eslint "eqeqeq": "warn", "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React from 'react'
import { search, createPackage, searchTypes } from '../actions'
import LeadList from './LeadList'
import DownloadPage from './DownloadPage'

import { getPlanStartDate } from 'billing/selectors'

import moment from 'moment'

import Card from 'common/Card'
import { LegacyPageLayout, DefaultLayout } from 'common/layouts'
import DateRange from './DateRange'
import BigLoader from 'common/BigLoader'
import LoadingMask from 'common/LoadingMask'
import { Title } from 'common/Page'
import { connect } from 'react-redux'
import * as leadListSelectors from '../selectors'
import MarketSelector from './MarketSelector'

import typeLabels from '../types'

import unique from 'lodash/array/unique'
import without from 'lodash/array/without'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { Button, Box, Text, Menu, Position, Checkbox } from '@realsoftworks/decor'

import './leadlistpage.less'

import groupBy from 'lodash/collection/groupBy'

class LeadListPage extends React.Component {
  static propTypes = {

  };

  state = {
    isLoading: false,
    buildingPackage: false,

    market: false,
    filters: {
      minDate: moment().subtract(2, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z',
      maxDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z',
      offset: 0,
      limit: 50
    }
  };

  onStopDownload = () => {
    this.setState({ isDownloading: false })
  };

  createPackage = () => {
    const filters = { ...this.state.filters }
    delete filters.offset
    delete filters.limit

    this.setState({ buildingPackage: true }, () => {
      this.props.createPackage(filters).then(result => {
        this.setState({ screen: 'download', buildingPackage: false, packageId: result.id })
      })
    })
  };

  onChange = (field, value) => {
    var filters = {
      ...this.state.filters,
      [field]: value
    }

    this.setState({
      ...this.state,
      filters
    })
    this.search(filters)
  };

  onChangeMarket = market => {
    var filters = {
      ...this.state.filters,
      county: market.counties.map(v => v.id)
    }
    this.setState({ market, filters, screen: null })
    this.search(filters)

    this.setState({ loadingTypes: true, types: null })
    this.props.searchTypes(market.counties).then(payload => {
      this.setState({ loadingTypes: false })

      var types = Object.keys(payload).reduce((ret, v) => ret.concat(payload[v]), [])
      types = unique(types)
      this.setState({ loadingTypes: false, types })
    })
  };

  onChangeTypes = value => {
    this.onChange('types', value)
  };

  search = filters => {
    this.setState({ isLoading: true })
    this.props.search(filters).then(() => {
      this.setState({ isLoading: false })
    })
  };

  onPageClick = offset => {
    var filters = {
      ...this.state.filters,
      offset
    }
    this.setState({ filters })
    this.search(filters)
  };

  render () {
    const { market, filters, isLoading, buildingPackage, screen } = this.state
    const { leads, count, startDate, error, markets } = this.props

    const minDate = moment(startDate).subtract(3, 'days').toDate()

    const hasLoaded = leads && leads.length > 0
    const bigLoading = (isLoading && !hasLoaded) || buildingPackage

    return (
      <LegacyPageLayout className='lead-list-page'
        title={
          <Title>
            <Box width='100%' display='flex' justifyContent='flex-end'>
              <Box display='flex' flex='1'>
                {market &&
                  <DateRange
                    onChange={this.onChange}
                    minDate={this.state.filters.minDate}
                    maxDate={this.state.filters.maxDate}
                    startDate={minDate}
                  />
                }
                {market &&
                  <LeadTypePicker
                    loading={this.state.loadingTypes}
                    types={this.state.types}
                    onChange={this.onChangeTypes}
                    value={filters.types}
                  />
                }
              </Box>
              <Box>
                {/* <button type="button" className="advanced-filters btn btn-default bulk-update-btn" onClick={this.openFilters}>ADVANCED FILTERS</button> */}
                <MarketSelector market={this.state.market} onChange={this.onChangeMarket}/>
              </Box>
            </Box>
          </Title>
        }

        content={
          <DefaultLayout>
            {
              market
                ? <BigLoader loading={bigLoading}>
                  <div style={{ position: 'relative' }}>
                    {screen == 'download'
                      ? <DownloadPage
                        maxDate={filters.maxDate}
                        minDate={filters.minDate}
                        count={count}
                        market={market}
                        onReturn={() => this.setState({ screen: null })}
                        packageId={this.state.packageId}
                      />
                      : error
                        ? <DateError/>
                        : <div>
                          {hasLoaded && (
                            <Box display='flex' justifyContent='center' my={5}>
                              <Button variant='primary' size='large' sizeVariant='large' disabled={isLoading} onClick={this.createPackage}>
                                Download All Leads ({this.props.count})
                              </Button>
                            </Box>
                          )}

                          <LeadList
                            params={filters}
                            onPageClick={this.onPageClick}
                            onToggleRow={this.onToggleRow}
                            leads={leads}
                            count={count}
                          />
                        </div>

                    }
                    {isLoading && <LoadingMask/>}
                  </div>
                </BigLoader>
                : <Card mx='auto' className='select-page-card'>
                  <SelectPage markets={markets}/>
                </Card>
            }
          </DefaultLayout>
        }
      />
    )
  }
}

const DateError = () => (
  <div className='big-fatal-error'>
    <span className='big-fatal-icon fa-5x'>
      <i className='fa fa-exclamation-triangle'/>
    </span>
    <h2>Invalid Date Range</h2>
    <p>Please check your input and try again. Note - you can only search back up to 3 days before the start of your Pro plan.</p>
  </div>
)
class SelectPage extends React.Component {
  render () {
    const markets = groupBy(this.props.markets, v => v.state[0])

    return (
      <div className='leadlist-select-page'>
        <span className='leadlist-select-icon fa-stack fa-2x'>
          <i className='fa fa-certificate fa-stack-2x'/>
          <i className='fa fa-check fa-inverse fa-stack-1x'/>
        </span>
        <h2 className='title'>Better than your average list!</h2>
        <p>The lead lists you are about to download are as fresh as they get - we analyze the documents from county record usually less than 24 hours after they are posted.</p>
        <p>Your access to these hot leads is based upon your plan start date: you can search back up to 3 days before your start date. So if you signup Monday morning, your access starts on the Friday before</p>

        <h3>Coverage</h3>
        <p>Our lists are available in the following areas and counties:</p>
        {
          Object.keys(markets).sort().map(state => (
            <div key={state}>
              <ul>
                {markets[state].map(market => <li key={market.name}>{market.name}, {market.state[0]} - {market.counties.map(v => v.name).sort().join(', ')}</li>)}
              </ul>
            </div>
          ))
        }

        <h2 className='select-text'>Select your market to get started!</h2>

      </div>
    )
  }
}

class LeadTypePicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value,
      open: false
    }
  }

  open = e => {
    this.setState({ open: true, anchorEl: e.currentTarget })
  };

  close = () => {
    this.setState({ open: false })
    if (this.state.value !== this.props.value)
      this.props.onChange(this.state.value)
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value)
      this.setState({ value: nextProps.value })
  }

  onCheckChange = name => (e, checked) => {
    let value = [].concat(this.state.value || this.props.types)
    if (checked) {
      value.push(name)
      value = unique(value)
    } else { value = without(value, name) }

    this.setState({ value })
  };

  getText () {
    const { value } = this.state
    if (!value) return 'All Types'

    if (value.length == 1)
      return typeLabels[value[0]]

    return `${value.length} Types`
  }

  buildMenu () {
    let { value } = this.state
    const { types } = this.props

    if (!value)
      value = [].concat(types)

    const items = types.map(t => (
      <Checkbox
        key={t}
        name={t}
        checked={value.includes(t)}
        label={typeLabels[t]}
        onChange={this.onCheckChange(t)}
      />
    ))

    return <Box px={3} py={1}>{items}</Box>
  }

  render () {
    const text = this.getText()
    const { types } = this.props

    return (
      <Box display='flex' alignItems='center'>
        <Button onClick={this.open}>
          {text} <Text fontSize={3}><FontAwesomeIcon icon={faSortDown} /></Text>
        </Button>
        {types &&
          <Menu position={Position.BOTTOM_LEFT} onClose={this.close} open={this.state.open} target={this.state.anchorEl}>
            {this.buildMenu()}
          </Menu>
        }
      </Box>
    )
  }
}

const mapStateToProps = (state, props) => {
  const leads = leadListSelectors.getLeadList(state)
  const count = leadListSelectors.getLeadListCount(state)
  const error = leadListSelectors.getLeadListError(state)
  const markets = leadListSelectors.getMarkets(state)

  return {
    startDate: getPlanStartDate(state),
    leads,
    markets,
    count,
    error,
    counties: leadListSelectors.getCounties(state)
  }
}
const mapDispatchToProps = {
  search,
  createPackage,
  searchTypes
}
LeadListPage = connect(mapStateToProps, mapDispatchToProps)(LeadListPage)
export default LeadListPage
