/* eslint "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import { isPaid } from '../selectors'
import DocumentTitle from 'react-document-title'

import CMAUpgradeCTA from 'common/CMAUpgradeCTA'

import { Card } from 'common/Card.js'

class UpgradeHandler extends React.Component {
  render () {
    return (
      <div className='upgrade-handler'>
        <DocumentTitle title='Upgrade | Propelio' />
        {
          !this.props.isPaid
            ? <CMAUpgradeCTA />
            : <Thanks />
        }

      </div>
    )
  }
}
class Thanks extends React.Component {
  render () {
    return (
      <Card className='thanks-card'>

        <div className='row'>
          <div className='col-md-5'>
            <h1>Congrats!</h1>
            <p>Your account has been upgraded and the full power of Propelio is at your finger tips.</p>
            <p>Let us know if there's anything at all we can do for you.</p>
          </div>
          <div className='col-md-7'>
            <div className='teaser' />
          </div>
        </div>

        <div className='buttons'>
          <a href='/search' className='btn btn-create'>Create Lead</a>
        </div>

      </Card>
    )
  }
}

UpgradeHandler = connect(state => ({
  isPaid: isPaid(state)
}))(UpgradeHandler)

export default UpgradeHandler
