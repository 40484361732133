import get from 'lodash/object/get'

export const getCurrent = state => state.users.current

export const getCurrentUserId = state =>
  get(state, 'users.current.id')

export const getIsBeta = state => getCurrent(state).beta

export const checkModelPermission = (state, model, action) => {
  const p = state.users.permissions
  if (!p[model]) return false
  if (Array.isArray(action)) return action.some(a => p[model].includes(a))
  return p[model].includes(action)
}

export const getTeam = state =>
  state.users.team.value

export const getTeamInfo = state =>
  state.users.team

export const getSetting = (state, key) => get(state.users.settings.value, key)

export const getSettingInfo = state => state.users.settings
