/* eslint "eqeqeq": "warn", "react/no-string-refs": "warn", "react/jsx-key": "warn", "no-useless-call": "warn" */
import React from 'react'
import createReactClass from 'create-react-class'
import Import from '../../../views/import'
import Model from '../../../core/model.js'
import EntityFieldTypes from '../../../core/EntityFieldTypes.js'
import EntityFieldProperties from '../../../views/entityedit/EntityFieldProperties.react'
var FileUploadPanel = Import.UploadPanel; var FileSummaryPanel = Import.SummaryPanel

class FieldSelector extends React.Component {
  static defaultProps = {
    value: '',
    newfield: {},
    meta: [] // contact field template
  };

  render () {
    this.fieldTypes = this.fieldTypes || EntityFieldTypes.getFieldTypes()
    var fieldTypes = this.fieldTypes

    var newfield = this.props.newfield ? this.props.newfield : { type: '', label: '' }

    var errors = this.props.errors || []

    return (
      <div className='field-selector'>
        <div><strong>Contact Field</strong></div>
        <select className='form-control' value={this.props.value || ''} onChange={this.props.onChange}>
          <option value=''>Ignore</option>
          {this.getOptions()}
          <option disabled={true}>──────────</option>
          <option value='new'>Create New Field</option>
        </select>
        {this.props.value == 'new'

          ? <div className='new-field-options'>
            <div className={'form-group' + (errors.includes('type') ? ' has-error has-feedback' : '')}>
              <select ref='fieldtype' className='form-control' value={newfield.type} onChange={this.onNewFieldChange.bind(this, 'type')}>
                <option value=''>-- Select New Field Type --</option>
                {fieldTypes.map(function (f) {
                  return <option value={f.name}>{f.label}</option>
                })}
              </select>
            </div>
            <div className={'form-group' + (errors.includes('label') ? ' has-error has-feedback' : '')}>
              <label>Label</label>
              <input type='text' className='form-control' value={newfield.label} onChange={this.onNewFieldChange.bind(this, 'label')}/>
            </div>
            <div className='form-group'>
              {this.getFieldConfigOptions()}
            </div>
          </div>

          : ''
        }
      </div>
    )
  }

  getOptions = () => {
    var excluded = ['contacttag']

    return this.props.meta.map(function (o) {
      if (excluded.indexOf(o.name) != -1)
        return ''

      if (o.type == 'address') {
        var parts = ['line1', 'city', 'state', 'zip']
        var labels = ['Street', 'City', 'State', 'Zipcode']

        return (
          <optgroup label={o.label}>
            {parts.map(function (p, i) {
              return <option value={o.name + '.' + p}>{labels[i]}</option>
            })}
          </optgroup>
        )
      }

      return <option value={o.name}>{o.label}</option>
    }, this)
  };

  getFieldConfigOptions = () => {
    var field = this.props.newfield || {}
    var t = field.type
    var fts = EntityFieldTypes.getFieldsByType()

    if (t && fts[t].properties)
      return (
        <div className='form-group'>
          <EntityFieldProperties properties={fts[t].properties} field={field} onChange={this.onNewFieldChange}/>
        </div>
      )
    else
      return ''
  };

  onNewFieldChange = (prop, e) => {
    var v = (e.target ? e.target.value : e)
    var newfield = Object.assign({}, this.props.newfield || {})
    newfield[prop] = v

    this.props.onNewFieldChange(newfield)
  };
}

class MatchingPanel extends React.Component {
  static defaultProps = {
    headers: [],
    rows: [],
    meta: [],
    mappings: {},
    newfields: {}
  };

  onMappingChange = (header, e) => {
    var v = e.target.value
    var m = this.props.mappings
    if (v)
      m[header] = v
    else
      delete m[header]

    this.props.onMapped(m)
  };

  onNewFieldChange = (header, config) => {
    var m = Object.assign({}, this.props.newfields || {})
    m[header] = config
    this.props.onNewFieldChange(m, header, config)
  };

  render () {
    var rows = this.props.rows

    return (
      <div className={this.props.hidden ? 'hide' : '' + ' match'}>
        <h3>Match Fields</h3>
        <div>
          {this.props.headers.map(function (h, i) {
            return (
              <div className='row matching-row'>
                <div className='col-md-5'>
                  <div className='panel panel-default'>
                    <div className='panel-body'>
                      <table className='table table-striped'>
                        <thead><tr><th>{h}</th></tr></thead>
                        <tbody>
                          {rows.map(function (r) {
                            return (
                              <tr><td>{r[i]}</td></tr>
                            )
                          }, this)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-2 separator'>
                  <span className='glyphicon glyphicon-arrow-right'></span>
                </div>
                <div className='col-md-5'>
                  <div className='panel panel-default'>
                    <div className='panel-body'>
                      <FieldSelector
                        errors={this.props.errors[h]}
                        value={this.props.mappings[h]}
                        newfield={this.props.newfields[h]}
                        meta={this.props.meta}
                        onChange={this.onMappingChange.bind(this, h)}
                        onNewFieldChange={this.onNewFieldChange.bind(this, h)}/>
                    </div>
                  </div>
                </div>
              </div>
            )
          }, this)}
        </div>
      </div>
    )
  }
}

var ContactImport = createReactClass({
  displayName: 'ContactImport',

  getInitialState: function () {
    return {
      step: 1,
      filename: '',
      preview: { headers: [], rows: [] },
      mappings: {},
      newfields: {},
      errors: {},
      errorMsg: '',
      msg: '',
      progress: 0
    }
  },

  reset: function () {
    this.setState(this.getInitialState())
  },

  render: function () {
    return (
      <div className='contact-import'>

        {this.state.msg

          ? <div className='alert alert-info alert-dismissable'>
            <button type='button' className='close' onClick={this.reset}><span aria-hidden='true'>&times;</span><span className='sr-only'>Close</span></button>
            {this.state.msg}
          </div>
          : ''
        }

        <h1>Contact Import</h1>
        <p>Upload any CSV file with a header row. Propelio creates and matches contacts by email address, so make sure the CSV includes an email column.</p>
        <FileUploadPanel entityType='contact' progress={this.state.progress} hidden={this.state.step > 1} filename={this.state.filename} onAdd={this.onAdd} onFinish={this.onUploadFinish} onProgress={this.onProgress} canUpload={this.state.canUpload} error={this.state.error}/>
        <FileSummaryPanel filename={this.state.filename} hidden={this.state.step < 2} onReset={this.reset}/>
        <MatchingPanel hidden={this.state.step < 2}
          headers={this.state.preview.headers}
          rows={this.state.preview.rows}
          meta={this.props.fieldTypes}
          onMapped={this.onMapped}
          onNewFieldChange={this.onNewFieldChange}
          mappings={this.state.mappings}
          newfields={this.state.newfields}
          errors={this.state.errors}/>

        <div className={'btn-toolbar clearfix ' + (this.state.step < 2 ? 'hide' : '')}>
          <button
            type='button'
            className='btn btn-success btn-lg pull-right'
            disabled={this.state.errorMsg !== '' || this.state.importing}
            onClick={this.startImport}>{this.state.importing ? 'Please wait...' : 'Import'}</button>
          {this.state.errorMsg ? <div className='mapping-error text-danger pull-right'><span className='glyphicon glyphicon-exclamation-sign'></span> {this.state.errorMsg}</div> : ''}

        </div>
      </div>
    )
  },

  onAdd: function (filename) {
    var index
    if ((index = filename.indexOf('.csv')) == -1 || index !== filename.length - 4)
      this.setState({ filename: filename, step: 1, canUpload: false })
    else
      this.setState({ filename: filename, canUpload: true })
  },

  onProgress: function (p) {
    this.setState({ progress: p })
  },

  onUploadFinish: function (data) {
    if (data.error)
      this.setState({ canUpload: false, error: data.error, progress: 0 })
    else
      this.setState({ step: 2, preview: data, mappings: data.suggestions, canUpload: true, progress: 0 }, this.doMappingValidation)
  },

  onMapped: function (mappings) {
    var newfields = this.state.newfields
    var errors = this.state.errors
    for (var key in mappings)
      if (mappings[key] == 'new')
        newfields[key] = newfields[key] || { label: key }

    var newkeys = Object.keys(newfields)
    newkeys.forEach(function (key) {
      if (mappings[key] !== 'new') {
        delete newfields[key]
        delete errors[key]
      }
    })

    this.setState({ mappings: mappings, newfields: newfields, errors: errors }, this.doMappingValidation)
  },

  onNewFieldChange: function (newfields, header) {
    this.setState({ newfields: newfields }, this.doNewFieldValidation.bind(this, [header]))
  },

  startImport: function () {
    var valid = this.validate()

    if (!valid.errorMsg)
      valid.importing = true

    this.setState(valid, function () {
      if (!valid.errorMsg) {
        var params = {
          mappings: JSON.stringify(this.state.mappings),
          newfields: JSON.stringify(this.state.newfields)
        }

        Model('contact').execute('import', params).then(this.onImportComplete)
      }
    })
  },

  doMappingValidation: function () {
    var valid = this.validate(['validateNotBlank'])
    this.setState(valid)
  },

  doNewFieldValidation: function (fields) {
    var valid = this.validateNewFields(fields)
    this.setState(valid)
  },

  validate: function (validators) {
    if (!validators)
      validators = ['validateNotBlank', 'validateNewFields']

    var s = {}
    validators.forEach(function (v) {
      if (!s.errorMsg)
        s = this[v].call(this)
    }, this)

    return s
  },

  validateNotBlank: function () {
    var errorMsg = ''; var canImport = Object.keys(this.state.mappings).length > 0
    if (!canImport)
      errorMsg = 'Please provide at least one field mapping'

    return { errorMsg: errorMsg }
  },

  validateNewFields: function (fields) {
    var errors = {}; var errorMsg = ''
    if (fields)
      errors = this.state.errors
    else
      fields = Object.keys(this.state.newfields)

    fields.forEach(function (key) {
      var f = this.state.newfields[key]
      var err = this.validateNewFieldCfg(f)
      errors[key] = err
    }, this)

    for (var key in errors)
      if (errors[key] && errors[key].length > 0)
        errorMsg = 'Please check above for errors'

    return { errors: errors, errorMsg: errorMsg }
  },

  validateNewFieldCfg: function (f) {
    var err = []
    if (!f.type)
      err.push('type')

    if (!f.label)
      err.push('label')

    return err
  },

  onImportComplete: function (data) {
    if (data.success) {
      this.reset()
      this.setState({ msg: 'Your import has started. Check your contact list shortly.', importing: false })
    }
  }
})

export default ContactImport
