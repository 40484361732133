/* eslint "eqeqeq": "warn" */

import fetch from 'common/fetch'

import { takeEvery } from 'redux-saga'
import { take, race, call, put } from 'redux-saga/effects'

import * as C from './actions'
import * as UserActions from '../users/actions'
import throwAny from 'common/util/throwAny'

const hasAgreed = termsId => ({ type, meta }) => type == C.MLS_ACCEPT_BROKER_TERMS_SUCCESS && meta.id == termsId
const hasSigned = documentId => ({ type, meta }) => type == C.SIGN_DOCUMENT_SUCCESS && meta.documentId == documentId
const isCancelSameSourceBroker = (source, broker) => ({ type, meta }) => type == C.AUTH_CANCELLED && meta.source == source && meta.broker.id == broker.id

const connect = (sourceId, brokerId) => fetch(`/mls/auth/connect/${sourceId}/${brokerId}`, {
  method: 'POST'
}).then(res => {
  if (res.status == 200)
    return res.json()
  else
    return res.json().then(j => {
      throwAny(j)
    })
})

function * authorization ({ payload, meta, type }) {
  const { source, broker, user } = meta
  var requirements = []

  if (!user.emailConfirmed)
    requirements.push(take(UserActions.USER_EMAIL_CONFIRMED))

  if (!user.termsOfService)
    requirements.push(take(C.MLS_ACCEPT_TOS_SUCCESS))

  // deprecated
  if (broker.requirements.esign)
    broker.requirements.esign.forEach(esign => {
      if (!esign.signed)
        requirements.push(take(hasSigned(esign.id)))
    })

  if (broker.requirements.terms)
    broker.requirements.terms.forEach(term => {
      if (!term.agreed)
        requirements.push(take(hasAgreed(term.id)))
    })

  const { finished } = yield race({
    finished: requirements,
    cancelled: take(isCancelSameSourceBroker(source, broker))
  })

  if (finished)
    try {
      yield put(C.authConnect(source, broker))
      yield call(connect, source, broker.id)
      yield put(C.authConnectSuccess(source, broker))
    } catch (e) {
      yield put(C.authConnectFailure(source, broker, e))
    }
}

export default function * () {
  yield takeEvery(C.AUTH_START, authorization)
}
