/* eslint "handle-callback-err": "warn", "prefer-const": "warn" */
import qs from 'qs'
import { Schema, arrayOf, normalize } from 'normalizr'

import fetch from 'common/fetch'
import { contact } from '../contacts/schema.js'
import get from 'lodash/object/get'
import set from 'lodash/object/set'
import { toast } from '@realsoftworks/decor'

const ENDPOINT = '/leads'
const ENDPOINT_WITH_ADDRESS = '/leads/withaddress'
const ENDPOINT_FROM_PARCEL = '/leads/fromparcel'

const lead = new Schema('lead')
const user = new Schema('user')
const entityfield = new Schema('entityfield')
const entitytemplate = new Schema('entitytemplate', { idAttribute: 'entity_type' })
entitytemplate.define({
  fields: arrayOf(entityfield)
})

const file = new Schema('file')
file.define({
  user: user
})

lead.define({
  values: {
    leadowner: arrayOf(user),
    contacts: arrayOf(contact)
  },
  files: arrayOf(file),
  template: entitytemplate
})

var leadListSchema = {
  models: arrayOf(lead)
}

var contactRoleSchema = { contact }

export const SEARCH_LEADS = 'SEARCH_LEADS'
export const SEARCH_LEADS_SUCCESS = 'SEARCH_LEADS_SUCCESS'
export const SEARCH_LEADS_COMPLETE = 'SEARCH_LEADS_COMPLETE'
export const SEARCH_LEADS_FAILURE = 'SEARCH_LEADS_FAILURE'

export function startSearch () {
  return dispatch => {
    dispatch({ type: SEARCH_LEADS })
  }
}

export function completeSearch () {
  return dispatch => {
    dispatch({ type: SEARCH_LEADS_COMPLETE })
  }
}

export function search (p) {
  const params = { ...p }

  if (!params.priority)
    delete params.priority

  if (!params.limit)
    params.limit = 20

  if (!params.offset)
    params.offset = 0

  const timestamp = new Date()
  return dispatch => {
    dispatch({ type: SEARCH_LEADS, meta: { params } })
    var urlParams = qs.stringify(params)

    return fetch(`${ENDPOINT}?${urlParams}`)
      .then(res => res.json())
      .then(leadsRespRaw => {
        const leadsRawData = get(leadsRespRaw, 'models', [])
        const leadsData = leadsRawData.map(l =>
          addSearchScreenData(l, { hasFetchedAllDetails: false }))
        const leadsResp = set(leadsRespRaw, 'models', leadsData)
        return leadsResp
      })
      .then(json => normalize(json, leadListSchema))
      .then(payload => {
        dispatch({ type: SEARCH_LEADS_SUCCESS, payload, meta: { params, timestamp } })
        return payload
      })
      .catch(error => dispatch({ type: SEARCH_LEADS_FAILURE, payload: error, meta: { params } }))
  }
}

export const CLEAR_LEADS = 'CLEAR_LEADS'

export function clear () {
  return dispatch => {
    dispatch({ type: CLEAR_LEADS })
  }
}

export const READ = 'LEAD_READ'
export const READ_SUCCESS = 'LEAD_READ_SUCCESS'
export const READ_FAILURE = 'LEAD_READ_FAILURE'

export function read (id) {
  return dispatch => {
    dispatch({ type: READ, payload: { id } })

    const payload = {}
    return fetch(`${ENDPOINT}/${id}`,
      {
        method: 'GET'
      })
      .then(res => res.json())
      .then(json => normalize(json, lead))
      .then(json => {
        dispatch({ type: READ_SUCCESS, payload: json, meta: { params: { id: id } } })
        return json
      })
      .catch(() => dispatch({ type: READ_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const UPDATE = 'LEADS_UPDATE'
export const LEADS_UPDATE_SUCCESS = 'LEADS_UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'LEADS_UPDATE_FAILURE'
export const UPDATE_ERROR_NOTIF = {
  title: 'Failed to update lead',
  content: 'Sorry, we tried to update but it failed. Please check your internet connection or try again'
}

export function update (params) {
  return dispatch => {
    dispatch({ type: UPDATE, meta: { params } })
    const leadId = params.id

    return fetch(`${ENDPOINT}/${leadId}`, {
      method: 'PUT',
      body: JSON.stringify(params.body)
    })
      .then(res => res.json())
      .then(json => normalize(json, lead))
      .then(normed => {
        if (!normed || !normed.result) throw new Error('Failed to update lead')
        return normed
      })
      .then(payload => {
        dispatch({ type: LEADS_UPDATE_SUCCESS, payload, meta: { params } })
        return payload
      })
      .catch(error => {
        toast.error(UPDATE_ERROR_NOTIF)
        dispatch({ type: UPDATE_FAILURE, payload: error, meta: { params } })
      })
  }
}

export const bulkUpdate = (ids, body, show, sort) => async dispatch => {
  for (var i = 0; i < ids.length; i++) {
    const params = { id: ids[i], body, currentShow: show, currentSort: sort }
    await dispatch(update(params))
  }
  return true
}

export const CREATE = 'LEAD_CREATE'
export const CREATE_SUCCESS = 'LEAD_CREATE_SUCCESS'
export const CREATE_FAILURE = 'LEAD_CREATE_FAILURE'

export function create (data, maybeOpts) {
  const opts = maybeOpts || {}

  let endpoint = ENDPOINT
  let method = 'POST'

  if (opts.isSearchHistory) {
    if (data.propertyId && /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.exec(data.propertyId)) {
      endpoint = `${ENDPOINT_FROM_PARCEL}/${data.propertyId}`
      method = 'GET'
    } else {
      endpoint = ENDPOINT_WITH_ADDRESS
    }
  } else if (opts.isViewDetails) {
    endpoint = `${ENDPOINT}/${data.leadId}`
    method = 'GET'
  }

  return dispatch => {
    dispatch({ type: CREATE })

    let payload = {}
    return fetch(endpoint,
      {
        method,
        ...(method === 'POST' && { body: JSON.stringify(data) })
      })
      .then(res => res.json())
      .then(leadRawData => {
        if (leadRawData.code === 404) throw new Error('Lead not found')

        const hasFetchedAllDetails = !!(opts.isSearchHistory ||
          opts.isViewDetails)
        const leadData = addSearchScreenData(
          leadRawData,
          { hasFetchedAllDetails }
        )

        const leadSchema = lead
        payload = normalize(leadData, leadSchema)
        dispatch({ type: CREATE_SUCCESS, payload: payload })
        return payload
      })
      .catch(error => {
        dispatch({ type: CREATE_FAILURE, payload: { error } })
        return { error }
      })
  }
}

export const SEARCH_PROPERTY_DATA = 'SEARCH_PROPERTY_DATA'
export const SEARCH_PROPERTY_DATA_SUCCESS = 'SEARCH_PROPERTY_DATA_SUCCESS'
export const SEARCH_PROPERTY_DATA_FAILURE = 'SEARCH_PROPERTY_DATA_FAILURE'

export function searchPropertyData (params) {
  return dispatch => {
    dispatch({ type: SEARCH_PROPERTY_DATA })
    const { zip, line1 } = params
    var data = qs.stringify({
      zip,
      line1
    })

    const payload = {}
    fetch(`/property/v4?${data}`,
      {
        method: 'GET',
        dataType: 'json',
        foo: 'bar'
      })
      .then(res => res.json())
      .then(json => {
        if (json.error)
          return dispatch({ type: SEARCH_PROPERTY_DATA_SUCCESS, payload: json }) // failure?

        dispatch({ type: SEARCH_PROPERTY_DATA_SUCCESS, payload: json.results })
      })
      .catch(() => dispatch({ type: SEARCH_PROPERTY_DATA_FAILURE, payload }))
  }
}

export const UPDATE_GEO = 'UPDATE_GEO'
export const UPDATE_GEO_SUCCESS = 'UPDATE_GEO_SUCCESS'
export const UPDATE_GEO_FAILURE = 'UPDATE_GEO_FAILURE'

export function updateGeo (id, address) {
  return dispatch => {
    dispatch({ type: UPDATE_GEO })

    let payload = {}
    return fetch(`${ENDPOINT}/updateGeo/${id}`,
      {
        method: 'POST',
        body: JSON.stringify({ address })
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, lead)
        dispatch({ type: UPDATE_GEO_SUCCESS, payload: payload })
        return payload
      })
      .catch(() => dispatch({ type: UPDATE_GEO_FAILURE, payload: payload }))
  }
}

export const UPDATE_CONTACT_ROLE = 'UPDATE_CONTACT_ROLE'
export const UPDATE_CONTACT_ROLE_SUCCESS = 'UPDATE_CONTACT_ROLE_SUCCESS'
export const UPDATE_CONTACT_ROLE_FAILURE = 'UPDATE_CONTACT_ROLE_FAILURE'

export function updateContactRole (leadId, contactId, role) {
  return dispatch => {
    dispatch({ type: UPDATE_CONTACT_ROLE })

    const params = { role, leadId, contactId }
    let payload = {}
    return fetch(`${ENDPOINT}/leadcontact/${leadId}/${contactId}`,
      {
        method: 'POST',
        body: JSON.stringify({ role })
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, contactRoleSchema)
        dispatch({ type: UPDATE_CONTACT_ROLE_SUCCESS, payload, params })
        // return payload;
      })
      .catch(() => dispatch({ type: UPDATE_CONTACT_ROLE_FAILURE, payload, params }))
  }
}

export const UPDATE_CURRENT_LEAD_SORT_ORDER = 'UPDATE_CURRENT_LEAD_SORT_ORDER'

export function setCurrentLeadSortOrder (sortOrder) {
  const params = { sortOrder }
  return dispatch => {
    dispatch({ type: UPDATE_CURRENT_LEAD_SORT_ORDER, params })
  }
}

export const UPDATE_CURRENT_LEAD_STATE_FILTER = 'UPDATE_CURRENT_LEAD_STATE_FILTER'

export function setCurrentLeadStateFilter (stateFilter) {
  const params = { stateFilter }
  return dispatch => {
    dispatch({ type: UPDATE_CURRENT_LEAD_STATE_FILTER, params })
  }
}

const addSearchScreenData = (
  leadRawData,
  { hasFetchedAllDetails = false } = {}
) => {
  let leadData

  /**
   * Our search screens needs to know whether a lead has existing property
   * details (which comes from national leads at the time of writing) or
   * not (i.e. user-defined lead / manual lead).
   *
   * For that we'll add boolean value to `lead.hasPropertyDataInDb`
   */
  const hasPropertyDataInDb = !!(leadRawData &&
    leadRawData.property &&
    Object.keys(leadRawData.property).length)

  /**
   * Our search screens needs to know whether a lead has fetched all its
   * property details (which is _usually_ the case when it is response
   * from ENDPOINT_WITH_ADDRESS) or not (which is the case elsewhere, e.g.
   * when callling multiple leads from `/leads`).
   *
   * For that we'll add boolean value to `lead.hasFetchedAllDetails`
   */
  leadData = { ...leadRawData, hasFetchedAllDetails, hasPropertyDataInDb }

  return leadData
}

export const updateLeadLocally = ({ lead }) => ({
  type: CREATE_SUCCESS,
  payload: {
    entities: { lead: { [lead.id]: lead } },
    result: lead.id
  }
})
