import React from 'react'
import styled, { keyframes } from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Positioner, Position, Box } from '@realsoftworks/decor'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const bounce = keyframes`
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(20px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
`

const MenuBouncingBox = styled(Box)`
  animation-name: ${bounce};
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  animation-duration: 2s;
  animation-iteration-count: infinite;
  font-size: 32px;
  color: ${themeGet('colors.blue.500')};
`

const MenuHint = ({ target, ...props }) => {
  if (!target)
    return null

  return (
    <Positioner
      target={target}
      position={Position.BOTTOM}
    >
      {({ ref, style, zIndex }) => (
        <MenuBouncingBox ref={ref} zIndex={zIndex} style={style} pt={2}>
          <FontAwesomeIcon icon={faArrowUp} />
        </MenuBouncingBox>
      )}
    </Positioner>
  )
}

export default MenuHint
