import React, { useMemo, useCallback, useState } from 'react'
import { Box } from '@realsoftworks/decor'
import BigLoader from 'common/BigLoader'
import SearchSidebar from './SearchSidebar'
import { TOP_BAR_HEIGHT } from '../../../app/TopBar/TopBar'
import Property from './Property'
import { useSearchScreenProps } from 'search/SearchContext'
import { useHistory } from 'search/HistoryContext'
import formatLeadToHistory from 'search/formatLeadToHistory'
import { Switch, Route, withRouter, matchPath } from 'react-router-dom'
import createHistoryEntryUrl from 'search/createHistoryEntryUrl'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import withRouteReq from 'redirect/withRouteReq'
import { ROUTES } from '../../../const'
import SearchViaUrl from './SearchViaUrl'
import MapboxReact from '../../common/Map/components/MapboxReact'

const SearchResults = withRouter(({ location, history: routerHistory }) => {
  const { isReady } = useSearchScreenProps()
  const { history: historyRaw, isLoading: isLoadingHistory } = useHistory()
  const { smDown } = useBreakpoint()
  const isViewingProp = useMemo(() =>
    !!matchPath(location.pathname, {
      path: '/search/history/:leadId',
      exact: true
    }), [location.pathname])
  const shouldHideSidebar = isViewingProp && smDown
  const history = useMemo(() =>
    historyRaw.map(h =>
      ({
        ...formatLeadToHistory(h),
        onClick: () =>
          routerHistory.push(createHistoryEntryUrl(h.id))
      })),
  [historyRaw])

  const [hasFetched, setHasFetched] = useState(false)
  const setHasFetchedToTrue = useCallback(() =>
    setHasFetched(true),
  [setHasFetched])

  return (
    <Box display='flex' className='print-noflex'>
      <Box display='flex' className='print-noflex' width='100%'>
        {isReady && !shouldHideSidebar && (
          <SearchSidebar
            hasFetched={hasFetched}
            onFetchSuccess={setHasFetchedToTrue}
          />
        )}

        <Box
          height={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
          flexGrow='1'
          display='flex'
          justifyContent='center'
          alignContent='center'
          css='position: relative;'
          className='print-noflex print-normalize-container'
        >
          {!isReady ? (
            <BigLoader />
          ) : (
            <Switch>
              <Route
                path='/search/history' exact render={() => (
                  <MapboxReact isSearchHistory={true} multipleResults={isLoadingHistory ? [] : history}></MapboxReact>
                )}
              />

              <Route
                path='/search/history/via-url'
                component={SearchViaUrlWithReq}
              />

              <Route
                path='/search/history/:leadId'
                component={WrappedProperty}
              />
            </Switch>
          )}
        </Box>
      </Box>
    </Box>
  )
})

export default SearchResults

const SearchViaUrlWithReq = withRouteReq(ROUTES.SEARCH, SearchViaUrl)

// Property margin by breakpoint
export const PROP_MARGIN_BY_BP = {
  xs: 4,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 8
}

const WrappedProperty = props => {
  const { match } = props
  const leadId = match && match.params && match.params.leadId
  const { current } = useBreakpoint()

  return (
    <Box
      css={`
        position: absolute;
        top: ${PROP_MARGIN_BY_BP[current]}px;
        left: ${PROP_MARGIN_BY_BP[current]}px;
        right: ${PROP_MARGIN_BY_BP[current]}px;
        bottom: ${PROP_MARGIN_BY_BP[current]}px;
      `}
      className='print-normalize-container'
    >
      <Property key={leadId} {...props} />
    </Box>
  )
}
