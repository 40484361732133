import get from 'lodash/object/get'

const getPrimaryLimitKey = info => {
  const firstKnownUsedKey = ['daily', 'weekly', 'monthly', 'each']
    .find(k => get(info, k))
  const firstUnknownUsedKey = Object.keys(info || {})
    .find(k => get(info, k))
  return firstKnownUsedKey || firstUnknownUsedKey
}

export default getPrimaryLimitKey
