/* eslint "no-useless-escape": "warn" */

import BasicField from './util/BasicField.react'

import React from 'react'

import createReactClass from 'create-react-class'

var NumberField = createReactClass({
  displayName: 'NumberField',
  mixins: [BasicField],

  onChange: function (e) {
    var v = e.target.value

    this.props.onChange && this.props.onChange(v)
  },

  onKeyPress: function (e) {
    var c = e.key
    var re = /[0-9\.,\-]/
    if (!re.test(c))
      e.preventDefault()
  },

  render: function () {
    var { className, ...rest } = this.props

    return <input {...rest} type='text' className={'form-control' || className} onKeyPress={this.onKeyPress} onChange={this.onChange} />
  }
})

export default NumberField
