/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Pill, Text, Box, Tooltip } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'
import countSituations from 'common/util/countSituations'

const SituationRow = ({ label, active }) => (
  <Box display='flex' alignItems='center'>
    <Box flex='1' p={1}>{label}</Box>
    <Text as='div' width={25} textAlign='center' color={active ? 'teal.500' : 'red.500'}>
      <FontIcon icon={active ? 'check' : 'x'} />
    </Text>
  </Box>
)

const SituationsPill = ({ property, pillProps = {} }) => {
  const count = countSituations(property)
  const {
    isHomestead,
    isForeclosure,
    isSenior,
    isTaxDelinquent,
    isAbsenteeOwner,
    isVacant
  } = property || {}

  if (count == 0) return null

  const situationList = (
    <Box width={150}>
      {isHomestead && <SituationRow label='Homestead' active={isHomestead} />}
      {isForeclosure && <SituationRow label='Foreclosure' active={isForeclosure} />}
      {isSenior && <SituationRow label='Senior' active={isSenior} />}
      {isTaxDelinquent && <SituationRow label='Tax Delinquent' active={isTaxDelinquent} />}
      {isAbsenteeOwner && <SituationRow label='Absentee Owner' active={isAbsenteeOwner} />}
      {isVacant && <SituationRow label='Vacant' active={isVacant} />}
    </Box>
  )

  return (
    <Tooltip
      content={situationList}
    >
      <Pill size='small' {...pillProps}>{count}&nbsp;Situation{count > 1 ? 's' : ''}</Pill>
    </Tooltip>
  )
}

export default SituationsPill
