/* eslint "no-class-assign": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import setPath from 'lodash/object/set'
import cloneDeep from 'lodash/lang/cloneDeep'

import uncontrollable from 'uncontrollable'

class Form extends React.Component {
  constructor (props) {
    super(props)

    this.onInputChange = this.onInputChange.bind(this)
    this.onInputValidationChange = this.onInputValidationChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  getChildContext () {
    return {
      formContext: {
        values: this.props.value,
        onChange: this.onInputChange,
        onValidationChange: this.onInputValidationChange
      }
    }
  }

  onInputChange (path, v) {
    var currentValue = this.props.value
    if (typeof currentValue === 'undefined' || currentValue === null)
      currentValue = {}

    var vals = cloneDeep(currentValue)
    setPath(vals, path, v)
    this.props.onChange && this.props.onChange(vals)
  }

  onInputValidationChange (path, errors) {
  }

  onSubmit (e) {
    e.preventDefault()

    this.props.onSubmit && this.props.onSubmit(this.props.value)
  }

  render () {
    return (
      <form className={this.props.className} noValidate onSubmit={this.onSubmit} style={this.props.style}>
        {this.props.children}
      </form>
    )
  }
}

Form.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  defaultValue: PropTypes.object,

  errors: PropTypes.object,
  onValidateChange: PropTypes.func,
  onSubmit: PropTypes.func,
  style: PropTypes.object
}

Form.childContextTypes = {
  formContext: PropTypes.object
}
Form = uncontrollable(Form, { value: 'onChange', errors: 'onValidateChange' })
Form.displayName = 'Form'

export default Form
