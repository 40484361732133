/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Box } from '@realsoftworks/decor'

import CustomNeighborhoodTable from './CustomNeighborhoodTable'
import EmptyMessage from '../../leadlists/components/EmptyMessage'

class NeighborhoodResults extends React.Component {
  onEmptyMessageClick = () => {
    this.props.goToSearch()
  }

  render () {
    const { data, onChangePage, count } = this.props

    if (data.length == 0)
      return <EmptyMessage onClick={this.onEmptyMessageClick}/>

    return (
      <Box width='100%'>
        <CustomNeighborhoodTable data={data} count={count.total} onChangePage={onChangePage}/>
      </Box>
    )
  }
}

export default NeighborhoodResults
