import React, { FC } from 'react'
import { Card, Heading } from '@realsoftworks/decor'

const TabUnavailable: FC = ({ children }) =>
  <Card
    p={4}
    flexGrow={1}
    display='flex'
    flexWrap='wrap'
    alignItems='center'
    alignContent='center'
    justifyContent='center'
    boxShadow={1}
    bg='white'
    height='100%'
  >
    <Heading width='100%' as='h2' size={5} textAlign='center' color='secondary'>
      {children}
    </Heading>
  </Card>

export default TabUnavailable
