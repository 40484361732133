import logError from './logError'

const track = event => {
  if (window.analytics && window.analytics.track)
    try {
      analytics.track(event)
    } catch (e) {
      logError(e)
    }
}

export default {
  track
}
