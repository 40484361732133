/* eslint "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */

import React from 'react'
import { lifecycle, compose } from 'recompose'
import { connect } from 'react-redux'

import * as selectors from '../selectors'
import { canAccess, getMetadataFetchError } from '../../mls/selectors'
import { actions as mlsActions, selectors as mlsSelectors } from '../../mls'
import { selectors as billingSelectors } from '../../billing'

import BigFatalError from 'common/BigFatalError'
import BigLoader from 'common/BigLoader'

import CMAResultsPage from '../../../views/leads/cma/CMAResultsPage.react'
import FeatureComponent from 'billing/components/FeatureComponent'
import { FEATURES } from 'billing/constants'
import CMAUpgradeCTA from 'common/CMAUpgradeCTA'
import { TermsComponent as MLSTermsComponent } from 'mls/components'

import { createOrFetchForLead, search, toggle, fetchMetadata, saveARV } from '../actions'
import * as leadSelectors from 'leads/selectors'
import { read, update } from 'leads/actions'
import { Box, toast } from '@realsoftworks/decor'
import extractErrorMessage from 'common/extractErrorMessage'
import CMACoverage from 'cma/components/CMACoverage/CMACoverage'
import { selectCoverage, selectCoverageError, selectCoverageHasLoaded, selectCoverageIsLoading } from 'mls/reducer/coverage/coverage.selector'
import { isString } from 'lodash'
import { fetchCoverage } from 'mls/reducer/coverage/coverage.action'
import { GENERIC_ERROR_NOTIF } from 'const'
import logError from 'common/logError'

const CMAError = ({ error }) => {
  const { param } = error
  if (param == 'address' || param == 'params.address')
    return (
      <div className='big-fatal-error'>
        <span className='big-fatal-icon fa-stack fa-5x'>
          <i className='fa fa-cloud fa-stack-2x'/>
          <i className='fa fa-question fa-inverse fa-stack-1x'/>
        </span>
        <h2>Unable to find that address</h2>
        <p>We weren't able to look up that address in our database. If the address is incorrect, then go back and adjust it and try again.</p>
      </div>
    )
  else if (param == 'params.address.zip')
    return (
      <div className='big-fatal-error'>
        <span className='big-fatal-icon fa-stack fa-5x'>
          <i className='fa fa-cloud fa-stack-2x'/>
          <i className='fa fa-question fa-inverse fa-stack-1x'/>
        </span>
        <h2>Invalid or missing Zipcode</h2>
        <p>Please check the address and try again.</p>
      </div>
    )

  return (
    <BigFatalError errorMessage={extractErrorMessage(error)} />
  )
}

class SearchCMAHandler extends React.Component {
  // HACK: If it takes longer than 30 seconds to load the CMA, try again
  componentDidMount () {
    setTimeout(() => {
      const isAreaCoveredButFailed = this.getIsCovered() && this.props.loading
      if (isAreaCoveredButFailed)
        this.props.setRenderSearchCMAHandler(false)
    }, 30000)

    var { lead } = this.props
    if (lead.mls_source)
      this.props.viewedSource(lead.mls_source)
    else
      this.props.viewedSource('mls-next')

    const shouldFetchCoverage = !this.props.coverageHasLoaded && !this.props.coverageIsLoading

    if (shouldFetchCoverage)
      this.props.fetchCoverage()
        .catch(error => {
          logError(error)
          toast.error({
            title: 'Failed to load CMA',
            content: GENERIC_ERROR_NOTIF.content
          })
        })
  }

  onSaveArv = (arv, arvType) => {
    const { id: leadId } = this.props.lead
    const { id: cmaId } = this.props.cma
    this.props.update({ id: leadId, body: { arv } })
    this.props.saveARV(leadId, cmaId, arv, arvType)
  }

  onSearch = params => this.props.search(this.props.lead.id, this.props.cma.id, params)

  onStateChange = (id, state) =>
    this.props.toggle(this.props.lead.id, this.props.cma.id, { [id]: state })

  getIsCovered = () => {
    const { coveredCounties, address } = this.props
    const isCovered = isString(address.fips) && !!coveredCounties?.includes(address.fips)

    return isCovered
  }

  render () {
    var { lead, cma, searching, loading, isSavingArv, savingStates, error, metadata } = this.props
    const isCovered = this.getIsCovered()
    const shouldDisplayLoader = loading || !cma
    const shouldDisplayCoverage = !isCovered && (error || !shouldDisplayLoader)

    return (
      <Box flex={1} height='100%' width='100%' className='cma-panel' px={[1, 2]} pb={[1, 2]}>
        <FeatureComponent feature={FEATURES.CMA} alternative={<CMAUpgradeCTA/>}>
          <MLSTermsComponent source={lead.mls_source || 'mls-next'}>
            {shouldDisplayCoverage
              ? <CMACoverage />
              : error
                ? <CMAError error={error}/>
                : <BigLoader loading={shouldDisplayLoader}>
                  <CMAResultsPage
                    cma={cma}
                    metadata={metadata}
                    savingStates={savingStates}
                    isSavingArv={isSavingArv}
                    lead={lead}
                    onSearch={this.onSearch}
                    onStateChange={this.onStateChange}
                    onSaveArv={this.onSaveArv}
                    loading={searching}
                  />
                </BigLoader>
            }
          </MLSTermsComponent>
        </FeatureComponent>
      </Box>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var { lead } = ownProps
  var leadId = lead.id

  var cma = selectors.getCma(state, leadId)
  var error = selectors.getCmaError(state, leadId) || getMetadataFetchError(state)

  var searching = false
  var savingStates = {}
  var metadata = null

  if (cma) {
    searching = selectors.isCmaSearching(state, cma.id)
    savingStates = selectors.getSavingStates(state, cma.id)
    metadata = mlsSelectors.getMetadataForSource(state, cma.source)
  }

  const hasCmaButNoMetadata = (cma && !metadata)
  const coverageIsLoading = selectCoverageIsLoading(state)
  var loading = selectors.isCmaListLoading(state, leadId) || hasCmaButNoMetadata || coverageIsLoading

  return {
    cma,
    metadata,
    loading,
    error,
    searching,
    savingStates,
    paid: billingSelectors.isPaid(state),

    // MLS Coverage,
    coveredCounties: selectCoverage(state),
    coverageHasLoaded: selectCoverageHasLoaded(state),
    coverageIsLoading: coverageIsLoading,
    coverageError: selectCoverageError(state)
  }
}

const mapDispatchToProps = {
  fetch: createOrFetchForLead,
  search,
  toggle,
  viewedSource: mlsActions.viewedSource,
  fetchMetadata: fetchMetadata,
  update,
  saveARV,
  fetchCoverage
}

SearchCMAHandler = compose(
  connect(
    (state, { leadId }) => {
      const lead = leadSelectors.getLead(state, leadId)
      return {
        leadId,
        lead
      }
    },
    { read, update }
  ),

  connect((state, ownProps) => ({
    paid: billingSelectors.isPaid(state),
    canAccess: canAccess(state, ownProps.lead.mls_source || 'mls-next')
  }), mapDispatchToProps),

  connect(mapStateToProps),

  lifecycle({
    componentWillMount () {
      this.props.fetchMetadata()

      if (this.props.paid && this.props.canAccess && !this.props.cma)
        this.props.fetch(this.props.lead.id)
    },

    componentWillReceiveProps (newProps) {
      var wentPaid = newProps.paid && this.props.paid !== newProps.paid
      var gotAccess = newProps.canAccess && this.props.canAccess !== newProps.canAccess
      if (wentPaid || gotAccess)
        this.props.fetch(this.props.lead.id)
    }
  })
)(SearchCMAHandler)

export default SearchCMAHandler
