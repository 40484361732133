import React, { memo } from 'react'
import { Box } from '@realsoftworks/decor'
import FilterFooter from '../../../FilterFooter'
import { useAccordionFilterSection } from 'leadlists/components/AccordionFilterSection'
import PropertyInfoSection from './PropertyInfoSection'
import GeoSection from './GeoSection'
import PropertyTypeSection from './PropertyTypeSection'
import OwnerInfoSection from './OwnerInfoSection'
import EquityInfoSection from './EquityInfoSection'
import MiscSection from './MiscSection'
import ForeclosureSection from './ForeclosureSection'

const SellerFilters = ({
  filters,
  onChange,
  propertyTypes,
  showResults,
  counts,
}) => {
  const {
    openSection,
    toggleSectionMemoedFactory: toggleSection
  } = useAccordionFilterSection()

  return (
    <React.Fragment>
      <Box maxWidth={1000} pb={160}>
        <GeoSection
          filters={filters}
          onChange={onChange}
          type='sellers'
        />

        <PropertyInfoSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSection={toggleSection}
        />

        <PropertyTypeSection
          propertyTypes={propertyTypes}
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSection={toggleSection}
        />

        <OwnerInfoSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSection={toggleSection}
        />

        <EquityInfoSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSection={toggleSection}
        />

        <ForeclosureSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSection={toggleSection}
        />

        <MiscSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSectionFactory={toggleSection}
        />

        <Box mt={5}>
          <FilterFooter
            filters={filters}
            counts={counts}
            showResults={showResults}
            setFilters={onChange}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default memo(SellerFilters)
