import flow from 'lodash/function/flow'

const isPastOrPresentYear = (year: number): boolean =>
  year <= new Date().getFullYear()

export default isPastOrPresentYear

const isPastOrPresentYearMinus1 = (year: number): boolean =>
  year <= new Date().getFullYear()

export const validatePastOrPresentYearMinus1 = flow(
  isPastOrPresentYearMinus1,
  isValid => isValid ? true : `Year should not be greater than ${new Date().getFullYear() - 1}`
)
