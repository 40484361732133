/* eslint "react/jsx-key": "warn", "eqeqeq": "warn" */
import React from 'react'
import FieldFactory from '../../../views/FieldFactory.react'
import ContactHeader from './ContactHeader.react'
import uniq from 'lodash/array/uniq'

function getDefaultLabel (name) {
  name = name.split('_').map(function (value) {
    return value.substr(0, 1).toUpperCase() + value.substr(1)
  })
  return name.join(' ')
}

class Contact extends React.Component {
  state = {
    open: false
  };

  buildList = (value, key) => {
    value = value || []
    value = value.map(function (v) {
      return (<div className=''>{v[key]}</div>)
    })
    return value
  };

  getField = (name, format) => {
    var c = this.props.contact.values
    var label = getDefaultLabel(name)
    var value = c[name]

    if (format == 'email' || format == 'phone') {
      value = this.buildList(value, format)
      if (value.length == 0)
        value = null

      label = getDefaultLabel(format)
    } else if (value) {
      if (format)
        value = FieldFactory.format(value, format)

      value = <p>{value}</p>
    }
    return value
      ? <div className='form-group'>
        <label>{label}</label>
        {value}
      </div>
      : ''
  };

  getBody = () => {
    if (this.state.open) {
      var tagcontent = null
      var tags = this.props.contact.values.contacttag
      if (tags && tags.length > 0)
        tagcontent = (
          <div className='form-group contact-tags'>
            <label>Tags</label>
            {uniq(tags, 'id').map(function (tag) {
              return <span className='contact-tag' key={tag.id}>{tag.name} </span>
            })}
          </div>
        )

      return (
        <div className='list-view-body contact-body'>
          {tagcontent}
          <div className='fields'>
            <div className='field-group'>
              {this.getField('emails', 'email')}
              {this.getField('phones', 'phone')}
            </div>
            <div className='field-group'>
              {this.getField('company_name')}
              {this.getField('job_title')}
            </div>
            <div className='field-group'>
              {this.getField('address', 'address')}
            </div>
          </div>
        </div>
      )
    } else { return null }
  };

  onHeaderClick = e => {
    if (e.target.tagName.toLowerCase() != 'a')
      this.setState({ open: !this.state.open })
  };

  render () {
    var c = this.props.contact.values

    return (
      <div className={'contact list-view-item' + (this.state.open ? ' open ' : '') + (c.active == 0 ? ' inactive ' : ' active ')} onClick={this.onHeaderClick}>
        <div className='contact-list-header list-view-header'>
          <ContactHeader link={this.props.link} contact={this.props.contact} id={this.props.contact.id}/>
        </div>
        {this.getBody()}

      </div>
    )
  }
}

export default Contact
