/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import { read, createTag, update } from '../actions'

import { selectors as contactSelectors } from '../../../modules/contacts'
import ContactView from './ContactView.react'
import { toast } from '@realsoftworks/decor'
import { TAG_EXISTING_NOTIF } from 'const'

class ContactViewHandler extends React.Component {
  static propTypes = {

  };

  constructor (props) {
    super(props)
    var params = {}

    this.state = {
      params
    }
  }

  componentWillMount () {
    this.props.read(this.props.match.params.id)
  }

  createTag = tag => {
    var model = null // this.state.model; TODO
    var tags = [].concat(model.values.contacttag || [])
    const existingTag = tags.find(t => t.id === tag.id)
    if (existingTag) { toast.error(TAG_EXISTING_NOTIF) } else {
      tags.push(tag)
      model.values.contacttag = tags

      this.setState({
        model: model,
        contact: model.values
      })
    }
  };

  onAddTag = tagName => {
    // ContactActions.createTag(tag);
    // this.props.dispatch(createTag(tag));
    const tagData = { name: tagName }
    this.props.createTag(tagData)
      .then(tag => {
        this.createTag(tag)
      })
  };

  updateFn = model => this.props.update(this.props.contact.id, model);

  render () {
    return (
      <div className='main-content contact-view' id='contact-view'>
        <ContactView updateHandler={this.updateFn} onAddTagHandler={this.onAddTag} model={this.props.contact} dispatch={this.props.dispatch}></ContactView>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const contact = contactSelectors.getContact(state, match.params.id)
  const tags = contactSelectors.getTagsByReference(state, match.params.id)
  if (contact && contact.values)
    contact.values.contacttag = contact && contact.values && contact.values.contacttag ? tags : []

  return {
    contact
  }
}

const mapDispatchToProps = {
  createTag,
  read,
  update
}

ContactViewHandler = connect(mapStateToProps, mapDispatchToProps)(ContactViewHandler)
export default ContactViewHandler
