import { Paragraph, Heading, Card, Button, Box } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'

export default function EmptyMessage ({ onClick }) {
  return (
    <Card maxWidth={500} p={7} border='2px solid' borderColor='neutral.300' borderRadius='4px'>
      <Heading size={2} textAlign='center' color='neutral.400'>No Matches</Heading>
      <Paragraph>Nothing matched your search. Try adjusting the criteria.</Paragraph>
      <Box display='flex' justifyContent='center'>
        <Button mt={4} sizeVariant='large' variant='secondary' onClick={onClick}>
          <FontIcon icon='cog' mr={3} />
          Edit Criteria
        </Button>
      </Box>

    </Card>
  )
}
