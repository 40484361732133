/* eslint "no-prototype-builtins": "warn", "eqeqeq": "warn", "no-fallthrough": "warn" */
import React from 'react'
import { Numeric, Monetary } from 'common/format'
import { Box } from '@realsoftworks/decor'
import DateHelper from '../../../core/date'

const isBlank = v => v === null || v === undefined

const nonBlank = v => {
  if (isBlank(v))
    return '---'

  return v
}

const formatBeds = stats =>
  (!isBlank(stats.beds_above) && !isBlank(stats.beds_below))
    ? `${stats.beds_above}+${stats.beds_below}`
    : !isBlank(stats.beds)
      ? stats.beds
      : '--'

const formatFacts = stats =>
  `${
    formatBeds(stats)
  } / ${
    !isBlank(stats.baths_full) && !isBlank(stats.baths_half)
      ? `${stats.baths_full}.${stats.baths_half}`
      : nonBlank(stats.baths_full)
  } / ${
    nonBlank(stats.garage)
  }`

const formatClosePrice = (v, row) =>
  <span>
    <Monetary decimals={0}>{v}</Monetary>
    {!!row.list_price && (
      <Box className='percent'>
        <Numeric>{v / row.list_price * 100}</Numeric>%
      </Box>
    )}
  </span>

const dateRenderer = v => v ? DateHelper.format(v) : '--'
const propertyFactsAccessor = v => ({ beds: v.beds, baths_full: v.baths_full, baths_half: v.baths_half, garage: v.garage })
const numberRenderer = v => <Numeric>{v}</Numeric>
const decimalsRenderer = decimals => v => <Numeric decimals={decimals}>{v}</Numeric>
const monetary = v => (<Monetary decimals={2}>{v}</Monetary>)
const monetaryNoDecimals = v => (<Monetary decimals={0}>{v}</Monetary>)
const boolRenderer = v => {
  if (v === null || v === undefined)
    return '---'

  if (v === true || v === 1)
    return 'Yes'

  return 'No'
}

const defaultColumns = [
  {
    header: 'Address',
    id: 'address',
    accessor: 'address.line1',
    props: {
      className: 'small-caption',
      width: ['110px', 'auto'],
      flex: ['none', 'auto'],
      caption: 'address'
    }
  },
  {
    id: 'original_status',
    header: 'Status',
    accessor: 'original_status',
    props: {
      className: 'small-caption',
      width: ['60px', '80px'],
      flex: 'none',
      caption: 'status'
    }
  },
  {
    id: 'address.subdivision',
    header: 'Subdivision',
    accessor: 'address.subdivision',
    props: {
      className: 'small-caption',
      width: '120px',
      flex: 'none',
      caption: 'subd'
    }
  },
  {
    id: 'property_type',
    header: 'Property Type',
    accessor: 'property_type',
    props: {
      className: 'small-caption',
      width: [80, '100px'],
      flex: 'none',
      caption: 'type'
    }
  },
  {
    id: 'property_facts',
    header: 'Beds / Baths / Garage',
    sortable: false,
    accessor: propertyFactsAccessor,
    render: v => formatFacts(v),
    props: {
      className: 'small-caption',
      flex: 'none',
      width: '80px',
      caption: 'b/b/g'
    }
  },
  {
    id: 'stories',
    header: 'Stories',
    accessor: 'stories',
    props: {
      className: 'small-caption',
      textAlign: 'right'
    }
  },
  {
    id: 'year_built',
    header: 'Year Built',
    accessor: 'year_built',
    className: 'number-column',
    headerClassName: 'number-column',
    render: v => nonBlank(v),
    props: {
      className: 'small-caption',
      textAlign: 'right',
      flex: 'none',
      width: '55px',
      caption: 'built'
    }
  },
  {
    id: 'sqft',
    header: 'Sqft',
    accessor: 'sqft',
    className: 'number-column',
    headerClassName: 'number-column',
    render: numberRenderer,
    props: {
      className: 'small-caption',
      textAlign: 'right',
      flex: 'none',
      width: '55px',
      caption: 'sqft'
    }
  },
  {
    id: 'lot_size_acres',
    header: 'Lot Size (Acres)',
    accessor: 'lot_size_acres',
    className: 'number-column',
    headerClassName: 'number-column',
    render: decimalsRenderer(2),
    props: {
      className: 'small-caption',
      textAlign: 'right',
      flex: 'none',
      width: '70px',
      caption: 'sqft'
    }
  },
  {
    id: 'calculated_dom',
    header: 'DOM',
    accessor: v => v.calculated_dom || v.dom,
    className: 'number-column',
    headerClassName: 'number-column',
    props: {
      className: 'small-caption',
      textAlign: 'right',
      flex: 'none',
      width: '50px',
      caption: 'dom'
    }
  },
  {
    id: 'close_date',
    header: 'Close Date',
    accessor: 'close_date',
    render: dateRenderer,
    props: {
      className: 'small-caption',
      textAlign: 'right',
      flex: 'none',
      width: '90px',
      caption: 'close'
    }
  },
  {
    id: 'list_price',
    header: 'List Price',
    accessor: 'list_price',
    className: 'number-column',
    headerClassName: 'number-column',
    render: monetaryNoDecimals,
    props: {
      className: 'small-caption',
      textAlign: 'right',
      caption: 'list',
      width: '80px',
      flex: 'none'
    }
  },
  {
    id: 'close_price',
    header: 'Close Price',
    accessor: 'close_price',
    className: 'number-column',
    headerClassName: 'number-column',
    render: (v, row) => v ? formatClosePrice(v, row) : '--',
    props: {
      className: 'small-caption',
      textAlign: 'right',
      caption: 'close',
      width: '80px',
      flex: 'none'
    }
  },
  {
    id: 'dollars_per_foot',
    header: '$/sqft',
    accessor: v => ((v.close_price || v.list_price) && v.sqft) ? ((v.close_price || v.list_price) / v.sqft) : null,
    className: 'number-column',
    headerClassName: 'number-column',
    render: v => v ? monetary(v) : '--',
    props: {
      className: 'small-caption',
      textAlign: 'right',
      caption: '$/sqft',
      width: '70px',
      flex: 'none'
    }
  }

]

const columnMap = defaultColumns.reduce((ret, v) => {
  ret[v.id || v.accessor] = v
  return ret
}, {})

export default (fields = null, included = null) => {
  if (!fields)
    return [].concat(defaultColumns)

  fields = fields.filter(f => f.displayable !== false)

  if (included)
    fields = fields.filter(f => included.includes(f.id))

  var ret = []
  fields.forEach(f => {
    if (f.displayable === false)
      return

    var accessor = null
    var render = null

    switch (f.type) {
      case 'number':
        if (f.id == 'year_built')
          render = nonBlank
        else
          render = numberRenderer

        break

      case 'monetary':
        render = monetaryNoDecimals
        break

      case 'date':
        render = dateRenderer

      case 'boolean':
      case 'bool':
        render = boolRenderer
        break

      case 'propertyfacts':
        if (f.beds)
          accessor = row => ({
            ...propertyFactsAccessor(row),
            beds_above: row[f.beds.above],
            beds_below: row[f.beds.below]
          })

        break
    }

    var col = {
      header: f.label,
      id: f.id,
      render,
      ...columnMap[f.id]
    }

    if (accessor)
      col.accessor = accessor

    ret.push(col)
  })
  return ret
}
