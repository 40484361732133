import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Menu, ListItem } from '@realsoftworks/decor'

import FontIcon from 'decor/FontIcon'
import LoadingIcon from 'common/LoadingIcon'

import * as SiteLinks from './SiteLinks'

import SiteListVisitorChart from './SiteListVisitorChart'
import SiteListConversionChart from './SiteListConversionChart'

import './sitelist.less'

class SiteDomain extends React.Component {
  render () {
    const { site } = this.props
    const { domains } = site

    const domain = domains.length > 0 ? domains[0] : null

    return (
      <p>{
        domain
          ? <span className='site-list-domain'>{domain} - <SiteLinks.Settings site={site}>Edit</SiteLinks.Settings></span>
          : <SiteLinks.Settings site={site} className='site-list-domain-blank'>Set Custom Domain</SiteLinks.Settings>
      }</p>
    )
  }
}

class SiteMail extends React.Component {
  render () {
    const { site } = this.props
    const { messageCount } = site

    const el = (messageCount !== null && messageCount !== undefined) ? messageCount : <LoadingIcon/>

    return (
      <div className={classNames('mail', { 'has-mail': site.messageCount })}>
        <SiteLinks.Dashboard site={site}>
          <FontIcon className='icon fa fa-envelope-o'/>
          <div className='text'>
            <strong>{el}</strong>
            messages
          </div>
        </SiteLinks.Dashboard>
      </div>
    )
  }
}

class SiteListItem extends React.Component {
  state = { open: false };

  handleClick =e => this.setState({ open: true, anchorEl: e.currentTarget });
  handleClose =() => this.setState({ open: false });

  render () {
    const { site } = this.props

    return (
      <li className='site-list-item'>
        <div className='title'>
          <SiteLinks.Dashboard site={site}><h3>{site.name}</h3></SiteLinks.Dashboard>
          <SiteDomain site={site}/>
          <SiteLinks.Front site={site} className='site-link'>Visit Site <FontIcon className='fa fa-external-link'/></SiteLinks.Front>
        </div>

        <SiteMail site={site}/>

        <div className='stats'>
          <SiteLinks.Dashboard site={site}>
            <div className='views chart'>
              <h4>Views</h4>
              <SiteListVisitorChart siteId={site.id}/>
              <div className='subtext'>7-day</div>
            </div>
            <div className='conversions chart'>
              <h4>Conversions</h4>
              <SiteListConversionChart siteId={site.id}/>
              <div className='subtext'>7-day</div>
            </div>
          </SiteLinks.Dashboard>
        </div>

        <div className='manage'>
          <Button onClick={this.handleClick}><i className='fa fa-gear'/> Manage</Button>
        </div>

        <SiteLinksMenu
          site={site}
          open={this.state.open}
          target={this.state.anchorEl}
          onClose={this.handleClose}
        />

      </li>
    )
  }
}

class SiteLinksMenu extends React.Component {
  render () {
    const { site, ...rest } = this.props
    const style = { textDecoration: 'none', color: 'inherit' }
    return (
      <Menu minWidth={150} {...rest}>
        <ListItem as={SiteLinks.Content} site={site} href='#' style={style}>Content</ListItem>
        <ListItem as={SiteLinks.Design} site={site} href='#' style={style}>Design</ListItem>
        <ListItem as={SiteLinks.Settings} site={site} href='#' style={style}>Settings</ListItem>
      </Menu>
    )
  }
}

export default class SiteList extends React.Component {
  static propTypes = {
    sites: PropTypes.array.isRequired
  };

  render () {
    return (
      <ul className='site-list'>
        {this.props.sites.map(v => <SiteListItem key={v.id} site={v}/>)}
      </ul>
    )
  }
}
