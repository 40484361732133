export const getComment = (state, id) => state.tasks.comments[id]
export const getUser = (state, id) => state.tasks.users[id]

export const getTask = (state, id) => {
  var t = state.tasks.tasks[id]
  var task = { ...t, values: { ...t.values } }

  if (task.values && task.values.activity && task.values.activity.length > 0) {
    const comments = task.values.activity.map(activityId => getComment(state, activityId))
    task.values.activity = comments || []
  }

  if (task.values && task.values.owner)
    task.values.owner = getUser(state, task.values.owner) || {}

  return task
}

export const getTasks = (state, show) => {
  if (state.tasks && state.tasks.ordering && state.tasks.ordering && state.tasks.ordering[show]) {
    const taskIdsArray = state.tasks.ordering[show]
    const tasks = taskIdsArray.map(taskId => getTask(state, taskId))
    return tasks
  } else { return [] }
}

export const getTasksByReference = (state, type, id) => {
  if (state.tasks && state.tasks.byReference && state.tasks.byReference[type] &&
    state.tasks.byReference[type][id] && state.tasks.byReference[type][id].length > 0) {
    const taskIdsArray = state.tasks.byReference[type][id]
    const tasks = taskIdsArray.map(taskId => getTask(state, taskId))
    return tasks
  } else { return [] }
}
