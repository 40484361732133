import React, { useState, useCallback } from 'react'
import { useSearchScreenProps } from 'search/SearchContext'
import { Dialog, Button, Text, Box } from '@realsoftworks/decor'
import SearchMap from 'leads/components/SearchMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStreetView } from '@fortawesome/free-solid-svg-icons'

const StreetViewModalImageButton = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { lead, propertyDetails } = useSearchScreenProps()
  const address = propertyDetails && propertyDetails.address
  const leadId = lead && lead.id

  const handleOpenModal = useCallback(() =>
    !isOpen && setIsOpen(true),
  [isOpen])

  const css = {
    position: 'relative',
    cursor: leadId ? 'pointer' : undefined,
    ...(props.css || {})
  }

  if (!address || !leadId) return null

  return (
    <>
      <Box
        css={css}
        {...props}
      >
        <ImageButton onClick={handleOpenModal} />
      </Box>

      {leadId && (
        <Dialog
          title='StreetView'
          open={isOpen}
          onClose={() => setIsOpen(false)}
          toolbar={({ close }) => (
            <Button onClick={close}>Close</Button>
          )}
          maxWidth={['100wh', '66vw']}
          maxHeight={['100vh', '90vh']}
          width='100%'
          height='100%'
          contentProps={{
            flexShrink: 0,
            display: 'flex',
            alignItems: 'stretch'
          }}
        >
          <Box flex='1 1 auto' css='position: relative;'>
            <SearchMap leadId={leadId} />
          </Box>
        </Dialog>
      )}
    </>
  )
}

export default StreetViewModalImageButton

const ImageButton = ({ onClick }) =>
  <Box css={{ position: 'relative' }}>
    <Box
      as='img'
      src='/img/blurred-home.png'
      width='160px'
      height='90px'
      display='block'
      borderRadius={4}
    />

    <Box
      bg='rgba(0, 0, 0, 0.5)'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      css={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      onClick={onClick}
      p={2}
      borderRadius={4}
    >
      <Text fontSize={2} color='neutral.200'>
        <FontAwesomeIcon icon={faStreetView} size='2x' />
      </Text>

      <Text fontSize='12px' textAlign='center' display='block' pt={[2]} color='neutral.200'>
        Click for Google Street
      </Text>
    </Box>
  </Box>
