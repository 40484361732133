import { SEARCH_LEADS_COMPLETE, SEARCH_LEADS, CREATE, CREATE_SUCCESS, UPDATE_CURRENT_LEAD_SORT_ORDER, UPDATE_CURRENT_LEAD_STATE_FILTER, CLEAR_LEADS } from '../actions'

const initialState = {
  isLoaded: false,
  isSavingLead: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LEADS:
      return {
        ...state,
        isLoaded: false
      }
    case SEARCH_LEADS_COMPLETE:
      return {
        ...state,
        isLoaded: true
      }
    case CREATE:
      return {
        ...state,
        isSavingLead: true
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        isSavingLead: false
      }
    case UPDATE_CURRENT_LEAD_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.params.sortOrder
      }
    case UPDATE_CURRENT_LEAD_STATE_FILTER:
      return {
        ...state,
        stateFilter: action.params.stateFilter
      }
    case CLEAR_LEADS:
      return {
        ...state,
        isLoaded: false
      }
  }
  return state
}
