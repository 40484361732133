import React, { useEffect, useRef } from 'react'
import { useSearchScreenProps } from 'search/SearchContext'
import { Redirect } from 'react-router-dom'
import qs from 'qs'

const SearchViaUrl = () => {
  const { search } = useSearchScreenProps()
  const queryRef = useRef(window.location.search.substring(1))

  useEffect(() => {
    if (!search) return

    const { line1, city, state, zip } = qs.parse(queryRef.current) || {}
    if (!line1 || !city || !state || !zip) return
    search({ line1, city, state, zip })
  }, [search])

  return search ? (
    <Redirect to='/search/history' />
  ) : null
}

export default SearchViaUrl
