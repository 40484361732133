import { createTheme } from '@realsoftworks/decor'

const theme = createTheme({
  cards: {
    padded: {
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)', // level 1 shadow
      padding: 24
    },

    basic: {
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)' // level 1 shadow
    },

    'blue-bordered': {
      backgroundColor: '#EBF8FF', // blue.100
      border: '1px solid #55AAD4', // blue.500
      borderRadius: '4px',
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)' // level 1 shadow
    }
  }
})

export type ColorPairs = 'neutral'
  | 'lightTeal'
  | 'teal'
  | 'red'
  | 'lightRed'
  | 'blue'
  | 'yellow'

theme.colors = {
  ...theme.colors,
  pairs: {
    neutral: {
      light: theme.colors.neutral[200],
      dark: theme.colors.neutral[600]
    },
    teal: {
      light: theme.colors.teal[200],
      dark: theme.colors.teal[600]
    },
    lightTeal: {
      light: theme.colors.teal[100],
      dark: theme.colors.teal[500]
    },
    red: {
      light: theme.colors.red[200],
      dark: theme.colors.red[600]
    },
    lightRed: {
      light: theme.colors.red[100],
      dark: theme.colors.red[400]
    },
    blue: {
      light: theme.colors.blue[200],
      dark: theme.colors.blue[600]
    },
    yellow: {
      light: theme.colors.yellow[200],
      dark: theme.colors.yellow[600]
    },
  } as { [key in ColorPairs]: { light: string, dark: string } }
}

export default theme

/**
 * Breakpoints here are expected to be the minimum px size strings
 * (e.g. '576px') per breakpoint
 **/
export const breakpoints = theme.breakpoints
export const breakpointsKeyed = {
  xs: 0,
  sm: breakpoints[0],
  md: breakpoints[1],
  lg: breakpoints[2],
  xl: breakpoints[3]
}
