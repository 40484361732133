import { connect } from 'react-redux'

import { getSite } from '../selectors'

import SiteDashboard from './SiteDashboard'

const SiteDashboardHandler = connect(
  (state, { match }) => ({
    site: getSite(state, match.params.id)
  })
)(SiteDashboard)

export default SiteDashboardHandler
