/* eslint "camelcase": "warn", "eqeqeq": "warn" */
import mapLoader from 'common/mapLoader'
import { toSingleLine, isGeoComplete, isStreetviewComplete } from 'common/util/address'
import ensureArray from 'common/util/ensureArray'
import logError from './logError'

async function geocode (address) {
  const geocoder = new google.maps.Geocoder()
  const address_str = toSingleLine(address)

  return new Promise(function (resolve, reject) {
    let attempts = 0

    const geo = geocoder.geocode.bind(geocoder, { address: address_str }, function (results, status) {
      attempts += 1
      if (status == google.maps.GeocoderStatus.OK) {
        const result = results[0]

        const location = result.geometry.location

        const county_record = ensureArray(result.address_components)
          .find(a => a.types.indexOf('administrative_area_level_2') != -1)
        const county = county_record ? county_record.short_name.replace(/\sCounty/, '') : null

        const state_record = ensureArray(result.address_components)
          .find(a => a.types.indexOf('administrative_area_level_1') != -1)
        const state = state_record ? state_record.short_name : null

        const data = {
          lat: location.lat(),
          lon: location.lng(),
          county,
          state
        }

        resolve(data)
      } else if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT && attempts < 3) {
        setTimeout(geo, Math.random() * 1000)
      } else { // random delay
        reject(new Error('Geocode failed'))
      }
    })

    setTimeout(geo, Math.random() * 1000) // random delay
  }).then(data => {
    if (!data.county)
      return geonames(address, data)

    return data
  })
}

async function geonames (address, data) {
  const { city, state } = address
  try {
    const result = await fetch(`http://api.geonames.org/searchJSON?q=${city},${state}&maxRows=10&username=propelio`)
    const json = await result.json()

    const { geonames } = json
    const record = geonames.find(v => v.name == city && v.fcode == 'PPL') // ppl = populated place ie: city
    if (record) {
      const { geonameId } = record
      const lookupResult = await fetch(`http://api.geonames.org/getJSON?geonameId=${geonameId}&username=propelio`)
      const lookupJson = await lookupResult.json()
      const county = lookupJson.adminName2
      if (county)
        data = { ...data, county }
    }
    return data
  } catch (e) {
    return data
  }
}

function getDirectionPoint (addressText) {
  const directionsService = new google.maps.DirectionsService()
  return new Promise(resolve => {
    var request = {
      origin: addressText,
      destination: addressText,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    }
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        var location = response.routes[0].legs[0].start_location
        resolve({
          lat: location.lat(),
          lon: location.lng()
        })
      } else {
        logError('Directions service not successfull for the following reason:' + status)
        resolve(null)
      }
    })
  })
}

async function getStreetViewParams (address) {
  const point = { lat: address.lat, lon: address.lon }
  const roofLatLng = new google.maps.LatLng(point.lat, point.lng || point.lon)
  const addressText = toSingleLine(address)
  const directionsPoint = await getDirectionPoint(addressText)
  if (directionsPoint)
    Object.assign(point, directionsPoint)

  const streetViewService = new google.maps.StreetViewService()
  const latlng = new google.maps.LatLng(point.lat, point.lng || point.lon)

  return new Promise(function (resolve) {
    streetViewService.getPanorama({
      location: latlng,
      preference: 'nearest'
    }, (streetViewPanoramaData, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        const panoPoint = streetViewPanoramaData.location.latLng

        var streetViewParams = {
          lat: panoPoint.lat(),
          lon: panoPoint.lng(),
          heading: -google.maps.geometry.spherical.computeHeading(panoPoint, roofLatLng)
        }

        resolve(streetViewParams)
      } else { resolve({}) }
    })
  })
}

var Util = {}

const geoCache = {}
Util.geocode = async function (address) {
  const key = toSingleLine(address)
  if (!geoCache[key]) {
    await mapLoader()
    const result = await geocode(address)
    geoCache[key] = result
  }

  return geoCache[key]
}

const streetCache = {}
Util.getStreetViewParams = async function (address) {
  const key = `${address.lat}||${address.lon}`
  if (!streetCache[key]) {
    await mapLoader()
    streetCache[key] = await getStreetViewParams(address)
  }
  return streetCache[key]
}

Util.getDataForAddress = async function (address) {
  // console.info('getDataForAddress');
  let ret = { ...address }
  if (!isGeoComplete(address) || !address.county) {
    // console.info('getDataForAddress - will geocode');
    const geodata = await Util.geocode(address)
    // console.info('getDataForAddress - did geocode');
    ret = {
      ...ret,
      ...geodata
    }
  }

  if (!isStreetviewComplete(address)) {
    // console.info('getDataForAddress - will street');
    const streetview = await Util.getStreetViewParams(ret)
    // console.info('getDataForAddress - did street');
    const { lon: streetview_lon, heading: streetview_heading, lat: streetview_lat } = streetview
    ret = {
      ...ret,
      streetview_lon,
      streetview_heading,
      streetview_lat
    }
  }

  return ret
}

Util.parsePlace = function (place) {
  const PLACE_MAPPINGS = {
    street_number: { addressField: 'number', placeValue: 'short_name' },
    route: { addressField: 'street', placeValue: 'short_name' },
    locality: { addressField: 'city', placeValue: 'short_name' },
    neighborhood: { addressField: 'cityAlt1', placeValue: 'short_name' },
    sublocality_level_1: { addressField: 'cityAlt2', placeValue: 'short_name' },
    administrative_area_level_3: {
      addressField: 'township',
      placeValue: 'short_name',
      mutate: (township) => (township || '').replace(/township$/i, '').trim(),
    },
    administrative_area_level_2: { addressField: 'county', placeValue: 'short_name' },
    administrative_area_level_1: { addressField: 'state', placeValue: 'short_name' },
    postal_code: { addressField: 'zip', placeValue: 'short_name' }
  }

  if (!place.address_components)
    return false

  var ret = {}

  place.address_components.forEach(v => {
    v.types.forEach(t => {
      if (PLACE_MAPPINGS[t]) {
        var mapping = PLACE_MAPPINGS[t]

        if (mapping.mutate) {
          ret[mapping.addressField] = mapping.mutate(v[mapping.placeValue]);
        } else {
          ret[mapping.addressField] = v[mapping.placeValue]
        }
      }
    })
  })

  // See @https://github.com/realsoftworks/propelioweb/pull/501#pullrequestreview-448250392
  if (!ret.city) {
    const { cityAlt1, cityAlt2, township } = ret
    ret.city = cityAlt1 || cityAlt2 || township;
  }

  delete ret.cityAlt1
  delete ret.cityAlt2

  if (!ret.county && ret.city)
    ret.county = ret.city // for independent cities like St Louis

  if (ret.county)
    ret.county = ret.county.replace(/\sCounty/, '')

  if (ret.number || ret.street)
    ret.line1 = (ret.number || '') + (ret.number && ret.street ? ' ' : '') + (ret.street || '')

  if (place.geometry && place.geometry.location) {
    const loc = place.geometry.location
    ret = { ...ret, lat: loc.lat(), lon: loc.lng() }
  }

  return ret
}

export default Util
