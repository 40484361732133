/* eslint "eqeqeq": "warn" */
import React from 'react'

import { Dialog, Box } from '@realsoftworks/decor'
import ListingDetail from './CMAListingDetail.react.js'
import Chip from '../../common/Chip.react'
import CMAConstants from './CMAConstants'
import StateButtons from './StateButtons.react'

class CompModal extends React.Component {
  static defaultProps = {
    showStates: true
  };

  render () {
    const { comp } = this.props
    const showPhotos = comp.photos && comp.photos.length

    return (
      <Dialog width='auto' maxWidth={showPhotos ? 1100 : 680} title={ this.renderTitle() } open={this.props.show} onClose={this.props.onRequestClose}>
        <ListingDetail showPhotos={showPhotos} listing={comp} fields={this.props.fields}/>
      </Dialog>
    )
  }

  renderTitle = () => {
    var { comp } = this.props

    return (
      <Box display='flex' alignItems='center'>
        {this.props.showStates ? <StateButtons onChange={this.onStateChange} state={this.props.state} style={{ marginRight: 20 }} /> : ''}
        <h4 className='modal-title' style={{ display: 'inline-block' }}>{comp.address.line1}</h4>
        {this.getStatusIcon()}
      </Box>
    )
  };

  getStatusIcon = () => {
    var { comp } = this.props

    var styles = {
      verticalAlign: 'text-bottom',
      marginLeft: 10,
      backgroundColor: CMAConstants.colors[comp.status]
    }

    if (CMAConstants.colors[comp.status] == 'white') {
      styles.border = '1px solid #ccc'
      styles.color = '#333'
    }

    return <Chip style={styles}>{comp.original_status}</Chip>
  };

  onStateChange = (e, state) => {
    this.props.onStateChange(state)
  };
}

export default CompModal
