import React, { useState, useCallback, Fragment } from 'react'
import { update } from 'leads/actions'
import { connect } from 'react-redux'
import { Option, SelectButtons, Box, Select, Text } from '@realsoftworks/decor'
import { STATE_OPTS } from 'search/HistoryContext'
import LoadingIcon from 'common/LoadingIcon'
import { useSearchScreenProps } from 'search/SearchContext'
import useBreakpoint from 'common/util/hooks/useBreakpoint'

const withConnect = connect(null, { updateLeadStatus: update })
const SelectState = withConnect(({ updateLeadStatus }) => {
  const { lead, setLead } = useSearchScreenProps()
  const [isLoading, setIsLoading] = useState(false)
  const { xlUp } = useBreakpoint()

  const handleUpdateStatus = useCallback(ev => {
    if (isLoading) return
    const newState = ev.target.value
    const params = { id: lead.id, body: { state: newState } }
    setIsLoading(true)
    updateLeadStatus(params)
      .then(() => setLead(l => ({ ...l, state: newState })))
      .finally(() => setIsLoading(false))
  }, [lead.id])

  return xlUp ? (
    <Box bg='white' borderRadius={4} css='position: relative;'>
      {isLoading && (
        <Box
          css={`
            position: absolute;
            right: calc(100% + 4px);
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <LoadingIcon size={1} />{' '}
        </Box>
      )}

      <SelectButtons
        value={lead.state}
        placeholder='State'
        onChange={handleUpdateStatus}
        height='100%'
        css='font-size: 13px;'
      >
        {STATE_OPTS.map(({ value, label }, i) => (
          <Option key={i} value={value}>
            <Box px={1}>
              {label.split(' ').map((word, i) => (
                <Fragment key={i}>
                  {i !== 0 && (<>&nbsp;</>)}
                  {word}
                </Fragment>
              ))}
            </Box>
          </Option>
        ))}
      </SelectButtons>
    </Box>
  ) : (
    <Box>
      <Text display='block' color='secondary' fontSize={0} pb={0}>
        Update Status
      </Text>
      <Box css='position: relative;'>
        {isLoading && (
          <Box
            css={`
              position: absolute;
              right: calc(100% + 4px);
              top: 50%;
              transform: translateY(-50%);
            `}
          >
            <LoadingIcon size={1} />{' '}
          </Box>
        )}

        <Select
          value={lead.state}
          placeholder='State'
          onChange={handleUpdateStatus}
          height='100%'
          css={`
            font-size: 13px;
            min-height: 28px;
            padding: 0;
            width: 128px;
          `}
        >
          {STATE_OPTS.map(({ value, label }, i) => (
            <Option
              key={i}
              value={value}
              css='font-size: 13px; padding: 4px 8px;'
            >
              {label.split(' ').map((word, i) => (
                <Fragment key={i}>
                  {i !== 0 && (<>&nbsp;</>)}
                  {word}
                </Fragment>
              ))}
            </Option>
          ))}
        </Select>
      </Box>
    </Box>
  )
})

export default SelectState
