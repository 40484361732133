/* eslint "react/display-name": "warn" */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import { Box, Paragraph, Button } from '@realsoftworks/decor'
import { updateSettings } from 'users/actions'

import { FEATURES } from 'billing/constants'

const ResponsiveEmbed = ({ children, ...props }) => (
  <Box
    css={`
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
      `}
    {...props}
  >
    <Box
      css={`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `}
    >
      {children}
    </Box>

  </Box>
)

export const STEPS = [
  {
    title: 'Overview',
    tasks: [
      {
        id: '1-1',
        title: 'Watch a quick demo video',
        estimatedTime: '3 minutes',
        stepsCount: 1,
        steps: [
          {
            completionKey: 'video_watched',
            text: 'This short video will show you everything Propelio has to offer!',
            cta: {
              custom: () => <WatchVideoButton />
            }
          },
          {
            custom: () => (
              <ResponsiveEmbed mt={5} mb={3}>
                <ReactPlayer width='100%' height='100%' url='https://www.youtube.com/watch?v=9WggiVwBwm8' />
              </ResponsiveEmbed>
            )
          }
        ]
      }
    ]
  },

  {
    title: 'Generate More Leads',
    tasks: [
      {
        id: '2-1',
        title: 'Download a Lead List',
        feature: FEATURES.LEADS,
        estimatedTime: '3-5 minutes',
        stepsCount: 1,
        steps: [{
          completionKey: 'leadlist.downloaded',
          text: 'Browse our massive database to find property lists, cash buyers and private lenders. You can search for buyers and lenders that deal in the kinds of properties you do or stack motivations to find the homeowners that most likely to sell.',
          cta: {
            url: '/leadlists',
            text: 'Browse Leads'
          }
        }]
      },
      {
        id: '2-2',
        title: 'Set up your Website',
        feature: FEATURES.SITES,
        permission: {
          model: 'sites',
          action: 'manage'
        },
        estimatedTime: '5-15 minutes',
        stepsCount: 3,
        steps: [
          {
            completionKey: 'site.created',
            text: 'Create your site - Our website platform was built for investors without any technical experience. Put your business online by answering a few simple questions.',
            cta: {
              url: '/sites',
              text: 'Create Site'
            }
          },
          {
            completionKey: 'site.domain_configured',
            text: 'Add a custom domain - your website’s domain (ex: propelioproperties.com) is a core part of your brand. Find and purchase a custom domain or configure one you purchased elsewhere',
            cta: {
              custom: ({ onboarding }) => {
                const id = onboarding.site ? onboarding.site.id : null
                const url = `/sites/${id ? `${id}/settings` : ''}`
                return (
                  <Button as='a' href={url} variant='primary' width='100%'>Setup Domain</Button>
                )
              }
            }
          },
          {
            completionKey: 'site.email_configured',
            text: 'Set up your email - getting a custom email for your domain is the last step for setting up your online presence. We support any mail provider but recommend GMail for Business',
            cta: {
              url: '/sites',
              text: 'Configure Email'
            }
          }
        ]
      },
      {
        id: '2-3',
        feature: FEATURES.DRIVING,
        title: 'Drive for Dollars',
        estimatedTime: '5-10 minutes',
        stepsCount: 2,
        steps: [
          {
            completionKey: 'drive.leads_downloaded',
            text: 'Our mobile app lets you generate leads on-the-go. Download it in the app store and start driving for dollars or just click on a house and add it to a marketing list',
            cta: {
              custom: () => (
                <Paragraph mb={0} color='neutral.500'>
                  Download the app and start a drive or add a lead to a marketing list
                </Paragraph>
              )
            }
          },
          {
            completionKey: 'drive.leads_downloaded',
            text: 'After you get back from your drive, go the Driving for Dollars area and download your leads',
            cta: {
              url: '/drivingfordollars',
              text: 'Download your Leads'
            }
          }
        ]
      }
    ]
  },

  {
    title: 'Analyze Deals',
    tasks: [
      {
        id: '3-1',
        title: 'Run your first CMA',
        feature: FEATURES.CMA,
        permission: {
          model: 'lead',
          action: 'create'
        },
        estimatedTime: '3 minutes',
        stepsCount: 2,
        steps: [
          {
            completionKey: 'lead.created',
            text: 'Add a Lead to the Lead Tracker',
            cta: {
              url: '/search',
              text: 'Add a Lead'
            }
          },
          {
            completionKey: 'cma.created',
            text: 'Run a CMA and figure out what it’s worth',
            cta: {
              custom: ({ onboarding }) => {
                const id = onboarding.lead ? onboarding.lead.id : null
                const url = `/search/history/${id || ''}`
                return (
                  <Button as='a' href={url} variant='primary' width='100%'>Run a CMA</Button>
                )
              }
            }
          }
        ]
      }
    ]
  },

  {
    title: 'Manage your Leads',

    tasks: [
      {
        id: '4-1',
        title: 'Add a Lead to the Lead Tracker',
        permission: {
          model: 'lead',
          action: 'create'
        },
        stepsCount: 1,
        estimatedTime: '2 minutes',
        steps: [
          {
            completionKey: 'lead.created',
            text: 'The Lead Tracker helps you organize your due diligence and gives you a place to store documents and tasks lists.',
            cta: {
              url: '/search/history',
              text: 'Add a Lead'
            }
          }
        ]
      },
      {
        id: '4-2',
        title: 'Check out the Lead Template',
        permission: {
          model: 'lead',
          action: 'template'
        },
        stepsCount: 1,
        estimatedTime: '1 minutes',
        steps: [
          {
            completionKey: 'lead.template_viewed',
            text: 'You can customize just about every field you see on the Lead screen through a drag-and-drop interface. Want to add a new field? No problem.',
            cta: {
              url: '/templates/view/lead',
              text: 'View Lead Template'
            }
          }
        ]
      }
    ]
  },

  {
    title: 'Make Propelio Yours',
    tasks: [
      {
        id: '5-1',
        title: 'Complete your profile',
        estimatedTime: '3 minutes',
        stepsCount: 3,
        steps: [
          {
            completionKey: 'user.profile_updated',
            text: {
              custom: () => (
                <>
                  <Paragraph>Upload your avatar so your teammates will recognize you</Paragraph>
                  <Paragraph>Update your job title</Paragraph>
                  <Paragraph mb={0}>Add your phone number</Paragraph>
                </>
              )
            },
            cta: {
              url: '/users/settings',
              text: 'Edit Profile'
            }
          }
        ]
      },
      {
        id: '5-2',
        title: 'Invite your Team',
        permission: {
          model: 'admin',
          action: 'users'
        },
        steps: [
          {
            completionKey: 'user.invited',
            text: 'Propelio was built for teams to work together. Invite a team member and give them a role that reflects their responsibilities',
            cta: {
              url: '/users/team',
              text: 'Invite Team'
            }
          }
        ]
      }
    ]
  }
]

const WatchVideoButton = connect(
  null,
  dispatch =>
    ({
      markVideoWatched: () =>
        dispatch(updateSettings({ 'onboarding.video_watched': true }))
    })
)(({ markVideoWatched }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Button
      variant='primary' width='100%' disabled={isLoading} onClick={() => {
        setIsLoading(true)
        markVideoWatched()
          .catch(() => { setIsLoading(false) })
      }}
    >
      {isLoading ? 'Please wait...' : 'I watched it'}
    </Button>
  )
})
