import React, { useCallback, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { isTrialing as getIsTrialing, getBillingInfo, getIsUpgrading, getCurrentPlan } from 'billing/selectors'
import { Box, Text, Button, toast } from '@realsoftworks/decor'
import { selectDownloads } from 'lists/selectors'
import get from 'lodash/object/get'
import { Numeric } from 'common/format'
import getPrimaryLimitKey from '../getPrimaryLimitKey'
import UpgradeDialog from 'billing/components/UpgradeDialog'
import { endTrial } from 'billing/actions'
import LoadingButton from 'common/LoadingButton'
import { fetchTrialPromoInfo, expireTrialPromoInfo } from 'billing/trialPromo/actions'
import { useEffectOnce } from 'react-use'
import { getTrialPromoVal, getTrialPromoExp, getTrialBillingAmount } from 'billing/trialPromo/selectors'
import useDateTimeout from 'common/util/hooks/useDateTimeout'

const withConnect = connect(
  (state, ownProps) => {
    const isTrialing = getIsTrialing(state)
    const limitInfo = get(selectDownloads(state, ownProps.listId), 'limits')
    const promoVal = getTrialPromoVal(state)

    const limits = get(limitInfo, 'limits')
    const upgrade = get(limitInfo, 'upgrade')
    const primaryKey = getPrimaryLimitKey(limits || {})
    const currentLimit = limits && limits[primaryKey]
    const upgradeLimit = upgrade && upgrade[primaryKey]
    const hasLimitInfo = typeof currentLimit === 'number' &&
      typeof upgradeLimit === 'number'

    const shouldPromote = isTrialing

    return {
      billinginfo: getBillingInfo(state),
      promoExp: getTrialPromoExp(state),
      billingAmount: getTrialBillingAmount(state),
      isUpgrading: getIsUpgrading(state),
      currentPlanName: getCurrentPlan(state).name,
      isTrialing,
      limitInfo,
      promoVal,
      shouldPromote,
      hasLimitInfo
    }
  },
  { endTrial, fetchTrialPromoInfo, expireTrialPromoInfo })

const TrialNotice = withConnect(({ shouldPromote, listId, ...props }) => {
  if (!shouldPromote) return null

  return (
    <Box display='flex' alignItems='center' {...props}>
      <Box flex='1 1 auto'>
        <TrialText listId={listId} />
      </Box>
      <Box flex='0 0 auto' pl={5}>
        <TrialCta listId={listId} />
      </Box>
    </Box>
  )
})

export const TrialText = withConnect(({
  limitInfo,
  isTrialing,
  promoVal,
  promoExp,
  fetchTrialPromoInfo,
  shouldPromote,
  hasLimitInfo,
  expireTrialPromoInfo,
  currentPlanName,
  ...props
}) => {
  useEffectOnce(() => { fetchTrialPromoInfo() })

  const limits = get(limitInfo, 'limits')
  const upgrade = get(limitInfo, 'upgrade')
  const primaryKey = getPrimaryLimitKey(limits || {})
  const currentLimit = limits && limits[primaryKey]
  const upgradeLimit = upgrade && upgrade[primaryKey]

  useDateTimeout(() => {
    expireTrialPromoInfo()
  }, promoExp && new Date(promoExp), [promoExp])

  if (!shouldPromote) return null

  return (
    <Text
      display='block'
      color='red.500'
      fontWeight='bold'
      fontSize={1} {...props}
    >
      {promoVal && (
        <Text css='text-decoration: underline;'>
          Get {promoVal} off first month for upgrading now.
        </Text>
      )}
      {promoVal && ' '}
      Add your payment info and upgrade from trial to a Paid {currentPlanName} plan.
      {hasLimitInfo && (
        <Fragment>
          {' '}Upgrading increases the daily download limit from
          {' '}<Numeric>{currentLimit}</Numeric>
          {' '}to
          {' '}<Numeric>{upgradeLimit}</Numeric>
          {' '}per day.
        </Fragment>
      )}
    </Text>
  )
})

export const EndTrialButton = withConnect(({
  billinginfo,
  isTrialing,
  container = ({ content }) => content,
  endTrial,
  shouldPromote,
  billingAmount,
  onBeforeRequest = () => Promise.resolve(),
  disabled,
  willAddBillingInfo,
  isUpgrading,
  currentPlanName,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(isUpgrading || false)

  const handleEndTrial = useCallback(() => {
    setIsLoading(true)

    // Note: For some reason, when setIsLoading is called in finally callback,
    //   it is being called before toast.info() function
    onBeforeRequest()
      .then(() => endTrial())
      .then(() => {
        setIsLoading(false)
        toast.info({ title: `Upgraded from Trial to Paid ${currentPlanName}` })
      })
      .catch(() => {
        setIsLoading(false)
        toast.error({
          title: `Failed to upgrade to Paid ${currentPlanName}`,
          content: 'We’re sorry. please refresh the page and try again or get in touch with us by clicking the support button on lower right.'
        })
      })
  }, [endTrial])

  if (!shouldPromote) return null

  return container({
    content: (
      <LoadingButton
        variant='danger'
        onClick={handleEndTrial}
        loading={isLoading || isUpgrading}
        disabled={(!willAddBillingInfo && !billinginfo) || disabled}
        {...props}
      >
        Pay
        {billingAmount && ` $${billingAmount}`}
        {' '}now and Upgrade
      </LoadingButton>
    )
  })
})

export const TrialCta = withConnect(({ shouldPromote, listId, ...props }) => {
  const [isOpen, setIsOpen] = useState()
  const open = useCallback(() => setIsOpen(true), [setIsOpen])
  const close = useCallback(() => setIsOpen(false), [setIsOpen])

  if (!shouldPromote) return null

  return (
    <Fragment>
      <Button
        variant='danger'
        onClick={open}
        {...props}
      >
        Upgrade
      </Button>

      <UpgradeDialog
        open={isOpen}
        onRequestClose={close}
        listId={listId}
      />
    </Fragment>
  )
})

export default TrialNotice
