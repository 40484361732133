/* eslint "eqeqeq": "warn" */
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Input } from '@realsoftworks/decor'
import Icon from '@realsoftworks/decor/dist/components/Icon'
import LoadingIcon from 'common/LoadingIcon'
import fetch from 'common/fetch'
import MapUtil from 'common/geocode'
import mapLoader from 'common/mapLoader'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { SearchContext, useSearchInputInitializer } from '../SearchContext'
import debounce from 'lodash/function/debounce'
import uniq from 'lodash/array/uniq'

const SearchInput = withRouter(({ location, instanceId, ...props }) => {
  const {
    setInputRef,
    isSearching
  } = useSearchInputInitializer({ instanceId })
  const [_inputRef, _setInputRef] = useState()
  const { xsDown } = useBreakpoint()

  /**
   * Special case: hide if on topbar, and is on `/search`
   * because there would be another search input displayed else where
   */
  const shouldHide = instanceId === 'topbar' &&
    location.pathname === '/search'

  /**
   * For some obscure reason, when `setInputRef()` is passed directly to input,
   * it gets called multiple times. This is the work around to that (ensures)
   * that it is only called once per mount.
   */
  useEffect(() => {
    if (!_inputRef) return
    setInputRef(_inputRef)
  }, [!!_inputRef])

  useEffect(() => {
    if (!_inputRef) return

    const handleEnterPress = ev => {
      const isEnterKey = ev.which == 13 || ev.keyCode == 13

      if (!isEnterKey) return

      const selId = selectedSuggestionIdRef.current

      if (selId) {
        navigate(null, selId)
        return
      }

      const value = _inputRef.value

      if (value.length < 3) return

      // fetch(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?${qs.stringify({
      //   fields: 'geometry,address_components',
      //   input: value,
      //   inputtype: 'textQuery',
      //   key: GOOGLE_KEY
      // })}`)
      //   .then(res => res.json())
      //   .then(data => {
      //     console.log(data)
      //   })
      //
      // API above is for server call only apparently.

      mapLoader()
        .then(maps => {
          const autocompleteService = new google.maps.places.AutocompleteService();

          // issue can't restrict to US
          autocompleteService.getQueryPredictions({
            input: value,
            componentRestrictions: { country: 'us' }, // <-- this doesn't work
            placeIdOnly: true,
            fields: ['place_id']
          }, (results, status) => {

            // placesServiceRef.current.findPlaceFromQuery({
            //   query: value,
            //   fields: ['place_id']
            // }, (results, status) => {
            if (status !== 'OK') return

            const place = results[0]

            if (!place) return


            placesServiceRef.current.getDetails({
              placeId: place.place_id,
              fields: ['geometry', 'address_components']
            }, (placeDetails, status) => {
              if (status !== 'OK') return

              navigate(MapUtil.parsePlace(placeDetails))
            })
          })
        })
    }

    const handleArrowPress = ev => {
      const isUpArrowKey = (ev.which || ev.keyCode) == 38
      const isDnArrowKey = (ev.which || ev.keyCode) == 40

      if (!(isUpArrowKey || isDnArrowKey)) return

      ev.preventDefault()
      ev.stopPropagation()

      const sugg = suggestionsRef.current
      const selId = selectedSuggestionIdRef.current

      if (!(sugg && sugg.length > 0)) return

      if (!selId) {
        setSelectedSuggestionId(sugg[0].id);
        return
      }

      const currentIdx = sugg.findIndex((el) => el.id === selId)
      let nextIdx

      if (isUpArrowKey) nextIdx = currentIdx - 1
      else if (isDnArrowKey) nextIdx = currentIdx + 1

      if (nextIdx < 0) nextIdx = sugg.length - 1
      else if (nextIdx >= sugg.length) nextIdx = 0

      setSelectedSuggestionId(sugg[nextIdx].id)
    }

    const handleEscapePress = ev => {
      const isEscKey = (ev.which || ev.keyCode) == 27

      if (!isEscKey) return

      ev.preventDefault()
      ev.stopPropagation()

      resetSearch()
    }

    _inputRef.addEventListener('keyup', handleEnterPress)
    _inputRef.addEventListener('keydown', handleArrowPress)
    _inputRef.addEventListener('keydown', handleEscapePress)

    return () => {
      _inputRef.removeEventListener('keyup', handleEnterPress)
      _inputRef.removeEventListener('keydown', handleArrowPress)
      _inputRef.removeEventListener('keydown', handleEscapePress)
    }
  }, [_inputRef])

  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestionsState] = useState([])
  const suggestionsRef = useRef(suggestions)
  const [selectedSuggestionId, setSelectedSuggestionIdState] = useState()
  const selectedSuggestionIdRef = useRef(selectedSuggestionId)

  const setSuggestions = (sugg) => {
    suggestionsRef.current = sugg
    setSuggestionsState(sugg)
  }

  const setSelectedSuggestionId = (selId) => {
    selectedSuggestionIdRef.current = selId
    setSelectedSuggestionIdState(selId)
  }

  const resetSearch = () => {
    _inputRef.value = ''
    setSuggestions([])
    setSelectedSuggestionId(null)
  }

  const searchContextValue = useContext(SearchContext)
  const search = searchContextValue.getCurrentListener() || (() => { })

  const navigate = (placeDetails, parcelId) => {
    search(placeDetails, parcelId)
    // _inputRef.value = suggestion.address
    resetSearch()
  }

  const currentRequestNumberRef = useRef(0);
  const latestResolvedRequestNumberRef = useRef(0);

  const suggestionTypes = [
    'exact',
    'close',
    'fuzzy',
  ];

  const getSuggestions = debounce((value) => {
    setIsLoading(true);

    const requestNumber = ++currentRequestNumberRef.current
    const requestsComplete = {}
    console.log('dispatching request ', requestNumber);

    for (const type of suggestionTypes) {
      fetch(`${window.App.routes.gateway}/parcels/v1/parcels/suggest/${type}?query=${value}`, { credentials: 'include' })
        .then(res => res.json())
        .then(data => {
          console.log(`resolving request ${type} `, requestNumber);
          if (requestNumber < latestResolvedRequestNumberRef.current) {
            console.log('discarding outdated response')
            return;
          }

          if (requestNumber > latestResolvedRequestNumberRef.current) {
            console.log(`updating ${data.items.length} suggestions`);
            latestResolvedRequestNumberRef.current = requestNumber
            setSuggestions(data.items)
          } else if (requestNumber === latestResolvedRequestNumberRef.current) {
            console.log(`appending ${data.items.length} suggestions`);
            const combined = uniq([...suggestionsRef.current, ...data.items].sort((a, b) => b.score - a.score), 'id').slice(0, 10);
            console.log('new total suggestions: ', combined.length)
            setSuggestions(combined);
          }

          // make sure any selected item still exists after changing the suggestions
          const selId = selectedSuggestionIdRef.current

          if (selId) {
            const currentIdx = suggestionsRef.current.findIndex((el) => el.id === selId)

            if (currentIdx === -1) {
              setSelectedSuggestionId(null);
            }
          }

          requestsComplete[type] = true

          const exactResults = suggestionsRef.current.filter((i) => i.score === 1.2).length;
          const closeResults = suggestionsRef.current.filter((i) => i.score === 1.1).length;
          const loadingComplete = Object.keys(requestsComplete).length === suggestionTypes.length;

          if (loadingComplete) {
            console.log('loading complete');
            setIsLoading(false);
          }

          if (exactResults === 10 || (requestsComplete.exact && exactResults + closeResults === 10)) {
            console.log('relevant results have been populated')
            setIsLoading(false)
          }
        })
        .catch((e) => {
          console.log('e: ', e);
        })
    }
  }, 450)

  useEffect(() => {
    if (!mapTarget) return

    mapLoader()
      .then(maps => {
        const mapInst = new maps.Map(mapTargetRef)
        const placesService = new maps.places.PlacesService(mapInst)
        placesServiceRef.current = placesService
      })
  }, [mapTarget])

  const [mapTarget, setMapTarget] = useState()
  const placesServiceRef = useRef()

  return (
    <Box
      width='100%'
      maxWidth='560px'
      height={36}
      style={{
        position: 'relative',
        visibility: shouldHide
          ? 'hidden'
          : 'visible'
      }}
      {...props}
    >
      <Box ref={ref => {
        if (!ref) return
        mapLoader()
          .then(maps => {
            const mapInst = new maps.Map(ref)
            const placesService = new maps.places.PlacesService(mapInst)
            placesServiceRef.current = placesService
          })
      }} style={{ display: 'none' }} />
      <Input
        disabled={isSearching}
        width='100%'
        placeholder='Search for a specific property by entering the address'
        ref={_setInputRef}
        pl={0}
        style={{
          paddingLeft: xsDown ? 32 : 48,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          fontSize: xsDown ? '13px' : '14px'
        }}
        onChange={ev => {
          const { value } = ev.target

          setSelectedSuggestionId(null)

          if (value.length < 3) {
            setSuggestions([])
            return
          }

          getSuggestions(value)
        }}
      />

      {/* Search Icon */}
      <Icon
        fontSize={[3, 4]}
        variant='primary'
        style={{
          top: xsDown ? 4 : 2,
          left: xsDown ? 8 : 12,
          position: 'absolute'
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Icon>

      {/* Loader */}
      {(isSearching || isLoading) && (
        <Box
          display='flex'
          px={2}
          variant='primary'
          bg='white'
          alignItems='center'
          style={{ top: 1, right: 1, bottom: 1, position: 'absolute' }}
        >
          <LoadingIcon size={2} />
        </Box>
      )}

      {suggestions.length > 0 && (
        <Box
          border='1px solid #ccc'
          style={{ top: '100%', right: 0, left: 0, position: 'absolute' }}
        >
          {suggestions.map(suggestion => (
            <Box
              key={suggestion.id}
              p={2}
              bg='white'
              borderTop='0 none'
              borderLeft='0 none'
              borderRight='0 none'
              borderBottom='1px solid #ccc'
              backgroundColor={selectedSuggestionId === suggestion.id ? 'rgb(252, 239, 199)' : '#FFF'}
              outline='none'
              width='100%'
              textAlign='left'
              as='button'
              onClick={() => {
                navigate(null, suggestion.id)
              }}
            >
              {suggestion.address}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
})

export default SearchInput

const checkSelectedSuggestion = () => {
  const FOCUSED_SUGGESTION_CLASSNAME = 'pac-item-selected'
  const focusedSuggestionEls = document
    .getElementsByClassName(FOCUSED_SUGGESTION_CLASSNAME)
  return focusedSuggestionEls.length > 0
}
