import * as Sentry from '@sentry/browser'
import { ExtraErrorData } from '@sentry/integrations'

import { getCurrentEnterpriseId } from 'enterprises/selectors'
import { getCurrentUserId } from 'users/selectors'

const SENTRY_DSN = 'https://09a4cce60f394a93a35bcaf49754e133@o361455.ingest.sentry.io/5247946'
const {
  SENTRY_ENVIRONMENT,
  RELEASE_HASH
} = (window.App && window.App.env) || {}

export const initErrorReporter = ({ store }) => {
  Sentry.init({
    integrations: [new ExtraErrorData()],
    dsn: SENTRY_DSN,
    beforeSend: event => {
      const state = store.getState()
      event.tags.enterprise_id = getCurrentEnterpriseId(state)
      event.user = {
        ...event.user,
        id: getCurrentUserId(state)
      }
      return event
    }
  })
  Sentry.configureScope(scope => {
    scope.setTag('environment', SENTRY_ENVIRONMENT || 'UNDEFINED_IN_JS')
    scope.setTag('release_hash', RELEASE_HASH || 'UNDEFINED_IN_JS')
  })
}
