/* eslint "no-prototype-builtins": "warn" */
import { COMMENT_SUCCESS, READ_SUCCESS, COMPLETE_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case READ_SUCCESS:
    case COMMENT_SUCCESS:
    case COMPLETE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.activity) {
        const activitiesObj = action.payload.entities.activity
        const comments = {}
        for (var key in activitiesObj)
          if (activitiesObj.hasOwnProperty(key)) {
            const activity = activitiesObj[key]
            if (activity.type === 'comment')
              comments[activity.id] = activity
          }

        return {
          ...state,
          ...comments
        }
      }
  }
  return state
}
