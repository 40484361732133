import React from 'react'
import { Pill } from '@realsoftworks/decor'
import getEquityType from 'common/util/getEquityType'

const Equity = ({ loanToValue }) => {
  const type = getEquityType(loanToValue)

  return (type === 'OWNED')
    ? (<Pill variant='secondary'>Free and Clear</Pill>)
    : (type === 'UPSIDE_DOWN')
      ? (<Pill variant='neutral'>Upside Down</Pill>)
      : (type === 'HIGH')
        ? (<Pill variant='danger'>High Equity</Pill>)
        : (type === 'LOW')
          ? (<Pill variant='warn'>Low Equity</Pill>)
          : null
}

export default Equity
