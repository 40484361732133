import extractErrorMessage from 'common/extractErrorMessage'
import { Dispatch } from 'redux'
import fetchCoverageApi from './fetchCoverage'

export const FETCH_COVERAGE = 'mls/coverage/FETCH_COVERAGE'
export const FETCH_COVERAGE_SUCCESS = 'mls/coverage/FETCH_COVERAGE_SUCCESS'
export const FETCH_COVERAGE_FAILED = 'mls/coverage/FETCH_COVERAGE_FAILED'

export const fetchCoverage = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: FETCH_COVERAGE })

  return fetchCoverageApi()
    .then(resp => {
      const coverage = resp?.coverage
      if (!Array.isArray(coverage)) {
        const error = extractErrorMessage(resp)
        throw new Error(error)
      }

      return dispatch({ type: FETCH_COVERAGE_SUCCESS, payload: { value: coverage } })
    })
    .catch(error => {
      dispatch({ type: FETCH_COVERAGE_FAILED, payload: { error } })
      throw error
    })
}
