import React from 'react'

class CMAField extends React.Component {
  render () {
    var { label, name, params, onChange } = this.props
    var value = params[this.props.name]

    var child = React.cloneElement(this.props.children, {
      value,
      onChange: function (e) {
        const v = e.target.value
        onChange(name, v)
      }
    })

    return (
      <div className='form-group'>
        <label className='control-label'>{label}</label>
        <div className='field'>
          {child}
        </div>
      </div>
    )
  }
}
export default CMAField
