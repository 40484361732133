/* eslint "eqeqeq": "warn", "no-case-declarations": "warn" */
import { combineReducers } from 'redux'
import {
  FETCH_MESSAGES, FETCH_MESSAGES_SUCCESS,
  FETCH_SITE_MESSAGES, FETCH_SITE_MESSAGES_SUCCESS,
  FETCH_MESSAGES_STATS, FETCH_MESSAGES_STATS_SUCCESS,
  DESTROY_MESSAGE_SUCCESS,
  PROMOTE_MESSAGE_SUCCESS
} from '../actions'

import without from 'lodash/array/without'

const entities = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_MESSAGES_SUCCESS:
    case FETCH_SITE_MESSAGES_SUCCESS:
      return {
        ...state,
        ...payload.entities.sitemessage
      }

    case PROMOTE_MESSAGE_SUCCESS:
      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          leadId: payload.id
        }
      }
    case DESTROY_MESSAGE_SUCCESS:
      return {
        ...state,
        [meta.id]: null
      }
  }
  return state
}

const bySite = (state = {}, { type, meta, payload }) => {
  switch (type) {
    case FETCH_SITE_MESSAGES:

      if (meta.offset !== 0)
        return state

      return {
        ...state,
        [meta.id]: null
      }

    case FETCH_SITE_MESSAGES_SUCCESS:

      return {
        ...state,
        [meta.id]: {
          items: meta.offset == 0 ? payload.result.items : state[meta.id].items.concat(payload.result.items),
          count: payload.result.count
        }
      }

    case DESTROY_MESSAGE_SUCCESS:
      const id = meta.id
      const newstate = {}
      let different = false
      Object.keys(state).forEach(key => {
        const list = state[key]
        const newlist = without(list.items, id)
        const listChanged = list.length !== newlist.length
        different = different || listChanged
        newstate[key] = listChanged ? { items: newlist, count: list.count - 1 } : list
      })

      if (different)
        return newstate
  }
  return state
}

const all = (state = null, { type, payload, meta }) => {
  switch (type) {
    case FETCH_MESSAGES:
      return meta.offset == 0 ? null : state

    case FETCH_MESSAGES_SUCCESS:
      return {
        items: meta.offset == 0 ? payload.result.items : state.items.concat(payload.result.items),
        count: payload.result.count
      }

    case DESTROY_MESSAGE_SUCCESS:
      if (state !== null)
        return {
          ...state,
          items: without(state.items, meta.id),
          count: state.count - 1
        }
  }
  return state
}

const stats = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_MESSAGES_STATS:
      return null

    case FETCH_MESSAGES_STATS_SUCCESS:
      return payload.reduce((ret, v) => {
        ret[v.site_id] = v.count
        return ret
      }, {})
  }

  return state
}

export default combineReducers({
  entities,
  all,
  bySite,
  stats
})
