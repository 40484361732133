import { ParcelFilters } from './parcels.types'

const requireLandLordOnlyForParams = (filters: ParcelFilters): ParcelFilters => ({
  ...filters,
  ownerType: {
    ...filters.ownerType,
    individual: {
      ...filters.ownerType?.individual,
      absentee: {
        inState: true,
        outOfCountry: true,
        outOfState: true
      }
    },
    vacancy: {
      isVacant: false
    }
  }
})

export default requireLandLordOnlyForParams

export const undoLandLordOnlyForParams = (filters: ParcelFilters): ParcelFilters => ({
  ...filters,
  ownerType: {
    ...filters.ownerType,
    individual: {
      ...filters.ownerType?.individual,
      absentee: {
        inState: false,
        outOfCountry: false,
        outOfState: false
      }
    },
    vacancy: undefined
  }
})
