/* eslint "react/jsx-key": "warn", "eqeqeq": "warn", "react/no-string-refs": "warn" */

import React from 'react'
import EditorInput from './form/EditorInput.react'

class EditorList extends React.Component {
  static defaultProps = {
    value: []
  };

  state = {
    open: false,
    editing: -1,
    editorValue: ''
  };

  render () {
    var dk = this.props.displayKey

    return (
      <div className='editor-list'>

        {
          this.props.value.length > 0
            ? [<div className='editor-list-values'>
              {this.props.value.map(function (v, i) {
                return <EditorInput
                  type='text' value={v[dk]}
                  editing={this.state.editing == i}
                  onClick={this.onItemClick.bind(this, i)}
                  onFinish={this.onFinish.bind(this, i)}
                  onChange={this.onChange.bind(this, i)}
                />
              }, this)}
            </div>,

            this.state.open
              ? <input type='text' className='form-control' ref='input' value={this.state.editorValue} onChange={this.onEditorValueChange} onBlur={this.onEditorBlur}/>
              : <a href='#' className='add' onClick={this.onAddClick}>Add another</a>

            ]

            : <EditorInput
              type='text' value=''
              editing={this.state.editing == 0}
              onClick={this.onItemClick.bind(this, 0)}
              onFinish={this.onFinish.bind(this, 0)}
              onChange={this.onChange.bind(this, 0)}
            />

        }
      </div>
    )
  }

  onItemClick = i => {
    this.setState({ editing: i })
  };

  onFinish = () => {
    this.setState({ editing: null })
  };

  onChange = (i, e) => {
    var value = e.target ? e.target.value : e
    var values = [].concat(this.props.value)
    if (i == values.length)
      values.push({})

    values[i][this.props.displayKey] = value

    // Remove value from array if empty
    if (!value) values.splice(i, 1)

    this.props.onChange(values)

    this.onFinish()
  };

  onAddClick = () => {
    this.setState({ open: true }, function () {
      this.refs.input.focus()
    })
  };

  onEditorValueChange = e => {
    this.setState({ editorValue: e.target.value })
  };

  onEditorBlur = e => {
    var v = this.state.editorValue
    this.setState({ open: false, editorValue: '' })
    this.onChange(this.props.value.length, v)
  };
}

export default EditorList
