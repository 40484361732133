/* eslint "eqeqeq": "warn" */
import React from 'react'
import styled from 'styled-components'
import { Box, Text, Caption } from '@realsoftworks/decor'
import themeGet from '@styled-system/theme-get'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ServiceCost from 'marketing/components/ServiceCost'

const OptionActiveIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCheckCircle
}))`
  color: ${themeGet('colors.teal.500')};
`

const OptionIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCircle
}))`
  color: ${themeGet('colors.neutral.500')};
`

const SecondaryText = styled(Text)``

const OptionContainer = styled(Box)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: white;
  color: ${themeGet('colors.neutral.900')};
  border: 2px solid ${themeGet('colors.neutral.500')};
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  transition: background-color 250ms linear, border-color 250ms linear;

  &:hover {
    color: ${themeGet('colors.primary.900')};
    background: ${themeGet('colors.primary.100')};
    border: 2px solid ${themeGet('colors.primary.200')};
  }

  &[data-active=true] {
    background: ${themeGet('colors.primary.100')};
    color: ${themeGet('colors.primary.900')};
    border: 2px solid ${themeGet('colors.primary.300')};

    ${SecondaryText} {
      color: ${themeGet('colors.teal.500')};
    }

    ${OptionActiveIcon} {
      transform: scale(1);
    }
  }

  ${OptionActiveIcon} {
    transform: scale(0);
    transition: transform 250ms linear;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  ${OptionIcon} {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  ${SecondaryText} {
    color: ${themeGet('colors.neutral.500')};
  }
`

const Logo = styled(Box)`
  position: absolute;
  right: 0px;
  top: -200px;
  ${props => props.active && 'top: 0px;'}
`

const Logos = styled(Box)`
  position: absolute;
  z-index: 1;
  opacity: 0.15;
  top: 12px;
  right: 24px;

  img {
    width: 100%;
  }
`

const Option = ({ name, description, provider, active, logoProps = {}, logo, logoActive, ...props }) => (
  <OptionContainer data-active={active} {...props}>
    <Logos>
      <Logo {...logoProps} active={!active}>{logo}</Logo>
      <Logo {...logoProps} active={active}>{logoActive}</Logo>
    </Logos>
    <OptionIcon />
    <OptionActiveIcon />
    <Caption>{name}</Caption>
    <Box my={3}>
      <Box>
        <Text fontSize={3}>
          <SecondaryText>$</SecondaryText> <ServiceCost service={`skiptrace.${provider}`} />
        </Text>
        <SecondaryText fontSize={0}>/ EA</SecondaryText>
      </Box>
      <Box />
    </Box>
    <Box>
      {description}
    </Box>
  </OptionContainer>
)

const OptionButtons = ({ value, onChange, creditPrice, ...props }) => (
  <>
    <Box mb={2}>
      <Text fontSize={4}>Pick Provider</Text>
    </Box>
    <Box display='flex' mx={-3} {...props}>
      <Option
        mx={3}
        flex='1'
        active={value == 'skipGenie'}
        onClick={() => onChange('skipGenie')}
        name='Skip Genie'
        description='Multiple phone numbers and emails for each record. Also returns age/DOB and relative info.'
        provider='skipGenie'
        logo={<img src='https://propelio-cloudfront.s3-us-west-2.amazonaws.com/skip-genie.png' />}
        logoActive={<img src='https://propelio-cloudfront.s3-us-west-2.amazonaws.com/skip-genie-teal.png' />}
        logoProps={{ width: '140px' }}
      />
    </Box>
  </>
)

export default OptionButtons
