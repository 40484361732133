import React, { Fragment, useState, useCallback } from 'react'
import { Card, Box, Text, Button } from '@realsoftworks/decor'
import { getDnsRecordsWithId } from 'sites/reducer/manage/dnsrecords/selectors'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import EditDnsRecord from './EditDnsRecord'
import identity from 'lodash/utility/identity'

const COL_INFOS = [
  { key: 'actions', displayName: '', colProps: { }, colStyle: { width: '120px' } },
  { key: 'type', displayName: 'Type', colProps: {}, colStyle: { width: '120px' } },
  { key: 'name', displayName: 'Name', colProps: {}, colStyle: { width: '120px' } },
  { key: 'value', displayName: 'Value', colProps: { span: '9999' }, colStyle: {} },
  { key: 'ttl', displayName: 'TTL', colProps: {}, colStyle: { width: '80px' } }
]

const VALUE_INFOS = [
  { key: 'value' },
  { key: 'priority' },
  { key: 'service' },
  { key: 'protocol' },
  { key: 'port' },
  { key: 'weight' }
]

const COLS_SUM = COL_INFOS.reduce((acc, col) => acc + col.width, 0)
const BORDER_WIDTH = 2

const withConnect = connect(
  (state, { allowedTypes, disallowedTypes }) => {
    const dnsRecords = getDnsRecordsWithId(state, 'get')
    const whiteListedRecordTypes = allowedTypes
      ? dnsRecords.filter(r => allowedTypes.includes(r.type))
      : dnsRecords
    const finalRecordTypes = disallowedTypes
      ? whiteListedRecordTypes.filter(r => !disallowedTypes.includes(r.type))
      : whiteListedRecordTypes

    return { dnsRecords: finalRecordTypes }
  }
)

const DnsRecordsTable = withConnect(({ domain, dnsRecords, allowedTypes, disallowedTypes, ...props }) =>
  dnsRecords && dnsRecords.length ? (
    <Card
      width='100%'
      borderWidth={BORDER_WIDTH}
      borderColor='neutral.200'
      borderStyle='solid'
      css='overflow: auto;'
      {...props}
    >
      <Box as='table' width='100%' minWidth={COLS_SUM}>
        <colgroup>
          {COL_INFOS.map(({ key, colProps, colStyle }) => (
            <col key={key} span='1' style={colStyle} {...colProps} />
          ))}
        </colgroup>

        <Box as='thead'>
          <Box as='tr'>
            {COL_INFOS.map(({ key, displayName }) => (
              <Text
                as='th'
                p={2}
                key={key}
                bg='neutral.200'
                fontWeight='bold'
                fontSize={0}
              >
                {displayName}
              </Text>
            ))}
          </Box>
        </Box>
        <Box as='tbody'>
          {dnsRecords.map((record, i) => (
            <DnsRecordRow
              domain={domain}
              isEven={!!((i + 1) % 2)}
              key={record.id}
              record={record}
              allowedTypes={allowedTypes}
              disallowedTypes={disallowedTypes}
            />
          ))}
        </Box>
      </Box>
    </Card>
  ) : (
    <Text color='secondary'>
      You have no records
    </Text>
  ))

export default DnsRecordsTable

const DnsRecordRow = connect(state => ({
  allRecords: getDnsRecordsWithId(state, 'get')
}))(({ domain, isEven, allRecords, record, allowedTypes, disallowedTypes }) => {
  const recordIndex = allRecords.indexOf(record)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggleCollapse = useCallback(() =>
    setIsCollapsed(v => !v),
  [setIsCollapsed])

  return (
    <Fragment>
      <Box as='tr'>
        {COL_INFOS.map(({ key, displayFn = v => v }) => (
          <Text
            as='td'
            bg={isEven ? 'neutral.100' : 'white'}
            fontSize={0}
            p={2}
            key={key}
          >
            {key === 'actions' ? (
              <Button onClick={toggleCollapse}>
                <FontAwesomeIcon icon={faEdit} />
                {' '}EDIT
              </Button>
            ) : key === 'type' ? (
              record.type === 'ALIAS' && record.mask === false
                ? 'URL Redirect'
                : record.type
            ) : key === 'value' ? (
              <Box display='flex' flexWrap='wrap'>
                {VALUE_INFOS.map(({ key, displayFn = identity }) =>
                  record[key] ? (
                    <Box px={2}>
                      {displayFn(record[key])}
                    </Box>
                  ) : null
                )}
              </Box>
            ) : displayFn(record[key])}
          </Text>
        ))}
      </Box>

      {!isCollapsed && (
        <Box as='tr'>
          <Box
            as='td'
            p={2}
            bg={isEven ? 'neutral.100' : 'white'}
            colSpan={COL_INFOS.length + 1}
          >
            <EditDnsRecord
              domain={domain}
              recordIndex={recordIndex}
              handleClose={toggleCollapse}
              record={record}
              allowedTypes={allowedTypes}
              disallowedTypes={disallowedTypes}
            />
          </Box>
        </Box>
      )}
    </Fragment>
  )
})
