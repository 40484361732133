/* eslint "react/display-name": "warn" */
import React from 'react'
import styled from 'styled-components'
import { Box } from '@realsoftworks/decor'

const DefaultLayout = styled(Box)`

  @media print {
    margin: 0 !important;
    max-width: auto !important;  
  }
`

export default ({ children, ...props }) => (
  <DefaultLayout mx={[3, 3, 3, 3, 'auto']} my={48} width={['auto', 'auto', 'auto', 'auto', '100%']} maxWidth={1200} {...props}>
    {children}
  </DefaultLayout>
)
