/* eslint "react/jsx-key": "warn", "eqeqeq": "warn" */
import React, { useEffect, useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/object/get'
import PagingToolbar from 'common/PagingToolbar'
import { Numeric } from 'common/format'
import LoadingIcon from 'common/LoadingIcon'
import BigLoader from 'common/BigLoader'
import {
  Tooltip, toast,
  Box,
  Heading,
  Caption,
  Table,
  Text,
  Checkbox
} from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTombstone } from '@fortawesome/pro-regular-svg-icons'
import { useSearchScreenProps } from 'search/SearchContext'

import ProviderIcons from '../../skiptrace/ProviderIcons'

import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'
import { GENERIC_ERROR_NOTIF } from 'const'
import { useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import { noop } from 'lodash'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { useLogError } from 'common/util/hooks/useLogError'

const EmptyMessage = ({ importing }) => (
  <Box mx='auto' p={6}>
    <Heading textAlign='center' color='secondary'>
      {importing ? 'Your import will begin shortly' : 'No Properties here... yet!'}
    </Heading>
  </Box>
)

const formatCityStateZip = ({ city, state, zip }) => {
  var commad = []
  if (city) commad.push(city)
  if (state) commad.push(state)

  var spaced = [commad.join(', ')]
  if (zip) spaced.push(zip)
  return spaced.join(' ')
}

const ContactAddressDisplay = ({ record }) => {
  const { contact, meta } = record
  const { property } = meta

  if (!contact)
    return (
      <Box style={{ position: 'relative' }}>
        <AddressDisplay address={(property || {}).address} />
      </Box>
    )

  return (
    <AddressDisplay address={(contact || {}).address} />
  )
}

const AddressDisplay = ({ address }) => {
  if (!address)
    return '--'

  const { line1, line2, city, state, zip } = address
  return (
    <>
      {line1}
      {line2 && <Text as='div'>{line2}</Text>}
      {(city || state || zip) && <Text as='div' color='secondary'>{formatCityStateZip(address)}</Text>}
    </>
  )
}
AddressDisplay.propTypes = {
  address: PropTypes.object
}

const ContactName = ({ contact, meta }) => {
  const { specificSearch } = useSearchScreenProps()
  const [isLoadingSkiptraceLink, setIsLoadingSkiptraceLink] = useState(false)
  const { line1, city, state, zip } = get(meta, 'property.address', get(contact, 'address', {}))
  const propertyId = get(meta, 'property.id')
  const address = useMemo(() =>
    ({ line1, city, state, zip }),
  [line1, city, state, zip])

  if (!contact) return '--'

  const isSkipped = contact.skiptraces && contact.skiptraces.length > 0
  let { firstName, firstname, lastName, lastname, name } = contact

  if (firstname)
    firstName = firstname

  if (lastname)
    lastName = lastname

  if (name) return name

  const displayName = [firstName, lastName].join(' ').trim('')
  const handleVisitSkiptrace = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsLoadingSkiptraceLink(true)
    specificSearch({ address, propertyId, query: 'tab=skiptrace' })
  }

  return (
    <Box display='flex' alignItems='center'>
      {displayName ? (
        <Box as='a' onClick={handleVisitSkiptrace} alignItems='center' display='flex' style={{ cursor: 'pointer' }}>
          <Text
            as={FontAwesomeIcon}
            icon={faPhoneSquareAlt}
            color={isSkipped ? 'teal.500' : 'red.500'}
            fontSize={4}
          />
          <Box ml={2}>{displayName}</Box>
        </Box>
      ) : 'Unknown'}

      {isLoadingSkiptraceLink && <Box pl={2}><LoadingIcon /></Box>}

      {meta.pendingSkiptrace ? (
        <Box ml={4}>
          <Text color='neutral.300'><LoadingIcon /></Text>
          {' '}<Text color='secondary'>skipping...</Text>
        </Box>
      ) : null}

      {' '}<ProviderIcons ml={2} skiptraces={contact.skiptraces} />
    </Box>
  )
}

const PropertyStats = ({ property: v = {} }) => (
  <>
    <Numeric blank='--'>{v.beds}</Numeric> /{' '}
    <Numeric blank='--'>{v.baths_full}</Numeric>{typeof v.baths_half === 'number' ? '.' : ''}<Numeric>{v.baths_half}</Numeric><br />
    <Numeric blank='--'>{v.sqft}</Numeric> <Text color='secondary'>sqft</Text>, {v.year_built || '--'} <Text color='secondary'>build</Text>
  </>
)

const PhoneNumber = ({ record, ...props }) => (
  <Box {...props}>
    {record.number} {record.type && `(${record.type})`}
  </Box>
)

const Email = ({ value, ...props }) => (
  <Box {...props}>
    {value}
  </Box>
)

const ContactInfo = ({ meta, contact, ...props }) => {
  const isSkipped = !!contact.skiptraceId || (contact.skiptraces && contact.skiptraces.length > 0)
  const { phones, emails, dateOfBirth, dateOfDeath, possibleRelatives } = contact

  if (!isSkipped) return null

  let allStatsIncluded = true

  const skiptraceStats = (contact.skiptraces || []).reduce((stats, skiptrace) => {
    ++stats.total

    if (skiptrace.results) {
      stats.matched += skiptrace.results.matched || 0
      stats.results += skiptrace.results.total || 0
    } else {
      allStatsIncluded = false
    }

    return stats
  }, { matched: 0, results: 0, total: 0 })

  const anyMatched = skiptraceStats.total > 0 &&
    ((phones && phones.length > 0) || (emails && emails.length > 0) || (possibleRelatives && possibleRelatives.length > 0))

  let resultMsg

  if (allStatsIncluded)
    if (skiptraceStats.matched > 0)
      resultMsg = (<Box>{skiptraceStats.matched} of {skiptraceStats.results} skiptrace results matched the contact</Box>)
    else if (skiptraceStats.results > 0)
      resultMsg = (<Box>None of the {skiptraceStats.results} skiptrace results matched this contact</Box>)
    else if (skiptraceStats.total > 0)
      resultMsg = (<Box>None of the skiptraces returned a result</Box>)

  return (
    <Box {...props}>
      <Box>
        {resultMsg}
      </Box>
      <Box mt={3}>
        {(dateOfBirth || dateOfDeath) && <Box>
          <Caption color='secondary' as='div' mb={1}>
          Date of Birth - Death
          </Caption>
          {dateOfBirth} - {dateOfDeath}
        </Box>}
        {anyMatched && <Box display='flex' alignItems='top' flexWrap='wrap' mx={-3}>
          <Box mx={3} my={2}>
            <Caption color='secondary' as='div' mb={1}>Phones:</Caption>
            {(!phones || !phones.length) ? 'none' : phones.map((v, idx) => <PhoneNumber key={idx} record={v} mb={1} />)}

          </Box>
          <Box mx={3} my={2}>
            <Caption color='secondary' as='div' mb={1}>Emails:</Caption>
            {(!emails || !emails.length) ? 'none' : emails.map((v, idx) => <Email key={idx} value={v} mb={1} />)}
          </Box>
        </Box>}
        {(possibleRelatives && possibleRelatives.length) > 0 && (
          <Box my={2}>
            <Caption color='secondary' as='div' mb={1}>Possible Relatives:</Caption>
            <Box>
              {possibleRelatives.map((v, idx) => {
                const { name, age, deceased } = v
                return (
                  <Box key={idx} mb={1}>
                    {name}{age && `, ${age} y/o`}{' '}{deceased && (
                      <Tooltip content='deceased, sadly'>
                        <Text color='primary.500'>
                          <FontAwesomeIcon icon={faTombstone} />
                        </Text>
                      </Tooltip>
                    )}
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>

    </Box>
  )
}

const MaxHeightTableBody = styled(Table.Body)`
  max-height: 500px;
`

const RelativeTable = styled(Table)`
  position: relative
`

const ListMemberTable = ({ memberInfo, importProgress, onChangePage, onRowClick, loading, type, showItemToggle = false, selectedItems = [], onSelectItemToggle = noop, onDeselectAll = noop, onSelectAll = noop, ...props }) => {
  useEffect(() => {
    if (!memberInfo) toast.error(GENERIC_ERROR_NOTIF)
  }, [memberInfo])

  if (!memberInfo) return null

  const { offset, limit, count, items } = memberInfo
  const length = items.length
  const scrollParentRef = useRef()
  const isAllSelected = useMemo(
    () => memberInfo.items.filter(item => selectedItems.indexOf(item.id) !== -1).length === memberInfo.items.length,
    [memberInfo.items, selectedItems]
  )

  useEffect(() => {
    if (!scrollParentRef.current) return
    scrollParentRef.current.scrollTop = 0
  }, [offset])

  const handleCheckboxClick = () => {
    if (isAllSelected)
      onDeselectAll()
    else
      onSelectAll()
  }

  return (
    <>
      <RelativeTable scrolling {...props}>
        <TableLoadingOverlay className={loading ? 'visible' : ''}>
          <BigLoader />
        </TableLoadingOverlay>
        <MemberTableHead type={type} showItemToggle={showItemToggle} isAllSelected={isAllSelected} handleCheckboxClick={handleCheckboxClick} />
        <MaxHeightTableBody ref={scrollParentRef}>
          {
            memberInfo.items.map(row => (
              <MemberTableRow
                key={row.id}
                type={type}
                row={row}
                scrollParentRef={scrollParentRef}
                showItemToggle={showItemToggle}
                isSelected={selectedItems.indexOf(row.id) !== -1}
                onSelectItemToggle={onSelectItemToggle}
              />
            ))
          }
        </MaxHeightTableBody>
        {memberInfo.count > 0 && <PagingToolbar count={count} length={length} offset={offset} limit={limit} onChangePage={onChangePage} />}

      </RelativeTable>
      {memberInfo.count == 0 ? <EmptyMessage importing={importProgress} /> : ''}
    </>
  )
}

const MemberTableHead = ({ type, isAllSelected, showItemToggle, handleCheckboxClick }) => (
  <Table.Head>
    <Table.HeaderCell flex='initial'>
      <Checkbox checked={isAllSelected} onChange={handleCheckboxClick} css={{ alignItems: 'center', visibility: showItemToggle ? 'visible' : 'hidden' }} />
    </Table.HeaderCell>
    {type !== 'contact' &&
      <>
        <Table.HeaderCell>Address</Table.HeaderCell>
        <Table.HeaderCell>Stats</Table.HeaderCell>
      </>}
    <Table.HeaderCell>Contact</Table.HeaderCell>
    <Table.HeaderCell>Mailing Address</Table.HeaderCell>
  </Table.Head>
)

const MemberTableRow = ({ type, row, scrollParentRef, showItemToggle, isSelected, onSelectItemToggle }) => {
  useLogError({
    error: 'row.id is unexpectedly falsy in MemberTableRow',
    shouldLog: !row.id
  }, [!!row.id])

  const { isSyncHovered, hoverRef } = useSyncedIsHoveredWithAutoScroll({
    scrollParentRef,
    memberId: row.id,
    groupId: 'd4d'
  })

  const handleRowClick = event => {
    if (type === 'contact') return

    // Only consider the click event as valid if event target is a child of the row
    // This prevents the bug where clicking on property preview dialog and its overlay
    // would cause the row to toggle its select state.
    const tableRowClass = '.' + Array.from(event.currentTarget.classList).join('.')
    if (event.target.closest(tableRowClass))
      onSelectItemToggle(row.id)
  }

  const handleCheckboxClick = () => {
    onSelectItemToggle(row.id)
  }

  return (
    <Table.Row key={row.id} ref={hoverRef} onClick={handleRowClick}>
      <Table.TextCell flex={0} bg={isSyncHovered ? 'blue.200' : undefined} verticalAlign='left' py={15}>
        <Checkbox checked={isSelected} onChange={handleCheckboxClick} css={{ alignItems: 'flex-start', visibility: showItemToggle ? 'visible' : 'hidden' }} />
      </Table.TextCell>
      {type !== 'contact' &&
        <>
          <Table.TextCell
            verticalAlign='top'
            bg={isSyncHovered ? 'blue.200' : undefined}
          >
            <AddressPreviewLink
              address={get(row, 'meta.property.address')}
              propertyId={get(row, 'meta.property.id')}
            />
          </Table.TextCell>
          <Table.TextCell
            verticalAlign='top'
            bg={isSyncHovered ? 'blue.200' : undefined}
          >
            <PropertyStats property={get(row, 'meta.property')} />
          </Table.TextCell>
        </>}
      <Table.TextCell
        verticalAlign='top'
        bg={isSyncHovered ? 'blue.200' : undefined}
      >
        <ContactName contact={row.contact} meta={row.meta} />
        <ContactInfo contact={row.contact} meta={row.meta} />
      </Table.TextCell>
      <Table.TextCell
        verticalAlign='top'
        bg={isSyncHovered ? 'blue.200' : undefined}
      >
        <ContactAddressDisplay record={row} />
      </Table.TextCell>
    </Table.Row>
  )
}

const TableLoadingOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(98, 93, 82, 0.5);
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
`

export default ListMemberTable
