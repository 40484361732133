import React from 'react'
import { Box } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'

const SkipIcon = ({ success, ...props }) => (
  <Box display='inline-block' width={16} height={16} style={{ position: 'relative' }}>
    <FontIcon
      icon={success ? 'check' : 'question'}
      size='xs'
      style={{
        position: 'absolute',
        top: -3,
        left: success ? -3 : -1
      }}
    />
    <FontIcon
      icon='phone'
      style={{

      }}
    />
  </Box>
)

export default SkipIcon
