import React from 'react'
import moment from 'moment'
import { oneOfType, string, object, oneOf, bool } from 'prop-types'
import { Box, Tooltip, Position } from '@realsoftworks/decor'
import DateTime from './DateTime'

const RelativeDateTime = ({
  children,
  format,
  position = Position.BOTTOM,
  hasTooltip = true,
  hasTime = true
}) => {
  const content = hasTime
    ? moment(children).fromNow()
    : moment(children).calendar(null, NO_TIME_CALENDAR_FORMATS)

  return (
    <Box display='inline-flex' alignItems='center'>
      {hasTooltip
        ? <Tooltip position={position} content={<DateTime format={format}>{children}</DateTime>}>
          <span>{content}</span>
        </Tooltip>
        : content}
    </Box>
  )
}

RelativeDateTime.propTypes = {
  children: oneOfType([string, object]).isRequired,
  format: string,
  position: oneOf([Object.values(Position)]),
  iconProps: object,
  hasTooltip: bool,
  hasTime: bool
}

export default RelativeDateTime

const NO_TIME_CALENDAR_FORMATS = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'MM/DD/YYYY'
}
