import React from 'react'
import { Text } from '@realsoftworks/decor'

const EmptyTableMsg = props => (
  <Text
    p={4}
    display='flex'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
    width='100%'
    height='100%'
    color='secondary'
    {...props}
  />
)

export default EmptyTableMsg
