import qs from 'qs'
import { Schema, arrayOf } from 'normalizr'

import { CALL_API } from '../../middleware/api'
import { TRACK_EVENT } from '../../middleware/analytics'

export const FETCH_BALANCE = 'marketing/fetch_balance'
export const FETCH_BALANCE_SUCCESS = 'marketing/fetch_balance_success'
export const FETCH_BALANCE_FAILED = 'marketing/fetch_balance_failed'

export const fetchBalance = () => ({
  [CALL_API]: {
    url: '/marketing/v2/credits',
    method: 'get',
    types: {
      start: FETCH_BALANCE,
      success: FETCH_BALANCE_SUCCESS,
      error: FETCH_BALANCE_FAILED
    }
  }
})

export const PURCHASE_CREDITS = 'marketing/purchase_credits'
export const PURCHASE_CREDITS_SUCCESS = 'marketing/purchase_credits_success'
export const PURCHASE_CREDITS_FAILED = 'marketing/purchase_credits_failed'

export const purchaseCredits = amount => ({
  [CALL_API]: {
    url: '/marketing/v1/credits',
    method: 'post',
    body: {
      amount
    },
    types: {
      start: PURCHASE_CREDITS,
      success: PURCHASE_CREDITS_SUCCESS,
      error: PURCHASE_CREDITS_FAILED
    }
  },
  [TRACK_EVENT]: {
    name: 'marketing.credits.purchase',
    properties: {
      amount
    }
  }
})

export const UPDATE_REFILL = 'marketing/update_refill'
export const UPDATE_REFILL_SUCCESS = 'marketing/update_refill_success'
export const UPDATE_REFILL_FAILED = 'marketing/update_refill_failed'

export const updateRefillSettings = params => ({
  [CALL_API]: {
    url: '/marketing/v1/refill',
    body: params,
    method: 'post',
    types: {
      start: UPDATE_REFILL,
      success: UPDATE_REFILL_SUCCESS,
      error: UPDATE_REFILL_FAILED
    }
  },
  [TRACK_EVENT]: {
    name: 'marketing.credits.refill.updated',
    properties: params
  }
})

export const DELETE_REFILL = 'marketing/delete_refill'
export const DELETE_REFILL_SUCCESS = 'marketing/delete_refill_success'
export const DELETE_REFILL_FAILED = 'marketing/delete_refill_failed'

export const deleteRefillSettings = () => ({
  [CALL_API]: {
    url: '/marketing/v1/refill',
    method: 'delete',
    parseResponse: false,
    types: {
      start: DELETE_REFILL,
      success: DELETE_REFILL_SUCCESS,
      error: DELETE_REFILL_FAILED
    }
  },
  [TRACK_EVENT]: {
    name: 'marketing.credits.refill.disabled'
  }
})

export const FETCH_ORDERS = 'marketing/fetch_orders'
export const FETCH_ORDERS_SUCCESS = 'marketing/fetch_orders_success'
export const FETCH_ORDERS_FAILED = 'marketing/fetch_orders_failed'

const SchemaOrder = new Schema('order')
const fetchOrderSchema = { items: arrayOf(SchemaOrder) }

export const fetchOrders = (params = {}) => ({
  [CALL_API]: {
    url: `/marketing/v2/orders?${qs.stringify(params)}`,
    method: 'get',
    meta: { params },
    schema: fetchOrderSchema,
    types: {
      start: FETCH_ORDERS,
      success: FETCH_ORDERS_SUCCESS,
      error: FETCH_ORDERS_FAILED
    }
  }
})
