/* eslint "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "react/no-deprecated": "warn" */
'use strict'

import React from 'react'
import createReactClass from 'create-react-class'
import StoreBound from '../../core/StoreBound.js'
import ActivityStore from '../../activity/ActivityStore.js'
import CommentForm from '../CommentForm.react'
import ActivityFeed from './ActivityFeed.react'
import ActivityActions from '../../activity/ActivityActions.js'

var EntityActivity = createReactClass({
  displayName: 'EntityActivity',
  mixins: [StoreBound(ActivityStore)],

  getStateFromStore: function () {
    var activity = ActivityStore.getAll()
    var more = ActivityStore.isMore()

    return {
      activity: activity,
      more: more
    }
  },

  render: function () {
    var mode = null
    if (this.props.id)
      mode = 'view'
    else if (this.props.type)
      mode = 'index'

    var emptyMsg = null
    if (this.state.activity.length == 0)
      emptyMsg = (
        <div className='empty-msg'>
          <p>There's nothing here yet but this will show all your activity soon</p>
        </div>
      )

    return (
      <div>
        {emptyMsg}
        {this.props.allowComment === false ? '' : <CommentForm onAdd={this.onAddComment} />}

        <ActivityFeed activity={this.state.activity} more={this.state.more} mode={mode} />
      </div>
    )
  },

  componentDidMount () {
    ActivityStore.loadData(this.props.type, this.props.id)
  },

  onAddComment: function (text) {
    ActivityActions.comment(text, this.props.type, this.props.id)
  },

  statics: {
    render: function (dom, type, id) {
      ActivityStore.loadData(type, id)
      React.render(
        <EntityActivity type={type} id={id} />,
        dom
      )
    }
  }
})

export default EntityActivity
