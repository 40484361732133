import createFetchReducer from 'common/createFetchReducer'
import { FETCH_COVERAGE, FETCH_COVERAGE_FAILED, FETCH_COVERAGE_SUCCESS } from './coverage.action'

const initStateOverride = {
  isLoading: false
}

const coverage = createFetchReducer(
  FETCH_COVERAGE,
  FETCH_COVERAGE_SUCCESS,
  FETCH_COVERAGE_FAILED,
  initStateOverride
)

export default coverage
