/* eslint "eqeqeq": "warn", "react/jsx-key": "warn", "react/no-deprecated": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import { Monetary } from 'common/format'
import { NumberInput } from '@realsoftworks/decor'

class CMASummary extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      suggestedArv: props.cma.arv ? Number(props.cma.arv) : null,
      selectedSuggestion: props.cma.arvType || 'avg_per_foot'
    }
  }

  static propTypes = {
    cma: PropTypes.object.isRequired,
    subject: PropTypes.object.isRequired
  };

  static defaultProps = {

  };

  render () {
    var { salesstats, selectedSuggestion, suggestedArv, leasestats } = this.state
    var { isSavingArv } = this.props

    var salescontent = ''

    if (salesstats.count > 0) {
      let resultElement = ''
      if (selectedSuggestion == 'custom')
        resultElement = (
          <NumberInput value={suggestedArv} onChange={this.onChangeOverride} width='100%'/>
        )
      else
        resultElement = (
          <div className='calculated'>
            <Monetary decimals={0}>{suggestedArv}</Monetary>
          </div>
        )

      salescontent = (
        <div className='clearfix sales-summary-content'>
          {this.getSummaryStats(salesstats)}

          <div className='suggested-arv'>
            <h4>Suggested ARV</h4>

            <div className='choices'>
              <div className='radio'>
                <label>
                  <input type='radio' name='optionsRadios' value='avg_per_foot' onChange={this.onSuggestionChange} checked={selectedSuggestion == 'avg_per_foot'}/>
                  Average $/sqft
                </label>
              </div>

              <div className='radio'>
                <label>
                  <input type='radio' name='optionsRadios' value='avg_price' onChange={this.onSuggestionChange} checked={selectedSuggestion == 'avg_price'}/>
                  Average Price
                </label>
              </div>

              <div className='radio'>
                <label>
                  <input type='radio' name='optionsRadios' value='custom' onChange={this.onSuggestionChange} checked={selectedSuggestion == 'custom'}/>
                  Override
                </label>
              </div>
            </div>

            <div className='results'>
              {resultElement}
              <div className='btns'>
                <button className='btn btn-success' disabled={isSavingArv} onClick={this.onSaveClick}>{isSavingArv ? [<i className='fa fa-spinner fa-pulse'></i>, ' '] : '' }Save ARV</button>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      salescontent = (
        <div className='empty-msg'>
          <div className='help'>
            <div className='include-btn'></div>
            Pick your comps and we can calculate the ARV
          </div>
        </div>
      )
    }

    var leasecontent = ''
    if (leasestats.count > 0)
      leasecontent = (
        <div className='rent-stats'>
          {this.getSummaryStats(leasestats)}
        </div>
      )
    else
      leasecontent = (
        <div className='empty-msg'>
          <div className='help'>
            <div className='include-btn'></div>
            Pick your comps and we can calculate the estimated rent
          </div>
        </div>
      )

    return (
      <div className='cma-summary clearfix'>
        <div className='sales-summary summary-box'>
          <h3>Sales</h3>
          {salescontent}
        </div>

        <div className='lease-summary summary-box'>
          <h3>Rentals</h3>
          {leasecontent}
        </div>

      </div>
    )
  }

  getCalculatedStats = (cma, listname) => {
    var { states, data } = cma
    var list = data[listname]

    data = Array.prototype.reduce.call(list, (data, comp) => {
      if (states[comp.key] == 'included') {
        data.count++;

        ['sqft', 'beds', 'baths_full', 'baths_half', 'garage', 'year_built'].forEach(field => {
          data[field] = data[field] || 0
          data[field] += comp[field]
        })

        var price = comp.close_price || comp.list_price

        data.price += price

        data.dollars_per_foot = data.dollars_per_foot || 0
        data.dollars_per_foot += (price / comp.sqft)
      }
      return data
    }, { count: 0, price: 0 })

    return data
  };

  onSuggestionChange = e => {
    var selectedSuggestion = e.target.value

    var suggestedArv = this.calculateSuggestion(selectedSuggestion, this.state.salesstats)

    this.setState({ selectedSuggestion, suggestedArv })
  };

  componentWillMount () {
    if (this.props.cma)
      this.recompute(this.props.cma)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.cma)
      this.recompute(nextProps.cma)
  }

  recompute = cma => {
    var salesstats = this.getCalculatedStats(cma, 'sales')
    var leasestats = this.getCalculatedStats(cma, 'leases')
    var { selectedSuggestion, suggestedArv } = this.state

    suggestedArv = this.calculateSuggestion(selectedSuggestion, salesstats)
    suggestedArv = Math.round(suggestedArv)

    this.setState({
      salesstats, suggestedArv, leasestats
    })
  };

  calculateSuggestion = (selectedSuggestion, stats) => {
    let suggestedArv = this.state.suggestedArv

    switch (selectedSuggestion) {
      case 'avg_per_foot':
        var dollarsPerFoot = Math.round(stats.dollars_per_foot / stats.count * 100) / 100
        suggestedArv = dollarsPerFoot * this.props.subject.sqft
        break

      case 'avg_price':
        suggestedArv = stats.price / stats.count
        break
    }

    if (!Number.isFinite(suggestedArv))
      suggestedArv = 0

    return suggestedArv
  };

  onChangeOverride = suggestedArv => {
    if (suggestedArv.target)
      suggestedArv = suggestedArv.target.value

    this.setState({ suggestedArv })
  };

  onSaveClick = () => {
    this.props.onSaveArv(this.state.suggestedArv, this.state.selectedSuggestion)
  };

  getSummaryStats = stats => {
    const averagePrice = stats.price / stats.count
    const averagePerFoot = stats.dollars_per_foot / stats.count

    let fAveragePrice = '--'
    let fAveragePerFoot = '--'

    if (Number.isFinite(averagePrice))
      fAveragePrice = <Monetary decimals={0}>{averagePrice}</Monetary>

    if (Number.isFinite(averagePerFoot))
      fAveragePerFoot = <Monetary decimals={2}>{averagePerFoot}</Monetary>

    return (
      <div className='summary-stats'>
        <div className='stat'>
          <label htmlFor=''>Included Comps</label>
          <div className='value'>{stats.count}</div>
        </div>
        <div className='stat'>
          <label htmlFor=''>Average $/sqft</label>
          <div className='value'>{fAveragePerFoot}</div>
        </div>
        <div className='stat'>
          <label htmlFor=''>Average Price</label>
          <div className='value'>{fAveragePrice}</div>
        </div>
      </div>
    )
  };
}

export default CMASummary
