import get from 'lodash/object/get'

const selectCma = (state, cmaId) =>
  get(state, ['entities', 'cma', cmaId])

export const selectCompSavedState = (state, cmaId, compId) =>
  get(selectCma(state, cmaId), ['savedStates', compId])

export const selectCompState = (state, cmaId, compId) =>
  get(selectCma(state, cmaId), ['states', compId])

export const selectCompStates = (state, cmaId) =>
  get(selectCma(state, cmaId), ['states'])

export const selectComp = (state, cmaId, compId) => {
  const compGroups = get(selectCma(state, cmaId), ['data'])
  const comps = [...compGroups?.sales || [], ...compGroups?.leases || []]
  const comp = comps.find(c => c.key === compId)
  return comp
}
