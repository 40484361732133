
const cache = {}

export default function ({ id, extractValue, extractLabel, filter, limit }) {
  if (!cache[id])
    cache[id] = {
      records: {},
      filter: async filters => {
        const result = await filter(filters)
        result.forEach(v => {
          cache[id].records[extractValue(v)] = v
        })
        return result
      },
      get: recordId => cache[id].records[recordId],
      extractValue,
      extractLabel,
      limit
    }

  return cache[id]
}
