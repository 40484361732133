import { toast } from '@realsoftworks/decor'

let intercomPromise

const waitIntercom = () => {
  if (intercomPromise) return intercomPromise

  // Wait for Intercom to boot (max 30 seconds)
  intercomPromise = new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      clearInterval(interval)
      reject(new Error('Intercom took too long too load.'))
    }, 30000)

    const interval = setInterval(() => {
      if (window.Intercom && window.Intercom.booted) {
        resolve(window.Intercom)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  })

  return intercomPromise
}

export const INTERCOM_ERROR_NOTIF = {
  title: 'Failed to open Customer Support',
  content: 'Sorry, Customer Support failed to load. Please try again in few seconds or try refreshing the page.'
}

const waitAndOpenIntercom = () =>
  waitIntercom()
    .then(() => window.Intercom('show'))
    .catch(() => toast.error(INTERCOM_ERROR_NOTIF))

export default waitAndOpenIntercom
