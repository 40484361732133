import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Box, Caption, Text } from '@realsoftworks/decor'
import { getCurrent } from 'users/selectors'
import { connect } from 'react-redux'
import useBooleanCookie from 'common/util/hooks/useBooleanCookie'

const LegendContainer = styled(Box)`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 5%;
    left: 5%;
    padding: 0 10px;
    min-width: 110px;
`
const LegendItem = styled(Box)`
    display: flex;
    align-items: center;
    margin: 2px 0px;
`
const withConnect = connect(state =>
  ({ userId: (getCurrent(state) || {}).id }))
const Legend = withConnect(({ userId, containerProps = {} }) => {
  const cookieKey = `${userId}:isPropertyDataLegendOpen`
  const [show, setShow] = useBooleanCookie(cookieKey, true)

  if (!show)
    return (
      <LegendContainer onClick={() => setShow(true)} {...containerProps}>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Caption my={2}>Legend</Caption>
          <FontAwesomeIcon icon={faAngleUp} />
        </Box>
      </LegendContainer>
    )

  return (
  // hardcoded
    <LegendContainer onClick={() => setShow(false)} {...containerProps}>
      <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
        <Caption my={2}>Legend</Caption>
        <FontAwesomeIcon icon={faAngleDown} />
      </Box>
      <Box
        mx={-1}
        width='400px'
        display='flex'
        alignItems='center'
        flexWrap='wrap'
      >
        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" viewBox='0 0 26 26' xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12.5" fill="#FCEFC7" stroke="#E9B949"/>
                <path fill="#c99a2e" d="M14.48 14.867a.404.404 0 01-.404.405H11.65a.404.404 0 01-.405-.405v-1.213H6.39v3.64c0 .648.566 1.214 1.214 1.214h10.518c.647 0 1.213-.566 1.213-1.213v-3.641h-4.854zm3.642-5.259h-2.023V8.395c0-.648-.567-1.214-1.214-1.214H10.84c-.647 0-1.214.566-1.214 1.214v1.213H7.604c-.648 0-1.214.567-1.214 1.214v2.023h12.945v-2.023c0-.647-.566-1.214-1.213-1.214zm-3.641 0h-3.237V8.8h3.237z"/>
              </svg>
            </Box>
            <Text fontSize='15px'>In State Absentee</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='30' height='30' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 121">
              <path stroke-width="14" stroke="#1e9600" fill="none" d="M44.302 74.896L10.345 71.32c-5.562-30.4 24.275-60.83 54.738-61.005 26.783-.207 56.544 29.796 51.8 60.328l-32.51 4.28"/>
              <path stroke-width="4" stroke="#a19c91" fill="#d1ccc2" d="M114 68c0 27.613-22.387 50-50 50S14 95.613 14 68s22.387-50 50-50 50 22.387 50 50zm0 0"/>
              </svg>
            </Box>
            <Text fontSize='15px'>Free and Clear</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" viewBox='0 0 26 26' xmlns="http://www.w3.org/2000/svg">
                <circle id="svg_1" stroke="#E9B949" fill="#FCEFC7" r="12.5" cy="13" cx="13"/>
                <path id="svg_2" fill="#C99A2E" d="m19.591735,11.407633l-3.44459,0l-3.16762,-4.67816a0.48265,0.40729 0 0 0 -0.41893,-0.20499l-1.97411,0c-0.32038,0 -0.55154,0.25866 -0.46354,0.51858l1.47772,4.36457l-3.1001,0l-1.30201,-1.46495c-0.09102,-0.10249 -0.23418,-0.16277 -0.38578,-0.16277l-1.20526,0c-0.31375,0 -0.54401,0.24874 -0.46776,0.50561l0.94968,2.74982l-0.94968,2.74982c-0.07625,0.25688 0.15401,0.50561 0.46776,0.50561l1.20526,0c0.1519,0 0.29476,-0.06027 0.38578,-0.16277l1.30201,-1.46494l3.1001,0l-1.47772,4.36431c-0.088,0.25993 0.14316,0.51883 0.46354,0.51883l1.97411,0c0.173,0 0.33273,-0.07833 0.41863,-0.20499l3.16792,-4.67815l3.44459,0c1.06541,0 2.89335,-0.72866 2.89335,-1.62771s-1.82794,-1.62772 -2.89335,-1.62772z"/>
              </svg>
            </Box>
            <Text fontSize='15px'>Out of State/Country</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M17.2787 3.27699C13.2288 2.51118 2.99455 6.85672 3.41773 17.0558L6.11761 17.5663' stroke='url(#paint0_linear)' strokeWidth='6' />
                <circle cx='16' cy='16' r='12.5' fill='#D3CEC4' stroke='#A39E93' />
                <defs>
                  <linearGradient id='paint0_linear' x1='17.921' y1='4.16104' x2='2.29666' y2='15.5128' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#1E9600' />
                    <stop offset='0.510417' stopColor='#FFF200' />
                    <stop offset='1' stopColor='#FF0000' />
                    <stop offset='1' stopColor='#FF0000' />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
            <Text fontSize='15px'>High Equity</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='13' cy='13' r='12.5' fill='#C9F5F8' stroke='#2B959C' />
                <path d='M13 13C14.8938 13 16.4286 11.4332 16.4286 9.5C16.4286 7.5668 14.8938 6 13 6C11.1063 6 9.57143 7.5668 9.57143 9.5C9.57143 11.4332 11.1063 13 13 13ZM15.4 13.875H14.9527C14.358 14.1539 13.6964 14.3125 13 14.3125C12.3036 14.3125 11.6446 14.1539 11.0473 13.875H10.6C8.6125 13.875 7 15.5211 7 17.55V18.6875C7 19.4121 7.57589 20 8.28571 20H17.7143C18.4241 20 19 19.4121 19 18.6875V17.55C19 15.5211 17.3875 13.875 15.4 13.875Z' fill='#1F878E' />
              </svg>
            </Box>
            <Text fontSize='15px'>Owner Occ.</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='30' height='26' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M8.59668 5.5063C4.46275 6.46067 2.18445 13.8809 5.7646 16.9111L8.59258 16.8617' stroke='url(#paint0_linear)' strokeWidth='6' />
                <circle cx='17' cy='13' r='12.5' fill='#D3CEC4' stroke='#A39E93' />
                <defs>
                  <linearGradient id='paint0_linear' x1='18.6331' y1='0.839708' x2='4.39262' y2='15.5862' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#1E9600' />
                    <stop offset='0.510417' stopColor='#FFF200' />
                    <stop offset='1' stopColor='#FF0000' />
                    <stop offset='1' stopColor='#FF0000' />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
            <Text fontSize='15px'>Low Equity</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='13' cy='13' r='12.5' fill='#F29B9B' stroke='#BA2525' />
                <path d='M20 8.80001V9.26667C20 9.32856 19.9754 9.38791 19.9317 9.43167C19.8879 9.47543 19.8286 9.50001 19.7667 9.50001H19.0667V9.85001C19.0667 10.0433 18.91 10.2 18.7167 10.2H7.28333C7.09005 10.2 6.93333 10.0433 6.93333 9.85001V9.50001H6.23333C6.17145 9.50001 6.1121 9.47543 6.06834 9.43167C6.02458 9.38791 6 9.32856 6 9.26667V8.80001C6 8.75385 6.01369 8.70872 6.03935 8.67034C6.065 8.63197 6.10146 8.60206 6.14411 8.58441L12.9108 6.01774C12.9679 5.99409 13.0321 5.99409 13.0892 6.01774L19.8559 8.58441C19.8985 8.60206 19.935 8.63197 19.9607 8.67034C19.9863 8.70872 20 8.75385 20 8.80001ZM19.3 17.6667H6.7C6.3134 17.6667 6 17.9801 6 18.3667V18.8333C6 18.8952 6.02458 18.9546 6.06834 18.9983C6.1121 19.0421 6.17145 19.0667 6.23333 19.0667H19.7667C19.8286 19.0667 19.8879 19.0421 19.9317 18.9983C19.9754 18.9546 20 18.8952 20 18.8333V18.3667C20 17.9801 19.6866 17.6667 19.3 17.6667ZM8.33333 10.6667V16.2667H7.28333C7.09005 16.2667 6.93333 16.4234 6.93333 16.6167V17.2H19.0667V16.6167C19.0667 16.4234 18.91 16.2667 18.7167 16.2667H17.6667V10.6667H15.8V16.2667H13.9333V10.6667H12.0667V16.2667H10.2V10.6667H8.33333Z' fill='#A61B1B' />
              </svg>
            </Box>
            <Text fontSize='15px'>Bank Owned</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M10.9146 23.9103C7.91634 24.685 2.63145 19.4368 3.68728 16.4624' stroke='#A61B1B' strokeWidth='6' />
                <circle cx='14' cy='13' r='12.5' fill='#D3CEC4' stroke='#A39E93' />
              </svg>
            </Box>
            <Text fontSize='15px'>Upside Down</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
                <circle stroke="#fd7e14" fill="#ffd8a8" r="12.5" cy="13" cx="13"/>
                <path stroke="null" d="m18.26641,18.704437l-0.51995,0l0,-11.85495c0,-0.3446 -0.27934,-0.62395 -0.62394,-0.62395l-8.73523,0c-0.3446,0 -0.62395,0.27935 -0.62395,0.62395l0,11.85495l-0.51995,0c-0.17229,0 -0.31198,0.13969 -0.31198,0.31198l0,0.51995l11.64698,0l0,-0.51995c0,-0.17229 -0.13969,-0.31198 -0.31198,-0.31198zm-8.00729,-10.50307c0,-0.17229 0.13969,-0.31197 0.31197,-0.31197l1.03991,0c0.17229,0 0.31197,0.13968 0.31197,0.31197l0,1.03991c0,0.17229 -0.13968,0.31197 -0.31197,0.31197l-1.03991,0c-0.17228,0 -0.31197,-0.13968 -0.31197,-0.31197l0,-1.03991zm0,2.49578c0,-0.17229 0.13969,-0.31197 0.31197,-0.31197l1.03991,0c0.17229,0 0.31197,0.13968 0.31197,0.31197l0,1.03991c0,0.17229 -0.13968,0.31197 -0.31197,0.31197l-1.03991,0c-0.17228,0 -0.31197,-0.13968 -0.31197,-0.31197l0,-1.03991zm1.35188,3.84766l-1.03991,0c-0.17228,0 -0.31197,-0.13968 -0.31197,-0.31197l0,-1.03991c0,-0.17229 0.13969,-0.31197 0.31197,-0.31197l1.03991,0c0.17229,0 0.31197,0.13968 0.31197,0.31197l0,1.03991c0,0.17229 -0.13968,0.31197 -0.31197,0.31197zm1.97583,4.15963l-1.66386,0l0,-2.1838c0,-0.17229 0.13969,-0.31198 0.31198,-0.31198l1.03991,0c0.17228,0 0.31197,0.13969 0.31197,0.31198l0,2.1838zm1.66385,-4.4716c0,0.17228 -0.13969,0.31197 -0.31197,0.31197l-1.03991,0c-0.17229,0 -0.31197,-0.13969 -0.31197,-0.31197l0,-1.03991c0,-0.17229 0.13968,-0.31197 0.31197,-0.31197l1.03991,0c0.17228,0 0.31197,0.13968 0.31197,0.31197l0,1.03991zm0,-2.49578c0,0.17228 -0.13969,0.31197 -0.31197,0.31197l-1.03991,0c-0.17229,0 -0.31197,-0.13969 -0.31197,-0.31197l0,-1.03991c0,-0.17229 0.13968,-0.31197 0.31197,-0.31197l1.03991,0c0.17228,0 0.31197,0.13968 0.31197,0.31197l0,1.03991zm0,-2.49578c0,0.17228 -0.13969,0.31197 -0.31197,0.31197l-1.03991,0c-0.17229,0 -0.31197,-0.13969 -0.31197,-0.31197l0,-1.03991c0,-0.17229 0.13968,-0.31197 0.31197,-0.31197l1.03991,0c0.17228,0 0.31197,0.13968 0.31197,0.31197l0,1.03991z" fill="#fd7e14"/>
              </svg>
            </Box>
            <Text fontSize='15px'>Corporate</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <circle id="svg_2" stroke="#A39E93" fill="#D3CEC4" r="12.5" cy="13.062529" cx="13.062478"/>
                <text font-weight="bold" xmlSpace="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="16" id="svg_3" y="25.464936" x="15.906273" stroke-opacity="null" stroke-width="0" stroke="#A39E93" fill="#d64545">F</text>
              </svg>
            </Box>
            <Text fontSize='15px'>Foreclosure</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='13' cy='13' r='12.5' fill='#A7D8F0' stroke='#3994C1' />
                <path d='M14.125 9.71875V6H8.65625C8.29258 6 8 6.29258 8 6.65625V19.3438C8 19.7074 8.29258 20 8.65625 20H17.8438C18.2074 20 18.5 19.7074 18.5 19.3438V10.375H14.7812C14.4203 10.375 14.125 10.0797 14.125 9.71875ZM15.875 16.1719C15.875 16.3523 15.7273 16.5 15.5469 16.5H10.9531C10.7727 16.5 10.625 16.3523 10.625 16.1719V15.9531C10.625 15.7727 10.7727 15.625 10.9531 15.625H15.5469C15.7273 15.625 15.875 15.7727 15.875 15.9531V16.1719ZM15.875 14.4219C15.875 14.6023 15.7273 14.75 15.5469 14.75H10.9531C10.7727 14.75 10.625 14.6023 10.625 14.4219V14.2031C10.625 14.0227 10.7727 13.875 10.9531 13.875H15.5469C15.7273 13.875 15.875 14.0227 15.875 14.2031V14.4219ZM15.875 12.4531V12.6719C15.875 12.8523 15.7273 13 15.5469 13H10.9531C10.7727 13 10.625 12.8523 10.625 12.6719V12.4531C10.625 12.2727 10.7727 12.125 10.9531 12.125H15.5469C15.7273 12.125 15.875 12.2727 15.875 12.4531ZM18.5 9.3332V9.5H15V6H15.1668C15.3418 6 15.5086 6.06836 15.6316 6.19141L18.3086 8.87109C18.4316 8.99414 18.5 9.16094 18.5 9.3332Z' fill='#2D83AE' />
              </svg>
            </Box>
            <Text fontSize='15px'>Trust</Text>
          </LegendItem>
        </Box>
        <Box px={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <circle id="svg_2" stroke="#A39E93" fill="#D3CEC4" r="12.5" cy="13.062529" cx="13.062478"/>
                <text font-weight="bold" xmlSpace="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="16" id="svg_3" y="25.464936" x="14.968766" stroke-opacity="null" stroke-width="0" stroke="#A39E93" fill="#d64545">V</text>
              </svg>
            </Box>
            <Text fontSize='15px'>Vacant</Text>
          </LegendItem>
        </Box>

        {/* Row */}
        <Box px={1} pb={1} flex='1 0 140px'>
          <LegendItem>
            <Box pr={2}>
              <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx="13" cy="13" r="12.5" fill="#a5b646" stroke="#656f2b"/>
                <path fill="#656f2b" d="M18.492 9.113l-5.155-2.148a1.293 1.293 0 00-.991 0L7.19 9.113c-.48.198-.794.668-.794 1.19 0 5.33 3.074 9.013 5.947 10.21.317.132.674.132.99 0 2.302-.958 5.95-4.269 5.95-10.21 0-.522-.313-.992-.791-1.19zm-5.65 9.736l-.002-10.23 4.723 1.968c-.089 4.065-2.205 7.01-4.72 8.262z"/>
              </svg>
            </Box>
            <Text fontSize='15px'>Military</Text>
          </LegendItem>
        </Box>
        <Box px={1} pb={1} flex='1 0 160px'>
          <LegendItem>
            <Box pr={2}>
              <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <circle id="svg_2" stroke="#A39E93" fill="#D3CEC4" r="12.5" cy="13.062529" cx="13.062478"/>
                <text font-weight="bold" xmlSpace="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="16" id="svg_3" y="25.402436" x="16.718779" stroke-opacity="null" stroke-width="0" stroke="#A39E93" fill="#d64545">2</text>
              </svg>
            </Box>
            <Text fontSize='15px'>Multiple</Text>
          </LegendItem>
        </Box>
      </Box>
    </LegendContainer>
  )
})

export default Legend
