import React from 'react'
import { Box } from '@realsoftworks/decor'
import Toolbar from '../../ResultToolbar'
import LenderCard from './LenderCard'
import EmptyMessage from '../../EmptyMessage'
import PagingToolbar from 'common/PagingToolbar'

export default function LenderResult ({ data, loading, counts, onSaveClick, paging, onChangePage, onChangeMode }) {
  const onEmptyMessageClick = React.useCallback(() => {
    onChangeMode('criteria')
  }, [])

  return (

    <Box maxWidth={680}>
      {data.length > 0 &&
        <Box>
          <Toolbar count={counts.total} onSaveClick={onSaveClick} onChangeMode={onChangeMode} mb={5} />
          {data.map(d => <LenderCard key={d.id} contact={d} mb={5} />)}
          {paging && <PagingToolbar {...paging} loading={loading} count={counts.total} onChangePage={onChangePage} />}
        </Box>}
      {!data.length && <EmptyMessage onClick={onEmptyMessageClick} />}
    </Box>
  )
}
