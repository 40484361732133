/* eslint "no-class-assign": "warn" */
import React from 'react'

import { connect } from 'react-redux'

import BigLoader from 'common/BigLoader'

import BrokerRequirements from './BrokerRequirements'

import { actions as UserActions, selectors as UserSelectors } from 'users'
import * as MLSSelectors from '../../selectors'
import * as actions from '../../actions'

class MLSTerms extends React.Component {
  render () {
    var { requirements, source, email, loading } = this.props
    var { brokers } = requirements

    var broker = brokers[0]

    return (
      <BigLoader loading={loading}>
        <BrokerRequirements
          broker={broker}
          email={email}
          source={source}
          user={requirements.user}
          sendConfirmEmail={this.props.sendConfirmEmail}
          acceptMLSTerms={this.props.acceptMLSTerms}
          acceptBrokerTerms={this.props.acceptBrokerTerms}
          checkConfirmEmail={this.props.checkConfirmEmail}
          startAuth={this.props.startAuth}
          cancelAuth={this.props.cancelAuth}
        />
      </BigLoader>
    )
  }
}

function mapStateToProps (state, props) {
  const { source } = props
  const { email } = UserSelectors.getCurrent(state)

  var { user, brokers } = MLSSelectors.getRequirements(state, source)

  var requirements = {
    user, source, brokers
  }

  return {
    email,
    requirements,
    loading: MLSSelectors.isAuthorizing(state, source)
  }
}

MLSTerms = connect(mapStateToProps, {
  signDocument: actions.signDocument,
  sendConfirmEmail: UserActions.sendConfirmEmail,
  acceptMLSTerms: actions.acceptTermsOfService,
  acceptBrokerTerms: actions.acceptBrokerTerms,
  checkConfirmEmail: UserActions.checkConfirmEmail,
  startAuth: actions.startAuth,
  cancelAuth: actions.cancelAuth
})(MLSTerms)

export default MLSTerms

let TermsComponent = ({ canAccess, children, ...rest }) => (
  !canAccess ? <MLSTerms {...rest} /> : children
)

TermsComponent = connect((state, { source }) => ({
  canAccess: MLSSelectors.canAccess(state, source)
}))(TermsComponent)

export { TermsComponent }
