import { get } from 'lodash'
import { createSelector } from 'reselect'

export const selectProperties = (state): object =>
  get(state, ['propertypreviews', 'byId'])
const selectPropertyId = (state, propId) => propId
export const selectProperty: (object, string) => any = createSelector(
  [selectProperties, selectPropertyId],
  (properties, propId) => properties[propId]
)
