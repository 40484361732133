/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import BigFatalError from 'common/BigFatalError'
import { FontIcon } from '../../../views/common'
import BigLoader from 'common/BigLoader'
import { Crumb } from '../../../views/common/Breadcrumbs'

import { fetchAll } from '../actions'
import mls from '../../mls'

import DealAlertList from './searches/DealAlertList'

class DealAlertsIndexHandler extends Component {
  componentWillMount () {
    this.props.dispatch(fetchAll())
    this.props.dispatch(mls.actions.fetchMetadata())
  }

  render () {
    const { error, loading } = this.props

    let child = null
    if (loading)
      child = <BigLoader />
    else if (error)
      child = <BigFatalError />
    else
      child = <DealAlertList {...this.props} />

    return (
      <div className='deal-alert-index'>
        <Crumb
          tools={
            <Link key='settings-link' to='/dealalerts/add' className='btn'>
              <FontIcon className='fa fa-plus' /> Create Alert
            </Link>
          }
        />
        {child}
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { list } = state.dealalerts
  const items = list.value.map(id => state.entities.dealalerts[id])

  const { metadata } = state.mls
  const { byId } = metadata

  var error = list.error || metadata.error

  var loading = list.loading || metadata.loading

  return { loading, error, items, markets: byId }
}

DealAlertsIndexHandler = connect(mapStateToProps)(DealAlertsIndexHandler)

export default DealAlertsIndexHandler
