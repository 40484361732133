import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Label, Select, Option, Box, Input, Text, NumberInput } from '@realsoftworks/decor'
import pick from 'lodash/object/pick'
import get from 'lodash/object/get'

const RECORD_TYPES = ['ALIAS', 'A', 'AAAA', 'CNAME', 'MX', 'SRV', 'TXT', 'URL Redirect']
const COMMON_FIELDS = ['type', 'name', 'value', 'ttl']

const DnsRecordForm = ({
  record,
  footer = null,
  onSubmit = () => {},
  allowedTypes,
  disallowedTypes
}) => {
  const rhf = useForm({ defaultValues: record })
  const { watch } = rhf
  const handleSubmit = rhf.handleSubmit(data => {
    const commonFields = pick(data, COMMON_FIELDS)
    let specialFields

    if (!commonFields.name) commonFields.name = '@'

    let srvName = data.name.trim() === '@' ? '' : data.name

    switch (data.type) {
      case 'ALIAS':
        specialFields = {
          mask: true
        }
        break
      case 'URL Redirect':
        specialFields = {
          type: 'ALIAS',
          mask: false
        }
        break
      case 'SRV':

        if (data.service && data.protocol) {
          // remove any leading underscores if they were provided
          const service = data.service.replace(/^_/, '')
          const protocol = data.protocol.replace(/^_/, '')

          srvName = `_${service}._${protocol}${srvName ? '.' + srvName : ''}`
        }
        specialFields = {
          name: srvName,
          priority: data.priority,
          weight: data.weight,
          port: data.port
        }
        break
      case 'MX':
        specialFields = {
          priority: data.priority
        }
        break
      default:
        specialFields = {}
    }

    const finalData = {
      ...commonFields,
      ...specialFields
    }

    onSubmit(finalData)
  })

  const isURLRedir = record?.type === 'ALIAS' && record?.mask === false
  useEffect(() => {
    if (isURLRedir) rhf.setValue('type', 'URL Redirect')
  }, [])

  const whiteListedRecordTypes = allowedTypes
    ? RECORD_TYPES.filter(t => allowedTypes.includes(t))
    : RECORD_TYPES
  const finalRecordTypes = disallowedTypes
    ? whiteListedRecordTypes.filter(t => !disallowedTypes.includes(t))
    : whiteListedRecordTypes

  const type = finalRecordTypes.length === 1 ? finalRecordTypes[0] : watch('type')
  const isSrv = type === 'SRV'
  const isSrvOrMx = isSrv || type === 'MX'

  return (
    <Box
      as='form'
      mx={-3}
      display='flex'
      onSubmit={handleSubmit}
      flexWrap='wrap'
      alignItems='flex-start'
    >
      <FieldSection
        defaultValue={finalRecordTypes.length === 1 ? finalRecordTypes[0] : undefined}
        required
        name='type'
        displayName='Type'
        rhf={rhf}
        onChange={pass2ndElAsVal}
        render={({ value, onChange }) => (
          <Select width='100%' value={value} onChange={ev => onChange(ev.target.value)}>
            {finalRecordTypes.map(t => (
              <Option key={t} value={t}>{t}</Option>
            ))}
          </Select>
        )}
      />

      <FieldSection
        name='name'
        displayName='Name'
        rhf={rhf}
      />

      <FieldSection
        required
        name='value'
        displayName='Value'
        rhf={rhf}
      />

      <FieldSection
        required
        name='ttl'
        displayName='TTL'
        defaultValue='600'
        rhf={rhf}
        as={NumberInput}
        thousandSeparator=''
        placeholder='No. of Seconds'
        precision={0}
      />

      {isSrvOrMx && (
        <FieldSection
          required
          name='priority'
          displayName='Priority'
          rhf={rhf}
          as={NumberInput}
          thousandSeparator=''
          precision={0}
        />)}

      {isSrv && (
        <FieldSection
          name='service'
          displayName='Service'
          rhf={rhf}
        />)}

      {isSrv && (
        <FieldSection
          name='protocol'
          displayName='Protocol'
          rhf={rhf}
        />)}

      {isSrv && (
        <FieldSection
          required
          name='port'
          displayName='Port'
          rhf={rhf}
          as={NumberInput}
          thousandSeparator=''
          precision={0}
        />)}

      {isSrv && (
        <FieldSection
          required
          name='weight'
          displayName='Weight'
          rhf={rhf}
          as={NumberInput}
          thousandSeparator=''
          precision={0}
        />)}

      {footer && (
        <Box p={3} flex='1 1 100%' display='flex' justifyContent='flex-end'>
          {footer}
        </Box>
      )}
    </Box>
  )
}

export default DnsRecordForm

const FieldSection = ({
  name,
  displayName,
  required,
  rhf,
  as = Input,
  render,
  ...props
}) => {
  const error = get(rhf, `errors.${name}.message`)
  return (
    <Box
      p={3}
      flex='1 1 25%'
      minWidth='240px'
      display='flex'
      alignItems='center'
      flexWrap='wrap'
    >
      <Label flex='0 0 80px' pb={1}>
        {displayName}
        {required && <Text color='red.500'>*</Text>}
      </Label>
      <Controller
        as={render ? undefined : as}
        render={render}
        name={name}
        defaultValue=''
        control={rhf.control}
        rules={required ? { required: `${displayName} is required.` } : {}}
        width='100%'
        {...props}
      />
      {error && (<Text pt={1} flex='1 1 100%' color='red.500'>{error}</Text>)}
    </Box>
  )
}

const pass2ndElAsVal = ([_, v]) => v
