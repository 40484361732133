/* eslint "react/no-find-dom-node": "warn" */
import ReactDOM from 'react-dom'

var BasicField = {

  focus: function () {
    ReactDOM.findDOMNode(this).focus()
  },

  getValue: function () {
    return ReactDOM.findDOMNode(this).value
  }
}

export default BasicField
