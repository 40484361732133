import styled from 'styled-components'
import { Box } from '@realsoftworks/decor'
import themeGet from '@styled-system/theme-get'

const Input = styled(Box)`
  border: none;
  padding: 0px;
  flex: 1 1 auto;
  min-width: 150px;
  margin-left: 8px;
  font-size: ${themeGet('fontSizes.1')}px;

  &:focus {
    outline: none;
  }
`

Input.defaultProps = {
  as: 'input',
  height: 24
}

export default Input
