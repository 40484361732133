export const FETCH = 'cma/FETCH'
export const FETCH_INTERRUPTED = 'cma/FETCH_INTERRUPTED'
export const FETCH_SUCCESS = 'cma/FETCH_SUCCESS'
export const FETCH_FAILURE = 'cma/FETCH_FAILURE'

export const CREATE = 'cma/CREATE'
export const CREATE_INTERRUPTED = 'cma/CREATE_INTERRUPTED'
export const CREATE_SUCCESS = 'cma/CREATE_SUCCESS'
export const CREATE_FAILURE = 'cma/CREATE_FAILURE'

export const SEARCH = 'cma/SEARCH'
export const SEARCH_INTERRUPTED = 'cma/SEARCH_INTERRUPTED'
export const SEARCH_SUCCESS = 'cma/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'cma/SEARCH_FAILURE'

export const TOGGLE = 'cma/TOGGLE'
export const TOGGLE_SUCCESS = 'cma/TOGGLE_SUCCESS'
export const TOGGLE_FAILURE = 'cma/TOGGLE_FAILURE'

export const LEAD_SOURCE = 'cma/LEAD_SOURCE'

export const SAVE_ARV = 'cma/save_arv'
export const SAVE_ARV_SUCCESS = 'cma/save_arv_success'
export const SAVE_ARV_FAILURE = 'cma/save_arv_failure'
