import React, { FC, SyntheticEvent, useState, useCallback, useEffect } from 'react'
import { Box } from '@realsoftworks/decor'

const UnderlineField: FC<{
  prefix?: string
  postfix?: string
  width?: string
  css?: object
  value?: number
  onChange: (event: { target: { value: number | undefined } }) => void
}> = ({
  prefix,
  postfix,
  width = '80px',
  value,
  onChange,
  ...props
}) => {
  const [_value, _setValue] = useState(value)
  const onBlur = useCallback(() =>
    onChange({ target: { value: _value }}),
  [onChange, _value])

  const _onChange = useCallback(ev =>
    _setValue(ev.target.value),
  [])

  useEffect(() => {
    if (value !== _value) _setValue(value)
  }, [value])

  return (
    <Box
      display='flex'
      css={{
        borderBottom: '1px solid #857F72'
      }}
    >
      {prefix && <Box pr={2}>{prefix}</Box>}
      <Box
        as='input'
        width={width}
        css={{
          padding: 0,
          margin: 0,
          border: 0
        }}
        value={_value}
        onChange={_onChange}
        onBlur={onBlur}
        {...props}
      />
      {postfix && <Box pl={2}>{postfix}</Box>}
    </Box>
  )
}

export default UnderlineField
