/* eslint "node/no-deprecated-api": "warn", "no-case-declarations": "warn", "no-prototype-builtins": "warn" */
import { SEARCH_LEADS_SUCCESS, CLEAR_LEADS, LEADS_UPDATE_SUCCESS, CREATE_SUCCESS } from '../actions' // CREATE_SUCCESS
import without from 'lodash/array/without'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_LEADS_SUCCESS:
      if (action.type === SEARCH_LEADS_SUCCESS && action.meta && action.meta.timestamp < state.timestamp)
        return state

      if (action.meta && action.meta.params) {
        const { models: ids, count } = action.payload.result
        const { offset, limit } = action.meta.params
        const { lastSingleSearchId } = state

        /**
         * A newly searched lead may not appear in as the first item in the
         * order IDs due to race issues. This makes sure last searched lead
         * is placed in the beginning if page 1 of leads (offset = 0) is shown
         */
        const shouldAddLastSearched = offset === 0 &&
          lastSingleSearchId &&
          ids[0] !== lastSingleSearchId

        return {
          ids: shouldAddLastSearched
            ? [lastSingleSearchId, ...ids]
            : ids,
          offset,
          limit,
          count: shouldAddLastSearched ? count + 1 : count,
          length: shouldAddLastSearched ? ids.length + 1 : ids.length,
          timestamp: action.meta.timestamp
        }
      }
      return state

    case CREATE_SUCCESS:
      /**
       * The resulting lead could be already existing in list or new.
       * If already existing in list, move result to top of list
       * If new, we want to prepend new ID, and increment length and count
       *   length - no. of leads we currently have
       *   count - no. of leads in the database that matches current filters
       */
      const newId = action.payload.result
      const oldIds = state.ids || []
      const newIds = [newId, ...oldIds]
      const newUniqueIds = [...new Set(newIds)]
      const hasIdsIncreased = newUniqueIds.length > oldIds.length

      return {
        ...state,
        count: hasIdsIncreased ? (state.count || 0) + 1 : (state.count || 0),
        length: hasIdsIncreased ? (state.length || 0) + 1 : (state.length || 0),
        ids: newUniqueIds,
        lastSingleSearchId: newId
      }

    case LEADS_UPDATE_SUCCESS:
      if (action.meta && action.meta.params && action.meta.params.id) {
        var { body } = action.meta.params
        if (body && (body.hasOwnProperty('active') || body.hasOwnProperty('deleted'))) { // only remove ids for state changes
          const removedIds = action.meta.params.id
          const ids = without(state.ids, removedIds)
          return {
            ...state,
            ids
          }
        }
      }
      return state

    case CLEAR_LEADS:
      return {
        timestamp: state.timestamp
      }
  }
  return state
}
