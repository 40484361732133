/* eslint "eqeqeq": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import BigLoader from 'common/BigLoader'
import { Switch, Route } from 'react-router-dom'
import { Box, Heading } from '@realsoftworks/decor'

import ListList from '../ListList/ListList'
import Stats from '../Stats'
import ListDetail from '../ListDetail'
import CallToAction from '../CallToAction'

import DocumentTitle from 'react-document-title'

export default class ListHandler extends React.Component {
  static propTypes = {
    fetchLists: PropTypes.func.isRequired,
    listsIds: PropTypes.array
  }

  static defaultProps = {
    fetchLists: async () => {}
  }

  state = { loading: true };

  componentDidMount () {
    this.props.fetchLists().then(() => this.setState({ loading: false }))
  }

  render () {
    const { loading } = this.state
    const { match, lists } = this.props

    return (
      <DocumentTitle title='Marketing Lists'>
        {loading ? <BigLoader/>
          : lists.length == 0 ? <CallToAction/>
            : <Box>
              <Heading mb={2}>Marketing Lists</Heading>
              <Box display='flex' mx={-5}>
                <Box width='260px' mx={5}>
                  <ListList path={match.path} lists={lists}/>
                </Box>
                <Box flex='1 1 auto' mx={5}>
                  <Switch>
                    <Route path={`${match.path}/`} exact component={Stats}/>
                    <Route path={`${match.path}/:id`} component={ListDetail}/>
                  </Switch>
                </Box>
              </Box>
            </Box>
        }
      </DocumentTitle>
    )
  }
};
