/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box } from '@realsoftworks/decor'

import { search, fetchMessages, fetchMessageStats } from '../actions'
import { getList, getCrossMessageData } from '../selectors'

import DataContainer from './DataContainer'
import SiteList from './SiteList'

import { Card, CardTitle } from 'common/Card'
import { Crumb } from 'common/Breadcrumbs'
import Button from 'decor/Button'

import CreateSiteDialog from './CreateSiteDialog'

import BigFatalError from 'common/BigFatalError'

import CrossSiteVisitorChart from './CrossSiteVisitorChart'
import CrossSiteConversionChart from './CrossSiteConversionChart'

import MessageListDataContainer from './MessageListDataContainer'
import DomainsPanel from './domains/DomainsPanel'
import ReactPlayer from 'react-player'

class SitesIndexHandler extends Component {
  state = { open: false };

  onCreateClick = () => this.setState({ open: true });
  onRequestClose = () => this.setState({ open: false });

  onFinish = () => {
    // this.setState({open: false});
    this.props.search()
  };

  componentWillMount () {
    this.props.fetchMessageStats()
  }

  render () {
    return (
      <DataContainer
        load={search}
        selector={getList}
        render={({ error, props }) => {
          if (error)
            return <BigFatalError/>

          return (
            <React.Fragment>

              <CreateSiteDialog
                open={this.state.open}
                onRequestClose={this.onRequestClose}
                onFinish={this.onFinish}
              />

              { props.data.length == 0
                ? <EmptySites onClick={this.onCreateClick}/>
                : <React.Fragment>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Card className='visitors-card' mb={5}>
                        <CardTitle>Visitors (30 days)</CardTitle>
                        <CrossSiteVisitorChart/>
                      </Card>
                    </div>
                    <div className='col-md-6'>
                      <Card className='conversions-card' mb={5}>
                        <CardTitle>Conversions (30 days)</CardTitle>
                        <CrossSiteConversionChart/>
                      </Card>
                    </div>
                  </div>

                  <Crumb
                    tools={[
                      <Button key='create' className='btn' onClick={this.onCreateClick}><i className='fa fa-plus'/> Create Site</Button>
                    ]}
                  />

                  <Card className='messages-card' mb={5}>
                    <MessageListDataContainer load={fetchMessages} selector={getCrossMessageData}/>
                  </Card>

                  <Card mt={5}>
                    <SiteList sites={props.data}/>
                  </Card>

                  <Box mt={5}>
                    <DomainsPanel/>
                  </Box>

                </React.Fragment>
              }
            </React.Fragment>
          )
        }}
      />

    )
  }
};

const EmptySites = ({ onClick }) => (
  <Card className='getting-started'>
    <h1>Online Marketing Made Easy!</h1>
    <p style={{ maxWidth: '30em', margin: '0 auto 20px auto' }}>You're just a couple clicks away from setting up your first website. No technical experience required!</p>

    <ReactPlayer url='https://www.youtube.com/watch?v=wshTl2j18fA' width='100%' style={{ margin: '0 auto 20px auto' }} />

    <Button sizeVariant='large' onClick={onClick}>Get Started</Button>
  </Card>
)

SitesIndexHandler = connect(null, {
  search, fetchMessageStats
})(SitesIndexHandler)

export default SitesIndexHandler
