/* eslint "no-case-declarations": "warn" */
import { listArrToById, listByIdToArr } from 'common/util/convertList'

import {
  FETCH_NOTIFS,
  FETCH_NOTIFS_SUCCESS,
  FETCH_NOTIFS_FAILED,

  FETCH_PAGE_NOTIFS,
  FETCH_PAGE_NOTIFS_SUCCESS,
  FETCH_PAGE_NOTIFS_FAILED,

  FETCH_MORE_PAGE_NOTIFS,
  FETCH_MORE_PAGE_NOTIFS_SUCCESS,
  FETCH_MORE_PAGE_NOTIFS_FAILED,

  TOGGLE_NOTIF,
  TOGGLE_NOTIF_SUCCESS,
  TOGGLE_NOTIF_FAILED
} from './actions'

const initState = {
  value: {},
  isLoadingForFirstTime: true,
  isLoading: true,
  error: null,

  isLoadingPage: false,
  errorPage: null,

  hasMore: true,
  isLoadingPageMore: false,
  errorPageMore: null
}

const notifications = (state = initState, { type, payload }) => {
  switch (type) {
    /* Fetch (widget) notifs */
    case FETCH_NOTIFS:
      return { ...state, isLoading: true, error: null }
    case FETCH_NOTIFS_SUCCESS:
      // Merge startegy:
      // Prepend the notifs that the store don't have yet. This way, (1) we won't be deleting notifs
      // that have been fetched by the page (because we're just prepending), and (2) we won't mess
      // up any ongoing toggling of notifs (because we don't replace if there's already existing
      // notif, so the toggle status won't be affected by this action)
      const newNotifs = listByIdToArr(payload.value).filter(n => !state.value[n.id])
      const mergedNotifs = {
        ...listArrToById(newNotifs),
        ...state.value
      }

      return {
        ...state,
        value: mergedNotifs,
        isLoading: false,
        isLoadingForFirstTime: false
      }
    case FETCH_NOTIFS_FAILED:
      return { ...state, isLoading: false, error: payload.error }

    /* Fetch page notifs */
    case FETCH_PAGE_NOTIFS:
      return { ...state, hasMore: true, isLoadingPage: true, errorPage: null }
    case FETCH_PAGE_NOTIFS_SUCCESS:
      // Merge strategy: replace. Because page notifs will always have more than widgets need,
      // and page notifs itself should discard "load more" notifs on initial fetch
      return { ...state, value: payload.value, hasMore: payload.hasMore, isLoadingPage: false }
    case FETCH_PAGE_NOTIFS_FAILED:
      return {
        ...state,
        isLoadingPage: false,
        errorPage: payload.error
      }

    /* Fetch more page notifs */
    case FETCH_MORE_PAGE_NOTIFS:
      return {
        ...state,
        isLoadingPageMore: true,
        errorPageMore: null
      }
    case FETCH_MORE_PAGE_NOTIFS_SUCCESS:
      // Merge strategy: join
      return {
        ...state,
        value: { ...state.value, ...payload.value },
        hasMore: payload.hasMore,
        isLoadingPageMore: false
      }
    case FETCH_MORE_PAGE_NOTIFS_FAILED:
      return {
        ...state,
        isLoadingPageMore: false,
        errorPageMore: payload.error
      }

    /* Toggle notif */
    case TOGGLE_NOTIF: // Toggle notif optimistically
      return {
        ...state,
        value: toggleNotif(payload, state.value)
      }
    case TOGGLE_NOTIF_SUCCESS: // Because update was optimistic, it's noop here
      return state
    case TOGGLE_NOTIF_FAILED: // Just revert when failed
      return toggleNotif(payload, state.value)

    default:
      return state
  }
}

export default notifications

const toggleNotif = (id, notifs) =>
  listArrToById(
    listByIdToArr(notifs)
      .map(n =>
        n.id === id
          ? { ...n, values: { ...n.values, read: !n.values.read } }
          : n)
  )
