import React, { forwardRef, ReactNode } from 'react'
import { Box, Pill } from '@realsoftworks/decor'
import getStyledColor from 'common/util/getStyledColor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FilterPill = forwardRef(({
  color,
  onDeleteFilter,
  children,
  ...props
}: {
  color: string
  onDeleteFilter?: () => void
  children: ReactNode
}, ref) =>
  <Pill
    ref={ref}
    variant='default'
    size='small'
    css={`
      min-height: 20px;
      border-radius: 9999px;
      font-size: 13px;
      border: 1px solid ${getStyledColor(`pairs.${color}.dark`)};
      color: ${getStyledColor(`pairs.${color}.dark`)};
      background: ${getStyledColor(`pairs.${color}.light`)};
      position: relative;
    `}
    {...props}
  >
    <Box display='flex' alignItems='stretch'>
      {children}
      {typeof onDeleteFilter === 'function' && (
        <Box
          display='flex'
          alignItems='center'
          pl={1}
          pr='6px'
          mr={-2}
          onClick={onDeleteFilter}
          css='cursor: pointer;'
        >
          <FontAwesomeIcon
            icon={faTimes}
          />
        </Box>
      )}
    </Box>
  </Pill>)

export default FilterPill
