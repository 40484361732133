import { oneOfType, string, object } from 'prop-types'
import moment from 'moment'

const DateTime = ({ children, format = 'MMMM D, YYYY, h:mm a' }) =>
  moment(children).format(format)

DateTime.propTypes = {
  children: oneOfType([string, object]).isRequired,
  format: string
}

export default DateTime
