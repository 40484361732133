import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

export default createReactClass({
  displayName: 'LoadingIcon',

  getDefaultProps () {
    return {
      size: 1
    }
  },

  render () {
    const { size } = this.props

    var classes = classNames({
      'icon-loading': true,
      ['icon-' + size + 'x']: true
    })

    return (<i className={classes} />)
  }
})
