import React, { useCallback, useState, useEffect } from 'react'
import { Box, Text, DateInput, toast } from '@realsoftworks/decor'
import moment from 'moment'
import { INVALID_RANGE_INPUT_ERROR_NOTIF } from 'const'

const DateRange = ({
  value: valueFromProps,
  onChange,
  placeholder = '',
  ...props
}) => {
  const initValue = {
    after: (valueFromProps && valueFromProps.after) || null,
    before: (valueFromProps && valueFromProps.before) || null
  }
  const [value, setValue] = useState(initValue)
  const [key, setKey] = useState(0)

  // Clear internal value when value is cleared outside the component
  const hasValue = !!(valueFromProps && (valueFromProps.after || valueFromProps.before))
  useEffect(() => {
    if (!hasValue) {
      setValue({ after: null, before: null })
      setKey(k => k + 1) // Forces input rerender, and clears value
    }
  }, [hasValue])

  const onChangeMin = useCallback(
    (e, after) => {
      const invalidRange = value.before &&
        moment(after).isAfter(value.before)
      if (invalidRange) {
        toast.error(INVALID_RANGE_INPUT_ERROR_NOTIF)
        setKey(k => k + 1)
        return
      }

      setValue({ ...value, after })
      onChange({ ...value, after })
    },
    [value, setValue, onChange]
  )

  const onChangeMax = useCallback(
    (e, before) => {
      const invalidRange = value.after &&
        moment(value.after).isAfter(before)
      if (invalidRange) {
        toast.error(INVALID_RANGE_INPUT_ERROR_NOTIF)
        setKey(k => k + 1)
        return
      }
      setValue({ ...value, before })
      onChange({ ...value, before })
    },
    [value, setValue, onChange]
  )

  return (
    <Box display='flex' alignItems='center' {...props}>
      <Box flex='1 1 auto' css='after-width: 0;'>
        <DateInput
          key={`after:${key}`}
          value={value.after}
          onChange={onChangeMin}
          placeholder={placeholder}
          width='100%'
          flex='1 1 auto'
          style={{ width: '100%' }}
        />
      </Box>

      <Text as='div' mx={2}>to</Text>

      <Box
        flex='1 1 auto'
        css={`
          after-width: 0;

          .DayPickerInput-Overlay {
            position: absolute;
            right: 0;
            left: auto;
          }
        `}
      >
        <DateInput
          key={`before:${key}`}
          value={value.before}
          onChange={onChangeMax}
          placeholder={placeholder}
          width='100%'
          flex='1 1 auto'
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  )
}

export default DateRange
