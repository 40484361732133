/* eslint "react/no-deprecated": "warn", "no-prototype-builtins": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import BasicSwitch from './../internal/BasicSwitch'
import classNames from 'classnames'

import './checkbox.less'

class Checkbox extends React.Component {
    static propTypes = {
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      label: PropTypes.string,
      disabled: PropTypes.bool,

      checkedIcon: PropTypes.node,
      uncheckedIcon: PropTypes.node
    };

    static defaultProps = {
      className: ''
    };

    state = {
      switched:
        this.props.checked ||
        this.props.defaultChecked ||
        false
    };

    componentWillReceiveProps (nextProps, nextContext) {
      var nextState = {}
      if ((nextProps.hasOwnProperty('checked') || this.props.hasOwnProperty('Checked')) &&
              this.props.checked !== nextProps.checked)
        nextState.switched = nextProps.checked

      this.setState(nextState)
    }

    _handleStateChange = newSwitched => {
      this.setState({ switched: newSwitched })
    };

    _handleToggle = (e, isInputChecked) => {
      if (this.props.onChange) this.props.onChange(e, isInputChecked)
    };

    render () {
      let { className, checkedIcon, uncheckedIcon, ...other } = this.props

      checkedIcon = checkedIcon || <div className='checkbox-default-check'><i/></div>
      uncheckedIcon = uncheckedIcon || <div className='checkbox-default-box'/>

      const checkedEl = (
        <div className='checkbox-checked'>{checkedIcon}</div>
      )

      const uncheckedEl = (
        <div className='checkbox-unchecked'>{uncheckedIcon}</div>
      )

      const switchElement = (
        <div className={ (this.state.switched ? ' on' : '') }>
          {uncheckedEl}
          {checkedEl}
        </div>
      )

      const switchProps = {
        ref: 'switch',
        className: classNames('checkbox', className, { disabled: this.props.disabled }),
        switchWrapClassName: 'checkbox-wrap',

        centerRipple: true,
        switchElement: switchElement,
        switched: this.state.switched,

        onParentShouldUpdate: this._handleStateChange,
        onSwitch: this._handleToggle
      }

      return (
        <BasicSwitch
          {...other}
          {...switchProps} />
      )
    }
}

export default Checkbox
