/* eslint "react/no-deprecated": "warn" */
import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'
import FormInput from '../../../views/form/FormInput.react'
import SimpleList from '../../../views/SimpleList.react'
import { completeCreate, update, destroy, createTag, closeModal } from '../actions'
import { connect } from 'react-redux'
import { selectors as contactSelectors } from '../index.js'
import { Dialog, Button, toast } from '@realsoftworks/decor'
import LoadingButton from 'common/LoadingButton'
import { TAG_EXISTING_NOTIF } from 'const'
import get from 'lodash/object/get'

var ContactModal = createReactClass({
  displayName: 'ContactModal',

  getDefaultProps: function () {
    return {
      title: false
    }
  },

  getInitialState: function () {
    return {
      name: '',
      company_name: '',
      phones: [],
      emails: []
    }
  },

  render: function () {
    return (
      <Dialog
        title={this.props.title}
        open={this.props.isOpen}
        onClose={this.onBackdropClick}
        toolbar={(
          <Fragment>
            <Button onClick={this.onBackdropClick}>Close</Button>
            <LoadingButton
              ml={2}
              variant='primary'
              onClick={this.onSave}
              loading={this.state.isLoading}
            >
              Save
            </LoadingButton>
          </Fragment>
        )}
      >
        <div className='form-group'>
          <label>Name</label>
          <div className='row'>
            <div className='col-md-6'>
              <FormInput type='text' name='firstname' placeholder='First' value={this.state.firstname} onChange={this.handleChange('firstname')}/>
            </div>
            <div className='col-md-6'>
              <FormInput type='text' name='lastname' placeholder='Last' value={this.state.lastname} onChange={this.handleChange('lastname')}/>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <label>Company Name</label>
          <FormInput type='text' name='company_name' value={this.state.company_name} onChange={this.handleChange('company_name')}/>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label>Phone</label>
              <SimpleList displayKey='phone' value={this.state.phones} onChange={this.handleChange('phones')}/>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label>Email</label>
              <SimpleList displayKey='email' value={this.state.emails} onChange={this.handleChange('emails')}/>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label>Tags</label>
          <FormInput
            type='picker'
            placeholder='Add tags...'
            model='contacttag'
            value={this.state.contacttag}
            onChange={this.onChange.bind(this, 'contacttag')}
            onAdd={this.onTagAdd}/>
        </div>

        <div className='form-group'>
          <label>Notes</label>
          <FormInput type='text' multiline={true} name='notes' value={this.state.notes} onChange={this.handleChange('notes')}/>
        </div>
      </Dialog>
    )
  },

  setNewState: function (contact) {
    if (contact) {
      var me = this
      var v = Object.assign({}, contact.values)
      me.setState(v)
    }
  },

  componentWillReceiveProps: function (nextProps) {
    if (nextProps.contact && !this.props.isContactDetailsLoaded) {
      var v = Object.assign({}, nextProps.contact.values)
      this.replaceState(v)
    }
  },

  onChange: function (name, value) {
    if (value.target)
      value = value.target.value

    var s = {}
    s[name] = value
    this.setState(s)
  },

  handleChange: function (name) {
    return this.onChange.bind(this, name)
  },

  onSave: function () {
    this.setState(
      ({ phones, emails, ...state }) => ({
        ...state,
        phones: Array.isArray(phones) ? phones.filter(v => v.phone) : phones,
        emails: Array.isArray(emails) ? emails.filter(v => v.email) : emails
      }),
      () => {
        if (this.props.contactId) {
          this.props.onUpdateContact(this.props.contactId, this.state)
          this.replaceState({})
          this.props.onRequestClose()
          this.props.closeModal()
        } else {
          this.setState({ isLoading: true })
          this.props.onCompleteCreateContact(this.state)
            .then(contact => {
              const contactVal = {
                id: contact.id,
                name: get(contact, 'values.name', '')
              }
              this.replaceState({})
              this.props.onRequestClose(contactVal)
              this.props.closeModal()
            })
            .finally(() => {
              this.setState({ isLoading: false })
            })
        }
      })
  },

  toggleDelete: function () {
    if (this.props.contact.values.active) { this.props.destroy(this.props.contactId) } else {
      // this.props.reActivate(this.props.contactId);
    }
  },

  onBackdropClick: function () {
    this.replaceState({})
    this.props.onRequestClose()
    this.props.closeModal()
  },

  onTagAdd: function (tag) {
    this.props.createTag({ name: tag }).then(tag => {
      const tags = this.state.contacttag || []
      const existingTag = tags.find(t => t.id === tag.id)
      if (existingTag)
        toast.error(TAG_EXISTING_NOTIF)
      else
        this.setState({ contacttag: [...tags, tag] })
    })
  }
})

const mapStateToProps = (state, props) => {
  const contact = contactSelectors.getContact(state, props.contactId)
  const isContactDetailsLoaded = contactSelectors.getIsDetailsLoaded(state)
  const tags = contactSelectors.getTagsByReference(state, props.contactId)
  if (contact && contact.values)
    contact.values.contacttag = tags

  return {
    contact,
    tags,
    isContactDetailsLoaded
  }
}
const mapDispatchToProps = {
  onCompleteCreateContact: completeCreate,
  onUpdateContact: update,
  destroy,
  createTag,
  closeModal
}
ContactModal = connect(mapStateToProps, mapDispatchToProps)(ContactModal)
export default ContactModal
