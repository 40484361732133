import React from 'react'
import SettingsComponent from 'users/components/SettingsComponent'
import styled from 'styled-components'
import { updateSettings } from 'users/actions'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMugHot } from '@fortawesome/pro-duotone-svg-icons'
import { Dialog, Button, Paragraph, Text } from '@realsoftworks/decor'

const Icon = styled(Text).attrs({
  children: <FontAwesomeIcon icon={faMugHot} />
})`
  color: #38A1A8;
  font-size: 150px;
  text-align: center;
  display: block;
`

const SkiptraceOrderProcessingDialog = ({ onClose: propsOnClose, updateSettings, ...props }) => {
  const onClose = React.useCallback(() => {
    // give animation a chance
    setTimeout(() => updateSettings({ 'skiptrace.processingNotice': true }), 1000)
    propsOnClose()
  })

  return (
    <SettingsComponent settingKey='skiptrace.processingNotice'>
      <Dialog
        title="We're working on your order"
        onClose={onClose}
        toolbar={({ close }) => (<Button variant='primary' onClick={close}>Got it</Button>)}
        {...props}
      >
        <Icon mb={4} />
        <Paragraph>
          Your order will process in the background so you can leave this page and go about your day. Check back here later for your results.
        </Paragraph>
      </Dialog>
    </SettingsComponent>
  )
}

const mapStateToProps = null
const mapDispatchToProps = {
  updateSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(SkiptraceOrderProcessingDialog)
