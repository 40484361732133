/* eslint "react/no-string-refs": "warn", "react/jsx-key": "warn" */
import React from 'react'
import DateField from './DateField.react'
import TimeField from './TimeField.react'

class DateTimeField extends React.Component {
  render () {
    var value = this.props.value || {}
    var dt = value.date || ''
    var time = value.time || ''

    return (
      <div className='date-time-field'>
        <DateField ref='datefield' value={dt} onChange={this.onDateChange} minDate={this.props.minDate} maxDate={this.props.maxDate}/>
        {this.props.time ? <TimeField ref='timefield' value={time} onChange={this.onTimeChange}/> : ''}
        {
          this.props.range
            ? [<div className='separator'>to</div>, <DateField ref='enddatefield' value={value.enddate || ''} onChange={this.onEndDateChange}/>,
              this.props.time ? <TimeField ref='endtimefield' value={value.endtime || ''} onChange={this.onEndTimeChange}/> : ''
            ]

            : ''
        }

      </div>
    )
  }

  onDateChange = v => {
    var value = Object.assign({}, this.props.value || {})
    value.date = v

    if (!this.props.time) {
      delete value.time
      delete value.endtime
    }

    this.props.onChange(value)
  };

  onTimeChange = v => {
    var value = Object.assign({}, this.props.value || {})
    value.time = v
    this.props.onChange(value)
  };

  onEndDateChange = v => {
    var value = Object.assign({}, this.props.value || {})
    value.enddate = v

    if (!this.props.time) {
      delete value.time
      delete value.endtime
    }

    this.props.onChange(value)
  };

  onEndTimeChange = v => {
    var value = Object.assign({}, this.props.value || {})
    value.endtime = v
    this.props.onChange(value)
  };

  focus = () => {
    this.refs.datefield.focus()
  };

  checkBlur = e => this.refs.datefield.checkBlur(e) && (this.props.range ? this.refs.enddatefield.checkBlur(e) : true);
}

export default DateTimeField
