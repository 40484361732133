/* eslint "eqeqeq": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Paragraph, Box, Dialog } from '@realsoftworks/decor'
import styled from 'styled-components'
import without from 'lodash/array/without'
import unique from 'lodash/array/unique'

import ListItem from './ListItem'

const ListBody = styled(Box)`
  max-height: 300px;
  overflow: auto;
`

class TypingDialog extends React.Component {
  static propTypes = {
    initialValue: PropTypes.array,
    ...Dialog.propTypes
  }

  state = {
    typed: '',
    open: this.props.open,
    value: this.props.initialValue
  }

  inputRef = React.createRef();

  static getDerivedStateFromProps (props, state) {
    if (props.open !== state.open)
      return {
        open: props.open,
        value: props.initialValue
      }

    return null
  }

  onKeyDown = e => {
    if (e.key == 'Enter')
      this.onAdd()
  }

  onType = e => this.setState({ typed: e.target.value });
  onAdd = () => {
    const { typed, value } = this.state
    const newValue = unique([typed, ...(value || [])])
    this.setState({ value: newValue, typed: '' })
    this.inputRef.current.focus()
  }

  onDelete = value => {
    this.setState({ value: without(this.state.value, value) })
  }

  render () {
    const { onChange, ...props } = this.props
    const { value, typed } = this.state

    const toolbar = ({ close }) => (
      <React.Fragment>
        <Button
          variant='primary'
          onClick={() => {
            onChange(value)
            close()
          }}
        >
          Save
        </Button>
      </React.Fragment>
    )

    return (
      <Dialog
        width={320}
        {...props}
        toolbar={toolbar}
      >
        <Box display='flex' flexDirection='column'>
          <Paragraph>Type in the box below and click Add to put it in the list.</Paragraph>
          <Box display='flex' mx={-1} mb={5}>
            <Input ref={this.inputRef} flex='1' mx={1} onChange={this.onType} value={typed} onKeyDown={this.onKeyDown}/>
            <Button variant='secondary' mx={1} onClick={this.onAdd}>Add</Button>
          </Box>
          <ListBody flex='1'>
            {
              (!value || !value.length)
                ? <Paragraph textAlign='center' color='secondary'>Nothing in the list</Paragraph>
                : value.map((v, i) => <ListItem key={i} label={v} value={v} icon='x' onClick={this.onDelete}/>)
            }
          </ListBody>
        </Box>
      </Dialog>
    )
  }
}

export default TypingDialog
