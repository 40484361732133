import React, { Component } from 'react'
import { LineChart, Line, XAxis, YAxis } from 'recharts'

class SiteListChart extends Component {
  render () {
    const { data, yKey } = this.props

    return (
      <LineChart width={136} height={47} data={data}>
        <XAxis dataKey='ts' hide />
        <YAxis dataKey={yKey} hide />
        <Line type='monotone' dataKey={yKey} stroke='#4a90e2' strokeWidth={3} dot={false} />
      </LineChart>
    )
  }
}

export default SiteListChart
