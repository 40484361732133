import { combineReducers } from 'redux'

import metadata from './metadata'
import requirements from './requirements'
import brokers from './brokers'
import lastSource from './lastSource'
import coverage from './coverage/coverage.reducer'

export default combineReducers({
  metadata,
  requirements,
  brokers,
  lastSource,
  coverage
})
