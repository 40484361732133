import { combineReducers } from 'redux'
import {
  FETCH_CROSS_VISITORS_SUCCESS,
  FETCH_SITE_VISITORS_SUCCESS,
  FETCH_CROSS_CONVERSIONS_SUCCESS,
  FETCH_SITE_CONVERSIONS_SUCCESS,
  FETCH_ROW_VISITORS_SUCCESS,
  FETCH_ROW_CONVERSIONS_SUCCESS
} from '../actions'

const visitors = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_CROSS_VISITORS_SUCCESS:
      return {
        ...state,
        cross: payload
      }

    case FETCH_SITE_VISITORS_SUCCESS:
      return {
        ...state,
        [meta.id]: payload
      }
  }

  return state
}

const conversions = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_CROSS_CONVERSIONS_SUCCESS:
      return {
        ...state,
        cross: payload
      }

    case FETCH_SITE_CONVERSIONS_SUCCESS:
      return {
        ...state,
        [meta.id]: payload
      }
  }

  return state
}

const rowVisitors = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ROW_VISITORS_SUCCESS:
      return {
        ...state,
        [meta.id]: payload
      }
  }

  return state
}

const rowConversions = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ROW_CONVERSIONS_SUCCESS:
      return {
        ...state,
        [meta.id]: payload
      }
  }

  return state
}

export default combineReducers({
  visitors,
  conversions,
  row: combineReducers({
    visitors: rowVisitors,
    conversions: rowConversions
  })
})
