import React from 'react'
import { NumberInput, Box } from '@realsoftworks/decor'

class CMARangeField extends React.Component {
  render () {
    var { label, params, ...rest } = this.props
    var value = params[this.props.name]

    return (
      <div className='form-group'>
        <label className='control-label'>{label}</label>
        <Box display='flex' alignItems='center'>
          <NumberInput minWidth={0} width='100%' flex='1' value={value ? value.min : null} {...rest} type='' placeholder='min' onChange={this.onChange.bind(this, 'min')} />
          <Box mx={3}>to</Box>
          <NumberInput minWidth={0} width='100%' flex='1' value={value ? value.max : null} {...rest} type='' placeholder='max' onChange={this.onChange.bind(this, 'max')} />
        </Box>
      </div>
    )
  }

  onChange (minOrMax, e) {
    var v = Object.assign({}, this.props.params[this.props.name])
    v[minOrMax] = (e && e.target) ? e.target.value : e
    this.props.onChange(this.props.name, v)
  }
}
export default CMARangeField
