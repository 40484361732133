import { useRef, useCallback, useEffect } from 'react'

// This saves listeners by id and cleans them when updating the listener or on unmount
export default () => {
  const { current: listeners } = useRef({})

  const cleanListenerLater = useCallback((id, listener) => {
    if (listeners[id]) listeners[id].remove()
    listeners[id] = listener
  }, [])

  useEffect(() => () => {
    Object.values(listeners).forEach(l => l.remove())
  }, [])

  return cleanListenerLater
}
