import React from 'react'
import { Box, Checkbox, Link } from '@realsoftworks/decor'
import { Monetary } from 'common/format'

const AcceptTerms = ({
  checked,
  count,
  servicePrice,
  creditPrice,
  minimum,
  availableCredits,
  onChange,
  ...props
}) => {
  const totalCredits = servicePrice * count
  const requiredCredits = Math.max(totalCredits - availableCredits, 0)
  let purchaseCredits = requiredCredits
  if (purchaseCredits > 0 && purchaseCredits < minimum)
    purchaseCredits = minimum

  const coveredByBalance = purchaseCredits === 0

  const displayedCost = (purchaseCredits || totalCredits) * creditPrice / 100
  const method = coveredByBalance ? 'deducted from my balance' : 'charged to my card'

  return (
    <Box {...props}>
      <Checkbox
        label={
          <>
            I understand that <em>up to</em> <Monetary>{displayedCost}</Monetary> will be {method}{' '}
            and I accept the <Link href='https://www.propelio.com/terms' target='_blank'>Terms of Service</Link>
          </>
        }
        checked={checked}
        onChange={onChange}
      />
    </Box>
  )
}

export default AcceptTerms
