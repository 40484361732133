/* eslint "no-class-assign": "warn", "eqeqeq": "warn" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getCurrent } from 'users/selectors'

import './username.less'

class UserName extends Component {
  render () {
    const { user, me } = this.props
    return (
      <span className={classNames('username', { 'username-me': me })}>{user.name}</span>
    )
  }
}

UserName.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  me: PropTypes.bool
}

UserName = connect((state, props) => ({
  me: getCurrent(state).id == props.user.id
}))(UserName)

export default UserName
