/* eslint "eqeqeq": "warn", "react/no-unescaped-entities": "warn" */
import * as SiteLinks from './SiteLinks'
import Alert from 'decor/Alert'

const SiteDomainAlert = ({ site, configure = true }) => (
  site.domains.length == 0
    ? <Alert variant='info'>
      Brand your site with a custom domain (like "propelioproperties.com") {configure && <span><SiteLinks.Settings site={site}><strong>Configure.</strong></SiteLinks.Settings></span>}
    </Alert>
    : null
)

export default SiteDomainAlert
