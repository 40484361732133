/* eslint "eqeqeq": "warn" */
export function isMetadataLoading (state) {
  return state.mls.metadata.loading || !getMetadata(state)
}

export function getMetadata (state) {
  return state.mls.metadata.data
}

export function getMetadataForSource (state, source) {
  var data = state.mls.metadata.byId
  if (data)
    return data[source]

  return null
}

export function getMetadataFetchError (state) {
  return state.mls.metadata.error
}

export function getDocument (state, docId) {
  return state.mls.requirements.documents[docId]
}

export function canAccess (state, source) {
  var pending = state.mls.requirements.pendingSources
  return pending.indexOf(source) == -1
}

export function getRequirements (state, source) {
  var reqs = state.mls.requirements
  var brokers = reqs.brokersBySource[source]

  if (!brokers)
    return null

  brokers = brokers.map(b => {
    b = reqs.brokerSources[`${source}-${b}`]
    return {
      ...b,
      requirements: {
        ...b.requirements,
        esign: b.requirements.esign ? b.requirements.esign.map(id => getDocument(state, id)) : null
      }
    }
  })

  return {
    user: reqs.user,
    brokers
  }
}

export function isAuthorizing (state, source) {
  return state.mls.requirements.authorizing[source]
}

export function getBroker (state, id) {
  return state.mls.brokers.byId[id]
}

export function getBrokerBySource (state, sourceId) {
  var brokerId = state.mls.brokers.bySource[sourceId]
  return getBroker(state, brokerId)
}

export function getLastBroker (state) {
  var lastSource = state.mls.lastSource
  return getBrokerBySource(state, lastSource)
}
