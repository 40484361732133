import React from 'react'
import { Box } from '@realsoftworks/decor'

export default function IntroLayout ({ children, ...props }) {
  return (
    <Box maxWidth={1000} mx='auto' {...props}>
      {children}
    </Box>
  )
}
