/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "react/jsx-no-target-blank": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from 'decor/Dialog'
import BigLoader from 'common/BigLoader'
import Toolbar from 'decor/Toolbar'
import Button from 'decor/Button'
import Mask from 'common/LoadingMask'
import FormInput from 'decor/form/FormInput'
import Form from 'decor/form/Form'
import FormError from 'decor/form/Error'

import { fetchMail, updateMail } from '../../actions'
import { getDomain } from '../../selectors'
import { GENERIC_ERROR_NOTIF } from 'const'
import { toast } from '@realsoftworks/decor'

const INITIAL_STATE = {
  chosen: null,
  loading: false,
  saving: false,
  custom: false
}

class DomainMailDialog extends Component {
  state = INITIAL_STATE;

  reset () {
    this.setState(INITIAL_STATE)
  }

  onRequestClose = () => {
    if (!this.state.loading)
      this.props.onRequestClose()
  };

  onPick = preset => {
    this.setState({ chosen: preset })
  }

  onBack = () => this.onPick(null);

  onSave = fields => {
    this.setState({ saving: true })
    return this.props.domain &&
      this.props.domain.id &&
      this.props.updateMail(this.props.domain.id, this.state.chosen, fields)
        .then(r => {
          this.setState({ saving: false })
          if (r && !r.error) this.props.onRequestClose()
          if (!r) toast.error(GENERIC_ERROR_NOTIF)
          return r
        })
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (!this.props.open && nextProps) {
      this.reset()
      const { domain } = nextProps

      if (domain) {
        const { mail } = domain.managed

        if (mail) {
          this.setState({ chosen: mail })
          if (mail == 'custom') {
            this.setState({ loading: true })
            this.props.fetchMail(domain.id).then(r => {
              if (r && !r.error)
                this.setState({ custom: r.items })

              this.setState({ loading: false })
            })
          }
        }
      }
    }
  }

  render () {
    const { chosen, loading, saving } = this.state
    const { onRequestClose, preset, ...rest } = this.props
    return (

      <Dialog
        {...rest}
        title='Mail Settings'
        className='domain-dialog domain-mail-dialog'
        onRequestClose={this.onRequestClose}
      >
        {saving && <Mask/>}
        {
          loading ? <BigLoader/>
            : chosen
              ? chosen == 'google'
                ? <GmailForm onBack={this.onBack} update={this.onSave}/>
                : <CustomMailForm initialRecords={this.state.custom} onBack={this.onBack} update={this.onSave}/>
              : <PresetPickerForm onPick={this.onPick}/>
        }

      </Dialog>
    )
  }
}

class GmailForm extends Component {
  render () {
    return (
      <div>
        <h3>Gmail Setup</h3>
        <p>GSuite is Google's version of gmail for businesses and offers the same best-in-class service at an affordable price.</p>
        <p>With one click, you can point your domain's mailservers to Google's. To continue setup, you'll need to follow the instructions provided by Google
          to verify your domain and configure your mailboxes.
        </p>
        <ol>
          <li>
            <h4>Register for GSuite</h4>
            <p>If you don't already have a GSuite account, you can register for one below</p>
            <Button appearance='primary' containerElement={<a href='https://gsuite.google.com/' target='_blank'/>}>Register</Button>
          </li>
          <li>
            <h4>Configure DNS</h4>
            <p>You can complete this step at any time, even while you wait for Google to set up your mailboxes.</p>
            <Button appearance='primary' onClick={this.props.update}>Update DNS</Button>
          </li>
        </ol>
        <Toolbar>
          <Button appearance='secondary' onClick={() => this.props.onBack()}>Back</Button>
        </Toolbar>
      </div>
    )
  }
}

class CustomMailForm extends Component {
  constructor (props) {
    super(props)
    const servers = (props.initialRecords || []).map(v => ({ server: v.data || v.value, ...v })) // TODO ask arlen
    this.state = { loading: false, value: { servers } }
  }

  onChange = value => this.setState({ value });

  update = () => {
    this.setState({ error: false })
    this.props.update({
      servers: this.state.value.servers.filter(v => !!v.server)
    }).then(r => {
      if (r && r.error)
        this.setState({ error: true })
    })
  }

  render () {
    return (
      <div>
        <h3>Custom Setup</h3>
        <p>Enter up to 3 mail servers below to configure your MX DNS records</p>
        <Form value={this.state.value} onChange={this.onChange}>
          <table>
            <thead>
              <tr>
                <th>Server</th>
                <th>Priority</th>
                <th>TTL</th>
              </tr>
            </thead>
            <tr>
              <td><FormInput name='servers[0].server'/></td>
              <td><FormInput name='servers[0].priority' type='number'/></td>
              <td><FormInput name='servers[0].ttl' type='number'/></td>
            </tr>
            <tr>
              <td><FormInput name='servers[1].server'/></td>
              <td><FormInput name='servers[1].priority' type='number'/></td>
              <td><FormInput name='servers[1].ttl' type='number'/></td>
            </tr>
            <tr>
              <td><FormInput name='servers[2].server'/></td>
              <td><FormInput name='servers[2].priority' type='number'/></td>
              <td><FormInput name='servers[2].ttl' type='number'/></td>
            </tr>
          </table>
          {this.state.error && <FormError>Please confirm your records and submit again</FormError>}
        </Form>
        <Toolbar>
          <Toolbar.Left>
            <Button onClick={() => this.props.onBack()}>Back</Button>
          </Toolbar.Left>
          <Button appearance='secondary' onClick={this.update}>Save</Button>
        </Toolbar>
      </div>
    )
  }
}

class PresetPickerForm extends Component {
  render () {
    return (
      <div className='mail-preset-options'>
        <div className='mail-preset-option' onClick={ () => this.props.onPick('google')}>
          <h2>Gmail</h2>
          <p>Create or use your GSuite Account</p>
        </div>
        <div className='mail-preset-option' onClick={ () => this.props.onPick('custom')}>
          <h2>Custom</h2>
          <p>Enter your own MX records</p>
        </div>
      </div>
    )
  }
}

DomainMailDialog = connect(
  (state, props) => ({
    domain: props.domain || getDomain(state, props.domainName)
  }),
  {
    fetchMail,
    updateMail
  }
)(DomainMailDialog)

DomainMailDialog.propTypes = {}
DomainMailDialog.defaultProps = {}

export default DomainMailDialog
