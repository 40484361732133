import React from 'react'
import { Card as BaseCard } from '@realsoftworks/decor'

const Card = props => (
  <BaseCard variant='padded' {...props} />
)

class CardTitle extends React.Component {
  render () {
    var { className, ...otherProps } = this.props
    return (<h2 className={'card-title ' + (className || '')} {...otherProps}>{this.props.children}</h2>)
  }
}

export default Card

export { Card }
export { CardTitle }
