import { put } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'

import { actions as users } from 'users'
import { actions as leads } from 'leads'
import { actions as sites } from 'sites'
import { actions as cma } from 'cma'
import { actions as driving } from 'drivingfordollars'
import { actions as lists } from 'lists'

function updateSetting (setting, value = true) {
  return function * () {
    yield put(users.updateSettings({ [setting]: true }))
  }
}

export default function * () {
  // Leads actions
  yield takeEvery(leads.CREATE_SUCCESS, function * (action) {
    const leadId = action.payload.result
    yield put(users.updateSettings({
      'onboarding.lead.created': true,
      'onboarding.lead.id': leadId
    }))
  })

  // CMA actions
  yield takeEvery(cma.CREATE_SUCCESS, updateSetting('onboarding.cma.created'))

  // Sites actions
  yield takeEvery(sites.CREATE_SUCCESS, function * (action) {
    const { id } = action.payload
    yield put(users.updateSettings({
      'onboarding.site.created': true,
      'onboarding.site.id': id
    }))
  })

  yield takeEvery(sites.UPDATE_SITE_DOMAIN_SUCCESS, updateSetting('onboarding.site.domain_configured'))
  yield takeEvery(sites.UPDATE_MAIL_SETTINGS_SUCCESS, updateSetting('onboarding.site.email_configured'))

  // D4D
  yield takeEvery(driving.DOWNLOAD_LIST, updateSetting('onboarding.drive.leads_downloaded'))

  // lead lists
  yield takeEvery(lists.DOWNLOAD_LIST, updateSetting('onboarding.leadlist.downloaded'))
}
