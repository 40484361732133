import React from 'react'
import { Box, Button } from '@realsoftworks/decor'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { PageTitle } from 'common/layouts'
import { ListList } from 'lists'
import { selectLists } from 'lists/selectors'
import { compose } from 'redux'
import PagingToolbar from 'common/PagingToolbar'
import { TOP_BAR_HEIGHT } from 'app/TopBar'

const withContainer = compose(
  connect(state => ({ lists: selectLists(state) })),
  withRouter
)

export const LISTS_SIDEBAR_WIDTH = 300
const ListsSidebar = withContainer(({ match, pageData, lists }) =>
  (!lists || !lists.length) ? null : (
    <Box
      width={LISTS_SIDEBAR_WIDTH}
      height={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
      pt={7}
      css='border-right: 1px solid #D3CEC4; position: sticky; top: 0;'
      display='flex'
      flexDirection='column'
    >
      <Box flex='0 1 auto'>
        <PageTitle textAlign='center'>
          Marketing Lists
          <Button as={Link} to={match.path} mb={3} ml={3} variant='secondary'>
            New List
          </Button>
        </PageTitle>
      </Box>
      <Box flex='1 1 auto' overflowY='auto'>
        <ListList lists={lists} path={match.path} />
      </Box>
      <Box flex='0 1 auto'>
        <PagingToolbar {...pageData} />
      </Box>
    </Box>
  ))

export default ListsSidebar
