import React from 'react'
import { Link as RRLink, withRouter } from 'react-router-dom'
import flow from 'lodash/function/flow'
import FetchHandler from 'common/FetchHandler'
import DriveList from 'drivingfordollars/components/DriveList'

import withDrives from 'drivingfordollars/utils/withDrives'

import {
  Box,
  Card,
  Button
} from '@realsoftworks/decor'

const ITEM_LIMIT = 50
const DrivesListSection = ({ drives, isLoading, hasLoaded, error, match, ...props }) => (
  <Card variant='basic' {...props}>
    <FetchHandler
      isLoading={isLoading}
      error={error}
      isEmpty={hasLoaded && drives.length === 0}
      emptyText='You don’t have any drives yet.'
    >
      <>
        <DriveList drives={drives} />
        <Box display='flex' justifyContent='center' mt={6} pb={5}>
          <Button to={`${match.url}/drives`} variant='secondary' sizeVariant='large' as={RRLink}>See All Drives</Button>
        </Box>
      </>
    </FetchHandler>
  </Card>
)

export default flow(
  withDrives({ params: { limit: ITEM_LIMIT } }),
  withRouter
)(DrivesListSection)
