import React, { createContext, useMemo, useContext, useEffect, useState } from 'react'

/**
 * App is using a default layout with too-small max width, this allows
 * components to optionally remove the default layout.
 */

const ctx = createContext({
  isDefaultLayoutUsed: true,
  setIsDefaultLayoutUsed: () => {}
})

export const RemoveDefaultLayoutProvider = props => {
  const [isDefaultLayoutUsed, setIsDefaultLayoutUsed] = useState(true)

  const ctxValue = useMemo(() => ({
    isDefaultLayoutUsed,
    setIsDefaultLayoutUsed
  }), [isDefaultLayoutUsed, setIsDefaultLayoutUsed])

  return (
    <ctx.Provider {...props} value={ctxValue} />
  )
}

export const useRemoveDefaultLayout = () => {
  const { isDefaultLayoutUsed } = useContext(ctx)
  return { isDefaultLayoutUsed }
}

export const useRemoveDefaultLayoutUntilUnmount = () => {
  const { setIsDefaultLayoutUsed } = useContext(ctx)

  useEffect(() => {
    // Remove default layount on mount
    setIsDefaultLayoutUsed(false)

    // Bring back default layout on unmount
    return () =>
      setIsDefaultLayoutUsed(true)
  }, [])
}
