import React from 'react'
import { Paragraph, Box, Input, Select, Option } from '@realsoftworks/decor'
import qs from 'qs'
import DialogPicker, { ApiAdapter } from './DialogPicker'
import api from 'common/api'

import stateList from 'common/states'

const extractValue = v => v.fips
const extractLabel = v => `${v.name}, ${v.state}`
const apiFilter = async filters => {
  if (!filters) return []
  const { name, state } = filters
  const params = {}
  if (name) params.name = name
  if (state) params.state = state
  return api.get(`/national-leads/v1/counties?${qs.stringify(params)}`)
}

const adapter = ApiAdapter({
  id: 'nationwide-counties',
  extractLabel,
  extractValue,
  filter: apiFilter,
  limit: 10
})

const CountyFilters = ({ value = { name: '', state: '' }, onChange, ...props }) => (
  <Box display='flex' {...props}>
    <Input placeholder='County Name' flex='1' width={1} value={value.name} onChange={e => onChange({ ...value, name: e.target.value })} />
    <Select width={100} value={value.state} onChange={e => onChange({ ...value, state: e.target.value })} menuProps={{ overflowY: 'scroll', maxHeight: 200 }}>
      <Option value=''>State</Option>
      {stateList.map(v => <Option key={v} value={v}>{v}</Option>)}
    </Select>
  </Box>
)

const CountyPicker = props => (
  <DialogPicker
    type='list'
    width='auto'
    adapter={adapter}
    dialogProps={{
      title: 'Select Counties',
      children: <Paragraph>Search and select the counties you’re interested in and we’ll pull the leads.</Paragraph>,
      emptyText: 'Use the filters above to find the right counties',
      filterComponent: CountyFilters
    }}
    {...props}
  />
)

export default CountyPicker
