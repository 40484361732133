import { LegacyParams, ParcelFilters, ParcelParams } from './parcels.types'
import cleanObject from 'common/util/cleanObject'
import requireLandLordOnlyForParams from './requireLandLordOnlyForParams'

const legacyToParcelParams = (
  { limit, offset, radius, ...filtersRaw }: LegacyParams,
  { isLandLordRequired }: { isLandLordRequired: boolean }
): ParcelParams => {
  const transformedFilters: ParcelFilters = {
    ...filtersRaw,
    ownerType: {
      ...filtersRaw.ownerType,
      vacancy: filtersRaw.ownerType?.vacancy?.isVacant ? filtersRaw.ownerType?.vacancy : undefined
    }
  }

  const finalFilters = isLandLordRequired ? requireLandLordOnlyForParams(transformedFilters) : transformedFilters

  const parcelParams: ParcelParams = {
    limit,
    offset,
    location: {
      neighborhood: [radius[0], radius[1]]
    },
    filters: cleanObject(finalFilters)
  }

  return parcelParams
}

export default legacyToParcelParams
