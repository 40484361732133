import React, { Component } from 'react'

import SiteDomainAlert from './SiteDomainAlert'
import { Card, CardTitle } from 'common/Card'

import MessageListDataContainer from './MessageListDataContainer'
import { fetchSiteMessages } from '../actions'
import { getSiteMessageData } from '../selectors'
import SiteVisitorChart from './SiteVisitorChart'
import SiteConversionChart from './SiteConversionChart'

class SiteDetail extends Component {
  render () {
    const { site } = this.props

    return (
      <div className='sites-detail'>
        <SiteDomainAlert site={site}/>
        <div className='row'>
          <div className='col-md-6'>
            <Card className='visitors-card'>
              <CardTitle>Visitors (30 Days)</CardTitle>
              <SiteVisitorChart siteId={site.id}/>
            </Card>
          </div>
          <div className='col-md-6'>
            <Card className='conversions-card'>
              <CardTitle>Conversions (30 Days)</CardTitle>
              <SiteConversionChart siteId={site.id}/>
            </Card>
          </div>
        </div>
        <Card className='messages-card' mt={5}>
          <SiteDetailMessages siteId={site.id}/>
        </Card>
      </div>
    )
  }
}

class SiteDetailMessages extends Component {
  load = vars => fetchSiteMessages(this.props.siteId, vars);
  selector = state => getSiteMessageData(state, this.props.siteId);

  render () {
    return (

      <MessageListDataContainer
        load={this.load}
        selector={this.selector}
      />
    )
  }
}

export default SiteDetail
