import React from 'react'
import { oneOfType, shape, number, string, bool } from 'prop-types'
import Numeric from 'common/format/Numeric'
import { toK } from 'common/util/number'

const Monetary = ({ children, decimals = 2, useToK }) => {
  if (!children && children !== 0) return null

  const value = (typeof children === 'object' && children.value) || Number(children)

  if (Number.isNaN(value)) return null

  const currency = '$'

  if (typeof value !== 'number') return null

  if (useToK)
    return String(toK(
      value,
      { dollars: true, decimalPlaces: decimals, decimalOptional: true }
    ))

  return (
    <>{currency}<Numeric decimals={decimals}>{value}</Numeric></>
  )
}

Monetary.propTypes = {
  children: oneOfType([
    shape({
      value: number.isRequired,
      currency: string.isRequired
    }),
    number,
    string
  ]),
  decimals: number,
  useToK: bool
}

export default Monetary
