/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Tooltip, Link } from '@realsoftworks/decor'

const LocationTooltip = ({ label, pluralLabel, locations }) => {
  if (locations == null)
    return '--'

  const ordered = Object.keys(locations).sort((a, b) => locations[a] > locations[b])
  if (ordered.length == 0)
    return '--'

  const main = ordered.slice(0, 3)
  const rest = ordered.slice(3)

  let mainText = ''
  let restEl = ''
  if (rest.length > 0) {
    mainText = `${pluralLabel} of ${main.join(', ')} and `
    restEl = <Tooltip content={rest.join(', ')}><Link>{rest.length} other{rest.length > 1 ? 's' : ''}</Link></Tooltip>
  } else {
    const len = main.length
    if (len == 1 || len == 2)
      mainText = (len == 1 ? label : pluralLabel) + ' of ' + main.join(' and ')
    else
      mainText = `${pluralLabel} of ${main[0]}, ${main[1]} and ${main[2]}`
  }

  return (
    <>
      {mainText}{restEl}
    </>
  )
}

export default LocationTooltip
