/* eslint "react/no-render-return-value": "warn" */
import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal.react'

var node = null
var D = null

function init () {
  if (node)
    return

  node = document.createElement('div')
  node.className = 'modal-portal'
  document.body.appendChild(node)
  D = ReactDOM.render(<Dialogs/>, node)
}

class Dialogs extends React.Component {
  static confirm (params) {
    init()
    D.confirm(params)
  }

  state = {
    shown: false,
    confirm: {}
  };

  confirm = params => {
    var defaults = {
      message: 'Are you sure?',
      title: 'Confirm'
    }

    params = Object.assign({}, defaults, params || {})

    this.setState({ confirm: params, shown: 'confirm' })
  };

  render () {
    var { shown, confirm } = this.state

    return (
      <div>
        <Modal show={shown === 'confirm'}>
          <div className='modal-header'>
            <h2 className='modal-title'>{confirm.title}</h2>
          </div>
          <div className='modal-body'>
            {confirm.message}
          </div>
          <div className='modal-footer'>
            <button className='btn btn-default' onClick={this.confirmNo}>No</button>
            <button className='btn btn-primary' onClick={this.confirmYes}>Yes</button>
          </div>
        </Modal>
      </div>
    )
  }

  confirmNo = () => {
    this.state.confirm.callback(false)
    this.setState({ shown: false })
  };

  confirmYes = () => {
    this.state.confirm.callback(true)
    this.setState({ shown: false })
  };
}

export default Dialogs
