import React, { useMemo, FC } from 'react'
import { Box, Heading, Checkbox } from '@realsoftworks/decor'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import get from 'lodash/object/get'
import useGroupedCheckbox from 'common/util/useGroupedCheckbox'
import set from 'common/util/set'

export type OwnerInfoFilters = {
  ownerType?: {
    individual?: {
      ownerOccupied?: boolean
      absentee: {
        inState?: boolean
        outOfState?: boolean
        outOfCountry?: boolean
      }
      military?: boolean
    }
    isTrust?: boolean
    isBank?: boolean
    isCompany?: boolean
    taxExemptions?: {
      isSenior?: boolean
      isHomestead?: boolean
    }
    vacancy?: { isVacant: boolean }
  }
}

const ALL_TYPE_KEYS = [
  'ownerType.isTrust',
  'ownerType.isBank',
  'ownerType.isCompany',
  'ownerType.isIndividual'
]

const ALL_ABSENTEE_KEYS = [
  'ownerType.individual.absentee.inState',
  'ownerType.individual.absentee.outOfState',
  'ownerType.individual.absentee.outOfCountry'
]

const ALL_STATUS_KEYS = [
  'ownerType.individual.ownerOccupied',
  'ownerType.individual.military',
  ...ALL_ABSENTEE_KEYS
]

const ALL_EXCEMPTIONS_KEYS = [
  'ownerType.taxExemptions.isSenior',
  'ownerType.taxExemptions.isHomestead'
]

const ALL_KEYS = [
  ...ALL_TYPE_KEYS,
  ...ALL_STATUS_KEYS,
  ...ALL_EXCEMPTIONS_KEYS
]

/**
 * Note: in the implementation, undefined is used instead of false when checkbox
 *   is unticked, to prevent setting the filters to "no (false) to all owner
 *   types" which causes 500 response
 */
const OwnerInfoSection: FC<{
  filters: OwnerInfoFilters
  onChange: (filters: OwnerInfoFilters) => void
  openSection: string | null
  toggleSection: (section: string | null) => void
}> = ({
  filters,
  onChange,
  openSection,
  toggleSection
}) => {
  const SECTION_KEY = 'OWNER_INFO'
  const isSectionOpen = openSection === SECTION_KEY

  const typeGroup = useGroupedCheckbox({
    values: filters,
    keys: ALL_TYPE_KEYS,
    onChange,
    useUndefinedWhenFalse: true
  })

  const absenteeGroup = useGroupedCheckbox({
    values: filters,
    keys: ALL_ABSENTEE_KEYS,
    onChange,
    useUndefinedWhenFalse: true
  })

  const statusGroup = useGroupedCheckbox({
    values: filters,
    keys: ALL_STATUS_KEYS,
    onChange,
    useUndefinedWhenFalse: true
  })

  const excemptionGroup = useGroupedCheckbox({
    values: filters,
    keys: ALL_EXCEMPTIONS_KEYS,
    onChange,
    useUndefinedWhenFalse: true
  })

  const createFieldProps = key => ({
    onChange: (_ev, value) =>
      onChange(set(
        key.split('.'),
        value ? true : undefined,
        filters
      )),
    checked: get(filters, key)
  })

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    ...ALL_KEYS.map(k => get(filters, k))
  ]

  return useMemo(() =>
    <AccordionFilterSection
      mt={5}
      heading='Owner Info'
      contentContainerProps={{ pt: 2 }}
      toggleSection={toggleSection(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >
      <Box mx={-3} display='flex' flexWrap='wrap'>
        <Box flex='1 1 180px' minWidth='180px' p={2} px={3}>
          <Heading size={5} mb={3}>Owner Type</Heading>
          <Checkbox
            label='All Types'
            checked={typeGroup.areAllChecked}
            onChange={typeGroup.handleCheckAll}
          />
          <Checkbox
            ml={5}
            label='Individual'
            {...createFieldProps('ownerType.isIndividual')}
          />
          <Checkbox
            ml={5}
            label='Trust Owned'
            {...createFieldProps('ownerType.isTrust')}
          />
          <Checkbox
            ml={5}
            label='Bank Owned'
            {...createFieldProps('ownerType.isBank')}
          />
          <Checkbox
            ml={5}
            label='Corporate Owned'
            {...createFieldProps('ownerType.isCompany')}
          />
        </Box>

        <Box flex='1 1 180px' minWidth='180px' p={2} px={3}>
          <Heading size={5} mb={3}>Individual Status</Heading>
          <Checkbox
            label='All Status'
            checked={statusGroup.areAllChecked}
            onChange={statusGroup.handleCheckAll}
          />
          <Checkbox
            ml={5}
            label='Owner Occupied'
            {...createFieldProps('ownerType.individual.ownerOccupied')}
          />
          <Checkbox
            ml={5}
            label='Absentee Owner'
            checked={absenteeGroup.areSomeChecked}
            onChange={absenteeGroup.handleCheckAll}
          />
          <Checkbox
            ml='48px'
            label='In State'
            {...createFieldProps('ownerType.individual.absentee.inState')}
          />
          <Checkbox
            ml='48px'
            label='Out of State'
            {...createFieldProps('ownerType.individual.absentee.outOfState')}
          />
          <Checkbox
            ml='48px'
            label='Out of Country'
            {...createFieldProps('ownerType.individual.absentee.outOfCountry')}
          />
          <Checkbox
            ml={5}
            label='Military'
            {...createFieldProps('ownerType.individual.military')}
          />
        </Box>

        <Box flex='1 1 180px' minWidth='180px' p={2} px={3}>
          <Heading size={5} mb={3} >Tax Exemptions</Heading>
          <Box>
            <Checkbox
              label='All Exemptions'
              checked={excemptionGroup.areAllChecked}
              onChange={excemptionGroup.handleCheckAll}
            />
            <Checkbox
              ml={5}
              label='Senior'
              {...createFieldProps('ownerType.taxExemptions.isSenior')}
            />
            <Checkbox
              ml={5}
              label='Homestead'
              {...createFieldProps('ownerType.taxExemptions.isHomestead')}
            />
          </Box>
          <Heading size={5} mb={3}>Vacancy Status</Heading>
          <Box>
            <Checkbox
              label='Vacant'
              {...createFieldProps('ownerType.vacancy.isVacant')}
            />
          </Box>
          <Box flex='2' mx={3} mb={2} />
        </Box>
      </Box>
    </AccordionFilterSection>,
  rerenderDeps)
}

export default OwnerInfoSection
