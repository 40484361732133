import React, { useCallback, useState, useEffect } from 'react'
import { Box, NumberInput, Text, toast } from '@realsoftworks/decor'
import isNumber from 'common/util/isNumber'
import { INVALID_RANGE_INPUT_ERROR_NOTIF } from 'const'

const INVALID_VALUE_ERROR_NOTIF_TITLE = 'Invalid value'

const NumberRange = ({
  value: valueFromProps,
  onChange: onChangeFromProps,
  thousandSeparator = ',',
  placeholder = '',

  // Note that validator could return true if valid, false if not, or also
  // string if not valid in order to display a custom error message
  validateValue = _v => true,
  ...props
}) => {
  const initValue = valueFromProps || { min: undefined, max: undefined }
  const [value, setValue] = useState(initValue)
  const [key, setKey] = useState(0)
  const [hasChanged, setHasChanged] = useState(false)

  // Clear internal value when value is cleared outside the component
  const hasValue = !!(valueFromProps &&
    (typeof valueFromProps.min === 'number' ||
    typeof valueFromProps.max === 'number'))
  useEffect(() => {
    if (!hasValue) {
      setValue({ min: undefined, max: undefined })
      setKey(k => k + 1) // Forces input rerender, and clears value
    }
  }, [hasValue])

  const onBlur = useCallback(() => {
    const invalidRange = typeof value.min === 'number' &&
      typeof value.max === 'number' &&
      value.max < value.min

    setHasChanged(false)

    const maxValidationResult = validateValue(value.max)
    const minValidationResult = validateValue(value.min)
    if (
      invalidRange ||
      (isNumber(value.max) && maxValidationResult !== true) ||
      (isNumber(value.min) && minValidationResult !== true)
    ) {
      setValue(initValue)
      setKey(k => k + 1) // Forces input rerender, and reverts invalid value

      const invalidValueErrMsg = maxValidationResult || minValidationResult
      const hasInvalidValueErrMsg = typeof invalidValueErrMsg === 'string'
      const errMsg = invalidRange
        ? INVALID_RANGE_INPUT_ERROR_NOTIF
        : hasInvalidValueErrMsg
          ? {
            title: INVALID_VALUE_ERROR_NOTIF_TITLE,
            content: invalidValueErrMsg
          }
          : null

      if (errMsg) toast.error(errMsg)
    } else if (hasChanged) {
      onChangeFromProps(value)
    }
  }, [value, onChangeFromProps])

  const onChangeMin = useCallback(
    ({ target: { value: newMin } }) => {
      setHasChanged(true)
      setValue({ ...value, min: newMin && Number(newMin) })
    },
    [value, setValue]
  )

  const onChangeMax = useCallback(
    ({ target: { value: newMax } }) => {
      setHasChanged(true)
      setValue({ ...value, max: newMax && Number(newMax) })
    },
    [value, setValue]
  )

  return (
    <Box display='flex' alignItems='center' {...props}>
      <NumberInput
        key={`min:${key}`}
        value={value.min}
        onChange={onChangeMin}
        onBlur={onBlur}
        thousandSeparator={thousandSeparator}
        placeholder={placeholder}
        flex='1 1 auto'
        css='min-width: 0'
      />

      <Text as='div' mx={2}>to</Text>

      <NumberInput
        key={`max:${key}`}
        value={value.max}
        onChange={onChangeMax}
        onBlur={onBlur}
        thousandSeparator={thousandSeparator}
        placeholder={placeholder}
        flex='1 1 auto'
        css='min-width: 0'
      />
    </Box>
  )
}

export default NumberRange
