import { useEffect } from 'react'
import setDateTimeout from 'common/util/setDateTimeout'

const useDateTimeout = (fn, d, deps = []) => {
  useEffect(() => {
    if (!d) return

    const timeoutId = setDateTimeout(fn, d)
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [...deps])
}

export default useDateTimeout
