import { SkiptraceFormat } from 'types'

const downloadListSkiptrace = ({ format, listId }: {
  format: SkiptraceFormat
  listId: string
}) => {
  const w: any = window
  w.location = `/lists/v3/${listId}/skiptrace/download?format=${format}`
}

export default downloadListSkiptrace
