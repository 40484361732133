import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { string } from 'prop-types'
import flow from 'lodash/function/flow'
import createMatchParamsPropTypes from 'drivingfordollars/utils/createMatchParamsPropTypes'
import LeadsTable from 'drivingfordollars/components/LeadsTable'
import { fetchDriveLeads } from 'drivingfordollars/actions'
import { selectLeads } from 'drivingfordollars/selectors'

const KeyedLeadsTable = ({ match, ...props }) => (
  <LeadsTable key={match.params.id} style={TOP_ROUNDED_CORNERS_STYLES} {...props} />
)

KeyedLeadsTable.propTypes = createMatchParamsPropTypes({
  id: string.isRequired
})

const withState = connect(
  state => {
    const { value, count, isLoading, error } = selectLeads(state)
    const leads = Object.values(value)
    return { leads, count, isLoading, error }
  },
  (dispatch, ownProps) =>
    ({
      fetchLeads: (opts = {}) => {
        const { id } = ownProps.match.params
        return dispatch(fetchDriveLeads({ id, ...opts }))
      }
    })
)

export default flow(
  withState,
  withRouter
)(KeyedLeadsTable)

const TOP_ROUNDED_CORNERS_STYLES = {
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4
}
