import * as api from 'common/api'

type Response = null | {
  /** An array of FIPS with string type */
  coverage?: string[]
}

const fetchCoverage = (): Promise<Response> =>
  api.get('/cma/coverage')

export default fetchCoverage
