import React from 'react'
import { Button } from '@realsoftworks/decor'
import LoadingIcon from 'common/LoadingIcon'

const LoadingButton = ({
  loading,
  children,
  text = '',
  loadingText = '',
  disabled = false,
  ...props
}) => (
  <Button {...props} disabled={disabled || loading}>
    {loading && <LoadingIcon key='loading-icon'/>}
    {loading && ' '}
    {(loading && loadingText)
      ? loadingText
      : (text || children)}
  </Button>
)

export default LoadingButton
