import React, { useState } from 'react'
import { Box, Text } from '@realsoftworks/decor'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate } from 'common/util/date'
import SkiptraceEntry from './SkiptraceEntry'

const normalizeId = passedId => {
  const id = String(passedId)
  return id.length === 1
    ? id
    : id[0] === '0'
      ? normalizeId(id.substr(1))
      : id
}

const getName = (team: any[], userId) => {
  const user = team && team.find(u =>
    normalizeId(u.id) === normalizeId(userId)
  )

  return user?.name || 'Unknown'
}

const formatProviders = providers => {
  const populatedProviders: string[] = []
  if (providers?.skipGenie?.length > 0) populatedProviders.push('Skip Genie')
  if (Object.keys(providers?.reiGroup || {}).length > 0) populatedProviders.push('Batch Skip')
  const prefix = populatedProviders.length > 1 ? 'using providers' : 'using'
  return populatedProviders.length > 1
    ? `${prefix} ${populatedProviders.join(' and ')}`
    : `${prefix} ${providers?.skipGenie ? 'Skip Genie' : 'Batch Skip'}`
}

const SkiptraceResult = ({ skiptrace, team }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <Box>
      {/* Selected Skiptrace info */}
      {skiptrace
        ? (
          <Box
            pt={4}
            pb={2}
            css='cursor: pointer;'
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
          >
            <Text px={2} fontSize={1} color='secondary'>
              Skipped
              {' '}{formatDate(skiptrace.createdAt)}
              {' '}by
              {' '}{getName(team, skiptrace.userId)}
              {' '}{formatProviders(skiptrace.providers)}
            </Text>
            <FontAwesomeIcon
              icon={isCollapsed ? faChevronRight : faChevronDown}
            />
          </Box>
        )
        : null}
      {/* Selected Skiptrace Results */}
      {isCollapsed
        ? null
        : skiptrace.results?.length > 0
          ? skiptrace.results.map((result, i) =>
            <SkiptraceEntry
              date={skiptrace.createdAt}
              key={`result-${result.provider}-${i}`}
              provider={result.provider}
              result={result}
              index={i}
              total={skiptrace.results.length}
              mx={1}
              mb={i === (skiptrace.results.length - 1) ? 1 : 4}
            />)
          : (
            <Box px={3} pt={1} pb='6px' display='flex' alignItems='center'>
              <strong>NO RESULTS FOUND &mdash; NO CHARGE</strong>
            </Box>
          )}
    </Box>
  )
}

export default SkiptraceResult
