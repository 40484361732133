/* eslint "camelcase": "warn" */
import React, { useCallback, useRef, useEffect } from 'react'
import { Marker } from 'common/Map'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Card, Text, Box } from '@realsoftworks/decor'
import { Monetary } from 'common/format'
import SmallPill from './SmallPill'
import totalBaths from 'common/util/totalBaths'
import { orDash } from 'common/util/renderHelpers'
import get from 'lodash/object/get'
import getStyledColor from 'common/util/getStyledColor'
import useMouseEnter from 'common/util/hooks/useMouseEnter'

export const STATUS_COLORS = {
  active: 'teal.500',
  pending: 'blue.500',
  sold: 'red.500',
  expired: 'white',
  cancelled: 'white',
  withdrawn: 'white'
}

export const STATUS_LABELS = {
  active: 'Active',
  pending: 'Pending',
  sold: 'Sold',
  expired: 'Expired',
  cancelled: 'Cancelled',
  withdrawn: 'Withdrawn'
}

const PointDownMarker = styled(Marker)`
  transform: translate(-50%, -50%);
  left: 50%;
`

const MarkerWrap = styled(Card)`
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
`

const BottomArrow = styled.div`
  border: 6px solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-top-color: ${props => themeGet(`colors.${STATUS_COLORS[props.status]}`, STATUS_COLORS[props.status])(props)};
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const ListingCard = styled(Card)`
  display: inline-block;
`

const ListingPrice = ({ listing, onClick }) => {
  let icon = ''
  let property = listing
  if (listing.listing)
    ({ icon, listing: property } = listing)

  const { close_price, list_price, status, id } = property
  const price = close_price || list_price

  const memoClick = useCallback(() => onClick(property), [id])
  const color = STATUS_COLORS[status]
  const fontColor = color === 'white' ? 'text' : 'white'

  return (
    <ListingCard bg={STATUS_COLORS[status]} px={2} py={1} onClick={memoClick}>
      <Text color={fontColor} fontSize={0}>
        {icon}
        {' '}
        <Monetary useToK decimals={1}>{price}</Monetary>
      </Text>
    </ListingCard>
  )
}

const MLS_DETAILS_DELAY = 500
const MLSMarker = ({
  active,
  setActive,
  listings,
  lat,
  lng,
  onClick,
  isIncluded,
  ...props
}) => {
  const first = listings[0]
  const property = first.listing || first
  const hoverableRef = useRef()
  const isHovered = useMouseEnter(hoverableRef)
  const isHoveredRef = useRef(isHovered)
  const timeoutIdRef = useRef()
  const key = `${lat}||${lng}`
  const isActive = active === key

  // Displays the property details after delay
  const setActiveAfterDelay = useCallback(key => {
    // cancel any prior timeout instance
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)

    // If after the timeout, the marker is still being hovered, show details
    // by calling `setActive`
    const setActiveIfStillHovered = () => {
      const isStillHoveredAfterTimeout = isHoveredRef.current
      if (isStillHoveredAfterTimeout) setActive(key)
    }
    const timeoutId = setTimeout(setActiveIfStillHovered, MLS_DETAILS_DELAY)

    // Lets keep the timeout id so we can cancel as necessary
    // (e.g. when unmounted, when this function is called again before timeout)
    timeoutIdRef.current = timeoutId
  }, [])

  // On hover or unhover, show property details after a delay or hide
  // respectively
  useEffect(() => {
    // Lets also keep the reference to hover state so that our delayed function
    // can know if the marker is still being hovered
    isHoveredRef.current = isHovered

    if (isHovered && typeof setActive === 'function') setActiveAfterDelay(key)
    if (!isHovered && typeof setActive === 'function') setActive('')
  }, [isHovered])

  // On unmount, cancel any pending delayed function
  useEffect(() => () => {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
  }, [])

  return (
    <PointDownMarker
      className={isIncluded ? '' : 'print-hide'}
      css={isActive ? 'z-index: 9999; top: -110%;' : ''}
    >
      <MarkerWrap
        ref={hoverableRef}
        boxShadow={3}
        {...props}
        css='cursor: pointer; display: flex;'
      >
        {listings.map((l, i) => (
          isActive ? (
            <ListingDetails key={(l.id || i)} listing={l} onClick={onClick} />
          ) : (
            <ListingPrice key={(l.id || i)} listing={l} onClick={onClick} />
          )
        ))}
      </MarkerWrap>
      <BottomArrow status={property.status} />
    </PointDownMarker>
  )
}

export default MLSMarker

const ListingDetails = ({ listing, onClick }) => {
  let icon = ''
  let property = listing
  if (listing.listing)
    ({ icon, listing: property } = listing)

  const { close_price, list_price, status, id } = property
  const price = close_price || list_price

  const memoClick = useCallback(() => onClick(property), [id])
  const color = STATUS_COLORS[status]
  const fontColor = color === 'white' ? 'text' : 'white'

  return (
    <Box
      onClick={memoClick}
      minHeight='120px'
      minWidth='120px'
      display='flex'
      alignItems='flex-end'
      justifyContent='stretch'
      css={`
        background-color: ${getStyledColor(color)};
        background-image: url('${get(property, 'photos[0].url', '')}');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border: 2px solid ${getStyledColor(color)};
      `}
    >
      <Text
        p={2}
        display='block'
        color='white'
        fontSize={0}
        bg='rgba(0,0,0,0.25)'
      >
        {status && (
          <SmallPill
            mb={1}
            variant='neutral'
            css={`
              background-color: ${getStyledColor(color)};
              color: ${getStyledColor(fontColor)};
            `}
          >
            {STATUS_LABELS[status] || status}
          </SmallPill>
        )}
        <br />
        {icon}{' '}<Monetary>{price}</Monetary>
        <br />
        <Small>
          {orDash(property.beds)} beds
          {' '}/{' '}
          {orDash(totalBaths(property))} baths
          {' '}/{' '}
          {orDash(property.sqft)} sqft
        </Small>
      </Text>
    </Box>
  )
}

const Small = p => <Text fontSize={0} {...p} />
