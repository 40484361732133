/* eslint "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'

import { Heading, Box } from '@realsoftworks/decor'
import { getSubscription } from '../selectors'

import { DateTime, Monetary } from 'common/format'

class InvoiceInfo extends React.Component {
  render () {
    const { subscription, ...props } = this.props

    return (
      <Box className='invoice-info' {...props}>
        <Heading size={5} className='title'>Next Invoice</Heading>
        <p className='payment'>
          <DateTime format='MM/DD/YYYY'>{subscription.active_until}</DateTime>
          {' '}-{' '}
          <Monetary decimals={2}>{subscription.next_invoice_amount}</Monetary>
        </p>
      </Box>
    )
  }
}

InvoiceInfo = connect(
  state => ({
    subscription: getSubscription(state)
  }),
  {}
)(InvoiceInfo)

export default InvoiceInfo
