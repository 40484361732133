/* eslint "react/no-deprecated": "warn", "react/jsx-key": "warn", "no-class-assign": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn" */
import React, { Component } from 'react'
import styled from 'styled-components'
import compose from 'recompose/compose'
import { withRouter, Route, Switch, Link } from 'react-router-dom'

import { fetch, destroy } from '../actions'
import { fetchMetadata } from '../../mls/actions'
import { selectors as mlsSelectors } from '../../mls'
import { getMetadata } from '../../mls/selectors'

import BigLoader from 'common/BigLoader'
import BigFatalError from 'common/BigFatalError'
import { Button, FontIcon } from '../../../views/common'
import { connect } from 'react-redux'

import { Crumb } from '../../../views/common/Breadcrumbs'

import DeleteAlertDialog from './DeleteAlertDialog'
import DealAlertListingsHandler from './DealAlertListingsHandler'
import DealAlertEditHandler from './DealAlertEditHandler'
import DealAlertListingDetailHandler from './DealAlertListingDetailHandler'

const EditButtonDisabled = styled.div`
  cursor: not-allowed;
  opacity: .5;
`

class DealAlertDetailHandler extends Component {
  state = {
    dialog: false
  };

  onDelete = () => {
    this.props.destroy(this.props.match.params.id)
      .then(() => {
        this.props.history.push('/dealalerts')
      })
  };

  componentWillMount () {
    this.props.fetchMetadata()
    this.props.fetch(this.props.match.params.id)
  }

  openDialog = () => this.setState({ dialog: true });
  closeDialog = () => this.setState({ dialog: false });

  render () {
    var { loading, alert, match, error } = this.props
    var isDealAlertsSupported = this.props.metadata?.find(source => source.id === this.props.alert?.source)?.dealAlerts

    return (
      <div className='dealalert-detail'>
        
        {error ? <BigErrorPicker error={error}/> : loading ? <BigLoader/>

          : <div>
            <Crumb
              title={alert.name || '(no name)'}
              link={<Link to={`/dealalerts/${match.params.id}`}/>}
              tools={
                [
                  isDealAlertsSupported ?
                  <Link to={`/dealalerts/${match.params.id}/edit`} className='btn'>
                    <FontIcon className='fa fa-gear'/> Edit
                  </Link> :
                  <EditButtonDisabled className='btn'>
                    <FontIcon className='fa fa-gear'/> Edit
                  </EditButtonDisabled>,
                  <Button to='dealalertedit' className='btn' onClick={this.openDialog}>
                    <FontIcon className='fa fa-times'/> Delete
                  </Button>
                ]

              }
            />

            <DeleteAlertDialog
              open={this.state.dialog}
              onDelete={this.onDelete}
              processing={this.props.destroying}
              onClose={this.closeDialog}
            />

            <Switch>
              <Route path={`${this.props.match.path}/`} exact component={DealAlertListingsHandler}/>
              <Route path={`${this.props.match.path}/edit`} component={DealAlertEditHandler}/>
              <Route path={`${this.props.match.path}/:listingId`} component={DealAlertListingDetailHandler}/>
            </Switch>

          </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  var { id } = props.match.params
  var alert = state.entities.dealalerts[id]
  var detail = state.dealalerts.detail[id] || { loading: !alert }

  var { loading, error, destroying } = detail
  var metadata = getMetadata(state)
  var isMetadataLoading = mlsSelectors.isMetadataLoading(state)

  loading = loading || isMetadataLoading

  return {
    alert, loading, error, destroying, metadata
  }
}

const mapDispatchToProps =
{
  fetch: fetch,
  destroy: destroy,
  fetchMetadata: fetchMetadata
}

DealAlertDetailHandler = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DealAlertDetailHandler)

export default DealAlertDetailHandler

function BigErrorPicker (props) {
  var { error } = props
  return (error.status && error.status == 404) ? <Big404/> : <BigFatalError/>
}

function Big404 (props) {
  return (
    <div className='big-fatal-error'>
      <span className='big-fatal-icon fa-stack fa-5x'>
        <i className='fa fa-cloud fa-stack-2x'/>
        <i className='fa fa-question fa-inverse fa-stack-1x'/>
      </span>
      <h2>Nothing to see here</h2>
      <p>We weren't able to look up whatever it is you were looking for. If you think this is a mistake, let us know!</p>
    </div>
  )
}
