/* eslint "eqeqeq": "warn", "react/no-string-refs": "warn", "react/no-deprecated": "warn" */
import PropTypes from 'prop-types'
import React from 'react'

import PictureShow from '../../common/PictureShow'

function extractShape (w, h, slideRatio) {
  return w / h < slideRatio[0] / slideRatio[1] ? 'tall' : 'wide'
}

function hasShape (w, h) {
  return typeof w === 'number' && typeof h === 'number'
}

class Slide extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    slideRatio: PropTypes.number,
    slidePending: PropTypes.bool
  };

  static defaultProps = {
    slideRatio: 1
  };

  render () {
    var width = this.props.width
    var height = this.props.height
    var check = hasShape(width, height)
    var shapeClass = check ? extractShape(width, height, this.props.slideRatio) : 'stretch'

    var imgStyle

    if (shapeClass === 'tall')
      imgStyle = {
        maxHeight: height
      }
    else if (shapeClass === 'wide')
      imgStyle = {
        maxWidth: width
      }

    return (
      <div className='ps-slide'>
        <img className={['ps-img', shapeClass].join(' ')} style={imgStyle} src={this.props.src}/>
      </div>
    )
  }
}

class ListingSlideshow extends React.Component {
  state = {
    slideIndex: 0
  };

  render () {
    var { listing } = this.props
    var { photos } = listing

    if (!photos || photos.length == 0)
      return (
        <div className='empty-slideshow embed-responsive embed-responsive-4by3'>
          <div className='embed-responsive-item' style={{ backgroundColor: '#f2f2f2' }}>
            <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center', fontSize: '250%', color: '#777' }}>
              No Photos
            </div>
          </div>
        </div>
      )

    return (
      <div className='listing-slideshow'>
        <PictureShow slideBuffer={13} ratio={[4, 3]} ref='slideshow' onBeforeTransition={this.onSlideChange}>
          {Array.prototype.map.call(photos, (p, i) =>
            <Slide key={`${listing.source}-${listing.mls}-${i}`} src={p.url}/>
          )}
        </PictureShow>
        <div className='next' onClick={this.next}></div>
        <div className='prev' onClick={this.prev}></div>
        <div className='count'>{this.state.slideIndex + 1} of {photos.length}</div>
      </div>

    )
  }

  next = () => {
    this.refs.slideshow.next()
  };

  prev = () => {
    this.refs.slideshow.previous()
  };

  onSlideChange = (prev, slideIndex) => {
    this.setState({ slideIndex })
  };

  componentWillReceiveProps (nextProps) {
    this.setState({ slideIndex: 0 })
  }
}

export default ListingSlideshow
