import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { getPropertyTypes } from 'leadlists/selectors'
import { fetchPropertyTypes } from 'leadlists/actions'
import groupBy from 'lodash/collection/groupBy'
import ensureArray from 'common/util/ensureArray'
import FilterPill from '../FilterPill'
import { Box, Text } from '@realsoftworks/decor'
import startCase from 'lodash/string/startCase'
import ReactTooltip from 'react-tooltip'
import theme from 'theme'
import './PropertyTypesPills.less'

const withState = connect(
  state => ({ propertyTypesDefinition: getPropertyTypes(state) }),
  { fetchPropertyTypes }
)

export enum TooltipPosition {
  top = 'top',
  bottom = 'bottom',
}

const PropertyTypesPills = withState(({
  propertyTypesFilters: maybeSelectedTypes,
  propertyTypesDefinition: def,
  fetchPropertyTypes,
  filters,
  setFilters,
  color,
  tooltipPosition = TooltipPosition.top
}: {
  propertyTypesFilters?: number[]
  propertyTypesDefinition?: {
    loading?: boolean
    types?: {
      groups?: { [key: string]: number[] }
      types?: { [key: string]: string }
    }
  }
  fetchPropertyTypes: () => void
  filters?: { [key: string]: unknown }
  setFilters?: (filters: { [key: string]: unknown }) => void
  color: string
  tooltipPosition?: TooltipPosition
}) => {
  const selectedTypes = ensureArray(maybeSelectedTypes)
  const shouldLoad = !def?.loading
    && !def?.types

  useEffect(() => {
    if (shouldLoad) fetchPropertyTypes()
  }, [shouldLoad])

  const groupsDef = def?.types?.groups
  const typesDef = def?.types?.types || {}
  const isPropertyTypeDefReady = !!groupsDef && typeof groupsDef === 'object'
  const isAnyPropertyTypeFilterSet = !!selectedTypes.length

  const allSelectedGroups = useMemo(() => {
    if (!groupsDef || !isAnyPropertyTypeFilterSet) return []

    const typeGroups = Object.entries(groupsDef)
    const allSelectedGroups = typeGroups
      .filter(([_groupName, groupTypes]) =>
        Array.isArray(groupTypes)
        && groupTypes.every(groupMember =>
          selectedTypes.includes(groupMember)
        ))
      .map((([groupName]) => groupName))

    return allSelectedGroups
  }, [groupsDef, selectedTypes])

  const areAllTypesSelected = allSelectedGroups.length
    && allSelectedGroups.length === Object.values(groupsDef || {}).length

  const selectedGroups = useMemo<{ [key: string]: number[] }>(() => {
    if (!groupsDef) return {}
    const selectedGroups = groupBy(
      selectedTypes,
      selectedType => {
        const matchingGroupEntry =
          Object.entries(groupsDef)
            .find(([groupName, groupTypes]) =>
              groupTypes.includes(selectedType))

        const group = matchingGroupEntry?.[0] || 'UNKNOWN'
        return group
      }
    )

    return selectedGroups
  }, [selectedTypes, groupsDef])

  if (
    !isPropertyTypeDefReady
    || !isAnyPropertyTypeFilterSet
  ) return null

  if (areAllTypesSelected) {
    const handleDeleteFilters = typeof setFilters === 'function'
      ? () => {
        setFilters({
          ...filters || {},
          propertyTypes: undefined
        })
      }
      : undefined


    return (
      <Box p='2px'>
        <ReactTooltip
          id='all-property-types'
          effect='solid'
          arrowColor='transparent'
          backgroundColor={theme.colors.blue[900]}
          className='tooltip'
          overridePosition={({ top, left }) =>
            ({
              top: tooltipPosition === TooltipPosition.top
                ? top + 5
                : top - 5,
              left
            })}
          place={tooltipPosition}
        >
          <Box
            mx={-2}
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            maxWidth='600px'
          >
            {Object.entries(groupsDef || {})
              .map(([groupName, groupTypes]) =>
                <Box
                  flex={['1 1 100%', null, '1 1 33%']}
                  px={2}
                  style={{ whiteSpace: 'normal' }}
                >
                  <Text fontSize={1} display='block' pb={2}>{groupName}</Text>
                  {ensureArray(groupTypes)
                    .map(type => typesDef[type])
                    .filter((type): type is string => !!type)
                    .map(type => startCase(type.toLowerCase()))
                    .map(type =>
                      <Box key={type}>{type}</Box>)}
                </Box>)}
          </Box>
        </ReactTooltip>

        <FilterPill
          color={color}
          onDeleteFilter={handleDeleteFilters}
          data-tip
          data-for='all-property-types'
        >
          All Property Types
        </FilterPill>
      </Box>
    )
  }

  return Object.entries(groupsDef || {})
    .map(([groupName]) =>
      selectedGroups[groupName]
      && { groupName, selectedGroupTypes: selectedGroups[groupName] })
    .filter(Boolean)
    .map(({ groupName, selectedGroupTypes }) => {
      if (!selectedGroupTypes.length) return null
      const isAllOfGroupSelected = allSelectedGroups?.includes(groupName)
      const parsedSelectedTypes =
        selectedGroupTypes
          .map(type => typesDef[type])
          .filter((type): type is string => !!type)
          .map(type => startCase(type.toLowerCase()))

      const handleDeleteFilters = typeof setFilters === 'function'
        ? () => {
          const updatedSelectedTypes = selectedTypes
            .filter(type => !selectedGroupTypes.includes(type))

          setFilters({
            ...filters || {},
            propertyTypes: updatedSelectedTypes.length
              ? updatedSelectedTypes
              : undefined
          })
        }
        : undefined

      return (
        <Box p='2px' key={groupName}>
          <ReactTooltip
            id={groupName}
            effect='solid'
            arrowColor='transparent'
            backgroundColor={theme.colors.blue[900]}
            className='tooltip'
            overridePosition={({ top, left }) =>
              ({
                top: tooltipPosition === TooltipPosition.top
                  ? top + 5
                  : top - 5,
                left
              })}
            place={tooltipPosition}
          >
            <Text fontSize={1} display='block' pb={2}>{groupName}</Text>
            {parsedSelectedTypes.map(type =>
              <Box key={type}>{type}</Box>)}
          </ReactTooltip>

          <FilterPill
            color={color}
            onDeleteFilter={handleDeleteFilters}
            data-tip
            data-for={groupName}
          >
            {isAllOfGroupSelected ? 'All' : selectedGroupTypes.length}
            {' '}{groupName}
            {!isAllOfGroupSelected && ' types'}
          </FilterPill>
        </Box>
      )
    })
})

export default PropertyTypesPills
