import React from 'react'
import { connect } from 'react-redux'
import { Numeric } from 'common/format'
import { selectPrice, selectServicePrice } from '../selectors'

const ServiceCost = ({
  quantity = 1,
  service,
  servicePrice,
  creditPrice,
  ...props
}) => <Numeric decimals={2} {...props}>{quantity * servicePrice * creditPrice / 100}</Numeric>

const mapStateToProps = (state, ownProps) => ({
  servicePrice: selectServicePrice(state, ownProps.service),
  creditPrice: selectPrice(state)
})

export default connect(mapStateToProps)(ServiceCost)
