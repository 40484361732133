import {
  DEALALERT_FETCH_LISTING_DETAIL,
  DEALALERT_FETCH_LISTING_DETAIL_SUCCESS,
  DEALALERT_FETCH_LISTING_DETAIL_FAILURE,
  DEALALERT_TOGGLE_EXCLUDE,
  DEALALERT_TOGGLE_EXCLUDE_SUCCESS,
  DEALALERT_TOGGLE_EXCLUDE_FAILURE,
  DEALALERT_CREATE_LEAD,
  DEALALERT_CREATE_LEAD_SUCCESS,
  DEALALERT_CREATE_LEAD_FAILURE
} from '../../actions'

export default function (state = {}, { type, payload, meta }) {
  switch (type) {
    case DEALALERT_FETCH_LISTING_DETAIL:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { loading: true, error: null })
      }

    case DEALALERT_FETCH_LISTING_DETAIL_SUCCESS:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { loading: false })
      }

    case DEALALERT_FETCH_LISTING_DETAIL_FAILURE:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { loading: false, error: payload })
      }

    case DEALALERT_TOGGLE_EXCLUDE:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { excluding: true })
      }

    case DEALALERT_TOGGLE_EXCLUDE_SUCCESS:
    case DEALALERT_TOGGLE_EXCLUDE_FAILURE:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { excluding: false })
      }

    case DEALALERT_CREATE_LEAD:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { favoriting: true })
      }

    case DEALALERT_CREATE_LEAD_SUCCESS:
    case DEALALERT_CREATE_LEAD_FAILURE:
      return {
        ...state,
        [meta.listingId]: update(state[meta.listingId], { favoriting: false })
      }
  }

  return state
};

const getUpdater = defaultValue => (state = defaultValue, obj) => ({ ...state, ...obj })
var update = getUpdater({ loading: false, error: null, saving: false, favoriting: false, excluding: false })
