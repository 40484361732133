import qs from 'qs'
import { Schema, arrayOf, normalize } from 'normalizr'
import api from 'common/api'
import fetch from 'common/fetch'
const ENDPOINT = '/property-leads'

export const lead = new Schema('lead')
export const leads = arrayOf(lead)
const leadsSchema = { items: leads }

const formatParams = params => {
  const formatted = {}
  Object.keys(params).forEach(key => {
    var v = params[key]
    if (Array.isArray(v))
      v = v.join('|')

    formatted[key] = v
  })
  return formatted
}

// TAG ACTIONS ---------------------------------------------------
export const SEARCH_MARKETS = 'MARKETS_SEARCH'
export const SEARCH_MARKETS_SUCCESS = 'MARKETS_SEARCH_SUCCESS'
export const SEARCH_MARKETS_FAILURE = 'MARKETS_SEARCH_FAILURE'

export function searchMarkets () {
  return dispatch => {
    dispatch({ type: SEARCH_MARKETS })
    return api.get(`${ENDPOINT}/markets`)
      .then(res => {
        if (res.error)
          return dispatch({ type: SEARCH_MARKETS_FAILURE, payload: res.error })

        return dispatch({ type: SEARCH_MARKETS_SUCCESS, payload: res })
      })
  }
}

export const SELECT_MARKET = 'SELECT_MARKET'

export function selectMarket (marketId) {
  return dispatch => {
    dispatch({ type: SELECT_MARKET, meta: { params: { marketId } } })
  }
}
/*
export const UPDATE_SELECTED_LEADS = "UPDATE_SELECTED_LEADS";

export function updateSelected(selected, isToggleAll) {
  return (dispatch) => {
    dispatch({type: UPDATE_SELECTED_LEADS, meta: {params: {selected, isToggleAll}}});
  }
} */

export const ADD_LEADS_TO_CUSTOM_PACKAGE = 'ADD_LEADS_TO_CUSTOM_PACKAGE'

export function addLeadsToCustomPackage (leadIds, marketId) {
  return dispatch => {
    const payload = leadIds
    dispatch({ type: ADD_LEADS_TO_CUSTOM_PACKAGE, payload, meta: { params: { marketId } } })
  }
}

export const DELETE_LEADS_FROM_CUSTOM_PACKAGE = 'DELETE_LEADS_FROM_CUSTOM_PACKAGE'

export function deleteLeadsFromCustomPackage (leadIds, marketId) {
  return dispatch => {
    const params = { marketId }
    if (leadIds === 'all')
      params.deleteAll = true

    dispatch({ type: DELETE_LEADS_FROM_CUSTOM_PACKAGE, payload: leadIds, meta: { params } })
  }
}

export const SEARCH_TYPES = 'TYPES_SEARCH'
export const SEARCH_TYPES_SUCCESS = 'TYPES_SEARCH_SUCCESS'
export const SEARCH_TYPES_FAILURE = 'TYPES_SEARCH_FAILURE'
export function searchTypes (counties) {
  const ids = counties.map(c => c.id).join('|')

  return dispatch => {
    dispatch({ type: SEARCH_TYPES })
    return fetch(`${ENDPOINT}/types/${ids}`)
      .then(res => res.json())
      .then(payload => {
        dispatch({ type: SEARCH_TYPES_SUCCESS, payload })
        return payload
      })
      .catch(error => dispatch({ type: SEARCH_TYPES_FAILURE, payload: error }))
  }
}

export const SEARCH_MARKET_LEADS = 'SEARCH_MARKET_LEADS'
export const SEARCH_MARKET_LEADS_SUCCESS = 'SEARCH_MARKET_LEADS_SUCCESS'
export const SEARCH_MARKET_LEADS_FAILURE = 'SEARCH_MARKET_LEADS_FAILURE'

export function search (params) {
  return dispatch => {
    const formatted = formatParams(params)

    dispatch({ type: SEARCH_MARKET_LEADS, meta: { params } })
    var body = qs.stringify(formatted)
    return api.get((`${ENDPOINT}/leads/?${body}`))
      .then(payload => {
        if (payload.error)
          return dispatch({ type: SEARCH_MARKET_LEADS_FAILURE, payload: payload.error, meta: { params } })

        payload = normalize(payload, leadsSchema)
        return dispatch({ type: SEARCH_MARKET_LEADS_SUCCESS, payload, meta: { params } })
      })
  }
}

export const CREATE_PACKAGE_LEADS = 'CREATE_PACKAGE_LEADS'
export const CREATE_PACKAGE_LEADS_SUCCESS = 'CREATE_PACKAGE_LEADS_SUCCESS'
export const CREATE_PACKAGE_LEADS_FAILURE = 'CREATE_PACKAGE_LEADS_FAILURE'

export function createPackage (params) {
  return dispatch => {
    const formatted = formatParams(params)

    dispatch({ type: CREATE_PACKAGE_LEADS, meta: { params } })
    var body = JSON.stringify(formatted)

    return fetch(`${ENDPOINT}/packages`, {
      method: 'POST',
      body
    })
      .then(res => res.json())
      .then(payload => {
        dispatch({ type: CREATE_PACKAGE_LEADS_SUCCESS, payload, meta: { params } })
        return payload
      })
      .catch(error => dispatch({ type: CREATE_PACKAGE_LEADS_FAILURE, payload: error, meta: { params } }))
  }
}
