import keyBy from 'lodash/collection/indexBy'

import * as CONSTANTS from '../actions'

export default (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case CONSTANTS.SEARCH_COUNTIES_SUCCESS:
      return {
        ...state,
        ...(keyBy(payload, v => v.fips))
      }
  }

  return state
}
