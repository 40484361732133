/* eslint "eqeqeq": "warn", "react/no-deprecated": "warn", "no-prototype-builtins": "warn", "react/no-find-dom-node": "warn", "no-class-assign": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import BasicInput from './../internal/BasicInput'

import { parse, format } from 'common/util/number'

import uncontrollable from 'uncontrollable'

const numberFormat = '0,000'

function calculateNewSelection (oldValue, oldIdx, newValue) {
  var oldLength = oldValue.length

  var dotIndex = newValue.indexOf('.')
  var oDotIndex = oldValue.indexOf('.')

  var newIdx = oldIdx

  if (newValue != oldValue)
    if (oldValue == '.') { // handles case where they hit the decimal point first
      newIdx = 2 // newValue will be "0.00" so we want it right after the dot
    } else if (oldValue.indexOf('.') == -1 && dotIndex != -1) {
    // this gets triggered on the first number. value goes from
    // 1 => 1.00 so we want to keep the selection where its
      newIdx = oldIdx
    } else {
      oldLength = oldValue.length
      var oldPreDecimalLength = oldValue.split('.')[0].length
      var newPreDecimalLength = newValue.split('.')[0].length

      // this works for everything before the decimal point
      newIdx = Math.max(0, newValue.length - oldLength + oldIdx)

      // if they just typed the dot, then keep the index
      if (oldIdx > 0 && oldValue[oldIdx - 1] == '.')
        newIdx = newPreDecimalLength - oldPreDecimalLength + oldIdx

      // for cases where we are after the dot
      if (oldIdx >= oDotIndex + 1) {
        var decimalPoints = newValue.length - dotIndex - 1
        if (decimalPoints == 2)
          newIdx = oldIdx
      }
    }

  return newIdx
}

class NumberInput extends React.Component {
  constructor (props) {
    super(props)

    this.onKeyDown = this.onKeyDown.bind(this)
    this.onChange = this.onChange.bind(this)

    this.format = props.format

    if (this.props.decimals === true)
      this.format = this.format + '.00[00000]'

    this.state = {
      inputValue: format(this.props.value, { format: this.format })
    }
  }

  getMask () {
    var str = '0-9'

    if (this.props.negative)
      str += '\\-'

    if (this.props.decimals)
      str += '\\.'

    str = `[${str}]`

    if (this.maskStr !== str) {
      this.mask = new RegExp(str)
      this.maskStr = str
    }

    return this.mask
  }

  onKeyDown (e) {
    var key = e.key
    var field = e.target
    var value = field.value
    var selIdx = field.selectionStart
    var selEnd = field.selectionEnd

    if (this.props.decimals)
      if (key == '.') {
        field.value = value.replace('.', '')
        field.selectionStart = field.selectionEnd = selIdx
      } else {
        var dotIndex = value.indexOf('.')
        if (key == 'Backspace' && selIdx == selEnd) {
          if (selIdx - 1 == dotIndex) {
            // can't backspace the dot!
            e.preventDefault()
            field.selectionStart = field.selectionEnd = field.selectionStart - 1
          }
        } else if (key == 'Delete' && selIdx == selEnd && selIdx == dotIndex) {
          // can't delete the dot!
          e.preventDefault()
          field.selectionStart = field.selectionEnd = field.selectionStart + 1
        }
      }

    var backspaceComma = key == 'Backspace' && value[selIdx - 1] == ','
    var deleteComma = key == 'Delete' && value[selIdx] == ','

    if (backspaceComma || deleteComma) {
      var newIdx

      if (backspaceComma)
        newIdx = selIdx - 1
      else
        newIdx = selIdx + 1

      field.selectionStart = field.selectionEnd = newIdx
    }

    this.props.onKeyPress && this.props.onKeyPress(e)
  }

  onChange (e) {
    let raw = e.target.value; let v = null

    raw = this.preformatRaw(raw)

    if (raw !== '')
      v = parse(raw)

    this.props.onChange(v)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      var v = nextProps.value

      if (v !== this.props.value) {
        var inputValue = format(v, { format: this.format })
        this.setState({ inputValue }, () => this.updateInput())
      }
    }
  }

  preformatRaw (raw) {
    if (raw == '.00')
      raw = ''

    if (raw == '.')
      raw = '0.00'

    return raw
  }

  formatRaw (raw, parsed) {
    var formatted = format(parsed, { format: this.format })
    return formatted
  }

  componentDidMount () {
    this.updateInput()
  }

  updateInput () {
    var node = ReactDOM.findDOMNode(this)
    var oldIdx = node.selectionStart
    var oldValue = node.value

    node.value = this.state.inputValue

    if (document.activeElement !== node)
      return

    var newIdx = this.state.selectionStart || calculateNewSelection(oldValue, oldIdx, this.state.inputValue)

    node.selectionStart = newIdx
    node.selectionEnd = newIdx
  }

  render () {
    const { className, type, decimals, allowNegative, inputMask, ...rest } = this.props

    return (
      <BasicInput
        {...rest}
        inputMask={this.getMask()}
        value={undefined}
        className={classNames('number-input', className)}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
      />
    )
  }
}

NumberInput.propTypes = {
  decimals: PropTypes.bool,
  negative: PropTypes.bool
}

NumberInput.defaultProps = {
  decimals: false,
  allowNegative: true,
  format: numberFormat
}
NumberInput = uncontrollable(NumberInput, { value: 'onChange' })

export default NumberInput
