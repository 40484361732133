import { SEARCH_LEADS_SUCCESS, LEADS_UPDATE_SUCCESS, READ_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_LEADS_SUCCESS:
    case LEADS_UPDATE_SUCCESS:
    case READ_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.entityfield)
        return {
          ...state,
          ...action.payload.entities.entityfield
        }

      return state
  }
  return state
}
