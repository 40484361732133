import React, { FC, useMemo } from 'react'
import { Box, Heading } from '@realsoftworks/decor'
import MonetaryRange from 'leadlists/components/filters/MonetaryRange'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import DateRange from 'leadlists/components/filters/DateRange'
import { NumberRange as NumberRangeType, DateRange as DateRangeType } from 'types'
import get from 'lodash/object/get'
import set from 'common/util/set'
import flow from 'lodash/function/flow'

type ForeclosureFilters = {
  preforeclosure: {
    recordingDate: DateRangeType
    defaultAmount: NumberRangeType
    auctionDate: DateRangeType
    openingBid: NumberRangeType
  }
}

type FieldProps<T> = {
  onChange: (value: T) => void,
  value: T
}

const passFirstParam = a => a

const ForeclosureSection: FC<{
  filters: ForeclosureFilters
  onChange: (filters: ForeclosureFilters) => void
  openSection: string | null
  toggleSection: (section: string | null) => void
}> = ({
  filters,
  onChange,
  openSection,
  toggleSection
}) => {
  const SECTION_KEY = 'FORECLOSURE'
  const isSectionOpen = openSection === SECTION_KEY

  const createFieldProps = (
    key: string,
    valueGetter: (a, b) => any = passFirstParam,
    valueKey: string = 'value'
  ) => ({
    onChange: flow(
      valueGetter,
      value =>
        onChange(set(
          `preforeclosure.${key}`,
          value,
          filters
        ))
    ),
    [valueKey]: get(filters, `preforeclosure.${key}`)
  })

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    get(filters, 'preforeclosure.recordingDate'),
    get(filters, 'preforeclosure.defaultAmount'),
    get(filters, 'preforeclosure.auctionDate'),
    get(filters, 'preforeclosure.openingBid'),
  ]

  return useMemo(() => (
    <AccordionFilterSection
      mt={5}
      heading='Pre-Foreclosure'
      toggleSection={toggleSection(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >

      <Box mx={-3} display='flex' flexWrap='wrap' flexDirection='column'>
        <Box
          mx={3}
          mb={5}
          py={5}
          css={`
            border-bottom: 1px solid #ddd;
          `}
        >
          <Heading mb={2} size={5}>Lis Pendens & Notice of Default</Heading>
          <Box display='flex'>
            <Box px={3} py={2} flex='1 1 240px' minWidth='240px'>
              <Heading mb={2} size={6}>Recording Date</Heading>
              <DateRange
                placeholder='Any'
                {...createFieldProps('recordingDate') as FieldProps<DateRangeType>}
              />
            </Box>
            <Box px={3} py={2} flex='1 1 240px' minWidth='240px'>
              <Heading mb={2} size={6}>Default Amount</Heading>
              <MonetaryRange
                placeholder='Any'
                {...createFieldProps('defaultAmount') as FieldProps<NumberRangeType>}
              />
            </Box>
          </Box>
        </Box>
        <Box mx={3}>
          <Heading mb={2} size={5}>Notice of Sale & Notice of Trustees Sale</Heading>
          <Box display='flex'>
            <Box px={3} py={2} flex='1 1 240px' minWidth='240px'>
              <Heading mb={2} size={6}>Auction Date</Heading>
              <DateRange
                placeholder='Any'
                {...createFieldProps('auctionDate') as FieldProps<DateRangeType>}
              />
            </Box>
            <Box px={3} py={2} flex='1 1 240px' minWidth='240px'>
              <Heading mb={2} size={6}>Opening Bid</Heading>
              <MonetaryRange
                placeholder='Any'
                {...createFieldProps('openingBid') as FieldProps<NumberRangeType>}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </AccordionFilterSection>
  ), rerenderDeps)
}

export default ForeclosureSection
