/* eslint "no-case-declarations": "warn" */
import { combineReducers } from 'redux'

import * as CONSTANTS from '../actions'

const filterTs = (state, action, reducer) => {
  const { ts } = action.meta
  const existingTs = state ? state.ts : 0

  if (ts > existingTs)
    return reducer(state, action)

  return state
}

const data = (state = { ts: 0, items: null }, action) => {
  const { type, payload, meta = { ts: 0 } } = action
  const { ts = 0 } = meta

  switch (type) {
    case CONSTANTS.CLEAR:
      return { ts: 0, items: null, loading: false }
    case CONSTANTS.FETCH:
      return { ...state, loading: true }
    case CONSTANTS.FETCH_SUCCESS:
      return filterTs(state, action, () => ({ items: payload.items, loading: false, ts }))
  }

  return state
}

const paging = (state = { ts: 0 }, action) => {
  const { type, payload, meta = { ts: 0 } } = action
  const { ts } = meta
  switch (type) {
    case CONSTANTS.CLEAR:
      return { ts: 0 }

    case CONSTANTS.FETCH_SUCCESS:
      const { offset, limit } = meta.params
      const { count } = payload
      const length = payload.items.length
      return filterTs(state, action, () => ({
        offset, limit, length, count, ts
      }))
  }

  return state
}

export default combineReducers({
  data,
  paging
})
