import { useState, useEffect, memo, useRef, useMemo } from 'react'
import { bool, arrayOf, number, func, object } from 'prop-types'
import { useMapApi } from 'common/Map'
import useListenerAutoCleaner from 'common/useListenerAutoCleaner'
import RadiusCircle from './RadiusCircle'
import theme from '../../../../theme'
import { geoJsonToLatLngLiteral } from './utils'

const UP = 0
const RIGHT = 1
const DOWN = 2
const LEFT = 3
const DIRECTIONS = [UP, RIGHT, DOWN, LEFT]

// NOTE: don't recreate the polygon and styles objects if not
//   necessary to prevent recalculations due to props change
const RadiusCircleDrawer = memo(({
  drawing,
  center,
  radius,
  minRadius = 0,
  maxRadius = Infinity,
  onFinish,
  planeStyles = {},
  markerStyles = {},
  ...props
}) => {
  const { map, maps } = useMapApi()
  const [mapObjects, setMapObjects] = useState({})
  const cleanListenerLater = useListenerAutoCleaner()
  const ghostCircleRef = useRef({ INIT: 'INIT' })
  const handlerInfoRef = useRef({ onFinish, minRadius, maxRadius })

  useEffect(() => {
    handlerInfoRef.current = { onFinish, minRadius, maxRadius }
  }, [onFinish, minRadius, maxRadius])

  updateMap({
    map,
    maps,
    drawing,
    center,
    radius,
    markerStyles,
    mapObjects,
    setMapObjects,
    cleanListenerLater,
    ghostCircleRef,
    handlerInfoRef
  })

  // Clickable map objects stops mousemove events propagation,
  // which the draggable radius selector events depends on
  const unclickablePlaneStyles = useMemo(() => ({
    ...planeStyles,
    clickable: false
  }), [planeStyles])

  const unclickableGhostStyles = useMemo(() => ({
    ...planeStyles,
    clickable: false,
    fillColor: 'transparent'
  }), [planeStyles])

  return (
    <>
      <RadiusCircle
        center={center}
        radius={radius}
        planeStyles={unclickablePlaneStyles}
        {...props}
      />

      {/* Ghost circle that's visible while resizing */}
      {useMemo(() => (
        <RadiusCircle
          center={center}
          radius={0}
          planeStyles={unclickableGhostStyles}
          ref={ghostCircleRef}
          {...props}
        />
      ), [center, unclickablePlaneStyles])}
    </>
  )
})

export default RadiusCircleDrawer

RadiusCircleDrawer.propTypes = {
  drawing: bool.isRequired,
  center: arrayOf(number.isRequired).isRequired,
  radius: number.isRequired, // radius in miles
  minRadius: number, // also in miles
  maxRadius: number, // also in miles
  onFinish: func.isRequired,
  planeStyles: object,
  markerStyles: object
}

/* Subprocedures */
const updateMap = ({
  map,
  maps,
  drawing,
  center,
  radius,
  markerStyles,
  mapObjects,
  setMapObjects,
  cleanListenerLater,
  ghostCircleRef,
  handlerInfoRef
}) => {
  useEffect(() => {
    // Note that we create mapObjects only once per instance
    if (!map) return

    const commonOptions = direction =>
      ({
        position: geoJsonToLatLngLiteral(moveLatLngByMiles(radius, direction, center)),
        map: drawing ? map : null,
        cursor: [UP, DOWN].includes(direction) ? 'col-resize' : 'row-resize',
        icon: {
          ...DEFAULT_CIRCLE_STYLES({ maps }),
          ...markerStyles
        }
      })

    // Update handlers, then exit
    if (mapObjects.handles) {
      mapObjects.handles.forEach((handle, i) => {
        handle.setOptions(commonOptions(i))
      })

      return
    }

    // Initiate handlers
    const handles = DIRECTIONS.map(i => {
      const cursor = [UP, DOWN].includes(i) ? 'col-resize' : 'row-resize'
      const handle = new maps.Marker(commonOptions(i))

      let isStillPainting
      let hasMouseUpped
      let mouseMoveListener

      const handleMouseDown = () => {
        isStillPainting = false
        hasMouseUpped = false
        map.setOptions({ draggable: false, draggableCursor: cursor, draggingCursor: cursor })
        mouseMoveListener = map.addListener('mousemove', handleMouseMove)
      }

      const handleMouseMove = ({ latLng }) => {
        if (isStillPainting) return

        const animate = () => {
          if (hasMouseUpped) return

          isStillPainting = false

          const uncappedDistanceInMeters = maps.geometry.spherical
            .computeDistanceBetween(latLng, new maps.LatLng(...center))
          const distanceInMiles = metersToMiles(uncappedDistanceInMeters)
          const { minRadius, maxRadius } = handlerInfoRef.current

          const distanceInMeters = distanceInMiles > maxRadius
            ? milesToMeters(maxRadius)
            : distanceInMiles < minRadius
              ? milesToMeters(minRadius)
              : uncappedDistanceInMeters

          ghostCircleRef.current.setRadius(distanceInMeters)
        }

        isStillPainting = true
        requestAnimationFrame(animate)
      }

      const handleMouseUp = () => {
        hasMouseUpped = true
        const miles = metersToMiles(ghostCircleRef.current.getRadius())

        map.setOptions({ draggable: true, draggableCursor: '', draggingCursor: '' })
        mouseMoveListener.remove()
        ghostCircleRef.current.setRadius(0)
        handlerInfoRef.current.onFinish(miles)
      }

      const mouseDownListener = handle.addListener('mousedown', handleMouseDown)
      const mouseUpListener = handle.addListener('mouseup', handleMouseUp)

      cleanListenerLater(`mouseDownListener-${i}`, mouseDownListener)
      cleanListenerLater(`mouseUpListener-${i}`, mouseUpListener)

      return handle
    })

    setMapObjects({ handles })
  }, [map, radius, center, drawing, markerStyles])
}

/* Constants */

const { colors } = theme

const DEFAULT_CIRCLE_STYLES = ({ maps }) =>
  ({
    path: maps.SymbolPath.CIRCLE,
    scale: 6,
    strokeWeight: 0,
    fillColor: colors.yellow[500],
    fillOpacity: 1
  })

/* Utils */

const moveLatLngByMiles = (miles, directionalIndex, latLng) => {
  switch (directionalIndex) {
    case UP: // offset to the top
      return addMilesToLatLng([miles, 0], latLng)
    case RIGHT: // to the right
      return addMilesToLatLng([0, miles], latLng)
    case DOWN: // to the bottom
      return addMilesToLatLng([-miles, 0], latLng)
    case LEFT: // to the left
      return addMilesToLatLng([0, -miles], latLng)
    default:
  }
}

// Thanks to
// https://stackoverflow.com/questions/7477003/calculating-new-longitude-latitude-from-old-n-meters
const EARTH_RADIUS_MILES = 3958.8
const addMilesToLatLng = ([dx, dy], [lat, lng]) => {
  const { PI, cos } = Math
  const rad = EARTH_RADIUS_MILES

  const newLat = lat + (dy / rad) * (180 / PI)
  const newLng = lng + (dx / rad) * (180 / PI) / cos(lat * PI / 180)

  return [newLat, newLng]
}

const MILES_PER_METERS = 0.000621371
const metersToMiles = m => m * MILES_PER_METERS
const milesToMeters = mi => mi / MILES_PER_METERS
