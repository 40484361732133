import React, { useMemo } from 'react'
import { formatDateShort } from 'common/util/date'
import { Box } from '@realsoftworks/decor'
import isNumber from 'common/util/isNumber'
import get from 'lodash/object/get'
import { ColorPairs } from 'theme'
import PropertyTypesPills from './PropertyTypesPills'
import FilterPill from './FilterPill'
import pick from 'lodash/object/pick'
import curry from 'lodash/function/curry'
import GeoPills from './GeoPills'
import ensureArray from 'common/util/ensureArray'

const FiltersPills = ({
  filters: rawFilters,
  onDeleteFilter,
  setFilters,
  tooltipPosition,
  ...props
}) => {
  const filters = useMemo(() => normalizeGeoFilters(rawFilters), [rawFilters])
  const displayTexts = useMemo(() => filtersToDisplayText(filters), [filters])

  if (!displayTexts.length) return null

  return (
    <Box as='section' display='flex' flexWrap='wrap' {...props}>
      {displayTexts
        .map(({ id, key, color, ...displayData }) =>
          'display' in displayData ? (
            <Box p='2px' key={id}>
              <FilterPill
                color={color}
                onDeleteFilter={typeof onDeleteFilter === 'function'
                  ? () => onDeleteFilter(key)
                  : undefined}
              >
                {displayData.display}
              </FilterPill>
            </Box>
        ) : (
          'custom' in displayData && displayData.custom && key === 'propertyTypes'
        ) ? (
          <PropertyTypesPills
            key={id}
            color={color}
            setFilters={setFilters}
            filters={filters}
            propertyTypesFilters={displayData.custom?.propertyTypes}
            tooltipPosition={tooltipPosition}
          />
        ) : (
          'custom' in displayData && displayData.custom && key === 'geo'
        ) ? (
          <GeoPills
            color={color}
            filters={filters}
            setFilters={setFilters}
            county={displayData.custom?.county}
            city={displayData.custom?.city}
            zip={displayData.custom?.zip}
          />
        ) : null)}
    </Box>
  )
}

export default FiltersPills

const filtersToDisplayText = (filters: any): Display[] => {
  const f = filters || {}

  const createEquityDisplay = ({
    label,
    parentKey,
    childKey,
    transform = String
  }: {
    label: string,
    parentKey: string,
    childKey: string,
    transform: (v: number) => string
  }) => {
    const isParentChecked = get(filters, `equityType.${parentKey}.isChecked`)
    const maybeValue = get(filters, `equityType.${parentKey}.${childKey}`)
    const value = isParentChecked && typeof maybeValue === 'number'
      ? transform(maybeValue)
      : null
    return createGenericDisplay(
      label,
      value,
      `equityType.${parentKey}.${childKey}`
    )
  }

  /*
   * NOTE: be mindful of order, as it is meant to be displayed in the same order
   */

  const sellerPropInfoFilters = [
    ['Beds', 'beds'],
    ['Baths', 'baths'],
    ['Year Built', 'yearBuilt'],
    ['Building Sqft', 'buildingSqft'],
    ['Lot Sqft', 'lotSqft'],
    ['Stories', 'stories'],
    ['Units', 'units'],
  ].map(([label, key]) =>
    createRangeDisplay(
      label,
      get(filters, `propertyDetails.${key}`),
      [`propertyDetails.${key}.max`, `propertyDetails.${key}.min`]
    ))

  /* Construct geo filters */
  const geoFilters = {
    key: 'geo',
    custom: pick(filters, 'city', 'county', 'zip'),
  }

  /* Construct sellerPropTypeFilters */
  const maybePropTypes = get(filters, 'propertyTypes')
  const propertyTypes = Array.isArray(maybePropTypes)
    ? maybePropTypes
    : []
  const propTypesDisplay = {
    key: 'propertyTypes',
    custom: { propertyTypes }
  }
  const sellerPropTypeFilters = [propTypesDisplay]

  const sellerOwnerTypeFilters = [
    ['Owner Occ.', 'individual.ownerOccupied'],
    ['In State', 'individual.absentee.inState'],
    ['Out Of State', 'individual.absentee.outOfState'],
    ['Out Of Country', 'individual.absentee.outOfCountry'],
    ['Military', 'individual.military'],
    ['Individual', 'isIndividual'],
    ['Trust Owned', 'isTrust'],
    ['Bank Owned', 'isBank'],
    ['Company Owned', 'isCompany'],
    ['Senior', 'taxExemptions.isSenior'],
    ['Homestead', 'taxExemptions.isHomestead'],
  ].map(([label, key]) =>
    createBoolDisplay(
      label,
      get(filters, `ownerType.${key}`),
      `ownerType.${key}`
    ))

  const sellerEquityFilters = [
    createEquityDisplay({
      label: 'Min Eq.',
      parentKey: 'highEquity',
      childKey: 'percentAtLeast',
      transform: v => `${v}%`
    }),
    createEquityDisplay({
      label: 'Min Eq.',
      parentKey: 'highEquity',
      childKey: 'dollarsAtLeast',
      transform: v => `$${v}`
    }),
    createEquityDisplay({
      label: 'Max Eq.',
      parentKey: 'lowEquity',
      childKey: 'percentAtMost',
      transform: v => `${v}%`
    }),
    createEquityDisplay({
      label: 'Max Eq.',
      parentKey: 'lowEquity',
      childKey: 'dollarsAtMost',
      transform: v => `$${v}`
    }),
    createEquityDisplay({
      label: 'Upside Down At Least',
      parentKey: 'upsideDown',
      childKey: 'upsideDownAtLeast',
      transform: v => `$${v}`
    }),

    ...[
      ['Free and Clear.', 'freeAndClear'],
      ['Unknown Equity', 'unknown'],
    ].map(([label, key]) =>
      createBoolDisplay(
        label,
        get(filters, `equityType.${key}`),
        `equityType.${key}`
      )),

    ...[
      ['Est. Value', 'estimatedValue'],
      ['Tax Value', 'taxValue'],
    ].map(([label, key]) =>
      createRangeDisplay(
        label,
        get(filters, `equityInfo.${key}`),
        [`equityInfo.${key}.max`, `equityInfo.${key}.min`],
        { format: formatThousandsToKUsd }
      )),

    createRangeDisplay(
      'Years Owned',
      get(filters, `equityInfo.ownershipLength`),
      [`equityInfo.ownershipLength.max`, `equityInfo.ownershipLength.min`]
    )
  ]

  const sellerForeclosureFilters = [
    ...[
      ['Unknown', 'unknown'],
      ['Lis Pendens', 'lisPendens'],
      ['Notice Of Default', 'noticeOfDefault'],
      ['Notice Of Sale', 'noticeOfSale'],
      ['Notice Of TrusteesSale', 'noticeOfTrusteesSale'],
    ].map(([label, key]) =>
      createBoolDisplay(
        `${label} Foreclosure Docs`,
        get(filters, `preforeclosure.documentTypes.${key}`),
        `preforeclosure.documentTypes.${key}`
      )),

    ...[
      ['Recording Date', 'recordingDate'],
      ['Auction Date', 'auctionDate'],
    ].map(([label, key]) =>
      createRangeDateDisplay(
        `Foreclosure ${label}`,
        get(filters, `preforeclosure.${key}`),
        [`preforeclosure.${key}.after`, `preforeclosure.${key}.before`]
      )),

    ...[
      ['Default Amount', 'defaultAmount'],
      ['Opening Bid', 'openingBid'],
    ].map(([label, key]) =>
      createRangeDisplay(
        `Foreclosure ${label}`,
        get(filters, `preforeclosure.${key}`),
        [`preforeclosure.${key}.min`, `preforeclosure.${key}.max`],
        { format: formatThousandsToKUsd }
      )),
  ]

  const buyerFilters = [
    createRangeDisplay(
      'Spent Amount',
      { min: f?.minSpend, max: f?.maxSpend },
      ['minSpend', 'maxSpend'],
      { format: formatThousandsToKUsd }
    ),

    createDateDisplay('Recent Deal After', f?.recentDeal, 'recentDeal'),
    createGenericDisplay('Min Deal Count', f?.minDeals, 'minDeals'),
  ]

  const lenderFilters = [
    createRangeDisplay(
      'Lent Amount',
      { min: f?.minLent, max: f?.maxLent },
      ['minLent', 'maxLent'],
      { format: formatThousandsToKUsd }
    ),

    createDateDisplay('Recent Loan After', f?.recentLoan, 'recentLoan'),
    createGenericDisplay('Min Loan Count', f?.minLoans, 'minLoans'),
  ]

  const otherFilters = [
    createBoolDisplay('New Only', f?.newOnly, 'newOnly')
  ]

  const displayTextFilteredWithId = [
    colorIt('teal', geoFilters),
    ...sellerPropInfoFilters.map(colorIt('lightTeal')),
    ...buyerFilters.map(colorIt('lightTeal')),
    ...lenderFilters.map(colorIt('lightTeal')),
    ...sellerPropTypeFilters.map(colorIt('yellow')),
    ...sellerOwnerTypeFilters.map(colorIt('blue')),
    ...sellerEquityFilters.map(colorIt('red')),
    ...sellerForeclosureFilters.map(colorIt('lightRed')),
    ...otherFilters.map(colorIt('neutral')),
  ]
    .map((filterDisplay, i) => ({ id: i.toString(), ...filterDisplay }))
    .filter(d =>
      (d as BasicDisplayData)?.display
      || (d as CustomDisplayData)?.custom)

  return displayTextFilteredWithId
}

type Key = string | string[]
type Range<T> = { min?: T | unknown, max?: T | unknown } | unknown
type BasicDisplayData = {
  key: Key,
  display: Element | string | null
}
type CustomDisplayData = {
  key: Key,
  custom: any
}
type DisplayData = BasicDisplayData | CustomDisplayData
type DisplayColored = DisplayData & { color: ColorPairs }
type Display = DisplayColored & { id: string }

const colorIt = curry((color: ColorPairs, display: DisplayData): DisplayColored =>
    ({ ...display, color }), 2)

const createGenericDisplay = <V extends unknown>(
  name: string,
  data: V,
  key: Key
): DisplayData =>
  ({
    key: key,
    display: data ? `${name}: ${data}` : null
  })

const createRangeDisplay = (
  name: string,
  data: Range<number>,
  key: Key,
  opts: {
    format: (v: unknown) => unknown
  } = {
    format: v => v
  }
) => {
  const { format } = opts
  const min: unknown = get(data, 'min')
  const max: unknown = get(data, 'max')

  return {
    key,
    display: isNum(min) && isNum(max)
      ? `${name}: ${format(min)} - ${format(max)}`
      : isNum(max)
        ? `${name}: ${format(max)} or less`
        : isNum(min)
          ? `${name}: ${format(min)} or more`
          : null
  }
}

const isNum = i => typeof i === 'number' && !isNaN(i)

const createRangeDateDisplay = (
  name: string,
  data: Range<Date>,
  key: Key
): DisplayData => {
  const afterDate = get(data, 'after')
  const beforeDate = get(data, 'before')
  const afterFormatted = isDate(afterDate) && formatDateShort(afterDate)
  const beforeFormatted = isDate(beforeDate) && formatDateShort(beforeDate)

  return {
    key,
    display: afterFormatted && beforeFormatted
      ? `${name}: ${afterFormatted} - ${beforeFormatted}`
      : beforeFormatted
        ? `${name}: before ${beforeFormatted}`
        : afterFormatted
          ? `${name}: after ${afterFormatted}`
          : null
  }
}

const createDateDisplay = (
  name: string,
  data: Date | unknown,
  key: Key
): DisplayData =>
  ({
    key,
    display: isDate(data) ? `${name}: ${formatDateShort(data)}` : null
  })

const isDate = (v: unknown): v is Date => v instanceof Date

const createBoolDisplay = (
  name: string,
  data: boolean | undefined,
  key: Key
): DisplayData =>
  ({
    key,
    display: data ? name : null
  })

const formatThousandsToKUsd = (num: number | unknown): string | null =>
  isNumber(num) && num >= 1000
    ? `$${num / 1000}k`
    : isNumber(num)
    ? num.toString()
    : null

const normalizeGeoFilters = filters =>
  ({
    ...filters,
    city: filters?.city || ensureArray(filters?.location?.city)
      .map(city => ({ category: 'city', id: city, details: city })),
    zip: filters?.zip || ensureArray(filters?.location?.zip)
      .map(zip => ({ category: 'zip', id: zip, details: zip })),
    county: filters?.county || ensureArray(filters?.location?.county)
      .map((fips, i) => {
        if (!filters?.location?.countyDesc?.[i]) return null
        return {
          category: 'county',
          id: fips,
          details: {
            fips,
            name: filters?.location?.countyDesc?.[i].split(', ')[0],
            state: filters?.location?.countyDesc?.[i].split(', ')[1]
          }
        }
      })
      .filter(Boolean)
  })
