import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Box, Caption, Card } from '@realsoftworks/decor'
import { STATUS_COLORS, STATUS_LABELS } from 'common/MLSMarker'
import useBooleanCookie from 'common/util/hooks/useBooleanCookie'
import { connect } from 'react-redux'
import { getCurrent } from 'users/selectors'

const LegendContainer = styled(Box)`
  cursor: pointer;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 40px;
  left: 10px;
  min-width: 110px;
`

const LegendItem = styled(Box)`
  display: flex;
  align-items: center;
`

const withConnect = connect(state =>
  ({ userId: (getCurrent(state) || {}).id }))
const CMAMapLegend = withConnect(({ userId, containerProps = {} }) => {
  const cookieKey = `${userId}:isCMAMapLegendOpen`
  const [show, setShow] = useBooleanCookie(cookieKey, true)

  if (!show)
    return (
      <LegendContainer p={2} onClick={() => setShow(true)} {...containerProps}>
        <Box p={1} display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Caption>Legend</Caption>
          <FontAwesomeIcon icon={faAngleUp} />
        </Box>
      </LegendContainer>
    )

  return (
    <LegendContainer
      p={2}
      onClick={() => setShow(false)} {...containerProps}
      className='print-hide'
    >
      <Box p={1} display='flex' justifyContent='space-between' alignItems='center' width='100%'>
        <Caption>Legend</Caption>
        <FontAwesomeIcon icon={faAngleDown} />
      </Box>
      {Object.entries(STATUS_COLORS).map(([k, c]) => (
        <LegendItem p={1} key={k}>
          <Card
            boxShadow={1}
            bg={c}
            width='16px'
            height='16px'
            style={{
              border: c !== 'white' ? '' : '1px solid #ccc'
            }}
            mr={2}
          />
          <Box>{STATUS_LABELS[k]}</Box>
        </LegendItem>
      ))}
    </LegendContainer>
  )
})

export default CMAMapLegend
