/* eslint "no-case-declarations": "warn", "no-lone-blocks": "warn" */
import { combineReducers } from 'redux'
import {
  FETCH_LISTS_SUCCESS,
  FETCH_LIST_SUCCESS,
  UPDATE_LIST_SUCCESS,
  FETCH_MEMBERS_SUCCESS,
  DELETE_LIST_SUCCESS,
  FETCH_DOWNLOADS_SUCCESS,
  FETCH_SKIPPABLE_COUNT,
  FETCH_SKIPPABLE_COUNT_SUCCESS,
  SUBMIT_SKIPTRACEORDER_SUCCESS,
  FETCH_GENERAL_DOWNLOAD_INFO_SUCCESS,
  SET_SELECTED_LIST_ITEMS,
  CLEAR_SKIPPABLE_COUNT,
  FETCH_SKIPPED_COUNT,
  FETCH_SKIPPED_COUNT_SUCCESS,
  CLEAR_SKIPPED_COUNT
} from './actions'

const entities = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_LISTS_SUCCESS:
    case FETCH_LIST_SUCCESS:
    case UPDATE_LIST_SUCCESS:
      return {
        ...state,
        ...payload.entities.list
      }

    case SUBMIT_SKIPTRACEORDER_SUCCESS: {
      return {
        ...state,
        [payload.id]: payload
      }
    }

    case DELETE_LIST_SUCCESS:
      const { [meta.id]: removed, ...rest } = state
      return rest
  }
  return state
}

const INITIAL_LISTS_STATE = { all: null }
const lists = (state = INITIAL_LISTS_STATE, { type, payload, meta }) => {
  switch (type) {
    case FETCH_LISTS_SUCCESS:
      // group lists by their type
      const byType = Object.keys(payload.entities.list || []).reduce((acc, id) => {
        acc.all.push(id)
        const type = payload.entities.list[id].type
        if (!acc[type]) acc[type] = []
        acc[type].push(id)
        return acc
      }, { all: [] })

      // if we requested a particular type and got none back,
      // populate it that type with an empty array
      const { params = {} } = meta
      if (params.type && !byType[params.type])
        byType[params.type] = []

      return byType

    case DELETE_LIST_SUCCESS:
      return Object.keys(state).reduce((acc, v) => {
        acc[v] = state[v]
        if (acc[v])
          acc[v] = acc[v].filter(v => v !== meta.id)

        return acc
      }, {})
  }
  return state
}

const members = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        [meta.id]: {
          ...payload,
          ...meta
        }
      }
  }
  return state
}

const downloads = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_DOWNLOADS_SUCCESS: {
      return {
        ...state,
        [meta.id]: payload
      }
    }

    case FETCH_GENERAL_DOWNLOAD_INFO_SUCCESS: {
      return {
        ...state,
        [meta.type]: { limits: payload }
      }
    }

    default:
      return state
  }
}

const skipableCounts = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SKIPPABLE_COUNT: {
      return {
        ...state,
        [meta.id]: {
          ...(state[meta.id] || {}),
          [meta.params.provider]: {
            count: null
          }
        }
      }
    };

    case FETCH_SKIPPABLE_COUNT_SUCCESS: {
      return {
        ...state,
        [meta.id]: {
          ...(state[meta.id] || {}),
          [meta.params.provider]: {
            count: payload,
            members: meta.params.members
          }
        }
      }
    }

    case CLEAR_SKIPPABLE_COUNT: {
      return {
        ...state,
        [payload.id]: undefined
      }
    }

    case SUBMIT_SKIPTRACEORDER_SUCCESS: {
      return {
        ...state,
        [payload.id]: {}
      }
    }
  }

  return state
}

const skippableCountsForSkippedCountDerivation = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SKIPPED_COUNT: {
      return {
        ...state,
        [meta.id]: null
      }
    };

    case FETCH_SKIPPED_COUNT_SUCCESS: {
      return {
        ...state,
        [meta.id]: payload
      }
    }

    case CLEAR_SKIPPED_COUNT:
    case SUBMIT_SKIPTRACEORDER_SUCCESS: {
      return {
        ...state,
        [payload.id]: null
      }
    }
  }

  return state
}

const selectedItems = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_LIST_ITEMS:
      return {
        ...state,
        [payload.id]: payload.list
      }
  }

  return state
}

export default combineReducers({
  entities,
  lists,
  members,
  downloads,
  skipableCounts,
  selectedItems,
  skippableCountsForSkippedCountDerivation
})
