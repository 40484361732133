/* eslint "react/display-name": "warn" */
import React from 'react'
import {
  Box,
  Card,
  Heading,
  Text
} from '@realsoftworks/decor'

const IMG_URL = 'https://propelio-cloudfront.s3-us-west-2.amazonaws.com/daniel-onboarding.png'

export default () => (
  <Box display='flex' justifyContent='center' flexWrap='wrap'>
    <Box pt={[3, 0]} css='position: relative;' width={252}>
      <Box css={`
        @media screen and (min-width: 48em) {
          position: absolute;
          left: 8px; right: 16px; bottom: 0;
        }
      `}
      >
        <Box
          src={IMG_URL}
          alt=''
          width={1}
          height='auto'
          as='img'
        />
      </Box>
    </Box>
    <Box flex={[undefined, 1]} pl={[0, 3]} pt={[0, 5]} pb={4} width={['100%', 'auto']}>
      <Card variant='blue-bordered' px={5} pt={5} pb={6}>
        <Heading size='6' lineHeight='25px' fontWeight={700} color='blue.900'>
          Hi, I’m Daniel from Propelio!
        </Heading>
        <Text fontSize='1' lineHeight='23px' color='blue.900'>
          I want to help you get the most out of your trial. Check out everything we have to offer below and you’ll be armed with the tools to grow your business!
        </Text>
      </Card>
    </Box>
  </Box>
)
