import React from 'react'
import Card from 'common/Card'
import styled from 'styled-components'

const CardWrap = styled(Card)`
  max-width: 500px;
`

const StatsCard = () => (
  <CardWrap p={5} borderTop='12px solid' borderColor='teal.500'>
    stats
  </CardWrap>
)

export default StatsCard
