
import React from 'react'

class Avatar extends React.Component {
  render () {
    var { avatar } = this.props.user

    return (
      <div className='avatar'>
        {avatar
          ? <img src={this.props.user.avatar || '/img/default-avatar.jpg'} />
          : <div className='initials'>
            {this.props.user.name.split(' ').map(s => s.charAt(0))}
          </div>}
      </div>
    )
  }
}

export default Avatar
