import React, { Fragment } from 'react'
import { string } from 'prop-types'

const Text = ({ children }) =>
  children
    .split('\n')
    .map((para, i, src) => (
      <Fragment key={i}>
        {para} {i !== src.length - 1 && (<br />)}
      </Fragment>
    ))

Text.propTypes = { children: string }

export default Text
