import PropTypes from 'prop-types'
import React from 'react'

import classNames from 'classnames'

import './paper.less'

class Paper extends React.Component {
  static propTypes = {
    /**
     * Children passed into the paper element.
     */
    children: PropTypes.node,

    /**
     * How much shadow to show
     */
    zDepth: PropTypes.number,

    /**
     * round corners
     */
    rounded: PropTypes.bool
  };

  static defaultProps = {
    rounded: false,
    zDepth: 0
  };

  render () {
    const {
      children,
      className,
      zDepth,
      rounded,
      ...rest
    } = this.props

    return (
      <div {...rest} className={classNames('decor', 'decorPaper', `decorPaper-z-${zDepth}`, className, { 'decorPaper-rounded': rounded })}>
        {children}
      </div>
    )
  }
}

export default Paper
