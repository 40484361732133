export const getData = state => state.leadlists.records.data
export const getPaging = state => state.leadlists.records.paging
export const getPropertyTypes = state => state.leadlists.propertyTypes

export const getSellerCounts = state => state.leadlists.counts.sellers
export const getBuyerCounts = state => state.leadlists.counts.buyers
export const getLenderCounts = state => state.leadlists.counts.lenders

export const getCounty = (state, id) => state.leadlists.counties[id]
export const getFilter = (state, id) => state.leadlists.filters[id] || {}
