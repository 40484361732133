import React, { Component } from 'react'

import LoadingIcon from 'common/LoadingIcon'
import SmallFatalError from 'common/SmallFatalError'

import DataContainer from './DataContainer'
import SiteListChart from './SiteListChart'

import { fetchRowConversions } from '../actions'
import { getRowConversions } from '../selectors'

class SiteListVisitorChart extends Component {
  render () {
    return (
      <DataContainer
        loadingElement={<TinyLoader />}
        load={() => fetchRowConversions(this.props.siteId)}
        selector={state => getRowConversions(state, this.props.siteId)}
        render={({ error, props }) => {
          if (error) return <SmallFatalError />
          return <SiteListChart data={props.data} yKey='count' />
        }}
      />
    )
  }
}

const TinyLoader = () => (
  <div className='tiny-loader'>
    <LoadingIcon />
  </div>
)

SiteListVisitorChart.propTypes = {}
SiteListVisitorChart.defaultProps = {}

export default SiteListVisitorChart
