/* eslint "eqeqeq": "warn", "react/no-string-refs": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import FormInput from '../../../views/form/FormInput.react'

class DateRange extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    startDate: PropTypes.object,
    minDate: PropTypes.string.isRequired,
    maxDate: PropTypes.string.isRequired
  };

  state = {
  };

  getDayOfWeek = dateStr => {
    var DAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    var date = new Date((dateStr || '').replace(/-/g, '/').replace(/[TZ]/g, ' '))
    var todaysDate = new Date()
    if (date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0))
      return <span className='today-date'>Today</span>

    return DAY[date.getDay()]
  };

  onChange = (field, value) => {
    value = value.date + 'T00:00:00Z'
    return this.props.onChange(field, value)
  };

  render () {
    return (
      <div className='date-range-component'>
        <span className='weekday-label'>{this.getDayOfWeek(this.props.minDate)}</span>
        <FormInput type='date' name='minDate' ref='datefield' minDate={this.props.startDate} value={{ date: this.props.minDate }} onChange={this.onChange.bind(this, 'minDate')}/>
        <span className='to-label'>to</span>
        <span className='weekday-label'>{this.getDayOfWeek(this.props.maxDate)}</span>
        <FormInput type='date' name='maxDate' ref='datefield' value={{ date: this.props.maxDate }} onChange={this.onChange.bind(this, 'maxDate')}/>
      </div>
    )
  }
}

export default DateRange
