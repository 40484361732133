import React from 'react'
import { arrayOf, shape, object, string, func, bool, oneOfType } from 'prop-types'
import moment from 'moment'
import { Box, Text, IconButton } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Attachment = ({ children, onDelete, isDeletable, ...props }) =>
  !!children && !!children.length && (
    <Box mx={1} {...props}>
      {children
        .map((a, i) => (
          <Box key={i} py={2} display='flex' alignItems='center'>
            <Box
              width={58}
              height={58}
              borderWidth={2}
              borderStyle='solid'
              borderColor='neutral.200'
              src={a.thumbnail}
              as='img'
            />

            <Box pl={3} flex='1'>
              <Text fontSize={1} color='blue.900'>{a.name}</Text>
              <Box mt={1}>
                <Text fontSize={0} color='neutral.500'>
                  Uploaded by {a.createdBy} {moment(a.createdAt).fromNow()}
                </Text>
              </Box>
            </Box>

            {isDeletable && (
              <IconButton variant='danger' onClick={() => { onDelete(a) }}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            )}
          </Box>
        ))}
    </Box>
  )

export default Attachment

Attachment.propTypes = {
  children: arrayOf(shape({
    thumbnail: string,
    name: string.isRequired,
    createdBy: string,
    createdAt: oneOfType([string, object])
  })),
  onDelete: func,
  isDeletable: bool
}
