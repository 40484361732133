import React, { FC, memo } from 'react'
import moment from 'moment'
import { Box, Text, Button } from '@realsoftworks/decor'
import { connect } from 'react-redux'
import { getCma } from 'cma/selectors'

const withState = connect((state, { leadId }) => ({
  // eslint-disable-next-line camelcase
  updateDateStr: getCma(state, leadId)?.updated_at
}))

const OldCmaNotice: FC<{
  updateDateStr?: string
  rerunCma: () => void
  [key: string]: any
}> = withState(({ updateDateStr, rerunCma, ...props }) => {
  if (!updateDateStr) return null

  const updateDate = moment(updateDateStr)

  if (!updateDate.isValid()) return null

  const now = moment()
  const ageInMos = now.diff(updateDate, 'months')
  const MIN_MONTHS_NOTICE = 1

  if (ageInMos < MIN_MONTHS_NOTICE) return null

  const ageInYears = Math.floor(ageInMos / 12)
  const timeAgo = ageInYears === 1
    ? 'a year'
    : ageInYears > 1
      ? `${ageInYears} years`
      : ageInMos === 1
        ? 'a month'
        : `${ageInMos} months`

  return (
    <Box p={4} bg='yellow.100' display='flex' flexDirection='column' alignItems='center' {...props}>
      <Text fontSize={1} color='yellow.800'>
        <strong>NOTE</strong>: This data you are viewing was generated over
        {' '}{timeAgo} ago
      </Text>
      <Button variant='primary' mt={3} onClick={rerunCma}>Click here to refresh</Button>
    </Box>
  )
})

export default memo(OldCmaNotice)
