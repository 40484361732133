import React from 'react'
import './field.less'
import cn from 'classnames'

import Label from '../Label'
import FormInput from '../FormInput'
import Hint from '../Hint'

export default function Field (props) {
  const { label, type, className, inputClassName, hint, error, ...rest } = props

  return (
    <div className={cn('decor', 'decorFormField', 'form-group', className)}>
      {/* TODO deprecated */}
      {label && <Label>{label}</Label>}
      {type && <FormInput type={type} className={inputClassName} {...rest} />}
      {hint && <Hint>{hint}</Hint>}

      {props.children}
    </div>
  )
};
