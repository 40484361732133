const countSituations = situations =>
  [
    situations && situations.isSenior,
    situations && situations.isHomestead,
    situations && situations.isTaxDelinquent,
    situations && situations.isForeclosure,
    situations && situations.isAbsenteeOwner,
    situations && situations.isVacant
  ].reduce((c, v) => c + (v ? 1 : 0), 0)

export default countSituations
