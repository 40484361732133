import React from 'react'
import flow from 'lodash/function/flow'
import * as api from 'common/api'
import { listArrToById, listByIdToArr } from 'common/util/convertList'
import { toast } from '@realsoftworks/decor'
import { WIDGET_LIMIT, PAGE_LIMIT } from './const'
import { selectNotifs, selectIsLoadingForFirstTime } from './selectors'
import qs from 'qs'
import throwAny from 'common/util/throwAny'

/* Action Types */
export const POLL_NOTIFS = 'notifications/POLL_NOTIFS'
export const FETCH_NOTIFS = 'notifications/REQUEST_RECEIVE_NOTIFS'
export const FETCH_NOTIFS_SUCCESS = 'notifications/REQUEST_RECEIVE_NOTIFS_SUCCESS'
export const FETCH_NOTIFS_FAILED = 'notifications/REQUEST_RECEIVE_NOTIFS_FAILED'

export const FETCH_PAGE_NOTIFS = 'notifications/FETCH_PAGE_NOTIFS'
export const FETCH_PAGE_NOTIFS_SUCCESS = 'notifications/FETCH_PAGE_NOTIFS_SUCCESS'
export const FETCH_PAGE_NOTIFS_FAILED = 'notifications/FETCH_PAGE_NOTIFS_FAILED'

export const FETCH_MORE_PAGE_NOTIFS = 'notifications/FETCH_MORE_PAGE_NOTIFS'
export const FETCH_MORE_PAGE_NOTIFS_SUCCESS = 'notifications/FETCH_MORE_PAGE_NOTIFS_SUCCESS'
export const FETCH_MORE_PAGE_NOTIFS_FAILED = 'notifications/FETCH_MORE_PAGE_NOTIFS_FAILED'

export const TOGGLE_NOTIF = 'notifications/TOGGLE_NOTIF'
export const TOGGLE_NOTIF_SUCCESS = 'notifications/TOGGLE_NOTIF_SUCCESS'
export const TOGGLE_NOTIF_FAILED = 'notifications/TOGGLE_NOTIF_FAILED'

/* Action Creators */
let intervalId
const POLL_INTERVAL = 30000
export const pollNotifs = () => dispatch => {
  if (intervalId) return

  dispatch(fetchNotifs())

  intervalId = setInterval(() => {
    dispatch(fetchNotifs())
  }, POLL_INTERVAL)
}

const createFetchNotifs = (FETCH, SUCCESS, ERROR, params) => () => async (dispatch, getState) => {
  dispatch({ type: FETCH })

  try {
    const {
      count,
      result,
      error,
      status
    } = await api.get(`/notifications?${qs.stringify(params)}`)

    if (status === 401) window.location.replace('/logout')

    if (error) throwAny(error)

    const notifsArr = result.models
    const notifs = listArrToById(notifsArr)
    const hasMore = (params && params.limit)
      ? result.models.length === params.limit
      : true

    // Get new unread toasts
    const oldNotifs = selectNotifs(getState())
    const isLoadingForFirstTime = selectIsLoadingForFirstTime(getState())
    const notifsToToast = isLoadingForFirstTime
      ? [] // don't toast on first fetch
      : notifsArr
        .filter(n => !oldNotifs[n.id]) // Use notifs that aren't already existing
        .filter(n => !n.read) // Use notifs that aren't read yet
        .map(n => n.values.text)

    notifsToToast.forEach(n => toast.neutral({
      title: 'Notification',
      content: <span dangerouslySetInnerHTML={{ __html: n }} />
    }))

    dispatch({ type: SUCCESS, payload: { count, value: notifs, hasMore } })
  } catch (error) {
    dispatch({ type: ERROR, payload: { error }, error: true })
  }
}

export const fetchNotifs = createFetchNotifs(
  FETCH_NOTIFS,
  FETCH_NOTIFS_SUCCESS,
  FETCH_NOTIFS_FAILED,
  { limit: WIDGET_LIMIT }
)

export const fetchPageNotifs = createFetchNotifs(
  FETCH_PAGE_NOTIFS,
  FETCH_PAGE_NOTIFS_SUCCESS,
  FETCH_PAGE_NOTIFS_FAILED,
  { limit: PAGE_LIMIT }
)

export const fetchMorePageNotifs = () => (dispatch, getState) => {
  const notifs = flow(getState, selectNotifs, listByIdToArr)()
  const oldestNotif = notifs.reduce((acc, n) =>
    !acc
      ? n
      : +new Date(n.created) < +new Date(acc.created)
        ? n
        : acc,
  null)

  createFetchNotifs(
    FETCH_MORE_PAGE_NOTIFS,
    FETCH_MORE_PAGE_NOTIFS_SUCCESS,
    FETCH_MORE_PAGE_NOTIFS_FAILED,
    { limit: PAGE_LIMIT, minId: oldestNotif.id }
  )()(dispatch, getState)
}

export const toggleNotif = id => async dispatch => {
  dispatch({ type: TOGGLE_NOTIF, payload: id })

  try {
    const { error } = await api.get(`/notifications/toggle/${id}`)
    if (error) throwAny(error)
    dispatch({ type: TOGGLE_NOTIF_SUCCESS })
  } catch (error) {
    dispatch({ type: TOGGLE_NOTIF_FAILED, payload: { error }, error: true })
  }
}
