/* eslint "eqeqeq": "warn" */
import React from 'react'

import BigLoader from 'common/BigLoader'
import CashSalesTable from './CashSalesTable'
import { Box } from '@realsoftworks/decor'

class CashSales extends React.Component {
  render () {
    return (
      <Box width='100%'>
        <BigLoader loading={this.props.customNeighbors.loading || this.props.customNeighbors.fetchSource != 'CashSales'}>
          <CashSalesTable
            data={this.props.customNeighbors.result == null ? [] : this.props.customNeighbors.result}
          />
        </BigLoader>
      </Box>
    )
  }
}

export default CashSales
