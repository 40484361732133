import React, { useMemo, FC, useEffect } from 'react'
import { Box, Heading, Checkbox, NumberInput } from '@realsoftworks/decor'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import get from 'lodash/object/get'
import set from 'common/util/set'
import MonetaryRange from 'leadlists/components/filters/MonetaryRange'
import EquityField from './EquityField'
import ConditionTypeField from './ConditionTypeField'
import { NumberRange as NumberRangeType } from 'types'
import isNumber from 'common/util/isNumber'
import flow from 'lodash/function/flow'
import { NumberRange } from 'leadlists/components/filters'
import { validatePastOrPresentYearMinus1 } from 'common/util/isPastOrPresentYear'

export type EquityInfoFilters = {
  equityType?: {
    highEquity?: {
      isChecked?: boolean
      percentAtLeast?: number,
      dollarsAtLeast?: number,
      conditionType?: 'AND' | 'OR',
    }
    lowEquity?: {
      isChecked?: boolean
      percentAtMost?: number,
      dollarsAtMost?: number,
      conditionType?: 'AND' | 'OR',
    }
    upsideDown?: {
      isChecked?: boolean
      upsideDownAtLeast?: number,
    }
    freeAndClear?: boolean
    unknown?: boolean
  }
  equityInfo?: {
    estimatedValue: NumberRangeType
    taxValue: NumberRangeType
    ownershipLength: NumberRangeType
  }
}

const DEFAULT_HIGH_EQ = { isChecked: true, percentAtLeast: 19 }
const DEFAULT_LOW_EQ = { isChecked: true, percentAtMost: 20 }
const DEFAULT_UPSIDE_DOWN_EQ = { isChecked: true, upsideDownAtLeast: 0 }

const EquityInfoSection: FC<{
  filters: EquityInfoFilters
  onChange: (filters: EquityInfoFilters) => void
  openSection: string | null
  toggleSection: (section: string | null) => void
}> = ({
  filters,
  onChange,
  openSection,
  toggleSection
}) => {
  const SECTION_KEY = 'EQUITY_INFO'
  const isSectionOpen = openSection === SECTION_KEY

  const isHighEquityChecked = get(filters, 'equityType.highEquity.isChecked')
  const isLowEquityChecked = get(filters, 'equityType.lowEquity.isChecked')
  const isUpsideDownChecked = get(filters, 'equityType.upsideDown.isChecked')
  const hepercentAtLeast = get(filters, 'equityType.highEquity.percentAtLeast')
  const hedollarsAtLeast = get(filters, 'equityType.highEquity.dollarsAtLeast')
  const heConditionType = get(filters, 'equityType.highEquity.conditionType')
  const lepercentAtMost = get(filters, 'equityType.lowEquity.percentAtMost')
  const ledollarsAtMost = get(filters, 'equityType.lowEquity.dollarsAtMost')
  const leconditionType = get(filters, 'equityType.lowEquity.conditionType')
  const upsideDownAtLeast = get(filters, 'equityType.upsideDown.upsideDownAtLeast')
  const freeAndClear = get(filters, 'equityType.freeAndClear')
  const unknownField = get(filters, 'equityType.unknown')

  const filledHighEqFieldsCount = [
    isNumber(hepercentAtLeast),
    isNumber(hedollarsAtLeast)
  ].filter(Boolean).length

  const filledLowEqFieldsCount = [
    isNumber(lepercentAtMost),
    isNumber(ledollarsAtMost)
  ].filter(Boolean).length

  const filledUpsideDownFieldsCount = [
    isNumber(upsideDownAtLeast)
  ].filter(Boolean).length

  const areAllTypesSelected = !!(
    isHighEquityChecked &&
    filledHighEqFieldsCount &&
    isLowEquityChecked &&
    filledLowEqFieldsCount &&
    isUpsideDownChecked &&
    filledUpsideDownFieldsCount &&
    freeAndClear &&
    unknownField)

  const updateKey = (key: string, value: unknown): void =>
    onChange(set(
      key.split('.'),
      value,
      filters
    ))

  const createFieldProps = <V extends unknown, W extends unknown>(
    key,
    {
      getValue = ev => ev?.target?.value,
      transformOnChange = value => value,
      transformDisplay = value => value
    }: {
      getValue?: (...args: any[]) => V
      transformOnChange?: (...args: any[]) => V
      transformDisplay?: (...args: any[]) => W
    } = {}
  ): {
    onChange: (...args: any[]) => void
    value: W | undefined
  } => {
    const value = get(filters, key)

    return {
      onChange: (fst, snd) =>
        updateKey(key, transformOnChange(getValue(fst, snd))),
      value: transformDisplay(value)
    }
  }

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    hepercentAtLeast,
    hedollarsAtLeast,
    heConditionType,
    lepercentAtMost,
    ledollarsAtMost,
    leconditionType,
    upsideDownAtLeast,
    freeAndClear,
    unknownField,
    isHighEquityChecked,
    isLowEquityChecked,
    isUpsideDownChecked
  ]

  const createHandleSectionCheckboxChange = ({
    isSectionChecked,
    fillCount,
    key,
    defaults
  }) => () => {
    const willBeTicked = !isSectionChecked
    const isFilled = fillCount > 0

    if (willBeTicked)
      if (isFilled)
        // Just check and use previously filled values
        onChange(set(`${key}.isChecked`, true, filters))
      else
        // Check and defaults
        onChange(set(`${key}`, defaults, filters))
    else
      // Just uncheck
      onChange(set(`${key}.isChecked`, false, filters))
  }

  const formattedInputProps = useMemo(() => ({
    as: NumberInput,
    thousandSeparator: ','
  }), [])

  const unformattedInputProps = useMemo(() => ({
    as: NumberInput,
    thousandSeparator: ''
  }), [])

  useEffect(() => {
    // Set/unset condition type if applicable/not applicable
    if (filledHighEqFieldsCount > 1 && !heConditionType)
      updateKey('equityType.highEquity.conditionType', 'OR')
    if (filledHighEqFieldsCount <= 1 && heConditionType)
      updateKey('equityType.highEquity.conditionType', undefined)
    if (filledLowEqFieldsCount > 1 && !leconditionType)
      updateKey('equityType.lowEquity.conditionType', 'OR')
    if (filledLowEqFieldsCount <= 1 && leconditionType)
      updateKey('equityType.lowEquity.conditionType', undefined)

    // Untick parent checkboxes if all sub fields are emptied
    if (filledHighEqFieldsCount === 0 && isHighEquityChecked)
      updateKey('equityType.highEquity.isChecked', false)
    if (filledLowEqFieldsCount === 0 && isLowEquityChecked)
      updateKey('equityType.lowEquity.isChecked', false)
    if (filledUpsideDownFieldsCount === 0 && isUpsideDownChecked)
      updateKey('equityType.upsideDown.isChecked', false)
  }, [
    filledHighEqFieldsCount,
    filledLowEqFieldsCount,
    heConditionType,
    leconditionType
  ])

  return useMemo(() =>
    <AccordionFilterSection
      mt={5}
      heading='Equity Info'
      toggleSection={toggleSection(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >
      <Box mx={-3} display='flex' flexWrap='wrap'>
        <Box flex='1 1 240px' minWidth='240px'>
          <Box p={2} px={3}>
            <Heading size={5} mb={3}>Equity Type</Heading>
            <Box>
              <Checkbox
                label='All Types'
                checked={areAllTypesSelected}
                onChange={() => {
                  if (areAllTypesSelected) {
                    const updatedFilters = flow(
                      set('highEquity.isChecked', false),
                      set('lowEquity.isChecked', false),
                      set('upsideDown.isChecked', false),
                      set('freeAndClear', false),
                      set('unknown', false)
                    )(filters?.equityType)

                    updateKey('equityType', updatedFilters)
                  } else {
                    // Set checkboxes to true, use subfields but if there
                    // isn't any, use defaults
                    const updatedFilters = flow(
                      filledHighEqFieldsCount > 0
                        ? set('highEquity.isChecked', true)
                        : set('highEquity', DEFAULT_HIGH_EQ),
                      filledLowEqFieldsCount > 0
                        ? set('lowEquity.isChecked', true)
                        : set('lowEquity', DEFAULT_LOW_EQ),
                      filledLowEqFieldsCount > 0
                        ? set('upsideDown.isChecked', true)
                        : set('upsideDown', DEFAULT_UPSIDE_DOWN_EQ),
                      set('freeAndClear', true),
                      set('unknown', true)
                    )(filters?.equityType)

                    updateKey('equityType', updatedFilters)
                  }
                }}
              />

              {/* High Eq Section */}
              <Checkbox
                ml={5}
                label='High Equity'
                checked={isHighEquityChecked}
                onChange={
                  createHandleSectionCheckboxChange({
                    isSectionChecked: isHighEquityChecked,
                    fillCount: filledHighEqFieldsCount,
                    key: 'equityType.highEquity',
                    defaults: DEFAULT_HIGH_EQ
                  })
                }
              />

              <EquityField
                {...(isHighEquityChecked ? {} : { display: 'none' })}
                inputProps={unformattedInputProps}
                pl={7}
                pb={3}
                label='Min Equity:'
                postfix='%'
                {...createFieldProps(
                  'equityType.highEquity.percentAtLeast'
                )}
              />

              <EquityField
                {...(isHighEquityChecked ? {} : { display: 'none' })}
                inputProps={formattedInputProps}
                pl={7}
                pb={3}
                label='Min Equity:'
                prefix='$'
                {...createFieldProps(
                  'equityType.highEquity.dollarsAtLeast'
                )}
              />

              {filledHighEqFieldsCount > 1 && (
                <ConditionTypeField
                  {...(isHighEquityChecked ? {} : { display: 'none' })}
                  pl={8}
                  pt={1}
                  pb={3}
                  {...createFieldProps(
                    'equityType.highEquity.conditionType',
                    { getValue: v => v }
                  )}
                />
              )}

              {/* Low Eq Section */}
              <Checkbox
                ml={5}
                label='Low Equity'
                checked={isLowEquityChecked}
                onChange={
                  createHandleSectionCheckboxChange({
                    isSectionChecked: isLowEquityChecked,
                    fillCount: filledLowEqFieldsCount,
                    key: 'equityType.lowEquity',
                    defaults: DEFAULT_LOW_EQ
                  })
                }
              />

              <EquityField
                {...(isLowEquityChecked ? {} : { display: 'none' })}
                inputProps={unformattedInputProps}
                pl={7}
                pb={3}
                label='Max Equity:'
                postfix='%'
                {...createFieldProps(
                  'equityType.lowEquity.percentAtMost'
                )}
              />

              <EquityField
                {...(isLowEquityChecked ? {} : { display: 'none' })}
                inputProps={formattedInputProps}
                pl={7}
                pb={3}
                label='Max Equity:'
                prefix='$'
                {...createFieldProps(
                  'equityType.lowEquity.dollarsAtMost'
                )}
              />

              {filledLowEqFieldsCount > 1 && (
                <ConditionTypeField
                  {...(isLowEquityChecked ? {} : { display: 'none' })}
                  pl={8}
                  pt={1}
                  pb={3}
                  {...createFieldProps(
                    'equityType.lowEquity.conditionType',
                    { getValue: v => v }
                  )}
                />
              )}

              {/* Upside Down */}
              <Checkbox
                ml={5}
                label='Upside Down'
                checked={isUpsideDownChecked}
                onChange={
                  createHandleSectionCheckboxChange({
                    isSectionChecked: isUpsideDownChecked,
                    fillCount: filledUpsideDownFieldsCount,
                    key: 'equityType.upsideDown',
                    defaults: DEFAULT_UPSIDE_DOWN_EQ
                  })
                }
              />

              <EquityField
                {...(isUpsideDownChecked ? {} : { display: 'none' })}
                inputProps={formattedInputProps}
                pl={7}
                pb={3}
                label='Min Upside Down:'
                prefix='$'
                {...createFieldProps(
                  'equityType.upsideDown.upsideDownAtLeast'
                )}
              />

              <Checkbox
                ml={5}
                label='Free and Clear'
                checked={!!freeAndClear}
                onChange={() =>
                  updateKey(
                    'equityType.freeAndClear',
                    freeAndClear ? undefined : true
                  )}
              />

              <Checkbox
                ml={5}
                label='Unknown Equity'
                checked={!!unknownField}
                onChange={() =>
                  updateKey(
                    'equityType.unknown',
                    unknownField ? undefined : true
                  )}
              />

            </Box>
          </Box>
        </Box>
        <Box flex='1 1 240px' minWidth='240px'>
          <Box p={3}>
            <Heading mb={2} size={6}>Estimated Value</Heading>
            <MonetaryRange
              placeholder='Any'
              {...createFieldProps(
                'equityInfo.estimatedValue',
                { getValue: v => v }
              )}
            />
          </Box>

          <Box p={3}>
            <Heading mb={2} size={6}>Tax Value</Heading>
            <MonetaryRange
              placeholder='Any'
              {...createFieldProps(
                'equityInfo.taxValue',
                { getValue: v => v }
              )}
            />
          </Box>

          <Box p={3}>
            <Heading mb={2} size={6}>Years of Ownership</Heading>
            <NumberRange
              validateValue={validatePastOrPresentYearMinus1}
              placeholder='Any'
              {...createFieldProps(
                'equityInfo.ownershipLength',
                { getValue: v => v }
              )}
            />
          </Box>
        </Box>
      </Box>
    </AccordionFilterSection>,
  rerenderDeps)
}

export default EquityInfoSection
