import React, { useState, useCallback } from 'react'
import { Box, Heading, Card } from '@realsoftworks/decor'
import Collapsable from './Collapsable'

const AccordionFilterSection = ({
  heading,
  children,
  contentContainerProps = {},
  isSectionOpen,
  toggleSection,
  ...props
}) => (
  <Card p={4} borderRadius='5px' bg='white' boxShadow={1} {...props}>
    <Heading
      size={4}
      onClick={toggleSection}
      display='flex'
      css='cursor: pointer;'
    >
      <Box flex='1'>{heading}</Box>
      <Collapsable.Chevron fontSize={3} open={isSectionOpen}/>
    </Heading>
    <Collapsable open={isSectionOpen}>
      <Box pt={4} {...contentContainerProps}>
        {children}
      </Box>
    </Collapsable>
  </Card>
)

export default AccordionFilterSection

export const useAccordionFilterSection = () => {
  const [openSection, setOpenSection] = useState(null)
  const toggleSection = key =>
    useCallback(() => setOpenSection(v => v === key ? null : key), [])
  const toggleSectionMemoedFactory = useCallback(key =>
    () => setOpenSection(v => v === key ? null : key))

  return { openSection, toggleSection, toggleSectionMemoedFactory }
}
