import { hasFeature } from 'billing/selectors'
import { checkModelPermission } from 'users/selectors'

export const selectIsReqMet = (state, reqs) =>
  reqs.some(({ feature, model, action }) => {
    const hasFeat = !feature || hasFeature(state, feature)
    const hasPerms = (!model && !action) ||
      checkModelPermission(state, model, action)
    return hasFeat && hasPerms
  })
