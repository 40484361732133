import React, { useState, useMemo, useEffect, FC } from 'react'
import ReactMapGL, { Source, Layer, LayerProps, NavigationControl, WebMercatorViewport } from 'react-map-gl'
import { MAPBOX_KEY, US_CENTER } from 'const'
import * as counties from './counties.json'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Box } from '@realsoftworks/decor'
import { selectCoverage, selectCoverageHasLoaded } from 'mls/reducer/coverage/coverage.selector'
import { CenteredLoader } from 'common/BigLoader'
import ensureArray from 'common/util/ensureArray'

type Viewport = Pick<WebMercatorViewport, 'latitude' | 'longitude' | 'zoom'>

const withContainers = compose(
  connect(state => ({
    coveredFips: selectCoverage(state),
    coverageHasLoaded: selectCoverageHasLoaded(state)
  }))
)

const US_MAINLAND_BOUNDS: [[number, number], [number, number]] = [[-124.848974, 24.396308], [-66.885444, 49.384358]]
const incomingStatesMap = {
  '01': true, // Alabama
  '08': true, // Colorado
  '09': true, // Connecticut
  '10': true, // Delaware
  '13': true, // Georgia
  '12': true, // Florida
  '29': true, // Missouri
  '31': true, // Nebraska
  '37': true, // North Carolina
  '40': true, // Oklahoma
  '47': true, // Tennessee
  '48': true, // Texas
}

export const COVERED_COLOR = '#4fb959' // Green from v2 theme
export const INCOMING_COLOR = '#ffcf77'// Yellow from v2 theme

type Props = {
  coveredFips: ReturnType<typeof selectCoverage>,
  coverageHasLoaded: ReturnType<typeof selectCoverageHasLoaded>,
}

export const CMACoverageMapDumb: FC<Props> = ({ coveredFips, coverageHasLoaded }) => {
  const [boxRef, setBoxRef] = useState<HTMLDivElement | null>(null)
  const [viewport, setViewport] = useState<Viewport>({ latitude: US_CENTER[0], longitude: US_CENTER[1], zoom: 4 })

  // Update viewport to fit the bounds of contingent United States
  useEffect(() => {
    if (!boxRef) return

    const height = boxRef.clientHeight
    const width = boxRef.clientWidth
    const viewport = new WebMercatorViewport({ height, width })
      .fitBounds(US_MAINLAND_BOUNDS, { padding: 16 })

    setViewport(viewport)
  }, [boxRef])

  const coveredFipsMap = {}

  for (const fips of (coveredFips || [])) {
    coveredFipsMap[fips] = true;
  }

  const coveredCounties = {
    ...counties,
    features: counties.features.filter((f) => {
      const { STATE, COUNTY } = f.properties;

      return !!coveredFipsMap[`${STATE}${COUNTY}`];
    })
  }

  const incomingCounties = {
    ...counties,
    features: counties.features.filter((f) => {
      const { STATE, COUNTY } = f.properties;

      return incomingStatesMap[STATE] && !coveredFipsMap[`${STATE}${COUNTY}`];
    })
  }

  const coveredCountiesLayer: LayerProps = {
    id: 'coveredCountiesLayer',
    type: 'fill',
    source: 'counties',
    paint: {
      'fill-color': COVERED_COLOR,
      'fill-opacity': 0.7,
      'fill-outline-color': 'rgba(0,0,0,0.1)'
    },
  }

  const incomingCountiesLayer: LayerProps = {
    id: 'incomingCountiesLayer',
    type: 'fill',
    source: 'counties',
    paint: {
      'fill-color': INCOMING_COLOR,
      'fill-opacity': 0.3,
      'fill-outline-color': 'rgba(0,0,0,0.1)'
    },
  }



  return useMemo(() => {
    if (!coverageHasLoaded)
      return <CenteredLoader />

    return (
      <Box position='relative' className='mapbox cma-coverage' ref={setBoxRef}>
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken={MAPBOX_KEY}
          onViewportChange={setViewport}
          width='100%'
          height={600}
        >
          <Source id='coveredCounties' type='geojson' data={coveredCounties as any}>
            <Layer {...coveredCountiesLayer} />
          </Source>
          <Source id='incomingCounties' type='geojson' data={incomingCounties as any}>
            <Layer {...incomingCountiesLayer} />
          </Source>

          <NavigationControl
            className='navigation-control'
            showCompass={false}
          />
        </ReactMapGL>
      </Box>
    )
    },
    [viewport, incomingCountiesLayer, coveredCountiesLayer])
}

const CMACoverageMap = withContainers(CMACoverageMapDumb)

export default CMACoverageMap
