import React from 'react'
import qs from 'qs'
import { Paragraph, Box, Input, Select, Option } from '@realsoftworks/decor'
import api from 'common/api'
import stateList from 'common/states'
import DialogPicker, { ApiAdapter } from './DialogPicker'

const extractValue = v => v.id
const extractLabel = v => v.id
const apiFilter = async filters => {
  if (!filters) return []
  const { name, state } = filters
  const params = {}
  if (name) params.name = name
  if (state) params.state = state
  return api.get(`/national-leads/v1/cities?${qs.stringify(params)}`)
}

const adapter = ApiAdapter({
  id: 'nationwide-cities',
  extractLabel,
  extractValue,
  filter: apiFilter,
  limit: 25
})

const Filters = ({ value = { name: '', state: '' }, onChange, ...props }) => (
  <Box display='flex' {...props}>
    <Input placeholder='City Name' flex='1' width={1} value={value.name} onChange={e => onChange({ ...value, name: e.target.value })} />
    <Select width={100} value={value.state} onChange={e => onChange({ ...value, state: e.target.value })} menuProps={{ overflowY: 'scroll', maxHeight: 200 }}>
      <Option value=''>State</Option>
      {stateList.map(v => <Option key={v} value={v}>{v}</Option>)}
    </Select>
  </Box>
)

const CityPicker = props => (
  <DialogPicker
    type='list'
    width='auto'
    extractValue={extractValue}
    extractLabel={extractLabel}
    adapter={adapter}
    dialogProps={{
      title: 'Select Cities',
      children: <Paragraph>Search and select the cities you’re interested in and we’ll pull the leads.</Paragraph>,
      emptyText: 'Use the filters above to find the right cities',
      filterComponent: Filters
    }}
    {...props}
  />
)

export default CityPicker
