
'use strict'

import React from 'react'

class Chip extends React.Component {
  static defaultProps = {
    type: '',
    title: ''
  };

  render () {
    var classes = 'chip'
    if (this.props.type)
      classes += ' ' + this.props.type

    return (
      <div className={classes} style={this.props.style}>{this.props.children}<span>{this.props.title}</span></div>
    )
  }
}

export default Chip
