window.globalThis = window
window.api = require('common/api')
window.App = window.App || {}
window.React = require('react')
window.ReactDOM = require('react-dom')
window.$ = require('jquery')
window.jQuery = require('jquery')
window.Router = require('./core/Router')
window.ContactImport = require('./modules/contacts/components/ContactImport.react').default
window.EntityActivity = require('./views/activity/EntityActivity.react').default
window.EntityEditor = require('./views/entityedit/EntityEditor.react').default
window.Leads = {
  Import: require('leads/components/LeadImport.react').default
}
window.AppComponents = require('./app') // used in TopNav
