import React, { createContext, useContext, useMemo, useRef, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHover, useTimeout, useClickAway } from 'react-use'
import './Sidebar.css'
import { Link, matchPath, withRouter } from 'react-router-dom'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import { Box, Text } from '@realsoftworks/decor'
import styled from 'styled-components'
import { TOP_BAR_HEIGHT } from '../TopBar'

export const SIDEBAR_WIDTH = 48
const Sidebar = ({
  topOffset = 0,
  children,
  isOpenOnMobile,
  onRequestClose,
  isPhpEmbed
}) => {
  const { smDown } = useBreakpoint()
  const ref = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)
  useClickAway(ref, ev => {
    const toggleSidebarEl = document.getElementById('toggle-sidebar')

    if (
      ev.target.id === 'toggle-sidebar' ||
      (toggleSidebarEl && toggleSidebarEl.contains(ev.target))
    ) return

    if (typeof onRequestClose === 'function') onRequestClose()
  }, ['click'])

  // The Collapsing Sidebar on Desktop, and Top Bar Menu on Mobile
  const renderHoverable = () => (
    <Box
      ref={ref}
      as='aside'
      flexDirection='column'
      alignItems='stretch'
      justifyContent='flex-start'
      height={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
      width={(isExpanded || isOpenOnMobile) ? '240px' : SIDEBAR_WIDTH + 'px'}
      bg='#045D62'
      pt={3}
      pb={4}
      px={0}
      className='print-hide'
      style={{
        display: (smDown && !isOpenOnMobile) ? 'none' : 'flex',
        position: 'fixed',
        left: 0,
        top: topOffset,
        boxShadow: '0 4px 3px rgba(0,0,0,0.12), 0 4px 2px rgba(0,0,0,0.24)',
        transition: 'width .2s ease',
        ...(isPhpEmbed && { zIndex: 9999 })
      }}
    >
      {/* Menu Items becomes "position: fixed" overlay on mobile */}
      {(!smDown || isOpenOnMobile) && children}
    </Box>
  )

  const [hoverable, isHovered] = useHover(renderHoverable)

  useEffect(() => setIsExpanded(isHovered), [isHovered])

  const handleItemClick = useCallback(() =>
    setIsExpanded(false), [isHovered])
  const ctxVal = useMemo(() =>
    ({ isExpanded, handleItemClick }), [isExpanded, handleItemClick])

  return (
    <ctx.Provider value={ctxVal}>
      {hoverable}
    </ctx.Provider>
  )
}

export default Sidebar

export const SidebarItem = withRouter(({
  icon,
  label,
  to,
  onRequestClose,
  route,
  exact = false,
  location,
  ...props
}) => {
  const linkProps = route
    ? { as: Link, to }
    : { as: 'a', href: to }

  const { smDown } = useBreakpoint()
  const { isExpanded, handleItemClick } = useSidebarLogic()
  const { pathname } = location || {}
  const isActive = useMemo(() =>
    !!matchPath(pathname, {
      path: to,
      exact
    }), [pathname, exact])

  const closeMobileSidebar = useCallback(() => {
    handleItemClick()
    if (smDown && typeof onRequestClose === 'function') onRequestClose()
  }, [onRequestClose])

  const renderHoverable = isHovered =>
    (
      <StyledBox
        display='flex'
        height='40px'
        alignItems='center'
        bg={(isActive || isHovered) ? 'primary' : 'transparent'}
        color='white'
        onClick={closeMobileSidebar}
        css={{
          cursor: 'pointer',
          textDecoration: 'none',

          // These styles are here to override global styles
          ':hover': {
            color: 'white',
            textDecoration: 'none'
          },
          ':focus': {
            color: 'white',
            textDecoration: 'none'
          }
        }}
        {...linkProps}
        {...props}
      >
        {/* Parent Page Menu Item Icon */}
        <Text
          fontSize='24px'
          width={SIDEBAR_WIDTH}
          height='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <FontAwesomeIcon icon={icon} />
        </Text>

        {/* Parent Page Menu Item Text */}
        {(isExpanded || smDown) && (smDown ? (
          <Text
            className='fade-in'
            fontSize={1}
            height='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            {label}
          </Text>
        ) : (
          <RenderAfterDelay delay={200}>
            <Text
              className='fade-in'
              fontSize={1}
              height='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {label}
            </Text>
          </RenderAfterDelay>
        ))}
      </StyledBox>
    )

  const [hoverable] = useHover(renderHoverable)

  return hoverable
})

export const RenderAfterDelay = ({ delay, children }) => {
  const [isReady] = useTimeout(delay)

  if (!isReady()) return null

  return children
}

const ctx = createContext({
  isExpanded: false,
  handleItemClick: () => {}
})

export const useSidebarLogic = () =>
  ({ ...useContext(ctx), smDown: useBreakpoint().smDown })

const StyledBox = styled(Box)``

export const WhenExpanded = ({ isOpenOnMobile, children }) => {
  const { isExpanded } = useSidebarLogic()
  const { smDown } = useBreakpoint()

  return children && (isExpanded || isOpenOnMobile) && (smDown ? (
    children
  ) : (
    <RenderAfterDelay delay={200}>
      {children}
    </RenderAfterDelay>
  ))
}
