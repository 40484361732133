import React from 'react'
import { connect } from 'react-redux'

import TwoPartSearch from '../TwoPartSearch'
import BuyerIntro from './components/BuyerIntro'
import BuyerFilters from './components/BuyerFilters'
import BuyerResult from './components/BuyerResult'

import * as selectors from '../../selectors'
import { fetchBuyers, fetchBuyerCounts, clearPrevious, exportLeads } from '../../actions'

let Buyers = props => (
  <TwoPartSearch
    introComponent={BuyerIntro}
    resultComponent={BuyerResult}
    filterComponent={BuyerFilters}
    type='cashbuyers'
    {...props}
  />
)

Buyers = connect(
  state => ({
    data: selectors.getData(state),
    counts: selectors.getBuyerCounts(state),
    filter: selectors.getFilter(state, 'buyers')
  }),
  {
    fetch: fetchBuyers,
    clear: clearPrevious,
    fetchCounts: fetchBuyerCounts,
    exportLeads
  }
)(Buyers)

export default Buyers
