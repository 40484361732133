import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import Card from 'common/Card'
import analytics from 'common/analytics'

class DownloadPage extends React.Component {
  static propTypes = {
    onReturn: PropTypes.func.isRequired,
    market: PropTypes.object.isRequired,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    count: PropTypes.number.isRequired,
    packageId: PropTypes.string.isRequired
  };

  state = {
  };

  startDownload = () => {
    window.location.href = '/property-leads/packages/' + this.props.packageId
  };

  componentDidMount () {
    this.startDownload()
    analytics.track('leadlist.download')
  }

  render () {
    const todayStr = moment().format('MMMM Do, YYY')

    const marketName = this.props.market ? this.props.market.name + ', ' + this.props.market.state.join('/') : ''

    return (
      <Card className='download-leads-page'>
        <h1>Download started!</h1>
        <div className='download-leads-info'>
          <p className='download-date'>{todayStr}</p>
          <p><span className='lead-qty-text-pre'>{this.props.count || 0} Leads{this.props.count ? '' : 's'}</span> in <span className='lead-qty-text-post'>{marketName}</span></p>
          {this.props.minDate && this.props.maxDate && <p>From {this.props.minDate || ''} to {this.props.maxDate || ''}</p>}
        </div>
        <p className='download-click-message'>Click the button below to download if your download did not start already</p>

        <button type='button' className='download-page-btn download-leads-btn' onClick={this.startDownload}>
          Download Again
        </button>

        <button onClick={this.props.onReturn} type='button' className='download-page-btn find-more-leads-btn'>
          Find More Leads
        </button>
      </Card>
    )
  }
}

export default DownloadPage
