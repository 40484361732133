/* eslint "eqeqeq": "warn" */
import get from 'lodash/object/get'

export const getList = state => state.sites.list ? state.sites.list.map(id => getSite(state, id)) : null

export const getSite = (state, id) => {
  const s = state.sites.entities[id]
  if (!s) return null

  return {
    ...s,
    messageCount: getMessageCount(state, id)
  }
}

export const getSettings = (state, id) => state.sites.settings[id]

export const getMessageCount = (state, id) => {
  const stats = state.sites.messages.stats
  if (!stats) return null
  return stats[id] || 0
}

export const getMessage = (state, id) => {
  let msg = state.sites.messages.entities[id]
  if (msg)
    msg = {
      ...msg,
      site: getSite(state, msg.site_id)
    }

  return msg
}

export const getCrossMessages = state => {
  const messages = state.sites.messages.all
  if (!messages) return null

  return messages.items.map(v => getMessage(state, v))
}

export const getCrossMessageCount = state => {
  const messages = state.sites.messages.all
  if (!messages) return null

  return messages.count
}

export const getCrossMessageData = state => {
  const count = getCrossMessageCount(state)
  if (count == null) return null

  return {
    count,
    items: getCrossMessages(state)
  }
}

export const getSiteMessages = (state, siteId) => {
  const messages = state.sites.messages.bySite[siteId]
  if (!messages) return null

  return messages.items.map(v => getMessage(state, v))
}

export const getSiteMessageCount = (state, siteId) => {
  const messages = state.sites.messages.bySite[siteId]
  if (!messages) return null

  return messages.count
}

export const getSiteMessageData = (state, id) => {
  const count = getSiteMessageCount(state, id)
  if (count == null) return null

  return {
    count,
    items: getSiteMessages(state, id)
  }
}

export const getCrossVisitors = state => state.sites.metrics.visitors.cross

export const getVisitors = (state, id) => state.sites.metrics.visitors[id]

export const getCrossConversions = state => state.sites.metrics.conversions.cross

export const getConversions = (state, id) => state.sites.metrics.conversions[id]

export const getRowVisitors = (state, id) => state.sites.metrics.row.visitors[id]

export const getRowConversions = (state, id) => state.sites.metrics.row.conversions[id]

export const getSiteError = (state, id) => state.sites.errors[id]

export const getDomain = (state, id) => state.sites.domains.entities[id]

export const getDomains = state => {
  const list = state.sites.domains.list
  if (!list) return null

  return list.map(v => ({
    ...getDomain(state, v),
    site: getSiteForDomain(state, v)
  }))
}

export const getDomainProducts = state => state.sites.domains.products
export const getDomainAccount = state => state.sites.domains.account
export const isOwnedDomain = (state, domain) => {
  if (!domain) return false

  domain = domain.replace('www.', '')

  const domains = getDomains(state)
  if (!domains) return false

  const d = domains.find(v => v.id == domain)
  return !!d
}

export const getSiteForDomain = (state, domain) => {
  const list = getList(state)
  if (!list) return null

  return list.find(v => v.domains.map(v => v.replace('www.', '')).includes(domain))
}

export const getSitesState = state => get(state, 'sites')
