import theme from '../../../../theme'

const idToColor = id =>
  colors[getHash(id) % colors.length]

export default idToColor

const getHash = s => {
  const str = String(s)
  let hash = 0
  let char
  if (str.trim().length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    // Convert to 32bit integer
    hash &= hash
  }
  return Math.abs(hash)
}

const colors = [
  theme.colors.teal[300],
  theme.colors.teal[400],
  theme.colors.teal[500],
  theme.colors.teal[600],
  theme.colors.teal[700],
  theme.colors.red[300],
  theme.colors.red[400],
  theme.colors.red[500],
  theme.colors.red[600],
  theme.colors.red[700],
  theme.colors.blue[300],
  theme.colors.blue[400],
  theme.colors.blue[500],
  theme.colors.blue[600],
  theme.colors.blue[700],
  theme.colors.yellow[300],
  theme.colors.yellow[400],
  theme.colors.yellow[500],
  theme.colors.yellow[600],
  theme.colors.yellow[700]
]
