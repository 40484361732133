/* eslint "eqeqeq": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './basicinput.less'

export default class BasicInput extends React.Component {
  static propTypes = {
    inputMask: PropTypes.any,
    type: PropTypes.string
  };

  static defaultProps = {
    type: 'text'
  };

  onKeyPress = e => {
    var { inputMask } = this.props
    if (inputMask && !inputMask.test(e.key))
      e.preventDefault()
    else
      this.props.onKeyPress && this.props.onKeyPress(e)
  };

  render () {
    const { className, onKeyPress, type, inputMask, ...rest } = this.props
    const Tag = type == 'textarea' ? 'textarea' : 'input'

    return (
      <Tag {...rest} onKeyPress={this.onKeyPress} type={type} className={classNames('decor', 'decorInput', 'form-control', className)}/>
    )
  }
}
