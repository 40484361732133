/* eslint "eqeqeq": "warn", "no-class-assign": "warn" */
import React from 'react'
import { Heading } from '@realsoftworks/decor'

import { Card } from 'common/Card'
import SearchCriteriaForm from './SearchCriteriaForm'
import BigLoader from 'common/BigLoader'

import uncontrollable from 'uncontrollable'

class SearchForm extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onChangeMarket = this.onChangeMarket.bind(this)
  }

  onChangeMarket (e) {
    this.props.onChange({
      ...this.props.value,
      source: e.target.value,
      property_type: [],
      geo: {},
      status: []
    })
  }

  onChange (value) {
    this.props.onChange(value)
  }

  getChosenMetadata () {
    return this.props.metadata.filter(v => v.id == this.props.value.source)[0]
  }

  render () {
    const { value, metadata, saving, count } = this.props
    const source = value ? value.source : undefined

    return (
      <div className='create-search'>
        <Card className='padded' mb={5}>
          <MarketPicker selected={source} markets={metadata} onChange={this.onChangeMarket} />
        </Card>
        {
          source
            ? <Card className='padded' mb={5}>
              <SearchCriteriaForm loadingCount={this.props.loadingCount} count={count} metadata={this.getChosenMetadata()} saving={saving} value={value} onChange={this.onChange} onSubmit={this.props.onSubmit} />
            </Card>
            : ''
        }
      </div>
    )
  }
}

function MarketPicker (props) {
  const { selected, markets } = props

  return (
    <div className='market-picker'>
      <Heading mb={3}>Select Market:</Heading>
      <select className='form-control' value={selected || ''} onChange={props.onChange}>
        <option value='' />
        {Array.prototype.map.call(markets, m => m.dealAlerts ? <option key={m.id} value={m.id}>{m.description}</option> : null)}
      </select>
    </div>
  )
}
SearchForm = uncontrollable(SearchForm, { value: 'onChange' })

SearchForm = BigLoader.whenLoading()(SearchForm)

export default SearchForm
