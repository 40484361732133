
import moment from 'moment'
import DateHelper from '../../../core/date.js'

var DateFieldMixin = {
  formatDisplay: function (value) {
    if (!value)
      return ''

    return value.format(this.props.displayFormat || DateHelper.formats.UI_DATE)
  },

  formatValue: function (value) {
    if (!value)
      return ''

    value = moment(value)
    if (value.isValid())
      return value.format(this.props.valueFormat || DateHelper.formats.SERVER_DATE)

    return false
  },

  parseValue: function (value) {
    if (!value)
      return ''

    value = moment(value, this.props.valueFormat || DateHelper.formats.SERVER_DATE)
    if (value.isValid())
      return value

    return false
  }
}

export default DateFieldMixin
