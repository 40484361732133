import React, { useState, useCallback, Fragment } from 'react'
import { Box, Button, Heading, toast } from '@realsoftworks/decor'
import { addDnsRecord } from 'sites/reducer/manage/dnsrecords/actions'
import { connect } from 'react-redux'
import { getDnsRecordsStatus } from 'sites/reducer/manage/dnsrecords/selectors'
import DnsRecordForm from '../DnsRecordForm'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const withConnect = connect(
  state =>
    ({ status: getDnsRecordsStatus(state, 'update') }),
  { addDnsRecord })

const AddDnsRecord = withConnect(({
  dnsOrMx = 'DNS',
  domain,
  status,
  addDnsRecord,
  allowedTypes,
  disallowedTypes,
  extraButtons,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [key, setKey] = useState(0)
  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])
  const handleAddDnsRecord = useCallback(record => {
    setIsAdding(true)
    addDnsRecord(domain, record)
      .then(() => {
        toast.info({ title: `Added new ${dnsOrMx} record successfully` })
        setKey(k => k + 1)
        setIsOpen(false)
      })
      .catch(() => {})
      .finally(() => setIsAdding(false))
  },
  [domain, addDnsRecord, setKey, setIsAdding])

  return (
    <Box as='section' {...props}>
      {isOpen ? (
        <>
          <Box display='flex' alignItems='center'>
            <Heading flex={1} as='h1' size={4}>{dnsOrMx} Records</Heading>
            {extraButtons}
          </Box>

          <Box css='position: relative;'>
            <Heading as='h1' color='secondary' size={6} pt={4}>Add New {dnsOrMx} Record</Heading>

            <DnsRecordForm
              key={key}
              disabled={status === 'LOADING'}
              onSubmit={handleAddDnsRecord}
              allowedTypes={allowedTypes}
              disallowedTypes={disallowedTypes}
              footer={(
                <Fragment>
                  <Button
                    type='button'
                    disabled={status === 'LOADING'}
                    onClick={handleClose}
                    variant='neutral'
                  >
                    Cancel
                  </Button>
                  <Button
                    ml={2}
                    disabled={status === 'LOADING'}
                    type='submit'
                    variant='primary'
                  >
                    {isAdding
                      ? `Adding ${dnsOrMx} Record...`
                      : `Add ${dnsOrMx} Record`}
                  </Button>
                </Fragment>
              )}
            />
          </Box>
        </>
      ) : (
        <Box display='flex' alignItems='center'>
          <Heading flex={1} as='h1' size={4}>{dnsOrMx} Records</Heading>
          {extraButtons}
          <Button
            variant='primary'
            onClick={handleOpen}
            ml={3}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Record
          </Button>
        </Box>
      )}
    </Box>
  )
})

export default AddDnsRecord
