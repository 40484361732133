import React from 'react'
import waitAndOpenIntercom from './waitAndOpenIntercom'

class SupportLink extends React.Component {
  onClick = e => {
    e.preventDefault()
    waitAndOpenIntercom()
  }

  render () {
    return (
      <a {...this.props} href='#' onClick={this.onClick}/>
    )
  }
}

export default SupportLink
