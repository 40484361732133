import React, { FC, useState } from 'react'
import { Dialog, Button, RadioGroup, Radio, Text, toast } from '@realsoftworks/decor'
import { SkiptraceFormat } from 'types'

const SkiptraceDownloadDialog: FC<{
  isOpen: boolean
  download: (format: SkiptraceFormat) => void
  onClose: () => void
}> = ({ isOpen, download, onClose }) => {
  const [opt, setOpt] = useState<null | SkiptraceFormat>(SkiptraceFormat.Vertical)

  return (
    <Dialog
      title='Select Format'
      open={isOpen}
      onClose={onClose}
      toolbar={(
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            ml={2}
            variant='primary'
            disabled={!opt}
            onClick={() => {
              if (!opt) return
              toast.info({ title: 'Download will start in a moment…' })
              download(opt)
              onClose()
            }}
          >
            Download
          </Button>
        </>
      )}
    >
      <Text display='block' pb={3}>We have two formats to choose from:</Text>
      <RadioGroup value={opt} onChange={(_e, v) => setOpt(v)}>
        <Radio value={SkiptraceFormat.Vertical} color='blue' label='Vertical' />
        <Text display='block' color='secondary' pl='30px' mt='-6px' mb={1}>
          One phone number per row. Each additional phone number takes up more rows so the information is not limited in any way.
        </Text>
        <Radio value={SkiptraceFormat.Horizontal} label='Horizontal' />
        <Text display='block' color='secondary' pl='30px' mt='-6px' mb={1}>
          One row per skiptrace. Emails, phones, etc, are separated into columns and are limited to 10 columns each.
        </Text>

      </RadioGroup>
    </Dialog>
  )
}

export default SkiptraceDownloadDialog
