/* eslint "react/display-name": "warn", "react/no-unescaped-entities": "warn", "eqeqeq": "warn", "react/jsx-key": "warn" */

import React from 'react'
import DateHelper from '../../core/date.js'
import ActivityActions from '../../activity/ActivityActions.js'

import Avatar from '../common/Avatar.react'

var singletemplates = {

  'entity-create': function (a) {
    return (
      <div>
        <span className='icon icon-created'></span> Create this
      </div>
    )
  },

  'entity-update': function (a) {
    return (
      <div>
        <span className='icon icon-updated'></span> Updated this
      </div>
    )
  },

  'entity-archive': function (a) {
    return (
      <div>
        <span className='icon icon-archived'></span> Archived this
      </div>
    )
  },

  'entity-activate': function (a) {
    return (
      <div>
        <span className='icon icon-activated'></span> Activated this
      </div>
    )
  },

  'entity-delete': function (a) {
    return (
      <div>
        <span className='icon icon-deleted'></span> Deleted this
      </div>
    )
  },

  comment: function (a) {
    return <div>{a.activity_data}</div>
  }

}

var indextemplate = {

  'entity-create': function (a) {
    return (
      <div>
        <span className='icon icon-saved'></span> Create <a href={a.entity.link}>{a.entity.display}</a>
      </div>
    )
  },

  'entity-update': function (a) {
    return <div>
      <span className='icon icon-save'></span> Updated <a href={a.entity.link}>{a.entity.display}</a>
    </div>
  },
  comment: function (a) {
    return <div>
      {a.entity && a.entity.link ? <div>Commented on <a href={a.entity.link}>{a.entity.display}</a></div> : ''}
      <div className='comment'>"{a.activity_data}"</div>
    </div>
  },
  'entity-archive': function (a) {
    return (
      <div>
        <span className='icon icon-folder-open'></span> Archived <a href={a.entity.link}>{a.entity.display}</a>
      </div>
    )
  },

  'entity-activate': function (a) {
    return (
      <div>
        <span className='icon icon-flash'></span> Activated <a href={a.entity.link}>{a.entity.display}</a>
      </div>
    )
  },

  'entity-delete': function (a) {
    return (
      <div>
        <span className='icon icon-remove-sign'></span> Deleted <a href={a.entity.link}>{a.entity.display}</a>
      </div>
    )
  }
}

var templates = {
  view: singletemplates,
  index: indextemplate
}

class SoloActivity extends React.Component {
  static defaultProps = {
    mode: 'view'
  };

  render () {
    var a = this.props.activity
    var mode = this.props.mode

    var tpls = templates[mode]
    var tpl = tpls[a.values.type]

    if (!tpl) {
      console.debug('Missing Activity template for type: ' + a.values.type)
      return <div></div>
    }

    return (
      <div className='activity'>
        <div className='activity-header'>
          <span className='user-name'>{a.values.user.name}</span>
          <span className='timestamp'>{DateHelper.timeAgo(a.created)}</span>
          <div className={'activity-message ' + a.type}>
            {tpl(a.values)}
          </div>
        </div>
      </div>
    )
  }
}

var groupTpls = {
  comment: function (a) {
    return <div className='comment'>"{a.activity_data}"</div>
  },

  'entity-create': function (a) {
    return (
      <div>
        <span className='icon icon-created'></span> Created this
      </div>
    )
  },

  'entity-update': function (a) {
    return (
      <div>
        <span className='icon icon-updated'></span> Updated this
      </div>
    )
  },

  'entity-archive': function (a) {
    return (
      <div>
        <span className='icon icon-archived'></span> Archived this
      </div>
    )
  },

  'entity-activate': function (a) {
    return (
      <div>
        <span className='icon icon-archived'></span> Activated this
      </div>
    )
  },

  'entity-delete': function (a) {
    return (
      <div>
        <span className='icon icon-deleted'></span> Deleted this
      </div>
    )
  }
}

class ActivityGroup extends React.Component {
  static defaultProps = {
    mode: 'view'
  };

  render () {
    var a = this.props.activity
    var len = a.values.activity.length
    var over = len > 3 && a.values.entity
    var overage = len - 3

    var activity = a.values.activity

    if (over)
      activity = activity.slice(0, 3)

    return (
      <div className='activity activity-group'>
        <div className='activity-header'>
          <Avatar user={a.values.user}/>
          <span className='user-name'>{a.values.user.name}</span>
          <span className='timestamp'>{DateHelper.timeAgo(a.modified)}</span>
        </div>
        {(this.props.mode != 'view' && a.values.entity)
          ? <div className='ref'><a href={a.values.entity.link}>{a.values.entity.display}</a></div>
          : ''
        }
        <div className='activity-group-body'>
          {activity.map(function (act) {
            var t = act.type
            var tpl = groupTpls[t]
            return <div key={'g' + act.id} className={'activity-message ' + a.type}>
              {tpl(act)}
            </div>
          })}

          {
            over ? ('... and ' + overage + ' more update' + (overage != 1 ? 's' : '')) : ''
          }
        </div>

      </div>
    )
  }
}

class ActivityFeed extends React.Component {
  static defaultProps = {
    mode: 'view'
  };

  render () {
    return (
      <div className='activity-feed'>
        {this.props.activity.map(function (a) {
          if (a.values.activity) // is a group
            return <ActivityGroup key={'g' + a.id} activity={a} mode={this.props.mode}/>

          return <SoloActivity activity={'s' + a.id} mode={this.props.mode}/>
        }, this)}
        {this.props.more
          ? <button type='button' className='btn btn-default btn-block' onClick={this.onClick}>Show Older</button>
          : ''}
      </div>
    )
  }

  onClick = () => {
    ActivityActions.loadPrevious()
  };
}

export default ActivityFeed
