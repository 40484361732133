import isNumber from './isNumber'

const EQUITY_TYPES_OBJ = {
  HIGH: '',
  LOW: '',
  UPSIDE_DOWN: '',
  OWNED: '',
  UNKNOWN: ''
}

export const UPSIDE_DOWN_LTV_MIN = 100
export const HIGH_LTV_MAX = 80
export const LOW_LTV_MIN = 81
export const OWNED_LTV = 0
export const EQUITY_TYPES = Object.keys(EQUITY_TYPES_OBJ)

export type EquityType = keyof typeof EQUITY_TYPES_OBJ


/* eslint 'eqeqeq': 'warn' */
const getEquityType = (maybeLoanToValue: unknown): EquityType => {
  if (!isNumber(maybeLoanToValue)) return 'UNKNOWN'

  return maybeLoanToValue === OWNED_LTV
    ? 'OWNED'
    : maybeLoanToValue >= UPSIDE_DOWN_LTV_MIN
    ? 'UPSIDE_DOWN'
    : maybeLoanToValue <= HIGH_LTV_MAX
    ? 'HIGH'
    : maybeLoanToValue >= LOW_LTV_MIN
    ? 'LOW'
    : 'UNKNOWN'
}

export default getEquityType
