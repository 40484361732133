import {
  string,
  instanceOf,
  number,
  arrayOf,
  shape
} from 'prop-types'

export const DRIVE = shape({
  id: string.isRequired,
  createdAt: instanceOf(Date).isRequired,
  finishedAt: instanceOf(Date),
  duration: number,
  points: arrayOf(shape({
    lat: number.isRequired,
    lng: number.isRequired
  })).isRequired,
  distance: shape({
    kilometers: number,
    miles: number
  })
})

export const DRIVES = arrayOf(DRIVE).isRequired

export const LIST = shape({
  id: string.isRequired,
  name: string.isRequired,
  memberStats: shape({
    sources: shape({
      drive: number
    }).isRequired
  }).isRequired
})

export const LISTS = arrayOf(LIST).isRequired
