/* eslint "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import qs from 'qs'
import { Crumb } from 'common/Breadcrumbs'
import Button from 'decor/Button'
import Card from 'common/Card'
import FontIcon from 'decor/FontIcon'
import BigLoader from 'common/BigLoader'
import BigError from 'common/BigError'

import { getSite, getSiteError } from '../selectors'
import { fetch } from '../actions'

import * as SiteLinks from './SiteLinks'
import CreateSiteDialog from './CreateSiteDialog'
import DashboardHandler from './SiteDashboardHandler'
import SettingsHandler from './SiteSettingsHandler'

const checkSuccess = location => qs.parse(location.search.substr(1)).success

class SiteDetailHandler extends Component {
  constructor (props) {
    super(props)

    this.siteId = props.match.params.id

    this.state = {
      dialogShown: checkSuccess(props.location)
    }
  }

  hideDialog = () => this.setState({ dialogShown: false })

  componentWillReceiveProps (nextProps) {
    if (this.state.dialogShown && !checkSuccess(nextProps.location))
      this.hideDialog()
  }

  componentWillMount () {
    this.props.fetchSite(this.siteId)
  }

  render () {
    const { site, error, match } = this.props
    const { dialogShown } = this.state

    return (
      <div className='site-detail-handler'>
        {error
          ? <BigError error={error} link={<Link to='/sites'>Back to Website List</Link>}/>
          : site
            ? <div>
              <CreateSiteDialog open={dialogShown} site={site} finished={true} showVideo={true} onRequestClose={this.hideDialog}/>
              <Crumb
                title={site.name}
                link={<Link to={`/sites/${site.id}`}/>}
                tools={[
                  <Button key='visit' containerElement={<SiteLinks.Front site={site}/>}><FontIcon className='fa fa-external-link'/> Visit Site</Button>
                ]}
              />
              <div className='row'>
                <div className='col-md-2'>
                  <Card className='site-menu-card'>
                    <ul>
                      <li><SiteLinks.Dashboard site={site}>Dashboard</SiteLinks.Dashboard></li>
                      <li><SiteLinks.Content site={site}>Content</SiteLinks.Content></li>
                      <li><SiteLinks.Design site={site}>Design</SiteLinks.Design></li>
                      <li><SiteLinks.Settings site={site}>Settings</SiteLinks.Settings></li>
                    </ul>
                  </Card>
                </div>
                <div className='col-md-10'>
                  <Switch>
                    <Route path={`${match.path}/`} exact component={DashboardHandler}/>
                    <Route path={`${match.path}/settings`} component={SettingsHandler}/>
                  </Switch>
                </div>
              </div>
            </div>
            : <BigLoader/>
        }
      </div>
    )
  }
}

SiteDetailHandler = connect(
  (state, props) => ({
    site: getSite(state, props.match.params.id),
    error: getSiteError(state, props.match.params.id)
  }),

  {
    fetchSite: fetch
  }

)(SiteDetailHandler)

export default SiteDetailHandler
