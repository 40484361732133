import { TOGGLE_LANDLORD, SET_LANDLORD } from '../actions'

const INIT_STATE = {
  isLandlord: false
}

const sharedFilters = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_LANDLORD: {
      return {
        ...state,
        isLandlord: !state.isLandlord
      }
    }

    case SET_LANDLORD: {
      return {
        ...state,
        isLandlord: payload.isLandlord
      }
    }

    default:
      return state
  }
}

export default sharedFilters
