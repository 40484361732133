/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn", "react/jsx-key": "warn" */

import React from 'react'
import FileInput from '../form/FileInput.react'
import ProgressBar from '../ProgressBar.react'
import EntityFieldTypes from '../../core/EntityFieldTypes'
import EntityFieldProperties from '../entityedit/EntityFieldProperties.react'

class FileUploadPanel extends React.Component {
  render () {
    return (
      <div className={this.props.hidden ? 'hide' : '' + ' upload'}>

        <a type='button' ref='button' className='btn btn-default btn-add'>
          Choose a file
          <FileInput
            autoUpload={false}
            onAdd={this.onAdd}
            onFinish={this.onFinish}
            onError={this.onError}
            onProgress={this.onProgress}
            url={Router.url(this.props.entityType, 'import')} // eslint-disable-line no-undef
            ref='fileinput'
          />
        </a>
        <span className='filename'>{this.props.filename} {this.props.canUpload === false ? <span className='text-danger'><span className='glyphicon glyphicon-exclamation-sign'></span> {this.props.error || 'Invalid file type'}</span> : ''}</span>
        <div className='btn-toolbar'>

          {this.props.progress ? <ProgressBar value={this.props.progress}/> : <button type='button' disabled={this.props.filename == '' || this.props.canUpload === false} className='btn btn-primary' onClick={this.onUploadClick}>Upload</button>}

        </div>
      </div>
    )
  }

  onAdd = files => {
    this.props.onAdd(files[0].name)
  };

  onUploadClick = () => {
    this.refs.fileinput.start()
  };

  onFinish = data => {
    this.props.onFinish(data)
  };

  onError = data => {
    this.props.onFinish({ error: data })
  };

  onProgress = p => {
    this.props.onProgress(p)
  };
}

class FileSummaryPanel extends React.Component {
  render () {
    return (
      <div className={this.props.hidden ? 'hide' : '' + ' summary'}>
        <a type='button' ref='button' className='btn btn-default btn-add' onClick={this.props.onReset}>
          Change file

        </a>
        <span className='filename'>{this.props.filename} {this.props.canUpload === false ? <span className='text-danger'><span className='glyphicon glyphicon-exclamation-sign'></span> {this.props.error || 'Invalid file type'}</span> : ''}</span>
      </div>
    )
  }
}

class FieldSelector extends React.Component {
  static defaultProps = {
    value: '',
    newfield: {},
    meta: [] // contact field template
  };

  getMetaAsObj = () => this.props.meta.reduce(function (obj, item) {
    obj[item.name] = item
    return obj
  }, {});

  render () {
    this.fieldTypes = this.fieldTypes || EntityFieldTypes.getFieldTypes()
    var fieldTypes = this.fieldTypes
    var value = this.props.value || { name: '', childName: '' }
    var selected = value.name
    var childFields = null
    var childSelected = null

    // value for child field will look like "contacts.something"
    // first need to check if value contains "."
    if (value.childName) {
      var modelField = value.name

      var metaIndex = this.getMetaAsObj()
      var fieldDef = metaIndex[modelField]

      if (fieldDef.child == true) {
        childFields = fieldDef.fields
        childSelected = value.childName
      }
    }

    var newfield = this.props.newfield ? this.props.newfield : { type: '', label: '' }

    var errors = this.props.errors || []

    return (
      <div className='field-selector'>
        <select className='form-control' value={selected} onChange={this.onChange}>
          <option value=''>Ignore</option>
          {this.getOptions(this.props.meta)}
          <option disabled={true}>──────────</option>
          <option value='new'>Create New Field</option>
        </select>

        {childSelected
          ? <select className='form-control' value={childSelected} onChange={this.onChildChange}>
            {this.getOptions(childFields)}
          </select>

          : ''
        }
        {this.props.value == 'new'

          ? <div className='new-field-options'>
            <div className={'form-group' + (errors.includes('type') ? ' has-error has-feedback' : '')}>
              <select ref='fieldtype' className='form-control' value={newfield.type} onChange={this.onNewFieldChange.bind(this, 'type')}>
                <option value=''>-- Select New Field Type --</option>
                {fieldTypes.map(function (f) {
                  return <option value={f.name}>{f.label}</option>
                })}
              </select>
            </div>
            <div className={'form-group' + (errors.includes(errors, 'label') ? ' has-error has-feedback' : '')}>
              <label>Label</label>
              <input type='text' className='form-control' value={newfield.label} onChange={this.onNewFieldChange.bind(this, 'label')}/>
            </div>
            <div className='form-group'>
              {this.getFieldConfigOptions()}
            </div>
          </div>

          : ''
        }
      </div>
    )
  }

  onChange = e => {
    var name = e.target.value
    var v = { name: name }

    // if value is a child model, we want to auto-pick the first of it's fields and return that as the value.
    // next time the value is passed in, we'll pick up on this in the render method
    if (name == 'new') {
      this.props.onChange(name)
      return
    }

    var metaIndex = this.getMetaAsObj()
    if (metaIndex[name] && metaIndex[name].child) {
      var fields = metaIndex[name].fields
      var firstName = fields[0].name

      v.childName = firstName
    }

    this.props.onChange(v)
  };

  onChildChange = e => {
    var ret = Object.assign({}, this.props.value)
    ret.childName = e.target.value
    this.props.onChange(ret)
  };

  getOptions = (fields, parentName) => {
    parentName = parentName || ''
    if (parentName)
      parentName = parentName + '.'

    return fields.map(function (o) {
      var name = parentName + o.name

      if (o.type == 'address') {
        var parts = ['line1', 'city', 'state', 'zip'] // TODO this should come from the server ideally
        var labels = ['Street', 'City', 'State', 'Zipcode']

        return (
          <optgroup label={o.label}>
            {parts.map(function (p, i) {
              return <option value={name + '.' + p}>{labels[i]}</option>
            })}
          </optgroup>
        )
      }

      return <option value={name}>{o.label}</option>
    }, this)
  };

  getFieldConfigOptions = () => {
    var field = this.props.newfield || {}
    var t = field.type
    var fts = EntityFieldTypes.getFieldsByType()

    if (t && fts[t].properties)
      return (
        <div className='form-group'>
          <EntityFieldProperties properties={fts[t].properties} field={field} onChange={this.onNewFieldChange}/>
        </div>
      )
    else
      return ''
  };

  onNewFieldChange = (prop, e) => {
    var v = (e.target ? e.target.value : e)
    var newfield = Object.assign({}, this.props.newfield || {})
    newfield[prop] = v

    this.props.onNewFieldChange(newfield)
  };
}

class MatchingPanel extends React.Component {
  static defaultProps = {
    headers: [],
    rows: [],
    meta: [],
    mappings: {},
    newfields: {}
  };

  onMappingChange = (header, e) => {
    var v = e.target ? e.target.value : e
    var m = this.props.mappings
    if (v)
      m[header] = v
    else
      delete m[header]

    this.props.onMapped(m)
  };

  onNewFieldChange = (header, config) => {
    var m = Object.assign({}, this.props.newfields || {})
    m[header] = config
    this.props.onNewFieldChange(m, header, config)
  };

  render () {
    var rows = this.props.rows

    return (
      <div className={this.props.hidden ? 'hide' : '' + ' match'}>
        <h3>Match Fields</h3>
        <div>
          {this.props.headers.map(function (h, i) {
            return (
              <div className='row matching-row'>
                <div className='col-md-5'>
                  <div className='panel panel-default'>
                    <div className='panel-body'>
                      <table className='table table-striped'>
                        <thead><tr><th>{h}</th></tr></thead>
                        <tbody>
                          {rows.map(function (r) {
                            return (
                              <tr><td>{r[i]}</td></tr>
                            )
                          }, this)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-md-2 separator'>
                  <span className='glyphicon glyphicon-arrow-right'></span>
                </div>
                <div className='col-md-5'>
                  <div className='panel panel-default'>
                    <div className='panel-body'>
                      <div><strong>{this.props.entityName || 'Contact'} Field</strong></div>
                      <FieldSelector
                        errors={this.props.errors[h]}
                        value={this.props.mappings[h]}
                        newfield={this.props.newfields[h]}
                        meta={this.props.meta}
                        onChange={this.onMappingChange.bind(this, h)}
                        onNewFieldChange={this.onNewFieldChange.bind(this, h)}/>
                    </div>
                  </div>
                </div>
              </div>
            )
          }, this)}
        </div>
      </div>
    )
  }
}

export default {
  SummaryPanel: FileSummaryPanel,
  UploadPanel: FileUploadPanel,
  FieldSelector: FieldSelector,
  MatchingPanel: MatchingPanel
}
