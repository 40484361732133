import React from 'react'
import { Button, Box, Tooltip } from '@realsoftworks/decor'
import { withRouter } from 'react-router-dom'

import SkiptraceIcon from './SkiptraceIcon'
import SkiptraceOrderModal from './SkiptraceOrderModal'

const SkiptraceOrderButton = ({ list, items, mode, location, ...props }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const closeModal = React.useCallback(() => setModalOpen(false))
  const openModal = React.useCallback(() => setModalOpen(true))

  React.useEffect(() => {
    setModalOpen(false)
  }, [location.search])

  const importing = !!list.importProgress
  if (importing) return null

  const hasOrders = (list.pendingSkiptraces || []).length > 0 || !!list.memberStats.skiptracing

  const button = (
    <Button
      variant='secondary'
      disabled={hasOrders}
      onClick={openModal}
      {...props}
    >
      <SkiptraceIcon /> Skiptrace {mode === 'list' ? 'List' : 'selected items'}
    </Button>
  )

  return (
    <>
      <SkiptraceOrderModal
        open={modalOpen}
        onClose={closeModal}
        list={list}
        members={items}
      />
      {hasOrders
        ? <Tooltip content='Please wait for your current order to finish'>
          <Box>
            {button}
          </Box>
        </Tooltip>
        : button}
    </>
  )
}

export default withRouter(SkiptraceOrderButton)
