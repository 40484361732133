import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box } from '@realsoftworks/decor'
import themeGet from '@styled-system/theme-get'
import styled from 'styled-components'

import ListItem, { Title } from './components/ListItem'

const ListLink = styled(NavLink)`
  display: block;
  border-bottom: 1px solid ${themeGet('colors.neutral.300')};

  &.active {
    ${Title} {
      font-weight: bold;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:active, &:focus {
    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
  }
`

const ListList = ({ lists, path, ...props }) => (
  <Box {...props}>
    {lists.map(list => (
      <ListLink key={list.id} to={`${path}/${list.id}`}>
        <ListItem list={list} />
      </ListLink>
    ))}
  </Box>
)

export default ListList
