/* eslint "eqeqeq": "warn" */
import { normalize } from 'normalizr'
import { actions as mlsActions } from '../mls'
import * as constants from './constants'
import * as schemas from './schema'
import fetch from 'common/fetch'
import { CALL_API_LEGACY } from '../../middleware/api'
import throwAny from 'common/util/throwAny'
import logError from 'common/logError'
import set from 'common/util/set'
import { selectCompSavedState, selectCompState, selectComp } from '../../reducers/selectors'
import flow from 'lodash/function/flow'
import identity from 'lodash/utility/identity'
import { toast } from '@realsoftworks/decor'
import { isString } from 'lodash'
import * as leadsActions from '../leads/actions'
import * as leadsSelectors from '../leads/selectors'

export function fetchMetadata () {
  return (dispatch, getState) => {
    if (getState().mls.metadata.data)
      return

    dispatch({
      [CALL_API_LEGACY]: {
        types: [mlsActions.MLS_METADATA_FETCH, mlsActions.MLS_METADATA_FETCH_SUCCESS, mlsActions.MLS_METADATA_FETCH_FAILURE],
        endpoint: '/cma/metadata'
      }
    })
  }
}

export const CREATE_SUCCESS = constants.CREATE_SUCCESS

const startFetch = leadId => ({ type: constants.FETCH, meta: { leadId } })
const interrruptFetchList = leadId => ({ type: constants.FETCH_INTERRUPTED, meta: { leadId } })
const finishFetch = (leadId, result) => ({ type: constants.FETCH_SUCCESS, payload: result, meta: { leadId } })
const failFetch = (leadId, error) => ({ type: constants.FETCH_FAILURE, error: true, payload: error, meta: { leadId } })

const fetchForLead = leadId => async dispatch => {
  dispatch(startFetch(leadId))
  try {
    var result = await fetch(`/cma/${leadId}`)
    var json = await result.json()
    if (result.status == 403) {
      dispatch(interrruptFetchList(leadId))
      return dispatch(startMlsAuth(leadId, json))
    } else if (result.status !== 200) {
      dispatch(failFetch(leadId, json))
      throwAny(json)
    }

    var payload = normalize(json, schemas.arrayOfCma)

    dispatch(finishFetch(leadId, payload))
    return payload.result
  } catch (e) {
    dispatch(failFetch(leadId, e))
    throw e
  }
}

const startCreate = leadId => ({ type: constants.CREATE, meta: { leadId } })
const interrruptCreate = leadId => ({ type: constants.CREATE_INTERRUPTED, meta: { leadId } })
const finishCreate = (leadId, result) => ({ type: constants.CREATE_SUCCESS, payload: result, meta: { leadId } })
const failCreate = (leadId, error) => ({ type: constants.CREATE_FAILURE, error: true, payload: error, meta: { leadId } })

export const create = leadId => async dispatch => {
  dispatch(startCreate(leadId))
  try {
    var result = await fetch(`/cma/add/${leadId}`, { method: 'POST' })

    var json = await result.json()

    if (result.status == 403) { // not authorized by the MLS
      dispatch(interrruptCreate(leadId))
      return dispatch(startMlsAuth(leadId, json))
    } else if (result.status !== 200) { return dispatch(failCreate(leadId, json.error)) }

    var payload = normalize(json, schemas.cma)

    dispatch(finishCreate(leadId, payload))
    return payload.result
  } catch (e) {
    dispatch(failCreate(leadId, e))
    throw e
  }
}

export const createOrFetchForLead = leadId => async dispatch => {
  try {
    var list = await dispatch(fetchForLead(leadId))

    if (list && list.length == 0)
      await dispatch(create(leadId))
  } catch (e) {
    logError(e)
  }
}

const startSearch = (leadId, cmaId, params) => ({ type: constants.SEARCH, meta: { leadId, cmaId, params } })
const interrruptSearch = (leadId, cmaId, params) => ({type: constants.SEARCH_INTERRUPTED, meta: { leadId, cmaId, params } })
const finishSearch = (leadId, cmaId, params, result) => ({ type: constants.SEARCH_SUCCESS, payload: result, meta: { leadId, cmaId, params } })
const failSearch = (leadId, cmaId, params, error) => ({ type: constants.SEARCH_FAILURE, error: true, payload: error, meta: { leadId, cmaId, params } })

export const search = (leadId, cmaId, params) => async dispatch => {
  dispatch(startSearch(leadId, cmaId, params))
  try {
    var result = await fetch(`/cma/search/${leadId}/${cmaId}`, { method: 'POST', body: JSON.stringify(params) })
    var json = await result.json()

    if (result.status == 403) { // not authorized by the MLS
      dispatch(interrruptSearch(leadId, cmaId, params))
      return dispatch(startMlsAuth(leadId, json))
    } else if (result.status !== 200) { return dispatch(failSearch(leadId, cmaId, params, json.error)) }

    var payload = normalize(json, schemas.cma)

    dispatch(finishSearch(leadId, cmaId, params, payload))
    return payload.result
  } catch (e) {
    dispatch(failSearch(leadId, cmaId, params, e))
  }
}

const startToggle = (leadId, cmaId, states, payload) => ({
  type: constants.TOGGLE,
  meta: { leadId, cmaId, states },
  payload
})

const finishToggle = (leadId, cmaId, states, result) => ({
  type: constants.TOGGLE_SUCCESS,
  payload: result,
  meta: { leadId, cmaId, states }
})

const failToggle = (leadId, cmaId, states, _entities, error) => ({
  type: constants.TOGGLE_FAILURE,
  error: true,
  payload: error,
  meta: { leadId, cmaId, states }
})

// Note that states are updated optimistically (update on request start, only
// revert on fail) via payload.entities (which is tracked by entities reducer).
//
// Also, on each successful save, state is saved as 'savedState' which serves as
// fallback when a comp state update fails. This is better than reverting to the
// state before update during the lifetime of thunk, because it is possible to
// have multiple thunk do updates on a single comp at a time.
export const toggle = (leadId, cmaId, states) => async (dispatch, getState) => {
  const reduxState = getState()
  const [compId, newState] = Object.entries(states || {})?.[0] || []
  const compSavedState = selectCompSavedState(reduxState, cmaId, compId)
  const currentCompState = selectCompState(reduxState, cmaId, compId)

  const startStateUpdater = createCompStateUpdateObj({
    cmaId,
    compId,
    state: newState,
    savedState: compSavedState || currentCompState
  })

  dispatch(startToggle(leadId, cmaId, states, startStateUpdater))

  try {
    const isSuccessful = await fetch(`/cma/toggle/${leadId}/${cmaId}`, {
      method: 'POST',
      body: JSON.stringify(states)
    })
      .then(res => res.status === 200)

    if (!isSuccessful) throw new Error()

    const successStateUpdater = createCompStateUpdateObj({
      cmaId,
      compId,
      savedState: newState
    })

    dispatch(finishToggle(leadId, cmaId, states, successStateUpdater))
  } catch (e) {
    const state = getState()
    const compSavedState = selectCompSavedState(state, cmaId, compId)
    const failedStateUpdater = createCompStateUpdateObj({
      cmaId,
      compId,
      state: compSavedState
    })

    const comp = selectComp(state, cmaId, compId)

    const message = {
      title: `Failed to update the comp “${comp?.address?.line1}” to “${newState}”`,
      content: `We’ve reverted it to “${compSavedState}”. Please check your connection and try again. If this keeps happening please contact support by pressing the support button on the lower right.`
    }
    toast.error(message)

    dispatch(failToggle(leadId, cmaId, states, failedStateUpdater, e))
  }
}

const createCompStateUpdateObj = ({ cmaId, compId, state, savedState }) =>
  flow(
    state === undefined
      ? identity
      : set(['entities', 'cma', cmaId, 'states', compId], state),
    savedState === undefined
      ? identity
      : set(['entities', 'cma', cmaId, 'savedStates', compId], savedState)
  )({})

const startMlsAuth = (leadId, resp) => async (dispatch, getState) => {
  /**
   * Patch local lead's mls_source based on acquired MLS data
   */
  const latestSource = resp?.error?.message?.source

  if (!isString(latestSource)) return

  const lead = leadsSelectors.getLead(getState(), leadId)
  const patchedLead = { ...lead, mls_source: latestSource }

  await dispatch(leadsActions.updateLeadLocally({ lead: patchedLead }))

  dispatch(mlsActions.setSourceRequirements(resp))
}

function checkStatus (res) {
  if (res.status !== 200)
    return res.json().then(throwAny)
  else
    return res.json()
}

const startSaveARV = (leadId, cmaId, arv, arvType) => ({ type: constants.SAVE_ARV, meta: { leadId, cmaId, arv, arvType } })
const finishSaveARV = (leadId, cmaId, arv, arvType, result) => ({ type: constants.SAVE_ARV_SUCCESS, payload: result, meta: { leadId, cmaId, arv, arvType } })
const failSaveARV = (leadId, cmaId, arv, arvType, error) => ({ type: constants.SAVE_ARV_FAILURE, error: true, payload: error, meta: { leadId, cmaId, arv, arvType } })

export const saveARV = (leadId, cmaId, arv, arvType) => async dispatch => {
  dispatch(startSaveARV(leadId, cmaId, arv, arvType))

  try {
    var result = await fetch(`/cma/update/${leadId}/${cmaId}`, {
      method: 'POST',
      body: JSON.stringify({ arv, arvType })
    })
      .then(res => res.json())

    var payload = normalize(result, schemas.cma)

    dispatch(finishSaveARV(leadId, cmaId, arv, arvType, payload))
  } catch (e) {
    dispatch(failSaveARV(leadId, cmaId, arv, arvType, e))
  }
}
