import { FETCH_TRIAL_PROMO_INFO, FETCH_TRIAL_PROMO_INFO_SUCCESS, FETCH_TRIAL_PROMO_INFO_FAILED, EXPIRE_TRIAL_PROMO_INFO } from './contants'

const initState = {
  status: 'INIT',
  promo: null
}

const trialPromo = (state = initState, { type, payload }) => {
  switch (type) {
    case FETCH_TRIAL_PROMO_INFO:
      return { ...state, status: 'LOADING' }
    case FETCH_TRIAL_PROMO_INFO_SUCCESS:
      return { ...state, status: 'SUCCESS', promo: payload.promo }
    case FETCH_TRIAL_PROMO_INFO_FAILED:
      return { ...state, status: 'FAILED' }
    case EXPIRE_TRIAL_PROMO_INFO:
      return { ...state, promo: null }
    default:
      return state
  }
}

export default trialPromo
