/* eslint "eqeqeq": "warn", "no-useless-call": "warn", "react/no-unescaped-entities": "warn" */
import React from 'react'
import createReactClass from 'create-react-class'
import Import from '../../../views/import'
import Model from '../../../core/model.js'
var FileUploadPanel = Import.UploadPanel; var FileSummaryPanel = Import.SummaryPanel; var MatchingPanel = Import.MatchingPanel

var ImportPanel = createReactClass({
  displayName: 'ImportPanel',

  getInitialState: function () {
    return {
      step: 1,
      filename: '',
      preview: { headers: [], rows: [] },
      mappings: {},
      newfields: {},
      errors: {},
      errorMsg: '',
      msg: '',
      progress: 0,
      importing: false
    }
  },

  reset: function () {
    this.setState(this.getInitialState())
  },

  render: function () {
    return (
      <div className={'import-panel ' + this.props.entityType + '-import'}>
        <FileUploadPanel entityType={this.props.entityType} progress={this.state.progress} hidden={this.state.step > 1} filename={this.state.filename} onAdd={this.onAdd} onFinish={this.onUploadFinish} onProgress={this.onProgress} canUpload={this.state.canUpload} error={this.state.error}/>
        <FileSummaryPanel filename={this.state.filename} hidden={this.state.step < 2} onReset={this.reset}/>
        <MatchingPanel hidden={this.state.step < 2}
          headers={this.state.preview.headers}
          rows={this.state.preview.rows}
          entityName={this.props.entityName}
          meta={this.props.fieldTypes}
          onMapped={this.onMapped}
          onNewFieldChange={this.onNewFieldChange}
          mappings={this.state.mappings}
          newfields={this.state.newfields}
          errors={this.state.errors}/>

        <div className={'btn-toolbar clearfix ' + (this.state.step < 2 ? 'hide' : '')}>
          <button
            type='button'
            className='btn btn-success btn-lg pull-right'
            disabled={this.state.errorMsg !== '' || this.state.importing}
            onClick={this.startImport}>{this.state.importing ? 'Please wait...' : 'Import'}</button>
          {this.state.errorMsg ? <div className='mapping-error text-danger pull-right'><span className='glyphicon glyphicon-exclamation-sign'></span> {this.state.errorMsg}</div> : ''}

        </div>
      </div>
    )
  },

  onAdd: function (filename) {
    var index
    if ((index = filename.indexOf('.csv')) == -1 || index !== filename.length - 4)
      this.setState({ filename: filename, step: 1, canUpload: false })
    else
      this.setState({ filename: filename, canUpload: true })

    if (this.props.onStart)
      this.props.onStart()
  },

  onProgress: function (p) {
    this.setState({ progress: p })
  },

  onUploadFinish: function (data) {
    if (data.error)
      this.setState({ canUpload: false, error: data.error, progress: 0 })
    else
      this.setState({ step: 2, preview: data, mappings: data.suggestions, canUpload: true, progress: 0 }, this.doMappingValidation)
  },

  onMapped: function (mappings) {
    var newfields = this.state.newfields
    var errors = this.state.errors
    for (var key in mappings)
      if (mappings[key] == 'new')
        newfields[key] = newfields[key] || { label: key }

    var newkeys = Object.keys(newfields)
    newkeys.forEach(function (key) {
      if (mappings[key] !== 'new') {
        delete newfields[key]
        delete errors[key]
      }
    })

    this.setState({ mappings: mappings, newfields: newfields, errors: errors }, this.doMappingValidation)
  },

  onNewFieldChange: function (newfields, header) {
    this.setState({ newfields: newfields }, this.doNewFieldValidation.bind(this, [header]))
  },

  startImport: function () {
    var valid = this.validate()

    if (!valid.errorMsg)
      valid.importing = true

    this.setState(valid, function () {
      if (!valid.errorMsg) {
        var params = {
          mappings: JSON.stringify(this.state.mappings),
          newfields: JSON.stringify(this.state.newfields)
        }

        Model(this.props.entityType).execute('import', params).then(this.onImportComplete)
      }
    })
  },

  doMappingValidation: function () {
    var valid = this.validate(['validateNotBlank'])
    this.setState(valid)
  },

  doNewFieldValidation: function (fields) {
    var valid = this.validateNewFields(fields)
    this.setState(valid)
  },

  validate: function (validators) {
    if (!validators)
      validators = ['validateNotBlank', 'validateNewFields']

    var s = {}
    validators.forEach(function (v) {
      if (!s.errorMsg)
        s = this[v].call(this)
    }, this)

    return s
  },

  validateNotBlank: function () {
    var errorMsg = ''; var canImport = Object.keys(this.state.mappings).length > 0
    if (!canImport)
      errorMsg = 'Please provide at least one field mapping'

    return { errorMsg: errorMsg }
  },

  validateNewFields: function (fields) {
    var errors = {}; var errorMsg = ''
    if (fields)
      errors = this.state.errors
    else
      fields = Object.keys(this.state.newfields)

    fields.forEach(function (key) {
      var f = this.state.newfields[key]
      var err = this.validateNewFieldCfg(f)
      errors[key] = err
    }, this)

    for (var key in errors)
      if (errors[key] && errors[key].length > 0)
        errorMsg = 'Please check above for errors'

    return { errors: errors, errorMsg: errorMsg }
  },

  validateNewFieldCfg: function (f) {
    var err = []
    if (!f.type)
      err.push('type')

    if (!f.label)
      err.push('label')

    return err
  },

  onImportComplete: function (data) {
    if (data.success) {
      this.reset()
      if (this.props.onFinish)
        this.props.onFinish()
    }
  }
})

class LeadImport extends React.Component {
  state = { finished: false };

  render () {
    return (
      <div className='lead-import'>

        {this.state.finished

          ? <div className='alert alert-info alert-dismissable'>
            <button type='button' className='close' onClick={this.reset}><span aria-hidden='true'>&times;</span><span className='sr-only'>Close</span></button>
            Your import has started. We'll notify you when it's done.
          </div>
          : ''
        }

        <h1>Lead Import</h1>
        <p>Upload any CSV file with a header row to start the import process.</p>
        <ImportPanel entityType='lead' entityName='Lead' fieldTypes={this.props.fieldTypes} onStart={this.reset} onFinish={this.onFinish}/>
      </div>
    )
  }

  onFinish = () => {
    this.setState({ finished: true })
  };

  reset = () => {
    this.setState({ finished: false })
  };
}

export default LeadImport
