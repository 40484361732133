
import React from 'react'
import FieldFactory from '../FieldFactory.react'

class DisplayField extends React.Component {
  static defaultProps = {
    emptyValue: '---',
    fullValue: '%v',
    type: 'text'
  };

  render () {
    var value = FieldFactory.format(this.props.value, this.props.type, this.props)
    var empty = !!value
    if (!value) { value = value || <span className='text-muted empty-message'>{this.props.emptyValue}</span> } else if (typeof value === 'string') {
      value = value.replace()
      // need special handling of dollar signs because technically $X is a regex backreference:
      // https://mattsnider.com/safari-regex-issue-with-0-9-in-replacement-text/
      value = this.props.fullValue.replace(/%v/g, value.replace(/\$/g, '#_#')).replace(/#_#/g, '$')
    }

    return (
      <div className='display-field' title={this.props.title}>
        <div className={'display-field-value ' + (!empty ? 'display-field-value-empty' : '')}>
          {value}
        </div>
      </div>
    )
  }
}

export default DisplayField
