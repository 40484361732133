import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DocumentTitle from 'react-document-title'

import Dashboard from './Dashboard'

const Handler = ({ match }) => (
  <DocumentTitle title='Lead Lists | Propelio'>
    <Switch>
      <Route path={`${match.path}`} component={Dashboard} />
    </Switch>
  </DocumentTitle>
)

export default Handler
