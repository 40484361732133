import React from 'react'
import { Box, Heading, Text } from '@realsoftworks/decor'
import BigLoader from 'common/BigLoader'
import SearchInput from './SearchInput'
import { useSearchScreenProps } from '../SearchContext'

const HomeSearch = () => {
  const { isReady } = useSearchScreenProps()

  return (
    <Box
      as='section'
      pt={[7, 7, 9]}
      pb={7}
      display='flex'
      flexDirection='column'
      alignItems='center'
      minHeight='calc(100vh - 144px)'
    >
      {!isReady ? <BigLoader /> : (
        <>
          <Heading px={3} as='h1' size={3} textAlign='center'>
            Detailed property information at your fingertips
          </Heading>

          <Box display='flex' justifyContent='center' width='100%' mt={5} p={3}>
            <SearchInput instanceId='preresultssearch' />
          </Box>

          <Text
            px={3}
            color='secondary'
            mt={5}
            textAlign='center'
            lineHeight={1.5}
          >
            Search our database of
            {' '}<Text fontWeight='600' color='red.500'>
              144&nbsp;Million&nbsp;Properties
            </Text>
          </Text>
        </>
      )}
    </Box>
  )
}

export default HomeSearch
