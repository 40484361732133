import React, { useState, useCallback } from 'react'
import { Card, Box, Text } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Monetary, DateTime } from 'common/format'
import { orDash } from 'common/util/renderHelpers'
import Mortgages from './Mortgages'
import EmptyTableMsg from './EmptyTableMsg'
import get from 'lodash/object/get'

const COL_WIDTHS = [36, 120, 120, 140, 140, 140, 140, 160, 160, 120, 180, 180]
const COLS_SUM = COL_WIDTHS.reduce((a, b) => a + b, 0)
const BORDER_WIDTH = 2

const Transfers = ({ transfers }) => {
  // To be used in the future if we make columns sortable
  const [sortBy, setSortBy] = useState('recordingInfo.recordingDate') // eslint-disable-line no-unused-vars

  const sortTable = useCallback((l, r) => get(l, sortBy) > get(r, sortBy) ? -1 : 1, [sortBy])

  return transfers && transfers.length ? (
    <Card
      width='100%'
      borderWidth={BORDER_WIDTH}
      borderColor='blue.200'
      borderStyle='solid'
      css='overflow: auto;'
    >
      <Box as='table' width='100%' minWidth={COLS_SUM}>
        <colgroup>
          {COL_WIDTHS
            .map((width, i) => (
              <col key={i} span='1' style={{ width }} />
            ))}
        </colgroup>

        <Box as='thead'>
          <Box as='tr'>
            {[
              '', // for expand/collapse icon
              'Recording Date',
              'Sale Date',
              'Purchase Method',
              'Document Number',
              'Document Type',
              'Transaction Type',
              'Buyer Name(s)',
              'Seller Name(s)',
              'Sale Amount',
              'Buyer Address',
              'Seller Address'
            ].map((label, i) => (
              <Text
                as='th'
                p={2}
                key={i}
                bg='blue.200'
                fontWeight='bold'
                fontSize={0}
              >
                {label}
              </Text>
            ))}
          </Box>
        </Box>
        <Box as='tbody'>
          {transfers.sort(sortTable).map((h, i) => (
            <TransferRow isEven={!!((i + 1) % 2)} key={i} {...h} />
          ))}
        </Box>
      </Box>
    </Card>
  ) : (
    <EmptyTableMsg>No transfer history</EmptyTableMsg>
  )
}

export default Transfers

const TransferRow = ({
  recordingInfo: maybeRecordingInfo,
  saleDate,
  purchaseMethod,
  transactionType,
  buyer: maybeBuyer,
  seller: maybeSeller,
  saleAmount,
  mortgages,
  isEven
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggleCollapse = useCallback(() => setIsCollapsed(v => !v), [])

  const recordingInfo = maybeRecordingInfo || {}
  const buyer = maybeBuyer || {}
  const seller = maybeSeller || {}

  return (
    <>
      <Box as='tr'>
        <TransferCell
          colWidth={COL_WIDTHS[0]}
          isEven={isEven}
          textAlign='center'
          pl={3}
          pr={1}
          onClick={toggleCollapse}
          css='cursor: pointer;'
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[1]} isEven={isEven}>
          {orDash(recordingInfo.recordingDate, v => (
            <DateTime format='MM/DD/YYYY'>{v}</DateTime>
          ))}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[2]} isEven={isEven}>
          {orDash(saleDate, v => (
            <DateTime format='MM/DD/YYYY'>{v}</DateTime>
          ))}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[3]} isEven={isEven}>
          {orDash(purchaseMethod)}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[4]} isEven={isEven}>
          {orDash(recordingInfo.documentNumber)}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[5]} isEven={isEven}>
          {orDash(recordingInfo.documentType)}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[6]} isEven={isEven}>
          {orDash(transactionType)}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[7]} isEven={isEven}>
          {orDash(buyer.names, v =>
            v.length ? v.join(' / ') : '--')}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[8]} isEven={isEven}>
          {orDash(seller.names, v =>
            v.length ? v.join(' / ') : '--')}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[9]} isEven={isEven}>
          {orDash(saleAmount, v => (
            <Monetary>{v}</Monetary>
          ))}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[10]} isEven={isEven}>
          {orDash(buyer.address, v => {
            const { line1, city, state, zip } = v
            return (
              <>
                {line1}{line1 && <br />}
                {[city, state, zip].join(' ')}
              </>
            )
          })}
        </TransferCell>

        <TransferCell colWidth={COL_WIDTHS[11]} isEven={isEven}>
          {orDash(seller.address, v => {
            const { line1, city, state, zip } = v
            if (!line1 && !city && !state && !zip) return '--'
            return (
              <>
                {line1}{line1 && <br />}
                {[city, state, zip].join(' ')}
              </>
            )
          })}
        </TransferCell>
      </Box>

      {!isCollapsed && (
        <Box as='tr'>
          <Box as='td' p={2} bg={isEven ? 'neutral.100' : 'white'} colSpan='12'>
            <Mortgages mortgages={mortgages} />
          </Box>
        </Box>
      )}
    </>
  )
}

const TransferCell = ({
  isEven,
  colWidth,
  children,
  ...props
}) => (
  <Text
    as='td'
    bg={isEven ? 'neutral.100' : 'white'}
    fontSize={0}
    p={2}
    {...props}
  >
    {children}
  </Text>
)
