import React from 'react'
import { Box, Text } from '@realsoftworks/decor'
import BigLoader from 'common/BigLoader'
import BigError from 'common/BigError'

import {
  string,
  bool,
  object,
  oneOfType,
  node,
  func
} from 'prop-types'

const FetchHandler = ({
  isLoading,
  error,
  isEmpty,
  children,
  emptyText,
  renderError = ({ error }) => (
    <Container pt={4}>
      <BigError error={error} />
    </Container>
  ),
  renderLoading = () => (
    <Container>
      <BigLoader />
    </Container>
  ),
  renderEmpty = ({ emptyText }) => (
    <Container py={5}>
      <Text fontSize={1} color='neutral.500'>{emptyText}</Text>
    </Container>
  )
}) =>
  isLoading
    ? renderLoading({})
    : error
      ? renderError({ error })
      : isEmpty
        ? renderEmpty({ emptyText })
        : children
          ? (typeof children === 'function' ? children() : children)
          : null

export default FetchHandler

FetchHandler.propTypes = {
  isLoading: bool.isRequired,
  error: object,
  isEmpty: bool.isRequired,
  emptyText: string,
  children: oneOfType([node, func]),
  renderError: func,
  renderLoading: func,
  renderEmpty: func
}

const Container = ({ children, ...props }) => (
  <Box display='flex' justifyContent='center' mx='auto' px={4} {...props}>
    {children}
  </Box>
)
