import ListHandler from './ListHandler'
import { connect } from 'react-redux'
import { selectLists } from '../../selectors'
import { fetchLists } from '../../actions'

const mapStateToProps = (state, ownProps) => ({
  lists: selectLists(state)
})

const mapDispatchToProps = {
  fetchLists
}

export default connect(mapStateToProps, mapDispatchToProps)(ListHandler)
