import React from 'react'
import classnames from 'classnames'

import './error.less'

const Error = ({ className, children, ...rest }) => (
  <div className={classnames('decorForm-error', className)} {...rest}>{children}</div>
)

export default Error
