import React, { useCallback, Fragment, useState } from 'react'
import { Box, Button, Heading, toast, Dialog } from '@realsoftworks/decor'
import { editDnsRecord, deleteDnsRecord } from 'sites/reducer/manage/dnsrecords/actions'
import { connect } from 'react-redux'
import { getDnsRecordsStatus } from 'sites/reducer/manage/dnsrecords/selectors'
import DnsRecordForm from '../../DnsRecordForm'

const withConnect = connect(
  state =>
    ({ status: getDnsRecordsStatus(state, 'update') }),
  { editDnsRecord, deleteDnsRecord })

const EditDnsRecord = withConnect(({
  domain,
  status,
  editDnsRecord,
  recordIndex,
  handleClose,
  record,
  deleteDnsRecord,
  allowedTypes,
  disallowedTypes
}) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleOpenDeleteModal = useCallback(() =>
    setIsDeleteModalOpen(true),
  [setIsDeleteModalOpen])

  const handleCloseDeleteModal = useCallback(() =>
    setIsDeleteModalOpen(false),
  [setIsDeleteModalOpen])

  const handleEdit = useCallback(record => {
    setIsUpdating(true)
    editDnsRecord(domain, record, recordIndex)
      .then(() => {
        toast.info(UPDATE_SUCCESS_NOTIF)
        handleClose()
      })
      .catch(() => {})
      .finally(() => setIsUpdating(false))
  }, [domain, editDnsRecord, recordIndex])

  const handleDelete = useCallback(() => {
    setIsDeleting(true)
    setIsDeleteModalOpen(false)
    deleteDnsRecord(domain, recordIndex)
      .then(() => toast.info(UPDATE_SUCCESS_NOTIF))
      .catch(() => {})
      .finally(() => setIsDeleting(false))
  }, [domain, deleteDnsRecord, recordIndex])

  return (
    <Box p={3} maxWidth='1100px' as='section' css='position: relative;'>
      <Heading as='h1' size={6} pb={4}>Update DNS Record</Heading>

      <DnsRecordForm
        record={record}
        disabled={status === 'LOADING'}
        onSubmit={handleEdit}
        allowedTypes={allowedTypes}
        disallowedTypes={disallowedTypes}
        footer={(
          <Fragment>
            <Box flex='1 1 auto'>
              <Button
                type='button'
                disabled={status === 'LOADING'}
                onClick={handleOpenDeleteModal}
                variant='danger'
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </Button>
            </Box>

            <Button
              ml={2}
              type='button'
              disabled={status === 'LOADING'}
              onClick={handleClose}
              variant='neutral'
            >
              Cancel
            </Button>

            <Button
              ml={2}
              disabled={status === 'LOADING'}
              type='submit'
              variant='primary'
            >
              {isUpdating ? 'Updating...' : 'Update'}
            </Button>
          </Fragment>
        )}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDelete}
      />
    </Box>
  )
})

export default EditDnsRecord

const UPDATE_SUCCESS_NOTIF = { title: 'Updated DNS record successfully ' }

const DeleteModal = ({ isOpen, onClose, onDelete }) => (
  <Dialog
    title='Confirm Delete'
    open={isOpen}
    onClose={onClose}
    toolbar={
      <Fragment>
        <Button onClick={onClose} variant='neutral'>Cancel</Button>
        <Button onClick={onDelete} variant='danger'>Delete Record</Button>
      </Fragment>
    }
  >
    This action is irreversible.
  </Dialog>
)
