/* eslint "eqeqeq": "warn" */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, IconButton } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'
import LoadingIcon from 'common/LoadingIcon'
import Numeric from 'common/format/Numeric'

const PagingToolbar = ({ loading, offset, limit, count, length, onChangePage }) => {
  const canPrev = offset == 0
  const canNext = (offset + limit) >= count
  const lastPageOffset = (count % limit)
    ? Math.floor(count / limit) * limit
    : count - limit

  return (
    <Box display='flex' alignItems='center' py={1}>
      <Box flex='1' />
      {loading && <Box mr={3}><LoadingIcon /></Box>}
      <Box pr={3}>
        <Text color='secondary' fontSize={0}>
          {offset + 1}-{Math.min(offset + length, count)} of
          {' '}
          <Numeric>{count}</Numeric>
        </Text>
      </Box>
      <IconButton disabled={canPrev} onClick={() => onChangePage(0)}><FontIcon icon='paging-first' /></IconButton>
      <IconButton disabled={canPrev} onClick={() => onChangePage(offset - limit)}><FontIcon icon='paging-prev' /></IconButton>
      <IconButton disabled={canNext} onClick={() => onChangePage(offset + limit)}><FontIcon icon='paging-next' /></IconButton>
      <IconButton disabled={canNext} onClick={() => onChangePage(lastPageOffset)}><FontIcon icon='paging-last' /></IconButton>
    </Box>
  )
}

PagingToolbar.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
}

export default PagingToolbar
