import * as ActionTypes from '../actions'
import without from 'lodash/array/without'

const initialState = {
  loading: true,
  saving: false,
  value: [],
  error: null
}

export default function (state = initialState, { payload, meta, type }) {
  switch (type) {
    case ActionTypes.DEALALERT_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        value: payload.result
      }
    }

    case ActionTypes.DEALALERT_LIST_FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload
      }
    }

    case ActionTypes.DEALALERT_CREATE: {
      return {
        ...state,
        saving: true
      }
    }

    case ActionTypes.DEALALERT_CREATE_SUCCESS: {
      return {
        ...state,
        saving: false,
        value: [...state.value, payload.result]
      }
    }

    case ActionTypes.DEALALERT_CREATE_FAILURE: {
      return {
        ...state,
        saving: false,
        error: payload
      }
    }

    case ActionTypes.DEALALERT_DESTROY_SUCCESS: {
      const newVals = without(state.value, payload.id)
      return {
        ...state,
        value: newVals
      }
    }
  }
  return state
}
