import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useSize } from 'react-hook-size'
import { MAPBOX_KEY } from 'const'
import ReactMapGL, { Marker, NavigationControl, Source, Layer } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import './MapboxReact.less'
import { useMap } from '../hooks/useMap'
import Legend from '../../../propertydata/components/Legend'
import PropertyMarkerIcon from './MapboxPropertyMarker'
import OwnerTypePropertyMarker from 'common/Map/components/MapboxOwnerTypePropertyMarker'
import { HouseMarker } from '../../Map'
import get from 'lodash/object/get'
import pick from 'lodash/object/pick'
import formatLeadToHistory from '../../../search/formatLeadToHistory'
import DrivesMapPin from '../../../drivingfordollars/components/DrivesMap/DrivesMapPin'
import idToColor from '../../../drivingfordollars/components/DrivesMap/idToColor'
import Popover from '../../../drivingfordollars/components/DrivesMap/Popover'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

const getLowestMiddleCoordinates = paths => {
  const { minLat, minLng, maxLng } = getCoordinatesExtremes(paths)
  const midLng = minLng + ((maxLng - minLng) / 2)
  return { lat: minLat, lng: midLng }
}

const getCoordinatesExtremes = points =>
  points.reduce((last, { lat, lng }) => ({
    minLat: (!last.minLat || lat < last.minLat) ? lat : last.minLat,
    maxLat: (!last.maxLat || lat > last.maxLat) ? lat : last.maxLat,
    minLng: (!last.minLng || lng < last.minLng) ? lng : last.minLng,
    maxLng: (!last.maxLng || lng > last.maxLng) ? lng : last.maxLng
  }), {})

const MAP_CONFIG = {
  maxZoom: 20,
  mapboxApiAccessToken: MAPBOX_KEY
}

const US_CENTER_COORDINATES = [
  {
    id: 'US_TOP_COORDINATES',
    latitude: 35.6870,
    longitude: -105.9378
  },
  {
    id: 'US_BOTTOM_COORDINATES',
    latitude: 41.2565,
    longitude: -95.9345
  }
]

const MapStyleOptionContainer = styled.div`
  cursor: pointer;
  background-color: white;
  z-index: 1;
  position: absolute;
  margin-left: ${p => p.isSearchHistory ? '35px' : '10px'};
  margin-top: ${p => p.isDrivingForDollarsDashboard ? '60px' : '10px'};
  width: fit-content;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;

  .map-style-header {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    svg {
      margin-left: 8px;
    }
  }

  .map-style-option {
    padding: 8px;

    &.selected {
      background-color: rgb(235, 235, 235);
      font-weight: bold;
    }
  }
`

const PopoverContainer = styled.div`
  top: ${p => p.topValue}px;
  left: ${p => p.leftValue}px;
  @media (min-width: 1250px) {
    left: ${p => p.leftValue - 100}px;
  }
  @media (min-width: 1450px) {
    left: ${p => p.leftValue - 200}px;
  }
  @media (min-width: 1650px) {
    left: ${p => p.leftValue - 300}px;
  }
  @media (min-width: 1850px) {
    left: ${p => p.leftValue - 400}px;
  }
  @media (min-width: 2050px) {
    left: ${p => p.leftValue - 500}px;
  }
  position: absolute;
  display: block;
`

const MarkerContainer = styled.div`
  position: relative;
  z-index: ${p => p.highlightedMarker.id === p.markerId && p.highlightedMarker.isHighlighted ? '9999999' : ''};
`

const MapboxReact = ({ isPropertyDetails, isSearchHistory, singleResult, multipleResults, neighbor, selectedProperty, cmaMarkers, cmaLegend, isDrivingForDollars, drivingForDollarsDrives, isDrivingForDollarsDashboard, isDriveDetails, isDealAlertsDashboard, printNow }) => {
  const [markers, setMarkers] = useState(US_CENTER_COORDINATES)
  const mapRef = useRef(null)
  const mapContainerRef = useRef(null)

  const [selectedMapStyle, setSelectedMapStyle] = useState('Map')
  const [showMapStyleOption, setShowMapStyleOption] = useState(false)

  const { width, height } = useSize(mapContainerRef)
  const [longitude, setLongitude] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [centerOnMarker, setCenterOnMarker] = useState(false)
  const [highlightedMarker, setHighlightedMarker] = useState({ id: '', isHighlighted: false })
  const [driveCoordinatesIdList, setDriveCoordinatesIdList] = useState([])

  const INITIAL_WIDTH = 400
  const INITIAL_HEIGHT = 400

  const { viewport, onViewportChange } = useMap({
    latitude: Number(latitude),
    longitude: Number(longitude),
    width: width || INITIAL_WIDTH,
    height: height || INITIAL_HEIGHT,
    markers,
    centerOnMarker,
    isPropertyDetails,
    printNow
  })

  const [popoverTarget, setPopoverTarget] = useState(null)
  const [hoveredLayer, setHoveredLayer] = useState(null)
  const [isHovering, setIsHovering] = useState(null)

  function setMarkerToBeCentered (marker) {
    setCenterOnMarker(true)
    setLongitude(marker.lng)
    setLatitude(marker.lat)
  }

  const mappedLeads = useMemo(() => {
    if (isDrivingForDollars)
      return (multipleResults || [])
        .map(l => ({
          ...pick(
            formatLeadToHistory({ property: get(l, 'meta.property') }),
            ['ownerType', 'isVacant', 'isForeclosure', 'loanToValue']
          ),
          id: get(l, 'id'),
          lat: get(l, 'meta.property.address.lat'),
          lng: get(l, 'meta.property.address.lon'),
          line1: get(l, 'meta.property.address.line1'),
          city: get(l, 'meta.property.address.city'),
          state: get(l, 'meta.property.address.state'),
          zip: get(l, 'meta.property.address.zip')
        }))
        .filter(l => typeof l.lat === 'number' && typeof l.lng === 'number')
    else
      return []
  }, [multipleResults, isDrivingForDollars])

  const renderPropertyMarkers = React.useMemo(() => markers.map(
    ({ id, ...marker }) => (
      <MarkerContainer key={id} highlightedMarker={highlightedMarker} markerId={id}>
        <Marker key={id} {...marker} offsetLeft={-17.5} offsetTop={-38}>
          {id !== 'US_TOP_COORDINATES' && id !== 'US_BOTTOM_COORDINATES' && cmaLegend === undefined && id !== 'dealsAlertMarker' && <PropertyMarkerIcon setHighlightedMarker={marker => setHighlightedMarker(marker)} id={id} marker={marker} setCenterOnMarker={Boolean => setCenterOnMarker(Boolean)} setMarkerToBeCentered={marker => setMarkerToBeCentered(marker)} isDrivingForDollars={isDrivingForDollars} />}
        </Marker>
      </MarkerContainer>
    )), [markers, highlightedMarker])

  const renderDrivingForDollarsPropertyMarkers = React.useMemo(() => mappedLeads && mappedLeads.map(l =>
    <div
      key={l.id}
      style={{ position: 'absolute', top: '22px', left: '13px' }}
    >
      <Marker key={l.id} latitude={l.lat} longitude={l.lng} offsetLeft={-15} offsetTop={-22}>
        <DrivesMapPin
          key={l.id}
          groupId='d4d'
          {...l}
        />
      </Marker>
    </div>
  ), [mappedLeads])

  const renderSelectedPropertyMarker = React.useMemo(() => {
    if (selectedProperty !== undefined) {
      let latitude, longitude
      if (cmaLegend === undefined) {
        latitude = selectedProperty.address.coordinates.latitude
        longitude = selectedProperty.address.coordinates.longitude
        return <Marker latitude={latitude} longitude={longitude} offsetLeft={-17.5} offsetTop={-38}>
          <OwnerTypePropertyMarker ownerType='home' />
        </Marker>
      } else {
        latitude = selectedProperty.lat
        longitude = selectedProperty.lon
        return <Marker latitude={latitude} longitude={longitude} offsetLeft={-9} offsetTop={-22}>
          <HouseMarker lat={latitude} lng={latitude}/>
        </Marker>
      }
    }
  }, [selectedProperty])

  const hasDriveCoordinates = drives => {
    const hasAtLeastOneDriveCoordinates = drives?.some(drive => drive?.points?.length > 0)
    return hasAtLeastOneDriveCoordinates
  }

  const getDriveCoordinatesIdList = drives => {
    const driveCoordinatesIdList = []
    if (hasDriveCoordinates(drives) === true)
      drives.forEach(drive => {
        if (drive.points && drive.points.length > 0) {
          driveCoordinatesIdList.push(drive.id)
          driveCoordinatesIdList.push(`Point${drive.id}`)
        }
      })
    return driveCoordinatesIdList
  }

  const renderDrivingForDollarsDrives = React.useMemo(() => {
    setDriveCoordinatesIdList(getDriveCoordinatesIdList(drivingForDollarsDrives))
    if (drivingForDollarsDrives && drivingForDollarsDrives.length > 0) {
      const d4dDrives = []

      drivingForDollarsDrives.forEach(drive => {
        const minDriveCoordinates = {}
        const maxDriveCoordinates = {}
        if (drive.points && drive.points.length > 0) {
          const { minLng, minLat, maxLat, maxLng } = getCoordinatesExtremes(drive.points)
          minDriveCoordinates.id = `${drive.id}min`
          minDriveCoordinates.latitude = minLat
          minDriveCoordinates.longitude = minLng
          d4dDrives.push(minDriveCoordinates)
          maxDriveCoordinates.id = `${drive.id}max`
          maxDriveCoordinates.latitude = maxLat
          maxDriveCoordinates.longitude = maxLng
          d4dDrives.push(maxDriveCoordinates)
        }
      })

      if (d4dDrives.length > 0 && _.isEqual(markers, d4dDrives) === false) setMarkers(d4dDrives)

      return drivingForDollarsDrives.map(drive => {
        if (drive.points && drive.points.length > 0) {
          const driveCoordinates = []

          drive.points.forEach(coordinates => {
            driveCoordinates.push([coordinates.lng, coordinates.lat])
          })

          const { lat, lng } = getLowestMiddleCoordinates(drive.points)

          const geojson = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  drive,
                  lat,
                  lng
                },
                geometry: {
                  type: 'LineString',
                  coordinates:
                    driveCoordinates
                }
              },
              {
                type: 'Feature',
                properties: {
                  drive,
                  lat,
                  lng
                },
                geometry: {
                  type: 'Point',
                  coordinates: driveCoordinates[0]
                }
              }
            ]
          }

          const layerStyle = {
            id: drive.id,
            type: 'line',
            source: 'geojson',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            }
          }

          const circleLayerStyle = {
            id: `Point${drive.id}`,
            type: 'circle',
            source: 'geojson'
          }

          return <div key={drive.id}>
            <Source id={drive.id} type='geojson' tolerance={0} data={geojson}>
              <Layer {...layerStyle} paint={{ 'line-color': idToColor(drive.id), 'line-width': 8 }} />
              <Layer {...circleLayerStyle} paint={{ 'circle-color': idToColor(drive.id), 'circle-radius': 4 }} maxzoom={5} />
            </Source>
          </div>
        }
      })
    }
  }, [drivingForDollarsDrives])

  useEffect(() => {
    let mounted = true
    const propertyList = []
    if (mounted && multipleResults === undefined && singleResult.length > 0) {
      let propertyData = {}
      propertyData = { ...singleResult[0] }
      propertyData.latitude = singleResult[0].address.coordinates.latitude
      propertyData.longitude = singleResult[0].address.coordinates.longitude
      propertyData.id = 99999
      propertyData.groupId = 'search-history'
      propertyData.lat = singleResult[0].address.coordinates.latitude
      propertyData.lng = singleResult[0].address.coordinates.longitude
      propertyData.ownerType = 'home'
      propertyData.loanToValue = singleResult[0].loanToValue
      propertyData.onClick = null
      propertyData.isForeclosure = null
      propertyData.isVacant = null
      if (propertyData.latitude && propertyData.latitude) propertyList.push(propertyData)
      if (_.isEqual(markers, propertyList) === false) setMarkers(propertyList)
    }
    return function cleanup () {
      mounted = false
    }
  }, [singleResult])

  useEffect(() => {
    let mounted = true
    const propertyList = []
    if (mounted && singleResult === undefined && multipleResults.length > 0) {
      let propertyData = {}
      if (!isDrivingForDollars && mappedLeads && mappedLeads.length === 0) {
        multipleResults.forEach(property => {
          propertyData = { ...property }
          propertyData.latitude = property.propertyAddressLat
          propertyData.longitude = property.propertyAddressLon
          propertyData.id = property.id
          propertyData.groupId = neighbor ? 'neighbor' : 'search-history'
          propertyData.lat = property.propertyAddressLat
          propertyData.lng = property.propertyAddressLon
          propertyData.ownerType = property.ownerType
          propertyData.loanToValue = property.loanToValue
          propertyData.onClick = property.onClick
          propertyData.isForeclosure = property.isForeclosure
          propertyData.isVacant = property.isVacant
          if (property.propertyAddressLat && property.propertyAddressLon) propertyList.push(propertyData)
        })
        if (_.isEqual(markers, propertyList) === false) setMarkers(propertyList)
      } else {
        mappedLeads.forEach(property => {
          propertyData = { ...property }
          propertyData.latitude = property.lat
          propertyData.longitude = property.lng
          propertyData.id = property.id
          propertyData.groupId = 'd4d'
          propertyData.lat = property.lat
          propertyData.lng = property.lng
          propertyData.ownerType = property.ownerType
          propertyData.loanToValue = property.loanToValue
          propertyData.onClick = property.onClick
          propertyData.isForeclosure = property.isForeclosure
          propertyData.isVacant = property.isVacant
          if (property.lat && property.lng) propertyList.push(propertyData)
        })
        if (_.isEqual(markers, propertyList) === false && driveCoordinatesIdList.length === 0) setMarkers(propertyList)
      }
    }
    return function cleanup () {
      mounted = false
    }
  }, [multipleResults])

  return (
    <div
      ref={mapContainerRef}
      className={singleResult === undefined ? 'mapbox' : 'mapbox singleResult'}
    >
      <ReactMapGL
        ref={mapRef}
        {...MAP_CONFIG}
        mapStyle={
          selectedMapStyle === 'Map'
            ? 'mapbox://styles/propelio-web/ckpwyhe8a081d17l9kcr1zdd6?optimize=true'
            : 'mapbox://styles/propelio-web/ckpx293np4kqe17nxdwqzoz8y?optimize=true'
        }
        {...viewport}
        preserveDrawingBuffer={true}
        width={printNow ? '700px' : '100%'}
        height={printNow ? '100%' : '100%'}
        onViewportChange={onViewportChange}
        interactiveLayerIds={driveCoordinatesIdList}
        getCursor={e => {
          setIsHovering(e.isHovering)
        }}
        onMouseEnter={e => {
          if (hasDriveCoordinates(drivingForDollarsDrives)) {
            const features = mapRef.current.queryRenderedFeatures(e.point, {
              layers: driveCoordinatesIdList
            })
            const drive = JSON.parse(features[0].properties.drive)
            drive.center = {}
            drive.center = e.center
            drive.lat = features[0].properties.lat
            drive.lng = features[0].properties.lng
            setHoveredLayer(drive)
          }
        }}
        reuseMaps={true}
      >
        <NavigationControl
          className={
            cmaLegend !== undefined
              ? 'navigation-control comparable-sales-tab'
              : 'navigation-control'
          }
          showCompass={false}
        />
        <MapStyleOptionContainer
          isDrivingForDollarsDashboard={isDrivingForDollarsDashboard}
          isSearchHistory={isSearchHistory}
          onMouseLeave={() => setShowMapStyleOption(false)}
        >
          <div
            className='map-style-header'
            onClick={() => setShowMapStyleOption(!showMapStyleOption)}
          >
            {selectedMapStyle} <FontAwesomeIcon icon={faAngleDown} />
          </div>
          {showMapStyleOption && (
            <div>
              <div
                className={
                  selectedMapStyle === 'Map'
                    ? 'map-style-option selected'
                    : 'map-style-option'
                }
                onClick={() => {
                  setSelectedMapStyle('Map')
                }}
              >
                Map
              </div>
              <div
                className={
                  selectedMapStyle === 'Satellite'
                    ? 'map-style-option selected'
                    : 'map-style-option'
                }
                onClick={() => {
                  setSelectedMapStyle('Satellite')
                }}
              >
                Satellite
              </div>
            </div>
          )}
        </MapStyleOptionContainer>
        {isHovering && hoveredLayer && isDriveDetails === undefined && (
          <PopoverContainer
            topValue={
              isDrivingForDollarsDashboard
                ? -290 + hoveredLayer.center.y
                : hoveredLayer.center.y - 440
            }
            leftValue={hoveredLayer.center.x}
          >
            <div data-purpose='popover-target' ref={setPopoverTarget} />
          </PopoverContainer>
        )}
        {popoverTarget && hoveredLayer && (
          <Popover
            key={hoveredLayer.id}
            target={popoverTarget}
            {...hoveredLayer}
          />
        )}
        {renderPropertyMarkers}
        {renderSelectedPropertyMarker}
        {renderDrivingForDollarsDrives}
        {renderDrivingForDollarsPropertyMarkers}
        {cmaMarkers !== undefined && cmaMarkers}
      </ReactMapGL>
      {cmaLegend !== undefined && !printNow && cmaLegend}
      {singleResult === undefined && cmaLegend === undefined && isDrivingForDollarsDashboard === undefined && isDealAlertsDashboard === undefined && <Legend containerProps={{
        style: {
          left: '10px',
          bottom: '40px',
          zIndex: '2'
        }
      }} />}
    </div>
  )
}

export default React.memo(MapboxReact)
