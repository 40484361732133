import React from 'react'
import { HouseMarker } from 'common/Map'
import CMAMarker from 'common/MapboxMLSMarker'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CMAMapLegend from './CMAMapLegend'
import { Box } from '@realsoftworks/decor'

import MapboxReact from 'common/Map/components/MapboxReact'
import { Marker } from 'react-map-gl'

const icons = {
  included: <FontAwesomeIcon icon='thumbs-up'/>,
  unknown: <FontAwesomeIcon icon='question'/>,
  excluded: <FontAwesomeIcon icon='thumbs-down'/>
}

class CMAMap extends React.Component {
  state = { active: '' }

  setActive = activeKey =>
    this.setState({ active: activeKey })

  render () {
    var { subject, comps, states, printNow } = this.props
    var subjectAddress = subject.values.address

    var center = { lat: subjectAddress.lat, lon: subjectAddress.lon }

    var coords = {}

    comps.forEach(c => {
      const key = getKey(c)

      if (!coords[key])
        coords[key] = { lat: c.address.lat, lon: c.address.lon, listings: [] }

      const state = states[c.key || c.id]
      const icon = icons[state || '']

      coords[key].listings.push({ listing: c, icon, state })
    })

    const markerCoordinates = () => [
      ...Object.values(coords)
        .filter(property => printNow ? property.listings.some(property => property.state === 'included') : property)
        .map(({ lat, lon }) => ({
          id: 'dealsAlertMarker',
          propertyAddressLat: lat,
          propertyAddressLon: lon
        })),
      {
        id: 'CMA',
        propertyAddressLat: center.lat,
        propertyAddressLon: center.lon
      }
    ]

    var compMarkers = Object.keys(coords).map(key => {
      var c = coords[key]

      var reactKey = `${c.lat}||${c.lon}`
      const isIncluded = c.listings.some(c => c.state === 'included')
      const isHovered = this.state.active === reactKey

      return (
        <div
          key={reactKey}
          style={{ zIndex: isHovered ? '9999999' : '', position: 'relative' }}
        >
          <Marker
            key={reactKey}
            latitude={c.lat}
            longitude={c.lon}
            offsetLeft={-15}
            offsetTop={-22}
          >
            <HouseMarker
              lat={c.lat}
              lng={c.lon}
              markerDetails={
                <CMAMarker
                  key={reactKey}
                  lat={c.lat}
                  lng={c.lon}
                  setActive={this.setActive}
                  active={this.state.active}
                  listings={c.listings}
                  onClick={this.props.onMarkerClick}
                  isIncluded={isIncluded}
                />
              }
            />
          </Marker>
        </div>
      )
    })

    return (
      <Box
        className='cma-map print-normalize-map-container'
        css='position: relative;'
      >
        <MapboxReact
          multipleResults={markerCoordinates()}
          selectedProperty={center}
          cmaMarkers={compMarkers}
          cmaLegend={<CMAMapLegend />}
          printNow={printNow}
        />
      </Box>
    )
  }
}

function getKey (c) {
  var { address } = c
  var { lat, lon } = address

  return lat + ',' + lon
}

export default CMAMap
