/* eslint "eqeqeq": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import { Card, CardTitle } from 'common/Card'
import ModelForm from '../../../views/util/ModelForm.react'
import FilesField from '../../../views/form/FilesField.react'
import LeadOwners from './LeadOwners.react'
import LeadContacts from './LeadContacts.react'
import EntityActivity from '../../../views/activity/EntityActivity.react'
import ContractFields from './ContractFields.react'
import FieldFactory from '../../../views/FieldFactory.react'

import { withRouter, Link } from 'react-router-dom'
import BigLoader from 'common/BigLoader'
import uniq from 'lodash/array/uniq'
import without from 'lodash/array/without'

import { isTrialing } from 'billing/selectors'
import { connect } from 'react-redux'

class LeadAttachments extends React.Component {
  render () {
    return (
      this.props.lead.id
        ? <FilesField
          value={this.props.lead.values.attachments || []}
          editable={true}
          formData={{ entity_id: this.props.lead.id, entity_type: 'lead', entity_field_name: 'attachments' }}
          onChange={this.props.onChange}/>
        : null
    )
  }
}

class LeadView extends React.Component {
  static propTypes = {
    leadId: PropTypes.string,
    lead: PropTypes.object.isRequired,
    leadOwners: PropTypes.array,
    entityTemplate: PropTypes.object.isRequired,
    entityTemplateType: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  getStateFromStore = () => {
    let { lead, entityTemplateType, entityTemplate } = this.props
    var loaded = !this.props.lead || !this.props.entityTemplate
    if (!lead) {
      lead = { id: this.props.leadId, actions: [], values: {} }
      loaded = false
    } else { loaded = true }

    if (!entityTemplate)
      entityTemplate = {}

    return {
      model: lead.values,
      lead,
      entityTemplate,
      entityTemplateType,
      fields: [],
      loaded
    }
  };

  state = {
    loadingFields: [],
    ...this.getStateFromStore()
  };

  render () {
    var fields = this.getFieldsByGroup()

    let { leadOwners } = this.props
    if (!leadOwners)
      leadOwners = []

    var lead = this.props.lead
    var values = lead.values

    if (!lead || !lead.values)
      return (<BigLoader/>)

    return (
      <div className='row'>
        <div className='col-md-6'>

          <Card className='card property-facts-card'>
            <CardTitle>Property Facts</CardTitle>
            <ModelForm
              loading={this.state.loadingFields}
              mode='editor'
              entityId={this.props.leadId}
              entityType={this.props.entityTemplateType}
              fields={fields.property}
              model={values}
              onChange={this.onChange}
            />
          </Card>

          <Card className='card financials-card'>
            <CardTitle>Financials</CardTitle>
            <ModelForm
              loading={this.state.loadingFields}
              mode='editor'
              entityId={this.props.leadId}
              entityType={this.props.entityTemplateType}
              fields={fields.financials} model={values} onChange={this.onChange}/>
          </Card>

          <Card className='card misc-card'>
            <CardTitle>Misc</CardTitle>
            <ModelForm
              loading={this.state.loadingFields}
              mode='editor'
              entityId={this.props.leadId}
              entityType={this.props.entityTemplateType}
              fields={fields.misc}
              model={values}
              onChange={this.onChange}
            />
          </Card>

        </div>
        <div className='col-md-6'>

          <Card className='card lead-owners-card'>
            <CardTitle>Lead Owners</CardTitle>
            <LeadOwners value={leadOwners} editable={lead.actions && lead.actions.indexOf('owners') != -1} onChange={this.onOwnersChange}/>
          </Card>

          {
            values.state == 'contract'
              ? <Card className='card contract-card'>
                <CardTitle>Contract Details</CardTitle>
                <ContractFields
                  lead={values}
                  loading={this.state.loadingFields}
                  onChange={this.onChange}
                />
              </Card>
              : ''
          }

          <Card className='card contacts-card'>
            <CardTitle>Contacts</CardTitle>
            <LeadContacts leadId={this.props.leadId} onChange={this.onChange.bind(this, 'contacts')} editable={true}/>
          </Card>

          <Card className='card attachments-card'>
            <CardTitle>Attachments</CardTitle>
            <LeadAttachments
              lead={this.props.lead}
              onChange={this.onChange.bind(this, 'attachments')}/>
          </Card>

          <Card className='card activity-card'>
            <CardTitle>Activity</CardTitle>
            <EntityActivity type={this.props.entityTemplateType} id={this.props.leadId}/>
          </Card>
        </div>
      </div>
    )
  }

  onOwnersChange = v => {
    this.onChange('leadowner', v)
  };

  onChange = (name, value) => {
    var model = {}
    if (Array.isArray(value) && value.length == 0)
      value = ''

    if (name === 'address')
      value = Object.assign(value, { lat: null, lon: null, streetview_lat: null, streetview_lon: null, streetview_heading: null })

    model[name] = value

    this.setState(({ loadingFields, ...state }) =>
      ({ ...state, loadingFields: uniq([...loadingFields, name]) }))

    return this.props.onUpdate({ id: this.props.lead.id, body: model })
      .finally(() => {
        this.setState(({ loadingFields, ...state }) =>
          ({ ...state, loadingFields: without(loadingFields, name) }))
      })
  };

  onPriorityChange = (e, value) => {
    this.onChange('priority', value ? 1 : 0)
  };

  getFieldsByGroup = () => {
    var blankfields = { property: [], financials: [], misc: [], attachments: null, contacts: null }
    var template = this.props.entityTemplate
    if (!template || !template.fields)
      return blankfields

    var fields = template.fields.reduce((memo, value) => {
      var g = value.group
      if (value.name == 'attachments') { memo.attachments = value } else if (value.name == 'contacts') { memo.contacts = value } else {
        if (!memo[g])
          g = 'misc'

        memo[g].push(value)
      }

      return memo
    }, blankfields)

    return fields
  };

  onGeocodeError = () => {
    this.setState({ geocodeError: true })
  };

  renderTitleTools = () => {
    var stateLabels = {
      pending: 'Lead',
      contract: 'Under Contract',
      portfolio: 'Portfolio',
      listing: 'Listing',
      sold: 'Completed'
    }

    var allStateOptions = [{
      label: stateLabels.pending,
      type: 'pending',
      handler: this.onStateClick,
      routeName: 'leads'
    }, {
      label: stateLabels.contract,
      type: 'contract',
      handler: this.onStateClick,
      routeName: 'contracts'
    }, {
      label: stateLabels.portfolio,
      type: 'portfolio',
      handler: this.onStateClick,
      routeName: 'portfolio'
    }, {
      label: stateLabels.listing,
      type: 'listing',
      handler: this.onStateClick,
      routeName: 'listings'
    }, {
      label: stateLabels.sold,
      type: 'sold',
      handler: this.onStateClick,
      routeName: 'history'
    }]

    var { lead } = this.props
    var { values } = lead
    var { state, deleted, active } = values

    var stateOptions = []
    allStateOptions.forEach(function (v, i) {
      if (state != v.type)
        stateOptions.push(v)
    }, this)

    var substate = ''
    if (deleted)
      substate = 'Deleted'
    else if (!active)
      substate = 'Archived'

    return (
      <React.Fragment>
        <div className='btn-group btn-status hidden-xs' key='mobile-btns'>
          <button type='button' className='btn btn-primary dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>
            {substate} {stateLabels[state]} <span className='caret'/><br/>
            (Update Status)
          </button>
          <ul className='dropdown-menu dropdown-menu-right ' role='menu'>
            {stateOptions.map(function (item) {
              return (<li key={item.type}><a onClick={this.onStateClick.bind(this, item)}>{item.label}</a></li>)
            }, this)}
            <li key='divider' className='divider'/>
            <li key='archive'><a onClick={this.onArchive}>{substate == 'Archived' ? 'Un-Archive' : 'Archive'}</a></li>
            <li key='delete'><a onClick={this.onDelete}>{substate == 'Deleted' ? 'Un-delete' : 'Delete'}</a></li>
          </ul>
        </div>
        {
          (values.address && values.address.county) || this.state.geocodeError
            ? <CMALink key='cmalink' lead={lead}/>
            : <span key='aspan'/>
        }
        <div className='btn-group visible-xs' key='state-dropdown'>
          <button type='button' className='btn btn-default dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>
            <i className='fa fa-gear fa-2x'/>
          </button>
          <ul className='dropdown-menu dropdown-menu-right ' role='menu'>
            <li key='stateheader' className='dropdown-header'>Change State</li>
            {stateOptions.map(function (item) {
              return (<li key={item.type}><a onClick={this.onStateClick.bind(this, item)}>{item.label}</a></li>)
            }, this)}
            <li key='divider' className='divider'/>
            <li key='archive'><a onClick={this.onArchive}>{substate == 'Archived' ? 'Un-Archive' : 'Archive'}</a></li>
            <li key='delete'><a onClick={this.onDelete}>{substate == 'Deleted' ? 'Un-delete' : 'Delete'}</a></li>
            <li key='divider2' className='divider'/>
            <li>
              <Link to={`/search/history/${lead.id}`}>Run CMA</Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  };

  onStateClick = action => {
    const params = { id: this.props.lead.id, body: { state: action.type } }
    this.props.onUpdate(params)
    // this.props.history.replace(`${action.routeName}/${this.props.lead.id}`);
  };

  onArchive = () => {
    var model = this.props.lead.values
    var id = this.props.lead.id
    const params = { id }
    if (model.active == 0 && model.deleted == 0)
      params.body = { deleted: false, active: true }
    else
      params.body = { deleted: false, active: false }

    this.props.onUpdate(params)
  };

  onDelete = () => {
    var model = this.props.lead.values
    var id = this.props.lead.id
    const params = { id }
    if (model.deleted == 1)
      params.body = { deleted: false, active: true }
    else
      params.body = { deleted: true, active: false }

    this.props.onUpdate(params)
  };
}
let CMALink = createReactClass({
  displayName: 'CMALink',

  getInitialState () {
    return {
      tooltipShown: false
    }
  },

  render () {
    const { lead } = this.props
    const { values } = lead

    const disabled = false
    const link = `/search/history/${lead.id}`

    return (
      <span style={ { height: '100%', position: 'relative' } } className='hidden-xs' onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
        <Link className={'btn btn-calculate ' + (!disabled ? 'btn-success' : 'btn-default')} to={link} disabled={disabled}>
          {
            values.arv
              ? <span>
                <small>ARV:</small> {FieldFactory.format(values.arv, 'monetary', { decimals: 0 })} <br/>
                <small>(view comps)</small>
              </span>
              : 'Run CMA'
          }
        </Link>
      </span>
    )
  },

  showTooltip () {
    this.setState({ tooltipShown: true })
  },

  hideTooltip () {
    this.setState({ tooltipShown: false })
  }
})

CMALink = connect(state => ({
  isTrialing: isTrialing(state)
}))(CMALink)

export default withRouter(LeadView)
