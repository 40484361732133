/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "no-class-assign": "warn" */
'use strict'

import { selectors as taskSelectors } from '../../tasks/index.js'
import PropTypes from 'prop-types'
import React from 'react'

import TaskForm from '../../tasks/components/tasks/TaskForm'
import TaskList from '../../tasks/components/tasks/TaskList.js'
import { searchByReference, create } from '../../tasks/actions'
import { connect } from 'react-redux'

class DropdownMenu extends React.Component {
  render () {
    return (
      <ul className='dropdown-menu' role='menu'>
        {this.props.children}
      </ul>
    )
  }
}

class LeadTasks extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props)
    var params = { active: true, completed: true, owner: 'everyone', groupBy: 'due', orderBy: 'due' }

    this.state = {
      params,
      open: false
    }
  }

  componentWillMount () {
    var referenceId = this.props.lead.id
    this.props.dispatch(searchByReference('lead', referenceId))
  }

  onSubmit = t => {
    this.props.dispatch(create(t))
    this.setState({ open: false })
  };

  onChangeParam = (field, value) => {
    var params = Object.assign({}, this.state.params || {})
    params[field] = value

    if (params.active == false && params.completed == false)
      params[field == 'active' ? 'completed' : 'active'] = true

    this.setState({ params: params })
  };

  render () {
    var { active, completed } = this.state.params
    var { open } = this.state

    var tasks = this.filterTasks(this.props.tasks)

    var address = this.props.lead.values.address || {}
    var leadName = address.line1 || ''

    return (
      <div className={'lead-tasks ' + (open ? 'open' : '') }>
        <div className='toolbar btn-toolbar'>
          <div className='btn-group'>
            <button type='button' className='btn btn-default dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>
              State: {
                active !== false
                  ? ('Active' + (
                    completed ? ' and Completed' : '')) : 'Completed'} <span className='caret'></span>
            </button>
            <DropdownMenu>
              <li onClick={this.onChangeParam.bind(this, 'active', !active)}>
                <a>
                  <input className='dropdown-checkbox' type='checkbox' checked={active} readOnly/>
                  Active
                </a>
              </li>
              <li onClick={this.onChangeParam.bind(this, 'completed', !completed)}>
                <a>
                  <input className='dropdown-checkbox' type='checkbox' checked={completed} readOnly/>
                  Completed
                </a>
              </li>
            </DropdownMenu>
          </div>

          <div className='btn-group pull-right'>
            <button className={'btn pull-right btn-add ' + (open ? 'btn-danger' : 'btn-success')} onClick={this.toggleOpen}><span className='icon glyphicon glyphicon-plus'></span> {open ? 'Cancel' : 'Add Task'}</button>
          </div>

        </div>
        <div className='form-ct'>
          <TaskForm collapsed={false} onSubmit={this.onSubmit} reference={{ id: this.props.lead.id, type: 'lead', name: leadName }}/>
        </div>
        <TaskList dispatch={this.props.dispatch} tasks={tasks} showRefs={['contact']}/>
      </div>
    )
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  };

  filterTasks = tasks => {
    var { active, completed } = this.state.params
    var ret = []
    tasks.forEach(function (t) {
      var isComplete = t.values.complete
      if (isComplete && !completed)
        return false

      if (!isComplete && !active)
        return false

      ret.push(t)
    })
    return ret
  };
}

const mapStateToProps = (state, props) => ({
  tasks: taskSelectors.getTasksByReference(state, 'lead', props.lead.id)
})

LeadTasks = connect(mapStateToProps, null)(LeadTasks)

export default LeadTasks
