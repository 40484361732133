import React from 'react'
import { Box, Heading, Text, Caption, Paragraph } from '@realsoftworks/decor'
import styled from 'styled-components'
import Card from 'common/Card'
import { monetary } from 'common/util/number'
import { Numeric, Monetary } from 'common/format'
import LocationTooltip from '../../LocationTooltip'
import * as dateUtil from 'common/util/date'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'

const FactBox = ({ ...props }) => <Box minHeight={50} mb={4} {...props} />

const FadeBox = styled(Box)`
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 180px;
    content: ' ';
    background: linear-gradient(270deg, #FFFFFF 65.62%, rgba(255, 255, 255, 0) 100%);
  }
`

const BuyerCaption = ({ ...props }) => <Caption fontSize={0} display='block' color='secondary' {...props} />

const formatFinancial = value => value !== null ? monetary(value, { decimals: 0 }) : '--'

const LastDeal = ({ contact }) => {
  const date = dateUtil.format(contact.latestDealDate, dateUtil.formats.MONTH_YEAR)
  const amount = monetary(contact.latestDealSpend, { decimals: 0 })

  let ret = ''
  if (date)
    ret += date

  if (amount) {
    if (date)
      ret += ': '

    ret += amount
  }

  return ret
}

export default function BuyerLenderCard ({ contact, ...props }) {
  const { minSpend, maxSpend } = contact
  let spendRange = '--'
  if (minSpend || maxSpend)
    spendRange = `${formatFinancial(contact.minSpend)} - ${formatFinancial(contact.maxSpend)}`

  return (
    <Card borderTop='12px solid' borderColor='blue.500' {...props}>
      <Box mb={5}>
        <Box style={{ position: 'relative' }}>
          <FadeBox>
            <Heading size={4} style={{ whiteSpace: 'nowrap' }}>{contact.name}</Heading>
          </FadeBox>
        </Box>
        <AddressPreviewLink
          fontSize={1}
          address={{
            line1: contact.addressLine1,
            city: contact.addressCity,
            state: contact.addressState,
            zip: contact.addressZip
          }}
        />
      </Box>
      <Box display='flex' alignItems='top' mx={-4}>
        <Box flex='2' mx={4}>
          <Box display='flex' mx={-4}>
            <Box flex='10' alignItems='top' mx={4}>
              <FactBox>
                <BuyerCaption>Avg Deal</BuyerCaption>
                <Heading size={4}>{formatFinancial(contact.avgSpend)}</Heading>
              </FactBox>
              <FactBox>
                <BuyerCaption>Price Range</BuyerCaption>
                <Text>{spendRange}</Text>
              </FactBox>
            </Box>

            <Box flex='13' alignItems='top' mx={4}>
              <FactBox>
                <BuyerCaption>Volume</BuyerCaption>
                <Heading size={5}>
                  {contact.totalSpend ? (
                    <span>
                      <Monetary useToK decimals={1}>{contact.totalSpend}</Monetary>
                      {' '}<Text color='secondary' fontSize='inherit'>in</Text>
                      {' '}<Numeric decimals={0}>{contact.totalDeals}</Numeric>
                      {' '}<Text color='secondary' fontSize='inherit'>deals</Text>
                    </span>
                  ) : '--'}
                </Heading>
              </FactBox>
              <FactBox>
                <BuyerCaption>Last Deal</BuyerCaption>
                <Text><LastDeal contact={contact} /></Text>
              </FactBox>
            </Box>
          </Box>

        </Box>

        <Box flex='1' mx={4}>
          <BuyerCaption>Geography</BuyerCaption>
          <Paragraph mb={5}>
            <LocationTooltip locations={contact.states} label='State' pluralLabel='States' />
          </Paragraph>
          <Paragraph>
            <LocationTooltip locations={contact.counties} label='County' pluralLabel='Counties' />
          </Paragraph>
        </Box>

      </Box>
    </Card>
  )
}
