import React, { Fragment, useState, useRef } from 'react'
import { ListItem, Menu, IconButton, Divider } from '@realsoftworks/decor'
import { useHistory, FILTER_OPTS, DEFAULT_FILTER } from 'search/HistoryContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

const HistoryFilter = () => {
  const { filter: selectedFilter, setFilterAndfetchHistory } = useHistory()
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const isActive = selectedFilter !== DEFAULT_FILTER

  return (
    <>
      <IconButton
        fontSize='22px'
        color={isActive ? 'blue.500' : 'secondary'}
        ref={ref}
        onClick={() => setIsOpen(v => !v)}
      >
        <FontAwesomeIcon icon={faFilter} />
      </IconButton>
      <Menu
        target={ref.current}
        open={isOpen}
        onClose={() => setIsOpen(null)}
        variant='dense'
      >
        {FILTER_OPTS.map((group, i) => (
          <Fragment key={i}>
            {i !== 0 && <Divider style={{ margin: '8px 0' }} />}
            {group.map(({ label, filter }, i) => (
              <ListItem
                key={i}
                fontSize={0}
                bg={filter === selectedFilter ? 'primary.500' : ''}
                color={filter === selectedFilter ? 'white' : ''}
                css={`
                  &:hover {
                    ${filter === selectedFilter ? `
                      background-color: #38A1A8;
                    ` : `
                      color: black;
                    `}
                  }
                `}
                onClick={() => {
                  setFilterAndfetchHistory(filter)
                  setIsOpen(null)
                }}
              >
                {label}
              </ListItem>
            ))}
          </Fragment>
        ))}
      </Menu>
    </>
  )
}

export default HistoryFilter
