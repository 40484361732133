import { useState, useEffect } from 'react'
import WebMercatorViewport from 'viewport-mercator-project'
import { getBounds } from '../utils/mapUtils'

export const useMap = ({ width, height, markers, latitude, longitude, centerOnMarker, isPropertyDetails, printNow }) => {
  const [viewport, setViewport] = useState({
    width: width,
    height: height
  })

  useEffect(() => {
    if (latitude && longitude && centerOnMarker) {
      const MARKERS_BOUNDS = getBounds([{ latitude, longitude }])
      setViewport(viewport => {
        const nextViewport = new WebMercatorViewport({
          ...(viewport),
          width,
          height
        }).fitBounds(MARKERS_BOUNDS, {
          padding: 29,
          maxZoom: 18
        })
        return nextViewport
      })
    }
  }, [centerOnMarker])

  useEffect(() => {
    if (width && height && markers.length) {
      const MARKERS_BOUNDS = getBounds(markers)
      setViewport(viewport => {
        const nextViewport = new WebMercatorViewport({
          ...(viewport),
          width,
          height
        }).fitBounds(MARKERS_BOUNDS, {
          padding: printNow ? 100 : 29,
          maxZoom: isPropertyDetails ? 10 : 18
        })

        return nextViewport
      })
    }
  }, [width, height, markers, printNow])

  const onViewportChange = nextViewport =>
    setViewport(nextViewport)

  return {
    onViewportChange,
    viewport
  }
}
