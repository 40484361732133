import { GOOGLE_KEY } from 'const'

let promise

const func = `$loadGoogleMaps${Date.now()}`

export default function mapLoader () {
  if (promise) return promise

  promise = new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject(new Error('google map cannot be loaded outside browser env'))
      return
    }

    if (window.google && window.google.maps) {
      resolve(window.google.maps)
      return
    }

    window[func] = () => {
      delete window[func]
      resolve(window.google.maps)
    }

    const url = `https://maps.googleapis.com/maps/api/js?libraries=places&sensor=false&key=${GOOGLE_KEY}&callback=${func}`

    var s = document.createElement('script')
    s.src = url
    document.head.appendChild(s)
  })

  return promise
}
