
import Router from './Router.js'
import ajax from './Ajax'

const Model = function (name) {
  var servicedef = Router.getServiceDef(name)

  return {
    create: function (data) {
      return ajax(servicedef.create, {
        data: data,
        dataType: 'json',
        type: 'POST'
      })
    },

    update: function (id, data) {
      return ajax(servicedef.update + '/' + id, {
        data: data,
        dataType: 'json',
        type: 'PUT'
      })
    },

    destroy: function (id) {
      return ajax(servicedef.destroy + '/' + id, {
        dataType: 'json',
        type: 'DELETE'
      })
    },

    read: function (id) {
      return ajax(servicedef.read + '/' + id, {
        dataType: 'json',
        type: 'GET'
      })
    },

    list: function (data) {
      return ajax(servicedef.list, {
        dataType: 'json',
        type: 'GET',
        data: data
      })
    },

    search: function (data) {
      return ajax(servicedef.search, {
        data: data,
        dataType: 'json',
        type: 'GET'
      })
    },

    execute: function (/* method, id, data */) {
      const c = arguments.length
      const method = arguments[0]

      var url = servicedef[method] || (servicedef.base + '/' + method)

      let data = {}

      for (let i = 1; i < c; i++) {
        const a = arguments[i]
        if (typeof a !== 'object') { url += ('/' + a) } else {
          data = a
          break
        }
      }

      return ajax(url, {
        data: data,
        dataType: 'json',
        type: 'POST'
      })
    }
  }
}

export default Model
