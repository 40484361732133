
import { combineReducers } from 'redux'

import current from './current'
import permissions from './permissions'
import team from './team'
import settings from './settings'

export default combineReducers({
  current,
  permissions,
  team,
  settings
})
