import React from 'react'
import { Card, Heading } from '@realsoftworks/decor'
import Mortgages from './Mortgages'
import Transfers from './Transfers'

const TransfersAndMortgages = ({ transfers, currentMortgages }) => (
  <Card p={3}>
    <Heading
      as='h2'
      pb={3}
      color='primary.500'
      size={5}
    >
      Current Mortgages
    </Heading>

    <Mortgages mortgages={currentMortgages} />

    <Heading
      as='h2'
      pt={6}
      pb={3}
      color='primary.500'
      size={5}
    >
      Transaction &amp; Mortgage History
    </Heading>
    <Transfers transfers={transfers} />
  </Card>
)

export default TransfersAndMortgages
