import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Box, Stack } from '@realsoftworks/decor'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faTasks, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { selectors as userSelectors } from 'users'
import { selectors as mlsSelectors } from 'mls'
import { selectors as billingSelectors } from 'billing'
import { fetchMyBrokers } from 'mls/actions'
import flow from 'lodash/function/flow'

import Menu from './Menu'
import Branding from './Branding'
import TopLink from './TopLink'
import Notice from './Notice'
import TrialNotice from './TrialNotice'
import BetaNotice from './BetaNotice'

import { NotificationsWidget } from 'notifications'
import SearchInput from 'search/components/SearchInput'
import { withSearchLogicConnect, useSearchLogic } from '../../modules/search/SearchContext'
import RouteReqComponent from 'redirect/RouteReqComponent'
import { ROUTES } from '../../const'
import waitAndOpenIntercom from 'common/waitAndOpenIntercom'

const SEARCH_INPUT_WIDTH = 420

const TopBar = ({
  user,
  broker,
  isPaid,
  isTrialing,
  hasBillingInfo,
  fetchMyBrokers,
  route,

  /**
   * This should be passed as true when TopBar is included in the
   * PHP part of the app
   */
  isPhpEmbed,
  ...props
}) => {
  useSearchLogic(props)

  React.useEffect(() => {
    fetchMyBrokers()
  }, [])

  const [topBox, setTopBox] = React.useState()

  const onSupportClick = e => {
    e.preventDefault()
    waitAndOpenIntercom()
  }

  const { notice } = window.App

  const boxHeight = topBox ? topBox.offsetHeight : TOP_BAR_HEIGHT

  React.useEffect(() => {
    if (!user) window.location.replace('/logout')
  }, [])

  return user ? (
    <Stack value={10}>
      {zIndex =>
        <Box style={{ zIndex: zIndex + 4, position: 'relative' }} ref={setTopBox}>
          {notice && <Notice>{notice}</Notice>}
          <Box
            height={TOP_BAR_HEIGHT}
            bg='#504A40'
            display='flex'
            pl='10px'
            pr={[0, null, null, null, 3]}
            py='6px'
            alignItems='center'
            css='position: relative'
            {...props}
          >
            <Menu mr={[2, null, null, null, 5]} route={route} sideSheetProps={{ pt: boxHeight + 24 }} isPhpEmbed={isPhpEmbed} />
            <Branding route={route} broker={broker} />
            <TrialNotice display={['none !important', null, null, 'inline-block !important']} ml={3} route={route} />
            <Box
              px={[2, null, null, null, 5]}
              pr={[0, 2, null, null, 5]}
              flex='1'
              display='flex'
              justifyContent='center'
            >
              {/* Absolutely centered when 840px wide and up */}
              <Box
                width='100%'
                maxWidth={`${SEARCH_INPUT_WIDTH}px`}
                css={`
                  @media (min-width: 840px) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                `}
              >
                {!isPhpEmbed && (
                  <RouteReqComponent routeKey={ROUTES.SEARCH}>
                    <SearchInput instanceId='topbar' maxWidth={`${SEARCH_INPUT_WIDTH}px`} />
                  </RouteReqComponent>
                )}
              </Box>
            </Box>
            <Box display={['none', 'flex']} alignItems='center' height='100%' ml='auto'>
              <TopLink content='Tasks' isRouterLink={route} to='/tasks'>
                <FontAwesomeIcon icon={faTasks} />
              </TopLink>

              <TopLink content='Contacts' isRouterLink={route} to='/contacts'>
                <FontAwesomeIcon icon={faAddressBook} />
              </TopLink>

              <TopLink content='Help' onClick={onSupportClick}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </TopLink>
            </Box>
            <NotificationsWidget />
            <BetaNotice display={['none !important', null, null, 'inline-block !important']} ml={3} route={route} />
          </Box>
        </Box>}

    </Stack>
  ) : null
}

TopBar.defaultProps = {
  route: true
}

const mapStateToProps = state => ({
  user: userSelectors.getCurrent(state),
  broker: mlsSelectors.getLastBroker(state),
  isPaid: billingSelectors.isPaid(state),
  isTrialing: billingSelectors.isTrialing(state),
  hasBillingInfo: billingSelectors.hasBillingInfo(state)
})

const mapDispatchToProps = {
  fetchMyBrokers
}

// Sidebar needs withRouter. Without it SidebarItem (which depends on
// withRouter) will not update on route change
export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withSearchLogicConnect,
  withRouter
)(TopBar)

// Toast needs this to position itself below the top bar
export const TOP_BAR_HEIGHT = 48

class ButtonDropdown extends React.Component {
  render () {
    var options = this.props.options
    var toggleClassName = this.props.className + ' dropdown-toggle'
    var groupClassName = this.props.navbarBtn ? 'btn-group navbar-btn' : 'btn-group'

    return (
      <div className={groupClassName}>
        <button type='button' className={this.props.className} onClick={this.onClick.bind(this)}>{this.props.children}</button>
        <button type='button' className={toggleClassName} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
          <span className='caret' />
          <span className='sr-only'>Toggle Dropdown</span>
        </button>
        <ul className='dropdown-menu'>
          {options.map(function (option, i) {
            var selectHandler = this.onSelect.bind(this, option.id)
            var key = 'option-' + i
            if (option instanceof Object)
              return <li key={key}><a onClick={selectHandler}>{option.text}</a></li>
            else
              return <li key={key}><a>{option}</a></li>
          }, this)}
        </ul>
      </div>
    )
  }

  componentDidMount () {
  }

  onClick () {
    if (this.props.onClick)
      this.props.onClick()
  }

  onSelect (id) {
    if (this.props.onSelect)
      this.props.onSelect(id)
  }
}

ButtonDropdown.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.array,
  onSelect: PropTypes.func
}

ButtonDropdown.defaultProps = {
  className: 'btn btn-primary',
  navbarBtn: false
}
