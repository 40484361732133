import React from 'react'
import classnames from 'classnames'

import './hint.less'

const Hint = ({ className, children, ...rest }) => (
  <div className={classnames('decorForm-hint', className)} {...rest}>{children}</div>
)

export default Hint
