import { combineReducers } from 'redux'
import createFetchReducer from 'common/createFetchReducer'
import mapValues from 'lodash/object/mapValues'

import {
  FETCH_DRIVES,
  FETCH_DRIVES_SUCCESS,
  FETCH_DRIVES_FAILED,
  FETCH_MAP_DRIVES,
  FETCH_MAP_DRIVES_SUCCESS,
  FETCH_MAP_DRIVES_FAILED,
  FETCH_LISTS,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILED,
  FETCH_LEADS,
  FETCH_LEADS_SUCCESS,
  FETCH_LEADS_FAILED,
  EDIT_LIST,
  EDIT_LIST_SUCCESS,
  EDIT_LIST_FAIL,
  FETCH_DRIVES_STATS,
  FETCH_DRIVES_STATS_SUCCESS,
  FETCH_DRIVES_STATS_FAILED,
  FETCH_LISTS_STATS,
  FETCH_LISTS_STATS_SUCCESS,
  FETCH_LISTS_STATS_FAILED,
  FETCH_LEADS_PER_DRIVE_SUCCESS
} from './actions'

export default combineReducers({
  drives: createFetchReducer(
    FETCH_DRIVES,
    FETCH_DRIVES_SUCCESS,
    FETCH_DRIVES_FAILED,
    {},
    (state, { type, payload }, fetchReducer) => {
      switch (type) {
        case FETCH_LEADS_PER_DRIVE_SUCCESS:
          return {
            ...state,
            value: mapValues(state.value, drive =>
              ({
                ...drive,
                leadsCount: payload.driveIdToCount[drive.id]
              }))
          }
        default:
          return fetchReducer(state, { type, payload })
      }
    }
  ),

  mapDrives: createFetchReducer(
    FETCH_MAP_DRIVES,
    FETCH_MAP_DRIVES_SUCCESS,
    FETCH_MAP_DRIVES_FAILED,
    { progress: null, isRecursionInProgress: false, afterDate: null },
    (state, { type, payload }, fetchReducer) => {
      switch (type) {
        case FETCH_MAP_DRIVES:
          return {
            ...state,
            isLoading: !payload.isRecursion,
            afterDate: payload.afterDate,
            error: null
          }
        case FETCH_MAP_DRIVES_SUCCESS:
          return {
            ...state,
            value: { ...state.value, ...payload.value },
            count: payload.count,
            isLoading: false,
            progress: payload.progress,
            isRecursionInProgress: !!(payload.isRecursion &&
              payload.progress &&
              payload.progress < 1)
          }
        default:
          return fetchReducer(state, { type, payload })
      }
    }
  ),

  drivesStats: createFetchReducer(
    FETCH_DRIVES_STATS,
    FETCH_DRIVES_STATS_SUCCESS,
    FETCH_DRIVES_STATS_FAILED
  ),

  listsStats: createFetchReducer(
    FETCH_LISTS_STATS,
    FETCH_LISTS_STATS_SUCCESS,
    FETCH_LISTS_STATS_FAILED
  ),

  leads: createFetchReducer(
    FETCH_LEADS,
    FETCH_LEADS_SUCCESS,
    FETCH_LEADS_FAILED
  ),

  lists: createFetchReducer(
    FETCH_LISTS,
    FETCH_LISTS_SUCCESS,
    FETCH_LISTS_FAILED,
    { isEditing: false, editError: null },
    (state, { type, payload }, fetchReducer) => {
      switch (type) {
        case EDIT_LIST:
          return {
            ...state,
            isEditing: true,
            editError: null
          }
        case EDIT_LIST_SUCCESS:
          return {
            ...state,
            value: {
              ...state.value,
              [payload.id]: payload.list
            },
            isEditing: false
          }
        case EDIT_LIST_FAIL:
          return {
            ...state,
            isEditing: false,
            editError: payload.error
          }
        default:
          return fetchReducer(state, { type, payload })
      }
    }
  )
})
