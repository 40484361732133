import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox } from '@realsoftworks/decor'

import uncontrollable from 'uncontrollable'

class CheckListItem extends React.Component {
  render () {
    const { label, ...other } = this.props

    return (
      <Checkbox {...other} label={label} />
    )
  }
}

CheckListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
}

class CheckList extends React.Component {
  constructor (props) {
    super(props)

    this._handleCheckChange = this._handleCheckChange.bind(this)
  }

  _handleCheckChange (e, checked) {
    var value = e.target.value
    var currentValue = this.props.value || []

    var values = [...currentValue]
    var index = values.indexOf(value)
    var hasValue = (index !== -1)
    var changed = false

    if (checked && !hasValue) {
      values.push(value)
      changed = true
    } else if (!checked && hasValue) {
      values.splice(index, 1)
      changed = true
    }

    if (changed) {
      this.props.onChange && this.props.onChange(e, values)
      this.props.onValueChange && this.props.onValueChange(values)
    }
  }

  render () {
    const { options, name, value } = this.props
    const hasValue = value !== null && value !== undefined

    const items = options.map(opt => {
      var props = typeof opt === 'string' ? { value: opt, label: opt } : opt
      return <CheckListItem key={props.value} {...props} name={name} checked={hasValue && value.indexOf(props.value) !== -1} onChange={this._handleCheckChange} />
    })

    return (
      <div className='check-list'>
        {items}
      </div>
    )
  }
}

CheckList.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string
}
export default uncontrollable(CheckList, { value: 'onValueChange' })
