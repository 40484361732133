import React, { useState, useCallback } from 'react'
import { Card } from '@realsoftworks/decor'
import PagingToolbar from 'common/PagingToolbar'
import FetchHandler from 'common/FetchHandler'
import DriveList from 'drivingfordollars/components/DriveList'
import withDrives from 'drivingfordollars/utils/withDrives'

const ITEM_LIMIT = 50

const DrivesListSection = ({ drives, count, isLoading, hasLoaded, error, fetchDrives }) => {
  const { offset, onChangePage } = driveListSectionHook({ fetchDrives })

  return (
    <Card variant='basic' mt={7}>
      <FetchHandler
        isLoading={isLoading}
        error={error}
        isEmpty={hasLoaded && drives.length === 0}
        emptyText='You don’t have any drive yet.'
      >
        <DriveList drives={drives} />

        {typeof count === 'number' && (
          <Card
            mt={5}
            borderTop='1px solid'
            borderColor='neutral.200'
            px={4}
          >
            <PagingToolbar
              offset={offset}
              limit={ITEM_LIMIT}
              count={count}
              length={drives.length}
              onChangePage={onChangePage}
            />
          </Card>
        )}
      </FetchHandler>
    </Card>
  )
}

export default withDrives(
  { params: { limit: ITEM_LIMIT } },
  DrivesListSection
)

const driveListSectionHook = ({ fetchDrives }) => {
  const [offset, setOffset] = useState(0)
  const onChangePage = useCallback(
    nextOffset => {
      fetchDrives({ limit: ITEM_LIMIT, offset: nextOffset })
      setOffset(nextOffset)
    },
    [offset]
  )

  return { offset, onChangePage }
}
