
import { combineReducers } from 'redux'

import omit from 'lodash/object/omit'

import * as constants from './constants'

const byLeadId = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case constants.FETCH:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], loading: true, error: null }
      }

    case constants.FETCH_INTERRUPTED:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], loading: false }
      }

    case constants.FETCH_SUCCESS:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], loading: false, items: payload.result }
      }

    case constants.FETCH_FAILURE:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], loading: false, error: payload }
      }

    case constants.CREATE:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], creating: true, error: null }
      }

    case constants.CREATE_SUCCESS:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], creating: false, items: [...state[meta.leadId].items, payload.result] }
      }

    case constants.CREATE_FAILURE:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], creating: false, error: payload }
      }

    case constants.CREATE_INTERRUPTED:
      return {
        ...state,
        [meta.leadId]: { ...state[meta.leadId], creating: false }
      }

    default: return state
  }
}

const detail = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case constants.SEARCH:
      return {
        ...state,
        [meta.cmaId]: { ...state[meta.cmaId], searching: true }
      }
    
    case constants.SEARCH_INTERRUPTED:
      return {
        ...state,
        [meta.cmaId]: { ...state[meta.cmaId], searching: false }
      }

    case constants.SEARCH_SUCCESS:
    case constants.SEARCH_FAILURE:
      return {
        ...state,
        [meta.cmaId]: { ...state[meta.cmaId], searching: false }
      }

    case constants.TOGGLE:
      return {
        ...state,
        [meta.cmaId]: { ...state[meta.cmaId], savingStates: mergeStates(state[meta.cmaId], meta.states) }
      }

    case constants.TOGGLE_SUCCESS:
    case constants.TOGGLE_FAILURE:
      return {
        ...state,
        [meta.cmaId]: { ...state[meta.cmaId], savingStates: omitState(state[meta.cmaId], meta.states) }
      }

    default: return state
  }
}

function mergeStates (detail, states) {
  var existing = detail ? detail.savingStates : {}
  return { ...existing, ...states }
}

function omitState (detail, states) {
  var existing = detail ? detail.savingStates : {}
  return omit(existing, Object.keys(states))
}

export default combineReducers({
  byLeadId,
  detail
  // leadSources
})
