/* eslint "no-class-assign": "warn" */
import React, { Component } from 'react'
import Select from 'decor/form/Select'
import { connect } from 'react-redux'
import { getDomains } from '../../selectors'

class DomainSelect extends Component {
  render () {
    const { domains, ...rest } = this.props

    return (
      <Select
        options={domains}
        valueKey='id'
        labelKey='id'
        isLoading={!domains}
        simpleValue
        promptTextCreator={v => `Non-Propelio domain: ${v}`}
        {...rest}
      />
    )
  }
}

DomainSelect = connect(

  state => ({
    domains: getDomains(state)
  })

)(DomainSelect)

DomainSelect.propTypes = {}
DomainSelect.defaultProps = {}

export default DomainSelect
