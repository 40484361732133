import React, { FC, useState, useCallback, useMemo } from 'react'
import { SyncedPropertyMarker } from 'common/Map'
import AddressPreviewDialog from 'propertypreviews/components/AddressPreviewDialog'
import { GoogleMap } from 'types'
import ensureOwnerType from 'common/util/ensureOwnerType'
import { ensureNumberOrUndefined } from 'common/util/ensureNumber'

const DrivesMapPin: FC<{
  map: GoogleMap
  lat: number
  lng: number
  id: string
  line1: string
  city: string
  state: string
  zip: string
  ownerType?: unknown
  isVacant?: unknown
  isForeclosure?: unknown
  loanToValue?: unknown
}> = ({
  map,
  lat,
  lng,
  id,
  line1,
  city,
  state,
  zip,
  ownerType,
  isVacant,
  isForeclosure,
  loanToValue
}) => {
  const address = useMemo(() =>
    ({ line1, city, state, zip }),
  [line1, city, state, zip])
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <>
      <AddressPreviewDialog
        address={address}
        isOpen={isOpen}
        onRequestClose={handleClose}
      />
      <SyncedPropertyMarker
        map={map}
        groupId='d4d'
        lat={lat}
        lng={lng}
        id={id}
        ownerType={ensureOwnerType(ownerType)}
        isVacant={!!isVacant}
        isForeclosure={!!isForeclosure}
        loanToValue={ensureNumberOrUndefined(loanToValue)}
        onClick={handleClick}
      />
    </>
  )
}

export default DrivesMapPin
