/* eslint "react/no-deprecated": "warn", "react/jsx-key": "warn", "react/jsx-no-target-blank": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import BigLoader from 'common/BigLoader'
import Dialog from 'decor/Dialog'
import FontIcon from 'decor/FontIcon'
import { Label, Field } from 'decor/form'
import Button from 'decor/Button'

import { fetchAccount } from '../../actions'
import { getDomainAccount } from '../../selectors'

class DomainConsoleDialog extends Component {
  componentWillReceiveProps (nextProps) {
    if (!this.props.open && nextProps.open)
      this.props.fetchAccount()
  }

  render () {
    const { account, ...rest } = this.props

    return (
      <Dialog
        {...rest}
        toolbar={[<Button onClick={this.props.onRequestClose} appearance='secondary'>Close</Button>]}
      >
        {!account ? <BigLoader /> : <AccountInfo account={account} />}

      </Dialog>
    )
  }
}

const AccountInfo = ({ account }) => (
  <div>
    <div className='alert alert-warning'>
      <p><strong>Warning:</strong> this is an advanced features and not recommended unless you have advanced knowledge of DNS records and domain management.</p>
      <p>If you reset your domain console password, the password below is not updated and you will be responsible for maintaining it.</p>
    </div>

    <Field>
      <Label>Link</Label>
      <div>
        <a href={account.loginUrl} target='_blank'><FontIcon className='fa fa-external-link' /> Login Page</a>
      </div>
    </Field>
    <Field>
      <Label>Username</Label>
      <div>{account.shopperId}</div>
    </Field>
    <Field>
      <Label>Password</Label>
      <div>{account.password}</div>
    </Field>
  </div>
)

DomainConsoleDialog.propTypes = {}
DomainConsoleDialog.defaultProps = {}

DomainConsoleDialog = connect(
  state => ({
    account: getDomainAccount(state)
  }),
  {
    fetchAccount
  }
)(DomainConsoleDialog)

export default DomainConsoleDialog
