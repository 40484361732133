/* eslint "react/display-name": "warn" */
import React, { useRef, useMemo } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TextTableCell,
  Text
} from '@realsoftworks/decor'

import { DateTime, Numeric } from 'common/format'
import { useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'
import { useLogError } from 'common/util/hooks/useLogError'

const NeighborhoodTable = React.memo(({ neighbors }) => {
  const scrollParentRef = useRef()

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Box mb='4'>
        <Text fontSize='2'>
          {`  ${neighbors.length}  `}
        </Text>
        <Text fontSize='2' color='neutral.500'>
                    results
        </Text>
      </Box>
      <Table
        ref={scrollParentRef}
        width='100%'
        maxHeight='70vh'
        style={{ overflow: 'auto' }}
        css='font-size: 14px; position: relative;'
      >
        <TableHead width='720px'>
          <TableHeaderCell flex='15'>ADDRESS</TableHeaderCell>
          <TableHeaderCell flex='12'>OWNER</TableHeaderCell>
          <TableHeaderCell flex='16'>STATS</TableHeaderCell>
          <TableHeaderCell flex='10'>LAST SALE</TableHeaderCell>
        </TableHead>
        <TableBody width='720px'>
          {neighbors && neighbors.map(record => (
            <NeighborhoodTableRow
              key={record.id}
              record={record}
              scrollParentRef={scrollParentRef}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
})

export default NeighborhoodTable

const NeighborhoodTableRow = ({ record, scrollParentRef }) => {
  useLogError({
    error: 'record.id is unexpectedly falsy in NeighborhoodTableRow',
    shouldLog: !record.id
  }, [!!record.id])

  const { isSyncHovered, hoverRef } = useSyncedIsHoveredWithAutoScroll({
    scrollParentRef,
    memberId: record.id,
    groupId: 'neighbor'
  })
  const cellBg = isSyncHovered ? 'blue.200' : ''
  const address = useMemo(() =>
    ({
      line1: record.propertyAddressLine1,
      city: record.propertyAddressCity,
      state: record.propertyAddressState,
      zip: record.propertyAddressZip
    }),
  [
    record.propertyAddressLine1,
    record.propertyAddressCity,
    record.propertyAddressState,
    record.propertyAddressZip
  ])

  return (
    <Box ref={hoverRef}>
      <TableRow>
        <TextTableCell flex='15' bg={cellBg}>
          <AddressPreviewLink address={address} />
        </TextTableCell>
        <TextTableCell flex='12' bg={cellBg}>
          {record.name}
        </TextTableCell>
        <TextTableCell flex='16' bg={cellBg}>
          {record.beds || '--'} / {record.bathsFull || '--'} / {record.garageSpaces || '--'}<br />
          <Box>
            <Text color='secondary'> sqft: </Text><Text> <Numeric blank='--'>{record.sqft}</Numeric> </Text>
          </Box>
        </TextTableCell>
        <TextTableCell flex='10' bg={cellBg}>
                    ${record.lastSalePrice ? <Numeric blank='--'>{record.lastSalePrice}</Numeric> : '--'} <br />
          {record.lastSaleDate ? <DateTime format='MM/DD/YYYY'>{record.lastSaleDate}</DateTime> : '--'}
        </TextTableCell>
      </TableRow>
    </Box>
  )
}
