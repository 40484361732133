/* eslint "no-class-assign": "warn" */
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import themeGet from '@styled-system/theme-get'
import { Button, Tooltip } from '@realsoftworks/decor'
import { selectors as billingSelectors } from 'billing'

const TrialNoticeButton = styled(Button)`
  color: white;
  background-color: ${themeGet('colors.red.500')};
  border-color: ${themeGet('colors.red.500')};

  &:hover, &:active {
    background-color: ${themeGet('colors.red.400')};
    border-color: ${themeGet('colors.red.400')};
    color: white;
  }

  &:visited {
    color: white;
  }
`

class TrialNotice extends React.Component {
  render () {
    const { subscription, route, isTrialing, ...props } = this.props
    const days = moment(subscription.trial_end_date).diff(moment(), 'days')

    if (!isTrialing) return null

    return (
      <Tooltip content='To keep your account active, enter your billing info'>
        <span style={{ display: 'inline-block' }}>
          <TrialNoticeButton variant='custom' as={route ? Link : 'a'} to='/billing' href='/billing' {...props}>
            <FontAwesomeIcon icon={faExclamationTriangle}/> Trial ends: {days} {days > 1 ? 'days' : 'day'}
          </TrialNoticeButton>
        </span>
      </Tooltip>
    )
  }
}

TrialNotice = connect(state => ({
  subscription: billingSelectors.getSubscription(state),
  isTrialing: billingSelectors.isTrialing(state)
}))(TrialNotice)

export default TrialNotice
