import { FETCH_PROPERTY_DATA_SUCCESS, FETCH_PROPERTY_DATA } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROPERTY_DATA_SUCCESS:
      return {
        result: action.payload,
        loading: false
      }
    case FETCH_PROPERTY_DATA:
      return {
        loading: true
      }
  }
  return state
}
