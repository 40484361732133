import { connect } from 'react-redux'
import { getSetting } from 'users/selectors'

const OnboardingComponent = ({ shown, children }) => {
  if (!shown)
    return null

  return children
}

const WrappedOnboardingComponent = connect(
  (state, ownProps) => ({
    shown: !getSetting(state, ownProps.setting)
  })
)(OnboardingComponent)

WrappedOnboardingComponent.defaultProps = {
  value: true
}

export default WrappedOnboardingComponent
