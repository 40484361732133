/* eslint "camelcase": "warn", "eqeqeq": "warn" */
import BasicStore from '../core/BasicStore.js'
import Dispatcher from '../core/Dispatcher.js'
import ListStore from '../core/liststore.js'
import ActivityConstants from './ActivityConstants.js'
import Model from '../core/model.js'

const UPDATE_COMPLETE = 'lead_update_complete'

var list = new ListStore()

var type = null
var id = null
var limit = 3 // TODO
var more = false

var maxId = 0
var minId = null

function loadData (data) {
  list.addAll(data)
}

function load (max, min, prepend, callback) {
  if (!max && !min && !prepend && !callback)
    callback = checkMore

  Model('activitygroup').list({ type: type, id: id, minId: min, maxId: max }).then(function (data) {
    onLoad(data.result.models || data.result, prepend)
    if (callback)
      callback(data.result.models)

    STORE.emitChange()
  })
}

function update () {
  load(maxId, null, true)
}

function loadOlder () {
  load(null, minId, false, checkMore)
}

function checkMore (data) {
  more = data.length > limit
}

function onLoad (data, prePend) {
  if (!prePend)
    loadData(data)
  else
    list.insertFirst(data)

  updateIds(data)
}

function updateIds (data) {
  if (!Array.isArray(data))
    data = [data]

  data.forEach(function (d) {
    var id = d.id
    if (id > maxId)
      maxId = id
    else
    if (!minId || id < minId)
      minId = id
  })
}

function comment (payload) {
  var model = payload.model
  Model('activity').create(model).then(function (data) {
    var model = data.result
    updateIds(model)
    list.insertFirst(model)
    STORE.emitChange()
  })
}

var ActivityStore = BasicStore.create({

  initialize: function (cfg) {
    this.bindEvent(ActivityConstants.COMMENT, comment)
    this.bindEvent(ActivityConstants.LOAD_PREVIOUS, loadOlder)
  },

  get: function (id) {
    return list.get(id)
  },

  getAll: function () {
    return list.getAll()
  },

  loadData: function (entity_type, entity_id) {
    type = entity_type
    id = entity_id

    this.bindToType(type)

    list.removeAll()

    load()
  },

  bindToType: function (t) {
    if (t == 'lead')
      this.bindEvent(UPDATE_COMPLETE, update)
  },

  isMore: function () {
    return more
  }
})

var STORE = new ActivityStore()
STORE.register(Dispatcher)

export default STORE
