import React from 'react'
import { Box, Heading, Text, Button } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'
import { Numeric } from 'common/format'

const Toolbar = ({ count, onSaveClick, onChangeMode, ...props }) => (
  <Box {...props} display='flex' alignItems='center'>
    <Heading size={4}>
      <Numeric>{count}</Numeric>
      {' '}<Text color='secondary' fontSize='inherit'>Result{count > 1 ? 's' : ''}</Text>
    </Heading>
    <Button sizeVariant='large' variant='secondary' ml={4} onClick={() => { onChangeMode('criteria') }}>
      <FontIcon icon='cog' mr={3} />
          Edit Criteria
    </Button>
    <Box flex='1' />
    <Button sizeVariant='large' variant='primary' onClick={onSaveClick}>Save List</Button>
  </Box>
)

export default Toolbar
