import React from 'react'
import styled from 'styled-components'
import { arrayOf, number, object } from 'prop-types'
import { DRIVE } from 'drivingfordollars/propTypes'
import { Box } from '@realsoftworks/decor'
import MapboxReact from 'common/Map/components/MapboxReact'

const DrivesMap = ({
  drive,
  drives: passedDrives,
  leads,

  // @param {number[]} borderRadius -
  //  [{upper-right radius}, {lower-right}, {lower-left}, {upper-left}]
  borderRadius,
  isDrivingForDollarsDashboard,
  isDriveDetails
}) => {
  const drives = (drive && [drive]) || passedDrives || []
  const [popoverTarget, setPopoverTarget] = React.useState(null)

  let lat

  if (popoverTarget && !lat)
    setPopoverTarget(null)

  const drivesData = isDrivingForDollarsDashboard ? drives[0] : drives

  const mappedDriveCoordinates = drivesData
    .filter(drive => drive?.points?.length > 0)
    .map(drive => ({
      ...drive,
      points: drive.points.map(coordinates => ({ lat: coordinates[0], lng: coordinates[1] }))
    }))

  return (
    <ConfigurableBox borderRadius={borderRadius} height={340}>
      <MapboxReact
        isDrivingForDollars={true}
        isDrivingForDollarsDashboard={isDrivingForDollarsDashboard}
        multipleResults={leads || []}
        isDriveDetails={isDriveDetails}
        drivingForDollarsDrives={mappedDriveCoordinates}
      >
      </MapboxReact>
    </ConfigurableBox>
  )
}

DrivesMap.defaultProps = {
  borderRadius: [0, 0, 0, 0]
}

DrivesMap.propTypes = {
  drive: DRIVE,
  drives: arrayOf(DRIVE),
  borderRadius: arrayOf(number),
  leads: arrayOf(object)
}

export default DrivesMap

const ConfigurableBox = styled(Box)`
  position: relative;

  & > div > div {
    border-top-right-radius: ${p => p.borderRadius[0]}px;
  }

  & > div > div {
    border-bottom-right-radius: ${p => p.borderRadius[1]}px;
  }

  & > div > div {
    border-bottom-left-radius: ${p => p.borderRadius[2]}px;
  }

  & > div > div {
    border-top-left-radius: ${p => p.borderRadius[3]}px;
  }
`
