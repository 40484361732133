import { FETCH_PROPERTY_TYPES, FETCH_PROPERTY_TYPES_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROPERTY_TYPES:
      return {
        loading: true
      }
    case FETCH_PROPERTY_TYPES_SUCCESS:
      return {
        loading: false,
        types: action.payload
      }
  }
  return state
}
