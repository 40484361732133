/* eslint "camelcase": "warn" */
import union from 'lodash/array/union'
import { CREATE_SUCCESS, SEARCH_SUCCESS } from '../actions'

function pushToArray (newId, reference, byReferenceIds) {
  let taskIds = byReferenceIds[reference.id]
  if (!taskIds)
    taskIds = [newId]
  else
    taskIds = union(taskIds, [newId])

  byReferenceIds[reference.id] = taskIds
  return byReferenceIds
}

function updateReferenceType (state, action) {
  switch (action.type) {
    case SEARCH_SUCCESS:
      if (action.payload && action.payload.result && action.payload.result.result && action.payload.result.result.models && action.payload.entities) {
        let byReferenceIds = { ...state }
        const tasks = action.payload.result.result.models
        tasks.forEach(taskId => {
          const reference = action.payload.entities.tasks[taskId].values.reference
          if (reference)
            byReferenceIds = pushToArray(taskId, reference, byReferenceIds)
        })
        return byReferenceIds
      }
      return []
    case CREATE_SUCCESS:
      if (action.payload && action.payload.result && action.payload.result.result) {
        let byReferenceIds = { ...state }
        const newTaskId = action.payload.result.result
        const reference = action.payload.entities.tasks[newTaskId].values.reference
        if (reference)
          byReferenceIds = pushToArray(newTaskId, reference, byReferenceIds)

        return byReferenceIds
      }
      return []
  }
  return []
}

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
    case CREATE_SUCCESS:
      if (action.payload) {
        const reference_type = 'lead' // TODO get from action.payload.entities[id].values.reference
        return {
          ...state,
          [reference_type]: updateReferenceType(state[reference_type], action)
        }
      }
      return state
  }
  return state
}
