import React from 'react'
import withSideEffect from 'react-side-effect'

class BodyScrollLock extends React.Component {
  render () {
    return null
  }
}

function reducePropsToState (propsList) {
  return propsList.length
}

let originalOverflow = null
let hasLocked = false

function handleStateChangeOnClient (state) {
  const body = document.getElementsByTagName('body')[0]

  if (state > 0) {
    if (!hasLocked)
      originalOverflow = body.style.overflow

    body.style.overflow = 'hidden'
    hasLocked = true
  } else {
    hasLocked = false
    body.style.overflow = originalOverflow || ''
  }
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(BodyScrollLock)
