import React from 'react'
import { Box, Heading, Text, Button } from '@realsoftworks/decor'
import SelectState from './SelectState'
import DeleteBtnOrStatus from './DeleteBtnOrStatus'
import { orDash } from 'common/util/renderHelpers'
import totalBaths from 'common/util/totalBaths'
import { Numeric } from 'common/format'

const PropertyTabsDetails = ({
  property,
  openTasksTab
}) => {
  const addr = property?.address || {}
  const summary = property?.summary || {}
  const buildingDetail = property?.buildingDetail || {}

  return (
    <Box
      pb={[0, 1]}
      pl={[0, 0, '20px']}
      width='100%'
      display='flex'
      className='print-noflex'
      alignItems='center'
      flexShrink={0}
      flexWrap={['wrap', null, null, null, 'nowrap']}
    >
      {addr && (
        <Heading
          as='h1'
          p={1}
          flexShrink={1}
        >
          <Text display='block' width='100%' fontSize={0}>
            {/* Line 1 */}
            {addr.line1}

            <br />

            <Gray>
              {/* City, State, Zip */}
              {addr.city},
              {' '}{addr.state}
              {' '}{addr.zip}

              <br />

              {/* Subdivision / County */}
              {' '}{[addr.subdivision, addr.county + ' County'].filter(Boolean).join(', ')}

              {/* Legal Description */}
              {addr.legalDescription && (<br />)}
              {addr.legalDescription}
            </Gray>

            <br />

            {/* Beds, Baths, Garage */}
            {orDash(summary.beds)}&nbsp;<Gray>beds</Gray>
            {' '}/{' '}
            {orDash(totalBaths(summary.baths))}&nbsp;<Gray>baths</Gray>
            {' '}/{' '}
            {orDash(buildingDetail.garageSpaces)}&nbsp;<Gray>garage</Gray>
            {' '}/{' '}
            {orDash(summary.sqft, v => <Numeric>{v}</Numeric>)}&nbsp;<Gray>sqft</Gray>
            {' '}/{' '}
            {orDash(summary.yearBuilt)}&nbsp;<Gray>build</Gray>
          </Text>
        </Heading>
      )}

      <Box
        flexGrow={1}
        display='flex'
        className='print-noflex'
        alignItems='center'
        flexWrap={['wrap', null, null, null, 'nowrap']}
      >
        <Box
          p={[1, null, null, null, 2]}
          display='flex'
          flexGrow={1}
          justifyContent={['flex-end', null, null, null, 'center']}
          className='print-hide'
          flexShrink={1}
        >
          <SelectState />
        </Box>

        <Box
          p={[1, null, null, null, 2]}
          pt={['21px', null, null, null, 2]}
          className='print-hide'
        >
          <Button
            variant='secondary'
            sizeVariant='small'
            onClick={openTasksTab}
          >
            Tasks
          </Button>
        </Box>

        <Box
          p={[0, null, null, null, 1]}
          pt={['21px', null, null, null, 1]}
          className='print-hide'
        >
          <DeleteBtnOrStatus />
        </Box>
      </Box>
    </Box>
  )
}

export default PropertyTabsDetails

const Gray = p => <Text color='secondary' fontSize={0} {...p} />
