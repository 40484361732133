import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Box } from '@realsoftworks/decor'

const Notice = styled(Box)`
  @media print {
    display: none;
  }

  padding: 12px;
  background-color: ${themeGet('colors.teal.100')};
  color: ${themeGet('colors.teal.900')};
  text-align: center;
  font-weight: bold;
`

export default Notice
