/* eslint "react/no-deprecated": "warn", "react/no-string-refs": "warn", "eqeqeq": "warn", "no-class-assign": "warn" */
import TaskForm from './tasks/TaskForm'
import TaskList from './tasks/TaskList'
import qs from 'qs'
import { selectors as taskSelectors } from '../../../modules/tasks'
import { connect } from 'react-redux'
import { create, searchByShow } from '../actions'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

// https://github.com/ReactTraining/react-router/issues/6268#issuecomment-408450350
export const PathNavLink = props => {
  const compare = (match, location) => (props.default && !location.search) || `${location.pathname}${location.search}` === props.to
  return <NavLink isActive={compare} {...props} />
}

class TasksHandler extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentUserId: PropTypes.string,
    show: PropTypes.string,
    tasks: PropTypes.array
  };

  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
  }

  componentWillMount () {
    this.search()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location.search !== this.props.location.search)
      this.search()
  }

  search () {
    const { search } = this.props.location
    let show = 'my'
    if (search.length > 0)
      ({ show } = qs.parse(search.substr(1)))

    this.props.dispatch(searchByShow(show))
  }

  onSubmit = v => {
    this.props.dispatch(create(v)).then(() => this.search())
  }

  render () {
    const { show, match } = this.props

    return (
      <div>
        <div id='tasks'>
          <div className='ct'>
            <ul className='nav nav-pills settings-nav'>
              <li className={show === 'my' ? 'active' : ''}>
                <PathNavLink to={`${match.path}?show=my`} default>My Tasks</PathNavLink>
              </li>
              <li className={show === 'delegated' ? 'active' : ''}>
                <PathNavLink to={`${match.path}?show=delegated`}>Delegated Tasks</PathNavLink>
              </li>
              <li className={show === 'completed' ? 'active' : ''}>
                <PathNavLink to={`${match.path}?show=completed`}>Completed Tasks</PathNavLink>
              </li>
            </ul>
            <hr/>
            <div id='task-list'>
              <TaskForm ref='form' collapse={true} onSubmit={this.onSubmit}/>
              <TaskList dispatch={this.props.dispatch} details={true} tasks={this.props.tasks} editable={true}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  let { search } = location
  if (search.indexOf('?') == 0)
    search = search.substr(1)

  const query = qs.parse(search)
  const show = query.show || 'my'
  const currentUserId = state.users.current.id
  const tasks = taskSelectors.getTasks(state, show)
  return {
    currentUserId: currentUserId,
    tasks: tasks
  }
}

TasksHandler = connect(mapStateToProps, null)(TasksHandler)
export default TasksHandler
