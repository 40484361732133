import React, { useState } from 'react'

import styled from 'styled-components'
import { Box, Heading } from '@realsoftworks/decor'

import FontIcon from 'common/FontIcon'
import Collapsable from '../Collapsable'

import FilterSectionContext from './FilterSectionContext'

const FilterSectionIcon = styled(Box)`
  display: inline-block;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: rotate(0deg);

  ${props => props.open ? 'transform: rotate(90deg);' : ''}
`

const FilterSection = ({ title, labelWidth, children }) => {
  const [open, setOpen] = useState(true)

  return (
    <Box>
      <Heading mb={2} size={6} onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <FilterSectionIcon open={open}><FontIcon icon='angle-right' /></FilterSectionIcon> <Box display='inline-block' ml={2}>{title}</Box>
      </Heading>
      <Collapsable pl={2} open={open}>
        <FilterSectionContext.Provider value={{ labelWidth }}>
          {children}
        </FilterSectionContext.Provider>
      </Collapsable>
    </Box>
  )
}

export default FilterSection
