import React from 'react'

class CMAListField extends React.Component {
  render () {
    var { label, params, values } = this.props
    return (
      <div className='form-group'>
        <label className='control-label'>{label}</label>
        <div className='field'>
          <select className='form-control' value={params[this.props.name] || null} onChange={this.onChange.bind(this)}>
            <option value=''>Any</option>
            {values.map(v => <option key={v} value={v}>{v}</option>)}
          </select>
        </div>
      </div>
    )
  }

  onChange (e) {
    var v = e.target ? e.target.value : ''
    if (v === '') v = null
    this.props.onChange(this.props.name, v)
  }
}

export default CMAListField
