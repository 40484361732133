/* eslint "react/display-name": "warn" */
import React, { useMemo, useCallback, memo, useState } from 'react'
import { Tabs, Tab } from '../../common/tabs'
import { getCategoryList, getIsCategoryOverListLimit, getCompStatusGroupWithState, getCompStatusGroupColumns, getCompStateViaLeadId } from '../../../modules/cma/selectors'
import { connect } from 'react-redux'
import classnames from 'classnames'
import styled from 'styled-components'
import { Box, TableFoot, TableCell, TableRow, Text } from '@realsoftworks/decor'
import ProTable from 'decor/ProTable'
import StateButtons from './StateButtons.react'
import OldCmaNotice from 'cma/views/OldCmaNotice'

/**
 * CompsCategoryGroups
 */
const connectCompCategoryGroups = connect((state, { leadId }) => ({
  hasLeases: !!getCategoryList(state, leadId, 'leases')
}))

const areCompsCategoryGroupsPropsEqual = (prev, next) =>
  prev.hasLeases === next.hasLeases

const CompsCategoryGroups = connectCompCategoryGroups(memo(({
  hasLeases,
  leadId,
  onTabChange,
  onCompClick,
  onCompStateChange,
  rerunCma
}) => {
  const compStatusGroupsProps = {
    leadId,
    onCompClick,
    onCompStateChange
  }

  return (
    <Tabs onChange={onTabChange}>
      <Tab label='Sales'>
        <CompsStatusGroups
          category='sales'
          rerunCma={rerunCma}
          {...compStatusGroupsProps}
        />
      </Tab>
      <Tab label='Rentals'>
        {hasLeases
          ? <CompsStatusGroups
            category='leases'
            rerunCma={rerunCma}
            {...compStatusGroupsProps}
          />
          : <NotSupportedMessage />}
      </Tab>
    </Tabs>
  )
}, areCompsCategoryGroupsPropsEqual))

export default CompsCategoryGroups

/*
 * CompsStatusGroups
 */
const connectCompsStatusGroups = connect((state, { leadId, category }) => {
  const list = getCategoryList(state, leadId, category)
  return {
    isOverLimit: getIsCategoryOverListLimit(state, leadId, category),
    isEmpty: !list || !list.length
  }
})

const SOLD_STATUSES = 'sold'
const ON_MARKET_STATUSES = ['active', 'pending']
const ON_MARKET_EXCLUDED_COLUMNS = ['close_date']
const OFF_MARKET_STATUSES = ['expired', 'cancelled', 'withdrawn']
const OFF_MARKET_EXCLUDED_COLUMNS = ['close_date']

const CompsStatusGroups = connectCompsStatusGroups(memo(({
  rerunCma,
  isOverLimit,
  isEmpty,
  category,
  leadId,
  onCompClick,
  onCompStateChange
}) => {
  if (isEmpty) return (
    <>
      <OldCmaNotice leadId={leadId} mx={-2} mt='-20px' />
      <EmptyListMessage />
    </>
  )

  const compStatusGroupProps = {
    leadId: leadId,
    category: category,
    onCompClick,
    onCompStateChange
  }

  return (
    <>
      <OldCmaNotice leadId={leadId} rerunCma={rerunCma} mx={-2} mt='-20px' />

      {isOverLimit && <OverLimitMessage />}

      <CompsStatusGroup
        title='Sold'
        statuses={SOLD_STATUSES}
        {...compStatusGroupProps}
      />

      <CompsStatusGroup
        title='On-Market'
        statuses={ON_MARKET_STATUSES}
        excludedColumns={ON_MARKET_EXCLUDED_COLUMNS}
        {...compStatusGroupProps}
      />
    </>
  )
}))

/*
 * CompsStatusGroup
 */
const connectCompsStatusGroup = connect((state, props) => ({
  comps: getCompStatusGroupWithState(
    state,
    props.leadId,
    props.category,
    props.statuses
  )
}))

const serializeComps = comps =>
  comps ? comps.map(c => c.key + c.state).join(',') : ''

const useSerializeComps = comps =>
  useMemo(() => serializeComps(comps), [comps])

const CompsStatusGroup = connectCompsStatusGroup(memo(({
  title,
  comps,
  leadId,
  excludedColumns,
  onCompClick,
  onCompStateChange
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isExcludedVisible, setIsExcludedVisible] = useState(false)
  const isEmpty = !comps || !comps.length
  const includedComps = useMemo(() =>
    comps.filter(c => c.state !== 'excluded')
  , [comps])

  const excludedComps = useMemo(() =>
    comps.filter(c => c.state === 'excluded')
  , [comps])

  const includedCompsSerialized = useSerializeComps(includedComps)
  const excludedCompsSerialized = useSerializeComps(excludedComps)

  const compListProps = {
    onCompClick,
    onCompStateChange,
    excludedColumns,
    leadId: leadId
  }

  const compListJsxMemoed = useMemo(() =>
    includedComps.length > 0 && (
      <CompsList
        shouldShowFooter
        comps={includedComps}
        {...compListProps}
      />
    ),
  [includedCompsSerialized])

  const excludedListJsxMemoed = useMemo(() =>
    excludedComps.length > 0 && (
      <div className='hidden-comps'>
        <h5>
          <a
            onClick={e => {
              e.preventDefault()
              setIsExcludedVisible(v => !v)
            }}
            style={{ cursor: 'pointer' }}
          >
            {isExcludedVisible ? 'Hide Excluded' : 'Show Excluded'}
          </a>
        </h5>

        {isExcludedVisible && (
          <CompsList comps={excludedComps} {...compListProps} />
        )}
      </div>
    ),
  [excludedCompsSerialized, isExcludedVisible])

  return (
    <div
      className={classnames(
        'comp-category',
        isOpen && 'open',
        isEmpty && 'empty'
      )}
    >

      <div className='comp-category-title-bar'>
        <i className='comp-category-toggle' onClick={() => setIsOpen(v => !v)}/>
        <h3>
          {title}
          {' '}
          <small>
            ({includedComps.length + excludedComps.length} properties)
          </small>
        </h3>
      </div>

      {
        isOpen && (
          <div>
            {compListJsxMemoed}
            {excludedListJsxMemoed}
          </div>
        )
      }
    </div>
  )
}))

const connectCompsListProps = connect((state, { leadId }) => ({
  columns: getCompStatusGroupColumns(state, leadId)
}))

const areCompsListPropsEqual = (prev, next) =>
  serializeComps(prev.comps) === serializeComps(next.comps)

const CompsList = connectCompsListProps(memo(({
  shouldShowFooter,
  leadId,
  comps,
  columns,
  excludedColumns,
  onCompClick,
  onCompStateChange
}) => {
  const overriddenColumns = useMemo(() => {
    const colsWithModifiedAddress = columns.map(c =>
      c.id === 'address'
        ? {
          ...c,
          render: (v, listing) =>
            <CompLink listing={listing} onClick={onCompClick}>{v}</CompLink>
        }
        : c)

    const colsWithStateCol = [
      {
        id: 'states',
        header: 'Include in ARV',
        className: 'states states-column print-hide',
        headerClassName: 'states states-column',
        render: (_v, row) =>
          <CompStates
            leadId={leadId}
            listing={row}
            onChange={onCompStateChange}
          />,
        props: {
          width: '110px',
          flex: 'none',
          css: `
            @media print {
              width: 30px;
            }
          `
        }
      },
      ...colsWithModifiedAddress
    ]

    const filteredCols = colsWithStateCol.filter(c =>
      !excludedColumns || !excludedColumns.includes(c.id))

    return filteredCols
  }, [columns, onCompClick, excludedColumns])

  const isThereAnyComps = comps.length > 0

  return (
    <Wrapper mb={5}>
      <Box overflow='auto' className='print-normalize-container'>
        <Box minWidth='1200px' className='print-normalize-container'>
          <ProTable
            responsive
            spacing='dense'
            columns={overriddenColumns}
            data={comps}
            footer={(shouldShowFooter && isThereAnyComps) ? (
              <CompListFooter
                comps={comps}
                columns={overriddenColumns}
              />
            ) : null}
            getRowClass={useCallback(({ state }) =>
              `print-white-bg ${state === 'included' ? '' : 'print-hide'}`)}
          />
        </Box>
      </Box>
    </Wrapper>
  )
}, areCompsListPropsEqual))

const NotSupportedMessage = memo(() => (
  <div className='empty-msg'>
    <h2 className='text-muted'>Not Supported</h2>
    <p>This MLS does not currently support rentals.</p>
  </div>
))

const OverLimitMessage = memo(() => (
  <div className='cma-over-limit'>
    <p>Your search returned too many results so we had to limit it. We recommend you change the criteria but if you
      want to see the full result, please contact the broker using the information below.</p>
  </div>
))

const EmptyListMessage = memo(() => (
  <div className='empty-msg'>
    <h2 className='text-muted'>No Results</h2>
    <p>Try changing the criteria and searching again. If the property facts are accurate you might try changing the distance or time range.</p>
  </div>
))

const Wrapper = styled(Box)`
  font-size: 14px;

  @media print {
    font-size: 12px;
  }
`

const CompLink = ({ onClick: propsOnClick, listing, ...props }) => {
  const onClick = useCallback(e => {
    e.preventDefault()
    propsOnClick(listing.key || listing.id)
  }, [propsOnClick, listing])

  return (
    <a href='#' onClick={onClick} {...props}/>
  )
}

const connectCompStates = connect((state, { leadId, listing }) => ({
  state: getCompStateViaLeadId(state, leadId, listing.key || listing.id)
}))

const CompStates = connectCompStates(memo(({ onChange: propsOnChange, listing, state }) => {
  const onChange = useCallback((_e, value) => {
    propsOnChange(listing.key || listing.id, value)
  }, [propsOnChange, listing])

  return (
    <StateButtons onChange={onChange} state={state}/>
  )
}))

const FOOTER_COLS = [
  'beds',
  'baths_full',
  'baths_half',
  'year_built',
  'garage',
  'dom',
  'calculated_dom',
  'cdom',
  'lot_size_acres',
  'sqft',
  'list_price',
  'close_price',
  'dollars_per_foot'
]

const COL_FOR_FOOTER_LABEL = 'property_type'

// Remove caption for columns that aren't applicable to min/avg/max row
// like "address" and "status"
const COLS_HIDDEN_ON_PRINT = [
  'address',
  'original_status',
  'address.subdivision'
]

const STATS_INFO = [
  { statKey: 'min', statLabel: 'Min' },
  { statKey: 'max', statLabel: 'Max' },
  { statKey: 'avg', statLabel: 'Avg' }
]

const identity = v => v
const CompListFooter = ({ columns, comps }) => {
  const isAnyCompIncluded = comps.some(c => c.state === 'included')
  const compsForStatsCalc = isAnyCompIncluded
    ? comps.filter(c => c.state === 'included')
    : comps
  const stats = calcStats(compsForStatsCalc)

  return (
    <CompListFooterContainer
      className={classnames(!isAnyCompIncluded && 'print-hide')}
    >
      {STATS_INFO.map(({ statKey, statLabel }) => (
        <TableRow key={statKey}>
          {columns.map((col, i) => {
            const id = col.id || col.accessor
            const render = col.render || identity
            const rawProps = col.props || {}
            const props = COLS_HIDDEN_ON_PRINT.includes(id)
              ? { ...rawProps, caption: '' }
              : rawProps

            const shouldColBeEmpty = id !== 'property_facts' &&
              !FOOTER_COLS.includes(id)
            const isStateBtnsCell = i === 0
            const row = stats[statKey]
            const value = id === 'property_facts' ? row : row[id]

            if (id === COL_FOR_FOOTER_LABEL)
              return (
                <TableCell
                  {...props}
                  flex='0 0 auto'
                  key='label'
                  textAlign='right'
                >
                  <Text fontWeight='bold'>{statLabel}</Text>
                </TableCell>
              )

            if (shouldColBeEmpty)
              return (
                <TableCell
                  {...props}
                  className={classnames(
                    props.className,
                    isStateBtnsCell && 'print-hide'
                  )}
                />
              )

            return (
              <TableCell key={id} {...props}>
                {render(value, row)}
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </CompListFooterContainer>
  )
}

const calcStats = comps => {
  const initStats = { min: {}, max: {}, avg: {}, total: {} }

  // Calc min / max / total
  const statsWithMinMaxTotal = comps.reduce((compsAcc, c) =>
    FOOTER_COLS.reduce((colsAcc, field) => {
      const value = field === 'dollars_per_foot'
        ? calcDollarPerSqft(c)
        : c[field]

      if (value === null || value === undefined)
        return colsAcc

      return {
        ...colsAcc,
        min: {
          ...colsAcc.min,
          [field]: Math.min(
            colsAcc.min[field] === undefined ? Infinity : colsAcc.min[field],
            value
          )
        },
        max: {
          ...colsAcc.max,
          [field]: Math.max(colsAcc.max[field] || null, value)
        },
        total: {
          ...colsAcc.total,
          [field]: (colsAcc.total[field] || 0) + value
        }
      }
    }, compsAcc),
  initStats)

  // Calc avg
  const completeStats = FOOTER_COLS.reduce((colsAcc, field) => {
    const total = statsWithMinMaxTotal.total[field]
    const nonEmptyCount = comps
      .filter(d => {
        const value = field === 'dollars_per_foot' ? calcDollarPerSqft(d) : d[field]
        const isNonEmpty = !!value || value === 0
        return isNonEmpty
      })
      .length

    const avg = total === 0
      ? 0
      : total !== undefined
        ? total / nonEmptyCount
        : null

    if (avg === null) return colsAcc

    return {
      ...colsAcc,
      avg: {
        ...colsAcc.avg,
        [field]: field === 'dollars_per_foot' || field === 'lot_size_acres'
          ? avg
          : Math.round(avg)
      }
    }
  }, statsWithMinMaxTotal)

  return completeStats
}

const CompListFooterContainer = styled(TableFoot)`
  @media print {
    display: block;
  }
`

const calcDollarPerSqft = d => {
  const canCalcUsdPerFoot = typeof d.sqft === 'number' &&
    (typeof d.close_price === 'number' || typeof d.list_price === 'number')

  if (!canCalcUsdPerFoot) return null

  const dollarPerSqft = (d.close_price || d.list_price) / d.sqft

  return dollarPerSqft
}
