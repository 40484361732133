import { combineReducers } from 'redux'

import tags from './tags'
import contactTags from './contactTags'
import contacts from './contacts'
import ordering from './ordering'
import ui from './ui'

export default combineReducers({
  tags,
  contactTags,
  contacts,
  ordering,
  ui
})
