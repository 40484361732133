import React from 'react'
import { Box } from '@realsoftworks/decor'
import Toolbar from '../../ResultToolbar'
import BuyerCard from './BuyerCard'
import EmptyMessage from '../../EmptyMessage'
import PagingToolbar from 'common/PagingToolbar'

export default function BuyerResult ({ data, loading, paging, onSaveClick, counts, onChangePage, onChangeMode }) {
  const onEmptyMessageClick = React.useCallback(() => {
    onChangeMode('criteria')
  }, [])

  return (
    <Box maxWidth={680}>
      {
        (data && data.length) > 0
          ? <Box>
            <Toolbar count={counts.total} onSaveClick={onSaveClick} onChangeMode={onChangeMode} mb={5} />
            {
              data.map(d => (
                <BuyerCard
                  key={d.id}
                  contact={d}
                  mb={24}
                />
              ))
            }
            {paging && <PagingToolbar {...paging} loading={loading} count={counts.total} onChangePage={onChangePage} />}
          </Box>
          : <EmptyMessage onClick={onEmptyMessageClick} />
      }
    </Box>
  )
}
