import React from 'react'

const backgroundImage = 'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)'
const animation = 'progress-bar-stripes 2s linear infinite' // from bootstrap

const Progress = props => (
  <div style={{ backgroundColor: '#e9ecef', height: 20, overflow: 'hidden', borderRadius: 5, fontSize: 12, lineHeight: '20px' }}>
    <div style={{ backgroundColor: '#40c3cb', animation, backgroundImage, backgroundSize: '20px', width: `${props.percent}%`, height: '100%', textAlign: 'center', color: 'white' }}>{props.children}</div>
  </div>
)

export default Progress
