/* eslint "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import * as selectors from '../selectors'

import BigLoader from 'common/BigLoader'
import BigFatalError from 'common/BigFatalError'

import { searchMarkets } from '../actions'
import LeadListPage from './LeadListPage'

class LeadListsIndexHandler extends React.Component {
  state = {
    loading: !this.props.markets
  };

  componentDidMount () {
    this.props.searchMarkets().then(() => this.setState({ loading: false }))
  }

  render () {
    const { loading } = this.state
    const { error } = this.props

    return (
      <BigLoader loading={loading}>
        {error ? <BigFatalError/> : <LeadListPage/>}
      </BigLoader>
    )
  }
}

const mapStateToProps = (state, props) => ({
  markets: selectors.getMarkets(state),
  error: selectors.getMarketError(state)
})
const mapDispatchToProps = {
  searchMarkets
}
LeadListsIndexHandler = connect(mapStateToProps, mapDispatchToProps)(LeadListsIndexHandler)
export default LeadListsIndexHandler
