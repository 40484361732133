import React, { Component } from 'react'
import { CMAMap } from 'cma'
import CompList from '../../../../views/leads/cma/CompList.react.js'
import { Checkbox } from '@realsoftworks/decor'

export default class DealAlertCMA extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showAll: false
    }
  }

  onMarkerClick = listing => {
    this.props.onListingClick(listing.key || listing.id)
  };

  render () {
    const { listings, subject, meta, metadata } = this.props
    const states = {}

    const { showAll } = this.state

    let includedListings = listings

    if (!showAll) {
      var includedIds = meta.reduce((ret, l) => {
        if (l.included) ret.push('' + (l.listing_id || l.id)) // TODO future proof?
        return ret
      }, [])

      includedListings = listings.reduce((ret, l) => {
        if (includedIds.indexOf(l.id) !== -1) ret.push(l)
        return ret
      }, [])
    }

    var subjectProperty = { values: subject } // TODO kludge?

    return (
      <div className='deal-alert-detail-cma'>
        <h2>{subject.address.line1} CMA</h2>
        <div className='section section-map'>
          <CMAMap states={states} comps={includedListings} subject={subjectProperty} onMarkerClick={this.onMarkerClick}/>
        </div>

        <div className='section'>
          <h4>Subject</h4>
          <CompList metadata={metadata} link={false} showStates={false} comps={[subject]}/>

          <h4 className='comps-list-title'>Comparables</h4>
          <div className='table-toolbar top-toolbar'>
            <Checkbox checked={showAll} onChange={(e, checked) => this.setState({ showAll: checked })} label='Show all solds'/>
          </div>
          <CompList metadata={metadata} showStates={false} comps={includedListings} onCompClick={this.props.onListingClick} showSummary={true}/>
        </div>
      </div>
    )
  }
}
