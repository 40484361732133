import React, { forwardRef, useState, useRef, useEffect, useMemo } from 'react'
import { arrayOf, string, func, bool } from 'prop-types'
import { Card, Input, Menu, ListItem, Box, Position, Text, toast } from '@realsoftworks/decor'
import debounce from 'lodash/function/debounce'
import useOnClickOutside from 'common/util/hooks/useOnClickOutside'
import LoadingIcon from 'common/LoadingIcon'
import * as api from 'common/api'
import qs from 'qs'
import Contact from './Contact'
import { GENERIC_ERROR_NOTIF } from 'const'

const ContactInput = forwardRef(({
  value: valueFromProps,
  onChange,
  onFocus = noop,
  onBlur = noop,
  allowMultiple = true,
  name
}, ref) => {
  const {
    inputRef,
    suggestions,
    setQuery,
    query,
    contacts,
    contactIds,
    isLoading,
    isSuggestionsVisible,
    onFocus: onFocusFromLogic,
    onBlur: onBlurFromLogic,
    onSuggestionSelect,
    onSuggestionDelete
  } = useContactInputLogic({ valueFromProps, allowMultiple })

  const { clickAreaRef } = useCustomOnClickOutside({
    onBlur,
    onChange,
    name,
    value: contactIds
  })

  return (
    <Card
      ref={clickAreaRef}
      display='flex'
      flexDirection='column'
      p={contactIds.length ? 3 : 0}
      bg={contactIds.length ? 'white' : 'transparent'}
      boxShadow={contactIds.length ? 2 : 0}
    >
      {/* Suggestion Input */}
      <Input
        ref={el => {
          if (ref && typeof ref === 'object')
            ref.current = el
          else if (typeof ref === 'function')
            ref(el)

          inputRef.current = el
        }}
        placeholder='Type to search contacts'
        value={query}
        onChange={ev => { setQuery(ev.target.value) }}
        onFocus={ev => {
          onFocus(ev)
          onFocusFromLogic()
        }}
        onBlur={onBlurFromLogic}
      />

      {/* Suggestions */}
      <Menu
        target={inputRef.current}
        open={isSuggestionsVisible}
        zIndex={10000}
        position={Position.BOTTOM_LEFT}
        onClose={() => {}}
      >
        {isLoading ? (
          <Box py={3} width={185} display='flex' justifyContent='center'>
            <LoadingIcon size={2} />
          </Box>
        ) : !suggestions.length ? (
          <Box py={3} width={185} display='flex' justifyContent='center'>
            <Text fontSize={0} color='neutral.500'>No Match Found</Text>
          </Box>
        ) : suggestions.map(s => {
          const v = (s && s.values) || {}

          return (
            <ListItem
              minWidth={185}
              key={s.id}
              onClick={ev => { ev.stopPropagation(); ev.preventDefault() }}
              onMouseDown={ev => { ev.stopPropagation(); ev.preventDefault() }}
              onMouseUp={ev => {
                ev.stopPropagation()
                setQuery('')
                onSuggestionSelect(s)
              }}
            >
              {`${v.firstname ? v.firstname : ''} ${v.lastname ? v.lastname : ''}`.trim() ||
                v.name.trim() ||
                `< id: ${s.id} >`}
            </ListItem>
          )
        })}
      </Menu>

      {/* Display */}
      <Contact
        mt={2}
        onDelete={onSuggestionDelete}

        // Contacts from props (whose key is only id) should be excluded from
        // data prop so that Contact would fetch for the missing data instead
        preloadedData={contacts.filter(c => Object.keys(c).length !== 1)}
        isDeletable
      >
        {contactIds}
      </Contact>
    </Card>
  )
})

ContactInput.propTypes = {
  value: arrayOf(string),
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  allowMultipl: bool,
  name: string
}

export default ContactInput

const noop = () => {}

const useCustomOnClickOutside = ({ onBlur, onChange, value, name }) => {
  const onBlurRef = useRef(() => {})
  onBlurRef.current = onBlur

  const clickAreaRef = useOnClickOutside(
    () => {
      const syntheticEvent = { target: { name } }
      onChange(syntheticEvent, value)

      // Why this fancy onBlur stuff? Because if we call onBlur right away, we
      // would be caliing the version of onBlur where our previous onChange call
      // hasn't took effect yet. This would cause required ClickToEdit fields to
      // not exit onClickOutside even though we've just set the value
      setTimeout(() => { onBlurRef.current() }, 0)
    },
    [value]
  )

  return { clickAreaRef }
}

const useContactInputLogic = ({ valueFromProps: passedValueFromProps, allowMultiple }) => {
  const valueFromProps = passedValueFromProps || []
  const debouncedScopeRef = useRef({})
  const inputRef = useRef()
  const [contacts, setContacts] = useState(valueFromProps.map(id => ({ id })))
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [hasFocus, setHasFocus] = useState(false)
  const contactIds = contacts.map(c => c.id)

  debouncedScopeRef.current.query = query
  debouncedScopeRef.current.lastQuery = query
  debouncedScopeRef.current.contactIds = contactIds

  const loadSuggestions = useMemo(() =>
    debounce(async () => {
      const { query } = debouncedScopeRef.current
      if (!query) return

      try {
        const resp = await api.get(`/contacts?${qs.stringify({ param: query })}`)

        if (debouncedScopeRef.current.lastQuery !== query) return

        setIsLoading(false)

        const hasMatch = resp && resp.result && resp.result.models && resp.result.models.length

        const newSuggestions = hasMatch && resp.result.models
          .filter(s => !debouncedScopeRef.current.contactIds.includes(s.id))
          .slice(0, 5)

        if (!newSuggestions || !newSuggestions.length) return

        setSuggestions(newSuggestions)
      } catch (e) {
        if (debouncedScopeRef.current.lastQuery !== query) return
        setIsLoading(false)
        toast.error(GENERIC_ERROR_NOTIF)
      }
    }, 500),
  [])

  useEffect(() => {
    setIsLoading(!!query)
    setSuggestions([])
    loadSuggestions()
  }, [query])

  return {
    inputRef,
    suggestions,
    setQuery,
    query,
    contacts,
    contactIds,
    isLoading,
    isSuggestionsVisible: hasFocus && !!query,
    onFocus: () => { setHasFocus(true) },
    onBlur: () => { setHasFocus(false) },
    onSuggestionSelect: suggestion => {
      setContacts(cs => allowMultiple ? [...cs, suggestion] : [suggestion])
    },
    onSuggestionDelete: id => {
      setContacts(cs => cs.filter(v => v.id !== id))
    }
  }
}
