import React, { useEffect, useContext, useState } from 'react'
import { Box, Pill, Button } from '@realsoftworks/decor'
import { connect } from 'react-redux'
import BigLoader from 'common/BigLoader'
import { SkiptraceContext, IAddress, addressKeyFmt } from 'search/SkiptraceContext'
import SkiptraceResult from './SkiptraceResult'
import StartSkiptrace from './StartSkiptrace'
import addressObjectToString from 'common/util/addressObjectToString'
import { fetchTeamOnceAtATime as fetchTeam } from 'users/actions'
import { getTeamInfo } from 'users/selectors'
import { isComplete } from 'common/util/address'
import TabUnavailable from 'search/components/TabUnavailable'
import SkiptraceDownloadDialog from 'common/SkiptraceDownloadDialog'
import downloadPropertySkiptrace from './downloadPropertySkiptrace'

// @TODO: Implement proper types
const normalizeId = passedId => {
  const id = String(passedId)
  return id.length === 1
    ? id
    : id[0] === '0'
      ? normalizeId(id.substr(1))
      : id
}

const useSkiptraceAddressMapper = (skiptraces: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([] as any)
  const ctx = useContext(SkiptraceContext)

  const stringged = JSON.stringify(skiptraces?.items)
  useEffect(() => {
    setIsLoading(true)
    if (!skiptraces?.items) {
      setData([])
      setIsLoading(false)
      return
    }

    // flatten providers into a single array per skiptrace
    const _skiptraces = skiptraces?.items
      .filter(Boolean)
      .map(skiptrace =>
        ({
          ...skiptrace,
          results: Object.entries(skiptrace.providers).reduce((stack, [key, obj]: any) => {
            if (key === 'reiGroup') {
              const owner = obj?.property?.owner
              const data = {
                provider: key,
                subjectName: owner?.name?.full || [owner?.name?.first, owner?.name?.last].join(' ').trim(),
                addressSearch: [owner?.mailingAddress],
                ...owner
              }
              return owner ? [...stack, data] : stack
            }

            if (Array.isArray(obj))
              return stack.concat(obj.map(item => ({
                ...item,
                provider: key
              })))

            return stack
          }, [] as any[])
        })
    )

    // set isSkipped status for each address
    // @TODO: convert to use normalizr
    const addresses: IAddress[] = []
    _skiptraces.forEach(result => {
      result.results.forEach(res => {
        res.addressSearch.forEach(_addr => {
          addresses.push({
            line1: _addr.address,
            city: _addr.city,
            state: _addr.state,
            zip: _addr.zip
          })
        })
      })
    })

    ctx.getPropertyDetails(addresses).then(results => {
      let idx = 0
      const mapped = _skiptraces.map(skiptrace => ({
        ...skiptrace,
        results: skiptrace.results.map(res => ({
          ...res,
          addressSearch: res.addressSearch.map(addr => ({
            ...addr,
            isSkipped: results[idx++]?.skiptraces?.length > 0 || false
          }))
        }))
      }))

      setData(mapped)
      setIsLoading(false)
    }).catch(() => {
      setData(_skiptraces)
      setIsLoading(false)
    })
  }, [stringged])

  return [isLoading, data]
}

const withState = connect(state => {
  const { value: team, isLoading } = getTeamInfo(state)
  const shouldFetch = !isLoading && (!team || (team.length === 0))

  return { team, shouldFetch }
}, { fetchTeam })

const Skiptrace = ({ skiptraces: _skiptraces, address, contactAddress, hasSkiptraced = false, shouldFetch, fetchTeam, team }) => {
  const [active, setActive] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [skiptraces, setSkiptraces] = useState<Record<string, any>>({})
  const [isAddressCheckLoading, mappedSkiptraces] = useSkiptraceAddressMapper(skiptraces[active])
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)

  useEffect(() => {
    if (!shouldFetch) return

    try {
      fetchTeam()
    } catch (e) {}
  }, [shouldFetch])

  const skiptracesStr = JSON.stringify(_skiptraces)
  useEffect(() => {
    if (!_skiptraces || _skiptraces.length === 0) {
      setIsLoading(false)
      return
    }

    const key = addressKeyFmt(address)
    setSkiptraces({
      [key]: {
        address,
        id: key,
        items: _skiptraces
      }
    })
    setActive(key)

    setIsLoading(false)
  }, [skiptracesStr])

  if (!isComplete(contactAddress))
    return (
      <TabUnavailable>We don’t have enough info on this property to run a skiptrace</TabUnavailable>
    )

  if (!hasSkiptraced || (!isLoading && Object.keys(skiptraces).length === 0)) return <StartSkiptrace address={contactAddress} />

  async function handlePillClick (id: string) {
    setActive(id)
  }

  async function handlePillDelete (id: string) {
    if (active === id) {
      const _skiptrace = Object.values(skiptraces).find(s => s.id !== id)
      setActive(_skiptrace?.id || '')
    }

    const cloned = JSON.parse(JSON.stringify(skiptraces))

    if (cloned[id]) {
      delete cloned[id]
      setSkiptraces(cloned)
    }
  }

  return (
    <Box bg='white' minHeight='100%'>
      <Box display='flex' p={1} alignItems='center'>
        <Box flex='1 1 100%' p={2}>
          {/* Skiptrace Records Pills List */}
          <Box display='flex'>
            {Object.values(skiptraces).map((s, idx) =>
              <Pill
                key={`pill-${idx}`}
                variant={s.id === active ? 'default' : 'transparent'}
                isRounded
                m={1}
                css='text-transform: capitalize; cursor: pointer;'
                onClick={() => handlePillClick(s.id)}
                onDelete={idx === 0 ? null : e => {
                  e.preventDefault()
                  e.stopPropagation()
                  handlePillDelete(s.id)
                }}
              >
                {addressObjectToString(s.address)}
              </Pill>
            )}
          </Box>
        </Box>

        {/* Upper-Right Actions */}
        <Box pr={6} display='flex'>
          <Box px={2}>
            <Button width={140} variant='secondary' onClick={() => setIsDownloadOpen(true)}>Download</Button>

            <SkiptraceDownloadDialog
              isOpen={isDownloadOpen}
              onClose={() => setIsDownloadOpen(false)}
              download={format => downloadPropertySkiptrace({ format, address: contactAddress })}
            />
          </Box>
          <Box px={2}>
            <Button width={140} variant='primary' onClick={() => setIsModalOpen(true)}>Skip Again</Button>
          </Box>
        </Box>
      </Box>

      {isLoading || isAddressCheckLoading
        ? (<Box><BigLoader loading={isLoading || isAddressCheckLoading} /></Box>)
        : mappedSkiptraces.map(_skiptrace => (
          <SkiptraceResult key={_skiptrace.id} skiptrace={_skiptrace} team={team} />
        )
        )}

      {/* Show skiptrace modal */}
      {isModalOpen
        ? <StartSkiptrace address={contactAddress} isModal={true} onClose={() => setIsModalOpen(false)} />
        : null}
    </Box>
  )
}

export default withState(Skiptrace)
