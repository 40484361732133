import get from 'lodash/object/get'
import { getManageState } from '../selectors'
import { createSelector } from 'reselect'

export const getDnsRecordsState = state =>
  get(getManageState(state), 'dnsrecords')

export const getDnsRecordsStatus = (state, key) =>
  get(getDnsRecordsState(state), key ? `status.${key}` : 'status')

export const getDnsRecords = state =>
  get(getDnsRecordsState(state), 'items')

export const getDnsRecordsWithId = createSelector(
  getDnsRecords,
  records =>
    records
      .map(r => ({ ...r, id: JSON.stringify(r) }))
      .map((r, i, arr) => {
        const hasIdenticalRecord = arr
          .filter(r2 => r2.id.includes(r.id))
          .length > 1
        return {
          ...r,
          id: hasIdenticalRecord ? r.id : r.id + i
        }
      }))
