/* eslint "eqeqeq": "warn", "camelcase": "warn" */
import React from 'react'

import LoadingIcon from 'common/LoadingIcon'

import { monetary } from 'common/util/number'

function andify (list) {
  if (!list || list.length == 0)
    return ''

  const count = list.length
  if (count == 1)
    return list[0]
  else if (count == 2)
    return list.join(' and ')
  else
    return list.slice(0, count - 1).concat(['and ' + list[count - 1]]).join(', ')
}

function expressRange ({ min, max } = { min: undefined, max: undefined }, number = true) {
  const hasMin = min !== undefined && min !== null
  const hasMax = max !== undefined && max !== null

  if (hasMin && hasMax)
    return 'between ' + min + ' and ' + max
  else if (hasMax)
    return number ? (max + ' or less') : ('in ' + max + ' or earlier')
  else if (hasMin)
    return number ? (min + ' or more') : ('in ' + min + ' or later')

  return ''
}

function extractRange (criteria, name) {
  var v = criteria[name]
  if (!v)
    v = { min: criteria[name + '_min'], max: criteria[name + '_max'] }

  return v
}

class CriteriaSummary extends React.Component {
  getText () {
    const { criteria } = this.props
    const { property_type, geo } = criteria

    const filterParts = ['All']

    // process property type

    if (criteria.status && criteria.status.length > 0)
      filterParts.push(andify(criteria.status))

    if (property_type) {
      var ptypesPart = Object.keys(property_type).map(category => {
        const type = andify(property_type[category])

        return category + ' which are ' + type
      })

      filterParts.push(andify(ptypesPart))
    } else { filterParts.push('properties') }

    // so far: All [type] properties

    // process geo
    let location = 'across the whole market'
    if (geo) {
      var counties = geo.counties || []
      counties = counties.map(v => typeof v === 'string' ? v : `${v.name} (${v.state})`)
      const countyText = andify(counties)
      if (countyText)
        location = `in ${countyText} ` + (counties > 1 ? 'counties' : 'county')
    }
    filterParts.push(location)

    // process property facts
    const facts = [];
    [
      { name: 'beds', label: 'bedrooms' },
      { name: 'baths_full', label: 'full bathrooms' },
      { name: 'sqft', label: 'square feet' }
    ].forEach(({ name, label }) => {
      var v = extractRange(criteria, name)
      const expressed = expressRange(v)
      if (expressed)
        facts.push((facts.length == 0 ? 'have ' : '') + expressed + ' ' + label)
    })

    const built = expressRange(extractRange(criteria, 'year_built'), false)
    if (built)
      facts.push('were built ' + built)

    if (facts.length > 0)
      filterParts.push('which ', andify(facts))

    const filterText = <p key='filter'>{filterParts.join(' ')}</p>
    const ret = [filterText]

    // list price
    let { min: list_price_min, max: list_price_max } = extractRange(criteria, 'list_price')
    list_price_min = (list_price_min ? monetary(list_price_min, { decimals: 0 }) : list_price_min)
    list_price_max = (list_price_max ? monetary(list_price_max, { decimals: 0 }) : list_price_max)
    const list = expressRange({ min: list_price_min, max: list_price_max })
    if (list)
      ret.push(<p key='listed'>Listed {list}</p>)

    if (criteria.equity_dollars || criteria.equity_percent) {
      const equityPieces = []
      if (criteria.equity_dollars)
        equityPieces.push(monetary(criteria.equity_dollars))

      if (criteria.equity_percent)
        equityPieces.push((criteria.equity_percent * 100).toFixed(0) + '%')

      if (equityPieces.length > 0) {
        const equityText = 'Each property must have at least ' + andify(equityPieces) + ' equity'
        ret.push(<p key='equity'>{equityText}</p>)
      }
    }

    return ret
  }

  render () {
    var { count, loading } = this.props

    return (
      <div>
        {this.getText()}
        {count !== undefined ? <p>{count} Matches {loading ? <LoadingIcon /> : null}</p> : null}
      </div>
    )
  }
}

export default CriteriaSummary
