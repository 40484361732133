import api from 'common/api'
import { TRACK_EVENT } from '../../middleware/analytics'

import * as constants from './constants'
import { isTrialing as getIsTrialing } from 'billing/selectors'
import throwAny from 'common/util/throwAny'

const { CHANGE_PLAN_SUCCESS } = constants
export { CHANGE_PLAN_SUCCESS }

export const fetchBillingInfo = () => async dispatch => {
  dispatch({ type: constants.FETCH_INFO })
  const result = await api.get('/billing')

  if (result.error)
    return dispatch({ type: constants.FETCH_INFO_FAILURE, error: result.error })

  return dispatch({ type: constants.FETCH_INFO_SUCCESS, payload: result })
}

export const fetchPlans = () => async dispatch => {
  dispatch({ type: constants.FETCH_PLANS })
  const result = await api.get('/billing/subscriptions')

  if (result.error)
    return dispatch({ type: constants.FETCH_PLANS_FAILURE, error: result.error })

  return dispatch({ type: constants.FETCH_PLANS_SUCCESS, payload: result })
}

export const changePlan = planId => async dispatch => {
  dispatch({ type: constants.CHANGE_PLAN, meta: { planId } })
  const result = await api.post('/billing/subscriptions', { planId })

  if (result.error)
    return dispatch({ type: constants.CHANGE_PLAN_FAILURE, error: result.error, meta: { planId } })

  const tracking = { name: 'billing.upgraded' }
  return dispatch({ type: constants.CHANGE_PLAN_SUCCESS, payload: result, meta: { planId }, [TRACK_EVENT]: tracking })
}

export const updateBillingInfo = info => async dispatch => {
  dispatch({ type: constants.UPDATE, meta: { info } })
  const result = await api.post('/billing', info)
  if (result.error) {
    dispatch({ type: constants.UPDATE_FAILURE, error: result.error })
    throwAny(result.error)
  }

  const tracking = { name: 'billing.info.updated' }
  return dispatch({ type: constants.UPDATE_SUCCESS, payload: result, [TRACK_EVENT]: tracking })
}

export const endTrial = () => (dispatch, getState) => {
  const isTrialing = getIsTrialing(getState())

  if (!isTrialing) return Promise.reject(new Error('You are currently not in trial.'))

  dispatch({ type: constants.END_TRIAL })

  return api.post('/billing/subscriptions/trialUpgrade')
    .then(resp => {
      dispatch({ type: constants.END_TRIAL_SUCCESS })
      dispatch({ type: constants.FETCH_INFO_SUCCESS, payload: resp })
    })
    .catch(error => {
      dispatch({ type: constants.END_TRIAL_FAILURE })
      throwAny(error)
    })
}

export const cancelPlan = () => async dispatch => {
  dispatch({ type: constants.CANCEL_PLAN })
  const result = await api.destroy('/billing/subscriptions')
  if (result.error)
    return dispatch({ type: constants.CANCEL_PLAN_FAILURE, error: result.error })

  return dispatch({ type: constants.CANCEL_PLAN_SUCCESS, payload: result, [TRACK_EVENT]: { name: 'billing.cancelled' } })
}

export const downgradeTrial = () => async dispatch => {
  const result = await api.post('/billing/downgradetrial')

  if (result.error)
    return

  const tracking = { name: 'billing.downgrade' }
  return dispatch({ type: constants.CHANGE_PLAN_SUCCESS, payload: result, [TRACK_EVENT]: tracking })
}
