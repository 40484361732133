import React, { useMemo } from 'react'
import { Heading, Box, Text, Caption } from '@realsoftworks/decor'
import moment from 'moment'
import { Monetary, Numeric } from 'common/format'
import PropertyPill from 'search/components/PropertyPill'
import SituationsPill from 'leadlists/components/Sellers/components/SituationsPill'
import { isEmpty, isNonEmpty, orNull, orDash } from 'common/util/renderHelpers'
import { useSearchScreenProps } from 'search/SearchContext'
import totalBaths from 'common/util/totalBaths'
import Pill from '@realsoftworks/decor/dist/components/Pill'
import { STATE_OPTS } from 'search/HistoryContext'
import { Link, withRouter, matchPath } from 'react-router-dom'
import getStyledColor from 'common/util/getStyledColor'
import createHistoryEntryUrl from 'search/createHistoryEntryUrl'
import { useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import { useLogError } from 'common/util/hooks/useLogError'

const SearchHistoryItem = withRouter(({ location, scrollParentRef, ...p }) => {
  const { lead: activeLead } = useSearchScreenProps()
  const lead = p.lead || {}
  const leadId = lead.id

  const stateLabel = lead.state !== 'search' &&
    (STATE_OPTS.find(o => o.value === lead.state) || {}).label

  useLogError({
    error: 'leadId is unexpectedly falsy in SearchHistoryItem',
    shouldLog: !leadId
  }, [!!leadId])

  const { isSyncHovered, hoverRef } = useSyncedIsHoveredWithAutoScroll({
    scrollParentRef,
    memberId: leadId,
    groupId: 'search-history'
  })

  const activeLeadId = activeLead && activeLead.id
  const isOnDetailsPage = matchPath(location.pathname, {
    path: '/search/history/:leadId'
  })

  const isDetailsShowing = isOnDetailsPage &&
    lead.id &&
    lead.id === activeLeadId

  const historyItemUrl = useMemo(() =>
    createHistoryEntryUrl(leadId),
  [leadId])

  return (
    <Text
      innerRef={hoverRef}
      p={4}
      as={Link}
      to={historyItemUrl}
      display='block'
      bg={isSyncHovered ? 'blue.200' : isDetailsShowing ? 'yellow.200' : 'white'}
      css={`
        &, &:hover, &:active,  &:focus, &:visited {
          text-decoration: none;
          color: ${getStyledColor('text')};
        }
      `}
    >
      {/* Property Address */}
      <Box
        m={-1}
        display='flex'
        flexGrow={1}
        alignItems='center'
        justifyContent='flex-end'
      >
        {!p.hasPropertyDataInDb && (
          <Box p={1}>
            <Pill variant='warn' size='small' css='font-size: 14px'>
              User Created
            </Pill>
          </Box>
        )}

        {stateLabel && (
          <Box p={1}>
            <Pill variant='secondary' size='small' css='font-size: 14px'>
              {stateLabel}
            </Pill>
          </Box>
        )}

        {lead.status === 'archived' && (
          <Box p={1}>
            <Pill variant='warn' size='small' css='font-size: 14px'>
              Archived
            </Pill>
          </Box>
        )}

        {lead.status === 'deleted' && (
          <Box p={1}>
            <Pill variant='danger' size='small' css='font-size: 14px'>
              Deleted
            </Pill>
          </Box>
        )}

        {p.hasDueTask && (
          <Box p={1}>
            <Pill variant='danger' size='small' css='font-size: 14px'>
              Task Due
            </Pill>
          </Box>
        )}
      </Box>

      <Box m={-1} display='flex' flexWrap='wrap' alignItems='baseline'>
        <Heading p={1} as='h2' size={6}>{p.propertyAddressLine1}</Heading>
        <Gray p={1}>
          {p.propertyAddressCity}, {p.propertyAddressState} {p.propertyAddressZip}
        </Gray>
      </Box>

      <Box m={-2} mt={-2} display='flex' flexWrap='wrap'>
        {/* Beds or Baths */}
        <Small p={2}>
          {orDash(p.beds)} <Gray>beds</Gray>
          {' '}/{' '}
          {orDash(totalBaths(p))} <Gray>baths</Gray>
        </Small>

        {/* Sqft or Year Built */}
        <Small p={2}>
          {orDash(p.sqft, v => <Numeric>{v}</Numeric>)} <Gray>sqft</Gray>
        </Small>

        {/* Sqft or Year Built */}
        <Small p={2}>
          {orDash(p.yearBuilt)} <Gray>build</Gray>
        </Small>
      </Box>

      {p.hasPropertyDataInDb && (
        <Box m={-2} pt={1} display='flex' flexWrap='wrap'>
          <Box p={2}>
            <GrayCap>EST. EQUITY</GrayCap>
            <Small mt='-2px'>
              {orDash(p.equity, v => <Monetary decimals={0}>{v}</Monetary>)}
            </Small>
          </Box>

          <Box p={2}>
            <GrayCap>EST. VALUE</GrayCap>
            <Small mt='-2px'>
              {orDash(p.value, v => <Monetary decimals={0}>{v}</Monetary>)}
            </Small>
          </Box>

          <Box p={2}>
            <GrayCap>LAST SOLD</GrayCap>
            <Small mt='-2px'>
              {orDash(p.lastSalePrice, v => <Monetary decimals={0}>{v}</Monetary>)}
              {isNonEmpty(p.lastSalePrice) &&
                isNonEmpty(p.lastSaleDate) &&
                  <br />}
              {isEmpty(p.lastSalePrice) &&
                isNonEmpty(p.lastSaleDate) &&
                ' '}
              {orNull(p.lastSaleDate, v => {
                const diff = moment().diff(v, 'years')
                return diff < 1 ? 'less than a year ago' : `${diff} years ago`
              })}
            </Small>
          </Box>
        </Box>
      )}

      <Box pt={2}>
        <Box mx='-2px' display='flex' flexWrap='wrap' alignItems='flex-end'>
          <GrayCap as='h2' p='2px'>
            OWNER
          </GrayCap>

          {p.isIndividual !== null && (
            <Box p='2px'>
              <PropertyPill type={p.isIndividual ? 'individual' : 'entity'} />
            </Box>
          )}

          {p.ownerType && (
            <Box p='2px'><PropertyPill type={p.ownerType} /></Box>
          )}
        </Box>
        <Small mt='-2px'>
          {orDash(p.name, n =>
            n ? n.split(',').join(', ') : null)}
        </Small>

        <Box py={1}>
          <SituationsPill
            property={p}
            pillProps={{
              size: 'small',
              style: {
                minHeight: '20px',
                borderRadius: '9999px',
                fontSize: '13px',
                border: '1px solid #2B959C'
              }
            }}
          />
        </Box>
      </Box>
    </Text>
  )
})

export default SearchHistoryItem

const Gray = p => <Text color='secondary' fontSize={0} {...p} />
const Small = p => <Text fontSize={0} {...p} />
const GrayCap = p =>
  <Caption display='block' color='secondary' fontSize='13px' {...p} />
