import React from 'react'
import Progress from 'common/Progress'
import { Numeric } from 'common/format'

const SkiptraceProgressBar = ({ memberStats }) => {
  if (!memberStats.skiptracing) return null

  return (
    <Progress percent={100}>
      Skiptracing... <Numeric>{memberStats.skiptracing}</Numeric> left
    </Progress>
  )
}

export default SkiptraceProgressBar
