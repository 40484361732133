/* eslint "react/jsx-key": "warn" */
import React, { Fragment } from 'react'
import { Box, Text, Dialog, Button } from '@realsoftworks/decor'
import { isTrialing } from 'billing/selectors'
import { connect } from 'react-redux'
import { selectDownloads, selectHasReachedLimit } from 'lists/selectors'
import get from 'lodash/object/get'
import omit from 'lodash/object/omit'
import capitalize from 'lodash/string/capitalize'
import { TrialCta } from './TrialNotice'

const withConnect = connect((state, ownProps) => ({
  isTrialing: isTrialing(state),
  limitInfo: get(selectDownloads(state, ownProps.listId), 'limits'),
  hasReachedLimit: selectHasReachedLimit(state, ownProps.listId)
}))

const ListRestrictionDialog = withConnect(({
  limitInfo,
  isTrialing,
  listId,
  isOpen,
  hasReachedLimit,
  onClose
}) => {
  const limits = get(limitInfo, 'limits', {})
  const downloadsRaw = get(limitInfo, 'downloads', {})
  const downloads = omit(downloadsRaw, ['_id'])
  const limitsLength = Object.keys(limits).length

  return (
    <Dialog
      title='Download Limits'
      open={isOpen}
      onClose={onClose}
      toolbar={({ close }) => (
        <Fragment>
          <Button onClick={close} variant='neutral'>Close</Button>
          <TrialCta ml={2} listId={listId} />
        </Fragment>
      )}
    >
      {hasReachedLimit && (
        <Text display='block' color='red.500' pb={3}>You are out of downloads at the moment. Try again later</Text>
      )}

      <Box pb={3}>
        The following is your download
        {' '}{limitsLength > 1 ? 'usages' : 'usage'}
        {' '}and
        {' '}{limitsLength > 1 ? 'limits' : 'limit'}
        {' '}so far. Limits resets every 12:00am CST.
        {isTrialing && (
          <strong>
            {' '}Upgrade from trial to Paid Pro account to increase the download limits.
          </strong>
        )}
      </Box>
      <Text fontSize={2} as='ul'>
        {Object.entries(limits).map(([interval, limit]) => (
          <li>
            <Text color='secondary'>{capitalize(interval)}:</Text>
            {' '}{get(downloads, interval, 0)}
            {' '}/{' '}
            {limit}
          </li>
        ))}
      </Text>
    </Dialog>
  )
})

export default ListRestrictionDialog
