import React from 'react'
import { connect } from 'react-redux'
import { fetchMapDrives, fetchListsStats } from 'drivingfordollars/actions'
import { selectMapDrives } from 'drivingfordollars/selectors'
import isFinite from 'lodash/lang/isFinite'
import Progress from 'common/Progress'

const withState = connect(
  state => {
    const { value, progress, isRecursionInProgress, count } = selectMapDrives(state)
    const drives = Object.values(value)

    return {
      progress,
      isRecursionInProgress,
      fetchedCount: isFinite(drives?.length) ? drives.length : null,
      totalCount: isFinite(count) ? count : null,
    }
  },
  { fetchMapDrives, fetchListsStats }
)


const DrivesFetchProgress = withState(({
  progress,
  isRecursionInProgress,
  fetchedCount,
  totalCount,
  ...props
}: {
  progress: number | null
  isRecursionInProgress: boolean
  fetchedCount: number | null
  totalCount: number | null
}) => {
  if (!isRecursionInProgress || !fetchedCount || !totalCount || !progress || progress >= 1)
    return null

  return (
    <Progress percent={progress * 100}>
      Loading drives {fetchedCount}/{totalCount} ({Math.round(progress * 100)}%)
    </Progress>
  )
})

export default withState(DrivesFetchProgress)
