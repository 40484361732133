/* eslint "react/display-name": "warn" */
import React from 'react'
import { Box } from '@realsoftworks/decor'

export default () => (
  <Box
    ml={5}
    width={4}
    height={32}
    bg='neutral.300'
  />
)
