/* eslint "camelcase": "warn" */
import { SEARCH_CONTACTS_SUCCESS, CLEAR_CONTACTS, COMPLETE_CREATE_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_CONTACTS_SUCCESS:
      if (action.meta && action.meta.params && action.payload && action.payload.result &&
        action.payload.result.result && action.payload.result.result.models) {
        const { state_filter } = action.meta.params
        const ids = action.payload.result.result.models
        return {
          ...state,
          [state_filter]: ids
        }
      }
      return state
    case CLEAR_CONTACTS:
      return []
    case COMPLETE_CREATE_SUCCESS:
      if (action.payload && action.payload.result && action.payload.result.result && action.payload.entities && action.payload.entities.contacts) {
        const newState = Object.assign({}, state)
        const id = action.payload.result.result
        const contact = action.payload.entities.contacts[id]
        let ids = newState.active ? newState.active : []
        const contacts = action.meta.params.contacts
        ids = insertContactIntoOrdering(contact, ids, contacts, 'alphabetical asc')
        return {
          ...state,
          active: ids
        }
      }
      return state
  }
  return state
}

function insertContactIntoOrdering (contact, ids, contacts, sortType) {
  const afterId = ids.findIndex(id => {
    if (contact.values.name.toUpperCase() < contacts[id].values.name.toUpperCase())
      return true
  })
  ids.splice(afterId, 0, contact.id)
  return ids
};
