/* eslint "eqeqeq": "warn", "react/jsx-key": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import { Box, Text, Button } from '@realsoftworks/decor'

import BigLoader from 'common/BigLoader'
import CashSales from './CashSales'
import NeighborhoodResults from './NeighborhoodResults'
import NeigborhoodTable from './NeighborhoodTable'
import {
  fetchCustomNeighbors,
  toggleLandlord,
  fetchNeighbors
} from '../actions'
import {
  getCustomNeighbors,
  getCustomNeighborsCount,
  getCustomNeighborsPaging,
  getNeighbors,
  selectIsLandlordTicked
} from '../selectors'
import Checkbox from '@realsoftworks/decor/dist/components/Checkbox'
import CashSalesOptions from './CashSalesOptions'
import FontIcon from 'common/FontIcon'

class NeighborhoodOptionsAndResults extends React.Component {
  constructor(props) {
    super(props)

    const d = new Date()
    d.setFullYear(d.getFullYear() - 3)

    this.state = {
      mode: 'Neighbors', // neighbors || cashsales || custom
      modes: ['Neighbors', 'CashSales', 'Custom'],
      cashSalesOpts: {
        range: 0.5,
        minDeals: 3,
        recentDeal: d,
        minDealsInputKey: 0
      }
    }
  }

  setCashSalesOpts = (stateOrUpdaterFn, callback = () => { }) => {
    this.setState(({ cashSalesOpts, ...s }) => ({
      ...s,
      cashSalesOpts: typeof stateOrUpdaterFn === 'function'
        ? stateOrUpdaterFn(cashSalesOpts)
        : { ...cashSalesOpts, ...stateOrUpdaterFn }
    }), callback)
  }

  onChangePage = offset => {
    this.props.fetchCustomNeighbors({ ...this.props.filters, offset }, 'Custom')
  }

  componentDidMount = () => {
    if (this.props.mode != undefined)
      this.setState({ mode: this.props.mode })
  }

  handleIsLandlordChange = () => {
    const { mode } = this.state
    const {
      filters: customFilters,
      property,
      toggleLandlord,
      fetchNeighbors,
      fetchCustomNeighbors
    } = this.props
    const coords = property.result.address.coordinates

    toggleLandlord()

    if (mode === 'Neighbors')
      fetchNeighbors({ lat: coords.latitude, lon: coords.longitude })
    else if (mode === 'Custom')
      fetchCustomNeighbors({ ...customFilters, radius: [coords.latitude, coords.longitude] }, 'Custom')
  }

  renderOptions = () => (
    <Box
      display='flex'
      alignItems={['center', null, 'flex-end']}
      flexWrap='wrap'
      m={-2}
    >
      <Box flex='0 1 auto' p={2} pb={[1, null, 0]}>
        <Text display='block' flex='1 1 100%' fontSize='1' pb={[2, null, 0]}>
          SEARCH OPTIONS
        </Text>
        <Box
          display='flex'
          alignItems='center'
          flexWrap='wrap'
          mx={[-1, null, -2]}
        >

          {this.state.modes
            .map(tmode => (
              <Box p={[1, null, 2]}>
                <Button
                  onClick={() => { this.setState({ mode: tmode }); this.props.onChangeMode(tmode) }}
                  sizeVariant={['small', null, 'medium']}
                  variant={tmode === this.state.mode ? 'primary' : 'secondary'}
                >
                  {tmode === 'CashSales' ? 'Cash Sales' : tmode}
                </Button>
              </Box>
            ))}

          {this.state.mode !== 'CashSales' && (
            <Box p={[1, null, 2]}>
              <Checkbox
                label='Landlords only'
                checked={this.props.isLandlord}
                onChange={this.handleIsLandlordChange}
                minHeight={22}
              />
            </Box>
          )}
        </Box>
      </Box>
      {this.renderTypeOptions()}
    </Box>
  )

  renderTypeOptions = () => {
    if (this.state.mode == 'CashSales')
      return (
        <Box flex='1 1 auto' p={2}>
          <CashSalesOptions
            cashSalesOpts={this.state.cashSalesOpts}
            fetchCustomNeighbors={(params, fs) => {
              this.props.fetchCustomNeighbors(params, fs)
            }}
            property={this.props.property}
            setCashSalesOpts={this.setCashSalesOpts}
          />
        </Box>
      )

    const isEditCriteriaVisible =
      (this.props.customNeighbors.result || []).length > 0 &&
      this.props.customNeighbors.fetchSource !== 'CashSales'
    if (this.state.mode == 'Custom' && isEditCriteriaVisible)
      return (
        <Box flex='1 1 auto' p={2}>
          <Button
            sizeVariant='medium'
            variant='neutral'
            color='lightblue.500'
            onClick={() => this.props.setMode('custom_search')}
          >
            <FontIcon icon='cog' />
            {' '}
            Edit Criteria
          </Button>
        </Box>
      )

    return null
  }

  renderNeighbors = () => (
    <BigLoader loading={this.props.neighbors.loading}>
      <Box display='flex' width='100%'>
        <NeigborhoodTable neighbors={this.props.neighbors.result || []} />
      </Box>
    </BigLoader>
  )

  renderCashSales = () => (
    <Box display='flex' width='100%'>
      <CashSales customNeighbors={this.props.customNeighbors || []} />
    </Box>
  )

  renderCustom = () => {
    if (this.props.customNeighbors.loading == null || this.props.customNeighbors.fetchSource == 'CashSales')
      return (
        <Box display='flex' flexDirection='column' alignItems='center' width='100%' py='6'>
          <Box my='3'>
            Click the button below to start your custom neighborhood search
          </Box>
          <Box my='3'>
            <Button
              onClick={() => this.props.setMode('custom_search')}
              sizeVariant='large'
              variant='primary'
            >
              Enter Criteria
            </Button>
          </Box>
        </Box>
      )
    else
      return (
        <BigLoader loading={this.props.customNeighbors.loading}>
          <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
            <NeighborhoodResults
              data={this.props.customNeighbors.result || []}
              count={this.props.count || { total: 0 }}
              paging={this.props.paging}
              onChangePage={this.onChangePage}
              goToSearch={() => this.props.setMode('custom_search')}
            />
          </Box>
        </BigLoader>
      )
  }

  renderMode = () => {
    if (this.state.mode == 'Neighbors') return this.renderNeighbors()
    if (this.state.mode == 'CashSales') return this.renderCashSales()
    if (this.state.mode == 'Custom') return this.renderCustom()
  }

  render = () => (
    <Box display='flex' width='100%' flexWrap='wrap' style={{ position: 'relative' }}>
      <Box p={2} flex='1 1 100%'>
        {this.renderOptions()}
      </Box>
      <Box p={2} height='80vh' flex='1 1 50%' minWidth='280px'>
        {typeof this.props.renderMap === 'function' && this.props.renderMap()}
      </Box>
      <Box p={2} flex='1 1 50%' minWidth='280px'>
        {this.renderMode()}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  neighbors: getNeighbors(state),
  customNeighbors: getCustomNeighbors(state),
  count: getCustomNeighborsCount(state),
  paging: getCustomNeighborsPaging(state),
  isLandlord: selectIsLandlordTicked(state)
})

const mapActionsToProps = {
  fetchNeighbors,
  fetchCustomNeighbors,
  toggleLandlord
}

export default connect(mapStateToProps, mapActionsToProps)(NeighborhoodOptionsAndResults)
