import React from 'react'
import { Caption, Box } from '@realsoftworks/decor'
import ListItem from './ListItem'

const CategoryList = ({ categories, onCategoryClick }) => (
  <Box>
    <Caption color='secondary' mb={1} as='div'>Search By:</Caption>
    {categories.map(
      v => <ListItem px={3} mx={-3} key={v.id} onClick={() => onCategoryClick(v.id)}>{v.icon} {v.label}</ListItem>
    )}
  </Box>
)

export default CategoryList
