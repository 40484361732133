import { combineReducers } from 'redux'

import byReference from './byReference'
import tasks from './tasks'
import comments from './comments'
import users from './users'
import ordering from './ordering'

export default combineReducers({
  byReference, tasks, comments, users, ordering
})
