import React, { FC, useEffect } from 'react'
import { Card, Box, Button } from '@realsoftworks/decor'
import PropertyInfoSection, { PropertyInfoFilters } from 'leadlists/components/Sellers/components/SellerFilters/PropertyInfoSection'
import OwnerInfoSection, { OwnerInfoFilters } from 'leadlists/components/Sellers/components/SellerFilters/OwnerInfoSection'
import EquityInfoSection, { EquityInfoFilters } from 'leadlists/components/Sellers/components/SellerFilters/EquityInfoSection'
import { useAccordionFilterSection } from 'leadlists/components/AccordionFilterSection'
import { setLandlord } from 'propertydata/actions'
import { connect } from 'react-redux'

type Filters = PropertyInfoFilters & OwnerInfoFilters & EquityInfoFilters

type Props = {
  filters: Filters
  onChange: (filters: Filters) => void
  search: (filters: Filters) => void
  cancel: () => void
  setLandlord: () => void
}

const withConnect = connect(null, { setLandlord })

/**
 * @TODOCOMMIT LANDLORD
 */
const NeighborhoodFilters: FC<Props> = withConnect(({ filters, search, cancel, onChange, setLandlord }) => {
  const {
    openSection,
    toggleSectionMemoedFactory: toggleSection
  } = useAccordionFilterSection()

  /**
   * Landlord would prefill absentee and vacancy filters, this clears that
   */
  useEffect(() => {
    setLandlord(false)
  }, [])

  return (
    <>
      <Box width='100%' mx={15} mt={30}>
        <Card mb={30}>
          <PropertyInfoSection
            filters={filters}
            onChange={onChange}
            openSection={openSection}
            toggleSection={toggleSection}
          />

          <OwnerInfoSection
            filters={filters}
            onChange={onChange}
            openSection={openSection}
            toggleSection={toggleSection}
          />

          <EquityInfoSection
            filters={filters}
            onChange={onChange}
            openSection={openSection}
            toggleSection={toggleSection}
          />
        </Card>
        <Box display='flex' justifyContent='flex-end' alignItems='center' mb={9}>
          <Button
            mx={2}
            onClick={() => { cancel() }}
            sizeVariant='medium'
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            mx={2}
            onClick={() => { search(filters) }}
            sizeVariant='medium'
            variant='primary'
          >
            Search Neighborhood
          </Button>
        </Box>
      </Box>
    </>
  )
})

export default NeighborhoodFilters
