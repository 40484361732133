
import moment from 'moment'

export const formats = {
  SERVER_DATE: 'YYYY-MM-DD',
  SERVER_DATETIME: 'YYYY-MM-DD HH:mm:ss',
  UI_DATE: 'MM/DD/YYYY',
  UI_DATE_SHORT: 'MM/DD/YY',
  SERVER_TIME: 'HH:mm:ss',
  UI_TIME: 'hh:mm A',
  UI_DATETIME: 'MM/DD/YYYY hh:mm A',
  MONTH_YEAR: 'MMM YYYY'
}

export const format = (date, format) => moment(date).format(format)

export const formatDatetime = date => format(date, formats.UI_DATETIME)

export const formatDate = date => format(date, formats.UI_DATE)

export const formatDateShort = date => format(date, formats.UI_DATE_SHORT)

export const relative = date => moment(date).fromNow()
