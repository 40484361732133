import { SEARCH_MARKETS_SUCCESS, SEARCH_MARKETS, SEARCH_MARKETS_FAILURE } from '../actions'

export default (state = { selected: null, markets: null, error: null }, action) => {
  switch (action.type) {
    case SEARCH_MARKETS:
      return {
        ...state,
        error: false
      }

    case SEARCH_MARKETS_SUCCESS:
      return {
        ...state,
        markets: action.payload.items
      }

    case SEARCH_MARKETS_FAILURE:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}
