import { Schema, arrayOf } from 'normalizr'

export const tag = new Schema('tag')
export const tagSchema = arrayOf(tag)

export const contact = new Schema('contact')
export const contactSchema = new Schema('contacts')
contactSchema.define({
  values: {
    contacttag: tagSchema
  }
})
