import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'
import saga from './sagas'

import Terms from './components/terms'

const components = { Terms }

export { actions, reducer, selectors, components, saga }

export default { actions, reducer, selectors, components, saga }
