/* eslint "no-return-assign": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { bulkDestroyMessages, bulkPromoteMessages } from '../actions'

import BigFatalError from 'common/BigFatalError'

import DataContainer from './DataContainer'
import MessageList from './MessageList'

import Mask from 'decor/Mask'

class MessageListDataContainer extends Component {
  state = { loading: false, busy: false, selected: [] };

  showMore = () => {
    this.setState({ loading: true })
    this.ct.refetch(vars => ({
      ...vars,
      offset: vars.offset + 10
    }))
  };

  onRefetch = () => {
    this.setState({ loading: false })
  };

  destroy = ids => {
    this.setState({ busy: true })
    this.props.bulkDestroyMessages(ids).then(() => this.setState({ busy: false }))
  };

  promote = ids => {
    this.setState({ busy: true })
    this.props.bulkPromoteMessages(ids).then(() => {
      let { selected } = this.state
      selected = selected.filter(id => !ids.includes(id))
      this.setState({ busy: false, selected })
    })
  };

  updateSelection = selected => this.setState({ selected });

  render () {
    const { slim } = this.props

    return (
      <DataContainer
        ref={ ref => this.ct = ref }
        initialVars={{ limit: 10, offset: 0 }}
        load={this.props.load}
        selector={this.props.selector}
        callback={this.onRefetch}
        render={({ error, props }) => {
          if (error)
            return <BigFatalError/>

          return (
            <div style={{ position: 'relative' }}>
              {this.state.busy && <Mask/>}
              <MessageList
                slim={slim}
                onSelectionChange={this.updateSelection}
                selected={this.state.selected}
                showSite={true}
                data={props.data.items}
                count={props.data.count}
                onCreateLead={this.promote}
                onDelete={this.destroy}
                onShowMore={this.showMore}
                loading={this.state.loading}
              />
            </div>
          )
        }}
      />
    )
  }
}

MessageListDataContainer = connect(null, {
  bulkDestroyMessages,
  bulkPromoteMessages

})(MessageListDataContainer)

export default MessageListDataContainer
