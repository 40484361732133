/* eslint "react/no-render-return-value": "warn" */
'use strict'

import React from 'react'
import ReactDOM from 'react-dom'

import ModalPortal from './ModalPortal.react'

var ModalLayerMixin = {
  componentDidMount () {
    this.node = document.createElement('div')
    this.node.className = 'modal-portal'
    document.body.appendChild(this.node)
    this.renderPortal(this.props)
  },

  componentDidUpdate () {
    this.renderPortal()
  },

  componentWillUnmount () {
    ReactDOM.unmountComponentAtNode(this.node)
    document.body.removeChild(this.node)
  },

  renderPortal () {
    var props = {
      ...this.props,
      children: this.renderLayer()
    }

    this.portal = ReactDOM.render(React.createElement(ModalPortal, props), this.node)
  },

  render () {
    return null
  }
}

export default ModalLayerMixin
