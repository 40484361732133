/* eslint "eqeqeq": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import ModelForm from '../../../views/util/ModelForm.react'
import ContactHeader from './ContactHeader.react'
import BigLoader from 'common/BigLoader'
import uniq from 'lodash/array/uniq'
import without from 'lodash/array/without'

class ContactView extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    updateHandler: PropTypes.func.isRequired,
    onAddTagHandler: PropTypes.func.isRequired
  };

  state = {
    v: null,
    loadingFields: []
  };

  onPickerChange = v => {
    this.setState({ v })
  };

  render () {
    const model = this.props.model

    if (!model || !model.template)
      return <BigLoader/>

    const contact = model.values || {}
    const fields = model.template && model.template.fields ? model.template.fields : []

    return (
      <div className='contact-detail'>
        <ContactHeader contact={contact}/>
        <ModelForm
          loading={this.state.loadingFields}
          mode='editor'
          entityId={model.id}
          entityType='contact' actions={null}
          fields={fields}
          model={contact}
          onChange={this.onChange}/>
      </div>
    )
  }

  onChange = (name, value) => {
    // convert from array of objects to array of ids
    var model = {}
    if (Array.isArray(value) && value.length == 0)
      value = ''

    model[name] = value

    this.setState(({ loadingFields, ...state }) =>
      ({ ...state, loadingFields: uniq([...loadingFields, name]) }))

    return this.props.updateHandler(model)
      .finally(() => {
        this.setState(({ loadingFields, ...state }) =>
          ({ ...state, loadingFields: without(loadingFields, name) }))
      })
  };
}

export default ContactView
