export default ({ type }) => {
  switch (type) {
    case 'drive':
      return 'Driving'

    case 'national-leads-privatelenders':
      return 'Private Lender'

    case 'national-leads-cashbuyers':
      return 'Cash Buyer'

    case 'national-leads-sellers':
      return 'Sellers'

    default:
      return 'Lead List'
  }
}
