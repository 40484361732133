import React, { FC } from 'react'
import { Box, Text, Tooltip } from '@realsoftworks/decor'
import AvailableBalance from 'marketing/components/AvailableBalance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const SkiptraceBalance: FC<{
  availableBalance: number,
  cost?: number,
}> = ({ availableBalance, cost }) => {
  const isBalanceInsufficient = typeof cost === 'number' && cost > availableBalance
  return (
    <>
      <Box py={4} px={5} display='flex' bg='neutral.100'>
        <Tooltip
          content={
            <>
              Your Propelio Wallet balance.<br />
              Any unused credit will be returned here and you can add to your balance in your billing settings.
            </>
          }
        >
          <Text fontSize={3}>Current Balance: <Text color='neutral.300'><FontAwesomeIcon icon={faQuestionCircle} /></Text></Text>
        </Tooltip>
        <Box ml='auto'>
          <Text
            px={2}
            py={1}
            fontSize={3}
            textAlign='right'
            css={{ borderRadius: 4 }}
            bg={isBalanceInsufficient ? 'red.200' : undefined}
            display='block'
          >
            $<AvailableBalance />
          </Text>
        </Box>
      </Box>

      {isBalanceInsufficient&& (
        <Box p={3} mx={5} mt={5} border='1px solid' borderColor='red.500' borderRadius={4} bg='red.100'>
          <Text css={{ textTransform: 'capitalize' }}>Insufficient Balance</Text>
          <Text color='secondary' pt={1} display='block'>
            Please visit the
            {' '}<Link to='/billing'>billing page</Link>
            {' '}to set up wallet auto-fill or purchase more market credits
          </Text>
        </Box>
      )}
    </>
  )
}

export default SkiptraceBalance
