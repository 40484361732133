import { combineReducers } from 'redux'

import * as CONSTANTS from '../actions'

const sellers = (state = null, { type, payload, meta }) => {
  switch (type) {
    case CONSTANTS.CLEAR:
      return null

    case CONSTANTS.FETCH_COUNT_SELLERS: {
      return { ...state, loading: true }
    }
    case CONSTANTS.FETCH_COUNT_SELLERS_SUCCESS: {
      return { ...state, ...payload, loading: false }
    }
    case CONSTANTS.FETCH_COUNT_SELLERS_FAILURE: {
      return { ...state, loading: false }
    }
  }

  return state
}

const buyers = (state = null, { type, payload, meta }) => {
  switch (type) {
    case CONSTANTS.CLEAR:
      return null

    case CONSTANTS.FETCH_COUNT_BUYERS: {
      return { loading: true }
    }
    case CONSTANTS.FETCH_COUNT_BUYERS_SUCCESS: {
      return { total: payload.total, loading: false }
    }
  }

  return state
}

const lenders = (state = null, { type, payload, meta }) => {
  switch (type) {
    case CONSTANTS.FETCH_COUNT_LENDERS: {
      return { loading: true }
    }
    case CONSTANTS.FETCH_COUNT_LENDERS_SUCCESS: {
      return { total: payload.total, loading: false }
    }
  }

  return state
}

export default combineReducers({
  sellers,
  buyers,
  lenders
})
