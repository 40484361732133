import merge from 'lodash/object/merge'

function customizer (objValue, srcValue) {
  if (Array.isArray(objValue))
    return srcValue
}

const entityTypes = ['dealalerts', 'dealalertlistings', 'leads', 'contacts']
const initialState = entityTypes.reduce((ret, type) => {
  ret[type] = {}
  return ret
}, {})

// Updates an entity cache in response to any action with response.entities.
export default function entities (state = initialState, { payload }) {
  if (payload && payload.entities)
    return merge({}, state, payload.entities, customizer)

  return state
}
