import React, { useCallback } from 'react'
import { Button, Text, Box } from '@realsoftworks/decor'

import { Numeric } from 'common/format'
import LoadingIcon from 'common/LoadingIcon'
import FixedFooter from './FixedFooter'
import FiltersPills from './FiltersPills'
import set from 'common/util/set'
import { TooltipPosition } from './FiltersPills/PropertyTypesPills/PropertyTypesPills'

const FilterFooter = ({
  filters,
  counts,
  showResults,
  setFilters = () => {}
}) => {
  let value = counts
  let estimate = false

  if (counts && typeof counts === 'object')
    if (counts.total)
      value = counts.total
    else if (counts.stats)
      if (counts.stats.count || counts.stats.count === 0) {
        value = counts.stats.count
      } else {
        value = counts.stats.estimate
        estimate = true
      }

  const onDeleteFilter = useCallback(key => {
    const keys = Array.isArray(key) ? key : [key]
    const deleted = keys.reduce((acc, key) =>
      set(key.split('.'), undefined, acc),
    filters)
    setFilters(deleted)
  },
  [setFilters, filters])

  return (
    <FixedFooter p={3} pr='80px'>
      <Box
        maxHeight='120px'
        flex='1 1 auto'
        display='flex'
        justifyContent='left'
        overflowY='auto'
      >
        <FiltersPills
          setFilters={setFilters}
          filters={filters}
          onDeleteFilter={onDeleteFilter}
          tooltipPosition={TooltipPosition.top}
        />
      </Box>

      <Box
        flex='0 1 auto'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        flexDirection='row'
        minWidth='320px'
      >
        <Box p={3}>
          {
            (counts == null)
              ? <Text pt={1} fontSize={0} color='neutral.500'>You must enter at least one geography filter</Text>
              : <Text pt={1} fontSize={2} color='black'>
                {(counts.loading)
                  ? <Text color='secondary'><LoadingIcon /></Text>
                  : ''}
                {estimate ? 'About ' : ' '}<Numeric>{value || 0}</Numeric> Matches
              </Text>
          }
        </Box>
        <Box p={3}>
          <Button
            onClick={ showResults }
            disabled={ !filters.county && !filters.city && !filters.zip}
            sizeVariant='large'
            variant='primary'
          >
              Preview List
          </Button>
        </Box>
      </Box>
    </FixedFooter>
  )
}

export default FilterFooter
