/* eslint "react/no-string-refs": "warn", "react/no-find-dom-node": "warn" */

import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import FieldFactory from '../FieldFactory.react'

class FormInput extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    autoComplete: PropTypes.string
  };

  static defaultProps = {
    type: null,
    id: null,
    name: null,
    onChange: null,
    value: null,
    autoComplete: 'off'
  };

  render () {
    var props = Object.assign({}, this.props, { ref: 'field' })
    if (props.fieldinfo)
      props = props.fieldinfo

    var Control = FieldFactory.getField(this.props.type, props)

    return (
      <div className={'form-input ' + (this.props.valid === false ? 'has-error' : '')}>
        {Control(props)}
      </div>
    )
  }

  focus = () => {
    this.refs.field.focus()
  };

  checkBlur = e => {
    var f = this.refs.field
    return !f.checkBlur || f.checkBlur(e)
  };

  getInputDOMNode = () => ReactDOM.findDOMNode(this.refs.field);
}

export default FormInput
