import React from 'react'
import { Heading, Card, Text, Box, Button } from '@realsoftworks/decor'
import DataView from 'search/components/DataView'
import { Monetary, DateTime } from 'common/format'
import { orDash } from 'common/util/renderHelpers'

const ValueSection = ({ property, onOpenCmaTab }) => {
  const summary = property?.summary

  return (
    <Card
      p={3}
      as='section'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      flexWrap='wrap'
      bg='yellow.100'
      boxShadow={1}
      borderRadius={3}
    >
      <Heading
        as='h2'
        color='primary.500'
        textAlign='center'
        pb={1}
        style={{ fontSize: 16 }}
      >
        Value
      </Heading>

      <DataView data={{
        'Est. Value': orDash(summary.value, v => (
          <Emphasized>
            <Monetary decimals={0}>{v}</Monetary>
          </Emphasized>
        )),
        'Est. Equity': orDash(summary.equity, v => (
          <Emphasized>
            <Monetary decimals={0}>{v}</Monetary>
          </Emphasized>
        )),
        'Last Sale Price': orDash(summary.lastSalePrice, v => (
          <Emphasized>
            <Monetary decimals={0}>{v}</Monetary>
          </Emphasized>
        )),
        'Last Sale Date': (
          <Box py='2px'>
            {orDash(summary.lastSaleDate, v => (
              <DateTime format='MM/DD/YYYY'>{v}</DateTime>
            ))}
          </Box>
        )
      }}
      />

      <Box mt={3} display='flex' justifyContent='center'>
        <Button onClick={onOpenCmaTab} variant='primary'>Run Comps/CMA</Button>
      </Box>
    </Card>
  )
}

export default ValueSection

const Emphasized = props => (
  <Text fontWeight='bold' color='red.400' fontSize={2} {...props} />
)
