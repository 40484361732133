import React from 'react'
import { Button, Box } from '@realsoftworks/decor'
import LoadingButton from 'common/LoadingButton'
import CMAField from './CMAField.js'
import CMARangeField from './CMARangeField.js'

class CMACriteria extends React.Component {
  render () {
    var { params, showAddCriteria, setDefaultCriteria, loading } = this.props

    const { limitMonths = 24 } = this.props.metadata
    const monthOptions = [];

    [3, 6, 9, 12, 18, 24].forEach(v => {
      if (v <= limitMonths)
        monthOptions.push(<option value={v}>{v}</option>)
    })

    return (
      <Box>
        <Button mb={4} variant='secondary' css='display: block; width: 100%;' onClick={setDefaultCriteria}>Set Recommended Defaults</Button>
        <Box className='cma-criteria'>
          <CMARangeField name='beds' label='Beds' type='number' params={params} onChange={this.onChange}/>
          <CMARangeField name='baths_full' label='Baths' type='number' params={params} onChange={this.onChange}/>
          <CMARangeField name='sqft' label='SqFt' type='number' params={params} onChange={this.onChange}/>
          <CMARangeField name='year_built' label='Year Built' thousandSeparator='' type='number' params={params} onChange={this.onChange}/>
          <CMAField name='range' label='Range (miles)' params={params} onChange={this.onChange}>
            <select className='form-control'>
              <option value='0.25'>1/4</option>
              <option value='0.5'>1/2</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option value='7.5'>7 1/2</option>
              <option>10</option>
            </select>
          </CMAField>
          <CMAField name='months' label='Months of Data' params={params} onChange={this.onChange}>
            <select className='form-control'>
              {monthOptions}
            </select>
          </CMAField>
        </Box>
        <Box display='flex' alignItems='center'>
          <a className='add-criteria-toggle' onClick={this.toggleAddCriteria}>{showAddCriteria ? 'Hide Additional Criteria' : 'Show Additional Criteria'}</a>
          <LoadingButton ml='auto' variant='primary' onClick={this.onSearch} loading={loading}>Search</LoadingButton>
        </Box>
      </Box>
    )
  }

  onChange = (name, value) => {
    this.props.onChange(name, value)
  }

  onSearch = () => {
    this.props.onSearch(this.props.params)
  }

  toggleAddCriteria = () => {
    this.props.toggleAddCriteria()
  }
}

export default CMACriteria
