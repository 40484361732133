import React, { Fragment, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, Box, IconButton, Heading, Button, Paragraph, Dialog, Pill, Menu, Position, ListItem, Text } from '@realsoftworks/decor'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import AutosizeInput from 'react-input-autosize'
import { fontSize, space } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

import LoadingIcon from 'common/LoadingIcon'
import ListTypeDisplay from '../../ListTypeDisplay'
import ListDownload from './ListDownload'
import SkiptraceOrderButton from '../../skiptrace/SkiptraceOrderButton'
import SkiptraceOrderHistoryButton from '../../skiptrace/SkiptraceOrderHistoryButton'
import FiltersPills from 'leadlists/components/FiltersPills'
import get from 'lodash/object/get'
import { TooltipPosition } from 'leadlists/components/FiltersPills/PropertyTypesPills/PropertyTypesPills'

const TypePill = ({ type, ...props }) => (
  <Pill {...props}><ListTypeDisplay type={type}/></Pill>
)

const Title = ({ mode, list, items, onClick, onDeleteClick, showActions }) => {
  const { name, type } = list
  const menuButton = React.useRef()
  const [isMenuShown, setIsMenuShown] = useState(false)
  const partialFilters = get(list, 'source.payload.filters', {})
  const newOnly = get(list, 'source.payload.newOnly')
  const location = get(list, 'source.payload.location')
  const filters = { ...partialFilters, newOnly, location }

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <Card border='1px solid transparent' p={2} onClick={onClick}>
          <Heading size={3} lineHeight='37px'>{name}</Heading>
        </Card>
        <IconButton color='neutral.500' ref={menuButton} onClick={() => { setIsMenuShown(!isMenuShown) }}><FontAwesomeIcon icon={faAngleDown} /></IconButton>
        <Menu
          target={menuButton.current}
          open={isMenuShown}
          zIndex={10}
          position={Position.BOTTOM_LEFT}
          onClose={() => {}}
        >
          <ListItem css='cursor: pointer' onClick={onClick}>
            <Text>Edit Name</Text>
          </ListItem>
          <ListItem css='cursor: pointer' onClick={() => onDeleteClick('list')}>
            <Text color='red.500'>Delete List</Text>
          </ListItem>
        </Menu>
        <TypePill ml={5} type={type} />
        <Box flex='1' />
        {
          showActions && (
            <React.Fragment>
              <ListDownload disabled={mode === 'item'} list={list} />
              <SkiptraceOrderButton mode={mode} items={items} list={list} ml={3} />
              <SkiptraceOrderHistoryButton list={list} ml={3} />
              <Button variant='danger' ml={3} onClick={() => onDeleteClick(mode)}>Delete {mode === 'list' ? 'list' : 'items'}</Button>
            </React.Fragment>
          )
        }
      </Box>
      <FiltersPills
        filters={filters}
        flex='1'
        p={4}
        tooltipPosition={TooltipPosition.bottom}
      />
    </Box>
  )
}

const DeleteDialog = ({ mode, onConfirm, deleting, ...props }) => (
  <Dialog
    title={mode === 'list' ? 'Delete list' : 'Delete selected items'}
    toolbarFlexLeft={mode === 'list'}
    {...props}
    toolbar={ ({ close }) => (
      <React.Fragment>
        <Button variant='neutral' onClick={close}>Cancel</Button>
        <Button variant='danger' disabled={deleting} onClick={onConfirm}>{deleting ? 'Deleting...' : 'Delete Forever'}</Button>
      </React.Fragment>
    )}
  >
    <Paragraph>Are you sure you want to delete {mode === 'list' ? 'this list' : 'the selected items'}? This action cannot be undone.</Paragraph>
  </Dialog>
)

const TitleInput = styled(AutosizeInput)`
  ${fontSize}
  ${space}
  line-height: 37px;

  > input {
    border-radius: 4px;
    border: 1px solid ${themeGet('colors.neutral.500')};
    padding: ${themeGet('space.2')}px;
    line-height: 37px;
  }
`

TitleInput.defaultProps = {
  fontSize: 5
}

const focusRef = el => el && el.focus()
export const EditingTitle = ({ name, saving, onSave, onCancel }) => {
  const [value, setValue] = useState(name)
  const onChange = useCallback(e => setValue(e.target.value), [name])

  return (
    <Box display='flex' alignItems='center'>
      <TitleInput value={value} onChange={onChange} inputRef={ focusRef }/>
      {saving ? (
        <Box ml={3}>
          <LoadingIcon size={2} />
        </Box>
      ) : (
        <Fragment>
          <IconButton ml={3} variant='primary' onClick={() => onSave(value)}><FontAwesomeIcon icon='check'/></IconButton>
          <IconButton ml={2} onClick={onCancel}><FontAwesomeIcon icon='times'/></IconButton>
        </Fragment>
      )}
    </Box>
  )
}

class ListHeader extends React.Component {
  static propTypes = {
    list: PropTypes.any,
    mode: PropTypes.string,
    selectedItems: PropTypes.arrayOf(PropTypes.string)
  }

  static defaultProps = {
    mode: 'list'
  }

  state = {
    editing: false,
    saving: false,
    deleteOpen: false,
    deleteMode: 'list',
    deleting: false
  };

  componentDidUpdate (prevProps) {
    if (prevProps.location.search !== this.props.location.search)
      this.setState({ deleteOpen: false })
  }

  startEditing = () => this.setState({ editing: true });
  onCancelEditing = () => this.setState({ editing: false, saving: false });

  openDialog = (deleteMode = 'list') => this.setState({ deleteOpen: true, deleteMode });
  closeDialog = () => this.setState({ deleteOpen: false });

  onSave = name => {
    this.setState({ saving: true })
    this.props.updateName(name).then(this.onCancelEditing)
  }

  onConfirmDelete = async () => {
    const { selectedItems, deleteList, deleteMembers } = this.props
    const { deleteMode } = this.state

    this.setState({ deleting: true })

    if (deleteMode === 'list')
      await deleteList()
    else
      await deleteMembers(selectedItems)

    this.setState({ deleting: false, deleteOpen: false })
  }

  render () {
    const { editing, saving, deleteOpen, deleteMode, deleting } = this.state
    const { list, download, showActions, mode, selectedItems } = this.props
    return (
      <Box p={4}>
        {editing
          ? <EditingTitle name={list.name} saving={saving} onSave={this.onSave} onCancel={this.onCancelEditing} />
          : <Title mode={mode} list={list} items={selectedItems} showActions={showActions} onClick={this.startEditing} onDeleteClick={this.openDialog} onDownloadClick={download} />
        }

        <DeleteDialog mode={deleteMode} open={deleteOpen} deleting={deleting} onClose={this.closeDialog} onConfirm={this.onConfirmDelete} />
      </Box>
    )
  }
}

export default withRouter(ListHeader)
