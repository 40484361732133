import { useState, useEffect, forwardRef, memo } from 'react'
import { arrayOf, number, object } from 'prop-types'
import { useMapApi } from 'common/Map'
import theme from '../../../../theme'
import { geoJsonToLatLngLiteral } from './utils'

const M_PER_MI = 1609.344

// NOTE: don't recreate the center and styles objects if not
//   necessary to prevent recalculations due to props change
const RadiusCircle = memo(forwardRef(({
  center,
  radius,
  planeStyles = {}
}, ref) => {
  const { map, maps } = useMapApi()
  const [circle, setCircle] = useState()

  useEffect(() => {
    if (circle && ref) ref.current = circle
  }, [circle, ref])

  initiateMapObjects({
    map,
    circle,
    maps,
    planeStyles,
    setCircle
  })

  drawCircleFromProps({
    map,
    circle,
    center,
    radius
  })

  return null
}))

export default RadiusCircle

RadiusCircle.propTypes = {
  center: arrayOf(number.isRequired).isRequired,
  radius: number.isRequired, // radius in miles
  planeStyles: object
}

/* Subprocedures */

const initiateMapObjects = ({
  map,
  circle: existingCircle,
  maps,
  planeStyles,
  setCircle
}) => {
  useEffect(() => {
    // Note that we create circle only once per instance
    if (!map || existingCircle) return

    const circle = new maps.Circle({
      ...DEFAULT_PLANE_STYLES,
      ...planeStyles
    })

    setCircle(circle)
  }, [map, existingCircle])
}

const drawCircleFromProps = ({
  map,
  circle,
  center,
  radius
}) => {
  useEffect(() => {
    if (!map || !circle) return

    circle.setOptions({
      map,
      center: geoJsonToLatLngLiteral(center),
      radius: radius * M_PER_MI
    })

    return () => {
      circle.setMap(null)
    }
  }, [map, circle, center, radius])
}

/* Constants */

const { colors } = theme

const DEFAULT_PLANE_STYLES = {
  strokeColor: colors.yellow[500],
  strokeOpacity: 0.7,
  strokeWeight: 3,
  fillColor: colors.yellow[500],
  fillOpacity: 0.3
}
