/* eslint "eqeqeq": "warn" */
import React from 'react'

class CategoryLinks extends React.Component {
  render () {
    var selected = this.props.selected || []

    return (
      <div className='category-links'>
        {this.props.categories.map(function (s, index) {
          var v = s.value
          if (selected.indexOf(v) != -1)
            return <strong key={'label-' + index}>{s.label}</strong>
          else
            return <a key={'link-' + index} href='#' onClick={this.onClick.bind(this, v)}>{s.label}</a>
        }, this)}
      </div>
    )
  }

  onClick = (v, e) => {
    e.preventDefault()

    this.props.onChange(v)
  };
}

export default CategoryLinks
