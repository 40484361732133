import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBolt, faStream, faGlobeAmericas, faCar, faMap, faSearch, faPowerOff, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Box } from '@realsoftworks/decor'
import { getSetting } from 'users/selectors'
import { updateSettings } from 'users/actions'
import OnboardingComponent from 'onboarding/OnboardingComponent'
import MenuHint from './MenuHint'
import TrialNotice from '../TrialNotice'
import Sidebar, { SidebarItem, WhenExpanded } from './Sidebar'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import PersonalMenu from '../PersonalMenu'
import RouteReqComponent from 'redirect/RouteReqComponent'
import { ROUTES } from '../../../const'
import { selectors as usersSelectors } from 'users'

const MenuButton = styled(Button)`
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Menu = ({
  route,
  sideSheetProps,
  onboardingMenu,
  updateSettings,
  isPhpEmbed,
  isBeta,
  ...props
}) => {
  const { smDown } = useBreakpoint()
  const [menuRef, setMenuRef] = React.useState()
  const [open, setOpen] = React.useState(false)
  const close = () => setOpen(false)

  const onMenuButtonClick = () => {
    if (!onboardingMenu)
      updateSettings({ 'onboarding.menu': true })

    setOpen(open => !open)
  }

  return (
    <>
      {smDown && (
        <MenuButton
          // Sidebar component depends on this ID
          id='toggle-sidebar'
          ref={setMenuRef}
          p={4}
          {...props}
          onClick={onMenuButtonClick}
        >
          <FontAwesomeIcon icon={faBars} />
        </MenuButton>
      )}

      <OnboardingComponent setting='onboarding.menu'>
        <MenuHint target={menuRef} />
      </OnboardingComponent>

      <Sidebar onRequestClose={close} isOpenOnMobile={open} topOffset={48} isPhpEmbed={isPhpEmbed}>

        <RouteReqComponent routeKey={ROUTES.SEARCH_HISTORY}>
          <SidebarItem onRequestClose={close} route={route} label='Property Data' to='/search/history' icon={faSearch} />
        </RouteReqComponent>

        <RouteReqComponent routeKey={ROUTES.LIST_BUILDER}>
          <SidebarItem onRequestClose={close} route={route} to='/leadlists' icon={faStream} label='List Builder' />
        </RouteReqComponent>

        <RouteReqComponent routeKey={ROUTES.SITES}>
          <SidebarItem onRequestClose={close} route={route} label='Websites' to='/sites' icon={faGlobeAmericas} />
        </RouteReqComponent>

        <RouteReqComponent routeKey={ROUTES.DRIVING}>
          <SidebarItem onRequestClose={close} route={route} label='D4$' to='/drivingfordollars' icon={faCar} />
        </RouteReqComponent>

        {/* <RouteReqComponent routeKey={ROUTES.DEALALERTS}>
          <SidebarItem onRequestClose={close} route={route} label='Deal Alerts' to='/dealalerts' icon={faBolt} />
        </RouteReqComponent> */}

        <RouteReqComponent routeKey={ROUTES.REGIONAL_LEADS}>
          <SidebarItem onRequestClose={close} route={route} label='Regional Leads' to='/regionalleads' icon={faMap} />
        </RouteReqComponent>

        <WhenExpanded isOpenOnMobile={open}>
          <Box pt={3} display='flex' alignItems='center' flexDirection='column'>
            <TrialNotice mb={4} display={[null, null, null, 'none']} />
          </Box>
        </WhenExpanded>

        <Box flexGrow='1' />
        
        <PersonalMenu route={route} />
        <SidebarItem onRequestClose={close} route={false} label='Exit Legacy App' href={window.App.routes.genesis} icon={faSignInAlt} />
        <SidebarItem onRequestClose={close} route={false} label='Log out' as='a' href='/logout' icon={faPowerOff} />
      </Sidebar>
    </>
  )
}

export default connect(
  state => ({
    isBeta: usersSelectors.getIsBeta(state),
    onboardingMenu: getSetting(state, 'onboarding.menu')
  }), {
    updateSettings
  }
)(Menu)
