import React, { FC, useEffect, useRef, useState } from 'react'
import { RadioGroup, Radio, Box, Checkbox, Text, NumberInput } from '@realsoftworks/decor'

type ReskipOptObj = {
  differentProvider?: boolean
  olderThanXDays?: number
}

type ReskipOpt = boolean | ReskipOptObj

const SkipOptions: FC<{
  value?: ReskipOpt | null
  onChange: (v: ReskipOpt | null) => void
  [key: string]: any
}> = ({ value: valueFromProps, onChange, ...props }) => {
  /**
   * `null` means other option is selected but subvalues hasn't been selected yet
   * `undefined` no opion selected at all
   */
  const [value, setValue] = useState<ReskipOpt | null | undefined>(valueFromProps)
  const [isOlderThanXTicked, setIsOlderThanXTicked] = useState<boolean>(false)
  const [inputError, setInputError] = useState('')
  const lastOtherValuesRef = useRef<ReskipOptObj | null>(null)
  const lastIsOlderThanXTicked = useRef(false)
  const radioValue = value === true
    ? 'reskipAll'
    : value === false
      ? 'reskipNone'
      : value === null || typeof value === 'object'
        ? 'other'
        : undefined

  const handleRadioChange = (_e, newValue) => {
    setValue(
      newValue === 'reskipNone'
        ? false
        : newValue === 'reskipAll'
          ? true
          : newValue === 'other'
            ? lastOtherValuesRef.current
            : undefined
    )

    if (newValue !== 'other' && typeof value === 'object') {
      lastIsOlderThanXTicked.current = isOlderThanXTicked
      lastOtherValuesRef.current = value
    }
  }

  const handleDifferentProviderToggle = (_e, checked) => {
    const newValue = checked
      ? {
        ...(typeof value === 'object' && value),
        differentProvider: true
      }
      : typeof (value as ReskipOptObj)?.olderThanXDays === 'number'
        ? { olderThanXDays: (value as ReskipOptObj).olderThanXDays }
        : null
    setValue(newValue)
  }

  const handleOlderThanXToggle = (_e, checked) => {
    setIsOlderThanXTicked(checked)

    const newValue = (value as ReskipOptObj)?.differentProvider
      ? { differentProvider: true }
      : null

    setValue(newValue)
  }

  const handleOlderThanXInputChange = e => {
    const newOlderThanXValue = e.target.value
    const isInputValid = typeof newOlderThanXValue === 'number'
    const maybeNumVal = isInputValid ? newOlderThanXValue : undefined
    const differentProvider = (value as ReskipOptObj)?.differentProvider

    const newValue = isInputValid
      ? {
        ...(typeof value === 'object' && value),
        olderThanXDays: maybeNumVal
      }
      : typeof differentProvider === 'boolean'
        ? { differentProvider }
        : null

    setValue(newValue)
  }

  // API consumer doesn't need to differentiate null and undefined reskip value,
  // so normalize it to null
  useEffect(() => {
    const exposedValue: ReskipOpt | null = (value === undefined || xDaysInputErr)
      ? null
      : value
    onChange(exposedValue)
  }, [value])

  // React to all values and change the error feedback accordingly
  const shouldXDaysInputBeSet = radioValue === 'other' && isOlderThanXTicked
  const olderThanXDays = (value as ReskipOptObj)?.olderThanXDays

  const EMPTY_ERR = 'Please input a number'
  const MIN_ERR = 'Please input a number greater than zero'
  const X_DAYS_MIN = 1

  const xDaysInputErr = !shouldXDaysInputBeSet
    ? ''
    : typeof olderThanXDays !== 'number'
      ? EMPTY_ERR
      : olderThanXDays < X_DAYS_MIN
        ? MIN_ERR
        : ''

  useEffect(() => {
    setInputError(xDaysInputErr)
  }, [xDaysInputErr])

  // Make sure checkbox is ticked when "older than x days" is set
  // (like when component has mounted with existing values)
  const shouldTickOlderThanXDays = !isOlderThanXTicked && typeof (value as ReskipOptObj)?.olderThanXDays === 'number'
  useEffect(() => {
    if (shouldTickOlderThanXDays) setIsOlderThanXTicked(true)
  }, [shouldTickOlderThanXDays])

  const renderOtherSection = ({ value, isOlderThanXTicked }) =>
    <Box ml={6} mt={2} css={{ position: 'relative' }}>
      <Checkbox
        label='from a differrent provider'
        checked={!!(value as ReskipOptObj)?.differentProvider}
        onChange={handleDifferentProviderToggle}
      />
      <Checkbox
        label={(
          <>
            <Box display='flex' alignItems='center' mt='-10px'>
              <Text>
                older than
              </Text>

              <NumberInput
                ml={2}
                value={(value as ReskipOptObj)?.olderThanXDays || ''}
                onChange={handleOlderThanXInputChange}
                width={60}
                disabled={!isOlderThanXTicked}

                // Clicking input shouldn't toggle checkbox
                onClick={ev => { ev.stopPropagation() }}
              />

              <Text ml={2}>
                days
              </Text>
            </Box>

            {isOlderThanXTicked && inputError && (
              <Box display='flex' color='red.500' alignItems='center' mt={1}>
                {inputError}
              </Box>
            )}
          </>
        )}
        checked={isOlderThanXTicked}
        onChange={handleOlderThanXToggle}
      />

      {radioValue !== 'other' && (
        <Box
          bg='rgba(255, 255, 255, .5)'
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }}
        />
      )}
    </Box>

  return (
    <RadioGroup
      value={radioValue}
      onChange={handleRadioChange}
      {...props}
    >
      <Radio value='reskipNone' label='Don’t reskip anything' />
      <Radio value='reskipAll' label='Reskip everything' color='red' />
      <Radio value='other' label='Reskip if previous skiptrace was:' color='yellow'/>
      {renderOtherSection({
        value: radioValue === 'other' ? value : lastOtherValuesRef.current,
        isOlderThanXTicked: radioValue === 'other' ? isOlderThanXTicked : lastIsOlderThanXTicked.current
      })}
    </RadioGroup>
  )
}

export default SkipOptions
