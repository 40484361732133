/* eslint "react/jsx-key": "warn", "eqeqeq": "warn" */
import React from 'react'

class EntityFieldProperties extends React.Component {
  onChange = (name, optionIndex, valueIndex) => {
    var v = this.props.properties[optionIndex].values[valueIndex].value

    this.props.onChange(name, v)
  };

  render () {
    var f = this.props.field
    var opts = this.props.properties.map(function (o, oi) {
      switch (o.type) {
        case 'radio':
          var value = f[o.name]
          value = !!value // boolean
          return (
            <div className='entity-field-option option-radio'>
              {o.values.map(function (ro, i) {
                return (
                  <div className='radio'>
                    <label>
                      <input type='radio' value={ro.value} checked={value == ro.value} onChange={this.onChange.bind(this, o.name, oi, i)}/>
                      {ro.label}
                    </label>
                  </div>
                )
              }, this)}
            </div>
          )
      }
    }, this)

    return (
      <div>{opts}</div>
    )
  }
}

export default EntityFieldProperties
