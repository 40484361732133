import React, { Component } from 'react'

import BigChart from './BigChart'

import BigFatalError from 'common/BigFatalError'
import DataContainer from './DataContainer'

import { fetchConversions } from '../actions'
import { getConversions } from '../selectors'

class SiteConversionChart extends Component {
  render () {
    return (
      <DataContainer
        load={() => fetchConversions(this.props.siteId)}
        selector={state => getConversions(state, this.props.siteId)}
        render={({ error, props }) => {
          if (error) return <BigFatalError />
          return <BigChart data={props.data} yKey='count' />
        }}
      />
    )
  }
}

SiteConversionChart.propTypes = {}
SiteConversionChart.defaultProps = {}

export default SiteConversionChart
