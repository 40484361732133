/* eslint "no-class-assign": "warn" */
import React from 'react'
import { selectors as leadSelectors } from '../index.js'
import LeadView from './LeadView.react'
import { update, updateGeo } from '../actions'
import { connect } from 'react-redux'

class LeadDetailsHandler extends React.Component {
  onGeocode = results => {
    const { match, leadId: maybeLeadId } = this.props
    const leadId = maybeLeadId || match.params.id
    this.props.updateGeo(leadId, results)
  };

  render () {
    return (
      <LeadView
        entityTemplate={this.props.entityTemplate}
        entityTemplateType={this.props.entityTemplateType}
        lead={this.props.lead}
        leadId={this.props.leadId}
        leadOwners={this.props.leadOwners}
        onUpdate={this.props.update}
        onGeocode={this.onGeocode}
      />
    )
  }
}

const mapStateToProps = (state, { match, leadId }) => {
  leadId = leadId || match.params.id
  var entityTemplateType = 'lead'
  var entityTemplate = leadSelectors.getEntityTemplate(state, entityTemplateType)
  const leadOwners = leadSelectors.getLeadOwners(state, leadId)
  return {
    lead: leadSelectors.getLead(state, leadId),
    entityTemplate,
    entityTemplateType,
    leadId,
    leadOwners
  }
}
const mapDispatchToProps = {
  update,
  updateGeo
}
LeadDetailsHandler = connect(mapStateToProps, mapDispatchToProps)(LeadDetailsHandler)
export default LeadDetailsHandler
