import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { minHeight } from 'styled-system'
import { Input, Box, Pill, Text, Link } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'

import TypingDialog from './components/TypingDialog'
import ListDialog from './components/ListDialog'

const EditLink = styled(Link)`
  cursor: pointer;
`

const PickerInput = styled(Input)`
  ${minHeight}
  padding-top: 0px;
  cursor: pointer;
`

const Placeholder = ({ text }) => <Text as='div' m={2} color='secondary'>{text}</Text>
Placeholder.defaultProps = {
  text: 'Click to edit'
}

class DialogPicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    dialogProps: PropTypes.object,
    data: PropTypes.array
  };

  static defaultProps = {
    dialogProps: {},
    width: 400,
    minHeight: 90,
    type: 'typing',
    adapter: {
      extractValue: v => v,
      extractLabel: v => v,
      get: v => v
    }
  };

  state = { open: false };

  open = () => this.setState({ open: true })
  onClose = () => this.setState({ open: false })

  onChange = value => {
    this.props.onChange(value)
  }

  render () {
    const {
      minHeight,
      type,
      data,
      value,
      onChange,
      dialogProps,
      adapter,
      ...props
    } = this.props

    const { open } = this.state

    const DialogComponent = type === 'typing' ? TypingDialog : ListDialog

    const workingValue = (value || []).map(adapter.get)

    return (
      <Box {...props}>
        <PickerInput as='div' mb={1} p={0} pb={2} minHeight={minHeight} onClick={this.open}>
          {
            workingValue.map(v => <Pill key={adapter.extractValue(v)} mt={2} ml={2} variant='secondary'>{adapter.extractLabel(v)}</Pill>)
          }
          { !workingValue.length && <Placeholder/> }

        </PickerInput>
        <EditLink onClick={this.open}><FontIcon icon='cog'/> Edit</EditLink>

        <DialogComponent
          open={open}
          onClose={this.onClose}
          onChange={this.onChange}
          initialValue={value}
          adapter={adapter}
          {...dialogProps}
        />
      </Box>
    )
  }
}

export default DialogPicker
