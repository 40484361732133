import { Avatar, Box, Caption, Divider, Link, ListItem, Menu, Position, Text } from '@realsoftworks/decor'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Link as RRLink } from 'react-router-dom'
import { selectors as userSelectors } from 'users'

import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import waitAndOpenIntercom from 'common/waitAndOpenIntercom'
import { checkModelPermission } from 'users/selectors'
import { RenderAfterDelay, SIDEBAR_WIDTH, useSidebarLogic } from './Menu/Sidebar'

const PersonalMenuListItem = ({ ...props }) => (
  <ListItem
    style={{ textDecoration: 'none', color: 'inherit' }}
    hoverColor='teal.200'
    {...props}
  />
)

const mapBusinessMenuProps = state => ({
  canAdminCompanies: checkModelPermission(state, 'admin', 'company'),
  canAdminUsers: checkModelPermission(state, 'admin', 'users')
})

const BusinessMenu = connect(mapBusinessMenuProps, null)(({
  canAdminCompanies,
  canAdminUsers,
  onClick,
  route,
  ...props
}) => {
  const LinkEl = route ? RRLink : 'a'
  if (canAdminCompanies)
    return <>
      <Divider bg='teal.200' />
      <Box pl={4} py={2}>
        <Caption fontSize='12px' color='teal.900'>Company Settings</Caption>
      </Box>
      <PersonalMenuListItem as={LinkEl} to='/billing' href={`${window.App.routes.genesis}/user/billing`} onClick={onClick}>
        Billing/Plans
      </PersonalMenuListItem>
      <PersonalMenuListItem as={LinkEl} to='/users/team' href={route ? '#' : '/users/team'} onClick={onClick}>
        Team Management
      </PersonalMenuListItem>
    </>

  if (canAdminUsers)
    return <>
      <Divider bg='teal.200' />
      <Box pl={4} py={2}>
        <Caption fontSize='12px' color='teal.900'>Company Settings</Caption>
      </Box>
      <PersonalMenuListItem as={LinkEl} to='/users/team' href={route ? '#' : '/users/team'} onClick={onClick}>
        Team Management
      </PersonalMenuListItem>
    </>

  return null
})

const mapPersonalMenuState = state =>
  ({ user: userSelectors.getCurrent(state) })
const withPersonalMenuState = connect(mapPersonalMenuState)
const PersonalMenu = withPersonalMenuState(({ user, route }) => {
  const { isExpanded, smDown } = useSidebarLogic()
  const boxref = React.useRef()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setOpen(false)
  }, [isExpanded])

  const openHelp = useCallback(ev => {
    ev.preventDefault()
    waitAndOpenIntercom()
  }, [])

  const profileProps = route
    ? { as: RRLink, to: '/users/settings' }
    : { as: 'a', href: '/users/settings' }

  const userInfoJsx = (
    <Text
      color='white'
      className='fade-in'
      fontSize={1}
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      Settings
      <Text display='inline-block' pl={2} pt='2px' fontSize={0}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Text>
    </Text>
  )

  return (
    <>
      <Menu
        target={boxref.current}
        position={Position.TOP_LEFT}
        open={open}
        onClose={() => setOpen(false)}
        minWidth={220}
        bg='teal.100'
      >
        <Box display='flex' alignItems='top' px={3} mt={4}>
          <Avatar width={48} height={48} name={`${user.firstname} ${user.lastname}`} src={user.avatar} mr={3} />
          <Box>
            <Text as='div'>{user.firstname} {user.lastname}</Text>
            {user.title && <Text as='div'>{user.title}</Text>}
            <Link {...profileProps} color='teal.500'>Edit Profile</Link>
          </Box>
        </Box>
        <BusinessMenu route={route} onClick={() => setOpen(false)} />
        <Divider bg='teal.200' />
        <PersonalMenuListItem as='a' href='#' onClick={openHelp}>
          Help
        </PersonalMenuListItem>
        <PersonalMenuListItem as='a' href='/logout'>
          Logout
        </PersonalMenuListItem>
      </Menu>

      <Box
        py={2}
        onClick={() => setOpen(true)}
        display='flex'
        alignItems='center'
        css='cursor: pointer; position: relative;'
      >
        <Box width={SIDEBAR_WIDTH} display='flex' justifyContent='center'>
          <Avatar
            height='32px'
            width='32px'
            name={`${user.firstname} ${user.lastname}`}
            src={user.avatar}
            fontSize={1}
          />
        </Box>

        {(isExpanded || smDown) && (smDown ? userInfoJsx : (
          <RenderAfterDelay delay={200}>{userInfoJsx}</RenderAfterDelay>
        ))}

        <Box ref={boxref} css='position: absolute; bottom: 0; right: 0;' />
      </Box>
    </>
  )
})

export default PersonalMenu
