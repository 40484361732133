import React from 'react'

import { Checkbox, Box } from '@realsoftworks/decor'

const PropertyTypeFilter = ({
  propertyTypes,
  propertyUseTypes: propertyTypesChecked = [],
  onChange
}) => {
  const checkAll = groupName => {
    const group = propertyTypes.types.groups[groupName]
    const isGroupAlreadyChecked = isGroupAllChecked(groupName)
    const checkedWithoutTheGroup = propertyTypesChecked
      .filter(t => !group.includes(t))

    return isGroupAlreadyChecked
      ? checkedWithoutTheGroup
      : [...checkedWithoutTheGroup, ...group]
  }

  const checkOne = type =>
    propertyTypesChecked.includes(type)
      ? propertyTypesChecked.filter(t => t !== type)
      : [...propertyTypesChecked, type]

  const isGroupAllChecked = groupName => {
    const group = propertyTypes.types.groups[groupName]
    const isAllAlreadyChecked = group
      .every(t => propertyTypesChecked.includes(t))

    return isAllAlreadyChecked
  }

  const areTypesReady = Object.keys(propertyTypes).length !== 0 && !propertyTypes.loading

  return (
    <Box display='flex'>
      {areTypesReady
        ? Object.keys(propertyTypes.types.groups).map(groupName => (
          <Box
            key={groupName}
            display='flex'
            flex='1'
            flexDirection='column'
            css='text-transform: capitalize;'
          >
            <Checkbox
              label={addAllPrefix(groupName)}
              checked={isGroupAllChecked(groupName)}
              onChange={(e, v) => onChange(null, checkAll(groupName))}
            />

            {propertyTypes.types.groups[groupName].map(type => (
              <Checkbox
                key={type}
                ml={5}
                label={propertyTypes.types.types[type].toLowerCase()}
                checked={propertyTypesChecked.includes(type)}
                onChange={(e, v) => onChange(null, checkOne(type))}
              />
            ))}
          </Box>
        ))

        : '--' }
    </Box>

  )
}

export default PropertyTypeFilter

const addAllPrefix = label =>
  (label.startsWith('All ') || label.startsWith('all '))
    ? label
    : `All ${label}`
