/* eslint "react/display-name": "warn" */

import React from 'react'
import TopBar, { TOP_BAR_HEIGHT } from './TopBar'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Box } from '@realsoftworks/decor'

export default () => (
  <Provider store={window.REDUX_STORE}>
    <Router>
      <Box height={TOP_BAR_HEIGHT}>
        <Box css={`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 9999;
        `}
        >
          <TopBar route={false} isPhpEmbed />
        </Box>
      </Box>
    </Router>
  </Provider>
)
