/* eslint "no-case-declarations": "warn" */
import { SEARCH_CONTACTS_SUCCESS, SEARCH_SUCCESS, TAG_CREATE_SUCCESS, TAG_UPDATE_SUCCESS, TAG_DELETE_SUCCESS, CONTACT_READ_SUCCESS, UPDATE_SUCCESS } from '../actions'
import get from 'lodash/object/get'
import merge from 'lodash/object/merge'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.tag) {
        const tags = action.payload.entities.tag
        return {
          ...state,
          tags
        }
      }
      return state
    case CONTACT_READ_SUCCESS:
    case SEARCH_CONTACTS_SUCCESS:
      const tag = get(action, 'payload.entities.tag')
      if (!tag || !action.payload.result) return state
      return {
        ...state,
        tags: merge({}, state.tags, tag)
      }

    case TAG_CREATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.tag && action.payload.result) {
        const id = action.payload.result
        const tag = action.payload.entities.tag[id]
        return {
          ...state,
          tags: {
            ...state.tags,
            [id]: tag
          }
        }
      }
      break
    case UPDATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.tag) {
        // let id = action.payload.result;
        const tags = action.payload.entities.tag
        const newState = {
          ...state,
          tags: {
            ...state.tags,
            ...tags
          }
        }
        return newState
      }
      break
    case TAG_UPDATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.tag && action.meta && action.meta.params && action.meta.params.id) {
        const contactId = action.meta.params.id
        const moddedTag = action.payload.entities.tag[contactId]
        const newState = {
          ...state,
          tags: {
            ...state.tags,
            [contactId]: {
              ...state.tags[contactId],
              ...moddedTag
            }
          }
        }
        return newState
      }
      return state
    case TAG_DELETE_SUCCESS:
      if (action.payload) {
        const deleteTag = action.payload
        const tags = Object.assign({}, state.tags)
        delete tags[deleteTag.id]
        return {
          ...state,
          tags
        }
      }
      return state
  }
  return state
}
