
var status = {
  active: 'active',
  active_contingent: 'active contingent',
  active_kick_out: 'active kick out',
  active_option_contract: 'active option contract',
  cancelled: 'cancelled',
  expired: 'expired',
  leased: 'leased',
  pending: 'pending',
  sold: 'sold',
  temp_off_market: 'temp off market',
  withdrawn: 'withdrawn',
  withdrawn_sublisting: 'withdrawn sublisting'
}

var colors = {
  active: '#40C3CB',
  cancelled: 'white',
  expired: 'white',
  pending: '#3778FE',
  sold: '#FE4D37',
  withdrawn: 'white',
  'withdrawn sublisting': 'white'
}

export default {
  status: status,
  colors: colors
}
