import { useEffect, useState } from 'react'
import logError from 'common/logError'

/**
 * Returns true on ref element mouseenter, false on mouseleave
 * react-hooks' useHoverDirty but with mouseenter/leave instead of mouseover/out
 */
const useMouseEnter = (ref, enabled = true) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (typeof ref !== 'object' || typeof ref.current === 'undefined')
      logError(new Error('useMouseEnter expects a single ref argument.'))

    const onMouseEnter = () => setValue(true)
    const onMouseLeave = () => setValue(false)

    if (enabled && ref && ref.current) {
      ref.current.addEventListener('mouseenter', onMouseEnter)
      ref.current.addEventListener('mouseleave', onMouseLeave)
    }

    // fixes react-hooks/exhaustive-deps warning about stale ref elements
    const { current } = ref

    return () => {
      if (enabled && current) {
        current.removeEventListener('mouseenter', onMouseEnter)
        current.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [enabled, ref])

  return value
}

export default useMouseEnter
