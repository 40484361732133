
var StoreBound = function (Store) {
  var Stores = Array.prototype.slice.call(arguments)

  return {
    componentDidMount: function () {
      Stores.forEach(function (s) {
        s.addChangeListener(this._onChange)
      }, this)
    },

    componentWillUnmount: function () {
      Stores.forEach(function (s) {
        s.removeChangeListener(this._onChange)
      }, this)
    },

    _onChange: function () {
      this.setState(this.getStateFromStore())
    },

    getInitialState: function () {
      return this.getStateFromStore()
    }
  }
}

export default StoreBound
