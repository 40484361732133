/* eslint "no-caller": "warn" */
import React from 'react'
import Transition from 'react-transition-group/Transition'
import styled from 'styled-components'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Icon } from '@realsoftworks/decor'

const Container = styled(Box)`
  transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
`

const ChevronIcon = styled(Icon)`
  transition: transform 250ms linear;
  ${props => props.open ? 'transform: rotate(-90deg);' : ''}
`

const CollapseChevron = ({ open, ...props }) => (
  <ChevronIcon open={open} {...props}>
    <FontAwesomeIcon icon={faChevronDown}/>
  </ChevronIcon>
)

class Collapsable extends React.Component {
  static defaultProps = {
    height: 'auto'
  };

  box = React.createRef();

  componentDidMount = () => {
    if (!this.props.open)
      this.box.current.style.height = '0px'
  }

  onEnter = () => {
    const element = this.box.current
    const h = element.scrollHeight

    element.style.height = `${h}px`
    element.addEventListener('transitioned', function (e) {
      element.removeEventListener('transitioned', arguments.callee)
      element.style.height = null
    })
  }

  onExit = () => {
    const element = this.box.current
    const h = element.scrollHeight
    const t = element.style.transition
    element.style.transition = ''

    requestAnimationFrame(function () {
      element.style.height = `${h}px`
      element.style.transition = t

      requestAnimationFrame(function () {
        element.style.height = '0px'
      })
    })
  }

  render () {
    const { open, ...props } = this.props

    return (
      <Transition
        in={open}
        onEnter={this.onEnter}
        onExit={this.onExit}
        timeout={250}
      >
        <Container ref={this.box} {...props}/>
      </Transition>
    )
  }
}

Collapsable.Chevron = CollapseChevron

export default Collapsable
