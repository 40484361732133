import React from 'react'
import PropTypes from 'prop-types'
import GoogleMap from 'google-map-react'
import { convertCoords, CoordShape, StreetviewShape } from '../util'
import { MapProvider, mapContextInitValue } from './MapContext'
import googleMapLoader from 'common/mapLoader'

const getBounds = markers => {
  var bounds = new google.maps.LatLngBounds()
  markers.forEach(c => {
    const { lat, lng } = c.props
    bounds.extend(new google.maps.LatLng({ lat, lng }))
  })

  return {
    ne: bounds.getNorthEast().toJSON(),
    sw: bounds.getSouthWest().toJSON(),
    bounds
  }
}

class Map extends React.Component {
  state = {
    mapContextValue: mapContextInitValue
  };

  onGoogleApiLoaded = ({ map, maps }) => {
    this.setState({
      mapContextValue: {
        mapApi: { map, maps }
      }
    })

    if (this.props.defaultStreetview || this.props.streetview)
      this.setupStreetview()

    if (this.props.fit) {
      const bounds = getBounds(React.Children.toArray(this.props.children))
      map.fitBounds(bounds.bounds)
    }
  }

  setupStreetview () {
    const { streetview, defaultStreetview } = this.props
    const { map } = this.state.mapContextValue.mapApi

    const baseProps = streetview || defaultStreetview

    const opts = {
      enableCloseButton: !streetview,
      position: convertCoords(baseProps.position)
    }

    const pov = {
      heading: baseProps.heading,
      pitch: baseProps.pitch || 0,
      zoom: baseProps.zoom || 1
    }

    const panorama = map.getStreetView()
    panorama.setOptions(opts)
    panorama.setPov(pov)
    panorama.setVisible(true)
  }

  componentWillUnmount () {
    this.setState({ mapApi: null })
  }

  render () {
    const { defaultCenter, streetview, streetviewControls, defaultStreetview, children, ...props } = this.props

    const transformed = {
      ...props,
      center: props.center ? convertCoords(props.center) : undefined,
      defaultCenter: defaultCenter ? convertCoords(defaultCenter) : undefined
    }

    const options = {
      mapTypeControl: true
    }

    if (streetviewControls)
      options.streetViewControl = true

    // try to make it so we can use 'lon' instead of 'lng'
    // const markers = React.Children.map(children, child => React.cloneElement(child, {lng: child.props.lng || child.props.lon}));

    return (
      <MapProvider value={this.state.mapContextValue}>
        <GoogleMap
          googleMapLoader={googleMapLoader}
          options={options}
          onGoogleApiLoaded={this.onGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
          {...transformed}
        >
          {children}
        </GoogleMap>
      </MapProvider>
    )
  }
}

Map.defaultProps = {
  streetviewControls: false
}

Map.propTypes = {
  defaultStreetview: StreetviewShape,
  center: CoordShape,
  defaultCenter: CoordShape,
  streetview: PropTypes.bool,
  streetviewControls: PropTypes.bool,
  mapOptions: PropTypes.object
}

export default Map
