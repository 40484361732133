/* eslint "react/display-name": "warn" */
import React from 'react'
import FullScreenLayout from './FullScreenLayout'
import DefaultLayout from './DefaultLayout'

export default ({ title, ...props }) => (
  <FullScreenLayout>
    <DefaultLayout {...props} />
  </FullScreenLayout>
)
