import { EXPORT_SUCCESS } from 'leadlists/actions'
import { takeEvery } from 'redux-saga'
import { put } from 'redux-saga/effects'
import { fetchLists, SKIPTRACE_POLL_FINISHED } from './actions'
import { fetchOrders } from 'marketing/actions'

export default function * listsSaga () {
  yield takeEvery(EXPORT_SUCCESS, function * () {
    yield put(fetchLists())
  })

  yield takeEvery(SKIPTRACE_POLL_FINISHED, function * (action) {
    yield put(fetchOrders({ referenceId: action.meta.id }))
  })
}
