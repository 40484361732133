/* eslint 'react/display-name': 'warn' */
import React, { useEffect } from 'react'
import { BrowserRouter as BaseRouter, Route, Switch } from 'react-router-dom'
import App from './app'

import UpgradeHandler from 'billing/components/UpgradeHandler'
import DealAlertsHandler from 'dealalerts/RouteHandler'
import PremiumLeadsHandler from 'premiumleads/RouteHandler'
import ListsHandler from 'lists/RouteHandler'
import { RouteHandler as BillingHandler, RetrialPage } from 'billing'
import SitesHandler from 'sites/RouteHandler'
import TasksHandler from 'tasks/RouteHandler'
import ContactsHandler from 'contacts/RouteHandler'
import DriveForDollarsHandler from 'drivingfordollars/RouteHandler'
import OnboardingHandler from 'onboarding/RouteHandler'
import NotificationsHandler from 'notifications/RouteHandler'
import SearchHandler from 'search/RouteHandler'

import { Box } from '@realsoftworks/decor'

import { RouteHandler as LeadListsHandler } from 'leadlists'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import HomeRedirect from 'redirect/HomeRedirect'
import withRouteReq from './modules/redirect/withRouteReq'
import { ROUTES } from './const'
import { SIDEBAR_WIDTH } from './app/TopBar/Menu/Sidebar'
import { connect } from 'react-redux'
import { getCurrent } from 'users/selectors'

const withState = connect(state => ({ user: getCurrent(state) }))

const Router = withState(({ user }) => {
  const { smDown } = useBreakpoint()

  useEffect(() => {
    if (!user) window.location.replace('/logout')
  }, [])

  return user ? (
    <BaseRouter>
      <App>
        <Box pl={smDown ? 0 : SIDEBAR_WIDTH} className='print-remove-left-padding'>
          <Switch>
            <Route path='/homeredirect' component={HomeRedirect} />
            <Route path='/search' component={SearchHandlerWithReq} />
            <Route path='/dealalerts' component={DealAlertsHandlerWithReq} />
            <Route path='/regionalleads' component={PremiumLeadsHandlerWithReq} />
            <Route path='/leadlists' component={LeadListsHandlerWithReq} />
            <Route path='/sites' component={SitesHandlerWithReq} />
            <Route path='/lists' component={ListsHandlerWithReq} />
            <Route path='/tasks' component={TasksHandler} />
            <Route path='/contacts' component={ContactsHandler} />
            <Route path='/billing' component={BillingHandler} />
            <Route path='/retrial/:plan' component={RetrialPage} />
            <Route path='/upgrade' component={UpgradeHandler} />
            <Route path='/drivingfordollars' component={DriveForDollarsHandlerWithReq} />
            <Route path='/onboarding' component={OnboardingHandler} />
            <Route path='/notifications' component={NotificationsHandler} />
          </Switch>
        </Box>
      </App>
    </BaseRouter>
  ) : null
})

export default Router

const SearchHandlerWithReq = withRouteReq(ROUTES.SEARCH_HISTORY, SearchHandler)
const DealAlertsHandlerWithReq = withRouteReq(
  ROUTES.DEALALERTS,
  DealAlertsHandler
)
const PremiumLeadsHandlerWithReq = withRouteReq(
  ROUTES.REGIONAL_LEADS,
  PremiumLeadsHandler
)
const LeadListsHandlerWithReq = withRouteReq(
  ROUTES.LIST_BUILDER,
  LeadListsHandler
)
const SitesHandlerWithReq = withRouteReq(ROUTES.SITES, SitesHandler)
const ListsHandlerWithReq = withRouteReq(ROUTES.LIST_BUILDER, ListsHandler)
const DriveForDollarsHandlerWithReq = withRouteReq(
  ROUTES.DRIVING,
  DriveForDollarsHandler
)
