import { normalize } from 'normalizr'

/*
{
  types: [initial, success, failure],
  schema: normalizrSchema
  endpoint: url,
  method,
  test: (state) => return true if action should be executed
}

 */

import Ajax from '../core/Ajax'

import api from 'common/api'

export const CALL_API_LEGACY = 'middleware_call_api_legacy'

function callApi ({ endpoint, method = 'GET', data, schema }) {
  method = method.toUpperCase()

  if (method !== 'GET')
    data = JSON.stringify(data)

  return Ajax(endpoint, {
    method,
    data: data,
    dataType: 'json',
    contentType: 'application/json'
  })
    .then(result => {
      if (schema)
        result = normalize(result, schema)

      return result
    })
}

const actionWith = (action, data) => {
  const finalAction = Object.assign({}, action, data)
  delete finalAction[CALL_API_LEGACY]
  return finalAction
}

const getHandlers = (action, next) => {
  const apiProps = action[CALL_API_LEGACY]
  var startHandler, successHandler, failureHandler

  if (apiProps.types) {
    const [requestType, successType, failureType] = apiProps.types

    startHandler = () => next(actionWith(action, { type: requestType }))
    successHandler = payload => next(actionWith(action, { type: successType, payload }))
    failureHandler = payload => next(actionWith(action, { type: failureType, payload }))
  } else { startHandler = successHandler = failureHandler = () => {} }

  return [startHandler, successHandler, failureHandler]
}
export const CALL_API = 'middleware_call_api'

const callApiNew = async (dispatch, { url, body, method = 'get', types, meta, schema, payload = f => f }) => {
  if (types.start)
    dispatch({ type: types.start, meta })

  const result = await api[method.toLowerCase()](url, body)
  if (result.error && types.error) {
    dispatch({ type: types.error, payload: result.error, meta })
    return result
  }

  const transformed = schema ? normalize(result, schema) : payload(result)
  if (types.success)
    dispatch({ type: types.success, meta, payload: transformed })

  return transformed
}

export default store => next => action => {
  const newApiProps = action[CALL_API]
  if (newApiProps)
    return callApiNew(next, newApiProps)

  const apiProps = action[CALL_API_LEGACY]
  if (typeof apiProps === 'undefined')
    return next(action)

  var [startHandler, successHandler, failureHandler] = getHandlers(action, next)

  startHandler()

  var ret = callApi(apiProps).then(
    result => successHandler(result),
    error => {
      failureHandler(error)
      throw error
    }
  )

  return ret
}
