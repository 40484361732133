import { FETCH_CUSTOM_NEIGHBORS_SUCCESS, FETCH_CUSTOM_NEIGHBORS, FETCH_CUSTOM_NEIGHBORS_FAILURE } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_NEIGHBORS_SUCCESS:
      return {
        result: action.payload.items,
        fetchSource: state.fetchSource,
        loading: false
      }
    case FETCH_CUSTOM_NEIGHBORS:
      return {
        loading: true,
        fetchSource: action.meta.fs
      }
    case FETCH_CUSTOM_NEIGHBORS_FAILURE:
      return {
        loading: false
      }
  }
  return state
}
