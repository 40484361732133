import { spawn } from 'redux-saga/effects'

import { saga as mls } from 'mls'
import onboarding from 'onboarding/saga'
import marketing from 'marketing/saga'
import lists from 'lists/saga'
import analytics from './analytics'

export default function * () {
  yield spawn(mls)
  yield spawn(onboarding)
  yield spawn(marketing)
  yield spawn(lists)
  yield spawn(analytics)
}
