/* eslint "eqeqeq": "warn" */
import React, { Fragment } from 'react'
import moment from 'moment'
import { Box } from '@realsoftworks/decor'
import { Monetary } from 'common/format'

import MLSLogo from 'mls/components/Logo'

import ListingSlideshow from './ListingSlideshow'
import MLSNextListingLogo from './MLSNextListingLogo'

var styles = {
  fact: {
    marginRight: 15
  },
  label: {
    width: '120px'
  }
}

const getBedRenderer = factsfield => {
  if (factsfield.beds) {
    const { beds } = factsfield

    return v => `${v[beds.above]}+${v[beds.below]}`
  }

  return v => v.beds
}

export default class ListingDetail extends React.Component {
  static defaultProps = {
    showPhotos: true
  };

  constructor (props) {
    super(props)

    const factsField = props.fields.find(v => v.id == 'property_facts')
    this.renderBeds = getBedRenderer(factsField)
  }

  render () {
    const { listing, showPhotos } = this.props

    return (
      <Box className='listing-details'>
        <Box display='flex' alignItems='top' mx={-4} flexWrap='wrap'>

          {showPhotos &&
            <Box flex='1' mx={4} minWidth='300px'>
              <ListingSlideshow listing={listing}/>
            </Box>
          }
          <Box flex='1' mx={4} paddingTop={showPhotos ? 2 : 0}>
            <div>
              <label style={styles.label}>MLS:</label>
              <span style={styles.fact} className='fact'>{listing.mls}</span>
            </div>
            <div>
              <label style={styles.label}><span className='icon icon-building'/> Property:</label>
              <span style={styles.fact} className='fact'>{this.renderBeds(listing)} beds</span>
              <span style={styles.fact} className='fact'>{listing.baths} baths</span>
              <span style={styles.fact} className='fact'>{listing.sqft} sqft</span>
              <span style={styles.fact} className='fact'>Year {listing.year_built}</span>
            </div>
            <div className='broker-info'>
              <label style={styles.label}><span className='icon icon-money'/> Financials:</label>
              <div>
                <span style={styles.fact} className='fact'>List Price: <Monetary decimals={2}>{listing.list_price}</Monetary> </span>
                {(listing.list_price && listing.sqft) && <span style={styles.fact} className='fact'><Monetary decimals={2}>{listing.list_price / listing.sqft}</Monetary>/sqft </span>}
              </div>
              <div>
                <span style={styles.fact} className='fact'>
                  Sold Price: { listing.close_price
                    ? (
                      <Fragment>
                        <Monetary>{listing.close_price}</Monetary>
                        {' '}
                        ({(listing.close_price * 100 / listing.list_price).toFixed(1)}%)
                      </Fragment>
                    )
                    : '--'}
                </span>
                <span style={styles.fact} className='fact'>
                  {listing.sqft && <React.Fragment><Monetary decimals={2}>{listing.close_price / listing.sqft}</Monetary>/sqft</React.Fragment>}
                </span>
              </div>

              <label style={styles.label}></label>
              <span style={styles.fact} className='fact'>DOM: {listing.calculated_dom || listing.dom}</span>
              <span style={styles.fact} className='fact'>Sold Date: { listing.close_date ? (moment(listing.close_date).format('MM/DD/YYYY')) : '--'}</span>

            </div>
            <BrokerInfo listing={listing}/>
            <blockquote>
              <p>{listing.remarks}</p>
            </blockquote>
          </Box>

        </Box>

      </Box>
    )
  }
};

const renderField = val => val || '--'
const getDeprecatedAgent = listing => ({ name: listing.listing_agent_name, phone: listing.listing_agent_phone, email: listing.listing_agent_email })
const getDeprecatedOffice = listing => ({ name: listing.listing_office_name, phone: listing.listing_office_phone, email: listing.listing_office_email })

const BrokerInfo = ({ listing }) => {
  var agent = listing.listing_agent || getDeprecatedAgent(listing)
  var office = listing.listing_office || getDeprecatedOffice(listing)

  return (
    <div key='brokerinfo' className='broker-info'>
      <label style={styles.label}>Listing Office:</label>
      <span style={styles.fact} className='fact'>Agent: {renderField(agent.name)} <br/><span className='phone'>{renderField(agent.phone)}</span> <span className='email'>{renderField(agent.email)}</span><br/></span>
      <span style={styles.fact} className='fact'>Office: {renderField(office.name)} <br/><span className='phone'>{renderField(office.phone)}</span></span>
      <div className='broker-logo'>
        {listing.source === 'mls-next' && listing.organization_id ? (
          <MLSNextListingLogo organizationId={listing.organization_id} cmaId={listing.cma_id} />
        ) : listing.source !== 'mls-next' ? (
          <MLSLogo source={listing.source}/>
        ) : null}
      </div>
    </div>
  )
}
