/* eslint "react/no-unescaped-entities": "warn", "react/display-name": "warn" */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrinStars } from '@fortawesome/free-solid-svg-icons'
import { fetchGeneralDownloads } from 'lists/actions'

import { Dialog, Label, Input, Button, Heading, Icon, Card, Text } from '@realsoftworks/decor'
import LoadingIcon from 'common/LoadingIcon'
import { TrialText, TrialCta } from 'lists/components/ListDetail/components/TrialNotice'
import { connect } from 'react-redux'

const OverNotice = props => (
  <Card border='2px solid' borderColor='yellow.300' bg='yellow.100' p={5} {...props}>
    <Text color='yellow.900'>
      <div><strong>Too Many Records</strong></div>
      <p>
        Only the first 30,000 records will be saved to your list.
      </p>
      <p>
        After your list is finished being created, you can return to this search and use the "New Only" filter
        to get the next set of records.
      </p>
    </Text>
  </Card>
)

const withConnect = connect(null, { fetchGeneralDownloads })
export default withConnect(function NewListDialog ({
  onSaveClick,
  saving,
  listId,
  counts,
  fetchGeneralDownloads,
  type,
  ...props
}) {
  const [name, setName] = React.useState('')
  const save = () => onSaveClick(name)

  const count = counts ? (counts.total || counts) : null
  const over = count > 30000

  useEffect(() => {
    fetchGeneralDownloads({ type })
  }, [])

  let toolbar = null
  if (listId)
    toolbar = ({ close }) => (
      <>
        <Button variant='secondary' onClick={close}>Close</Button>
        <Button variant='secondary' ml={3} as={Link} to='/leadlists/'>New List</Button>
        <Button variant='primary' ml={3} as={Link} to={`/leadlists/${listId}`}>View List</Button>
        <TrialCta listId={type} ml={3} />
      </>
    )
  else
    toolbar = ({ close }) => (
      <>
        <Button onClick={close} disabled={saving}>Cancel</Button>
        <Button ml={3} variant='primary' disabled={saving || !name.length} onClick={save}>{saving ? <span><LoadingIcon /> Creating List...</span> : 'Create List'}</Button>
        <TrialCta listId={type} ml={3} />
      </>
    )

  let content = null
  if (listId)
    content = (
      <>
        <Heading size={4} display='block' textAlign='center' mb={5}>
          <Icon><FontAwesomeIcon icon={faGrinStars} /></Icon>
          {' '}Awesome!{' '}
          <Icon><FontAwesomeIcon icon={faGrinStars} /></Icon>
        </Heading>
        We're getting your list ready right now but you can head over and check it out now.
        <TrialText listId={type} fontSize={0} pt={4} />
      </>
    )
  else
    content = (
      <>
        {(over) && <OverNotice mb={4} />}
        <Label display='block'>Name</Label>
        <Input value={name} onChange={e => setName(e.target.value)} width='100%' />
        <TrialText listId={type} fontSize={0} pt={4} />
      </>
    )

  return (
    <Dialog
      showClose
      toolbar={toolbar}
      onBeforeClose={() => !saving}
      maxWidth={500}
      {...props}
    >
      {content}
    </Dialog>
  )
})
