import React from 'react'
import Progress from 'common/Progress'

const ImportProgressBar = ({ importProgress }) => {
  if (!importProgress)
    return null

  let percent = 100
  const calcPercent = importProgress.count / (importProgress.total || 30000) * 100
  percent = Math.floor(Math.max(10, calcPercent))

  if (percent >= 100)
    return null

  return (
    <Progress percent={percent}>
      Import {percent}%
    </Progress>
  )
}

export default ImportProgressBar
