/* eslint "react/no-find-dom-node": "warn", "react/no-is-mounted": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'

import './ripple.less'

// TODO use CSS
var Transitions = {

  easeOutFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  easeInOutFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',

  easeOut (duration, property, delay, easeFunction) {
    easeFunction = easeFunction || this.easeOutFunction

    if (property &&
      Object.prototype.toString.call(property) === '[object Array]') {
      let transitions = ''
      for (let i = 0; i < property.length; i++) {
        if (transitions) transitions += ','
        transitions += this.create(duration, property[i], delay, easeFunction)
      }

      return transitions
    } else { return this.create(duration, property, delay, easeFunction) }
  },

  create (duration, property, delay, easeFunction) {
    duration = duration || '450ms'
    property = property || 'all'
    delay = delay || '0ms'
    easeFunction = easeFunction || 'linear'

    return `${property} ${duration} ${easeFunction} ${delay}`
  }
}

var Ripple = createReactClass({
  displayName: 'Ripple',

  propTypes: {
    opacity: PropTypes.number
  },

  getDefaultProps () {
    return {
      opacity: 0.16
    }
  },

  componentWillAppear (callback) {
    this._initializeAnimation(callback)
  },

  componentWillEnter (callback) {
    this._initializeAnimation(callback)
  },

  componentDidAppear () {
    this._animate()
  },

  componentDidEnter () {
    this._animate()
  },

  componentWillLeave (callback) {
    const style = ReactDOM.findDOMNode(this).style
    style.opacity = 0
    this.timeout = setTimeout(() => {
      if (this.isMounted()) callback()
    }, 2000)
  },

  _animate () {
    const style = ReactDOM.findDOMNode(this).style
    const transitionValue = `${Transitions.easeOut('2s', 'opacity')}, ${
      Transitions.easeOut('1s', 'transform')}`
    style.transition = transitionValue
    style.transform = 'scale(1)'
  },

  _initializeAnimation (callback) {
    const style = ReactDOM.findDOMNode(this).style
    style.opacity = this.props.opacity
    style.transform = 'scale(0)'
    setTimeout(() => {
      if (this.isMounted()) callback()
    }, 0)
  },

  render () {
    return (<div className='ripple' style={this.props.style} />)
  }
})

export default Ripple
