import React from 'react'

const Providers = ({ providers: [Phead, ...ptail], children }) =>
  ptail.length ? (
    <Phead>
      <Providers providers={ptail}>
        {children}
      </Providers>
    </Phead>
  ) : (
    <Phead>
      {children}
    </Phead>
  )

export default Providers
