/* eslint "react/display-name": "warn" */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LeadListsIndexHandler from './LeadListsIndexHandler'
import UploadLeadListHandler from './UploadLeadListHandler'

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/upload`} component={UploadLeadListHandler} />
    <Route path={`${match.path}/`} exact component={LeadListsIndexHandler} />
  </Switch>
)
