import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { func, bool, object, number } from 'prop-types'
import moment from 'moment'
import get from 'lodash/object/get'
import { Card, Select, Option } from '@realsoftworks/decor'
import { fetchMapDrives, fetchListsStats } from 'drivingfordollars/actions'
import { selectMapDrives, selectListsStats } from 'drivingfordollars/selectors'
import FetchHandler from 'common/FetchHandler'
import MapboxDrivesMap from 'drivingfordollars/components/DrivesMap/MapboxDrivesMap'
import { DRIVES } from 'drivingfordollars/propTypes'
import DrivesStats from '../DrivesStats'
import DrivesFetchProgress from './DrivesFetchProgress'

const DrivesMapSection = ({
  fetchMapDrives,
  fetchListsStats,
  drives,
  isLoading,
  error,
  leadsCount,
  isListsStatsLoading,
  afterDate: afterDateFromState,
  ...props
}) => {
  const {
    opts,
    selectedOpt,
    selectOptKey,
    hasLoaded
  } = drivesMapSectionHook({ fetchMapDrives, fetchListsStats, afterDateFromState })

  const filteredDrives = filterDrives({
    drives,
    afterDate: selectedOpt.value
  })

  return (
    <Card variant='basic' {...props}>
      <FetchHandler
        error={error}
        isLoading={!hasLoaded || isLoading}
        isEmpty={false}
        emptyText=''
      >
        <div style={{ position: 'relative' }}>
          <MapboxDrivesMap
            drive={filteredDrives}
            isDrivingForDollarsDashboard={true}
            borderRadius={[4, 0, 0, 4]}
          />

          <div style={{ position: 'absolute', top: 10, left: 10 }}>
            <Select
              placeholder='Pick time frame'
              value={selectedOpt.key}
              onChange={ev => { selectOptKey(ev.target.value) }}
            >
              {opts.map(opt => (
                <Option key={opt.key} value={opt.key}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {hasLoaded && <DrivesFetchProgress />}

        {hasLoaded && (
          <DrivesStats p={5} drives={filteredDrives} leadsCount={leadsCount} />
        )}
      </FetchHandler>
    </Card>
  )
}

DrivesMapSection.propTypes = {
  fetchMapDrives: func.isRequired,
  drives: DRIVES,
  isLoading: bool.isRequired,
  error: object,
  leadsCount: number,
  isListsStatsLoading: bool.isRequired
}

const withState = connect(
  state => {
    const { value, isLoading, error, afterDate } = selectMapDrives(state)
    const drives = Object.values(value)

    const { value: listsStats, isLoading: isListsStatsLoading } = selectListsStats(state)
    const leadsCount = get(listsStats, 'sources.drive')

    return {
      drives,
      isLoading,
      error,
      leadsCount,
      isListsStatsLoading,
      afterDate
    }
  },
  { fetchMapDrives, fetchListsStats }
)

export default withState(DrivesMapSection)

const drivesMapSectionHook = ({ fetchMapDrives, fetchListsStats, afterDateFromState }) => {
  const [opts] = useState(createDateOptions())
  const [selectedOptKey, selectOptKey] = useState(opts[0].key)
  const [hasLoaded, setHasLoaded] = useState(false)
  const selectedOpt = opts.find(o => o.key === selectedOptKey)

  useEffect(() => {
    (async () => {
      fetchListsStats({ afterDate: selectedOpt.value })
      // Refetch drives if the new after date filter is earlier than previous
      const newAfterDate = selectedOpt.value
      const isExistingAfterDateEarlier = afterDateFromState &&
        newAfterDate &&
        moment(newAfterDate).isSameOrAfter(afterDateFromState)

      // meaning previous fetch's result should suffice
      if (!isExistingAfterDateEarlier)
        await fetchMapDrives({ afterDate: selectedOpt.value })

      setHasLoaded(true)
    })().catch(() => {})
  }, [selectedOpt])

  return { opts, selectedOpt, selectOptKey, hasLoaded }
}

const filterDrives = ({ drives, afterDate }) =>
  drives.filter(d => moment(d.createdAt).isSameOrAfter(afterDate))

const createDateOptions = () =>
  [
    {
      label: '3 months',
      key: '3mo',
      value: moment().subtract(3, 'months').startOf('day').toDate()
    },
    {
      label: '6 months',
      key: '6mo',
      value: moment().subtract(6, 'months').startOf('day').toDate()
    },
    {
      label: '12 months',
      key: '12mo',
      value: moment().subtract(1, 'years').startOf('day').toDate()
    },
    {
      label: 'All',
      key: 'all',
      value: new Date(0) // Unix Epoch (a very long time ago)
    }
  ]
