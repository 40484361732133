import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducer'
import createSagaMiddleware, { END } from 'redux-saga'

import apiMiddleware from './middleware/api'
import analyticsMiddleware from './middleware/analytics'

export default function configureStore (initialState) {
  const composeEnhancers = (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        analyticsMiddleware,
        apiMiddleware,
        thunkMiddleware
      )
    )
  )

  store.runSaga = sagaMiddleware.run
  store.close = () => store.dispatch(END)

  return store
}
