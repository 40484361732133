import { Box } from '@realsoftworks/decor'
import styled from 'styled-components'
import { SIDEBAR_WIDTH } from '../../../app/TopBar/Menu/Sidebar'

const FullScreenLayout = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  /* This padding is here to take account for the sidebar */
  padding-left: ${SIDEBAR_WIDTH}px;

  @media screen and (max-width: 40rem) {
    padding-left: 0;
  }

  @media print {
    position: static;
    display: block;
    padding-left: 0;
  }
`

export default FullScreenLayout
