import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { array, bool, object, func } from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { Card, Box, Button, Menu, Position, Text, Tooltip } from '@realsoftworks/decor'
import LoadingIcon from 'common/LoadingIcon'
import FetchHandler from 'common/FetchHandler'
import NotificationsList from './NotificationsList'
import { WIDGET_LIMIT } from '../const'
import { pollNotifs } from '../actions'
import { selectNotifsInfo } from '../selectors'

const NotificationsWidget = ({ notifications, isLoadingForFirstTime, error, pollNotifs }) => {
  useEffect(() => {
    pollNotifs()
  }, [])

  const buttonRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const unreadNotifs = notifications.filter(n => !n.values.read)
  const hasUnread = unreadNotifs.length > 0
  const hasMore = notifications.length === WIDGET_LIMIT

  return (
    <>
      <Box my='-6px' py='6px' height='calc(100% + 12px)' ref={buttonRef}>
        <Tooltip content='Notifications'>
          <Box
            display='flex'
            alignItems='center'
            px={3}
            height='100%'
            bg={hasUnread ? 'red.400' : 'transparent'}
            onClick={() => { setIsOpen(s => !s) }}
            css='cursor: pointer'
          >
            <Text fontSize='24px' color='white'>
              <FontAwesomeIcon icon={faBell} />
            </Text>

            {hasUnread && (
              <Card
                display='flex'
                alignItems='center'
                justifyContent='center'
                ml='-5px'
                mt='-9px'
                borderRadius={8}
                width={16}
                height={16}
                bg='white'
              >
                <Text color='red.400' css='font-size: 10px'>
                  {unreadNotifs.length}
                </Text>
              </Card>
            )}
          </Box>
        </Tooltip>
        <Menu
          target={buttonRef.current}
          open={isOpen}
          onClose={() => { setIsOpen(false) }}
          width={420}
          position={Position.BOTTOM_RIGHT}
        >
          <Box maxHeight='66vh' display='flex' flexDirection='column' alignItems='scroll'>
            <Box pt={1} pb={3} px={3}>
              <Text fontSize={1}>Notifications</Text>
            </Box>

            <FetchHandler
              error={error}
              isLoading={isLoadingForFirstTime}
              isEmpty={!notifications.length}
              renderLoading={() => (
                <Box display='flex' py={4} justifyContent='center'>
                  <LoadingIcon size={2} />
                </Box>
              )}
              renderError={() => (
                <>
                  <Box flex-direction='column' alignItems='center'>
                    <i className='fa fa-cloud fa-stack-1x' />
                    <h2>Whoops - Our mistake!</h2>
                  </Box>
                </>
              )}
              renderEmpty={() => (<Box px={3} className='empty-text'>You have no notifications</Box>)}
            >
              <Card
                borderColor='neutral.200'
                borderTop='1px solid'
                borderBottom='1px solid'
                css={{ overflowY: 'auto' }}
                flex={1}
              >
                <NotificationsList
                  isSmall
                  borderRadius={hasMore ? undefined : [0, 4, 4, 0]}
                  notifications={notifications}
                  toggleAction={() => {}}
                />
              </Card>

              {hasMore && (
                <Box
                  display='flex'
                  justifyContent='center'
                  pt={2}
                >
                  <Button to='/notifications' as={Link} onClick={() => { setIsOpen(false) }}>
                    See All
                  </Button>
                </Box>
              )}
            </FetchHandler>
          </Box>
        </Menu>
      </Box>
    </>
  )
}

NotificationsWidget.propTypes = {
  notifications: array.isRequired,
  isLoadingForFirstTime: bool,
  error: object,
  pollNotifs: func.isRequired
}

const withState = connect(
  state => {
    const { value, isLoadingForFirstTime, error } = selectNotifsInfo(state)
    const notificationsArr = Object.values(value)
      .sort((a, b) =>
        +new Date(a.created) > +new Date(b.created) ? -1 : 1)
      .slice(0, WIDGET_LIMIT)

    return {
      notifications: notificationsArr,
      isLoadingForFirstTime,
      error
    }
  },
  { pollNotifs }
)

export default withState(NotificationsWidget)
