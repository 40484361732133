import { stringify } from 'qs'
import { Schema, arrayOf, normalize } from 'normalizr'
import moment from 'moment'
import * as api from 'common/api'
import { selectDrives, selectMapDrives } from './selectors'
import throwAny from 'common/util/throwAny'

import {
  DRIVES_ENDPOINT,
  LISTS_ENDPOINT,
  DRIVE_LEADS_ENDPOINT,
  DRIVES_STATS_ENDPOINT,
  LISTS_STATS_ENDPOINT
} from './const'
import ensureArray from 'common/util/ensureArray'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF_CONTENT } from 'const'
import * as Sentry from '@sentry/browser'

/* actionTypes */
export const FETCH_DRIVES = 'dfd/FETCH_DRIVES'
export const FETCH_DRIVES_SUCCESS = 'dfd/FETCH_DRIVES_SUCCESS'
export const FETCH_DRIVES_FAILED = 'dfd/FETCH_DRIVES_FAILED'

export const FETCH_LEADS_PER_DRIVE_SUCCESS = 'dfd/FETCH_LEADS_PER_DRIVE_SUCCESS'
export const FETCH_LEADS_PER_DRIVE_FAILED = 'dfd/FETCH_LEADS_PER_DRIVE_FAILED'

export const FETCH_MAP_DRIVES = 'dfd/FETCH_MAP_DRIVES'
export const FETCH_MAP_DRIVES_SUCCESS = 'dfd/FETCH_MAP_DRIVES_SUCCESS'
export const FETCH_MAP_DRIVES_FAILED = 'dfd/FETCH_MAP_DRIVES_FAILED'

export const FETCH_LISTS = 'dfd/FETCH_LISTS'
export const FETCH_LISTS_SUCCESS = 'dfd/FETCH_LISTS_SUCCESS'
export const FETCH_LISTS_FAILED = 'dfd/FETCH_LISTS_FAILED'

export const FETCH_LEADS = 'dfd/FETCH_LEADS'
export const FETCH_LEADS_SUCCESS = 'dfd/FETCH_LEADS_SUCCESS'
export const FETCH_LEADS_FAILED = 'dfd/FETCH_LEADS_FAILED'

export const EDIT_LIST = 'dfd/EDIT_LIST'
export const EDIT_LIST_SUCCESS = 'dfd/EDIT_LIST_SUCCESS'
export const EDIT_LIST_FAIL = 'dfd/EDLIST_IT_FAIL'

export const FETCH_DRIVES_STATS = 'dfd/FETCH_DRIVES_STATS'
export const FETCH_DRIVES_STATS_SUCCESS = 'dfd/FETCH_DRIVES_STATS_SUCCESS'
export const FETCH_DRIVES_STATS_FAILED = 'dfd/FETCH_DRIVES_STATS_FAILED'

export const FETCH_LISTS_STATS = 'dfd/FETCH_LISTS_STATS'
export const FETCH_LISTS_STATS_SUCCESS = 'dfd/FETCH_LISTS_STATS_SUCCESS'
export const FETCH_LISTS_STATS_FAILED = 'dfd/FETCH_LISTS_STATS_FAILED'

export const DOWNLOAD_LIST = 'dfd/DOWNLOAD_LIST'

const driveSchema = new Schema('drive')
const formatDrive = drive =>
  ({
    ...drive,
    createdAt: new Date(drive.createdAt),
    finishedAt: drive.finishedAt && new Date(drive.finishedAt),
    points: ensureArray(drive.points).map(({ latitude, longitude, ...point }) =>
      ({
        lat: latitude,
        lng: longitude,
        ...point
      }))
  })

/* fetchDrives */
export const fetchDrives = ({ limit, offset, afterDate: rawAfterDate }) =>
  async dispatch => {
    dispatch({ type: FETCH_DRIVES })

    try {
      const afterDate = rawAfterDate && formatDate(rawAfterDate)
      const query = stringify({ limit, offset, afterDate })
      const { count, items, error } = await api.get(`${DRIVES_ENDPOINT}?${query}`)
      if (error) throwAny(error)
      const formattedItems = items.map(formatDrive)
      const drives = normalize(formattedItems, arrayOf(driveSchema)).entities.drive || {}
      const payload = { value: drives, count }
      dispatch({ type: FETCH_DRIVES_SUCCESS, payload })
      dispatch(fetchLeadCountOfDrives({ ids: Object.keys(drives) }))
      return payload
    } catch (error) {
      dispatch({ type: FETCH_DRIVES_FAILED, payload: { error } })
    }
  }

const fetchLeadCountOfDrives = ({ ids }) => async dispatch => {
  try {
    const query = stringify({
      sourceType: 'drive',
      sourceIds: ids,
      limit: 0
    })

    const maybeLeads = await api.get(`${DRIVE_LEADS_ENDPOINT}?${query}`)
    const leads = Array.isArray(maybeLeads) ? maybeLeads : []
    const driveIdToCount = leads.reduce((acc, { sourceId, count } = {}) =>
      ({ ...acc, [sourceId]: count }), {})
    dispatch({ type: FETCH_LEADS_PER_DRIVE_SUCCESS, payload: { driveIdToCount } })
  } catch (error) {
    dispatch({ type: FETCH_LEADS_PER_DRIVE_FAILED, payload: { error } })
  }
}

/* fetchMapDrives */
export const fetchMapDrives = ({
  limit = 50,
  offset: rawOffset = 0,
  afterDate: rawAfterDate,
  isRecursion
}) =>
  async (dispatch, getState) => {
    const afterDate = rawAfterDate && formatDate(rawAfterDate)
    dispatch({ type: FETCH_MAP_DRIVES, payload: { isRecursion, afterDate } })
    let query

    try {
      const initMapDrivesState = selectMapDrives(getState())
      const initDrives = Object.values(initMapDrivesState?.value || {})
      const offset = Math.min(initDrives.length, rawOffset)

      query = stringify({ limit, offset, afterDate })
      const { count, items, error } = await api.get(`${DRIVES_ENDPOINT}?${query}`)

      // Check if the latest after date filter (from newer instance of fetch) is
      // earlier and has made this instance obsolete
      const latestMapDrivesState = selectMapDrives(getState())
      const isLatestAfterDateEarlier = latestMapDrivesState.afterDate &&
        afterDate &&
        moment(latestMapDrivesState.afterDate).isAfter(afterDate)
      const shouldAbort = isLatestAfterDateEarlier

      if (shouldAbort) return

      if (error) throwAny(error)

      const formattedItems = items.map(formatDrive)
      const drives = normalize(formattedItems, arrayOf(driveSchema)).entities.drive || {}

      const existingDrives = latestMapDrivesState.value
      const totalDrives = Object.values({ ...existingDrives, ...drives })
      const progress = totalDrives.length / count
      const shouldFetchMore = progress < 1

      const payload = {
        value: drives,
        count,
        ...(isRecursion || shouldFetchMore) && {
          isRecursion: true,
          progress
        }
      }

      dispatch({ type: FETCH_MAP_DRIVES_SUCCESS, payload })

      if (!shouldFetchMore) return

      // Fetch more as needed to have all of the drives there is for the user
      dispatch(fetchMapDrives({
        limit,
        offset: totalDrives.length,
        afterDate: rawAfterDate,
        isRecursion: true
      }))

      return payload
    } catch (error) {
      toast.error({
        title: 'Failed to fetch drives for the map',
        content: GENERIC_ERROR_NOTIF_CONTENT
      })

      Sentry.withScope(scope => {
        scope.setExtra('function', 'fetchMapDrives')
        scope.setExtra('file', 'app/webroot/js/modules/drivingfordollars/actions.js')
        Sentry.captureException(error)
      })

      dispatch({ type: FETCH_MAP_DRIVES_FAILED, payload: { error } })
    }
  }

/* fetchDrive */
export const fetchDrive = id => async (dispatch, getState) => {
  dispatch({ type: FETCH_DRIVES })
  const { count } = selectDrives(getState())

  try {
    const { error, ...drive } = await api.get(`${DRIVES_ENDPOINT}/${id}`)
    if (error) throwAny(error)
    const drives = { [id]: formatDrive(drive) }
    dispatch({ type: FETCH_DRIVES_SUCCESS, payload: { value: drives, count } })
  } catch (error) {
    dispatch({ type: FETCH_DRIVES_FAILED, payload: { error } })
  }
}

/* fetchDriveLeads */
export const fetchDriveLeads = ({
  id,
  offset,
  limit
}) => async dispatch => {
  dispatch({ type: FETCH_LEADS })

  try {
    const query = stringify({
      sourceType: 'drive',
      sourceId: id,
      limit,
      offset
    })

    const { items, error, count } = await api.get(`${DRIVE_LEADS_ENDPOINT}?${query}`)
    if (error) throwAny(error)
    const driveLeads = normalize(items, arrayOf(driveLeadSchema)).entities.driveLead || {}
    dispatch({ type: FETCH_LEADS_SUCCESS, payload: { value: driveLeads, count } })
  } catch (error) {
    dispatch({ type: FETCH_LEADS_FAILED, payload: { error } })
  }
}

const driveLeadSchema = new Schema('driveLead')

/* fetchLists */
export const fetchLists = ({ limit }) => async dispatch => {
  dispatch({ type: FETCH_LISTS })

  try {
    const query = stringify({ limit, type: 'drive' })
    const { items, error } = await api.get(`${LISTS_ENDPOINT}?${query}`)
    if (error) throwAny(error)
    const formattedItems = items.map(formatList)
    const lists = normalize(formattedItems, arrayOf(listSchema)).entities.list || {}
    dispatch({ type: FETCH_LISTS_SUCCESS, payload: { value: lists } })
  } catch (error) {
    dispatch({ type: FETCH_LISTS_FAILED, payload: { error } })
  }
}

const listSchema = new Schema('list')
const formatList = list =>
  ({ ...list, createdAt: new Date(list.createdAt) })

/* fetchListLeads */
export const fetchListLeads = ({
  id,
  offset,
  limit
}) => async dispatch => {
  dispatch({ type: FETCH_LEADS })

  try {
    const query = stringify({ limit, offset })
    const url = `${LISTS_ENDPOINT}/${id}/members?${query}`
    const { items, error, count } = await api.get(url)
    if (error) throwAny(error)
    const listLeads = normalize(items, arrayOf(listLeadSchema)).entities.listLead || {}
    dispatch({ type: FETCH_LEADS_SUCCESS, payload: { value: listLeads, count } })
  } catch (error) {
    dispatch({ type: FETCH_LEADS_FAILED, payload: { error } })
  }
}

const listLeadSchema = new Schema('listLead')

/* editList */
export const editList = ({ id, list }) => async dispatch => {
  dispatch({ type: EDIT_LIST })

  try {
    const { error, ...updatedList } = await api.put(`${LISTS_ENDPOINT}/${id}`, list)
    if (error) throwAny(error)
    dispatch({ type: EDIT_LIST_SUCCESS, payload: { id, list: updatedList } })
  } catch (error) {
    dispatch({ type: EDIT_LIST_FAIL, payload: { error } })
  }
}

/* fetchDrivesStats */
export const fetchDrivesStats = () => async dispatch => {
  dispatch({ type: FETCH_DRIVES_STATS })

  try {
    const { error, ...stats } = await api.get(DRIVES_STATS_ENDPOINT)
    if (error) throwAny(error)
    dispatch({ type: FETCH_DRIVES_STATS_SUCCESS, payload: { value: stats } })
  } catch (error) {
    dispatch({ type: FETCH_DRIVES_STATS_FAILED, payload: { error } })
  }
}

/* fetchListsStats */
export const fetchListsStats = ({ afterDate: rawAfterDate } = {}) => async dispatch => {
  dispatch({ type: FETCH_LISTS_STATS })

  try {
    const afterDate = rawAfterDate && formatDate(rawAfterDate)
    const query = stringify({ afterDate, type: 'drive' })
    const { error, ...stats } = await api.get(`${LISTS_STATS_ENDPOINT}?${query}`)

    if (error) throwAny(error)

    dispatch({ type: FETCH_LISTS_STATS_SUCCESS, payload: { value: stats } })
  } catch (error) {
    dispatch({ type: FETCH_LISTS_STATS_FAILED, payload: { error } })
  }
}

export const downloadList = id => async dispatch => {
  dispatch({ type: DOWNLOAD_LIST, meta: { id } })
  window.location = `${LISTS_ENDPOINT}/${id}/download`
}

/* Shared */
const formatDate = d => moment(d).format('YYYY-MM-DD')
