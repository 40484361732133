/* eslint "react/no-deprecated": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import pick from 'lodash/object/pick'

import Toolbar from 'decor/Toolbar'
import Card, { CardTitle } from 'common/Card'
import { Crumb } from 'common/Breadcrumbs'
import { Form, FormInput, Label, Field, Hint } from 'decor/form'
import { Button, Dialog } from '@realsoftworks/decor'

import LoadingButton from 'common/LoadingButton'
import BigLoader from 'common/BigLoader'

import CustomDomainForm from './settings/CustomDomainForm'

import SiteDomainAlert from './SiteDomainAlert'

import { fetchSettings, updateSettings, update, destroy, fetchDomains, updateSiteDomain } from '../actions'
import { getSettings, getSite, getDomains } from '../selectors'

class SitesSettingsHandler extends Component {
  state = {
    loading: true,
    deleteShown: false,
    loadingDomains: true
  };

  componentWillMount () {
    this.props.fetchDomains().then(() => this.setState({ loadingDomains: false }))
    this.props.fetchSettings(this.props.match.params.id).then(() => this.setState({ loading: false }))
  }

  saveSettings = values => this.props.updateSettings(this.props.match.params.id, values);

  saveDomain = values => this.props.updateSiteDomain(this.props.match.params.id, values);

  showDelete = () => this.setState({ deleteShown: true });
  hideDelete = () => this.setState({ deleteShown: false });

  destroySite = () => this.props.destroy(this.props.match.params.id).then(this.onDelete);

  onDelete = () => {
    this.props.history.replace('/sites')
  };

  render () {
    const { loading, loadingDomains } = this.state

    if (loading)
      return <BigLoader/>

    const { settings, site } = this.props

    if (!site)
      // must have just been deleted
      return <div/>

    return (
      <div className='site-detail-settings'>
        <Crumb title='Settings' tools={false}/>

        <DeleteDialog open={this.state.deleteShown} onClose={this.hideDelete} destroySite={this.destroySite} onDestroy={this.onDelete}/>

        <SiteDomainAlert site={site} configure={false}/>

        <div className='row'>
          <div className='col-md-6'>
            <Card mb={5}>
              <CardTitle>Domain &amp; Email</CardTitle>
              <CustomDomainForm domains={site.domains} loading={loadingDomains} save={this.saveDomain}/>
            </Card>

            <Card mb={5}>
              <CardTitle>Social Media</CardTitle>
              <SocialSettingsForm settings={settings} save={this.saveSettings}/>
            </Card>
          </div>
          <div className='col-md-6'>

            <Card mb={5}>
              <CardTitle>Settings</CardTitle>
              <SettingsForm settings={settings} save={this.saveSettings}/>
            </Card>

            <Card mb={5}>
              <CardTitle>Custom Scripts</CardTitle>
              <p>Add any custom scripts or styles to every page of your site. For best performance, put them in the footer unless setup requires them to go in the header</p>
              <CustomScriptsForm settings={settings} save={this.saveSettings}/>
            </Card>

            <div className='delete-area'>
              <Button variant='danger' onClick={this.showDelete}>Delete Site</Button>
            </div>

          </div>

        </div>

      </div>
    )
  }
}

class DeleteDialog extends Component {
  state = {};

  onDeleteClick = () => {
    this.setState({ deleting: true })
    this.props.destroySite().then(() => {
      this.setState({ deleting: false })
    })
  };

  render () {
    return (
      <Dialog
        toolbar={[
          <LoadingButton
            key='delete'
            loading={this.state.deleting}
            loadingText='Deleting...'
            onClick={this.onDeleteClick}>
              Yes, Delete
          </LoadingButton>
        ]}
        {...this.props}
      >
        <p>This will delete all of your site's posts, pages, comments and media. This action cannot be undone!</p>
        <p>Are you sure you want to proceed?</p>
      </Dialog>
    )
  }
}

class SettingsForm extends Component {
  constructor (props) {
    super(props)

    const value = pick(props.settings, [
      'propelio_company_name',
      'propelio_phone',
      'email',
      'propelio_market',
      'propelio_state'
    ])

    this.state = {
      value,
      saving: false
    }
  }

  onSubmit = values => {
    this.setState({ saving: true })
    this.props.save(values).then(() => this.setState({ saving: false }))
  };

  render () {
    return (
      <Form defaultValue={this.state.value} onSubmit={this.onSubmit}>
        <Field>
          <Label>Company Name</Label>
          <FormInput name='propelio_company_name'/>
        </Field>
        <Field>
          <Label>Phone Number</Label>
          <FormInput name='propelio_phone'/>
        </Field>
        <Field>
          <Label>Admin Email</Label>
          <FormInput name='email'/>
          <Hint>Used by contact forms</Hint>
        </Field>
        <Field>
          <Label>Primary Market</Label>
          <FormInput name='propelio_market'/>
          <Hint>A general name for the area you operate in (ex: "Dallas/Fort Worth"). "We buy houses all over [primary_market]"</Hint>
        </Field>
        <Field>
          <Label>State</Label>
          <FormInput name='propelio_state'/>
          <Hint>The state your market is in (ex: "Texas"). "We buy houses any where in the great state of [state]"</Hint>
        </Field>
        <Toolbar>
          <LoadingButton className='btn-success' type='submit' loadingText='Saving...' loading={this.state.saving}>Save</LoadingButton>
        </Toolbar>
      </Form>
    )
  }
}

class SocialSettingsForm extends Component {
  constructor (props) {
    super(props)

    const value = pick(props.settings, [
      'propelio_social_facebook',
      'propelio_social_twitter',
      'propelio_social_google',
      'propelio_social_pinterest',
      'propelio_social_instagram'
    ])

    this.state = {
      value,
      saving: false
    }
  }

  onSubmit = values => {
    this.setState({ saving: true })
    this.props.save(values).then(() => this.setState({ saving: false }))
  };

  render () {
    return (
      <Form defaultValue={this.state.value} onSubmit={this.onSubmit}>
        <Field>
          <Label>Facebook</Label>
          <FormInput name='propelio_social_facebook'/>
        </Field>
        <Field>
          <Label>Twitter</Label>
          <FormInput name='propelio_social_twitter'/>
        </Field>
        <Field>
          <Label>Google+</Label>
          <FormInput name='propelio_social_google'/>
        </Field>
        <Field>
          <Label>Pinterest</Label>
          <FormInput name='propelio_social_pinterest'/>
        </Field>
        <Field>
          <Label>Instagram</Label>
          <FormInput name='propelio_social_instagram'/>
        </Field>
        <Toolbar>
          <LoadingButton className='btn-success' type='submit' loadingText='Saving...' loading={this.state.saving}>Save</LoadingButton>
        </Toolbar>
      </Form>
    )
  }
}

class CustomScriptsForm extends Component {
  constructor (props) {
    super(props)

    const value = pick(props.settings, [
      'propelio_header_scripts',
      'propelio_footer_scripts'
    ])

    this.state = {
      value,
      saving: false
    }
  }

  onSubmit = values => {
    this.setState({ saving: true })
    this.props.save(values).then(() => this.setState({ saving: false }))
  };

  render () {
    return (
      <Form defaultValue={this.state.value} onSubmit={this.onSubmit}>
        <Field>
          <Label>Header Scripts</Label>
          <FormInput name='propelio_header_scripts' type='textarea' rows='8'/>
        </Field>
        <Field>
          <Label>Footer Scripts</Label>
          <FormInput name='propelio_footer_scripts' type='textarea' rows='8'/>
        </Field>
        <Toolbar>
          <LoadingButton className='btn-success' type='submit' loadingText='Saving...' loading={this.state.saving}>Save</LoadingButton>
        </Toolbar>
      </Form>
    )
  }
}

SitesSettingsHandler = connect(
  (state, { match }) => ({
    settings: getSettings(state, match.params.id),
    site: getSite(state, match.params.id),
    domains: getDomains(state)
  }),
  {
    fetchSettings,
    updateSettings,
    update,
    destroy,
    fetchDomains,
    updateSiteDomain
  }
)(SitesSettingsHandler)

export default SitesSettingsHandler
