import React, { createContext, useState } from 'react'
import api from 'common/api'
import pick from 'lodash/object/pick'

export interface IAddress {
  line1: string;
  city: string;
  state: string;
  zip: string;
}

interface IState {
  addresses: string[];
  data: Record<string, any>;
  getPropertyDetails: (addr: IAddress[]) => Promise<any[]>;
}

export const addressKeyFmt = ({ line1, city, state, zip }) => `${line1}-${city}-${state}-${zip}`

const noop: any = () => {}
const DEFAULT_STATE: IState = {
  addresses: [],
  data: {},
  getPropertyDetails: noop
} as IState

export const SkiptraceContext = createContext(DEFAULT_STATE)

export function SkiptraceProvider({ children }) {
  const [addresses, setAddresses] = useState([] as string[])
  const [data, setData] = useState({} as Record<string, any>)

  const getPropertyDetails = async (_addresses: IAddress[]) => {
    // remember which indexes already has cached data
    let cachedAddrIdx: number[] = []

    const filtered = _addresses.filter((addr, idx) => {
      if (addresses.includes(addressKeyFmt(addr))) {
        cachedAddrIdx.push(idx)
      } else {
        return true
      }
      return false
    })

    try {
      let response
      if (filtered.length > 0) {
        response = await api.post('/property/v4', {
          addresses: filtered.map(f => pick(f, ['line1', 'city', 'state', 'zip'])),
          include: ['skiptraces']
        })

        if (response && response.error) {
          return []
        }
      }

      let offset = 0
      let newAddresses: string[] = []
      let newData: Record<string, any> = {}
      const res = Array.from(new Array(_addresses.length)).reduce((ret, _, idx) => {
        const key = addressKeyFmt(_addresses[idx])
        if (cachedAddrIdx.includes(idx) && typeof data[key] !== 'undefined') {
          offset++
          return [...ret, data[key]]
        }
        newAddresses.push(key)
        setAddresses([...addresses, key])
        newData[key] = response[idx + offset]
        return [...ret, response[idx + offset]]
      }, [] as any[])

      setAddresses([...addresses, ...newAddresses])
      setData({ ...data, ...newData})
      return res
    } catch (e) {
      return []
    }
  }

  return (
    <SkiptraceContext.Provider value={{ addresses, data, getPropertyDetails }}>
      {children}
    </SkiptraceContext.Provider>
  )
}
