/* eslint "react/no-unescaped-entities": "warn", "react/no-deprecated": "warn" */
import React from 'react'
import LoadingButton from 'common/LoadingButton.js'

class EmailConfirm extends React.Component {
  render () {
    const {
      loading,
      onRequestEmail,
      email
    } = this.props

    return (
      <div className='cma-email-confirm'>
        <p>When you registered, we sent you an email with a link to confirm your email address. If you can't find it, we can send
        you another with the button below. <b>Please check all of your email folders including spam.</b> Sometimes it ends up in there :)</p>

        <p>Your email on file: <strong>{email}</strong>. If this is incorrect or you want to change it, please edit your profile in your Settings.</p>

        <LoadingButton
          variant='primary'
          className='btn btn-primary btn-lg'
          text='Re-Send Email'
          loadingText='Sending...'
          loading={loading}
          onClick={onRequestEmail}/>
      </div>
    )
  }

    startPolling = () => {
      this.stopPolling()
      this.timer = setInterval(this.doCheck, 5000)
    };

    stopPolling = () => {
      this.timer && clearInterval(this.timer)
    };

    doCheck = () => {
      this.props.checkEmail()
    };

    componentDidMount () {
      if (!this.props.confirmed)
        this.startPolling()
    }

    componentWillUnmount () {
      this.stopPolling()
    }

    componentWillUpdate (newProps) {
      if (newProps.confirmed)
        this.stopPolling()
    }
}

export default EmailConfirm
