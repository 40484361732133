
import * as Actions from '../actions'

export default (state = null, { payload, meta, type }) => {
  switch (type) {
    case Actions.VIEWED_SOURCE:
      return payload

    default: return state
  }
}
