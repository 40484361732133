/* eslint "eqeqeq": "warn", "react/no-deprecated": "warn" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'decor/Dialog'
import { Label } from 'decor/form'
import LoadingButton from 'common/LoadingButton'
import { Provider } from 'react-redux'
import SiteSelect from '../SiteSelect'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF } from 'const'

class DomainForwardingDialog extends Component {
  static contextTypes = {
    store: PropTypes.any
  };

  state = {
    type: 'site'
  };

  onTypeChange = e => this.setState({ type: e.target.value });
  onSiteChange = site => this.setState({ site });

  onSaveClick = () => {
    const { type } = this.state

    if (type == 'site') {
      const { site } = this.state
      const { domain } = this.props
      const siteId = site && site.id
      const domainId = domain && domain.id

      if (siteId && domainId)
        this.props.updateSiteDomain(siteId, domainId).then(() => {
          this.props.onRequestClose()
          this.setState({ loading: false })
        })
      else toast.error(GENERIC_ERROR_NOTIF)
    } else {
      const siteId = this.props.domain &&
        this.props.domain.site &&
        this.props.domain.site.id

      if (siteId)
        this.props.updateSiteDomain(siteId, null).then(() => {
          this.props.onRequestClose()
          this.setState({ loading: false })
        })
      else toast.error(GENERIC_ERROR_NOTIF)
    }

    this.setState({ loading: true })
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.domain && this.props.domain !== nextProps.domain) {
      const { domain } = nextProps
      const { site } = domain

      if (site)
        this.setState({ type: 'site', site: site.id })
    } else { this.setState({ type: 'site', site: null }) }
  }

  render () {
    const { ...rest } = this.props
    const { type, site } = this.state

    return (
      <Dialog
        {...rest}
        className='domain-forwarding-dialog'
        toolbar={[<LoadingButton key='loading' loading={this.state.loading} onClick={this.onSaveClick}>Save</LoadingButton>]}
      >
        <Provider store={this.context.store}>
          <div>
            <div className='section'>
              <div className='radio'>
                <Label>
                  <input type='radio' value='site' checked={type === 'site'} onChange={this.onTypeChange}/> Forward to Site
                </Label>
              </div>

              <div>
                <SiteSelect onChange={this.onSiteChange} disabled={type !== 'site'} value={site}/>
              </div>

            </div>
            {/* <div className="section">
              <Label>
                <input type="radio" value="domain" checked={type === 'domain'} onChange={this.onTypeChange}/> Forward to Domain
              </Label>

                <DomainSelect disabled={type !== 'domain'}/>
            </div> */}
            <div className='section'>
              <div className='radio'>
                <Label>
                  <input type='radio' value='none' checked={type === 'none'} onChange={this.onTypeChange}/> Clear Forwarding
                </Label>
              </div>
            </div>
          </div>
        </Provider>

      </Dialog>
    )
  }
}

DomainForwardingDialog.propTypes = {}
DomainForwardingDialog.defaultProps = {}

export default DomainForwardingDialog
