import { takeEvery } from 'redux-saga'

import { actions as leadlists } from 'leadlists'
import { actions as lists } from 'lists'

import analytics from 'common/analytics'

export default function * () {
  yield takeEvery(leadlists.FETCH, function * (action) {
    analytics.track('leadlists.search')
  })

  yield takeEvery(leadlists.EXPORT, function * (action) {
    analytics.track('leadlists.export')
  })

  yield takeEvery(lists.SUBMIT_SKIPTRACEORDER, function * (action) {
    analytics.track('lists.skiptrace', { provider: action.meta.params.provider })
  })
};
