import React, { useMemo, FC } from 'react'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import get from 'lodash/object/get'
import PropertyTypeFilter from './../PropertyTypeFilter'

type PropertyTypeFilters = {
  propertyTypes?: string[]
}

const PropertyTypeSection: FC<{
  filters: PropertyTypeFilters
  onChange: (filters: PropertyTypeFilters) => void
  openSection: string | null
  toggleSection: (section: string | null) => void
  propertyTypes: string[]
}> = ({
  filters,
  onChange,
  openSection,
  toggleSection,
  propertyTypes
}) => {
  const SECTION_KEY = 'PROP_TYPES'
  const isSectionOpen = openSection === SECTION_KEY

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    propertyTypes,
    get(filters, 'propertyTypes')
  ]

  return useMemo(() =>
    <AccordionFilterSection
      mt={5}
      heading='Property Type'
      contentContainerProps={{ pb: 4 }}
      toggleSection={toggleSection(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >
      <PropertyTypeFilter
        propertyTypes={propertyTypes}
        propertyUseTypes={get(filters, 'propertyTypes')}
        onChange={(_ev, propertyTypes) =>
          onChange({
            propertyTypes: (propertyTypes && propertyTypes.length)
              ? propertyTypes
              : undefined
          })}
      />
    </AccordionFilterSection>,
  rerenderDeps)
}

export default PropertyTypeSection
