import get from 'lodash/object/get'
import { createSelector } from 'reselect'
import { selectCompStates, selectCompState } from '../../reducers/selectors'
import { getMetadataForSource } from 'mls/selectors'
import buildColumns from 'mls/util/columns'
import unique from 'lodash/array/uniq'

const getCmaIdViaLeadId = (state, leadId) =>
  state.cma.byLeadId[leadId]?.items?.[0]

export const getCmaById = (state, cmaId) => state.entities.cma?.[cmaId] || null

export const getCma = (state, leadId) => {
  const cmaId = getCmaIdViaLeadId(state, leadId)
  const maybeCma = state.entities.cma?.[cmaId]
  return maybeCma || null
}

export const getCmaError = (state, leadId) => {
  if (!state.cma.byLeadId[leadId])
    return null

  return state.cma.byLeadId[leadId].error
}

export const isCmaListLoading = (state, leadId) => !state.cma.byLeadId[leadId] || state.cma.byLeadId[leadId].loading

export const isCmaSearching = (state, cmaId) => state.cma.detail[cmaId] && state.cma.detail[cmaId].searching

export const getSavingStates = (state, cmaId) => state.cma.detail[cmaId] ? state.cma.detail[cmaId].savingStates : {}

export const getCategoryList = (state, leadId, category) =>
  get(getCma(state, leadId), ['data', category])

export const getIsCategoryOverListLimit = (state, leadId, category) => {
  const count = get(getCma(state, leadId), [`${category}_count`])
  const categoryList = getCategoryList(state, leadId, category)
  const isOverLimit = count &&
    categoryList &&
    categoryList.length &&
    count > categoryList.length

  return isOverLimit
}

export const getCompStatusGroup = createSelector(
  getCategoryList,
  (_state, _leadId, _category, statuses) => statuses,
  (categoryList, statuses) =>
    categoryList.filter(i => statuses.includes(i.status))
)

const getCompStatesViaLeadId = (state, leadId) =>
  selectCompStates(state, getCmaIdViaLeadId(state, leadId))

export const getCompStateViaLeadId = (state, leadId, compId) =>
  selectCompState(state, getCmaIdViaLeadId(state, leadId), compId)

export const getCompStatusGroupWithState = createSelector(
  getCompStatusGroup,
  getCompStatesViaLeadId,
  (_state, _leadId, _category, statuses) => statuses,
  (comps, states) =>
    comps.map(c => ({ ...c, state: states[c.key] }))
)

export const getCmaMetaData = (state, leadId) =>
  getMetadataForSource(state, getCma(state, leadId)?.source)

export const getCompStatusGroupColumns = createSelector(
  getCma,
  getCmaMetaData,
  (cma, metadata) => {
    const params = typeof cma.params === 'string'
      ? JSON.parse(cma.params)
      : cma.params

    let fields = null
    let included = null

    if (metadata) {
      // Make sure fields contains a status field.
      fields = metadata.fields

      if (!fields.find(v => v.id === 'original_status')) {
        fields = [...metadata.fields]
        fields.splice(1, 0, { id: 'original_status' })
      }

      if (metadata.ui && metadata.ui.suggested_columns) {
        included = metadata.ui.suggested_columns

        // Add status column
        if (!included.includes('original_status')) {
          included = [...metadata.ui.suggested_columns]
          included.splice(1, 0, 'original_status')
        }

        /**
         * Enforce `lot_size_acres` column even when not included with
         * metadata.ui.suggested_columns
         */
        if (!included.includes('lot_size_acres')) {
          included = [...metadata.ui.suggested_columns]
          included.splice(7, 0, 'lot_size_acres')
        }
      }

      included = unique(included.concat(Object.keys(params)))
    }

    return buildColumns(fields, included)
  }
)
