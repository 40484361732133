/* eslint "no-case-declarations": "warn" */
import { SEARCH_MARKET_LEADS_SUCCESS, SEARCH_MARKET_LEADS, SEARCH_MARKET_LEADS_FAILURE, ADD_LEADS_TO_CUSTOM_PACKAGE, DELETE_LEADS_FROM_CUSTOM_PACKAGE } from '../actions'

import union from 'lodash/array/union'
import difference from 'lodash/array/difference'

export default (state = { error: null, leads: null }, action) => {
  switch (action.type) {
    case SEARCH_MARKET_LEADS:
      return {
        ...state,
        error: null
      }

    case SEARCH_MARKET_LEADS_FAILURE:
      return {
        ...state,
        leads: null,
        error: action.payload
      }

    case SEARCH_MARKET_LEADS_SUCCESS:

      const ids = action.payload.result.items
      return {
        error: null,
        ...state,
        leads: ids
      }
    case ADD_LEADS_TO_CUSTOM_PACKAGE:
      if (action.payload && action.meta && action.meta.params && action.meta.params.marketId) {
        const marketId = action.meta.params.marketId
        const selectedIds = action.payload
        const currentCustomPackage = state.customPackage && state.customPackage[marketId] ? state.customPackage[marketId] : []
        const ids = union(currentCustomPackage, selectedIds)
        return {
          ...state,
          customPackage: {
            ...state.customPackage,
            [marketId]: ids
          }
        }
      }
      return state
    case DELETE_LEADS_FROM_CUSTOM_PACKAGE:
      if (action.meta && action.meta.params && action.meta.params.marketId) {
        const marketId = action.meta.params.marketId
        if (action.meta.params.deleteAll) {
          return {
            ...state,
            customPackage: {
              ...state.customPackage,
              [marketId]: []
            }
          }
        } else if (action.payload) {
          const ids = difference(state.customPackage[marketId], action.payload)
          return {
            ...state,
            customPackage: {
              ...state.customPackage,
              [marketId]: ids
            }
          }
        }
      }
      return state
  }
  return state
}
