import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import GooglePlayIcon from './GooglePlayIcon'

import {
  Box,
  Heading,
  Card,
  Paragraph,
  Text
} from '@realsoftworks/decor'

const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.propeliomobile&hl=en_US'
const APPLE_URL = 'https://apps.apple.com/us/app/propelio-mobile/id1462188127'

const Empty = () => (
  <Container mx='auto' py={6}>
    <Heading size={4}>Driving for Dollars</Heading>

    <Card variant='padded' mt={2} borderTop='12px solid' borderColor='blue.600'>
      <Heading size={3} textAlign='center'>Hit the Road!</Heading>
      <Paragraph pt={5} fontSize={1}>
        Download the Propelio mobile app to unlock powerful tools on the go!
      </Paragraph>
      <Paragraph fontSize={1}>
        Create a Marketing List and Drive for Dollars using the app, then review your notes and download your leads here.
      </Paragraph>

      <Box display='flex' mx={-3} pt={5}>
        <Box justifyContent='center' px={3} width={1 / 2} as='a' href={APPLE_URL}>
          <AppStoreIcon width='100%' height='auto' />
          <Text
            display='block'
            textAlign='center'
            color='neutral.500'
            style={{ transform: 'translateY(-50%)' }}
          />
        </Box>
        <Box href={GOOGLE_PLAY_URL} px={3} width={1 / 2} as='a'>
          <GooglePlayIcon width='100%' height='auto' />
        </Box>
      </Box>
    </Card>
  </Container>
)

const Container = styled(Box)`
  max-width: 460px;
`

export default Empty
