import React from 'react'
import Marker from './Marker'
import styled from 'styled-components'
import { Card, Text } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CenterMarker = styled(Card)``

const HouseMarker = ({ markerDetails }) => (
  <Marker>
    {markerDetails === undefined
      ? <CenterMarker border='2px solid' borderColor='red.800' bg='red.800' borderRadius={100} width={40} height={40}>
        <Text as='div' textAlign='center' color='red.300' lineHeight='36px' fontSize={3}>
          <FontAwesomeIcon icon='home' />
        </Text>
      </CenterMarker>
      : <CenterMarker border='2px solid' borderColor='transparent' bg='transparent' borderRadius={100} width={32} height={39}>
        {markerDetails}
      </CenterMarker>}
  </Marker>
)

export default HouseMarker
