import Dispatcher from '../core/Dispatcher.js'
import ActivityContants from './ActivityConstants.js'

var ActivityActions = {
  comment: function (text, type, id) {
    Dispatcher.dispatch({ action: ActivityContants.COMMENT, model: { activity_data: text, type: 'comment', entity_type: type, entity_id: id } })
  },

  loadPrevious: function () {
    Dispatcher.dispatch({ action: ActivityContants.LOAD_PREVIOUS })
  }
}

export default ActivityActions
