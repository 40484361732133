import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { Box, Card, ToastContainer } from '@realsoftworks/decor'
import { Route } from 'react-router-dom'
import TopBar, { TOP_BAR_HEIGHT } from './TopBar'

import { DefaultLayout } from 'common/layouts'
import { RemoveDefaultLayoutProvider, useRemoveDefaultLayout } from 'common/util/useRemoveDefaultLayout'
import { SearchLogicProvider } from 'search/SearchContext'
import { HistoryProvider } from 'search/HistoryContext'
import { SkiptraceProvider } from 'search/SkiptraceContext'
import Providers from 'common/Providers'
import { SyncedMapListProvider } from 'search/SyncedMapListContext'

const TOAST_OFFSET = TOP_BAR_HEIGHT + 24

const AppBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const AppBar = styled(TopBar)``

const GlobalStyle = createGlobalStyle`

  html {
    height: 100%;
    font-size: 16px;
  }

  body {
    margin: 0px;
    height: 100%;
    // overflow: hidden;
    -webkit-overflow-scrolling: touch;
    font-family: "Asap", Arial, sans-serif;
    font-size: 16px;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
  }

  @media print {
    body, ${AppBox} {
      overflow-y: auto;
      display: block;
      position: static;
    }

    ${AppBar} {
      display: none;
    }

  }
`

const STATIC_ROUTES = [
  '/users/settings',
  '/users/team'
]

class App extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    query: PropTypes.object,
    match: PropTypes.object
  };

  render () {
    return (
      <React.Fragment>
        <Route path={STATIC_ROUTES} component={
          () => {
            window.location.reload()
            return null
          }
        }/>
        <GlobalStyle/>
        <ToastContainer offset={TOAST_OFFSET} />

        <Providers providers={[
          SearchLogicProvider,
          HistoryProvider,
          SyncedMapListProvider,
          SkiptraceProvider
        ]}>
          <AppBox
            display='flex'
            flexDirection='column'
            bg='neutral.100'
            className='print-white-bg print-noflex'
          >
            <AppBar/>
            <Card className='app-content-card print-noflex' flex='1' overflow='auto' display='flex' style={{ position: 'relative' }} flexDirection='column'>
              <RemoveDefaultLayoutProvider>
                <RemovableDefaultLayout>
                  { this.props.children }
                </RemovableDefaultLayout>
              </RemoveDefaultLayoutProvider>
            </Card>
          </AppBox>
        </Providers>
      </React.Fragment>
    )
  }
};

export default App

const RemovableDefaultLayout = ({ children, ...props }) => {
  const { isDefaultLayoutUsed } = useRemoveDefaultLayout()

  return isDefaultLayoutUsed ? (
    <DefaultLayout {...props}>{children}</DefaultLayout>
  ) : (
    children
  )
}
