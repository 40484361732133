import React, { useCallback, useState, useEffect } from 'react'
import { Box, Text, toast, NumberInput } from '@realsoftworks/decor'
import ensureNumber from 'common/util/ensureNumber'
import isNumber from 'common/util/isNumber'
import { INVALID_RANGE_INPUT_ERROR_NOTIF } from 'const'
import AdornedInput from '../AdornedInput'

const MonetaryRange = ({
  value: valueFromProps,
  onChange: onChangeFromProps,
  placeholder = '',
  ...props
}) => {
  const initValue = {
    min: ensureNumber(valueFromProps?.min, null),
    max: ensureNumber(valueFromProps?.max, null)
  }

  const [value, setValue] = useState(initValue)
  const [key, setKey] = useState(0)
  const [hasChanged, setHasChanged] = useState(false)

  // Clear internal value when value is cleared outside the component
  const hasValue = !!(typeof initValue?.min === 'number' ||
    typeof initValue?.max === 'number')
  useEffect(() => {
    if (!hasValue) {
      setValue({ min: null, max: null })
      setKey(k => k + 1) // Forces input rerender, and clears value
    }
  }, [hasValue])

  const onMinBlur = useCallback(() => {
    const invalidRange = typeof value.min === 'number' &&
      typeof value.max === 'number' &&
      value.max < value.min

    setHasChanged(false)

    if (invalidRange) {
      setValue(v => ({ ...v, min: initValue?.min }))
      setKey(k => k + 1) // Forces input rerender, and reverts invalid value
      toast.error(INVALID_RANGE_INPUT_ERROR_NOTIF)
    } else if (hasChanged) {
      onChangeFromProps(value)
    }
  }, [value, onChangeFromProps])

  const onMaxBlur = useCallback(() => {
    const invalidRange = typeof value.min === 'number' &&
      typeof value.max === 'number' &&
      value.max < value.min

    setHasChanged(false)

    if (invalidRange) {
      setValue(v => ({ ...v, max: initValue?.max }))
      setKey(k => k + 1) // Forces input rerender, and reverts invalid value
      toast.error(INVALID_RANGE_INPUT_ERROR_NOTIF)
    } else if (hasChanged) {
      onChangeFromProps(value)
    }
  }, [value, onChangeFromProps])

  const onChangeMin = useCallback(
    ev => {
      const newMin = ev.target.value
      setHasChanged(true)
      setValue({ ...value, min: isNumber(newMin) ? newMin : null })
    },
    [value, setValue]
  )

  const onChangeMax = useCallback(
    ev => {
      const newMax = ev.target.value
      setHasChanged(true)
      setValue({ ...value, max: isNumber(newMax) ? newMax : null })
    },
    [value, setValue]
  )

  return (
    <Box display='flex' alignItems='center' {...props}>
      <AdornedInput
        before='$'
        textAlign='right'
        inputComponent={NumberInput}
        key={`min:${key}`}
        value={value.min}
        onChange={onChangeMin}
        onBlur={onMinBlur}
        placeholder={placeholder}
        flex='1 1 auto'
        css='min-width: 0'
      />

      <Text as='div' px={2}>to</Text>

      <AdornedInput
        before='$'
        textAlign='right'
        inputComponent={NumberInput}
        key={`max:${key}`}
        value={value.max}
        onChange={onChangeMax}
        onBlur={onMaxBlur}
        placeholder={placeholder}
        flex='1 1 auto'
        css='min-width: 0'
      />
    </Box>
  )
}

export default MonetaryRange
