import { SEARCH_CONTACTS_SUCCESS, CONTACT_READ_SUCCESS, UPDATE_SUCCESS, COMPLETE_CREATE_SUCCESS } from '../actions'
import { SEARCH_LEADS_SUCCESS, LEADS_UPDATE_SUCCESS, READ_SUCCESS, UPDATE_CONTACT_ROLE_SUCCESS } from '../../leads/actions'

export default (state = { count: null, contacts: {} }, action) => {
  switch (action.type) {
    case SEARCH_LEADS_SUCCESS:
    case LEADS_UPDATE_SUCCESS:
    case READ_SUCCESS:
    case UPDATE_CONTACT_ROLE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.contact)
        return {
          ...state,
          contacts: {
            ...state.contacts,
            ...action.payload.entities.contact
          }
        }

      return state
    case SEARCH_CONTACTS_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.contacts)
        return {
          ...state,
          count: action.payload.result && action.payload.result.result && action.payload.result.result.count ? action.payload.result.result.count : state.count,
          contacts: {
            ...state.contacts,
            ...action.payload.entities.contacts
          }
        }

      break

    case CONTACT_READ_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.contacts && action.payload.result) {
        const contactId = action.payload.result
        const contact = action.payload.entities.contacts[contactId]
        return {
          ...state,
          contacts: {
            ...state.contacts,
            [contactId]: contact
          }
        }
      }
      break
    case UPDATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.contacts)
        return {
          ...state,
          contacts: {
            ...state.contacts,
            ...action.payload.entities.contacts
          }
        }

      break
    case COMPLETE_CREATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.contacts)
        return {
          ...state,
          contacts: {
            ...state.contacts,
            ...action.payload.entities.contacts
          }
        }

      break
  }
  return state
}
