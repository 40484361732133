/* eslint "react/display-name": "warn" */

import React from 'react'

export default () => (
  <div id='footer'>
    <ul>
      <li><a href='https://www.propelio.com/blog'>Blog</a></li>
      <li><a href='https://www.propelio.com/terms'>Terms of Service</a></li>
      <li><a href='/privacy'>Privacy Policy</a></li>
      <li>Copyright {new Date().getFullYear()} - Real Softworks, LLC</li>
    </ul>
  </div>
)
