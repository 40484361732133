import React from 'react'
import { connect } from 'react-redux'
import { Numeric } from 'common/format'
import { selectPrice, selectAvailable } from '../selectors'

const AvailableBalance = ({
  available,
  creditPrice,
  ...props
}) => <Numeric decimals={2} {...props}>{available * creditPrice / 100}</Numeric>

const mapStateToProps = (state, ownProps) => ({
  available: selectAvailable(state, ownProps.service),
  creditPrice: selectPrice(state)
})

export default connect(mapStateToProps)(AvailableBalance)
