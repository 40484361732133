
import where from 'lodash/collection/where'

var ListStore = function () {
  this.list = []
  this.map = {}
}

Object.assign(ListStore.prototype, {

  add: function (rec) {
    rec = Object.assign({}, rec)
    var id = rec.id
    if (!this.map[id]) {
      this.list.push(rec)
      rec.___index = this.list.length - 1
      this.map[id] = rec
    } else { this.mergeRecord(rec.id, rec) }
  },

  addAll: function (recs) {
    if (!Array.isArray(recs))
      recs = [recs]

    recs.forEach(this.add.bind(this))
  },

  setRecord: function (id, data) {
    var rec = this.get(id)
    var index = rec.___index
    data.___index = index
    this.map[id] = this.list[index] = data
  },

  removeAll: function () {
    this.map = {}
    this.list = []
  },

  mergeRecord: function (id, data) {
    var rec = this.get(id)
    Object.assign(rec, data)
  },

  get: function (id) {
    return this.map[id]
  },

  remove: function (id) {
    var rec = this.map[id]
    delete this.map[id]
    var index = rec.___index
    this.list.splice(index, 1)
    this.reindex()
  },

  reindex: function () {
    this.list.forEach(function (rec, index) {
      rec.___index = index
      this.map[rec.id] = rec
    }, this)
  },

  getAll: function () {
    return this.list
  },

  insertFirst: function (recs) {
    if (!Array.isArray(recs))
      recs = [recs]

    var existing = []

    recs.forEach(function (r) {
      var id = r.id
      if (!this.map[id])
        this.list.splice(0, 0, r)
      else
        existing.push(r)
    }, this)

    this.reindex()

    existing.forEach(function (r) {
      this.mergeRecord(r.id, r)
    }, this)
  },

  where: function (properties) {
    return where(this.list, properties)
  }
})
export default ListStore
