import { GET_RECORDS, GET_RECORDS_SUCCESS, GET_RECORDS_FAILED, UPDATE_RECORDS, UPDATE_RECORDS_SUCCESS, UPDATE_RECORDS_FAILED, USE_MX_PRESET, USE_MX_PRESET_SUCCESS, USE_MX_PRESET_FAILED } from './actions'
import set from 'common/util/set'
import flow from 'lodash/function/flow'

const initState = {
  status: {
    get: 'INIT',
    update: 'INIT'
  },
  error: {
    get: null,
    update: null
  },
  items: []
}

const dnsrecords = (state = initState, { type, payload = {} }) => {
  switch (type) {
    case GET_RECORDS:
      return flow(
        set(['status', 'get'], 'LOADING'),
        set(['error', 'get'], null)
      )(state)
    case GET_RECORDS_SUCCESS:
      return flow(
        set(['status', 'get'], 'SUCCESS'),
        set(['items'], payload.dnsRecords)
      )(state)
    case GET_RECORDS_FAILED:
      return flow(
        set(['status', 'get'], 'FAILED'),
        set(['error', 'get'], payload.error)
      )(state)

    case UPDATE_RECORDS:
      return flow(
        set(['status', 'update'], 'LOADING'),
        set(['error', 'update'], null)
      )(state)
    case UPDATE_RECORDS_SUCCESS:
      return flow(
        set(['status', 'update'], 'SUCCESS'),
        set(['items'], payload.dnsRecords)
      )(state)
    case UPDATE_RECORDS_FAILED:
      return flow(
        set(['status', 'update'], 'FAILED'),
        set(['error', 'update'], payload.error)
      )(state)

    case USE_MX_PRESET:
      return flow(
        set(['status', 'get'], 'SETTING_MX_PRESET'),
        set(['error', 'get'], null)
      )(state)
    case USE_MX_PRESET_SUCCESS:
      return flow(
        set(['status', 'get'], 'SUCCESS'),
        set(['items'], payload.dnsRecords)
      )(state)
    case USE_MX_PRESET_FAILED:
      return flow(
        // Status success because 'get'/'GET_RECORD' action is already a success
        // at this point and failing to use preset doesn't change that
        set(['status', 'get'], 'SUCCESS'),
        set(['error', 'get'], payload.error)
      )(state)

    default:
      return state
  }
}

export default dnsrecords
