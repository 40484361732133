import React from 'react'

import Comment from './Comment.react'

class CommentList extends React.Component {
  render () {
    return (
      <div className='comment-list'>
        {this.props.comments.map(function (item) {
          return <Comment key={item.id} comment={item} />
        }, this)}
      </div>
    )
  }
}

export default CommentList
