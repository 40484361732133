import { toast } from '@realsoftworks/decor'
import api from 'common/api'
import logError from 'common/logError'
import { selectIsReqMet } from 'common/selectors'
import { clearPrevious } from 'leadlists/actions'
import { create as createLead } from 'leads/actions'
import { getLeadsBySortOrder } from 'leads/selectors'
import qs from 'qs'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import formatLead from 'search/formatLead'
import { ROUTE_REQUIREMENTS } from '../../const'
import createHistoryEntryUrl from './createHistoryEntryUrl'
import { GENERIC_SEARCH_ERROR_NOTIF, VIEW_PROPERTY_ERROR_NOTIF } from './search.const'

/* Autocomplete Options */
const AC_OPTS = {
  /** Data fields that return when user selects a place */
  fields: ['address_component', 'geometry'],

  /** US only */
  componentRestrictions: { country: 'us' },

  /** Specific addres suggestions only */
  types: ['address'],

  /** We don't want to incur Google Places Details API billing */
  placeIdOnly: true
}

const noop = () => {}
noop.isNoop = true
const DEFAULT_CTX_VAL = {
  setInputRef: () => noop,
  isSearching: false,
  setIsSearching: noop,
  getCurrentListener: () => () => {},
  setCurrentListener: noop,
  searchScreenProps: {},
  setSearchScreenProps: noop,
  isInputReady: true
}
export const SearchContext = createContext(DEFAULT_CTX_VAL)

const SEARCH_REQ = ROUTE_REQUIREMENTS.SEARCH
const mapProps = state => ({
  isReqMet: selectIsReqMet(state, SEARCH_REQ.reqs)
})
const withConnect = connect(mapProps)

/**
 * Context provider that manages autocomplete input and search related states
 */
export const SearchLogicProvider = compose(
  withConnect,
  withRouter
)(({ isReqMet, location, history, ...p }) => {
  const [instances, setInstances] = useState({})
  const [isSearching, setIsSearching] = useState(false)
  const [isInputReady, setIsInputReady] = useState(true)
  const [searchScreenProps, setSearchScreenProps] =
    useState({ isSearchLogicInitiated: false })

  /* Set input as ready for users that can't search */
  useEffect(() => {
    if (!isReqMet) setIsInputReady(true)
  }, [!isReqMet])

  /* Keep ref to pathname for address select rediretion */
  const currRoute = location && location.pathname
  const currRouteRef = useRef(currRoute)
  useEffect(() => {
    currRouteRef.current = currRoute
  }, [currRoute])

  /* REPLACES event handler for autocomplete value change */
  const currentListenerRef = useRef(() => {})
  const setCurrentListener = useCallback(({ onChange }) => {
    currentListenerRef.current = onChange
  }, [])

  /* Initiates autocomplete ONCE when isInputRefSet is set */
  const setInputRef = instanceId => element =>
    setInstances(v => ({
      ...v,
      [instanceId]: { isInstanciated: false, element }
    }))

  async function fetchAddressFromAddressesApi (address) {
    try {
      setIsSearching(true)

      const query = qs.stringify({
        freeform: address,
        include: [
          'components',
          'details'
        ]
      })

      const { details, summary, components } = await api.get(`/addresses/v1/search?${query}`)

      const propertyAddress = {
        city: (summary && summary.city) || '',
        county: (details && details.countyName) || '',
        lat: (details && details.location && details.location.latitude) || '',
        line1: (summary && summary.line1) || '',
        lon: (details && details.location && details.location.longitude) || '',
        number: (components && components.primaryNumber) || '',
        state: (summary && summary.state) || '',
        street: components ? (`${components.streetPredirection ? `${components.streetPredirection}` : ''}${components.streetName ? ` ${components.streetName}` : ''}${components.streetSuffix ? ` ${components.streetSuffix} ` : ''}`) : '',
        zip: (summary && summary.postalCode) || ''
      }

      setIsSearching(false)

      if (details && summary && components)
        return propertyAddress
      else
        return false
    } catch (error) {
      setIsSearching(false)
      toast.error(GENERIC_SEARCH_ERROR_NOTIF)
      logError(error)
    }
  }

  useEffect(() => {
    Object.entries(instances)
      .map(([id, inst]) => {
        if (inst.isInstanciated) return

        setInstances(v => ({
          ...v,
          [id]: { ...inst, isInstanciated: true }
        }))
      })
  }, [instances])

  /* Render Provider with context value */
  const ctxVal = useMemo(() => ({
    setInputRef,
    isSearching,
    setIsSearching,
    getCurrentListener: () => currentListenerRef.current,
    setCurrentListener,
    searchScreenProps,
    setSearchScreenProps,
    isInputReady
  }), [
    setInstances,
    isSearching,
    setIsSearching,
    setCurrentListener,
    searchScreenProps,
    setSearchScreenProps,
    isInputReady
  ])

  return (
    <SearchContext.Provider value={ctxVal} {...p} />
  )
})

/**
 * Allows components to create search input bound to the context
 */
export const useSearchInputInitializer = ({ instanceId }) => {
  const {
    setInputRef,
    isSearching
  } = useContext(SearchContext)
  return {
    setInputRef: setInputRef(instanceId),
    isSearching
  }
}

/**
 * Allows components to control search input
 */
const useSearchInputController = () => {
  const {
    setCurrentListener,
    setIsSearching,
    isSearching,
    setSearchScreenProps
  } = useContext(SearchContext)

  return {
    setCurrentListener,
    setIsSearching,
    isSearching,
    setSearchScreenProps
  }
}

/**
 * Provides data needed by search screen
 */
export const useSearchScreenProps = () => {
  const { searchScreenProps, isInputReady } = useContext(SearchContext)

  return {
    isReady: isInputReady && searchScreenProps.isSearchLogicInitiated,
    ...searchScreenProps
  }
}

/**
 * (withSearchLogicConnect) Provides redux actions
 * necessary for `useSearchLogic()` hook
 */
const mapActions = {
  clearPrevProperties: clearPrevious,
  createLead
}

const mapState = state =>
  ({ leads: getLeadsBySortOrder(state) || [] })

export const withSearchLogicConnect = connect(mapState, mapActions)

/**
 * Prepares data consumed by Search Screen and its children.
 * Should only be used on ONE component at a time.
 */
export const useSearchLogic = ({
  leads,
  clearPrevProperties,
  createLead,
  history,
  location
}) => {
  /* Autocomplete input setup logic */
  const [address, setAddress] = useState(null)
  const [lead, setLead] = useState()

  const {
    setCurrentListener,
    setIsSearching,
    isSearching,
    setSearchScreenProps
  } = useSearchInputController()

  /* Keep ref to pathname for address select rediretion */
  const currRoute = location && location.pathname
  const currRouteRef = useRef(currRoute)
  useEffect(() => {
    currRouteRef.current = currRoute
  }, [currRoute])

  /* Does specific, general or neighbor search depending on input */
  /** @ARLEN This is what you're calling from SearchInput.js */
  const search = useCallback((address, propertyId) => {
    const type = getSearchType(address)
    const types = getSearchType.types

    /* Close currently open property details by redirecting */
    history.push('/search/history')

    if (type === types.SPECIFIC || propertyId) {
      clearPrevProperties()
      specificSearch({ address, propertyId })
    } else {
      toast.error(GENERIC_SEARCH_ERROR_NOTIF)
      logError('type unexpectedly matched no search type in handleAddrChange()')
    }
  }, [])

  /* Bind listener to search input change */
  useEffect(() => {
    setCurrentListener(({ onChange: search }))
  }, [])

  // Does single result search with address line1 and others.
  // Opts to neighbor search if search matches no result.
  const specificSearch = ({ address, query, propertyId }) => {
    setIsSearching(true)

    createLead({ ...address, propertyId }, { isSearchHistory: true })
      .then(leadResp => {
        if (leadResp.error) {
          const error = leadResp.error instanceof Error
            ? leadResp.error
            : new Error('Request failed')
          throw error
        }

        // Save the details in lead via setLead, so components can access them
        // through our hooks
        const leadRaw = getLeadFromResponse(leadResp)
        const lead = formatLead(leadRaw)
        setLead(lead)

        // Save address for our other functions' reference
        setAddress(address || lead?.address || lead?.propertyDetails?.address)

        // Now that we've loaded details, we can open the modal
        const historyItemUrl = createHistoryEntryUrl(lead.id)
        const extra = typeof query !== 'undefined' ? `?${query}` : ''
        history.push(historyItemUrl + extra)
      })
      .catch(e => {
        toast.error(GENERIC_SEARCH_ERROR_NOTIF)
        logError(e)
        // Close currently open property details by redirecting
        history.push('/search/history')
      })
      .finally(() => {
        setIsSearching(false)
      })
  }

  const getPropertyDetails = useCallback(({ leadId }) => {
    // Save lead and address for our other functions' and consumers' reference
    setLead({ id: leadId })

    const existingLead = leadId && leads.find(l => l.id === leadId)
    const formattedExistingLead = existingLead && formatLead(existingLead)
    const {
      hasFetchedAllDetails,
      hasPropertyDataInDb
    } = formattedExistingLead || {}

    /**
     * If either is true, just show what we already have and don't fetch:
     * - we already have the lead with full property details; or
     * - we already have the lead and we know it has no property details at all
     */
    const isFetchUnnecessary = formattedExistingLead &&
      (hasFetchedAllDetails || !hasPropertyDataInDb)

    if (isFetchUnnecessary) {
      setLead(formattedExistingLead)
      return Promise.resolve(formattedExistingLead)
    }

    return createLead({ leadId }, { isViewDetails: true })
      .then(leadResp => {
        if (leadResp.error) {
          const error = leadResp.error instanceof Error
            ? leadResp.error
            : new Error('Request failed')
          throw error
        }

        // Save the details in lead via setLead, so components can access them
        // through our hooks
        const leadRaw = getLeadFromResponse(leadResp)
        const lead = formatLead(leadRaw)
        const address = lead &&
          lead.propertyDetails &&
          lead.propertyDetails.address
        setLead(lead)
        setAddress(address)
        return lead
      })
      .catch(e => {
        // if failed to load properties data,
        //   close modal and notify an apology
        toast.error(VIEW_PROPERTY_ERROR_NOTIF)
        logError('error in getPropertyDetails():', e)

        // Close currently open property details by redirecting
        history.push('/search/history')
        throw e
      })
  }, [address, leads, history])

  /* Save search screen props to allow access from context consumer */
  useEffect(() =>
    setSearchScreenProps({
      isSearchLogicInitiated: true,
      isSearching,
      address,
      lead,
      propertyDetails: (lead && lead.propertyDetails) || null,
      getPropertyDetails,
      setLead,
      search,
      specificSearch
    }),
  [
    isSearching,
    address,
    lead,
    getPropertyDetails,
    setLead,
    search
  ])
}

const getSearchType = addr => {
  if (!addr) {
    logError('addr is unexpectedly falsy in getSearchType()')
    return
  }

  if (
    addr.line1 &&
    addr.city &&
    addr.state &&
    addr.zip
  ) return getSearchType.types.SPECIFIC

  return getSearchType.types.TOO_WIDE
}

getSearchType.types = {
  SPECIFIC: Symbol('getSearchType.type.SPECIFIC'),
  TOO_WIDE: Symbol('getSearchType.type.TOO_WIDE')
}

const getLeadFromResponse = resp => {
  const leadId = resp.result
  const leads = (resp &&
    resp.entities &&
    resp.entities.lead) ||
    {}
  const lead = leads[leadId]
  return lead
}
