
import React from 'react'

class ProgressBar extends React.Component {
  render () {
    return (
      <div className='progress'>
        <div className='progress-bar progress-bar-striped active' role='progressbar' aria-valuenow={this.props.value || 0} aria-valuemin='0' aria-valuemax='100' style={{ width: (this.props.value || 0) + '%' }}>
          <span className='sr-only'>{this.props.value || 0}% Complete</span>
        </div>
      </div>
    )
  }
}

export default ProgressBar
