/* eslint "react/no-string-refs": "warn", "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'
import FileInput from '../../../views/form/FileInput.react'
import types from '../types'

class UploadLeadListHandler extends React.Component {
  state = { loading: false, error: '', filename: null, finished: false };

  onError = error => {
    this.setState({ error: error, loading: false })
  };

  onFinish = data => {
    if (data.error)
      this.setState({ error: data.error })
    else
      this.setState({ finished: true })
  };

  reset = () => {
    this.setState({ finished: false })
  };

  onStart = () => {
    this.setState({ loading: true, error: '' })
  };

  onAdd = files => {
    const filename = files[0] ? files[0].name : ''
    this.setState({ filename })
  };

  onSelect = e => {
    this.setState({ selectValue: e.target.value })
  };

  render () {
    const options = Object.keys(types).reduce((ret, v) => {
      var value = v; var label = types[value]
      ret.push(<option key={value} value={value}>{label}</option>)
      return ret
    }, [])

    return (
      <div className='lead-import'>
        <h1>Lead List Import</h1>
        <select
          value={this.state.selectValue}
          onChange={this.onSelect}
        >
          <option value=''>select...</option>
          {options}
        </select>
        <p>Upload Lead List CSV file with a header row to start the import process.</p>

        {this.state.selectValue &&
        <a type='button' ref='button' className='upload-lead-list-btn btn btn-default btn-add' onClick={this.reset}>
          {this.state.filename ? 'File: ' + this.state.filename : 'Choose a file'}
          <FileInput
            autoUpload={true}
            onAdd={this.onAdd}
            onStart={this.onStart}
            onFinish={this.onFinish}
            onError={this.onError}
            paramName={this.state.selectValue}
            url='/property-leads/leads'
            ref='fileinput'
          />
        </a>
        }

        {this.state.finished && <p>Upload Successful!</p>}
        {this.state.error && <p>Upload Error: {this.state.error.message || 'Unknown error'}</p>}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})
const mapDispatchToProps = {}
UploadLeadListHandler = connect(mapStateToProps, mapDispatchToProps)(UploadLeadListHandler)
export default UploadLeadListHandler
