import React from 'react'

import './mask.less'
import classNames from 'classnames'

export default class Mask extends React.Component {
  render () {
    const { className, ...rest } = this.props
    return (
      <div className={classNames('decor', 'decorMask', className)} {...rest} />
    )
  }
}
