/* eslint "eqeqeq": "warn" */

import React from 'react'

var noop = e => { e.preventDefault() }

class PagingToolbar extends React.Component {
  render () {
    var count = this.props.count
    var offset = this.props.params.offset
    var limit = this.props.params.limit

    var child = ''

    // if we have enough records to make more than one page
    if (count > limit) {
      var pageCount = Math.ceil(count / limit)
      var hidden = (pageCount <= 1)
      var currentPage = offset / limit

      var maxPages = 4
      var startPage = Math.max(0, Math.floor(currentPage - maxPages))
      var endPage = Math.min(pageCount, Math.floor(currentPage + maxPages + 1))

      if (startPage == 0)
        endPage = Math.min(pageCount, 2 * maxPages + 1)

      if (endPage == pageCount - 1)
        startPage = Math.max(0, endPage - (2 * maxPages + 1))

      var pages = []
      for (var i = startPage; i < endPage; i++) {
        var isCurrent = (i == currentPage)
        pages.push(
          <li key={i} className={isCurrent ? 'active' : ''}>
            <a href={'#' + i} onClick={!isCurrent ? this.onPageClick : noop }>{i + 1}</a>
          </li>
        )
      }

      if (startPage != 0)
        pages.splice(0, 0, <li key='spacer1'><span>...</span></li>)

      if (endPage != pageCount)
        pages.splice(pages.length, 0, <li key='spacer2'><span>...</span></li>)

      child = (
        <nav className={hidden ? 'hide' : ''}>
          <ul className='pagination'>
            <li key='first'><a href='#0' onClick={this.onPageClick}>&laquo;</a></li>
            <li key='prev'>{currentPage != 0 ? <a href={'#' + (currentPage - 1)} onClick={this.onPageClick}>&lt;</a> : <span>&lt;</span>}</li>
            {pages}
            <li key='next'>{currentPage != pageCount - 1 ? <a href={'#' + (currentPage + 1)} onClick={this.onPageClick}>&gt;</a> : <span>&gt;</span>}</li>
            <li key='last'><a href={'#' + (pageCount - 1)} onClick={this.onPageClick}>&raquo;</a></li>
          </ul>
        </nav>
      )
    }

    return (
      <div className='paging-toolbar'>
        {child}
      </div>
    )
  }

  onPageClick = e => {
    e.preventDefault()

    var num = Number(e.target.hash.substring(1))

    var oldoffset = this.props.params.offset
    var offset = num * this.props.params.limit

    if (oldoffset != offset)
      this.props.onPageClick(offset)
  };
}

export default PagingToolbar
