import { combineReducers } from 'redux'

import markets from './markets'
import leads from './leads'
import ordering from './ordering'
import ui from './ui'
import types from './types'

export default combineReducers({
  markets,
  leads,
  ordering,
  ui,
  types
})
