import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import SearchHistoryItem from './SearchHistoryItem'
import { Card, Heading, Box, Text } from '@realsoftworks/decor'
import { TOP_BAR_HEIGHT } from '../../../../app/TopBar/TopBar'
import { useHistory } from 'search/HistoryContext'
import PagingToolbar from 'common/PagingToolbar'
import formatLeadToHistory from 'search/formatLeadToHistory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import useBooleanCookie from 'common/util/hooks/useBooleanCookie'
import { connect } from 'react-redux'
import { getCurrent } from 'users/selectors'
import SearchSidebarHeader from './SearchSidebarHeader'

export const SIDEBAR_HEADER_HT = 40

const withConnect = connect(state =>
  ({ userId: (getCurrent(state) || {}).id }))
const SearchSidebar = withConnect(({ hasFetched, onFetchSuccess, userId }) => {
  const {
    fetchHistory,
    isLoading,
    history: historyRaw,
    pagination
  } = useHistory()
  const history = useMemo(() =>
    historyRaw.map(formatLeadToHistory), [historyRaw])
  const hasNoHistory = !isLoading && !history.length

  useEffect(() => {
    if (!hasFetched)
      fetchHistory()
        .then(onFetchSuccess)
  }, [])

  // Manage cookie-based toggle sidebar state
  const cookieKey = `${userId}:isSearchHistorySidebarOpen`
  const [isOpen, setIsOpen] = useBooleanCookie(cookieKey, true)
  const toggleIsOpen = useCallback(() =>
    setIsOpen(!isOpen),
  [isOpen, setIsOpen])

  const scrollParentRef = useRef()

  return (
    <>
      {/* Show Sidebar Button */}
      {!isOpen && (
        <Box css='position: relative; z-index: 3;'>
          <ToggleButton
            isOpen={isOpen}
            onClick={toggleIsOpen}
            css='position: absolute; top: 0; left: 0;'
          />
        </Box>
      )}

      <Card
        width={[260, 280, 300, 320, 340]}
        height={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
        bg='white'
        boxShadow={2}
        overflowY='visible'
        display={isOpen ? 'flex' : 'none'}
        flexDirection='column'
        alignItems='stretch'
        flexShrink={0}
        css='position: relative; z-index: 3;'
        className='print-hide'
      >
        {/* Hide Sidebar Button */}
        <ToggleButton
          isOpen={isOpen}
          onClick={toggleIsOpen}
          css='position: absolute; top: 0; left: 100%; z-index: 2;'
        />

        {/* Covers Left Shadow of Hide Sidebar Button */}
        <Box
          width='2px'
          export height={SIDEBAR_HEADER_HT}
          bg='white'
          css='position: absolute; top: 0; right: 0; z-index: 2;'
        />

        <SearchSidebarHeader />

        {/* Loading */}
        {isLoading ? (
          <Box
            flexGrow={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Heading
              as='h2'
              size={6}
              px={3}
              color='muted'
              textAlign='center'
            >
              Loading Search History...
            </Heading>
          </Box>

        /* Empty */
        ) : hasNoHistory ? (
          <Box
            flexGrow={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Heading
              as='h2'
              size={6}
              px={3}
              color='secondary'
              textAlign='center'
            >
              Your search history is empty. Start searching or try changing the filter using the button above.
            </Heading>
          </Box>

        /* Show Results */
        ) : (
          <Card
            ref={scrollParentRef}
            flexGrow={1}
            overflowY='auto'
            css='position: relative;'
          >
            {history.map((r, i) => (
              <Box
                key={r.id || r.propertyAddressLine1}
                style={i === 0 ? {} : {
                  borderTop: '1px solid #ddd'
                }}
              >
                <SearchHistoryItem scrollParentRef={scrollParentRef} {...r} />
              </Box>
            ))}
          </Card>
        )}

        {pagination.count > 0 && (
          <Card boxShadow='0 0 3px rgba(0,0,0,0.12), 0 0 2px rgba(0,0,0,0.24)'>
            <PagingToolbar {...pagination} />
          </Card>
        )}
      </Card>
    </>
  )
})

export default SearchSidebar

const ToggleButton = ({ isOpen, className, ...props }) => (
  <Card
    width='24px'
    pl={isOpen ? '0' : '2px'}
    pr={!isOpen ? '0' : '2px'}
    height={SIDEBAR_HEADER_HT}
    display='flex'
    alignItems='center'
    justifyContent='center'
    boxShadow={1}
    bg='white'
    css={`
      border-bottom-right-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #E8E6E1;
      }

      ${props.css || ''}
    `}
    tabIndex={0}
    role='button'
    aria-pressed={isOpen ? 'true' : 'false'}
    aria-label='Toggle Sidebar'
    className={`print-hide ${className}`}
    id='TEST'
    {...props}
  >
    <Text fontSize='2' color='secondary'>
      <FontAwesomeIcon icon={isOpen ? faCaretLeft : faCaretRight} />
    </Text>
  </Card>
)
