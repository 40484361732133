/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Link } from 'react-router-dom'

class ContactHeader extends React.Component {
  onClick = e => {
    if (this.props.onClick)
      this.props.onClick(e)
  };

  render () {
    var c = this.props.contact || {}
    var v = c.values || {}

    var role = this.props.role
    var hasPerson = v.name && v.name != ' '
    var hasBusiness = !!v.company_name
    var link = this.props.link || false
    var name = hasPerson ? v.name : v.company_name

    var methods = []

    var phones = v.phones || []
    var emails = v.emails || []

    if (phones.length == 0 && v.phone)
      phones.push({ phone: v.phone })

    if (emails.length == 0 && v.email)
      emails.push({ email: v.email })

    var len = 1
    if (this.props.full)
      len = Math.max(phones.length, emails.length)

    for (var i = 0; i < len; i++) {
      var phone = i < phones.length ? phones[i].phone : null
      var email = i < emails.length ? emails[i].email : null

      methods.push(
        <span className='method-set' key={i}>
          <span className='contact-method' key='phone'> {phone ? <span className='phone'>{phone}</span> : null} </span>
          <span className='contact-method' key='email'> {email ? <span className='email'>{email}</span> : null} </span>
        </span>
      )
    }

    return (
      <span className='contact-header' onClick={this.onClick}>
        <span className='contact-name' key='names'>
          <span className='primary-name' key='primary'>{link ? <Link to={`/contacts/${this.props.contact.id}`}>{name}</Link> : name}</span>
          {role ? <span className='contact-role' key='role'>{role}</span> : (this.props.blankRole ? this.props.blankRole : '')}
          {hasPerson && hasBusiness ? <span className='company-name' key='company'>{v.company_name}</span> : ''}
        </span>
        <span className='contact-methods' key='methods'>
          {methods}
        </span>
      </span>
    )
  }
}

export default ContactHeader
