/* eslint "react/display-name": "warn" */
import React from 'react'

const ButtonBar = props => (
  <div className='button-bar'>
    {props.children}
  </div>
)

export default ButtonBar

ButtonBar.LeftButtons = props => <div className='button-bar-left'>{props.children}</div>
