import styled from 'styled-components'
import { Box } from '@realsoftworks/decor'

const Mask = styled(Box)`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0.2;
`

Mask.defaultProps = {
  bg: 'neutral.900'
}

export default Mask
