import React from 'react'

import { connect } from 'react-redux'

import TwoPartSearch from '../TwoPartSearch'
import SellerIntro from './components/SellerIntro'
import SellerFilters from './components/SellerFilters'
import SellerResults from './components/SellerResults'

import * as selectors from '../../selectors'
import { fetchSellers, clearPrevious, fetchSellerCounts, exportLeads, fetchPropertyTypes } from '../../actions'

let Sellers = props => (
  <TwoPartSearch
    introComponent={SellerIntro}
    resultComponent={SellerResults}
    filterComponent={SellerFilters}
    type='sellers'
    {...props}
  />
)

Sellers = connect(
  state => ({
    data: selectors.getData(state),
    counts: selectors.getSellerCounts(state),
    propertyTypes: selectors.getPropertyTypes(state),
    filter: selectors.getFilter(state, 'sellers')
  }),
  {
    fetch: fetchSellers,
    fetchCounts: fetchSellerCounts,
    clear: clearPrevious,
    exportLeads,
    fetchPropertyTypes
  }
)(Sellers)

export default Sellers
