import { getCmaById } from 'cma/selectors'
import React, { FC } from 'react'
import { connect } from 'react-redux'

type OwnProps = {
  organizationId: string
  cmaId: string
}

type ConnectProps = { logoUrl?: string | null }

const withState = connect((state, { cmaId, organizationId }: OwnProps) => ({
  logoUrl: getCmaById(state, cmaId)
    ?.organizations
    ?.find(org => org.id === organizationId)
    ?.logo
}))

const MLSNextListingLogoBase: FC<OwnProps & ConnectProps> = ({ logoUrl }) =>
  logoUrl
    ? (
      <img
        src={logoUrl}
        /** ClassName copied from `mls/components/Logo.js` */
        className='mls-logo'
      />
    )
    : null

const MLSNextListingLogo = withState(MLSNextListingLogoBase)

export default MLSNextListingLogo
