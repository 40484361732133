import * as CONSTANTS from '../actions'

const filters = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case CONSTANTS.CLEAR:
      return {}

    case CONSTANTS.FETCH: {
      return { [meta.filterType]: meta.params }
    }
  }

  return state
}

export default filters
