import eventEmitter from '../core/eventemit'
import Dispatcher from './Dispatcher'

function BasicStore () {
  this.__callbacks = {}
}

var CHANGE_EVENT = 'change'
Object.assign(BasicStore.prototype, eventEmitter, {

  emitChange: function () {
    this.emit(CHANGE_EVENT)
  },

  initialize: function (cfg) {

  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback)
  },

  /**
   * @param {function} callback
   */
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback)
  },

  bindEvent: function (event, callback) {
    this.__callbacks[event] = callback
  },

  register: function () {
    var store = this
    this.dispatcherIndex = Dispatcher.register(function (payload) {
      var action = payload.action
      var callback = store.__callbacks[action]
      if (callback) {
        var ret = callback.call(this, payload)
        return ret
      }

      return true
    })
  },

  waitFor: function (otherStores) {
    var callbackIndexes = otherStores.map(function (store) {
      return store.dispatcherIndex
    })
    Dispatcher.waitFor(callbackIndexes)
  }
})

var RESERVED_KEYS = ['waitFor']
BasicStore.create = function (spec) {
  RESERVED_KEYS.forEach(function (key) {
    if (spec[key])
      throw new Error("Reserved key '" + key + "' found in store definition")
  })

  var constructor = function (options) {
    options = options || {}
    BasicStore.call(this)

    for (var key in spec)
      if (key === 'initialize') {
        // do nothing
      } else if (typeof spec[key] === 'function') { this[key] = spec[key].bind(this) } else { this[key] = spec[key] }

    if (spec.initialize)
      spec.initialize.call(this, options)
  }

  constructor.prototype = Object.create(BasicStore.prototype)
  return constructor
}

export default BasicStore
