import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { bool, object, string } from 'prop-types'
import { Text, Checkbox } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconCheckbox = ({ checked, icon, color, ...props }) => {
  const [hasChanged, setHasChanged] = useState(false)
  const hasMountedRef = useRef(false)

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true
      return
    }

    if (hasChanged) return
    setHasChanged(true)
  }, [checked])

  return (
    <Checkbox
      {...props}
      checked={checked}
      color='neutral.500'
      checkedIcon={(
        <ScalingIcon icon={icon} checkedColor={color} shouldAnimate={hasChanged} />
      )}
      uncheckedIcon={(
        <ScalingIcon shrink icon={icon} checkedColor={color} shouldAnimate={hasChanged} />
      )}
    />
  )
}

IconCheckbox.propTypes = {
  checked: bool.isRequired,
  icon: object.isRequired,
  color: string.isRequired
}

export default styled(IconCheckbox)``

const ScalingIcon = ({ checkedColor, icon, shrink, shouldAnimate }) => (
  <Text color='neutral.200' css='position: relative;'>
    <FontAwesomeIcon icon={icon} />

    <Text
      color={checkedColor} css={`
        position: absolute;
        top: 0;
        left: 0;
        ${shouldAnimate ? `animation: ${shrink ? 'shrink' : 'grow'} .5s;` : ''}
        transform: scale(${shrink ? 0 : 1});

        @keyframes grow {
          from { transform: scale(0); }
          to { transform: scale(1); }
        }

        @keyframes shrink {
          from { transform: scale(1); }
          to { transform: scale(0); }
        }
      `}
    >
      <FontAwesomeIcon icon={icon} />
    </Text>
  </Text>
)
