import React from 'react'
import getEquityType from 'common/util/getEquityType'

const EquityTypePropertyMarker = loanToValue => {
  const equityType = getEquityType(loanToValue.loanToValue)

  if (equityType === 'UNKNOWN') return null

  const Equity = RENDER_MARKER[equityType]

  return <div className={`equity-type__container ${equityType}`}><Equity /></div>
}

const FreeAndClear = () => (
  <svg width='34px' height='34px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 127 121'>
    <path d='M44.302 74.896L10.345 71.32C4.783 40.92 34.62 10.49 65.083 10.315c26.783-.207 56.544 29.796 51.8 60.328l-32.51 4.28' fill='none' stroke='#1e9600' stroke-width='14'/>
  </svg>
)

const UpsideDown = () => (
  <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.6066 10.5272C8.71448 10.3375 5.54979 6.31801 4.78104 4.74183L6.98387 3.09942' stroke='#960000' strokeWidth='6' />
  </svg>
)

const HighEquity = () => (
  <svg width='21' height='24' viewBox='0 0 21 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M17.2787 6.27699C13.2288 5.51118 2.99455 9.85672 3.41773 20.0558L6.11761 20.5663' stroke='url(#paint0_linear)' strokeWidth='6' />
    <path d='M17.2622 0.150024L19.8603 7.78358H14.6641L17.2622 0.150024Z' fill='#625D52' />
    <defs>
      <linearGradient id='paint0_linear' x1='17.921' y1='7.16104' x2='2.29666' y2='18.5128' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#38A1A8' />
        <stop offset='0.510417' stopColor='#F7D070' />
        <stop offset='1' stopColor='#FF0000' />
        <stop offset='1' stopColor='#D64545' />
      </linearGradient>
    </defs>
  </svg>
)

const LowEquity = () => (
  <svg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.11748 5.99999C4.50003 7 3.41763 12 3.41763 13.7536L6.11751 14.2641' stroke='url(#paint0_linear)' strokeWidth='6' />
    <path d='M2.39591 1.80544L9.06481 6.3383L4.91498 9.46543L2.39591 1.80544Z' fill='#625D52' />
    <defs>
      <linearGradient id='paint0_linear' x1='17.9208' y1='0.858917' x2='2.29651' y2='12.2106' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#1E9600' />
        <stop offset='0.510417' stopColor='#FFF200' />
        <stop offset='1' stopColor='#FF0000' />
        <stop offset='1' stopColor='#FF0000' />
      </linearGradient>
    </defs>
  </svg>
)

const RENDER_MARKER = {
  OWNED: FreeAndClear,
  UPSIDE_DOWN: UpsideDown,
  HIGH: HighEquity,
  LOW: LowEquity
}

export default EquityTypePropertyMarker
