import React from 'react'
import get from 'lodash/object/get'
import { Numeric } from 'common/format'
import Stats from 'drivingfordollars/components/Stats'
import Duration from 'drivingfordollars/components/Duration'
import { DRIVES } from 'drivingfordollars/propTypes'
import { Text } from '@realsoftworks/decor'

const DrivesStats = ({ drives, leadsCount }) => {
  const { distance, duration } = drives.reduce((acc, d) =>
    ({
      distance: acc.distance + (get(d, 'distance.miles') || 0),
      duration: acc.duration + (d.duration || 0)
    }),
  { distance: 0, duration: 0 })

  return (
    <Stats
      p={5} data={{
        distance: (
          <>
            <Numeric decimals={0}>{distance}</Numeric>
            {' '}
            <Text color='neutral.500'>mi</Text>
          </>
        ),
        duration: (<Duration>{duration}</Duration>),
        trips: drives.length,
        leads: typeof leadsCount === 'number' ? leadsCount : '--'
      }}
    />
  )
}

export default DrivesStats

DrivesStats.propTypes = { drives: DRIVES }
