import React from 'react'
import styled from 'styled-components'
import { Box, Card } from '@realsoftworks/decor'

const ProgressWrap = styled(Card)`
  overflow: hidden;
  font-size: 12px;
  background-color: #e9ecef;
`

const ProgressBar = styled(Box)`
  background-color: #40c3cb;
  height: 100%;
  text-align: center;
  color: white;
  animation: progress-bar-stripes 2s linear infinite;
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Progress = ({ percent, ...props }) => (
  <ProgressWrap height='20px' {...props}>
    <ProgressBar width={`${percent}%`}>{props.children}</ProgressBar>
  </ProgressWrap>
)

export default Progress
