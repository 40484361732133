import { MinAddress, SkiptraceFormat } from 'types'
import qs from 'qs'
import fetch from 'common/fetch'
import { toast } from '@realsoftworks/decor'
import { GENERIC_ERROR_NOTIF_CONTENT } from 'const'

const downloadPropertySkiptrace = ({ address, format }: {
  address: MinAddress
  format: SkiptraceFormat
}): Promise<void> =>
  fetch(
    `/marketing/v2/skiptrace/v3/download?${qs.stringify({ ...address, format })}`,
    { method: 'GET' }
  )
    .then(resp => resp.text())
    .then(text => stringToDownload({ text, fileName: `skiptrace-${address.line1}.csv` }))
    .catch(() => toast.error({ title: 'Download Failed', content: GENERIC_ERROR_NOTIF_CONTENT }))

export default downloadPropertySkiptrace

const stringToDownload = ({ text, fileName }: {
  text: string
  fileName: string
}): void => {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plaincharset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', fileName)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
