import { FEATURES } from 'billing/constants'

export const ROUTE_REQUIREMENTS = {
  SEARCH: { // Search
    reqs: [{
      feature: FEATURES.LEADS,
      model: 'lead',
      action: 'create'
    }, {
      feature: FEATURES.SITES,
      action: 'lead',
      model: 'create'
    }, {
      feature: FEATURES.DRIVING,
      model: 'lead',
      action: 'create'
    }],
    to: '/search'
  },
  SEARCH_HISTORY: { // Search History
    reqs: [{
      feature: FEATURES.LEADS,
      model: 'lead',
      action: ['create', 'view']
    }, {
      feature: FEATURES.SITES,
      action: 'manage',
      model: 'sites'
    }, {
      feature: FEATURES.DRIVING,
      model: 'lead',
      action: ['create', 'view']
    }],
    to: '/search/history'
  },
  LIST_BUILDER: { // List Builder
    reqs: [{
      feature: FEATURES.LEADS,
      action: 'manage',
      model: 'leadlists'
    }],
    to: '/leadlists'
  },
  SITES: { // Websites
    reqs: [{
      feature: FEATURES.SITES,
      action: 'manage',
      model: 'sites'
    }],
    to: '/sites'
  },
  DRIVING: { // Driving For Dollars
    reqs: [{
      feature: FEATURES.DRIVING
    }],
    to: '/drivingfordollars'
  },
  REGIONAL_LEADS: { // Regional Leads
    reqs: [{
      feature: FEATURES.PREMIUMLEADS,
      action: 'manage',
      model: 'leadlists'
    }],
    to: '/regionalleads'
  },
  DEALALERTS: { // Deal Alerts
    reqs: [{
      feature: FEATURES.DEALALERTS,
      action: 'view',
      model: 'dealalert'
    }],
    to: '/dealalerts'
  },
  BILLING: { // Billing
    reqs: [{
      action: 'company',
      model: 'admin'
    }],
    to: '/billing'
  },
  USER: { // User Profile
    to: '/users/settings'
  }
}

export const ROUTES = Object.keys(ROUTE_REQUIREMENTS)
  .reduce((acc, k) => ({ ...acc, [k]: k }), {})

export const GENERIC_ERROR_NOTIF = {
  title: 'Something went wrong',
  content: 'We’re sorry. If you can’t access certain features, please try refreshing the page or getting in touch with us by clicking the floating button on lower right.'
}

export const GENERIC_ERROR_NOTIF_CONTENT = 'Sorry, something went wrong while processing your request. Please refresh the page and try again, or get in touch with us by clicking the support button on lower right.'

export const TAG_EXISTING_NOTIF = {
  title: 'Can’t add tag. It already exists.'
}

export const INVALID_RANGE_INPUT_ERROR_NOTIF = {
  title: 'Invalid range input',
  content: 'We’ve reverted your input to last valid value. Please use a valid range.'
}

export const GOOGLE_KEY = 'AIzaSyCi3feSdTerouRXNDTvioVli8bpZ40M6vY'
export const MAPBOX_KEY = 'pk.eyJ1IjoicHJvcGVsaW8td2ViIiwiYSI6ImNrbnFlNmVtdTAwNTkybm41Nng5MHJsNW0ifQ.jVxqdwqSS-qhJa74zMwnYA'

export const PROVIDER_SKIPGENIE = 'skipGenie'
export const PROVIDER_REIGROUP = 'reiGroup'
export const US_CENTER = [39.5013296, -101.425653]
