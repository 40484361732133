import PropTypes from 'prop-types'
import React from 'react'

import classnames from 'classnames'

import './label.less'

class Label extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  render () {
    const { className, ...rest } = this.props

    return (
      <label {...rest} className={classnames('decor', 'decorLabel', className)}/>
    )
  }
}

export default Label
