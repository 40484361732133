/* eslint "camelcase": "warn", "handle-callback-err": "warn" */
import qs from 'qs'
import { Schema, arrayOf, normalize } from 'normalizr'

import fetch from 'common/fetch'

const ENDPOINT = '/tasks'

const taskSchema = new Schema('tasks')
const userSchema = new Schema('users')
const activitySchema = new Schema('activity')
const arrayOfActivities = arrayOf(activitySchema)

taskSchema.define({
  creator: userSchema,
  values: {
    activity: arrayOfActivities,
    owner: userSchema
  }
})

const arrayOfTasks = arrayOf(taskSchema)
const searchSchema = { result: { models: arrayOfTasks } }
const resultSchema = { result: taskSchema }

export const SEARCH = 'TASKS_SEARCH'
export const SEARCH_SUCCESS = 'TASKS_SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'TASKS_SEARCH_FAILURE'

export function search (params) {
  return dispatch => {
    dispatch({ type: SEARCH, meta: { params } })
    var urlParams = qs.stringify(params)

    fetch(`${ENDPOINT}?${urlParams}`)
      .then(res => res.json())
      .then(json => normalize(json, searchSchema))
      .then(payload => {
        dispatch({ type: SEARCH_SUCCESS, payload, meta: { params } })
      })
      .catch(error => dispatch({ type: SEARCH_FAILURE, payload: error, meta: { params } }))
  }
}

export function searchByShow (show) {
  return search({ show })
}

export function searchByReference (reference_type, reference_id) {
  return search({ show: 'reference', reference_type, reference_id })
}

export const CREATE = 'TASKS_CREATE'
export const CREATE_SUCCESS = 'TASKS_CREATE_SUCCESS'
export const CREATE_FAILURE = 'TASKS_CREATE_FAILURE'

export function create (data) {
  return async dispatch => {
    dispatch({ type: CREATE })
    let payload = {}
    await fetch(`${ENDPOINT}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, resultSchema)
        dispatch({ type: CREATE_SUCCESS, payload: payload })
      })
      .catch(error => dispatch({ type: CREATE_FAILURE, payload: payload }))
  }
}

export const COMPLETE = 'TASK_COMPLETE'
export const COMPLETE_SUCCESS = 'TASK_COMPLETE_SUCCESS'
export const COMPLETE_FAILURE = 'TASK_COMPLETE_FAILURE'

export function complete (id) {
  return dispatch => {
    dispatch({ type: COMPLETE })

    let payload = {}
    fetch(`${ENDPOINT}/complete/${id}`,
      {
        method: 'POST'
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, taskSchema)
        dispatch({ type: COMPLETE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: COMPLETE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const UPDATE = 'TASK_UPDATE'
export const UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'TASK_UPDATE_FAILURE'

export function update (id, update) {
  return dispatch => {
    dispatch({ type: UPDATE })

    let payload = {}
    fetch(`${ENDPOINT}/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(update)
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, resultSchema)
        dispatch({ type: UPDATE_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: UPDATE_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const COMMENT = 'TASK_COMMENT'
export const COMMENT_SUCCESS = 'TASK_COMMENT_SUCCESS'
export const COMMENT_FAILURE = 'TASK_COMMENT_FAILURE'

export function comment (id, comment) {
  return dispatch => {
    dispatch({ type: COMMENT })

    let payload = {}
    fetch(`${ENDPOINT}/comment/${id}`,
      {
        method: 'POST',
        body: JSON.stringify(comment)
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, taskSchema)
        dispatch({ type: COMMENT_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: COMMENT_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}

export const READ = 'TASKS_READ'
export const READ_SUCCESS = 'TASKS_READ_SUCCESS'
export const READ_FAILURE = 'TASKS_READ_FAILURE'

export function read (id) {
  return dispatch => {
    dispatch({ type: READ })

    let payload = {}
    fetch(`${ENDPOINT}/${id}`,
      {
        method: 'GET'
      })
      .then(res => res.json())
      .then(json => {
        payload = normalize(json, taskSchema)
        dispatch({ type: READ_SUCCESS, payload: payload, meta: { params: { id: id } } })
      })
      .catch(error => dispatch({ type: READ_FAILURE, payload: payload, meta: { params: { id: id } } }))
  }
}
