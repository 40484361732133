import React, { useMemo, FC } from 'react'
import { Box, Heading } from '@realsoftworks/decor'
import { NumberRange } from '../../../filters'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import get from 'lodash/object/get'
import { validatePastOrPresentYearMinus1 } from 'common/util/isPastOrPresentYear'
import set from 'common/util/set'
import { NumberRange as NumberRangeType } from 'types'

export type PropertyInfoFilters = {
  propertyDetails?: {
    beds?: NumberRangeType
    baths?: NumberRangeType
    yearBuilt?: NumberRangeType
    buildingSqft?: NumberRangeType
    lotSqft?: NumberRangeType
    stories?: NumberRangeType
    units?: NumberRangeType
  }
}

const PropertyInfoSection: FC<{
  filters: PropertyInfoFilters
  onChange: (filters: PropertyInfoFilters) => void
  openSection: string | null
  toggleSection: (section: string | null) => void
}> = ({
  filters,
  onChange,
  openSection,
  toggleSection
}) => {
  const SECTION_KEY = 'PROP_INFO'
  const isSectionOpen = openSection === SECTION_KEY

  const createFieldProps = key => ({
    onChange: value =>
      onChange(set(
        ['propertyDetails', key],
        value,
        filters
      )),
    value: get(filters, `propertyDetails.${key}`)
  })

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    get(filters, 'propertyDetails.beds'),
    get(filters, 'propertyDetails.baths'),
    get(filters, 'propertyDetails.yearBuilt'),
    get(filters, 'propertyDetails.buildingSqft'),
    get(filters, 'propertyDetails.lotSqft'),
    get(filters, 'propertyDetails.stories'),
    get(filters, 'propertyDetails.units')
  ]

  return useMemo(() =>
    <AccordionFilterSection
      mt={5}
      heading='Property Info'
      contentContainerProps={{ pt: 2 }}
      toggleSection={toggleSection(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >
      <Box mx={-3} display='flex' flexWrap='wrap'>
        <Box flex='1 1 180px' minWidth='180px'>
          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Beds</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('beds')}
            />
          </Box>

          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Baths</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('baths')}
            />
          </Box>

          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Year Built</Heading>
            <NumberRange
              thousandSeparator=''
              placeholder='Any'
              validateValue={validatePastOrPresentYearMinus1}
              {...createFieldProps('yearBuilt')}
            />
          </Box>
        </Box>

        <Box flex='1 1 180px' minWidth='180px'>
          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Building Sqft</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('buildingSqft')}
            />
          </Box>

          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Lot Size Sqft</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('lotSqft')}
            />
          </Box>
        </Box>

        <Box flex='1 1 180px' minWidth='180px'>
          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Stories</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('stories')}
            />
          </Box>

          <Box p={2} px={3}>
            <Heading mb={2} size={6}>Units</Heading>
            <NumberRange
              placeholder='Any'
              {...createFieldProps('units')}
            />
          </Box>
        </Box>
      </Box>
    </AccordionFilterSection>,
  rerenderDeps)
}

export default PropertyInfoSection
