/* eslint "react/no-deprecated": "warn", "no-useless-escape": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { Form, Label, FormInput } from 'decor/form'
import Toolbar from 'decor/Toolbar'
import LoadingButton from 'common/LoadingButton'
import Button from 'decor/Button'
import BigLoader from 'common/BigLoader'
import LoadingIcon from 'common/LoadingIcon'
import { connect } from 'react-redux'
import { isOwnedDomain } from '../../selectors'
import { fetchDomains } from '../../actions'
import DomainPurchaseDialog from '../domains/DomainPurchaseDialog'
import Mask from 'decor/Mask'
import DomainSelect from '../domains/DomainSelect'
import { Link } from 'react-router-dom'
import { Button as ButtonV2 } from '@realsoftworks/decor'

class CustomDomainForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      purchaseDialogShown: false,
      useExisting: false,
      ...this.calculateState(props)
    }
  }

  showPurchaseDialog = () => this.setState({ purchaseDialogShown: true });
  hidePurchaseDialog = () => this.setState({ purchaseDialogShown: false });
  useExisting = () => this.setState({ editing: true });

  componentWillReceiveProps (nextProps) {
    // at the time of rendering/loading, we don't know if selected domain is owned or not
    if (!nextProps.loading && this.props.loading)
      this.setState(this.calculateState(nextProps))
  }

  calculateState (props) {
    let domain = props.domains && props.domains.length ? props.domains[0] : null

    if (domain)
      domain = domain.replace('www\.', '')

    let type = 'managed'
    if (domain && !props.isOwned)
      type = 'unmanaged'

    return {
      value: { [type + 'Value']: domain },
      type,
      hadValue: !!domain,
      domain,
      siteValue: domain
    }
  }

  onTypeChange = e => {
    this.setState({ type: e.target.value })
  };

  onPurchaseDomain = domain => {
    this.setState({
      value: { managedValue: domain },
      type: 'managed'
    }, () => {
      this.props.fetchDomains().then(this.onSubmit)
    })
  };

  onChange = value => {
    this.setState({ value })
  }

  cancelEditing = e => {
    e.preventDefault()
    this.setState({ editing: false })
  };

  onSubmit = () => {
    this.setState({ saving: true })

    const { type, value } = this.state
    const domain = type == 'none' ? null : value[type + 'Value']

    this.props.save(domain).then(() => this.setState({ saving: false, editing: false, hadValue: !!domain, domain, useExisting: false }))
  };

  render () {
    const { value, saving, type, hadValue, purchaseDialogShown, editing, domain } = this.state
    const { loading, isOwned } = this.props

    return (
      <div>
        <DomainPurchaseDialog open={purchaseDialogShown} onRequestClose={this.hidePurchaseDialog} onFinish={this.onPurchaseDomain}/>

        {hadValue &&
            <div style={{ marginBottom: 20 }}>
              <h3>{domain}</h3>
              <p>{loading ? <LoadingIcon/> : isOwned ? 'This domain is registered with Propelio' : 'This domain is registered outside of Propelio'}</p>
              {isOwned &&
                <div>
                  <ButtonV2
                    variant='secondary'
                    as={Link}
                    to={`/sites/domains/${domain}/manage`}
                  >
                    Advanced DNS
                  </ButtonV2>
                </div>
              }
              <hr/>
            </div>
        }

        {
          (editing)
            ? (loading ? <BigLoader/>
              : <Form value={value} onChange={this.onChange} onSubmit={this.onSubmit}>
                {loading && <Mask/>}

                <div className='domain-type domain-managed'>
                  <Label>
                    <input type='radio' name='domaintype' value='managed' checked={type == 'managed'} onChange={this.onTypeChange}/> Purchased in Propelio
                  </Label>
                  <FormInput name='managedValue' disabled={type !== 'managed'} type={DomainSelect}/>
                </div>

                <div className='domain-type domain-unmanaged'>
                  <Label>
                    <input type='radio' name='domaintype' value='unmanaged' checked={type == 'unmanaged'} onChange={this.onTypeChange}/> Purchased Outside Propelio
                  </Label>
                  <div className='input-group'>
                    <span className='input-group-addon'>www.</span>
                    <FormInput name='unmanagedValue' disabled={type !== 'unmanaged'}/>
                  </div>
                  <p>
                  For help in setting up your non-Propelio domain, see this <a href='https://intercom.help/propelio/websites/general-domain-set-up' target='_top'>help article.</a>
                  </p>
                </div>

                <div className='domain-type domain-none'>
                  <Label>
                    <input type='radio' name='domaintype' value='none' checked={type == 'none'} onChange={this.onTypeChange}/> No custom domain
                  </Label>
                </div>

                <Toolbar>
                  <Toolbar.Left>
                    <Button disabled={saving} appearance='secondary' onClick={this.cancelEditing}>Cancel</Button>
                  </Toolbar.Left>
                  <LoadingButton className='btn-success' loadingText='Saving...' loading={saving}>Save</LoadingButton>
                </Toolbar>
              </Form>
            )
            : <div>
              {!hadValue &&
              <p>A custom domain (ex: yourbusiness.com) with a custom email address is the most important step you can take to establish your company's online presence.</p>

              }
              <Button appearance='primary' style={{ marginRight: 16 }} onClick={this.showPurchaseDialog}>Purchase New Domain</Button>
              <Button appearance='secondary' onClick={this.useExisting}>Use Existing Domain</Button>
            </div>

        }
      </div>
    )
  }
}

CustomDomainForm = connect(
  (state, props) => ({
    isOwned: isOwnedDomain(state, props.domains && props.domains.length ? props.domains[0] : null)
  }), {
    fetchDomains
  }
)(CustomDomainForm)

export default CustomDomainForm
