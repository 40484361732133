import React, { useState } from 'react'
import { Box, Text } from '@realsoftworks/decor'
import UpperDetails from './UpperDetails'
import Card from '@realsoftworks/decor/dist/components/Card'
import LowerDetails from './LowerDetails'
import TransfersAndMortgages from './TransfersAndMortgages'
import Preforeclosures from './Preforeclosures'
import ValueSection from './ValueSection'
import TaxAssessmentInfoSection from './TaxAssessmentInfoSection'

const TAB_NAMES = ['Details', 'Mortgages & Transfers', 'Preforeclosures']
const PropertyDetails = ({
  property: propertyFromProps = {},
  onOpenSkiptraceTab,
  onOpenCmaTab
}) => {
  const property = propertyFromProps || {}
  const [activeIndex, setActiveIndex] = useState(0)
  const transfers = property.transferHistory
  const currentMortgages = property.loans
  const preforeclosures = property.preforeclosures

  return (
    <Box display='flex' flexWrap='wrap' p={[0, 1]}>
      <Box p={1} flex='1 0 66.66%'>
        <UpperDetails property={property} onOpenSkiptraceTab={onOpenSkiptraceTab} />
      </Box>

      <Box minWidth='240px' flex='1 1 33.33%'>
        <Box p={1}>
          <ValueSection property={property} onOpenCmaTab={onOpenCmaTab} />
        </Box>
        <Box p={1}>
          <TaxAssessmentInfoSection property={property} />
        </Box>
      </Box>

      <Box p={1} width='100%'>
        <Card
          bg='white'
          borderRadius='3px'
          display='flex'
          alignItems='stretch'
          boxShadow={1}
          flexWrap={['wrap', 'nowrap']}
        >
          <Box
            width={['100%', '120px']}
            display={['flex', 'block']}
            flexGrow={0}
            flexShrink={0}
            bg='neutral.200'
            css={`
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            `}
          >
            {TAB_NAMES.map((n, i) => (
              <Tab
                key={n}
                isActive={activeIndex === i}
                css={i === 0 ? 'border-top-left-radius: 3px;' : ''}
                onClick={() => setActiveIndex(i)}
              >
                {n}
              </Tab>
            ))}
          </Box>
          <Box flexGrow={1} flexShrink={1} overflowX='auto'>
            {/* Property Details */}
            {activeIndex === 0 && (
              <LowerDetails property={property} />
            )}

            {/* Transfer & Mortgages */}
            {activeIndex === 1 && (
              <TransfersAndMortgages
                transfers={transfers}
                currentMortgages={currentMortgages}
              />
            )}

            {/* Preforeclosures */}
            {activeIndex === 2 && (
              <Preforeclosures preforeclosures={preforeclosures} />
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default PropertyDetails

const Tab = ({ isActive, children, ...props }) => (
  <Box
    px={3}
    py={2}
    bg={isActive ? 'white' : 'neutral.200'}
    css={`cursor: pointer; ${props.css || ''}`}
    fontWeight='bold'
    {...props}
  >
    <Text fontSize={0}>
      {children}
    </Text>
  </Box>
)
