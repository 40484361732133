/* eslint "react/display-name": "warn" */
import React, { useRef, useMemo } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TextTableCell,
  Text
} from '@realsoftworks/decor'

import { DateTime, Monetary, Numeric } from 'common/format'
import { useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'
import { useLogError } from 'common/util/hooks/useLogError'

const CustomNeighborhoodTable = React.memo(({ data, onChangePage, count, ...props }) => (
  <Box width='100%' {...props}>
    <CustomNeighborhoodTableData data={data} />
  </Box>
))

const CustomNeighborhoodTableData = React.memo(({ data }) => {
  const scrollParentRef = useRef()

  return (
    <Table
      width='100%'
      maxHeight='65vh'
      style={{ overflow: 'auto' }}
      css='font-size: 14px; position: relative;'
      ref={scrollParentRef}
    >
      <TableHead width='720px'>
        <TableHeaderCell flex='12'>ADDRESS</TableHeaderCell>
        <TableHeaderCell flex='12'>BUYER</TableHeaderCell>
        <TableHeaderCell flex='16'>STATS</TableHeaderCell>
        <TableHeaderCell flex='12'>LAST SALE</TableHeaderCell>
      </TableHead>
      <TableBody width='720px'>
        {data.map(record => (
          <CustomNeighborhoodTableRow
            scrollParentRef={scrollParentRef}
            key={record.id}
            record={record}
          />
        ))}
      </TableBody>
    </Table>
  )
})

export default CustomNeighborhoodTable

const CustomNeighborhoodTableRow = ({ record, scrollParentRef }) => {
  useLogError({
    error: 'record.id is unexpectedly falsy in CustomNeighborhoodTableRow',
    shouldLog: !record.id
  }, [!!record.id])

  const { isSyncHovered, hoverRef } = useSyncedIsHoveredWithAutoScroll({
    scrollParentRef,
    memberId: record.id,
    groupId: 'neighbor'
  })
  const cellBg = isSyncHovered ? 'blue.200' : ''

  const address = useMemo(() =>
    ({
      line1: record.propertyAddressLine1,
      city: record.propertyAddressCity,
      state: record.propertyAddressState,
      zip: record.propertyAddressZip
    }),
  [
    record.propertyAddressLine1,
    record.propertyAddressCity,
    record.propertyAddressState,
    record.propertyAddressZip
  ])

  return (
    <Box ref={hoverRef}>
      <TableRow>
        <TextTableCell flex='12' bg={cellBg}>
          <AddressPreviewLink address={address} />
        </TextTableCell>
        <TextTableCell flex='12' bg={cellBg}>
          {record.name}
        </TextTableCell>
        <TextTableCell flex='16' bg={cellBg}>
          {record.beds || '--'} / <Numeric>{record.bathsFull || '--'}</Numeric> / {record.garageSpaces || '--'}<br />
          <Text color='neutral.500'> sqft: </Text><Text><Numeric>{record.sqft || '--'}</Numeric></Text>
          <Text color='neutral.500'> build: </Text><Text> {record.yearBuilt || '--'} </Text>
        </TextTableCell>
        <TextTableCell flex='12' bg={cellBg}>
          {record.lastSalePrice ? <Monetary>record.lastSalePrice</Monetary> : '--'} <br />
          {record.lastSaleDate ? <DateTime format='MM/DD/YYYY'>{record.lastSaleDate}</DateTime> : '--'}
        </TextTableCell>
      </TableRow>
    </Box>
  )
}
