import React from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/object/get'
import { DateTime, RelativeDateTime, Numeric } from 'common/format'
import Duration from 'drivingfordollars/components/Duration'

import {
  Card as BaseCard,
  Box,
  Text,
  Link,
  Positioner
} from '@realsoftworks/decor'

const Popover = ({ history, target, ...props }) => (
  <>
    <Positioner
      isShown={!!props.id}
      target={target}
    >
      {({ ref, style, state, css, zIndex }) => (
        <BaseCard
          variant='basic' ref={ref} style={style} data-state={state} css={css} zIndex={zIndex}
          px={4} pt={4} pb={5}
          mt={5}
        >
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Text fontSize={2} css='white-space: nowrap;'>
              <DateTime format='MMMM Do, YYYY'>{props.createdAt}</DateTime>
            </Text>
            <Text pl={2} fontSize={0} color='neutral.500' css='white-space: nowrap;'>
              <RelativeDateTime hasTooltip={false}>{props.createdAt}</RelativeDateTime>
            </Text>
          </Box>
          <Box display='flex' justifyContent='center' mt={3}>
            <Box display='flex' flexDirection='column' alignItems='flex-end'>
              <Text fontSize={1}>
                {get(props, 'distance.miles')
                  ? (<Numeric decimals={0}>{props.distance.miles}</Numeric>)
                  : '--'}
              </Text>
              <Text fontSize={1}>
                <Duration>
                  {props.duration}
                </Duration>
              </Text>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start' pl={1}>
              <Text fontSize={1} color='neutral.500'>miles</Text>
              <Text fontSize={1} color='neutral.500'>duration</Text>
            </Box>
            <Box pl={4}>
              <Text fontSize={1}>{props.leadsCount || '--'}</Text>
              <Text pl={1} fontSize={1} color='neutral.500'>leads</Text>
            </Box>
          </Box>

          <Link
            onMouseUp={ev => {
              ev.preventDefault()
              ev.stopPropagation()
              history.push(`/drivingfordollars/drives/${props.id}`)
            }}
            display='flex'
            justifyContent='center'
            mt={4}
            as='div'
          >
            <Text fontSize={0} fontWeight='bold'>View Details</Text>
          </Link>
        </BaseCard>
      )}
    </Positioner>
  </>
)

export default withRouter(Popover)
