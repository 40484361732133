/* eslint "react/no-render-return-value": "warn" */
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'

class Portal extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired
  };

  componentDidMount () {
    this.renderLayer()
  }

  componentDidUpdate () {
    this.renderLayer()
  }

  componentWillUnmount () {
    this.unrenderLayer()
  }

  getPortal () {
    return this.layer
  }

  unrenderLayer () {
    if (!this.layer)
      return

    ReactDOM.unmountComponentAtNode(this.layer)
    document.body.removeChild(this.layer)
    this.layer = null
  }

  renderLayer () {
    const {
      open,
      render
    } = this.props

    if (open) {
      if (!this.layer) {
        this.layer = document.createElement('div')
        document.body.appendChild(this.layer)
      }

      const layerElement = render()
      this.layerElement = ReactDOM.render(layerElement, this.layer)
    } else { this.unrenderLayer() }
  }

  render () {
    return null
  }
}

export default Portal
