import React from 'react'
import { Box, Caption, Text } from '@realsoftworks/decor'

import ListItem from './ListItem'
import LoadingIndicator from './LoadingIndicator'

const SearchResults = ({ categories, results, onItemClick }) => {
  const nonEmptyCategories = categories
    .filter(cat => {
      const list = results?.[cat.id]
      const hasAnyResult = list?.results?.length
      const isLoading = list?.loading
      return hasAnyResult || isLoading
    })

  return (
    <>
      {nonEmptyCategories.length
        ? (
          <Box display='flex' flexDirection='row' flexWrap='wrap' mx={-3}>
            {nonEmptyCategories.map(cat => {
              const list = results?.[cat.id]
              return (
                <Box key={cat.id} flex='1 1 50%'>
                  <Caption display='block' as='div' mb={1} color='secondary' px={3}>
                    {cat.label || cat.id}
                  </Caption>
                  <Box>
                    {list.loading && <Box px={3}><LoadingIndicator /></Box>}
                    {(list.results) && list.results
                      .map(v => ({
                        details: v,
                        category: cat.id,
                        id: cat.getId ? cat.getId(v) : v
                      }))
                      .map(v => (
                        <ListItem
                          px={3}
                          key={`${cat.id}|${cat.getId
                            ? cat.getId(v)
                            : v.id}`}
                          onClick={() => onItemClick(v)}
                        >
                          {cat.icon && <>{cat.icon}{' '}</>}
                          {cat.getLabel ? cat.getLabel(v.details) : v.details}
                        </ListItem>
                      ))}
                  </Box>
                </Box>
              )
            })}
          </Box>
        )
        : (<Text color='secondary'>Nothing matched your search</Text>)}
    </>
  )
}

export default SearchResults
