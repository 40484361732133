const getCoordinatesFromProperty = (property?: {
  address?: {
    lat?: number,
    lon?: number,
    coordinates?: {
      latitude?: number,
      longitude?: number,
    }
  }
}): null | { lat: number, lng: number } => {
  const address = property?.address
  const lat = typeof address?.lat === 'number'
    ? address.lat
    : typeof address?.coordinates?.latitude === 'number'
      ? address.coordinates.latitude
      : null

  const lng = typeof address?.lon === 'number'
    ? address.lon
    : typeof address?.coordinates?.longitude === 'number'
      ? address.coordinates.longitude
      : null

  if (lat === null || lng === null) return null

  return { lat, lng }
}

export default getCoordinatesFromProperty
