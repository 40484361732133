import React from 'react'
import { Card, Box, Heading } from '@realsoftworks/decor'
import DataView from 'search/components/DataView'
import { Monetary, DateTime } from 'common/format'
import { orNull } from 'common/util/renderHelpers'

const BORDER_WIDTH = 2

// @TODO: Maybe move this to common/format/address.js?
function formatAddress (data) {
  if (!data || !data.city || !data.state) return ''

  return [data.line1, data.city, data.state, data.zip].filter(i => !!i).join(', ')
}

const RecordDetails = ({ data }) => {
  const originalLoan = {
    'Recording Date': orNull(data.originalLoan?.recordingDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Instrument Number': orNull(data.originalLoan?.instrumentNumber),
    'Book/Page': orNull(data.originalLoan?.bookPage),
    'Loan Number': orNull(data.originalLoan?.loanNumber),
    'Maturity Date': orNull(data.originalLoan?.maturityDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Payment Amount': orNull(data.originalLoan?.paymentAmount, v => (
      <Monetary>{v}</Monetary>
    )),
    'Lender Name': orNull(data.originalLoan?.lender?.name),
    'Lender Address': orNull(formatAddress(data.originalLoan?.lender?.address)),
    'Lender Phone': orNull(data.originalLoan?.lender?.phone),
    'Servicer Phone': orNull(data.originalLoan?.servicer?.phone),
    'Loan Amount': orNull(data.originalLoan?.amount, v => (
      <Monetary>{v}</Monetary>
    )),
    'Loan Balance': orNull(data.originalLoan?.balance, v => (
      <Monetary>{v}</Monetary>
    )),
    Servicer: orNull(data.originalLoan?.servicer?.name),
    'Servicer Address': orNull(formatAddress(data.originalLoan?.servicer?.address))
  }

  const recordData = {
    'Recording Date': orNull(data.foreclosure.recordingDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Publication Date': orNull(data.foreclosure.publicationDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Instrument Number': orNull(data.foreclosure.instrumentNumber),
    'Instrument Date': orNull(data.foreclosure.instrumentDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Book/Page': orNull(data.foreclosure.bookPage),
    'Penalty Interest': orNull(data.foreclosure.penaltyInterest, v => (
      <Monetary>{v}</Monetary>
    )),
    'Judgement Date': orNull(data.foreclosure.judgementDate, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    'Judgement Amount': orNull(data.foreclosure.judgementAmount, v => (
      <Monetary>{v}</Monetary>
    )),
    Courthouse: orNull(data.foreclosure.courthouse),
    'Case Number': orNull(data.foreclosure.caseNumber),
    'Default Amount': orNull(data.foreclosure.defaultAmount, v => (
      <Monetary>{v}</Monetary>
    )),
    'Trustee Reference Number': orNull(data.foreclosure.trustee.referenceNumber),
    'Trustee Name': orNull(data.foreclosure.trustee.name),
    Address: orNull(formatAddress(data.foreclosure.trustee.address)),
    Phone: orNull(data.foreclosure.trustee.phone)
  }

  const auctionData = {
    Location: data.auction.location,
    Date: orNull(data.auction.date, v => (
      <DateTime format='MM/DD/YYYY'>{v}</DateTime>
    )),
    Time: orNull(data.auction.time),
    'Opening Bid': orNull(data.auction.openingBid, v => (
      <Monetary>{v}</Monetary>
    ))
  }

  const hasOriginalLoan = Object.values(originalLoan).some(v => v !== null)
  const hasRecordData = Object.values(recordData).some(v => v !== null)
  const hasAuctionData = Object.values(auctionData).some(v => v !== null)

  return (
    <Card
      bg='white'
      width='100%'
      borderWidth={BORDER_WIDTH}
      borderColor='yellow.200'
      borderStyle='solid'
      css='overflow: auto;'
    >
      <Box width='100%' display='flex' flexWrap='wrap'>
        {hasOriginalLoan && (
          <Box
            as='section'
            p={3}
            flex='1 1 33.333%'
            minWidth='240px'
            maxWidth='480px'
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Heading
              as='h2'
              color='primary.500'
              textAlign='center'
              pb={1}
              style={{ fontSize: 16 }}
            >
              Original Loan
            </Heading>

            <DataView
              shouldRemoveEmptyData
              data={originalLoan}
            />
          </Box>
        )}

        {hasRecordData && (
          <Box
            as='section'
            p={3}
            flex='1 1 33.333%'
            minWidth='240px'
            maxWidth='480px'
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Heading
              as='h2'
              color='primary.500'
              textAlign='center'
              pb={1}
              style={{ fontSize: 16 }}
            >
              {data.foreclosure.type}
            </Heading>

            <DataView
              shouldRemoveEmptyData
              data={recordData}
            />
          </Box>
        )}

        {hasAuctionData && (
          <Box
            as='section'
            p={3}
            flex='1 1 33.333%'
            minWidth='240px'
            maxWidth='480px'
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Heading
              as='h2'
              color='primary.500'
              textAlign='center'
              pb={1}
              style={{ fontSize: 16 }}
            >
              Auction
            </Heading>

            <DataView
              shouldRemoveEmptyData
              data={auctionData}
            />
          </Box>
        )}
      </Box>
    </Card>
  )
}
export default RecordDetails
