/* eslint "no-class-assign": "warn" */
import React from 'react'
import { connect } from 'react-redux'

import { checkModelPermission } from '../selectors'

class PermissionComponent extends React.Component {
  render () {
    const { not, can, alternative = null } = this.props

    const allowed = not ? !can : can

    if (allowed)
      return this.props.children
    else
      return alternative
  }
}

PermissionComponent = connect(
  (state, ownProps) => ({
    can: checkModelPermission(state, ownProps.model, ownProps.action)
  })
)(PermissionComponent)

export default PermissionComponent
