import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, Box, Paragraph, Text, Card, toast } from '@realsoftworks/decor'
import LoadingButton from 'common/LoadingButton'
import BigLoader from 'common/BigLoader'
import api from 'common/api'
import BillingInfo from 'billing/components/BillingInfo'
import { selectServices, selectAvailable, selectPrice, selectMinimum } from 'marketing/selectors'
import { fetchBalance } from 'marketing/actions'
import { hasBillingInfo } from 'billing/selectors'
import ShoppingCart from 'lists/components/skiptrace/SkiptraceOrderModal/ShoppingCart'
import AcceptTerms from 'lists/components/skiptrace/SkiptraceOrderModal/AcceptTerms'
import { useSearchScreenProps } from 'search/SearchContext'

import SkiptraceBalance from 'lists/components/skiptrace/SkiptraceBalance'
import { updateLeadLocally } from 'leads/actions'
import { getLeadsObj } from 'leads/selectors'

const baseUrl = '/marketing/v2/skiptrace/v3'
export const EMPTY_SKIPTRACES = {
  title: 'Empty skiptrace results — no charge',
  content: 'Sorry, the skiptrace order had no results'
}

const ErrorMessage = ({ error, ...props }) => {
  let text = error.message
  if (error.type === 'api_error')
    text = 'There was a problem submitting your request'

  return (
    <Text color='red.500' {...props}>{text}</Text>
  )
}

const OPTION_HINT = 'Choose a skiptrace provider above'

const mapStateToProps = state => {
  const services = selectServices(state)
  return ({
    minimum: selectMinimum(state),
    providers: services ? services.skiptrace : null,
    hasBillingInfo: hasBillingInfo(state),
    creditPrice: selectPrice(state),
    availableBalance: selectAvailable(state),
    leadRecords: getLeadsObj(state)
  })
}

const mapDispatchToProps = {
  fetchBalance,
  updateLeadLocally
}

const withState = connect(mapStateToProps, mapDispatchToProps)

const SkiptraceOrderModal = withState(({
  providers,
  fetchBalance,
  hasBillingInfo,
  minimum,
  creditPrice,
  availableBalance,
  address,
  isModal,
  onClose,
  updateLeadLocally,
  leadRecords,
  ...props
}) => {
  const { lead, setLead } = useSearchScreenProps() as any
  const isSkippable = true // true
  const [accepted, setTermsAccepted] = useState(false)
  const [error, setError] = useState(null)
  const [enteringInfo, setEnteringInfo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null)

  useEffect(() => {
    if (!selectedProvider) return

    setTermsAccepted(false)
  }, [selectedProvider])

  const selectOption = option => {
    setSelectedProvider(option)
  }

  const submitOrder = async () => {
    if (!hasBillingInfo && isBalanceInsufficient) {
      setEnteringInfo(true)
    } else if (selectedProvider) {
      setError(null)
      setLoading(true)
      const res = await api.post(`${baseUrl}`, {
        provider: selectedProvider,
        request: {
          line1: address.line1,
          city: address.city,
          state: address.state,
          zip: address.zip
        }
      })

      // Show an error toast if skiptrace is empty
      if (res?.error?.message) {
        const INSUFFICIENT_CREDITS_ERR_TYPE = 'insufficient_credits'
        const INSUFFICIENT_CREDITS_ERR_TOAST = {
          title: 'Insufficient Credits',
          content: 'Please add more credits in the billing page.'
        }

        toast.error(res.error.type === INSUFFICIENT_CREDITS_ERR_TYPE
          ? INSUFFICIENT_CREDITS_ERR_TOAST
          : {
            title: 'Skiptrace Failed',
            content: res.error.message
          })
      } else if (
        !res ||
        !res.result ||
        (res.result.providers[selectedProvider] && Object.keys(res.result.providers[selectedProvider]).length === 0)
      ) {
        toast.error(EMPTY_SKIPTRACES)
        const updateContextLead = {
          ...lead,
          propertyDetails: {
            ...(lead.propertyDetails || {}),
            skiptraces: [
              res.result,
              ...(lead.propertyDetails?.skiptraces || [])
            ]
          }
        }
        const reduxLead = leadRecords[lead.id]
        const updatedReduxLead = {
          ...reduxLead,
          property: {
            ...(reduxLead.property || {}),
            skiptraces: [
              res.result,
              ...(reduxLead.property?.skiptraces || [])
            ]
          }
        }

        updateLeadLocally({ lead: updatedReduxLead })
        setLead(updateContextLead)
      } else if (res && res.result) {
        fetchBalance()
        const updatedContextLead = {
          ...lead,
          propertyDetails: {
            ...(lead.propertyDetails || {}),
            skiptraces: [
              res.result,
              ...(lead.propertyDetails?.skiptraces || [])
            ]
          }
        }
        const reduxLead = leadRecords[lead.id]
        const updatedReduxLead = {
          ...reduxLead,
          property: {
            ...(reduxLead.property || {}),
            skiptraces: [
              res.result,
              ...(reduxLead.property?.skiptraces || [])
            ]
          }
        }

        updateLeadLocally({ lead: updatedReduxLead })
        setLead(updatedContextLead)
      }

      setLoading(false)

      if (isModal)
        onClose()
    }
  }

  const onTermsAcceptChange = useCallback((_e, v) => setTermsAccepted(v), [])

  useEffect(() => { fetchBalance() }, [])

  const isBalanceInsufficient = selectedProvider &&
    providers[selectedProvider] &&
    availableBalance < providers[selectedProvider]

  const hint = !selectedProvider
    ? OPTION_HINT
    : isSkippable && !accepted && !isBalanceInsufficient
      ? 'Please agree to the terms above'
      : null

  const content = (
    <BigLoader loading={!providers}>
      {
        !isBalanceInsufficient || !enteringInfo
          ? <>
            <ShoppingCart
              mb={5}
              provider={selectedProvider}
              onProviderChange={selectOption}
              totalCount={1}
              skippableCount={1}
              skippedCount={0}
              shouldForceHideOptions={false}
              shouldForceHideProviders={false}
              skipOption={null}
              onSkipOptionChange={() => {}}
            />
            {isSkippable &&
            <>
              <Box mx={-5}>
                <SkiptraceBalance
                  availableBalance={availableBalance}
                  cost={selectedProvider && providers[selectedProvider]}
                />
              </Box>

              {selectedProvider && !isBalanceInsufficient && (
                <AcceptTerms
                  mt={5}
                  checked={accepted}
                  onChange={onTermsAcceptChange}
                  count={1}
                  servicePrice={selectedProvider && providers[selectedProvider]}
                  creditPrice={creditPrice}
                  availableCredits={availableBalance}
                  minimum={minimum}
                />
              )}
            </>}

          </>
          : <>
            <Paragraph>Enter your billing info to continue with your order.</Paragraph>
            <BillingInfo />
          </>
      }

      <Box display='flex' justifyContent='flex-end' mt={5}>
        {hint && providers && <Text color='secondary'>{hint}</Text>}
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        {error && <ErrorMessage error={error} />}
      </Box>

      <Box display='flex' justifyContent='flex-end' pt={4}>
        <LoadingButton
          ml={3}
          disabled={!!hint || isBalanceInsufficient}
          variant='primary'
          onClick={submitOrder}
          loading={loading}
          loadingText='Submitting...'
        >
          Submit Order
        </LoadingButton>
      </Box>
    </BigLoader>
  )

  if (isModal)
    return (
      <Dialog
        title='Skiptrace Order'
        scroll='body'
        onClose={onClose}
        open={true}
        {...props}
      >
        {content}
      </Dialog>
    )

  return (
    <Card bg='white' p={4} display='flex' alignItems='center' justifyContent='center' minHeight='100%'>
      <Box flex='0 1 600px'>
        {content}
      </Box>
    </Card>
  )
})

export default SkiptraceOrderModal
