import { useCallback } from 'react'
import { useCookie } from 'react-use'

const useBooleanCookie = (
  key: string,
  initVal: boolean
): [boolean, (v: boolean) => void] => {
  const [cookieValue, updateCookie] = useCookie(key)
  const value = cookieValue === 'true'
    ? true
    : cookieValue === 'false'
      ? false
      : typeof initVal === 'boolean'
        ? initVal
        : false
  const setValue = useCallback((value: boolean) => {
    updateCookie(value ? 'true' : 'false')
  }, [cookieValue, updateCookie])
  return [value, setValue]
}

export default useBooleanCookie
