/* eslint "eqeqeq": "warn", "promise/param-names": "warn" */

var defaultOptions = {
  timeout: 60000,
  credentials: 'same-origin',
  headers: new Headers({
    Accept: 'application/json',
    'content-type': 'application/json'
  })
}

export default (url, options) =>
  Promise.race([
    fetch(url, { ...defaultOptions, ...options })
      .then(response => {
        if (response.status == 401)
          window.location = '/users/sessiontimeout'

        return response
      }),
    new Promise((_, reject) =>
      setTimeout(() =>
        reject(new Error('Request timedout')),
      (options && options.timeout) || defaultOptions.timeout)
    )
  ])
