import React, { Component } from 'react'

import BigChart from './BigChart'

import BigFatalError from 'common/BigFatalError'
import DataContainer from './DataContainer'

import { fetchVisitors } from '../actions'
import { getVisitors } from '../selectors'

class SiteVisitorChart extends Component {
  render () {
    return (
      <DataContainer
        load={() => fetchVisitors(this.props.siteId)}
        selector={state => getVisitors(state, this.props.siteId)}
        render={({ error, props }) => {
          if (error) return <BigFatalError />
          return <BigChart data={props.data} yKey='visitors' />
        }}
      />
    )
  }
}

SiteVisitorChart.propTypes = {}
SiteVisitorChart.defaultProps = {}

export default SiteVisitorChart
