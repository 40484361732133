import React from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'
import {
  Box,
  Label,
  Text
} from '@realsoftworks/decor'

const Stats = ({ data, ...props }) => (
  <Box display='flex' justifyContent='center' mx={-5} {...props}>
    {Object.entries(data)
      .map(([label, value], i) => (
        <Box key={i} display='flex' flexDirection='column' alignItems='center' px={5}>
          <StatsLabel color='neutral.500'>{label}</StatsLabel>
          <Text fontSize={3}>{value}</Text>
        </Box>
      ))}
  </Box>
)

Stats.propTypes = { object }

export default Stats

const StatsLabel = styled(Label)`
    font-size: 12px;
    text-transform: uppercase;
`
