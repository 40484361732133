/* eslint "eqeqeq": "warn" */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import FontIcon from 'common/FontIcon'
import { Box, Heading, Paragraph, IconButton, Dialog, Card, Button, List, ListItem, ListItemText } from '@realsoftworks/decor'
import without from 'lodash/array/without'
import unique from 'lodash/array/unique'
import debounce from 'lodash/function/debounce'
import Mask from './Mask'

const DialogListItem = ({ value, label, icon, onClick, ...props }) => {
  const memoClick = useCallback(() => onClick(value), [value, onClick])
  return (
    <ListItem>
      <ListItemText
        primaryText={label}
      />
      {
        icon &&
          <IconButton onClick={memoClick}>
            <FontIcon icon={icon}/>
          </IconButton>
      }
    </ListItem>
  )
}

class ListDialog extends React.Component {
  static propTypes = {
    initialValue: PropTypes.array,
    data: PropTypes.array
  }

  static defaultProps = {
    emptyText: 'No matches'
  }

  state = {
    typed: '',
    open: this.props.open,
    value: this.props.initialValue,
    filtered: [],
    filterValue: undefined
  }

  static getDerivedStateFromProps (props, state) {
    if (props.open !== state.open)
      return {
        open: props.open,
        value: props.initialValue,
        typed: '',
        filtered: [],
        filterValue: undefined
      }

    return null
  }

  onAddClick = v => {
    const { value = [] } = this.state
    const uniqueValues = unique([...value, v])
    this.setState({
      value: uniqueValues
    })
  }

  onRemoveClick = v => {
    this.setState({
      value: without(this.state.value, v)
    })
  }

  onSaveClick = close => {
    this.props.onChange(this.state.value)
    close()
  }

  onFilter = v => {
    this.setState({ filterValue: v })
    this.doFilter(v)
  }

  doFilter = debounce(v => {
    this.setState({ loading: true }, () => {
      this.props.adapter.filter(v).then(data => this.setState({ filtered: data, loading: false }))
    })
  }, 500)

  render () {
    const { children, emptyText, filterComponent: FilterComponent, adapter, onChange, ...props } = this.props
    const { value, filtered, filterValue, loading } = this.state
    const workingValue = value || []

    const included = filtered
      .reduce((acc, v) => {
        if (!workingValue.find(wv => wv == adapter.extractValue(v)))
          acc.push(v)

        return acc
      }, [])

    return (
      <Dialog
        {...props}
        width='100%'
        maxWidth={640}
        contentProps={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
        toolbar={ ({ close }) => (
          <React.Fragment>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={() => this.onSaveClick(close)} variant='primary'>Save & Continue</Button>
          </React.Fragment>
        )}
      >
        {children}
        <Card mx={-3} display='flex' flex='1' overflow='hidden' height={300}>
          <Card flex='1' mx={3} bg='neutral.100' display='flex' flexDirection='column' p={2}>
            {FilterComponent && <FilterComponent mb={3} value={filterValue} onChange={this.onFilter}/>}
            { (filtered.length == adapter.limit) && <Paragraph color='secondary' my={3}>Showing the first {adapter.limit} results</Paragraph>}
            <Card variant='dense' flex='1' overflow='auto' css='position: relative;'>
              {loading && <Mask/>}
              <List variant='dense'>
                { included.map(v => {
                  const value = adapter.extractValue(v)
                  const label = adapter.extractLabel(v)
                  return (
                    <DialogListItem
                      key={value}
                      value={value}
                      label={label}
                      onClick={this.onAddClick}
                      icon='+'
                    />
                  )
                })
                }
              </List>
              { !filtered.length && <Paragraph color='secondary'>{emptyText}</Paragraph>}
            </Card>
          </Card>
          <Box mx={3} flex='1' display='flex' flexDirection='column'>
            <Heading size={6} mb={2}>Included Items</Heading>
            {!workingValue.length && <Paragraph color='secondary'>Nothing added yet</Paragraph>}
            <List variant='dense' flex='1' overflow='auto'>
              {workingValue.map(v => {
                const item = adapter.get(v)
                const value = adapter.extractValue(item)
                const label = adapter.extractLabel(item)
                return (
                  <DialogListItem
                    key={value}
                    value={value}
                    label={label}
                    onClick={this.onRemoveClick}
                    icon='x'
                  />
                )
              })}
            </List>
          </Box>
        </Card>
      </Dialog>
    )
  }
}

export default ListDialog
