import isStrEqCaseInsensitive from 'common/util/isStrEqCaseInsensitive'

type Name = { firstName?: string | null, lastName?: string | null }

const doesContactAndSkiptraceMatch = (contact: Name, skiptraceRequest: Name): boolean => {
  const c = contact
  const s = skiptraceRequest
  const eq = isStrEqCaseInsensitive

  const areHumanNamesAndEqual = eq(c.firstName, s.firstName) && eq(c.lastName, s.lastName)

  /**
   * Company names has either first name or last name set and the other not (should be null)
   * So for a skiptrace to be a match to a company contact:
   *  - for both contact and request, either (never both) first name or last name must be string and similar; and
   *  - the other values must be falsy
   */
  const cStringVal = c.firstName || c.lastName
  const sStringVal = s.firstName || s.lastName
  const cFalsyVal = c.firstName && c.lastName
  const sFalsyVal = s.firstName && s.lastName
  const isEitherEqAndOthersNull = !!(cStringVal && sStringVal && !cFalsyVal && !sFalsyVal && eq(cStringVal, sStringVal))
  const areCompanyNamesAndEqual = isEitherEqAndOthersNull

  return areHumanNamesAndEqual || areCompanyNamesAndEqual
}

export default doesContactAndSkiptraceMatch
