import React from 'react'
import cn from 'classnames'

import Field from '../Field'
import Label from '../Label'
import FormInput from '../FormInput'
import Hint from '../Hint'
import Error from '../Error'

export default function FormField (props) {
  const { label, type, className, inputClassName, hint, error, ...rest } = props

  return (
    <Field className={cn(className)}>
      {label && <Label>{label}</Label>}
      {type && <FormInput type={type} className={inputClassName} {...rest} />}
      {hint && <Hint>{hint}</Hint>}
      {error && <Error>{error}</Error>}
    </Field>
  )
};

FormField.defaultProps = {
  type: 'text'
}
