import { connect } from 'react-redux'
import { getSetting } from '../selectors'

const SettingsComponent = ({ value = true, settingValue, children }) => {
  if (value === settingValue) return null

  return children
}

const mapStateToProps = (state, ownProps) => ({
  settingValue: getSetting(state, ownProps.settingKey)
})

export default connect(mapStateToProps)(SettingsComponent)
