import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCog,
  faHome,
  faHandshake,
  faMoneyBillWave,
  faTimes,
  faCheckCircle,
  faPlus,
  faCar,
  faQuestion,
  faPhone,
  faQuestionCircle,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

const icons = {
  'paging-first': faAngleDoubleLeft,
  'paging-prev': faAngleLeft,
  'paging-next': faAngleRight,
  'paging-last': faAngleDoubleRight,
  'angle-right': faAngleRight,
  home: faHome,
  handshake: faHandshake,
  money: faMoneyBillWave,
  cog: faCog,
  times: faTimes,
  check: faCheck,
  x: faTimes,
  checkcircle: faCheckCircle,
  '+': faPlus,
  car: faCar,
  '?': faQuestion,
  question: faQuestion,
  'question-circle': faQuestionCircle,
  '(?)': faQuestionCircle,
  phone: faPhone
}

const FontIcon = ({ icon, size, ...props }) => <FontAwesomeIcon icon={icons[icon]} size={size} {...props} />

FontIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons))
}

export default FontIcon
