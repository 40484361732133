import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchDrives } from 'drivingfordollars/actions'
import { selectDrives } from 'drivingfordollars/selectors'
import { DRIVES } from 'drivingfordollars/propTypes'
import curry from 'lodash/function/curry'

import {
  bool,
  object,
  func,
  number
} from 'prop-types'

const withDrives = curry((
  opts,
  BaseComponent
) => {
  const {
    params = {},
    shouldFetch = true
  } = opts || {}

  const WrappedComponent = ({
    drives,
    count,
    isLoading,
    error,
    fetchDrives,
    ...props
  }) => {
    // Without this, consumer can't know if the drives
    // came from the previous request (can happen on first render,
    // before calling `fetchDrive()`) or the newest one

    let setHasLoaded
    let hasLoaded = !isLoading

    if (shouldFetch) {
      [hasLoaded, setHasLoaded] = useState(false)

      useEffect(() => {
        fetchDrives(params).then(() => {
          setHasLoaded(true)
        })
      }, [])
    }

    return (
      <BaseComponent
        drives={drives}
        count={count}
        isLoading={isLoading}
        error={error}
        fetchDrives={fetchDrives}
        hasLoaded={hasLoaded}
        {...props}
      />
    )
  }

  BaseComponent.propTypes = {
    ...drivesPropTypes,
    ...(BaseComponent.propTypes || {})
  }

  return withState(WrappedComponent)
}, 2)

export default withDrives

export const drivesPropTypes = {
  isLoading: bool.isRequired,
  hasLoaded: bool.isRequired,
  error: object,
  fetchDrives: func.isRequired,
  count: number,
  drives: DRIVES
}

const withState = connect(
  state => {
    const { value, count, isLoading, error } = selectDrives(state)
    const drives = Object.values(value)

    return { drives, count, isLoading, error }
  },
  { fetchDrives }
)
