/* eslint "eqeqeq": "warn" */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import Card from 'common/Card'
import BigLoader from 'common/BigLoader'
import ListHeader from './components/ListHeader'
import ListMemberTable from './components/ListMemberTable'
import ImportProgressBar from './components/ImportProgressBar'
import SkiptraceProgressBar from './components/SkiptraceProgressBar'
import TrialNotice from './components/TrialNotice'

class ListDetail extends React.Component {
  static propTypes = {
    update: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    setSelectedListItems: PropTypes.func.isRequired,
    memberInfo: PropTypes.object
  }

  state = { ready: false, loadingMembers: true, selected: [] };

  componentDidMount () {
    const offset = this.getOffset()
    this.doFetch(offset)
  }

  componentDidUpdate (prevProps) {
    const pOffset = this.getOffset(prevProps)
    const cOffset = this.getOffset()

    if (pOffset !== cOffset)
      this.doFetch(cOffset)

    if (
      prevProps.location?.key !== this.props.location?.key ||
      prevProps.memberInfo?.id !== this.props.memberInfo?.id ||
      prevProps.memberInfo?.offset !== this.props.memberInfo?.offset
    )
      this.calculateSelected()
  }

  calculateSelected = () => {
    const { location, memberInfo } = this.props
    const cachedSelected = location?.state?.selected || []

    this.setState({
      selected: cachedSelected.filter(id => memberInfo.items.find(item => item.id === id))
    })
  }

  doFetch = (offset = 0) => {
    this.setState({ loadingMembers: true })
    const { limit } = this.props.memberInfo || {}
    return this.props.fetchMembers(this.props.list.id, { offset, limit }).then(() => this.setState({ ready: true, loadingMembers: false }))
  }

  getOffset = props => {
    const { location, memberInfo } = props || this.props
    const { search = '' } = location
    const limit = memberInfo?.limit || 50
    const queries = qs.parse(search.replace(/^\?/, ''))
    const page = queries.page || 1
    return limit * (page - 1)
  }

  setOffset = offset => {
    const { location, history, memberInfo } = this.props
    const { search = '', pathname, state = {} } = location
    const limit = memberInfo?.limit || 50
    const queries = qs.parse(search.replace(/^\?/, ''))

    history.push({
      ...location,
      pathname,
      search: qs.stringify({
        ...queries,
        page: Math.floor(offset / limit) + 1
      }),
      state: {
        ...state,
        selected: []
      }
    })
  }

  setHistory = (action, ids) => {
    const { location, history, list } = this.props

    // disable selections while there is an active import
    if (list.importProgress) return

    const { search = '', pathname, state = {} } = location
    const _ids = Array.isArray(ids) ? ids : [ids]
    let selected = state?.selected || []

    if (action === 'add')
      selected = [...selected, ..._ids]
    else if (action === 'delete')
      selected = selected.filter(id => !ids.includes(id))
    else if (action === 'replace')
      selected = ids

    history.replace({
      ...location,
      pathname,
      search,
      state: {
        ...state,
        selected
      }
    })
  }

  onChangePage = newOffset => {
    this.doFetch(newOffset)
    this.setOffset(newOffset)
  };

  onSelectItemToggle = id => {
    const { location } = this.props

    const selected = location?.state?.selected || []
    const isEnabled = selected.includes(id)

    this.setHistory(isEnabled ? 'delete' : 'add', id)
  }

  onDeselectItems = () => {
    this.setHistory('delete', this.props.memberInfo.items.map(item => item.id))
  }

  onSelectItems = () => {
    this.setHistory('add', this.props.memberInfo.items.map(item => item.id))
  }

  handleDeleteMembers = async (...props) => {
    await this.props.deleteMembers(...props)
    await this.doFetch(this.getOffset())
    this.setHistory('replace', [])
  }

  render () {
    const { list, memberInfo, update, deleteList, download, location, ...props } = this.props
    const { ready, loadingMembers, selected } = this.state

    const derivedType = list.type == 'national-leads-cashbuyers' || list.type == 'national-leads-privatelenders' ? 'contact' : 'property'

    return (
      <Fragment>
        <TrialNotice listId={list && list.id} mt={-6} mb={4} />
        <Card variant='basic' {...props} >
          <ListHeader list={list} updateName={update} deleteList={deleteList} deleteMembers={this.handleDeleteMembers} showActions={!!memberInfo} mode={selected.length > 0 ? 'item' : 'list'} selectedItems={selected} download={download} />
          <ImportProgressBar importProgress={list.importProgress} />
          <SkiptraceProgressBar pendingSkiptraces={list.pendingSkiptraces} memberStats={list.memberStats} />
          {!ready ? <BigLoader/> : <ListMemberTable importProgress={list.importProgress} memberInfo={memberInfo} type={derivedType} loading={loadingMembers} onChangePage={this.onChangePage} selectedItems={selected} showItemToggle={!list.importProgress} onSelectItemToggle={this.onSelectItemToggle} onSelectAll={this.onSelectItems} onDeselectAll={this.onDeselectItems} />}
        </Card>
      </Fragment>
    )
  }
}

export default withRouter(ListDetail)
