/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withRouter, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { Text } from '@realsoftworks/decor'

import BigLoader from 'common/BigLoader'
import LoadingMask from 'common/LoadingMask'
import Card from 'common/Card'
import { connect } from 'react-redux'

import { TermsComponent as MLSTermsComponent } from 'mls/components'
import { viewedSource } from 'mls/actions'
import * as mlsSelectors from 'mls/selectors'

import { fetchListings, cacheMapProps, toggleExcluded, createLead } from '../actions'

import DealAlertResult from './listings/DealAlertResult'

class DealAlertListingsHandler extends Component {
  componentDidMount () {
    this.doFetch()
    this.props.dispatch(viewedSource(this.props.alert.source))
  }

  componentWillReceiveProps (newProps) {
    if (!this.props.allowed && newProps.allowed)
      this.doFetch()
  }

  doFetch (queryParams = {}) {
    var { dispatch, alert } = this.props
    dispatch(fetchListings(alert.id, queryParams))
  }

  onSort = sort => {
    this.doFetch({ sort })
  };

  onPageClick = offset => {
    this.doFetch({ offset })
  };

  onClick = ({ id }) => {
    this.props.history.push(`${this.props.match.url}/${id}`)
  };

  onMapChange = props => {
    this.props.dispatch(cacheMapProps(props))
  };

  toggleListing = (listingId, excluded) => {
    this.props.dispatch(toggleExcluded(this.props.alert.id, listingId, excluded))
  };

  addLead = listing => {
    this.props.dispatch(createLead(this.props.alert.id, listing))
  };

  render () {
    var { loading, result, queryParams, alert, mapProps, statuses, metadata } = this.props

    return (
      <div className='dealalert-listings'>
        <MLSTermsComponent source={alert.source}>
          {
            (loading || !result)
              ? <BigLoader/>
              : result && result.count == 0
                ? <EmptyMessage id={this.props.match.params.id}/>
                : <div>
                  {loading && <LoadingMask/>}
                  <DealAlertResult
                    metadata={metadata}
                    mapProps={mapProps}
                    alert={alert}
                    result={result}
                    params={queryParams}
                    onSortChange={this.onSort}
                    onClick={this.onClick}
                    onPageClick={this.onPageClick}
                    onMapChange={this.onMapChange}
                    toggle={this.toggleListing}
                    addLead={this.addLead}
                    statuses={statuses}
                  />

                </div>

          }
        </MLSTermsComponent>
      </div>
    )
  }
}

const EmptyMessage = props =>
  <Card className='no-results'>
    <h1>No Deal Alerts... Yet!</h1>
    <p>You can change your criteria or try again later.</p>
    <Link to={`/dealalerts/${props.id}/edit`} className='settings-btn'>
      <Text fontSize={4}><FontAwesomeIcon icon={faCog} /></Text> Settings
    </Link>
  </Card>

var getIdMapper = entitytype => (state, ids) => ids.map(id => state.entities[entitytype][id])

var idMapper = getIdMapper('dealalertlistings')

const mapStateToProps = (state, ownProps) => {
  var alert = state.entities.dealalerts[ownProps.match.params.id]
  var metadata = mlsSelectors.getMetadataForSource(state, alert.source)

  // if we are loading results for the currently selected one
  var { loading, result, error, params, id, mapProps } = state.dealalerts.listings.list
  if (id == ownProps.match.params.id) {
    var uiState = {}

    if (result) {
      var idList = result.items

      result = {
        ...result,
        items: idMapper(state, idList)
      }

      uiState = idList.reduce((v, id) => {
        v[id] = state.dealalerts.listings.detail[id]
        return v
      }, {})
    }

    var allowed = mlsSelectors.canAccess(state, alert.source)

    return { loading, result, error, queryParams: params, mapProps, statuses: uiState, allowed, metadata, alert }
  }

  return { loading: true, allowed: true, alert, metadata }
}

DealAlertListingsHandler = compose(
  connect(mapStateToProps),
  withRouter
)(DealAlertListingsHandler)

export default DealAlertListingsHandler
