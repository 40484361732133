import React from 'react'
import { Text } from '@realsoftworks/decor'

const SubscriptPropertyMarker = ({ isForeclosure = false, isVacant = false }) => {
  const attrCount = [isForeclosure, isVacant].filter(Boolean).length
  if (attrCount === 0) return null
  return (
    <div className='subscript__container'>
      <Text
      >
        {attrCount > 1
          ? attrCount
          : isForeclosure
            ? 'F'
            : isVacant
              ? 'V'
              : null}
      </Text>
    </div>
  )
}

export default SubscriptPropertyMarker
