/* eslint "react/no-string-refs": "warn" */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

function getStyles (props, state) {
  const { horizontalPosition } = props

  return {
    root: {
      left: horizontalPosition === 'center'
        ? (state.offsetWidth - 24) / 2 * -1 : null
    },
    inner: {

    }
  }
}

export default class Tooltip extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      offsetWidth: false
    }
  }

  componentDidMount () {
    this.setState({ offsetWidth: this.refs.inner.offsetWidth })
  }

  render () {
    const {
      show,
      placement,
      verticalPosition,
      hortizontalPosition,
      horizontalOffset,
      className,
      children,
      ...props
    } = this.props

    const styles = getStyles(this.props, this.state)

    return (
      <div
        role='tooltip'
        {...props}
        className={classNames(className, 'tooltip', placement, { shown: show })}
        style={styles.root}
      >
        <div ref='inner' className='tooltip-inner'>
          {children}
        </div>
      </div>
    )
  }
}

Tooltip.propTypes = {
  /**
   * The direction the tooltip is positioned towards
   */
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right'])
}

Tooltip.defaultProps = {
  verticalPosition: 'bottom',
  horizontalOffset: 24
}
