import React from 'react'

import LoadingButton from 'common/LoadingButton.js'

class MLSTermsOfUse extends React.Component {
  render () {
    const { termsOfServiceText } = this.props

    return (
      <div className='cma-terms-of-use'>
        <div className='tos-copy-wrap'>
          <div dangerouslySetInnerHTML={{ __html: termsOfServiceText }} />
        </div>

        <LoadingButton
          variant='primary'
          loading={this.props.saving}
          onClick={this.props.onAgree}
          className='btn btn-success btn-lg'
          text='Agree to Terms'
          loadingText='Saving...'
        />
      </div>
    )
  }
}

export default MLSTermsOfUse
