import { useCallback, useMemo } from 'react'
import get from 'lodash/object/get'
import set from './set'

const useGroupedCheckbox = <T extends unknown>({
  values,
  keys,
  onChange,
  useUndefinedWhenFalse
 }: {
  values?: T
  keys: string[]
  onChange: (values: T) => void
  useUndefinedWhenFalse?: boolean
}) => {
  const falseValue = useUndefinedWhenFalse ? undefined : false
  const areAllChecked = useMemo(() =>
    keys.every(k => get(values, k)),
  [values, keys])
  const areSomeChecked = useMemo(() =>
    keys.some(k => get(values, k)),
  [values, keys])

  const handleCheckFactory = key => () =>
    onChange(set(
      key.split('.'),
      get(values, key) ? falseValue : true,
      values
    ))

  const handleCheckAll = useCallback(() => {
    const newValue = keys.reduce((acc, k) =>
      set(k.split('.'), areAllChecked ? falseValue : true, acc),
    values) as T
    onChange(newValue)
  }, [areAllChecked, onChange])

  return {
    handleCheckFactory,
    handleCheckAll,
    areAllChecked,
    areSomeChecked,
  }
}

export default useGroupedCheckbox
