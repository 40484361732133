import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react'
import { Card, Box, Text, Menu, ListItem, Button, toast } from '@realsoftworks/decor'
import AddDnsRecord from './AddDnsRecord'
import { getDnsRecordsStatus } from 'sites/reducer/manage/dnsrecords/selectors'
import { fetchDnsRecords, DOMAIN_NOT_FOUND_ERRMSG, useMXPreset } from 'sites/reducer/manage/dnsrecords/actions'
import { connect } from 'react-redux'
import BigLoader, { CenteredLoader } from 'common/BigLoader'
import DnsRecordsTable from './DnsRecordsTable'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Crumb } from 'common/Breadcrumbs'
import theme from 'theme'

const withContainer = compose(
  connect(
    state => ({ status: getDnsRecordsStatus(state, 'get') }),
    { fetchDnsRecords }
  ),
  withRouter
)

const ManageDns = withContainer(({
  match,
  status,
  fetchDnsRecords,
  history
}) => {
  const domain = match && match.params && match.params.domain
  const [hasFetched, setHasFetched] = useState(false)
  const MX_RECORDS_ONLY = ['MX']

  useEffect(() => {
    fetchDnsRecords(domain)
      .catch(err => {
        const isNotFound = err.message === DOMAIN_NOT_FOUND_ERRMSG
        if (isNotFound) {
          toast.error(NOT_FOUND_ERR)
          history.push('/sites')
        } else {
          toast.error(GET_RECORDS_FAILED_NOTIF)
        }
      })
      .finally(() => setHasFetched(true))
  }, [])

  return (
    <Box
      mx='auto'
      maxWidth='1200px'
      p={4}
    >
      <Crumb title={domain} />
      <Crumb title='Manage DNS Records' />

      <Card
        as='article'
        bg='white'
        borderRadius={3}
        boxShadow={1}
        p={5}
      >
        {!hasFetched || status === 'LOADING' ? (
          <CenteredLoader />
        ) : status === 'ERROR' ? (
          <Text color='secondary'>
            Sorry, we’ve encountered an error while processing your request. Please try again or contact support.
          </Text>
        ) : (
          <Fragment>
            <AddDnsRecord domain={domain} disallowedTypes={MX_RECORDS_ONLY} />
            <DnsRecordsTable domain={domain} disallowedTypes={MX_RECORDS_ONLY} mt={4} />
          </Fragment>
        )}
      </Card>

      <Card
        as='article'
        bg='white'
        borderRadius={3}
        boxShadow={1}
        mt={5}
        p={5}
        css={{ position: 'relative' }}
      >
        {status === 'SETTING_MX_PRESET' && (<LoadingMask />)}

        {!hasFetched || status === 'LOADING' ? (
          <CenteredLoader />
        ) : status === 'ERROR' ? (
          <Text color='secondary'>
            Sorry, we’ve encountered an error while processing your request. Please try again or contact support.
          </Text>
        ) : (
          <Fragment>
            <AddDnsRecord
              dnsOrMx='MX'
              domain={domain}
              allowedTypes={MX_RECORDS_ONLY}
              extraButtons={
                <PresetsButton domain={domain} />
              }
            />
            <DnsRecordsTable domain={domain} allowedTypes={MX_RECORDS_ONLY} mt={4} />
          </Fragment>
        )}
      </Card>
    </Box>
  )
})

export default ManageDns

const NOT_FOUND_ERR = {
  title: 'This domain does not exist',
  content: 'We’re redirecting you to sites.'
}

const GET_RECORDS_FAILED_NOTIF = {
  title: 'Failed to fetch',
  content: 'Sorry, please check your connection, try again, or contact support.'
}

const PresetsButton = connect(null, { useMXPreset })(({
  domain,
  useMXPreset
}) => {
  const btnRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const onClick = useCallback(() => {
    setIsOpen(v => !v)
  })

  return (
    <>
      <Menu
        target={btnRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ListItem
          onClick={() => {
            setIsOpen(false)
            useMXPreset(domain, 'google')
              .then(() => {
                const UPDATE_SUCCESS_NOTIF = { title: 'Used MX preset successfully ' }
                toast.info(UPDATE_SUCCESS_NOTIF)
              })
              .catch(e => {
                const UPDATE_ERR_NOTIF = { title: 'Failed to use MX preset' }
                toast.error({ ...UPDATE_ERR_NOTIF, content: e.message })
              })
          }}
          css={{
            cursor: 'pointer',
            ':hover': {
              backgroundColor: theme.colors.primary[400],
              color: '#fff'
            }
          }}
        >
          Google
        </ListItem>
      </Menu>
      <Button ref={btnRef} variant='secondary' onClick={onClick} css={{ position: 'relative' }}>
        Use Preset
      </Button>
    </>
  )
})

const LoadingMask = () => (
  <Box
    borderRadius={3}
    bg='rgba(255, 255, 255, 0.75)'
    css={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    <BigLoader />
  </Box>
)
