import { ROUTE_REQUIREMENTS } from '../../const'
import { connect } from 'react-redux'
import { selectIsReqMet } from 'common/selectors'

const withState = connect((state, { routeKey }) => {
  const { reqs } = ROUTE_REQUIREMENTS[routeKey]
  return { isReqMet: selectIsReqMet(state, reqs) }
})

const RouteReqComponent = withState(({
  isReqMet,
  alternative = null,
  children
}) =>
  isReqMet
    ? children
    : typeof alternative === 'function'
      ? alternative()
      : alternative)

export default RouteReqComponent
