import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link as RRLink } from 'react-router-dom'
import { string, bool, object, number, arrayOf } from 'prop-types'
import { stringify } from 'qs'
import get from 'lodash/object/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { DateTime, RelativeDateTime, Numeric } from 'common/format'
import UserAvatar from 'users/components/UserAvatar'
import createMatchParamsPropTypes from 'drivingfordollars/utils/createMatchParamsPropTypes'
import { DRIVE_LEADS_ENDPOINT } from 'drivingfordollars/const'
import { fetchDrive } from 'drivingfordollars/actions'
import { selectDrives, selectLeads } from 'drivingfordollars/selectors'
import Container from 'drivingfordollars/components/Container'
import FetchHandler from 'common/FetchHandler'
import Stats from 'drivingfordollars/components/Stats'
import MapboxDrivesMap from 'drivingfordollars/components/DrivesMap/MapboxDrivesMap'
import Duration from 'drivingfordollars/components/Duration'
import { DRIVE } from 'drivingfordollars/propTypes'
import DriveLeadsTable from './DriveLeadsTable'

import {
  Heading,
  Text,
  Card,
  Box,
  Link,
  Button
} from '@realsoftworks/decor'

const DriveDetails = ({
  drive,
  leadsCount,
  isLoading,
  error,
  fetchDrive,
  match,
  leads
}) => {
  const { id } = match.params
  const { hasLoaded } = driveDetailsHook({ drive, id, fetchDrive })

  return (
    <Container mx='auto'>
      <FetchHandler
        isLoading={!hasLoaded || isLoading}
        error={error}
        isEmpty={hasLoaded && !drive}
        emptyText='The drive you requested doesn’t exist.'
      >
        {() => (
          <>
            <Heading size={5}>
              Driving for Dollars -
              {' '}
              <DateTime format='MMMM Do, YYYY'>{drive.createdAt}</DateTime>
              &ensp;
              <Text color='neutral.500'>
                <RelativeDateTime hasTooltip={false}>{drive.createdAt}</RelativeDateTime>
              </Text>
            </Heading>

            <Card
              variant='basic'
              display='flex'
              alignItems='center'
              mt={3}
              pl={5}
              pr={6}
              py={5}
            >
              <UserAvatar userId={drive.createdBy} />

              <Stats
                pl={7} data={{
                  distance: typeof get(drive, 'distance.miles') === 'number' ? (
                    <>
                      <Numeric decimals={0}>{drive.distance.miles}</Numeric>
                      {' '}
                      <Text color='neutral.500'>mi</Text>
                    </>
                  ) : (
                    '--'
                  ),
                  duration: <Duration>{drive.duration}</Duration>,
                  leads: leadsCount || '--'
                }}
              />

              <Box flex='1' /> {/* Space filler */}

              <RRLink to='/drivingfordollars/drives'>
                <Link fontSize={0} as='div'>
                  <Text fontWeight='bold'>See All Drives</Text>
                  {' '}
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </RRLink>
            </Card>

            <Card variant='basic' mt={7}>
              <MapboxDrivesMap
                leads={leads}
                drive={drive}
                isDriveDetails={true}
                borderRadius={[4, 4, 4, 4]}
              />
            </Card>
          </>
        )}
      </FetchHandler>

      <Box display={!hasLoaded ? 'none' : 'block'}>
        <Box display='flex' mt={7}>
          <Heading flex='1' size={4}>Leads</Heading>
          <Button href={createDriveLeadsDownloadLink(id)} variant='primary' as='a'>
            Download
          </Button>
        </Box>

        <Card variant='basic' mt={2}>
          <DriveLeadsTable />
        </Card>
      </Box>
    </Container>
  )
}

DriveDetails.propTypes = {
  isLoading: bool.isRequired,
  error: object,
  drive: DRIVE,
  leadsCount: number,
  leads: arrayOf(object),
  ...createMatchParamsPropTypes({
    id: string.isRequired
  })
}

const withState = connect(
  (state, { match }) => {
    const { id } = match.params
    const { value, isLoading, error } = selectDrives(state)
    const drive = value[id]
    const { value: leadsRaw, count: leadsCount } = selectLeads(state)
    const leads = Object.values(leadsRaw)
    return { leads, isLoading, error, drive, leadsCount }
  },
  { fetchDrive }
)

export default withState(DriveDetails)

// If there's no drive data yet, which happens if you visit
// the link directly instead of navigating from a drive list,
// this hooks fetches drive details as necessary
const driveDetailsHook = ({ drive, id, fetchDrive }) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (drive && !hasLoaded) {
      setHasLoaded(true)
      return
    }

    (async () => {
      try {
        await fetchDrive(id)
        setHasLoaded(true)
      } catch (e) {}
    })()
  }, [])

  return { hasLoaded }
}

const createDriveLeadsDownloadLink = sourceId =>
  `${DRIVE_LEADS_ENDPOINT}/download?${stringify({
    sourceId,
    sourceType: 'drive'
  })}`
