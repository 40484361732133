/* eslint "eqeqeq": "warn", "react/no-string-refs": "warn", "react/no-find-dom-node": "warn" */
import React from 'react'
import ReactDOM from 'react-dom'

import $ from 'jquery'
import Router from '../../core/Router.js'
import findWhere from 'lodash/collection/findWhere'

class PickerField extends React.Component {
  static propTypes = {
    // TODO
  };

  static defaultProps = {
    displayKey: 'name',
    idField: 'id',
    placeholder: 'Type to search...',
    addMsg: 'Add new'
  };

  state = {
    focused: false
  };

  getValue = () => {
    var value = this.props.value || []

    if (!Array.isArray(value))
      value = [value]

    return value
  };

  add = newtoken => {
    var value = this.getValue()
    var existing = findWhere(value, { id: newtoken.id })

    if (!existing) {
      value = value.concat([newtoken])
      this.fireChange(value)
    }
  };

  remove = token => {
    var value = this.getValue()
    var newvalues = []
    value.forEach(function (item, i) {
      if (item.id != token.id)
        newvalues.push(item)
    })

    this.fireChange(newvalues)
  };

  componentDidUpdate (prevProps, prevState) {
    // console.info(this.props.name + " - componentDidUpdate");

    var focusChanged = this.state.focused != prevState.focused
    // console.info(this.props.name + " - focusedChanged - " + focusChanged);

    var $input = $(this.refs.input)
    if (this.isOverLimit()) { $input.hide() } else {
      $input.show()
      if (this.state.focused && focusChanged)
        this.focusInput()
      else if (this.state.focused && (!this.props.value || this.props.value.length == 0))
        this.focusInput()

      var $dom = $(ReactDOM.findDOMNode(this))
      var tokenWidth = 0
      $dom.find('.token').each(function () {
        tokenWidth += $(this).width()
      })
      var width = $dom.width() - tokenWidth

      $input.width(width)
    }

    if (this.state.focused && focusChanged) {
      $(document).on('click.pickerfield.checkblur', this.doBlur)
      if (this.props.onFocus)
        this.props.onFocus()
    } else if (!this.state.focused && focusChanged) {
      $(document).off('click.pickerfield.checkblur', this.doBlur)
      if (this.props.onBlur)
        this.props.onBlur()
    }
  }

  componentDidMount () {
    const compInst = this
    var url = null

    if (this.props.action && this.props.resource)
      url = Router.getUrl(this.props.resource, this.props.action)
    else if (this.props.action)
      url = Router.getUrl(this.props.model, this.props.action)
    else if (this.props.model)
      url = Router.getPickerUrl(this.props.model)

    var props = {
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      datumTokenizer: function (d) {
        // console.info(d);
        return Bloodhound.tokenizers.whitespace(d.name)
      }
    }

    if (url)
      props = Object.assign(props, {
        remote: {
          url: url + '?' + (this.props.argName || 'query') + '=%QUERY',
          filter: function (resp) {
            resp = resp.result || resp
            resp = resp.models || resp

            resp = resp || []

            // hackish...
            if (resp.length > 0 && resp[0].values)
              resp = resp.map(function (c) {
                return Object.assign({ id: c.id }, c.values)
              })

            compInst.suggestions = resp
            return resp
          }
        },
        datumTokenizer: function (d) {
          // console.info(d);
          return Bloodhound.tokenizers.whitespace(d.name)
        }
      })
    else if (this.props.data)
      props.local = this.props.data

    this.engine = new Bloodhound(props)
    this.engine.initialize()

    var allowAdd = this.props.onAdd
    var addMsg = this.props.addMsg

    var $input = $(this.getInputNode())
    $input.typeahead({
      minLength: 2,
      highlight: true
    }, {
      source: this.engine.ttAdapter(),
      displayKey: this.props.displayKey,
      valueKey: this.props.idKey,
      templates: {
        footer: function ({ query }) {
          const isTextSuggested = compInst.checkIfSuggested(query)
          return (!isTextSuggested && allowAdd)
            ? '<div class="footer"><button type="button" class="btn btn-success btn-add">' + addMsg + '</button></div>'
            : ''
        },
        empty: function () {
          return '<div class="empty-message">' +
            '<i>No results found. </i>' +
          '</div>'
        }
      }
    }).on('typeahead:selected', this.onTypeaheadSelect)

    if (allowAdd) {
      var me = this
      $(ReactDOM.findDOMNode(this)).on('click', '.tt-dropdown-menu .btn-add', function () {
        var text = $input.typeahead('val')
        $input.typeahead('val', '')
        me.props.onAdd(text)
      })
    }

    // hack
    $(ReactDOM.findDOMNode(this)).find('.tt-hint').removeAttr('data-reactid')
  }

  checkIfSuggested = query => {
    const suggestions = Array.isArray(this.suggestions) ? this.suggestions : []
    return suggestions.map(s => s.name).includes(query)
  }

  onTypeaheadSelect = (e, token) => {
    $(this.getInputNode()).typeahead('val', '')
    this.add(token)
  };

  getInputNode = () => this.refs.input;

  focusInput = () => {
    this.getInputNode().focus()
  };

  onClick = () => {
    if (!this.state.focused)
      this.setState({ focused: true })
  };

  doBlur = e => {
    // console.info(this.props.name + " - doBlur");
    if (this.checkClick(e))
      this.onBlur()
  };

  checkBlur = e => this.checkClick(e);

  checkClick = e => {
    // console.info(this.props.name + " - checkClick");
    var el = ReactDOM.findDOMNode(this)
    var inside = $(e.target).closest(el).length
    inside = inside || $(e.target).closest('.tt-suggestions').length || $(e.target).closest('.footer').length

    if (inside && $(e.target).is('.tt-input')) {
      var input = $(e.target).closest('.tokenfield').get(0)
      inside = (input == el)
    }

    if (!inside)
      // console.info(this.props.name + " - checkClick - !inside" );
      return true
  };

  onBlur = () => {
    // console.info(this.props.name + " - onBlur");
    this.setState({ focused: false })

    if (this.props.onBlur)
      this.props.onBlur()
  };

  focus = () => {
    if (this.isOverLimit())
      ReactDOM.findDOMNode(this).focus()
    else if (!this.inputFocused)
      this.focusInput()

    this.inputFocused = false
    this.setState({ focused: true })
  };

  onInputFocus = () => {
    this.inputFocused = true
    this.focus()
  };

  onInputBlur = e => {
    // console.info("blur: " + $(this.refs.input).is(":hidden"));

  };

  render () {
    var value = this.getValue()
    var displayKey = this.props.displayKey

    var tokens = value.map(function (item) {
      return (
        <div className='token' key={item.id} data-id={item.id} data-display={item[displayKey]}>
          <span className='token-label'>{item[displayKey]}</span>
          <a href='#' data-token-id={item.id} className='close' tabIndex='-1' onClick={this.onCloseClick}>×</a>
        </div>
      )
    }, this)

    return (
      <div onClick={this.onClick} className={'tokenfield form-control ' + (this.state.focused ? 'focus' : '')}>
        {tokens}
        <span>
          <input type='text'
            className='token-input'
            autoComplete='off'
            placeholder={this.props.placeholder}
            ref='input'
            onFocus={this.onInputFocus}
            onKeyDown={this.onKeyDown}
            onBlur={this.onInputBlur}
          />
        </span>
      </div>
    )
  }

  fireChange = value => {
    if (this.props.limit == 1) {
      if (value.length == 0)
        value[0] = null

      value = value[0]
    }

    if (this.props.onChange)
      this.props.onChange(value)
  };

  onCloseClick = e => {
    e.preventDefault()

    var rid = $(e.target).parents('.token').data('id')
    var rtoken = { id: rid }
    this.remove(rtoken)
  };

  onKeyDown = e => {
    if (e.which == 9)
      this.setState({ focused: false })
  };

  isOverLimit = value => {
    value = value || this.getValue()
    return (this.props.limit || 999999) <= value.length
  };

  componentWillUnmount () {
    $(document).off('click.pickerfield.checkblur', this.doBlur)
  }
}

export default PickerField
