import * as c from '../../actions'

export default (state = {}, { payload, type, meta }) => {
  switch (type) {
    case c.SIGN_DOCUMENT:
      return {
        ...state,
        [meta.documentId]: updateDocument(state[meta.documentId], { loading: true })
      }

    case c.SIGN_DOCUMENT_CANCELLED:
    case c.SIGN_DOCUMENT_FAILURE:
      return {
        ...state,
        [meta.documentId]: updateDocument(state[meta.documentId], { loading: false })
      }

    case c.SIGN_DOCUMENT_SUCCESS:
      return {
        ...state,
        [meta.documentId]: updateDocument(state[meta.documentId], { signed: true })
      }

    default:
      if (payload.entities && payload.entities.esign)
        return { ...state, ...payload.entities.esign }

      return state
  }
}

const updateDocument = (existing, updates) => ({ ...existing, ...updates })
