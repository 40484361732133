/* eslint "no-case-declarations": "warn" */
import without from 'lodash/array/without'
import { combineReducers } from 'redux'
import { SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE, FETCH_SUCCESS, FETCH_FAILURE, FETCH_SETTINGS_SUCCESS, UPDATE_SUCCESS, DESTROY_SUCCESS, UPDATE_SITE_DOMAIN_SUCCESS } from '../actions'

import domains from './domains'
import messages from './messages'
import metrics from './metrics'

const loading = (state = false, { type, payload }) => {
  switch (type) {
    case SEARCH:
      return true

    case SEARCH_SUCCESS:
    case SEARCH_FAILURE:
      return false
  }

  return state
}

const errors = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_FAILURE:
      return {
        ...state,
        [meta.id]: payload
      }
  }

  return state
}

const list = (state = null, { type, payload, meta }) => {
  switch (type) {
    case SEARCH_SUCCESS:
      return payload.result

    case DESTROY_SUCCESS:
      if (!state) return state
      return without(state, meta.id)
  }

  return state
}

const entities = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case SEARCH_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...state,
        ...payload.entities.site
      }

    case UPDATE_SUCCESS:
      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          ...meta.props
        }
      }

    case DESTROY_SUCCESS:
      return {
        ...state,
        [meta.id]: null
      }

    case UPDATE_SITE_DOMAIN_SUCCESS:
      const { domain, id } = meta
      const existing = Object.keys(state).find(id => {
        const domains = Array.isArray(state && state[id] && state[id].domains)
          ? state[id].domains
          : []
        return domains.includes('www.' + domain) || domains.includes(domain)
      })
      const domains = domain ? [domain] : []
      const ret = {
        ...state,
        [id]: {
          ...state[id],
          domains
        }
      }

      if (existing)
        ret[existing] = {
          ...state[existing],
          domains: []
        }

      return ret
  }

  return state
}

const settings = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        [meta.id]: payload
      }

    case DESTROY_SUCCESS:
      return {
        ...state,
        [meta.id]: null
      }
  }

  return state
}

export default combineReducers({
  list,
  errors,
  loading,
  entities,
  settings,
  messages,
  metrics,
  domains
})
