import React, { useMemo, useEffect, FC, ReactElement, useState } from 'react'
import { connect } from 'react-redux'
import { fetchProperty } from 'propertypreviews/actions'
import { Dialog, Button } from '@realsoftworks/decor'
import PropertyPreview from './PropertyPreview/index'
import LoadingButton from 'common/LoadingButton'
import { selectProperty } from 'propertypreviews/selectors'
import qs from 'qs'
import { MinAddress } from 'types'
import { useSearchScreenProps } from 'search/SearchContext'

const withConnect = connect(
  (state, ownProps) => {
    const address = ownProps.address || {}
    const id = ownProps.propertyId || [address.line1, address.city, address.state, address.zip]
      .join(':')
    const { status } = selectProperty(state, id) || {}
    const isReady = status === 'SUCCESS'
    return { isReady }
  },
  { fetchProperty }
)

const AddressPreviewDialog = withConnect(({
  address: maybeAddress,
  propertyId,
  fetchProperty,
  isReady,
  isOpen,
  onRequestClose
}: {
  address: MinAddress
  propertyId?: string
  fetchProperty: (a: MinAddress, propertyId?: string) => void
  isReady: boolean
  isOpen: boolean
  onRequestClose: (isOpen: boolean) => void
}): ReactElement => {
  const [loading, setLoading] = useState(false)
  const { specificSearch } = useSearchScreenProps() as any
  const { line1, city, state, zip } = maybeAddress || {}
  const address = useMemo(() =>
    ({ line1, city, state, zip }),
  [line1, city, state, zip])

  useEffect(() => {
    if (!isOpen) return
    fetchProperty(address, propertyId)
  }, [isOpen])

  const handleSearch = () => {
    if (!specificSearch) return

    setLoading(true)
    specificSearch({ address, propertyId })
  }

  return (
    <Dialog
      title='Property Preview'
      width='400px'
      open={isOpen}
      onClose={onRequestClose}
      toolbar={(
        <>
          {isReady && (
            <LoadingButton variant='default' onClick={handleSearch} loading={loading} loadingText='Loading Details...'>
              Go to Full Details
            </LoadingButton>
          )}
          <Button variant='primary' onClick={onRequestClose}>Go Back</Button>
        </>
      )}
    >
      <PropertyPreview address={address} propertyId={propertyId} />
    </Dialog>
  )
})

export default AddressPreviewDialog
