import React, { useState, useCallback, useEffect } from 'react'
import { Box, Card, Text, IconButton } from '@realsoftworks/decor'
import PropertyDetails from './PropertyDetails'
import BigLoader from 'common/BigLoader'
import WrappedNeighborhood from './WrappedNeighborhood'
import PropertyTabsHeader from './PropertyTabsHeader'
import { useSearchScreenProps } from 'search/SearchContext'
import SearchCMAHandler from 'cma/views/SearchCMAHandler'
import SearchLeadViewHandler from 'leads/components/SearchLeadViewHandler'
import IfLeadHasPropertyDataInDb from './IfLeadHasPropertyDataInDb'
import SearchTasks from 'leads/components/SearchTasks'
import PermissionComponent from 'users/components/PermissionComponent'
import FeatureComponent from 'billing/components/FeatureComponent'
import { FEATURES } from 'billing/constants'
import { SIDEBAR_HEADER_HT } from 'search/components/SearchSidebar/SearchSidebar'
import { PROP_MARGIN_BY_BP } from 'search/components/SearchHistory'
import useBreakpoint from 'common/util/hooks/useBreakpoint'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import getStyledColor from 'common/util/getStyledColor'
import Skiptrace from './Skiptrace'
import qs from 'qs'
import TabUnavailable from '../TabUnavailable'

const Property = ({ history, match, location }) => {
  const leadId = match && match.params && match.params.leadId
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(0)
  const { lead, propertyDetails, getPropertyDetails } = useSearchScreenProps()
  const { mdDown } = useBreakpoint()
  const { latitude, longitude } = propertyDetails?.address?.coordinates || {}
  const areCoordinatesSet = typeof latitude === 'number' && typeof longitude === 'number'
  const [renderSearchCMAHandler, setRenderSearchCMAHandler] = useState(true)

  const tabTitlesPro = [
    mdDown ? 'Details' : 'Property Details',
    mdDown ? 'CMA' : 'Comparable Sales',
    'Buyers',
    mdDown ? 'Notes' : 'Media/Notes',
    'Tasks',
    'Skiptrace'
  ]

  // TODONOW: can non pro skiptrace?
  const tabTitlesNonPro = [
    tabTitlesPro[0],
    tabTitlesPro[1],
    null,
    tabTitlesPro[3],
    tabTitlesPro[4],
    tabTitlesPro[5]
  ]

  useEffect(() => {
    if (!renderSearchCMAHandler)
      setTimeout(() => {
        setRenderSearchCMAHandler(true)
      }, 1000)
  }, [renderSearchCMAHandler])

  useEffect(() => {
    getPropertyDetails({ leadId })
      .then(() => setIsLoading(false))
      .catch(() => {}) // Error is already handled in getPropertyDetails()
  }, [])

  const queries = qs.parse(location.search.replace(/^\?/, '')) || {}
  useEffect(() => {
    if (!queries.tab) return
    const idx = tabTitlesPro
      .findIndex(title => title.toLowerCase() === String(queries.tab).toLowerCase())
    if (idx > -1)
      setActiveTab(idx)
  }, [queries.tab])

  const openTasksTab = useCallback(() =>
    setActiveTab(4),
  [setActiveTab])

  const renderTab = (t, i) => t && (
    <Box key={i} px={['2px', 1]}>
      <Tab
        isActive={activeTab === i}
        onClick={() => setActiveTab(i)}
      >
        {t}
      </Tab>
    </Box>
  )

  const handleClose = useCallback(() =>
    history.push('/search/history'),
  [history])

  const handleOpenSkiptraceTab = useCallback(() => {
    setActiveTab(SKIPTRACE_INDEX)
  }, [setActiveTab])

  const handleOpenCmaTab = useCallback(() => {
    setActiveTab(CMA_INDEX)
  }, [setActiveTab])

  return (
    <Box
      as='section'
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      className='print-noflex print-normalize-container'
    >
      {/* Property Header */}
      {!isLoading && (
        <PropertyTabsHeader
          property={propertyDetails}
          openTasksTab={openTasksTab}
        />
      )}

      {/* Tabs - Note: z-index is necessary for shadow stuff */}
      <Box
        display='flex'
        ml={['-2px', -1]}
        css='position: relative; z-index: 2;'
        className='print-hide'
      >
        <FeatureComponent
          feature={FEATURES.LEADS} alternative={((
            tabTitlesNonPro.map(renderTab)
          ))}
        >
          <PermissionComponent
            action='manage' model='leadlists' alternative={(
              tabTitlesNonPro.map(renderTab)
            )}
          >
            {tabTitlesPro.map(renderTab)}
          </PermissionComponent>
        </FeatureComponent>
      </Box>

      {/* Tab Contents */}
      <Card
        width='100%'
        flexGrow={1}
        bg='blue.100'
        boxShadow={2}
        overflowY='auto'
        className='print-normalize-container print-white-bg'
        css={`
          position: relative;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        `}
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='stretch'
          alignContent='center'
          height='100%'
          width='100%'
          css='position: absolute; z-index: 2;'
          className='print-noflex print-normalize-container'
        >
          {/* Modal Close Button */}
          <Box
            css={`
              position: absolute;
              z-index: 3;
              top: 8px;
              right: 12px;
            `}
          >
            <IconButton
              onClick={handleClose}
              display='block'
              css={`
                &, &:hover, &:active,  &:focus, &:visited {
                  text-decoration: none;
                  color: ${getStyledColor('secondary')};
                }
              `}
              className='print-hide'
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Box>

          {isLoading ? (
            <Box
              height='100%'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <BigLoader />
            </Box>
          ) : (
            <>
              {/* Details Tab */}
              {activeTab === DETAILS_INDEX && (
                <IfLeadHasPropertyDataInDb
                  hasPropertyDataInDb={lead && lead.hasPropertyDataInDb}
                >
                  <PropertyDetails
                    property={propertyDetails}
                    onOpenSkiptraceTab={handleOpenSkiptraceTab}
                    onOpenCmaTab={handleOpenCmaTab}
                  />
                </IfLeadHasPropertyDataInDb>
              )}

              {/* Comparable Sales tab (using CMA component) */}
              {activeTab === CMA_INDEX && renderSearchCMAHandler && (
                <SearchCMAHandler
                  leadId={lead.id}
                  setRenderSearchCMAHandler={bool => setRenderSearchCMAHandler(bool)}
                  address={propertyDetails?.address || {}}
                />
              )}

              {/* Buyers Tab (using Neighborhood component) */}
              {activeTab === BUYERS_INDEX && (
                areCoordinatesSet ? (
                  <WrappedNeighborhood />
                ) : (
                  <TabUnavailable>We don’t have enough info on this property to display buyers</TabUnavailable>
                )
              )}

              {/* Media/Notes tab */}
              {activeTab === NOTES_INDEX && (
                <Box p={2} className='lead-view-ct'>
                  <SearchLeadViewHandler leadId={lead && lead.id} />
                </Box>
              )}

              {/* Tasks tab */}
              {activeTab === TASKS_INDEX && (
                <Box p={2} className='lead-view-ct'>
                  <SearchTasks leadId={lead && lead.id} />
                </Box>
              )}

              {/* Skiptrace tab */}
              {activeTab === SKIPTRACE_INDEX && (
                <Box p={2} className='lead-view-ct' minHeight='100%'>
                  <Skiptrace
                    address={propertyDetails?.address}
                    contactAddress={propertyDetails?.contactAddress}
                    skiptraces={propertyDetails?.skiptraces}
                    hasSkiptraced={propertyDetails?.skiptraces && propertyDetails?.skiptraces?.length > 0}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Card>
    </Box>
  )
}

export default Property

const DETAILS_INDEX = 0
const CMA_INDEX = 1
const BUYERS_INDEX = 2
const NOTES_INDEX = 3
const TASKS_INDEX = 4
const SKIPTRACE_INDEX = 5

const Tab = ({ isActive, children, ...props }) => {
  const { current } = useBreakpoint()
  return (
    <Card
      px={[2, null, 3]}
      height={`${SIDEBAR_HEADER_HT - PROP_MARGIN_BY_BP[current]}px`}
      bg={isActive ? 'blue.100' : 'neutral.200'}
      boxShadow={2}
      display='flex'
      alignItems='center'
      css={`
        cursor: pointer;
        position: relative;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      `}
      {...props}
    >
      <Text fontSize={['13px', null, null, 0]}>
        {children}
      </Text>

      {/**
        * Covers the shadow between tabs and content
        * to create a continuous illusion
        */}
      <Box
        height='8px'
        bg='blue.100'
        css={`
          position: absolute;
          bottom: -8px;
          left: 0;
          right: -8px;
          z-index: 1;
        `}
      />
    </Card>
  )
}
