/* eslint "eqeqeq": "warn", "no-useless-escape": "warn" */

import numeral from 'numeral'
import times from 'lodash/utility/times'

var groupSep = ','

var gre = new RegExp(groupSep, 'g')

export function toK (amount, { dollars = true, decimalPlaces = 1, decimalOptional = true } = {}) {
  amount = amount / 1000
  var ending = 'k'

  if (amount > 1000) {
    amount = amount / 1000
    ending = 'M'
    if (decimalPlaces == 1)
      decimalPlaces = 2

    if (amount > 1000) {
      amount = amount / 1000
      ending = 'B'
    }
  }

  if ((decimalOptional && amount % 1 > 0) || !decimalOptional)
    amount = amount.toFixed(decimalPlaces)

  if (dollars)
    amount = '$' + amount

  return amount + ending
}

export function parse (val, props = {}) {
  if (val === null || typeof val === 'undefined' || typeof val === 'number')
    return val

  return numeral().unformat(val)
}

export function format (val, props = {}) {
  let format = '0,000[.]00'

  if (props.format) { format = props.format } else if (props.decimals === 0) { format = '0,000' } else if (typeof props.decimals === 'number' && props.decimals > 0) {
    const zeroes = times(props.decimals, () => '0').join('')
    format = `0,000[.]${zeroes}`
  }

  if (props.forceZeros)
    format = format.replace(/[\[\]]/g, '')

  if (val === null || typeof val === 'undefined' || val === '')
    return ''

  if (format == false)
    return val

  if (typeof val === 'string')
    val = val.replace(gre, '')

  val = numeral(val).format(format)

  return val
}

export function monetary (val, props = { decimals: 2 }) {
  var decimals = typeof props.decimals !== 'undefined' ? props.decimals : 2

  var numberformat = '0,000'
  if (decimals > 0)
    numberformat += '.' + times(decimals, () => '0').join('')

  var value = format(val, { format: numberformat })
  if (value)
    value = '$' + value

  return value
}
