/* eslint "react/no-unescaped-entities": "warn", "eqeqeq": "warn" */
import React from 'react'

import Logo from './Logo'

import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { selectors as mlsSelectors } from '../../../modules/mls'
import { fetchMyBrokers } from '../../../modules/mls/actions'
import DateUtil from '../../../core/date'

class NTREISDisclosure extends React.Component {
  render () {
    return (
      <div>
        Copyright © {new Date().getFullYear()}, by North Texas Real Estate Information Systems, Inc. All Rights Reserved<br />
        The listing information herein is provided from and copyrighted by the North Texas Real Estate Information Systems, Inc.
        Data provided by NTRIES is for personal, non-commercial use, may not be redistributed or reproduced.<br />

        Real estate listings held by brokerage firms other than the broker are marked with the NTREIS logo. All properties are subject to prior sale or withdrawal.
      </div>
    )
  }
}

class GMARDisclosure extends React.Component {
  render () {
    return (
      <div>
        Copyright © {new Date().getFullYear()}, by Greater McAllen Association of Realtor. All Rights Reserved<br />
        The listing information herein is provided from and copyrighted by the Greater McAllen Association of Realtor.
        Data provided by GMAR is for personal, non-commercial use, may not be redistributed or reproduced.<br />

        Real estate listings held by brokerage firms other than the broker are marked with the GMAR logo. All properties are subject to prior sale or withdrawal.
      </div>
    )
  }
}

class GTARDisclosure extends React.Component {
  render () {
    return (
      <div>
        Copyright © {new Date().getFullYear()}, by Greater Tyler Area Realtors. All Rights Reserved<br />
        The listing information herein is provided from and copyrighted by the Greater Tyler Area Realtors.
        Data provided by GTAR is for personal, non-commercial use, may not be redistributed or reproduced.<br />

        Real estate listings held by brokerage firms other than the broker are marked with the GTAR logo. All properties are subject to prior sale or withdrawal.
      </div>
    )
  }
}

class HARDisclosure extends React.Component {
  render () {
    return (
      <div>
        © Copyright {new Date().getFullYear()} Houston Realtors Information Service, Inc. - Certain information contained herein is derived
        from information which is the property of, and copyrighted by, Houston Realtors Information Service, Inc.
      </div>
    )
  }
}

class MARDisclosure extends React.Component {
  render () {
    return (
      <div>
        © Copyright {new Date().getFullYear()} Miami Area Realtors - by using this software, you acknowledges the MLS' ownership of and the validity of the MLS' copyright in the MLS
        database.
      </div>
    )
  }
}

class MIBORDisclosure extends React.Component {
  render () {
    return (
      <div>
        The Broker Listing Cooperative® listing service Listing Information displayed is deemed reliable but is not guaranteed accurate by the Broker Listing Cooperative® listing service. © Copyright {new Date().getFullYear()} MIBOR REALTOR® Association.
      </div>
    )
  }
}

class SABORDisclosure extends React.Component {
  render () {
    return (
      <div>
        The SABOR does not guarantee or is
        in any way responsible for its accuracy. All data is provided 'AS IS' and with all faults. Data maintained by
        the SABOR MLS may not reflect all real estate activity in the market.<br />
        Copyright {new Date().getFullYear()} San Antonio Board of REALTORS©
      </div>
    )
  }
}

class ABORDisclosure extends React.Component {
  render () {
    var { startDate, asOfDate } = this.props

    var periodText = (startDate && asOfDate)
      ? (DateUtil.format(startDate) + ' through ' + DateUtil.format(asOfDate))
      : 'specified in the "Criteria" above'

    return (
      <div>
        Based on information from the Austin Board of REALTORS® (alternatively, from ACTRIS) for the period {periodText}.<br />
        All information provided is deemed reliable but is not guaranteed and should be independently verified.
        The Austin Board of REALTORS®, ACTRIS and their affiliates provide the MLS and all content therein “AS IS” and without any warranty, express or implied.<br />
        Copyright {new Date().getFullYear()} Austin Central Texas Realty Information Services, Inc
      </div>
    )
  }
}

class ARMLSDisclosure extends React.Component {
  render () {
    var { startDate, asOfDate } = this.props

    var periodText = (startDate && asOfDate)
      ? (DateUtil.format(startDate) + ' through ' + DateUtil.format(asOfDate))
      : 'specified in the "Criteria" above'

    return (
      <div>
        Based on information from ARMLS for the period {periodText}.<br />
        All information should be verified by the recipient and none is guaranteed as accurate by ARMLS.
      </div>
    )
  }
}

const MARISDisclosure = props => (
  <div>
      ©{new Date().getFullYear()} MARIS. All rights reserved. Listings are courtesy of the participants of Mid America Regional Information Systems.
      Information deemed reliable but not guaranteed and should be independently verified. All properties are subject to prior sale, change or withdrawal.
      Neither listing broker(s) nor Broker shall be responsible for any typographical errors, misinformation, misprints and shall be held totally harmless.
    {/* Real estate listings held by brokerage firms other than Dielmann Sotheby's are marked with the IDX logo and detailed information about them includes the name of the listing brokers. */}
      Listings displaying the MARIS logo are courtesy of the participants of Mid America Regional Information Systems Internet Data Exchange.
  </div>
)

const FGCDisclosure = props => {
  var { asOfDate } = props

  var periodText = (asOfDate)
    ? (`on: ${DateUtil.format(asOfDate)}`)
    : 'as specified in the "Criteria" above'

  return (
    <div>
      ©{new Date().getFullYear()} Royal Palm Coast REALTOR® Association. All rights reserved. Information has not been verified, is not guaranteed, and is subject to change.<br />
      The data relating to real estate for sale on this web site comes in part from the Internet Data Exchange (IDX) of the Florida Gulf Coast Multiple Listing Service, Inc. Real estate listings held by brokerage firms other than (populate web site owners Brokerage Name) IDX logo and detailed information about them includes the name of the listing brokers. The information being provided is for consumers personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. <br />
      Data for this report was pulled {periodText}
    </div>
  )
}

const MREDDisclosure = props => (
  <div>
      ©{new Date().getFullYear()} MRED LLC - The accuracy of all information, regardless of source, including but not limited to square footages and lot sizes, is deemed reliable but not guaranteed and should be personally verified through personal inspection by and/or with the appropriate professionals.
  </div>
)

const MFRDisclosure = props => (
  <div>
      Listing information ©{new Date().getFullYear()} Stellar MLS.
    <img style={{ display: 'block', margin: '0 auto', maxWidth: 200 }} src='//pics.stellarmls.com/StellarMLS_horizontal_color-copy.png' />
  </div>
)

let Disclosure = ({ source, startDate, asOfDate, broker }) => (

  <div className='cma-disclosure'>

    {broker ? <BrokerContact broker={broker} /> : ''}

    <br />
    {broker && <>
      If you have a representation agreement with another broker, please let us know so we can discontinue this service.<br /><br />

      This data is only for clients or potential clients of the broker. We do not independently verify
      the currency, completeness, accuracy or authenticity of the data contained herein. The data may be subject to
      transcription and transmission errors. Accordingly, the data is provided on an ‘as is, as available’ basis only. <br />

    </>}

    {source == 'ntreis' || source == 'ntreis-v2' ? <NTREISDisclosure /> : ''}
    {source == 'fgc' ? <FGCDisclosure asOfDate={asOfDate} /> : ''}
    {source == 'har' ? <HARDisclosure /> : ''}
    {source == 'sabor' ? <SABORDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'abor' ? <ABORDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'armls' ? <ARMLSDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'maris' ? <MARISDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'mar' ? <MARDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'mfr' ? <MFRDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'mred' ? <MREDDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'mibor' ? <MIBORDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'gtar' ? <GTARDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'gmar' ? <GMARDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}
    {source == 'tr-actris' ? <ABORDisclosure startDate={startDate} asOfDate={asOfDate} /> : ''}

    <Logo source={source} />

  </div>
)

const BrokerContact = ({ broker }) => (
  <div>
    This service provided by broker:<br />
    {broker.name}<br />
    {broker.phone}<br />
  </div>
)

Disclosure = compose(

  connect(
    (state, props) => ({
      broker: mlsSelectors.getBrokerBySource(state, props.source)
    })
  ),

  lifecycle({
    componentDidMount () {
      if (!this.props.broker)
        this.props.dispatch(fetchMyBrokers())
    }
  })

)(Disclosure)

export default Disclosure
