import {
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE
} from '../actions'

const initState = { team: null, isLoading: false }

export default (state = initState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_TEAM:
      return { ...state, isLoading: true }
    case FETCH_TEAM_SUCCESS:
      return { ...state, isLoading: false, value: payload }
    case FETCH_TEAM_FAILURE:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
