import { curry } from 'lodash'

/**
 * Creates a new version of `obj` where the supplied `path` is set to `value.`
 * This function is PURE / NO SIDE-EFFECT in contract to lodash's `set`
 */
const set: any = curry((pathStrOrArr, value, maybeObjOrArr) => {
  const objOrArr = maybeObjOrArr || {}
  const path = typeof pathStrOrArr === 'string'
    ? pathStrOrArr.split('.')
    : pathStrOrArr
  if (!path || !path.length) return
  const [phead, ...ptail] = path
  if (!phead && phead !== 0) return objOrArr
  const clone = Array.isArray(objOrArr) ? [...objOrArr] : { ...objOrArr }
  const val = ptail.length
    ? set(ptail, value, objOrArr[phead] || {})
    : value
  clone[phead] = val
  return clone
})

export default set
