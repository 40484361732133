/* eslint "react/no-deprecated": "warn", "react/no-unescaped-entities": "warn" */

import React from 'react'

import MLSTermsOfUse from './MLSTermsOfUse'
import EmailConfirm from './EmailConfirm'
import LoadingButton from 'common/LoadingButton.js'

class BrokerRequirements extends React.Component {
  state = {
    isSendingConfirmEmail: false,
    savingCmaTOS: false,
    savingBrokerTOS: false,
    savingSigning: false, // TODO to add this functionality back in, we need to split the action some how
    loadingSigning: false
  };

  componentWillMount () {
    this.promises = {}

    const { source, broker, user } = this.props
    this.props.startAuth(source, broker, user)
  }

  sendConfirmEmail = () => {
    var finish = () => {
      if (this.promises.sendConfirmEmail) {
        this.setState({ isSendingConfirmEmail: false })
        this.promises.sendConfirmEmail = null
      }
    }

    this.setState({ isSendingConfirmEmail: true })
    this.promises.sendConfirmEmail = this.props.sendConfirmEmail({ url: window.location.href })
      .then(finish, e => { finish(); throw e })
  };

  checkConfirmEmail = () => {
    this.props.checkConfirmEmail()
  };

  acceptTerms = () => {
    var finish = () => {
      if (this.promises.acceptTerms) {
        this.setState({ savingCmaTOS: false })
        this.promises.acceptTerms = null
      }
    }

    this.setState({ savingCmaTOS: true })
    this.promises.acceptTerms = this.props.acceptMLSTerms()
      .then(finish, e => { finish(); throw e })
  };

  acceptBrokerTerms = id => {
    var finish = () => {
      if (this.promises.acceptBrokerTerms) {
        this.setState({ savingBrokerTOS: false })
        this.promises.acceptBrokerTerms = null
      }
    }

    this.setState({ savingBrokerTOS: true })
    this.promises.acceptBrokerTerms = this.props.acceptBrokerTerms(id)
      .then(finish, e => { finish(); throw e })
  };

  componentWillUnmount () {
    this.promises = {}

    const { source, broker, user } = this.props
    this.props.cancelAuth(source, broker, user)
  }

  render () {
    const { broker, user, email } = this.props
    const { isSendingConfirmEmail, savingCmaTOS, savingBrokerTOS } = this.state
    const brokerRequirements = broker.requirements

    return (
      <div className='cma-additional-terms card'>

        <h2>Additional Terms</h2>
        <p>Before we can provide you with any MLS data, you'll need to agree to some additional terms and conditions.</p>

        <div className={'additional-step step-1 tos-step ' + (user.emailConfirmed ? 'done' : '')}>
          <h3>Confirm Your Email</h3>
          <div className='step-wrap'>
            <EmailConfirm email={email} checkEmail={this.checkConfirmEmail} confirmed={user.emailConfirmed} loading={isSendingConfirmEmail} onRequestEmail={this.sendConfirmEmail}/>
          </div>
        </div>
        <div className={'additional-step step-2 tos-step ' + (user.termsOfService ? 'done' : '')}>
          <h3>MLS Data Terms of Use</h3>
          <div className='step-wrap'>
            <MLSTermsOfUse onAgree={this.acceptTerms} termsOfServiceText={user.termsOfServiceText} saving={savingCmaTOS}/>
          </div>
        </div>
        {
          (brokerRequirements && brokerRequirements.terms)
            ? brokerRequirements.terms.map(t =>
              <BrokerTerms key={t.id} terms={t} onAccept={this.acceptBrokerTerms} saving={savingBrokerTOS}/>
            )
            : ''
        }
      </div>
    )
  }
}

const BrokerTerms = ({ terms, onAccept, saving }) => (
  <div className={'additional-step step-3 broker-step ' + (terms.agreed ? 'done' : '')}>
    <h3>Additional Broker Terms</h3>
    <div className='step-wrap'>
      <div className='cma-terms-of-use'>
        <div className='tos-copy-wrap'>
          <div dangerouslySetInnerHTML={{ __html: terms.value }}/>
        </div>

        <LoadingButton
          variant='primary'
          loading={saving}
          onClick={() => onAccept(terms.id)}
          className='btn btn-success btn-lg'
          text='Agree to Terms'
          loadingText='Saving...'/>
      </div>
    </div>
  </div>
)

export default BrokerRequirements
