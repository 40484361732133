/* eslint "eqeqeq": "warn", "react/jsx-key": "warn" */

import React from 'react'

class SimpleList extends React.Component {
  static defaultProps = {
    value: [],
    displayKey: null
  };

  render () {
    var values = this.props.value
    var dk = this.props.displayKey
    if (values.length == 0) {
      var obj = {}
      obj[dk] = ''
      values.push(obj)
    }

    return (
      <div className='list'>
        {values.map(function (v, i) {
          return (<input type='text' ref={'field' + i} className='form-control' value={v[dk]} onChange={this.onChange.bind(this, i)} />)
        }, this)}
        <a className='' onClick={this.onAddAnother}>Add another</a>
      </div>
    )
  }

  onChange = (i, e) => {
    var v = e.target ? e.target.value : e
    var cv = [].concat(this.props.value)
    if (i == cv.length)
      cv.push({})

    cv[i][this.props.displayKey] = v
    this.props.onChange(cv)
  };

  onAddAnother = () => {
    if (this.props.value[this.props.value.length - 1][this.props.displayKey] != '')
      this.onChange(this.props.value.length, '')
  };
}

export default SimpleList
