/* eslint "eqeqeq": "warn" */
import React from 'react'
import PropTypes from 'prop-types'

import Big404 from './Big404'
import BigFatalError from './BigFatalError'

import './bigerror.less'

class BigError extends React.Component {
  static propTypes = {
    error: PropTypes.shape({
      type: PropTypes.string.isRequired
    })
  };

  render () {
    const { error, link } = this.props

    let errorEl = <BigFatalError/>

    switch (error.type) {
      case 'invalid_request_error':
        if (error.param == 'id')
          errorEl = <Big404/>
    }

    return (
      <div className='big-error'>
        {errorEl}
        {
          link &&
            <div className='big-error-link-ct'>
              {link}
            </div>
        }
      </div>
    )
  }
}

export default BigError
