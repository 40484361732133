import React from 'react'
import HomeSearch from './components/HomeSearch'
import SearchHistory from './components/SearchHistory'
import { Box } from '@realsoftworks/decor'
import { useRemoveDefaultLayoutUntilUnmount } from 'common/util/useRemoveDefaultLayout'
import DocumentTitle from 'react-document-title'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '../../const'
import withRouteReq from 'redirect/withRouteReq'

const SearchScreen = () => {
  // Remove the default layout for this screens
  useRemoveDefaultLayoutUntilUnmount()

  return (
    <DocumentTitle title='Search | Propelio'>
      <Box>
        <Switch>
          <Route path='/search' exact component={HomeSearchWithReq} />
          <Route path='/search/history' component={SearchHistoryWithReq} />
        </Switch>
      </Box>
    </DocumentTitle>
  )
}

export default SearchScreen

const HomeSearchWithReq = withRouteReq(ROUTES.SEARCH, HomeSearch)
const SearchHistoryWithReq = withRouteReq(
  ROUTES.SEARCH_HISTORY,
  SearchHistory
)
