import React from 'react'

import './page.less'

const Page = ({ className, ...rest }) => (
  <div className={'common-page ' + className} {...rest}/>
)

class Title extends React.Component {
  static defaultProps = {
    animate: false
  };

  state = { in: !this.props.animate };

  componentDidMount () {
    if (this.props.animate)
      setTimeout(function () {
        this.setState({ in: true })
      }.bind(this), 500)
  }

  render () {
    return (
      <div className={'common-page-title ' + (this.state.in ? 'in' : '') } >
        {this.props.children}
      </div>
    )
  }
}

Page.Title = Title

export default Page

export { Page, Title }
