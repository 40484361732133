import React from 'react'
import DateHelper from '../../../core/date'

export default class CMALastUpdated extends React.PureComponent {
  render () {
    var { date } = this.props

    return (
      <div className='cma-last-updated'>
        Last Updated: {DateHelper.format(date, DateHelper.formats.UI_DATETIME)}
      </div>
    )
  }
}
