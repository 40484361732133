import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { Heading, Paragraph, Box, Label, Text, Card as BaseCard, Button, Caption } from '@realsoftworks/decor'
import Card from 'common/Card'
import FontIcon from 'common/FontIcon'
import { Numeric } from 'common/format'
import CountyPicker from '../../CountyPicker'
import CityPicker from '../../CityPicker'
import ZipPicker from '../../ZipPicker'
import BigLoader from 'common/BigLoader'

import { Equity, OwnerType } from '../constants'

const Icon = styled.div``

const BaseSelectButton = styled(BaseCard)`
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid;
  font-weight: bold;
  color: ${props => themeGet(`colors.${props.color}.800`)(props)};
  padding: ${themeGet('space.3')}px;

  background-color: ${props => themeGet(`colors.${props.color}.200`)(props)};
  border-color: ${props => themeGet(`colors.${props.color}.200`)(props)};

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  ${Icon} {
    transform: scale(0);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    top: 4px;
    right: 4px;
    line-height: 16px;
  }

  &:hover {
    background-color: ${props => themeGet(`colors.${props.color}.300`)(props)};
    border-color: ${props => themeGet(`colors.${props.color}.300`)(props)};
  }

  &[data-selected=true] {
    background-color: ${props => themeGet(`colors.${props.color}.300`)(props)};
    border-color: ${props => themeGet(`colors.${props.color}.800`)(props)};
    /*
    box-shadow: ${props => themeGet('shadows.1')(props)};
    transform: translateY(-4px);
    */

    ${Icon} {
      transform: scale(1);
    }
  }
`

const SelectButton = ({ color, label, count, selected, ...props }) => (
  <BaseSelectButton color={color} data-selected={selected} {...props}>
    <Icon><FontIcon icon='checkcircle'/></Icon>
    <Text as='div' textAlign='center' fontWeight='bold' color='inherit' fontSize={2} mb={1}>
      <Numeric>{count}</Numeric>
    </Text>
    <Text as='div' textAlign='center' fontWeight='bold' color='inherit' fontSize={1}>{label}</Text>
  </BaseSelectButton>
)

const SectionLabel = ({ ...props }) => <Heading size={6} mb={2} {...props}/>

const OwnerSection = ({ value, onChange, counts }) => (
  <Box display='flex' mx={-3}>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === OwnerType.ABSENTEE}
        color='yellow'
        count={counts[OwnerType.ABSENTEE]}
        label='Absentee'
        onClick={() => onChange(OwnerType.ABSENTEE)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === OwnerType.TRUST}
        color='red'
        count={counts[OwnerType.TRUST]}
        label='Trust Owned'
        onClick={() => onChange(OwnerType.TRUST)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === OwnerType.BANK}
        color='blue'
        count={counts[OwnerType.BANK]}
        label='Bank Owned'
        onClick={() => onChange(OwnerType.BANK)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === OwnerType.OWNEROCCUPIED}
        color='teal'
        count={counts[OwnerType.OWNEROCCUPIED]}
        label='Occupants'
        onClick={() => onChange(OwnerType.OWNEROCCUPIED)}
      />
    </Box>
  </Box>
)

const EquitySection = ({ value, onChange, counts }) => (
  <Box display='flex' mx={-3}>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === Equity.HIGH}
        color='yellow'
        count={counts[Equity.HIGH]}
        label='High Equity'
        onClick={() => onChange(Equity.HIGH)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === Equity.LOW}
        color='red'
        count={counts[Equity.LOW]}
        label='Low Equity'
        onClick={() => onChange(Equity.LOW)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === Equity.UNDERWATER}
        color='blue'
        count={counts[Equity.UNDERWATER]}
        label='Upside Down'
        onClick={() => onChange(Equity.UNDERWATER)}
      />
    </Box>
    <Box flex='1' mb={5} mx={3}>
      <SelectButton
        selected={value === Equity.FREEANDCLEAR}
        color='teal'
        count={counts[Equity.FREEANDCLEAR]}
        label='Free and Clear'
        onClick={() => onChange(Equity.FREEANDCLEAR)}
      />
    </Box>
  </Box>
)

const pickValues = obj => Object.keys(obj).reduce((acc, v) => {
  if (obj[v])
    acc[v] = obj[v]

  return acc
}, {})

class Sellers extends React.Component {
  static propTypes = {
    fetchCounts: PropTypes.func.isRequired,
    counts: PropTypes.object
  }

  state = {

  }

  onChangeOwner = owner => {
    let value = owner
    if (this.state.ownerType === owner)
      value = null

    this.setState({ ownerType: value }, this.doFetchCounts)
  }

  onChangeEquity = equity => {
    let value = equity
    if (this.state.equity === equity)
      value = null

    this.setState({ equity: value }, this.doFetchCounts)
  }

  onChangePicker = (picker, value) => {
    this.setState({ [picker]: value }, this.doFetchCounts)
  }

  doFetchCounts = () => {
    // only grab non-null keys
    const params = pickValues(this.state)
    this.props.fetchCounts(params)
  }

  onSearch = () => {
    this.props.onSearch(pickValues(this.state))
  }

  render () {
    const { ownerType, equity, county, city, zip } = this.state
    let { counts } = this.props
    const hasCounts = !!counts
    if (!hasCounts)
      counts = { ownerType: {}, equity: {} }

    return (
      <Card borderTop='12px solid' borderColor='teal.500'>
        <Heading textAlign='center' size={2} mb={3}>Property Lists</Heading>
        <Paragraph mb={5}>
          Thousands of leads are now at your fingertips! Filter your results by geography, owner type, financials and more to
          find the perfect leads for your next campaign.
        </Paragraph>
        <Box>
          <Box display='flex' mx={-3}>
            <Box flex='1' mx={3} mb={5}>
              <Label>County</Label>
              <CountyPicker
                value={county}
                onChange={ value => this.onChangePicker('county', value)}
              />
            </Box>
            <Box flex='1' mx={3} mb={5}>
              <Label>City</Label>
              <CityPicker
                value={city}
                onChange={ value => this.onChangePicker('city', value)}
              />
            </Box>
            <Box flex='1' mx={3} mb={5}>
              <Label>Zipcode</Label>
              <ZipPicker value={zip} onChange={ value => this.onChangePicker('zip', value)}/>
            </Box>
          </Box>
        </Box>
        <BigLoader loading={!hasCounts}>
          <React.Fragment>
            <Box>
              <SectionLabel>Owner Type</SectionLabel>
              <OwnerSection
                value={ownerType}
                onChange={this.onChangeOwner}
                counts={counts.ownerType}
              />
            </Box>
            <Box>
              <SectionLabel>Equity</SectionLabel>
              <EquitySection
                value={equity}
                onChange={this.onChangeEquity}
                counts={counts.equity}
              />
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center' mt={5}>
              <Box mx={4}>
                <Caption color='teal.500' display='block' textAlign='center'>Property Lists</Caption>
                <Heading textAlign='center' size={3} color='teal.900'>
                  <Numeric>{counts.total}</Numeric>
                </Heading>
              </Box>
              <Button mx={4} onClick={this.onSearch} sizeVariant='large' variant='primary'>Show me the Leads!</Button>
            </Box>
          </React.Fragment>
        </BigLoader>

      </Card>
    )
  }
}

export default Sellers
