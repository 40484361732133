/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import mls from '../../mls'
import { create, fetchCount } from '../actions'
import { connect } from 'react-redux'

import BigLoader from 'common/BigLoader'
import { Crumb } from '../../../views/common/Breadcrumbs'
import SearchForm from './searches/SearchForm'

import { getMetadata, isMetadataLoading } from '../../mls/selectors'

class DealAlertAddHandler extends Component {
  state = {
    value: {}
  };

  componentWillMount () {
    this.props.fetchMetadata()
  }

  onChange = value => {
    var shouldUpdate = value.name == this.state.value.name

    this.setState({ value })

    if (shouldUpdate)
      this.fetchCount(value)
  };

  fetchCount (value) {
    this.setState({ loadingCount: true })

    var prom = this.props.fetchCount(value)
    prom.then(r => {
      if (prom.cancelled) return
      this.setState({ count: r.payload.count, loadingCount: false })
    })
  }

  onSubmit = () => {
    this.props.create(this.state.value)
      .then(({ payload }) => {
        this.props.history.replace(`/dealalerts/${payload.result}`)
      })
  };

  render () {
    var { loading, metadata, saving } = this.props

    return (
      <div className='dealalert-add'>
        <Crumb title='Create New'/>
        {loading ? <BigLoader/>
          : <SearchForm
            value={this.state.value}
            loadingCount={this.state.loadingCount}
            count={this.state.count}
            onChange={this.onChange}
            saving={saving}
            metadata={metadata}
            onSubmit={this.onSubmit}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  var metadata = getMetadata(state)
  var loading = isMetadataLoading(state)

  var { saving } = state.dealalerts.list

  return {
    loading, metadata, saving
  }
}

const mapDispatchToProps = {
  fetchMetadata: mls.actions.fetchMetadata,
  fetchCount,
  create
}

DealAlertAddHandler = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DealAlertAddHandler)

export default DealAlertAddHandler
