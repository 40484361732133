import get from 'lodash/object/get'

const totalBaths = property => {
  const p = property || {}
  let full = get(p, 'summary.baths.full') || p.bathsFull || p.baths_full || p.full
  let half = get(p, 'summary.baths.half') || p.bathsHalf || p.baths_half || p.half
  if (!isNumOrStr(full) && !isNumOrStr(half)) return null
  full = full || 0
  half = half || 0
  return half === 0 ? full : `${full}.${half}`
}

export default totalBaths

const isNumOrStr = v => typeof v === 'number' || typeof v === 'string'
