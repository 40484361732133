import { max, min } from 'lodash'

export type MarkerType = {
  id: string
  latitude: number
  longitude: number
}

export const getMinOrMax = (
  markers: MarkerType[],
  minOrMax: 'max' | 'min',
  latOrLng: 'latitude' | 'longitude'
) => {
  if (minOrMax === 'max') return (max(markers, value => value[latOrLng]) as any)[latOrLng]
  else return (min(markers, value => value[latOrLng]) as any)[latOrLng]
}

export const getBounds = (markers: MarkerType[]) => {
  const maxLat = getMinOrMax(markers, 'max', 'latitude')
  const minLat = getMinOrMax(markers, 'min', 'latitude')
  const maxLng = getMinOrMax(markers, 'max', 'longitude')
  const minLng = getMinOrMax(markers, 'min', 'longitude')

  const southWest = [minLng, minLat]
  const northEast = [maxLng, maxLat]
  return [southWest, northEast]
}
