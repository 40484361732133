import { Schema, arrayOf, normalize } from 'normalizr'

import api from 'common/api'
import qs from 'qs'
import anyToError from 'common/util/anyToError'
import { selectDownloads } from './selectors'
import { PROVIDER_SKIPGENIE } from 'const'

const rootUrl = '/lists/v3'
const SchemaList = new Schema('list')
const fetchSchema = { items: arrayOf(SchemaList) }

export const FETCH_LISTS = 'lists/fetch_lists'
export const FETCH_LISTS_SUCCESS = `${FETCH_LISTS}_SUCCESS`
export const FETCH_LISTS_FAILED = `${FETCH_LISTS}_FAILED`

const callApi = async (dispatch, { url, body, method = 'get', types, meta, schema, payload = f => f }) => {
  if (types.start)
    dispatch({ type: types.start, meta })

  let error
  let result

  try {
    result = await api[method.toLowerCase()](url, body)
    error = result && result.error && anyToError(result.error)
  } catch (e) {
    error = e
  }

  if (error && types.error) {
    dispatch({ type: types.error, payload: error, meta })
    return result
  }

  const transformed = schema ? normalize(result, schema) : payload(result)
  if (types.success)
    await dispatch({ type: types.success, meta, payload: transformed })

  return transformed
}

export const fetchLists = (params = {}) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/?` + qs.stringify(params),
  meta: { params },
  method: 'get',
  schema: fetchSchema,
  types: {
    start: FETCH_LISTS,
    success: FETCH_LISTS_SUCCESS,
    error: FETCH_LIST_FAILED
  }
})

export const FETCH_LIST = 'lists/fetch'
export const FETCH_LIST_SUCCESS = `${FETCH_LIST}_SUCCESS`
export const FETCH_LIST_FAILED = `${FETCH_LIST}_FAILED`

export const fetchList = id => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}`,
  method: 'get',
  schema: SchemaList,
  meta: { id },
  types: {
    start: FETCH_LIST,
    success: FETCH_LIST_SUCCESS,
    error: FETCH_LIST_FAILED
  }
})

export const UPDATE_LIST = 'lists/update'
export const UPDATE_LIST_SUCCESS = `${UPDATE_LIST}_SUCCESS`
export const UPDATE_LIST_FAILED = `${UPDATE_LIST}_FAILED`

export const updateList = (id, updates) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}`,
  method: 'put',
  body: updates,
  schema: SchemaList,
  meta: { id, updates },
  types: {
    start: UPDATE_LIST,
    success: UPDATE_LIST_SUCCESS,
    error: UPDATE_LIST_FAILED
  }
})

export const FETCH_MEMBERS = 'lists/fetch_members'
export const FETCH_MEMBERS_SUCCESS = `${FETCH_MEMBERS}_SUCCESS`
export const FETCH_MEMBERS_FAILED = `${FETCH_MEMBERS}_FAILED`

export const fetchMembers = (id, { offset = 0, limit = 50 } = {}) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/members?offset=${offset}&limit=${limit}`,
  method: 'get',
  meta: { id, offset, limit },
  types: {
    start: FETCH_MEMBERS,
    success: FETCH_MEMBERS_SUCCESS,
    error: FETCH_MEMBERS_FAILED
  }
})

export const DELETE_MEMBERS = 'lists/delete_members'
export const DELETE_MEMBERS_SUCCESS = `${DELETE_MEMBERS}_SUCCESS`
export const DELETE_MEMBERS_FAILED = `${DELETE_MEMBERS}_FAILED`

export const deleteMembers = (id, members) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/members`,
  method: 'destroy',
  meta: { id, members },
  body: {
    members
  },
  types: {
    start: DELETE_MEMBERS,
    success: DELETE_MEMBERS_SUCCESS,
    error: DELETE_MEMBERS_FAILED
  }
})

export const DELETE_LIST = 'lists/delete'
export const DELETE_LIST_SUCCESS = `${DELETE_LIST}_SUCCESS`
export const DELETE_LIST_FAILED = `${DELETE_LIST}_FAILED`

export const deleteList = id => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}`,
  method: 'destroy',
  meta: { id },
  types: {
    start: DELETE_LIST,
    success: DELETE_LIST_SUCCESS,
    error: DELETE_LIST_FAILED
  }
})

export const DOWNLOAD_LIST = 'lists/download'

export const download = (listId, downloadId) => async dispatch => {
  dispatch({ type: DOWNLOAD_LIST })
  window.location = `${rootUrl}/${listId}/downloads/${downloadId}`
}

export const CREATE_DOWNLOAD = 'lists/create_download'
export const CREATE_DOWNLOAD_SUCCESS = 'lists/create_download_success'
export const CREATE_DOWNLOAD_FAILURE = 'lists/create_download_failure'

export const createDownload = id => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/downloads`,
  method: 'post',
  meta: { id },
  types: {
    start: CREATE_DOWNLOAD,
    success: CREATE_DOWNLOAD_SUCCESS,
    error: CREATE_DOWNLOAD_FAILURE
  }
})

export const FETCH_DOWNLOADS = 'lists/fetch_downloads'
export const FETCH_DOWNLOADS_SUCCESS = 'lists/fetch_downloads_success'
export const FETCH_DOWNLOADS_FAILURE = 'lists/fetch_downloads_failure'

export const fetchDownloads = id => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/downloads`,
  method: 'get',
  meta: { id },
  types: {
    start: FETCH_DOWNLOADS,
    success: FETCH_DOWNLOADS_SUCCESS,
    error: FETCH_DOWNLOADS_FAILURE
  }
})

export const createAndDownloadAndRefresh = id => async dispatch => {
  const d = await createDownload(id)(dispatch)
  await fetchDownloads(id)(dispatch)
  download(id, d.id)(dispatch)
}

export const pollImport = id => async dispatch => {
  setTimeout(async () => {
    const { result, entities } = (await fetchList(id)(dispatch)) || {}
    await fetchMembers(id)(dispatch)

    if (result && entities.list[result].importProgress)
      pollImport(id)(dispatch)
  }, 2000)
}

export const FETCH_SKIPPABLE_COUNT = 'lists/fetch_skipable_count'
export const FETCH_SKIPPABLE_COUNT_SUCCESS = 'lists/fetch_skipable_count_success'
export const FETCH_SKIPPABLE_COUNT_FAILURE = 'lists/fetch_skipable_count_failure'

export const fetchSkippableCounts = (id, params) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/skiptrace/count`,
  method: 'POST',
  meta: { id, params },
  body: params,
  types: {
    start: FETCH_SKIPPABLE_COUNT,
    success: FETCH_SKIPPABLE_COUNT_SUCCESS,
    error: FETCH_SKIPPABLE_COUNT_FAILURE
  }
})

export const CLEAR_SKIPPABLE_COUNT = 'lists/CLEAR_SKIPPABLE_COUNT'
export const clearSkippableCount = id => ({ type: CLEAR_SKIPPABLE_COUNT, payload: { id } })

export const FETCH_SKIPPED_COUNT = 'lists/fetch_skipped_count'
export const FETCH_SKIPPED_COUNT_SUCCESS = 'lists/fetch_skipped_count_success'
export const FETCH_SKIPPED_COUNT_FAILURE = 'lists/fetch_skipped_count_failure'

export const fetchSkippedCount = (id, { members }) => async dispatch => callApi(dispatch, {
  url: `${rootUrl}/${id}/skiptrace/count`,
  method: 'POST',
  meta: { id },
  body: { members, provider: PROVIDER_SKIPGENIE, reskip: false },
  types: {
    start: FETCH_SKIPPED_COUNT,
    success: FETCH_SKIPPED_COUNT_SUCCESS,
    error: FETCH_SKIPPED_COUNT_FAILURE
  }
})

export const CLEAR_SKIPPED_COUNT = 'lists/CLEAR_SKIPPED_COUNT'
export const clearSkippedCount = id => ({ type: CLEAR_SKIPPED_COUNT, payload: { id } })

export const SUBMIT_SKIPTRACEORDER = 'lists/submit_skiptraceorder'
export const SUBMIT_SKIPTRACEORDER_SUCCESS = 'lists/submit_skiptraceorder_success'
export const SUBMIT_SKIPTRACEORDER_FAILURE = 'lists/submit_skiptraceorder_failure'

export const submitSkiptraceOrder = (id, params) => async dispatch => {
  const ret = await callApi(dispatch, {
    url: `${rootUrl}/${id}/skiptrace`,
    method: 'POST',
    meta: { id, params },
    body: params,
    types: {
      start: SUBMIT_SKIPTRACEORDER,
      success: SUBMIT_SKIPTRACEORDER_SUCCESS,
      error: SUBMIT_SKIPTRACEORDER_FAILURE
    }
  })

  return ret
}

export const pollSkiptraceOrder = id => async (dispatch, getState) => {
  setTimeout(async () => {
    const { result, entities } = await fetchList(id)(dispatch)

    if (result && entities.list[result].memberStats.skiptracing > 0) {
      pollSkiptraceOrder(id)(dispatch, getState)
    } else {
      const { offset = 0, limit = 50 } = getState().lists.members[id] || {}

      await fetchMembers(id, { offset, limit })(dispatch)
      finishPollSkiptrace(id)(dispatch)
    }
  }, 2000)
}

export const SKIPTRACE_POLL_FINISHED = 'lists/skiptrace_poll_finished'

export const finishPollSkiptrace = id => dispatch => dispatch({ type: SKIPTRACE_POLL_FINISHED, meta: { id } })

export const FETCH_GENERAL_DOWNLOAD_INFO = 'lists/FETCH_GENERAL_DOWNLOAD_INFO'
export const FETCH_GENERAL_DOWNLOAD_INFO_SUCCESS = 'lists/FETCH_GENERAL_DOWNLOAD_INFO_SUCCESS'
export const FETCH_GENERAL_DOWNLOAD_INFO_FAILURE = 'lists/FETCH_GENERAL_DOWNLOAD_INFO_FAILURE'

export const fetchGeneralDownloads = ({ type }) => async (dispatch, getState) => {
  const existingDownloads = selectDownloads(getState(), type)
  if (existingDownloads) return

  const res = await callApi(dispatch, {
    url: `${rootUrl}/downloads/national-leads-${type}`,
    method: 'GET',
    meta: { type },
    types: {
      start: FETCH_GENERAL_DOWNLOAD_INFO,
      success: FETCH_GENERAL_DOWNLOAD_INFO_SUCCESS,
      error: FETCH_GENERAL_DOWNLOAD_INFO_FAILURE
    }
  })

  return res
}

export const SET_SELECTED_LIST_ITEMS = 'lists/SET_SELECTED_LISTS_ITEMS'

export const setSelectedListItems = (id, list) => async dispatch => {
  dispatch({ type: SET_SELECTED_LIST_ITEMS, payload: { id, list } })
}
