/* eslint "eqeqeq": "warn" */
import React from 'react'

import EmptyMessage from '../../EmptyMessage'
import Card from 'common/Card'

import SellerTable from './SellerTable'
import ResultToolbar from '../../ResultToolbar'

class SellerResults extends React.Component {
  onEmptyMessageClick = () => {
    this.props.onChangeMode('criteria')
  }

  render () {
    const { data, loading, paging, onChangePage, onSaveClick, counts, onChangeMode, filters } = this.props

    if (!data || data.length == 0)
      return <EmptyMessage onClick={this.onEmptyMessageClick}/>

    return (
      <React.Fragment>
        <ResultToolbar loading={loading} count={counts.total} mb={5} onSaveClick={onSaveClick} onChangeMode={onChangeMode} />
        <Card variant='basic'>
          <SellerTable data={data} loading={loading} filters={filters} paging={paging} count={counts.total} onChangePage={onChangePage}/>
        </Card>
      </React.Fragment>
    )
  }
}

export default SellerResults
