/* eslint "react/jsx-no-target-blank": "warn", "eqeqeq": "warn" */
import React from 'react'
import DateHelper from '../../core/date.js'
import FileInput from './FileInput.react'
import ProgressBar from '../ProgressBar.react'

class File extends React.Component {
  render () {
    var f = this.props.file

    return (
      <div className={'file file-' + f.extension}>
        <div className='file-preview'>
          {
            f.url
              ? <a href={f.downloadUrl} target='_blank'>
                {f.is_image ? <div className='image-wrap'><img src={f.url}/></div> : <div className='file-placeholder'>{f.extension}</div>}
              </a>

              : <div className='file-placeholder'>{f.extension}</div>

          }
        </div>
        {f.url ? <a href={f.downloadUrl} target='_blank'>{f.filename}</a> : <span>{f.filename}</span>}
        {f.user ? <div className='file-details'>Uploaded by {f.user.name} {DateHelper.timeAgo(f.created)}</div> : ''}

        {
          this.props.editable ? <button type='button' className='close' onClick={this.onCloseClick}><span aria-hidden='true'>&times;</span><span className='sr-only'>Close</span></button> : ''
        }

      </div>
    )
  }

  onCloseClick = () => {
    this.props.onCloseClick(this.props.file.id)
  };
}

class FilesField extends React.Component {
  state = {
    loading: false,
    error: '',
    progress: 0
  };

  render () {
    var v = this.props.value || []

    const { error, loading } = this.state

    return (
      <div className='files-field'>

        {v.map(function (f, index) {
          return (
            <File key={'file-' + index} file={f} editable={this.props.editable} onCloseClick={this.onCloseClick.bind(this, index)}/>
          )
        }, this)}

        {!this.props.editable && v.length == 0 ? '---' : ''}

        {this.props.editable
          ? <div className='controls'>
            <a href='#' className={'btn btn-default btn-add ' + (this.state.loading ? 'disabled' : '') } >
              {this.state.loading ? (<span><span className='glyphicon glyphicon-upload'/> Saving...</span>) : 'Upload a file'}
              <FileInput onError={this.onError} onProgress={this.onProgress} hidden={this.state.loading} formData={this.props.formData} url={this.props.url} onStart={this.onStart} onFinish={this.onAdd}/>
            </a>
            {loading ? <ProgressBar value={this.state.progress}/> : ''}
            {error ? <span className='text-danger upload-error'>{error.message || error}</span> : ''}
          </div>
          : ''}

      </div>
    )
  }

  onError = error => {
    this.setState({ error: error, loading: false })
  };

  onStart = () => {
    this.setState({ loading: true, error: '' })
  };

  onProgress = p => {
    this.setState({ progress: p })
  };

  onAdd = file => {
    var files = this.props.value || []
    files = files.concat([file])
    var ids = files.map(function (f) {
      return f.id
    })

    this.setState({ loading: false, error: '' }, function () {
      this.props.onChange(ids)
    })
  };

  onCloseClick = (index, id) => {
    var ids = []
    this.props.value.forEach(function (f) {
      if (f.id !== id)
        ids.push(f.id)
    })

    this.props.onChange(ids)
  };
}

export default FilesField
