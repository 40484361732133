import React from 'react'
import { Box, Caption, Text, Card, Link } from '@realsoftworks/decor'
import { Numeric, Monetary } from 'common/format'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Collapsible from 'common/Collapsable'

const SummaryWarningIcon = () => (
  <Text color='blue.500'>
    <FontAwesomeIcon icon={faExclamationTriangle} />
  </Text>
)

const SummaryMessage = ({ caption, text, textColor = 'neutral', ...props }) => (
  <Box {...props}>
    <Caption color={`${textColor}.900`}>{caption}</Caption>
    <Text as='div' color={`${textColor}.500`}>{text}</Text>
  </Box>
)

const CostBreakdownMessage = ({
  amount,
  highlight,
  caption,
  text,
  ...props
}) => (
  <Card display='flex' alignItems='center' mx={-4} py={2} bg={highlight ? 'blue.100' : 'white'} {...props}>
    <Box mx={4} pl={3} width='100px' display='flex' justifyContent='flex-end' alignItems='center'>
      {highlight && <Box mr={3}><SummaryWarningIcon /></Box>}
      <Text as='div' fontSize='4' textAlign='right' css='white-space: nowrap;'>
        <Text color='secondary'>{amount < 0 && '- '}$</Text> <Numeric decimals={2}>{Math.abs(amount)}</Numeric>
      </Text>
    </Box>
    <SummaryMessage
      textColor={highlight ? 'blue' : 'neutral'}
      flex='1'
      mx={4}
      caption={caption}
      text={text}
    />
  </Card>
)

const OrderSummary = ({
  count,
  servicePrice,
  creditPrice,
  minimum,
  availableCredits,
  ...props
}) => {
  const [open, setOpen] = React.useState(false)

  const totalCredits = servicePrice * count
  const requiredCredits = Math.max(totalCredits - availableCredits, 0)
  let purchaseCredits = requiredCredits
  if (purchaseCredits > 0 && purchaseCredits < minimum)
    purchaseCredits = minimum

  const coveredByBalance = purchaseCredits === 0
  const minimumEnforced = purchaseCredits !== requiredCredits

  const displayedCost = (purchaseCredits || totalCredits) * creditPrice / 100

  let summaryMessage
  if (coveredByBalance)
    summaryMessage = 'We\'ll put a hold on your wallet for this amount. When complete, you will only pay for successful look ups and any unused amount will be returned.'
  else
    summaryMessage = (
      <>
        We will put a hold on your card for this amount. When complete, you will <strong>only</strong> be charged
        for <strong>successful lookups</strong> subject to the minimum purchase amount.
      </>
    )

  return (
    <Box {...props}>
      <Box display='flex' alignItems='top' mx={-4}>
        <Box mx={4} css='white-space: nowrap; position: relative;' pl={5}>
          {minimumEnforced &&
            <Box css='position: absolute; left: 0px; top: 0px;'><SummaryWarningIcon /></Box>}
          <Text fontSize={3} as='div' textAlign='right'>
            <Text color='secondary'>$</Text> <Numeric decimals={2}>{displayedCost}</Numeric>
          </Text>
        </Box>
        <SummaryMessage
          mx={4}
          caption='Maximum Cost'
          text={summaryMessage}
        />
      </Box>
      {
        !coveredByBalance &&
          <Box mt={3}>
            <Box mb={4}>
              <Text as='div' textAlign='center' color='secondary'>
              Minimum purchase is <Monetary>{minimum * creditPrice / 100}</Monetary>
              </Text>
            </Box>
            <Card border='light' borderRadius='5px'>
              <Box
                p={3}
                css='cursor: pointer;'
                display='flex'
                alignItems='center'
                onClick={() => setOpen(!open)}
              >
                <Box mr={3}>See cost breakdown</Box>
                <Box mr='auto'><SummaryWarningIcon /> <Link>Click for info</Link> </Box>
                <Collapsible.Chevron color='secondary' open={open} />
              </Box>
              <Collapsible open={open}>
                <Box px={3} pb={3}>
                  <CostBreakdownMessage
                    caption='Total Order Cost'
                    text='Maximum cost if every lookup returns some data'
                    amount={totalCredits * creditPrice / 100}
                  />
                  <CostBreakdownMessage
                    borderBottom='light'
                    caption='Wallet Balance'
                    text='Credit you already have will be applied to the cost'
                    amount={-availableCredits * creditPrice / 100}
                  />
                  <CostBreakdownMessage
                    caption='Required Purchase'
                    text="You'll need to purchase this much credit to complete this order"
                    amount={requiredCredits * creditPrice / 100}
                  />
                  {minimumEnforced &&
                    <CostBreakdownMessage
                      highlight
                      mb={-3}
                      caption='Minimum Purchase'
                      text={<>You need <Monetary>{requiredCredits * creditPrice / 100}</Monetary> but the minimum purchase is <Monetary>{minimum * creditPrice / 100}</Monetary></>}
                      amount={purchaseCredits * creditPrice / 100}
                    />}
                </Box>
              </Collapsible>
            </Card>
          </Box>
      }
    </Box>
  )
}

export default OrderSummary
