
import { combineReducers } from 'redux'
import * as Actions from '../actions'

import omit from 'lodash/object/omit'
import indexBy from 'lodash/collection/indexBy'

const bySource = (state = {}, { payload, meta, type }) => {
  switch (type) {
    case Actions.FETCH_MY_BROKERS_SUCCESS:
      return payload.result

    case Actions.AUTH_CONNECT_SUCCESS:
      return {
        ...state,
        [meta.source]: meta.broker.id
      }

    default: return state
  }
}

const byId = (state = {}, { payload, meta, type }) => {
  switch (type) {
    case Actions.FETCH_MY_BROKERS_SUCCESS:
      if (payload.entities.broker)
        return payload.entities.broker
      else
        return state

    case Actions.SOURCE_REQUIREMENTS:
      var brokers = indexBy(payload.brokers.map(removeRequirements), 'id')
      return {
        ...state,
        ...brokers
      }

    default: return state
  }
}

export default combineReducers({
  bySource,
  byId
})

const removeRequirements = b => omit(b, 'requirements')
