import isEmpty from 'lodash/lang/isEmpty'
import pick from 'lodash/object/pick'
import mapValues from 'lodash/object/mapValues'
import flow from 'lodash/function/flow'
import isPlainObject from 'lodash/lang/isPlainObject'

const cleanIfObj = v =>
  (v && !Array.isArray(v) && isPlainObject(v))
    ? cleanObject(v)
    : v

const isValueValid = (val: unknown): boolean => {
  if (Array.isArray(val) || val instanceof Date) return true
  const isEmptyObj = val && typeof val === 'object' && isEmpty(val)
  const isEmptyVal = ([null, undefined, ''] as unknown[]).includes(val)
  const isNaN = Number.isNaN(val)
  return !isEmptyObj &&
    !isEmptyVal &&
    !isNaN
}

const cleanObject =
  flow(
    v => mapValues(v, cleanIfObj),
    v => pick(v, isValueValid)
  )

export default cleanObject
