import fetch from './fetch'
import * as Sentry from '@sentry/browser'

export const request = (url, options = {}) => fetch(url, options).then(
  response => {
    if (options.parseResponse === false)
      return Promise.resolve(response.status === 200)

    return saferJsonParse({ response, url, options })
      .then(json => {
        const isResArray = response.ok && Array.isArray(json)
        if (isResArray) return json

        const isResObj = response.ok &&
            typeof json === 'object' &&
            json !== null
        if (isResObj)
          return {
            ...json,
            status: json.status || response.status
          }

        const isResNonObj = response.ok
        if (isResNonObj) return json

        const error = (json && json.error) ||
            json ||
            { type: 'request_error' }
        return {
          error,
          status: (json && json.error && json.error.status) ||
              (json && json.status) ||
              (response && response.status)
        }
      })
  },
  () =>
  // only reason to get here is connection errors
    ({ error: { type: 'api_connection_error' } })

)

export const get = (url, options = {}) => request(url, { ...options, method: 'GET' })

export const post = (url, body, options = {}) => request(url, { ...options, body: JSON.stringify(body), method: 'POST' })

export const put = (url, body, options = {}) => request(url, { ...options, body: JSON.stringify(body), method: 'PUT' })

export const destroy = (url, body, options = {}) => request(url, { ...options, body: JSON.stringify(body), method: 'DELETE' })

export default { get, post, put, request, destroy, delete: destroy }

const saferJsonParse = async ({ response, url, options }) => {
  let responseText
  try {
    responseText = await response.text()
    if (!responseText) {
      reportFailedJsonParse({
        error: new Error('Response is empty text'),
        responseText,
        url,
        options
      })
      return
    }
    const responseJson = JSON.parse(responseText)
    return responseJson
  } catch (error) {
    reportFailedJsonParse({ error, responseText, url, options })
    throw error
  }
}

const reportFailedJsonParse = ({ error, responseText, url, options }) => {
  Sentry.withScope(scope => {
    scope.setTag('request-url', url)
    scope.setExtra('request-options', options)
    scope.setExtra('request-response-text', responseText)
    Sentry.captureException(error)
  })
}
