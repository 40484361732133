import React, { useState, useCallback } from 'react'
import { update } from 'leads/actions'
import { connect } from 'react-redux'
import { Box, IconButton, Pill, Dialog, Button } from '@realsoftworks/decor'
import { ARCHIVED_OR_DELETED_OPTS } from 'search/HistoryContext'
import LoadingIcon from 'common/LoadingIcon'
import { useSearchScreenProps } from 'search/SearchContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import logError from 'common/logError'

const withConnect = connect(null, { updateLeadStatus: update })
const DeleteBtnOrStatus = withConnect(({ updateLeadStatus }) => {
  const { lead, setLead } = useSearchScreenProps()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDeleteLead = useCallback(ev => {
    setIsModalOpen(false)

    if (isLoading) return

    const DELETED_STATUS = 'deleted'
    const opt = ARCHIVED_OR_DELETED_OPTS
      .find(({ value }) => value.status === DELETED_STATUS)
    const payload = opt && opt.value && opt.value.payload
    const params = { id: lead.id, body: payload }

    if (!payload) return logError('payload is unexpectedly falsy in handleUpdateStatus()')

    setIsLoading(true)
    updateLeadStatus(params)
      .then(() => setLead(l => ({ ...l, status: DELETED_STATUS })))
      .finally(() => setIsLoading(false))
  }, [lead.id, isLoading])

  const handleOpenConfirmModal = useCallback(() => {
    if (isLoading) return
    setIsModalOpen(true)
  }, [isLoading])

  const status = lead && lead.status

  return (
    <Box css='position: relative;'>
      {isLoading && (
        <Box
          css={`
            position: absolute;
            right: calc(100% + 4px);
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <LoadingIcon size={1} />{' '}
        </Box>
      )}

      {status === 'deleted' ? (
        <Pill variant='danger' size='small' css='font-size: 14px'>
          Deleted
        </Pill>
      ) : status === 'archived' ? (
        <Pill variant='warn' size='small' css='font-size: 14px'>
          Archived
        </Pill>
      ) : (
        <>
          <IconButton
            disabled={isLoading}
            variant='danger'
            onClick={handleOpenConfirmModal}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </IconButton>

          <Dialog
            title='Confirm Deletion'
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            toolbar={(
              <>
                <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                <Button ml={2} variant='danger' onClick={handleDeleteLead}>
                  Delete
                </Button>
              </>
            )}
          >
            Are you sure you want to permanently delete this lead?
          </Dialog>
        </>
      )}
    </Box>
  )
})

export default DeleteBtnOrStatus
