import get from 'lodash/object/get'
import set from 'common/util/set'

const formatLead = lead => {
  const hasFetchedAllDetails = get(lead, 'hasFetchedAllDetails', false)
  const hasPropertyDataInDb = get(lead, 'hasPropertyDataInDb', true)
  const fallbackAddress = get(lead, 'values.address')
  const fallbackLead = fallbackAddress ? { address: fallbackAddress } : null

  // For some reason, in some cases, the county is missing from property details address
  // @TODO find the reason why the county is missing in property details address
  const propertyDetailsRaw = hasPropertyDataInDb
    ? get(lead, 'property')
    : fallbackLead
  const currentCounty = propertyDetailsRaw?.address?.county
  const fallbackCounty = lead?.values?.address?.county
  const propertyDetails = (!currentCounty && fallbackCounty)
    ? set(['address', 'county'], fallbackCounty, propertyDetailsRaw)
    : propertyDetailsRaw

  return {
    hasFetchedAllDetails,
    hasPropertyDataInDb,
    id: get(lead, 'id'),
    state: get(lead, 'values.state'),
    status: get(lead, 'values.deleted')
      ? 'deleted'
      : get(lead, 'values.active')
        ? 'active'
        : 'archived',
    propertyDetails
  }
}

export default formatLead
