import React, { useEffect, useCallback, useState } from 'react'
import { Link as DecorLink, Box } from '@realsoftworks/decor'
import { withRouter } from 'react-router-dom'
import AddressPreviewDialog from '../AddressPreviewDialog'

const AddressPreviewLink = ({
  address: maybeAddress,
  propertyId,
  fontSize,
  location,
  ...props
}) => {
  const { line1, city, state, zip } = maybeAddress || {}
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = useCallback(event => {
    event.stopPropagation()
    setIsOpen(true)
  }, [setIsOpen])
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen])

  useEffect(() => {
    setIsOpen(false)
  }, [location.search])

  return (
    <>
      <DecorLink
        onClick={handleClick}
        fontSize={fontSize || 0}
        style={{ textDecoration: 'underline', ...(props.style || {}) }}
        {...props}
      >
        {line1}
        <Box mt={(fontSize && fontSize > 0) ? 0 : 1} display='block'>
          {[[city, state].join(', '), zip].join(' ')}
        </Box>
      </DecorLink>
      <AddressPreviewDialog
        address={maybeAddress}
        propertyId={propertyId}
        isOpen={isOpen}
        onRequestClose={handleClose}
      />
    </>
  )
}

export default withRouter(AddressPreviewLink)
