export type UnknownObject = { [key: string]: any }

const ABSENTEE_OWNER_TYPES_OBJ = {
  absentee: 'absentee',
  outofstate: 'outofstate',
  outofcountry: 'outofcountry'
}

export const OWNER_TYPES_OBJ = {
  ...ABSENTEE_OWNER_TYPES_OBJ,
  home: 'home',
  owneroccupied: 'owneroccupied',
  trustowned: 'trustowned',
  bankowned: 'bankowned',
  companyowned: 'companyowned',
  military: 'military'
}

export type AbsenteeOwnerType = keyof typeof OWNER_TYPES_OBJ
export const ABSENTEE_OWNER_TYPES =
  Object.keys(ABSENTEE_OWNER_TYPES_OBJ) as AbsenteeOwnerType[]

export type OwnerType = AbsenteeOwnerType | keyof typeof OWNER_TYPES_OBJ
export const OWNER_TYPES = Object.keys(OWNER_TYPES_OBJ) as OwnerType[]

export type MinAddress = {
  line1: string
  city: string
  state: string
  zip: string
}

export type LatLng = { lat: number, lng: number }

export type GoogleMap = {
  getBounds: () => {
    contains: (latLng: LatLng) => boolean
  }
  setCenter: (latLng: LatLng) => void
}

export type NumberRange = { min?: number, max?: number }
export type DateRange = { before?: Date, after?: Date }
export type ConditionType = 'AND' | 'OR'

export enum SkiptraceFormat {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
