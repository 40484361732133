import { SEARCH_SUCCESS, TAG_CREATE_SUCCESS, TAG_DELETE_SUCCESS, CONTACT_READ_SUCCESS, UPDATE_SUCCESS } from '../actions'
import without from 'lodash/array/without'
import uniq from 'lodash/array/uniq'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
      if (action.payload && action.payload.result) {
        let tagIds = action.payload.result
        if (typeof tagIds === 'string')
          tagIds = [tagIds]

        if (tagIds === null)
          tagIds = []

        var newState = {}
        const contactId = action.meta && action.meta.params ? action.meta.params : null
        if (contactId)
          newState = {
            ...state,
            [contactId]: tagIds
          }
        else
          newState = { ...state }

        const origAll = newState.all
        if (origAll)
          newState.all = maybeUniq(tagIds.concat(origAll))
        else
          newState.all = maybeUniq(tagIds)

        return newState
      }
      return state
    case UPDATE_SUCCESS:
      if (action.payload && action.payload.result && action.payload.entities && action.payload.entities.contacts) {
        let tagIds = action.payload.entities.contacts[action.payload.result].values.contacttag
        if (typeof tagIds === 'string')
          tagIds = [tagIds]

        if (tagIds === null)
          tagIds = []

        let newState = {}
        const contactId = action.meta && action.meta.params && action.meta.params.id ? action.meta.params.id : null
        if (contactId)
          newState = {
            ...state,
            [contactId]: tagIds
          }
        else
          newState = { ...state }

        const origAll = newState.all
        if (origAll)
          newState.all = maybeUniq(tagIds.concat(origAll))
        else
          newState.all = maybeUniq(tagIds)

        return newState
      }
      return state

    case CONTACT_READ_SUCCESS:
      if (action.payload && action.payload.result && action.payload.entities && action.payload.entities.tag) {
        const tags = action.payload.entities.tag
        const contactId = action.payload.result
        var tagIds = Object.keys(tags)
        const newState = {
          ...state,
          [contactId]: tagIds
        }
        return newState
      }
      return state

    case TAG_DELETE_SUCCESS:
      if (action.payload) {
        const tags = without(state.all, action.payload.id)
        const newState = {
          ...state,
          all: maybeUniq(tags)
        }
        return newState
      }
      return state
    case TAG_CREATE_SUCCESS: {
      if (action.payload && action.payload.result && action.payload.entities && action.payload.entities.tag) {
        const tags = action.payload.entities.tag
        const tagIds = Object.keys(tags)
        const contactId = action.meta && action.meta.params && action.meta.params.id ? action.meta.params.id : null
        if (contactId) {
          const newState = {
            ...state,
            [contactId]: tagIds
          }
          return newState
        } else {
          const list = state.all || []
          return {
            ...state,
            all: maybeUniq([...list, ...tagIds])
          }
        }
      }
      return state
    }
  }
  return state
}

const maybeUniq = v => v && uniq(v)
