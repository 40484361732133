import styled from 'styled-components'
import { Box } from '@realsoftworks/decor'
import { themeGet } from '@styled-system/theme-get'

const ListItem = styled(Box)`
  cursor: pointer;

  &:hover {
    background-color: ${themeGet('colors.teal.200')};
  }
`

ListItem.defaultProps = {
  py: 1
}

export default ListItem
