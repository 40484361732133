import React, { useCallback, useMemo } from 'react'
import { NumberInput, Label, Box, DateInput } from '@realsoftworks/decor'
import FilterFooter from '../../FilterFooter'
import MonetaryRange from 'leadlists/components/filters/MonetaryRange'
import AccordionFilterSection, { useAccordionFilterSection } from 'leadlists/components/AccordionFilterSection'
import GeoSection from 'leadlists/components/Sellers/components/SellerFilters/GeoSection'
import MiscSection from 'leadlists/components/Sellers/components/SellerFilters/MiscSection'

export default function BuyerFilters ({
  filters,
  onChange,
  onChangeFilter,
  showResults,
  counts,
  data
}) {
  const {
    minSpend,
    maxSpend,
    minDeals,
    recentDeal
  } = filters

  const onChangeCount = useCallback(e => onChangeFilter('minDeals', e.target.value), [onChangeFilter])
  const onChangeRecentDeal = useCallback((e, v) => onChangeFilter('recentDeal', v), [onChangeFilter])
  const {
    openSection,
    toggleSection,
    toggleSectionMemoedFactory
  } = useAccordionFilterSection()

  return (
    <>
      <Box maxWidth={1000} pb={160}>
        <GeoSection onChange={onChange} />

        <AccordionFilterSection
          mt={5}
          heading='Financials'
          toggleSection={toggleSection('financials')}
          isSectionOpen={openSection === 'financials'}
        >
          <Box display='flex' mx={-3}>
            <Box flex='1' mx={3} mb={2}>
              <Box mb={4}>
                <Label mr={2}>Spent Amount</Label>
                <MonetaryRange
                  value={useMemo(() =>
                    ({ min: minSpend, max: maxSpend }),
                  [minSpend, maxSpend])}
                  onChange={useCallback(value => {
                    onChange({
                      minSpend: value?.min,
                      maxSpend: value?.max
                    })
                  }, [onChange])}
                  placeholder='Any'
                />
              </Box>
              <Box mb={4}>
                <Label mr={2}>Min Deal Count</Label>
                <NumberInput onChange={onChangeCount} value={minDeals} width='80px' placeholder='Any' />
              </Box>
            </Box>
            <Box flex='2' mx={4}>
              <Box display='flex' flexDirection='column' mb={2}>
                <Label>Recent Deal After</Label>
                <DateInput value={recentDeal} onChange={onChangeRecentDeal} width='130px' placeholder='Any' />
              </Box>
            </Box>
          </Box>
        </AccordionFilterSection>

        <MiscSection
          filters={filters}
          onChange={onChange}
          openSection={openSection}
          toggleSectionFactory={toggleSectionMemoedFactory}
        />

        <FilterFooter
          filters={filters}
          data={data}
          counts={counts}
          showResults={showResults}
          setFilters={onChange}
        />
      </Box>
    </>
  )
}
