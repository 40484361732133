import React from 'react'

import classNames from 'classnames'

import './toolbar.less'

class Toolbar extends React.Component {
  render () {
    const { className, children, ...rest } = this.props

    return (
      <div className={classNames('decor', 'decorToolbar', className)} {...rest}>
        {children}
      </div>
    )
  }
}

const ToolbarLeft = ({ className, children, ...rest }) => (
  <div className={classNames('decor', 'decorToolbar-left', className)} {...rest}>
    {children}
  </div>
)

export { ToolbarLeft }

const ToolbarText = ({ className, children, ...rest }) => (
  <div className={classNames('decor', 'decorToolbar-text', className)} {...rest}>
    {children}
  </div>
)
export { ToolbarText }

Toolbar.Left = ToolbarLeft
Toolbar.Text = ToolbarText

export default Toolbar
