/* eslint "no-case-declarations": "warn" */
import * as CONSTANTS from '../actions'

const filterTs = (state, action, reducer) => {
  const { ts } = action.meta
  const existingTs = state ? state.ts : 0

  if (ts > existingTs)
    return reducer(state, action)

  return state
}

export default (state = { ts: 0 }, action) => {
  const { type, payload, meta = { ts: 0 } } = action
  const { ts } = meta
  switch (type) {
    case CONSTANTS.FETCH_CUSTOM_NEIGHBORS_SUCCESS:
      const { offset, limit } = meta.params
      const { count } = payload
      const length = payload.items.length
      return filterTs(state, action, () => ({
        offset, limit, length, count, ts
      }))
  }

  return state
}
