/* eslint "react/display-name": "warn", "react/jsx-key": "warn" */
import React from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TextTableCell,
  TableCell,
  Text
} from '@realsoftworks/decor'
import PagingToolbar from 'common/PagingToolbar'
import { Monetary, DateTime } from 'common/format'
import SituationsPill from './SituationsPill'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'

const defaultNumber = v => (v !== null && v !== undefined) ? v : '--'
const Secondary = props => <Text color='secondary' fontSize='inherit' {...props} />

const SellerName = ({ name }) => name ? name.split(',').join(', ') : null

const SellerTable = React.memo(({ data, loading, paging, onChangePage, count, filters, ...props }) => (
  <Box {...props} css={`overflow-x: auto; ${props.css || ''}`}>
    <SellerTableData data={data} hasAuctionDate={filters.isForeclosure} />
    {paging && <PagingToolbar {...paging} loading={loading} count={count} onChangePage={onChangePage} />}
  </Box>
))

const SellerTableData = React.memo(({ data, hasAuctionDate }) => (
  <Table display='inline-block' maxWidth='auto' width='100%' css='font-size: 14px;'>
    <TableHead>
      <TableHeaderCell flex='15'>Address</TableHeaderCell>
      <TableHeaderCell flex='12'>Stats</TableHeaderCell>
      <TableHeaderCell flex='12'>Owner</TableHeaderCell>
      <TableHeaderCell flex='10'>Last Sold</TableHeaderCell>
      <TableHeaderCell flex='8' textAlign='right'>Est. Value</TableHeaderCell>
      <TableHeaderCell flex='8' textAlign='right'>Est. Equity</TableHeaderCell>
      {hasAuctionDate &&
        <TableHeaderCell flex='8' textAlign='right'>Auction Date</TableHeaderCell>}
      <TableHeaderCell flex='10'>Situations</TableHeaderCell>
    </TableHead>
    <TableBody>
      {data.map(record => (
        <TableRow key={record.id}>
          <TextTableCell flex='15'>
            <AddressPreviewLink
              address={{
                line1: record.propertyAddressLine1,
                city: record.propertyAddressCity,
                state: record.propertyAddressState,
                zip: record.propertyAddressZip
              }}
              propertyId={record.id}
            />
          </TextTableCell>
          <TextTableCell flex='12'>
            <Box mb={1}>
              {defaultNumber(record.beds)} <Secondary>beds</Secondary>, {defaultNumber(record.bathsFull)} <Secondary>baths</Secondary>
            </Box>
            {defaultNumber(record.sqft)} <Secondary>sqft</Secondary>, {defaultNumber(record.yearBuilt)} <Secondary>build</Secondary>
          </TextTableCell>
          <TextTableCell flex='12'>
            <SellerName name={record.name} />
          </TextTableCell>
          <TextTableCell flex='10'>
            <Box mb={1}>{record.lastSaleDate && <DateTime format='MM/DD/YYYY'>{record.lastSaleDate}</DateTime>}</Box>
            {record.lastSalePrice && <Monetary decimals={2}>{record.lastSalePrice}</Monetary>}
          </TextTableCell>
          <TextTableCell flex='8' textAlign='right'>
            <Monetary decimals={2}>{record.value}</Monetary>
          </TextTableCell>
          <TextTableCell flex='8' textAlign='right'>
            <Monetary decimals={2}>{record.equity}</Monetary>
          </TextTableCell>
          {hasAuctionDate &&
            <TextTableCell flex='8' textAlign='right'>
              <DateTime format='MM/DD/YYYY'>{record.auctionDate}</DateTime>
            </TextTableCell>}
          <TableCell flex='10'>
            <SituationsPill property={record} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
))

export default SellerTable
