import React, { useEffect, useState } from 'react'
import { array, bool, object, func } from 'prop-types'
import { connect } from 'react-redux'
import { Box, Card, Button } from '@realsoftworks/decor'
import { FixedWidthLayout, PageTitle } from 'common/layouts'
import FetchHandler from 'common/FetchHandler'
import LoadingIcon from 'common/LoadingIcon'
import NotificationsList from '../components/NotificationsList'
import { selectNotifsInfo } from '../selectors'
import { fetchPageNotifs, fetchMorePageNotifs } from '../actions'

const Notifications = ({
  notifications,
  error,
  isLoadingMore,
  errorMore,
  fetchPageNotifs,
  fetchMorePageNotifs,
  hasMore
}) => {
  const [isLoadingForFirstTime, setIsLoadingForFirstTime] = useState(true)
  useEffect(() => {
    (async () => {
      try {
        await fetchPageNotifs()
        setIsLoadingForFirstTime(false)
      } catch (e) {}
    })()
  }, [])

  return (
    <FixedWidthLayout display='flex' justifyContent='center'>
      <Box display='flex' flexDirection='column' width={1} maxWidth={740}>
        <PageTitle alignSelf='flex-start'>Notifications</PageTitle>
        <Card variant='basic' bg='white' alignSelf='stretch' boxShadow={1}>
          <FetchHandler
            error={error}
            isLoading={isLoadingForFirstTime}
            isEmpty={!notifications.length}
          >
            <NotificationsList borderRadius={4} notifications={notifications} />
          </FetchHandler>

          {/* Shows loader/error for load more */}
          <FetchHandler
            error={errorMore}
            isLoading={isLoadingMore}
            isEmpty={false}
            renderLoading={() => (
              <Box display='flex' pt={3} pb={5} justifyContent='center'>
                <LoadingIcon size={4} />
              </Box>
            )}
          />
        </Card>

        {hasMore && !isLoadingForFirstTime && !isLoadingMore && (
          <Button alignSelf='center' mt={6} variant='secondary' size='large' onClick={fetchMorePageNotifs}>
            Load More
          </Button>
        )}
      </Box>
    </FixedWidthLayout>
  )
}

Notifications.propTypes = {
  notifications: array.isRequired,
  hasMore: bool.isRequired,
  error: object,
  isLoadingMore: bool.isRequired,
  errorMore: object,
  fetchPageNotifs: func.isRequired,
  fetchMorePageNotifs: func.isRequired
}

const withState = connect(
  state => {
    const {
      value,
      isLoadingPage,
      errorPage,
      isLoadingPageMore,
      errorPageMore,
      hasMore
    } = selectNotifsInfo(state)
    const notificationsArr = Object.values(value)
      .sort((a, b) =>
        +new Date(a.created) > +new Date(b.created) ? -1 : 1)

    return {
      notifications: notificationsArr,
      isLoading: isLoadingPage,
      error: errorPage,
      isLoadingMore: isLoadingPageMore,
      errorMore: errorPageMore,
      hasMore
    }
  },
  { fetchPageNotifs, fetchMorePageNotifs }
)

export default withState(Notifications)
