const FREE_SUBSCRIPTION = { plan: { id: 'free', name: 'Free', metadata: { features: [] } } }

export const getSubscription = state => state.billing.subscription || FREE_SUBSCRIPTION

export const getCurrentPlan = state => getSubscription(state).plan
export const isUpgrading = state => state.billing.upgrading
export const getUpgradeError = state => state.billing.error
export const isPaid = state => getCurrentPlan(state).id !== 'free'
export const isTrialing = state => getSubscription(state).status === 'trialing'

export const getPlanStartDate = state => getSubscription(state).created

export const getBillingInfo = state => state.billing.info
export const hasBillingInfo = state => !!getBillingInfo(state)

export const hasFeature = (state, feature) => {
  const plan = getCurrentPlan(state)
  return plan.metadata.features.includes(feature)
}

export const getSubscriptionStates = state => state.billing.states
export const getIsUpgrading = state => state.billing.states.isUpgrading

export const getPlans = state => state.billing.plans

export const getRetrialStatus = state => state.billing.retrial

export const getCustomer = state => state.billing.customer

export const getProAlt = state => state.billing.proAlt
