/* eslint "react/display-name": "warn", "react/no-deprecated": "warn", "eqeqeq": "warn" */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import eq from 'lodash/lang/eq'
import moment from 'moment'

import Toolbar, { ToolbarLeft } from 'decor/Toolbar'
import Button from 'decor/Button'
import IconButton from 'decor/IconButton'
import { Box } from '@realsoftworks/decor'

import LoadingButton from 'common/LoadingButton'
import ProTable from 'decor/ProTable'
import Checkbox from 'decor/Checkbox'
import without from 'lodash/array/without'
import intersection from 'lodash/array/intersection'
import AddressPreviewLink from 'propertypreviews/components/AddressPreviewLink'

const baseColumns = [
  {
    id: 'email'
  },
  {
    id: 'phone'
  },
  {
    id: 'address',
    render: v => (
      <AddressPreviewLink
        address={{
          line1: v && v.line1,
          city: v && v.city,
          state: v && v.state,
          zip: v && v.zip
        }}
      />
    )
  },
  {
    id: 'created_at',
    render: v => moment(v).format('MMM D')
  }
]

const slimColumns = [
  {
    id: 'contact',
    render: (d, v) => (
      <React.Fragment>
        <Box mb={2}>
          <AddressPreviewLink
            address={{
              line1: v && v.line1,
              city: v && v.city,
              state: v && v.state,
              zip: v && v.zip
            }}
          />
        </Box>
        <Box mb={2}>{v.email || '--'}</Box>
        {v.phone && <Box>{v.phone}</Box>}
      </React.Fragment>
    )
  }
]

const EmptyMessage = () => (
  <div className='site-message-empty-messages'>
    <div className='title'>No messages</div>
    <p>When someone fills out a form on your site, the details will show up here.</p>
  </div>
)

class MessageList extends Component {
  constructor (props) {
    super(props)

    let columns = props.slim ? slimColumns : baseColumns
    if (props.showSite)
      columns = [{
        id: 'site.name',
        header: 'Site',
        render: (v, row) => row.site ? <Link to={`/sites/${row.site.id}`}>{v}</Link> : '(deleted)',
        props: {
          flex: 'none',
          width: '120px'
        }
      }].concat(columns)

    this.state = {
      selected: props.selected || [],
      columns
    }
  }

  updateSelection = selected => {
    this.setState({ selected })
    this.props.onSelectionChange && this.props.onSelectionChange(selected)
  };

  onDeleteClick = () => {
    this.props.onDelete(this.state.selected)
  };

  onCreateClick = () => {
    this.props.onCreateLead(this.state.selected)
  };

  componentWillReceiveProps (nextProps) {
    if (nextProps.selected && nextProps.selected !== this.state.selected)
      this.setState({ selected: nextProps.selected })
  }

  render () {
    const { selected, columns } = this.state
    const disabled = selected.length == 0

    const { data, count, loading } = this.props

    if (!data || data.length == 0) return <EmptyMessage/>

    return (
      <div className='site-message-list'>
        <Toolbar>
          <ToolbarLeft>
            <Button className='btn-success' disabled={disabled} onClick={this.onCreateClick}>Create Lead</Button>
            <Button className='btn-danger' disabled={disabled} onClick={this.onDeleteClick}>Delete</Button>
          </ToolbarLeft>
        </Toolbar>
        <SelectionTable sortable={false} idField='id' columns={columns} data={data} onSelectionChange={this.updateSelection} selected={selected}/>
        {data.length < count && <LoadingButton className='btn-show-more btn-info' loading={loading} onClick={this.props.onShowMore}>Show More</LoadingButton>}
      </div>
    )
  }
}

class SelectionTable extends Component {
  defaultProps = {};

  constructor (props) {
    super(props)
    this.state = {
      selected: [] || props.selected,
      checkAll: false,
      columns: this.prepareColumns(props.columns)
    }
  }

  onCheckChange = (e, checked) => {
    var id = e.target.value
    var selected = this.state.selected
    var checkAll = this.state.checkAll

    if (checked) {
      selected = selected.concat([id])
      selected = selected.sort()

      const allIds = this.getAllIds().sort()
      checkAll = eq(allIds, selected)
    } else {
      selected = without(selected, id)
      checkAll = false
    }

    this.setState({ selected, checkAll })
    this.props.onSelectionChange && this.props.onSelectionChange(selected)
  };

  componentWillReceiveProps (nextProps, nextContext) {
    let checkAll = this.state.checkAll
    let nextSelections = this.state.selected

    if (nextProps.selected && nextProps.selected !== this.state.selected) { nextSelections = nextProps.selected } else if (nextProps.data !== this.props.data) {
      const nextIds = nextProps.data.map(v => v[nextProps.idField])
      nextSelections = intersection(nextIds, nextProps.selected || this.state.selected)
    } else {
    // if selection didn't change and data didn't change, nothing to be done
      return
    }

    checkAll = nextSelections.length > 0 && nextSelections.length === this.getAllIds(nextProps.data).length

    if (checkAll !== this.state.checkAll || nextSelections !== this.state.selected)
      this.setState({ selected: nextSelections, checkAll })
  }

  onCheckAll = (e, checked) => {
    let selected
    if (!checked)
      selected = []
    else
      selected = this.getAllIds().sort()

    this.setState({ selected, checkAll: checked })
    this.props.onSelectionChange && this.props.onSelectionChange(selected)
  };

  getAllIds (data) {
    if (!data)
      data = this.props.data

    // TODO nw - this used to be generic and didn't include leadId
    return this.props.data.filter(v => !v.leadId).map(v => v[this.props.idField])
  }

  getRowClass = row => {
    var classes = [this.props.getRowClass ? this.props.getRowClass() : '']

    var id = row[this.props.idField]
    if (this.state.selected.includes(id))
      classes.push('selected-row')

    return classes.join(' ')
  };

  prepareColumns (columns) {
    return [{
      id: 'checkbox',
      props: {
        flex: 'none'
      },
      header: () => (
        <Checkbox
          checked={this.state.checkAll}
          onChange={this.onCheckAll}
        />
      ),
      render: (v, row) => {
        const id = row[this.props.idField]
        const leadId = row.leadId

        // TODO nw - this used to be generic and just return the checkbox

        if (leadId)
          return <IconButton
            tooltip='View Lead'
            iconClassName='icon-lead-link fa fa-external-link'
            link={<Link to={`/search/history/${row.leadId}`}/>}
          />

        return (
          <Checkbox
            value={id}
            checked={this.state.selected.includes(id)}
            onChange={this.onCheckChange}
          />
        )
      }
    }].concat(columns)
  }

  render () {
    const { columns, ...rest } = this.props
    const newcolumns = this.state.columns

    return (
      <ProTable
        responsive
        columns={newcolumns}
        {...rest}
      />
    )
  }
}

export default MessageList
