import get from 'lodash/object/get'

export const getTrialPromoVal = state =>
  get(state, 'billing.trialPromo.promo.percent_off')
    ? `${get(state, 'billing.trialPromo.promo.percent_off')}%`
    : get(state, 'billing.trialPromo.promo.amount_off')
      ? `$${get(state, 'billing.trialPromo.promo.amount_off')}`
      : null

export const getTrialPromoExp = state =>
  get(state, 'billing.trialPromo.promo.expiration')

export const getTrialBillingAmount = state => {
  const amount = get(state, 'billing.trialPromo.promo.billing_amount')

  // if we are displaying pennies, fix the decimal point to two places.
  if (amount && amount !== Number(amount.toFixed()))
    return amount.toFixed(2)

  return amount
}
