import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

class Tab extends React.Component {
  static propTypes = {
    selected: PropTypes.bool,
    label: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  static defaultProps = {
    style: {},
    onClick: () => {}
  };

  render () {
    const {
      selected,
      style,
      width,
      onClick,
      className,
      label,
      ...other
    } = this.props

    const classNames = classnames('tab', className, { active: selected })

    const styles = Object.assign({}, style, { width, cursor: 'pointer' })

    return (
      <div
        {...other}
        style={styles}
        className={classNames}
        onClick={onClick}>
        {label}
      </div>
    )
  }
}

export default Tab
