import { UPDATE_SUCCESS, READ_SUCCESS, SEARCH_SUCCESS, CREATE_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUCCESS:
    case READ_SUCCESS:
    case SEARCH_SUCCESS:
    case CREATE_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.users)
        return {
          ...state,
          ...action.payload.entities.users
        }
  }
  return state
}
