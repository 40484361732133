import React from 'react'
import styled from 'styled-components'
import { Box } from '@realsoftworks/decor'
import { Link } from 'react-router-dom'
import useBreakpoint from 'common/util/hooks/useBreakpoint'

const BrandingImg = styled(Box)`
  height: 36px;
`

const Branding = ({ route, broker }) => {
  const linkProps = route
    ? { as: Link, to: '/homeredirect' }
    : { as: 'a', href: '/homeredirect' }
  const { mdUp } = useBreakpoint()

  return (
    <>
      <Box {...linkProps} style={{ display: 'block', maxHeight: '100%' }}>
        <BrandingImg
          as='img'
          src={mdUp ? '/img/logo-white.png' : '/img/logo-icon-white.png'}
          maxHeight={26}
        />
      </Box>
      {broker && <BrandingImg ml={2} as='img' src={broker.logo_url} />}
    </>
  )
}

export default React.memo(Branding)
