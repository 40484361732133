/* eslint "eqeqeq": "warn", "react/no-deprecated": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'

import mls from '../../mls'
import { update, fetchCount } from '../actions'

import BigLoader from 'common/BigLoader'
import { connect } from 'react-redux'

import { Crumb } from '../../../views/common/Breadcrumbs'

import SearchForm from './searches/SearchForm'

import { getMetadata, isMetadataLoading } from '../../mls/selectors'

class DealAlertEditHandler extends Component {
  constructor (props) {
    super(props)
    this.state = { value: props.alert }
  }

  onChange = value => {
    var updateCount = value.name == this.state.value.name // some other field changed besides name
    this.setState({ value })

    if (updateCount)
      this.updateCount(value)
  };

  updateCount (value) {
    this.setState({ loadingCount: true })

    var prom = this.props.fetchCount(value)
    prom.then(r => {
      if (prom.cancelled) return
      this.setState({ count: r.payload.count, loadingCount: false })
    })
  }

  componentWillMount () {
    this.props.fetchMetadata()
    this.updateCount(this.state.value)
  }

  onSubmit = value => {
    this.props.update(this.props.match.params.id, value)
      .then(({ payload }) => this.props.history.push(`/dealalerts/${payload.result}`))
  };

  render () {
    const { metadata, saving, loading } = this.props

    return (
      <div className='dealalert-edit'>
        <Crumb title='Edit' />
        {loading ? <BigLoader/>
          : <SearchForm
            value={this.state.value}
            loadingCount={this.state.loadingCount}
            count={this.state.count}
            onChange={this.onChange}
            metadata={metadata}
            saving={saving}
            loading={loading}
            onSubmit={this.onSubmit}
          />
        }
      </div>
    )
  }
}

function getDetail (state, id) {
  return state.dealalerts.detail[id] || {}
}

const mapStateToProps = (state, props) => {
  var id = props.match.params.id

  var metadata = getMetadata(state)

  var detail = getDetail(state, id) || { saving: false }
  var alert = state.entities.dealalerts[id]
  var { saving } = detail

  var loading = isMetadataLoading(state)

  return {
    loading, metadata, saving, alert
  }
}

const mapDispatchToProps = {
  fetchMetadata: mls.actions.fetchMetadata,
  fetchCount,
  update
}

DealAlertEditHandler = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DealAlertEditHandler)

export default DealAlertEditHandler
