
var endpoints = ['create', 'destroy', 'read', 'list', 'update', 'search']

var servicedefs = {}

var Router = {

  getUrl: function (entity, service, extra) {
    var url = Router.getServiceDef(entity)[service || 'base']
    if (!url)
      throw new Error('No servicedef for ' + entity + '/' + service)

    return url + (extra ? ('/' + extra) : '')
  },

  url: function () {
    return Router.getUrl.apply(Router, arguments)
  },

  getServiceDef: function (name) {
    if (!servicedefs[name]) {
      var servicedef = App.routes[name]
      if (servicedef) {
        servicedef = Object.assign({}, servicedef)

        endpoints.forEach(function (val) {
          servicedef[val] = servicedef[val] || servicedef.base
        })
      } else {
        console.warn('No route defined for model: ' + name)
        servicedef = { base: '/' + name }
      }

      servicedefs[name] = servicedef
    }

    return servicedefs[name]
  },

  getPickerUrl: function (entity) {
    var def = Router.getServiceDef(entity)
    if (def.search)
      return def.search
    else
      return def.base
  },

  doSessionTimeout: function () {
    window.location = this.getUrl('user', 'sessiontimeout')
  }

}

export default Router
