import React, { useState, useCallback } from 'react'
import { Card, Box, Text } from '@realsoftworks/decor'
import { Monetary, DateTime } from 'common/format'
import { orDash } from 'common/util/renderHelpers'
import EmptyTableMsg from './EmptyTableMsg'

const COL_WIDTHS = [120, 120, 120, 120, 160, 120, 120, 120]
const COLS_SUM = COL_WIDTHS.reduce((a, b) => a + b, 0)
const BORDER_WIDTH = 2

const Mortgages = ({ mortgages }) => {
  // To be used in the future if we make columns sortable
  const [sortBy, setSortBy] = useState('recordingDate') // eslint-disable-line no-unused-vars

  const sortTable = useCallback((l, r) => l[sortBy] > r[sortBy] ? -1 : 1, [sortBy])

  return mortgages && mortgages.length ? (
    <Card
      width='100%'
      borderWidth={BORDER_WIDTH}
      borderColor='yellow.200'
      borderStyle='solid'
      css='overflow: auto;'
    >
      <Box as='table' width='100%' minWidth={COLS_SUM}>
        <colgroup>
          {COL_WIDTHS
            .map((width, i) => (
              <col span='1' key={i} style={{ width }} />
            ))}
        </colgroup>

        <Box as='thead'>
          <Box as='tr'>
            {[
              'Recording Date',
              'Document Number',
              'Loan Type',
              'Loan Amount',
              'Lender Name',
              'Loan Term',
              'Loan Due Date',
              'Lender Address'
            ].map((label, i) => (
              <Text
                as='th'
                p={2}
                key={i}
                bg='yellow.200'
                fontWeight='bold'
                fontSize={0}
              >
                {label}
              </Text>
            ))}
          </Box>
        </Box>
        <Box as='tbody'>
          {mortgages.sort(sortTable).map((m, i) => (
            <MortgageRow isEven={!!((i + 1) % 2)} key={i} {...m} />
          ))}
        </Box>
      </Box>
    </Card>
  ) : (
    <EmptyTableMsg>No mortgages</EmptyTableMsg>
  )
}

export default Mortgages

const MortgageRow = ({
  recordingDate,
  documentNumber,
  loanType,
  amount,
  lender: maybeLender,
  term,
  maturityDate,
  isEven
}) => {
  const lender = maybeLender || {}

  return (
    <Box as='tr'>
      <MortgageCell colWidth={COL_WIDTHS[0]} isEven={isEven}>
        {orDash(recordingDate, v => (
          <DateTime format='MM/DD/YYYY'>{v}</DateTime>
        ))}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[1]} isEven={isEven}>
        {orDash(documentNumber)}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[2]} isEven={isEven}>
        {orDash(loanType)}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[3]} isEven={isEven}>
        {orDash(amount, v => (
          <Monetary>{v}</Monetary>
        ))}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[4]} isEven={isEven}>
        {orDash(lender.name)}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[5]} isEven={isEven}>
        {orDash(term)}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[6]} isEven={isEven}>
        {orDash(maturityDate, v => (
          <DateTime format='MM/DD/YYYY'>{v}</DateTime>
        ))}
      </MortgageCell>

      <MortgageCell colWidth={COL_WIDTHS[7]} isEven={isEven}>
        {orDash(lender.address, v => {
          const { line1, city, state, zip } = v
          return (
            <>
              {line1}{line1 && <br />}
              {[city, state, zip].join(' ')}
            </>
          )
        })}
      </MortgageCell>
    </Box>
  )
}

const MortgageCell = ({
  isEven,
  colWidth,
  children,
  ...props
}) => (
  <Text
    as='td'
    bg={isEven ? 'neutral.100' : 'white'}
    fontSize={0}
    p={2}
    {...props}
  >
    {children}
  </Text>
)
