
import * as ActionTypes from '../../actions'

const initialState = {
  loading: true,
  params: {},
  id: null,
  result: null,
  count: 0,
  error: null,
  mapProps: {}
}

export default function (state = initialState, { payload, type, meta }) {
  switch (type) {
    case ActionTypes.DEALALERT_FETCH_LISTINGS:
      var newstate = {
        ...state,
        loading: true,
        id: meta.id,
        params: meta.params,
        error: null
      }

      if (newstate.id !== state.id) {
        newstate.result = null
        newstate.mapProps = {}
      }

      return newstate

    case ActionTypes.DEALALERT_FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        result: payload.result
      }

    case ActionTypes.DEALALERT_FETCH_LISTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      }

    case ActionTypes.DEALALERT_MAP_UPDATED:
      return {
        ...state,
        mapProps: {
          ...state.mapProps,
          ...payload
        }
      }
  }

  return state
}
