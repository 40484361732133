import { SEARCH_MARKET_LEADS_SUCCESS } from '../actions'

export default (state = {}, action) => {
  switch (action.type) {
    case SEARCH_MARKET_LEADS_SUCCESS:
      if (action.payload && action.payload.entities && action.payload.entities.lead) {
        const leads = action.payload.entities.lead
        return {
          ...state,
          ...leads
        }
      }
      return state
  }
  return state
}
