/* eslint "react/no-find-dom-node": "warn" */
'use strict'

import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'

import BasicField from './util/BasicField.react'
import 'jquery-autosize'

var TextField = createReactClass({
  displayName: 'TextField',
  mixins: [BasicField],

  render: function () {
    var { className, multiline, value, ...restProps } = this.props
    value = value || ''

    if (this.props.multiline)
      return <textarea className={'form-control' || className} value={value} {...restProps} />
    else
      return <input type='text' className={'form-control' || className} value={value} {...restProps} />
  },

  componentDidMount: function () {
    if (this.props.multiline && this.props.autoSize !== false) {
      var dom = ReactDOM.findDOMNode(this)

      // defer initialization of autosize until there is a value
      if (this.props.value)
        $(dom).autosize()
      else
        $(dom).one('keydown', function () {
          $(dom).autosize()
        })
    }
  },

  componentDidUpdate (prevProps) {
    if (this.props.multiline && this.props.autoSize !== false)
      // handle case where value is blanked out.
      if (!this.props.value && prevProps.value)
        $(ReactDOM.findDOMNode(this)).trigger('input')
  }
})

export default TextField
