/* eslint "no-prototype-builtins": "warn" */
import PropTypes from 'prop-types'
import React from 'react'

import pathGet from 'lodash/object/get'

export default function formAware (Field) {
  var clazz = class FormAware extends React.Component {
    constructor (props) {
      super(props)
      this.onChange = this.onChange.bind(this)
    }

    render () {
      var { name } = this.props
      var otherProps = {}

      if (this.context && this.context.formContext) {
        var ctx = this.context.formContext
        if (!this.props.hasOwnProperty('value')) {
          const v = pathGet(ctx.values, name)
          if (typeof v !== 'undefined')
            otherProps.value = v
        }

        otherProps.onChange = this.onChange
      }

      return <Field {...this.props} {...otherProps} />
    }

    onChange (e, v) {
      // support cases like number input where onchange passes original dom event as well as
      // a formatted value

      // if no formatted value
      if (v === undefined)
        if (e && e.target) // normal dom event
          v = e.target.value
        else
          v = e // no event at all - first param is value

      if (this.props.onChange)
        this.props.onChange(e, v)

      if (this.context && this.context.formContext) {
        this.context.formContext.onChange(this.props.name, v)
        this.validate(v)
      }
    }

    validate (v) {
      var errors = []
      if (this.props.hasOwnProperty('required') && (v === '' || typeof v === 'undefined')) {
        var msg = 'This field is required'
        if (typeof this.props.required !== 'undefined' && this.props.required !== true)
          msg = this.props.required

        errors.push(msg)
      }

      this.context.formContext.onValidationChange(this.props.name, errors)
    }
  }

  clazz.contextTypes = {
    formContext: PropTypes.object
  }

  return clazz
}
