import React from 'react'
import ModelForm from '../../../views/util/ModelForm.react'

var fields = [{
  label: 'Contract Price',
  name: 'contract_price',
  type: 'monetary'
}, {
  label: 'Option Period',
  name: 'option',
  type: 'date',
  range: true
}, {
  label: 'Closing Date',
  name: 'closing',
  type: 'date'
}]

class ContractFields extends React.Component {
  render () {
    return (

      <ModelForm mode='editor' loading={this.props.loading} entityId={this.props.lead.id} entityType='lead' fields={fields} model={this.props.lead} onChange={this.props.onChange} />

    )
  }
}

export default ContractFields
