import { Redirect } from 'react-router-dom'
import { ROUTE_REQUIREMENTS } from '../../const'
import RouteReqComponent from './RouteReqComponent'
import logError from 'common/logError'

const withRouteReq = (route, WrappedComponent) => {
  const requirements = ROUTE_REQUIREMENTS[route]

  if (!requirements) {
    logError('requirements is unexpectedly falsy in RouteReqComponent')
    return WrappedComponent
  }

  const CmptWithRouteReq = props => (
    <RouteReqComponent
      routeKey={route}
      alternative={() => <Redirect to='/homeredirect' />}
    >
      <WrappedComponent {...props} />
    </RouteReqComponent>
  )

  CmptWithRouteReq.displayName = `withRouteReq(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return CmptWithRouteReq
}

export default withRouteReq
