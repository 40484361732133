export const Equity = {
  HIGH: 'high',
  LOW: 'low',
  UNDERWATER: 'underwater',
  FREEANDCLEAR: 'owned'
}

export const OwnerType = {
  ABSENTEE: 'absentee',
  OUTOFSTATE: 'outofstate',
  BANK: 'bankowned',
  TRUST: 'trustowned',
  OWNEROCCUPIED: 'owneroccupied'
}
