import { useSyncedIsHovered, useSyncedIsHoveredWithAutoScroll } from 'search/SyncedMapListContext'
import OwnerTypePropertyMarker from 'common/Map/components/MapboxOwnerTypePropertyMarker'
import EquityTypePropertyMarker from 'common/Map/components/MapboxEquityTypePropertyMarker'
import SubscriptPropertyMarker from 'common/Map/components/MapboxSubscriptPropertyMarker'
import { useEffect } from 'react'
import { useLogError } from 'common/util/hooks/useLogError'

const PropertyMarkerIcon = ({ id, marker, setMarkerToBeCentered, setCenterOnMarker, isDrivingForDollars, setHighlightedMarker }) => {
  const { groupId, ownerType, loanToValue, isForeclosure, isVacant } = marker

  useLogError({
    error: 'id is unexpectedly falsy in PropertyMarkerIcon',
    shouldLog: !id
  }, [!!id])

  const { isSyncHovered, hoverRef } = groupId === 'search-history' || groupId === 'd4d' ? useSyncedIsHovered({
    memberId: id,
    groupId
  }) : useSyncedIsHoveredWithAutoScroll({
    memberId: id,
    groupId: 'neighbor'
  })

  function isInViewport (element) {
    const rect = element.getBoundingClientRect()
    const sideBarMenuWidth = 388
    return (
      rect.top >= 0 &&
      rect.left >= sideBarMenuWidth &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  useEffect(() => {
    const hoveredMarker = document.querySelector(`.marker-id-${id}`)
    const isHoveredMarkerVisible = isInViewport(hoveredMarker)
    if (isSyncHovered && !isHoveredMarkerVisible) setMarkerToBeCentered(marker)
    if (isHoveredMarkerVisible) setCenterOnMarker(false)
    setHighlightedMarker({ id: id, isHighlighted: isSyncHovered })
  }, [isSyncHovered])

  return (
    <div ref={hoverRef} className={ isSyncHovered ? `marker__container marker-id-${id} isHighlighted` : `marker__container marker-id-${id}`}>
      {isDrivingForDollars
        ? null
        : <>
          <EquityTypePropertyMarker loanToValue={loanToValue} />
          <OwnerTypePropertyMarker ownerType={ownerType} />
          <SubscriptPropertyMarker isForeclosure={isForeclosure} isVacant={isVacant} />
        </>
      }
    </div>
  )
}

export default PropertyMarkerIcon
