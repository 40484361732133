/* eslint "eqeqeq": "warn" */
import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import IconCheckbox from 'common/IconCheckbox'
import { Text } from '@realsoftworks/decor'
import { faThumbsUp, faQuestion, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled(Text)`
  @media print {
    font-size: 14px;

    ${IconCheckbox} {
      display: none;

      &[data-checked=true] {
        display: block;
      }
    }
  }
`

class StateButtons extends React.Component {
  onStateChange = (e, checked) => {
    var value = null
    if (checked)
      value = e.target.name

    this.props.onChange(e, value)
  };

  render () {
    var { state, saving } = this.props

    var wrapperClasses = classNames('cma-state-buttons', { saving: saving })

    return (
      <Wrapper display='flex' fontSize={4} className={wrapperClasses} style={this.props.style}>
        <IconCheckbox
          data-checked={state == 'included'}
          mr={2}
          color='teal.700'
          icon={faThumbsUp}
          checked={state == 'included'}
          name='included'
          onChange={this.onStateChange}
        />
        <IconCheckbox
          data-checked={state == 'unknown'}
          mr={2}
          color='blue.700'
          icon={faQuestion}
          checked={state == 'unknown'}
          name='unknown'
          onChange={this.onStateChange}
        />
        <IconCheckbox
          data-checked={state == 'excluded'}
          color='red.700'
          icon={faThumbsDown}
          checked={state == 'excluded'}
          name='excluded'
          onChange={this.onStateChange}
        />
      </Wrapper>
    )
  }
}

export default StateButtons
