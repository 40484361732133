import React, { useMemo, FC } from 'react'
import { Box, Checkbox, Text } from '@realsoftworks/decor'
import AccordionFilterSection from 'leadlists/components/AccordionFilterSection'
import get from 'lodash/object/get'
import set from 'common/util/set'

type NewOnlyFilters = {
  newOnly?: boolean
}

const MiscSection: FC<{
  filters: NewOnlyFilters
  onChange: (filters: NewOnlyFilters) => void
  openSection: string | null
  toggleSectionFactory: (section: string | null) => () => void
}> = ({
  filters,
  onChange,
  openSection,
  toggleSectionFactory
}) => {
  const SECTION_KEY = 'MISC'
  const isSectionOpen = openSection === SECTION_KEY

  const createCheckboxProps = key => ({
    onChange: (_ev, value) =>
      onChange(set(
        key,
        value ? true : undefined,
        filters
      )),
    checked: get(filters, key)
  })

  const rerenderDeps = [
    onChange,
    isSectionOpen,
    get(filters, 'newOnly')
  ]

  return useMemo(() =>
    <AccordionFilterSection
      mt={5}
      heading='List Options'
      contentContainerProps={{ pt: 2 }}
      toggleSection={toggleSectionFactory(SECTION_KEY)}
      isSectionOpen={isSectionOpen}
    >
      <Box mx={-3} display='flex' flexWrap='wrap'>
        <Box flex='1 1 180px' minWidth='180px' p={2} px={3}>
          <Checkbox label='Include new records only' {...createCheckboxProps('newOnly')} />
          <Text display='block' mt={-2} ml='29px' color='secondary'>
            Only include records which are not in a previously saved list
          </Text>
        </Box>
      </Box>
    </AccordionFilterSection>,
  rerenderDeps)
}

export default MiscSection
