import { useMedia } from 'react-use'
import { breakpointsKeyed } from '../../../../theme'

const useBreakpoint = () => {
  const gtOrEqMinOfSm = useMedia(`(min-width: ${breakpointsKeyed.sm})`)
  const gtOrEqMinOfMd = useMedia(`(min-width: ${breakpointsKeyed.md})`)
  const gtOrEqMinOfLg = useMedia(`(min-width: ${breakpointsKeyed.lg})`)
  const gtOrEqMinOfXl = useMedia(`(min-width: ${breakpointsKeyed.xl})`)
  const current = gtOrEqMinOfXl ? 'xl'
    : gtOrEqMinOfLg ? 'lg'
      : gtOrEqMinOfMd ? 'md'
        : gtOrEqMinOfSm ? 'sm'
          : 'xs'
  const gtOrEqMinOfXs = true
  return {
    xs: current === 'xs',
    xsUp: gtOrEqMinOfXs,
    xsDown: gtOrEqMinOfXs && !gtOrEqMinOfSm,

    sm: current === 'sm',
    smUp: gtOrEqMinOfSm,
    smDown: gtOrEqMinOfXs && !gtOrEqMinOfMd,

    md: current === 'md',
    mdUp: gtOrEqMinOfMd,
    mdDown: gtOrEqMinOfXs && !gtOrEqMinOfLg,

    lg: current === 'lg',
    lgUp: gtOrEqMinOfLg,
    lgDown: gtOrEqMinOfXs && !gtOrEqMinOfXl,

    xl: current === 'xl',
    xlUp: gtOrEqMinOfXl,
    xlDown: true,

    current
  }
}

export default useBreakpoint
