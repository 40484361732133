import React from 'react'
import DocumentTitle from 'react-document-title'
import { Link, Route, Switch } from 'react-router-dom'
import IndexHandler from './SitesIndexHandler'
import DetailHandler from './SiteDetailHandler'
import { LegacyPageLayout, DefaultLayout } from 'common/layouts'
import { Title } from 'common/Page'
import Breadcrumbs, { Crumb } from 'common/Breadcrumbs'
import ManageDnsHandler from './ManageDns/ManageDnsHandler'

class SitesHandler extends React.Component {
  render () {
    return (
      <LegacyPageLayout
        className='sites'
        title={
          <Title>
            <Breadcrumbs />
          </Title>
        }
        content={
          <DefaultLayout>
            <Crumb
              title='Websites'
              link={<Link to='/sites' />}
            />
            <DocumentTitle title='Websites'>
              <Switch>
                <Route path={`${this.props.match.path}/`} exact component={IndexHandler}/>
                <Route path={`${this.props.match.path}/domains/:domain/manage`} exact component={ManageDnsHandler}/>
                <Route path={`${this.props.match.path}/:id`} component={DetailHandler}/>
              </Switch>
            </DocumentTitle>
          </DefaultLayout>
        }
      />
    )
  }
}

export default SitesHandler
