import * as Actions from '../actions'

export default function (state = { loading: false, data: null, byId: null }, { type, payload }) {
  switch (type) {
    case Actions.MLS_METADATA_FETCH:
      return {
        ...state,
        loading: true,
        error: false
      }

    case Actions.MLS_METADATA_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        byId: index(payload)
      }

    case Actions.MLS_METADATA_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      }
  }

  return state
}

function index (data) {
  return data.reduce((ret, v) => {
    ret[v.id] = v
    return ret
  }, {})
}
