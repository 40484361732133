import PropTypes from 'prop-types'

export const CoordShape = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired
})

export const StreetviewShape = PropTypes.shape({
  position: CoordShape,
  heading: PropTypes.number.isRequired,
  pitch: PropTypes.number
})

export function convertCoords (position) {
  if (!position) return position

  const { lat, lon, lng } = position
  return { lat, lng: lon || lng }
}
