/* eslint "react/display-name": "warn", "react/jsx-key": "warn" */
import React from 'react'
import styled from 'styled-components'
import { Box, Text, Caption } from '@realsoftworks/decor'
import themeGet from '@styled-system/theme-get'
import ServiceCost from 'marketing/components/ServiceCost'
import { Numeric } from 'common/format'

const TableHeaderRow = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${themeGet('colors.neutral.200')};
  padding-bottom: 8px;
  margin: 20px 0;
`
const TableRow = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${themeGet('colors.neutral.200')};
  padding-bottom: 20px;
  align-items: center;
`

const TableCell = styled(Box)`
  ${props => `
    text-align: ${props.textAlign ? props.textAlign : 'left'};
  `}
`

export default ({ items, ...props }) => (
  <Box {...props}>
    <TableHeaderRow>
      <TableCell flex='3'>
        <Caption color='secondary'>Item</Caption>
      </TableCell>
      <TableCell flex='1' textAlign='right'>
        <Caption color='secondary'>Price</Caption>
      </TableCell>
      <TableCell flex='1' textAlign='right'>
        <Caption color='secondary'>Qty</Caption>
      </TableCell>
      <TableCell flex='2' textAlign='right'>
        <Caption color='secondary'>Total</Caption>
      </TableCell>
    </TableHeaderRow>
    {items.map((item, index) => (
      <TableRow key={`${item.name}-${index}`}>
        <TableCell flex='3'>
          <Text>{item.name}</Text>
        </TableCell>
        <TableCell flex='1' textAlign='right'>
          <Text>$<ServiceCost quantity={1} service={item.service} /></Text>
        </TableCell>
        <TableCell flex='1' textAlign='right'>
          <Text><Numeric>{item.quantity}</Numeric></Text>
        </TableCell>
        <TableCell flex='2' textAlign='right'>
          <Text fontSize={3}>$<ServiceCost quantity={item.quantity} service={item.service} /></Text>
        </TableCell>
      </TableRow>
    ))}
  </Box>
)
