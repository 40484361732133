/* eslint "react/no-unescaped-entities": "warn" */
import React, { useEffect } from 'react'
import * as leadSelectors from '../selectors'
import Detail from './LeadDetailHandler'
import BigLoader from 'common/BigLoader'
import { connect } from 'react-redux'
import { read } from 'leads/actions'

const mapState = (state, { leadId }) =>
  ({ lead: leadSelectors.getLead(state, leadId) })
const mapDispatch = { read }
const withConnect = connect(mapState, mapDispatch)

const SearchLeadViewHandler = withConnect(({
  read,
  leadId,
  lead,
  match
}) => {
  let child
  const readStatus = lead && lead.readStatus

  // Perform lead read as necessary
  useEffect(() => {
    if (readStatus !== 'success' && readStatus !== 'loading') read(leadId)
  }, [])

  if (readStatus === 'success') {
    const childProps = { leadId, lead, match }
    child = <Detail {...childProps} />
  } else if (
    !readStatus ||
    (readStatus === 'unstarted') ||
    (readStatus === 'loading')
  ) { child = <BigLoader /> } else { child = <NoLead /> }

  return (
    <div id='lead-view' className='lead-view'>
      {child}
    </div>
  )
})

export default SearchLeadViewHandler

const NoLead = () => (
  <div>
    <h1 className='text-muted'>Whoa! Lead not found!</h1>
    <p>Something was wrong with your request. Maybe you don't have permissions for the link you clicked on?</p>
  </div>
)
