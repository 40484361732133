import React from 'react'
import Container from 'drivingfordollars/components/Container'
import { Heading } from '@realsoftworks/decor'
import DrivesStatsSection from './DrivesStatsSection'
import DrivesListSection from './DrivesListSection'

const AllDrives = () => (
  <Container mx='auto'>
    <Heading size={5}>Driving for Dollars - All Drives</Heading>
    <DrivesStatsSection mt={1} />
    <DrivesListSection mt={7} />
  </Container>
)

export default AllDrives
