/* eslint "handle-callback-err": "warn" */
import qs from 'qs'

import api from 'common/api'
import fetch from 'common/fetch'
import { normalize } from 'normalizr'
import legacyToParcelParams from './parcels/legacyToParcelParams'
import { selectShouldRequireLandlord } from './selectors'
import requireLandLordOnlyForParams from './parcels/requireLandLordOnlyForParams'

export const FETCH_PROPERTY_DATA = 'FETCH_PROPERTY_DATA'
export const FETCH_PROPERTY_DATA_SUCCESS = 'FETCH_PROPERTY_DATA_SUCCESS'
export const FETCH_PROPERTY_DATA_FAILURE = 'FETCH_PROPERTY_DATA_FAILURE'

export function fetchPropertyData (params) {
  return dispatch => {
    dispatch({ type: FETCH_PROPERTY_DATA })
    const { city, state, zip, line1 } = params
    var data = qs.stringify({ city, state, zip, line1 })

    let payload = {}
    return fetch(`/property/v4/?${data}`,
      {
        method: 'GET',
        dataType: 'json',
        foo: 'bar'
      })
      .then(res => res.json())
      .then(json => {
        payload = json.results[0]
        dispatch({ type: FETCH_PROPERTY_DATA_SUCCESS, payload })
        return payload
      })
      .catch(error => {
        dispatch({ type: FETCH_PROPERTY_DATA_FAILURE, payload })
        throw error
      })
  }
}

export const FETCH_NEIGHBORS = 'FETCH_NEIGHBORS'
export const FETCH_NEIGHBORS_SUCCESS = 'FETCH_NEIGHBORS_SUCCESS'
export const FETCH_NEIGHBORS_FAILURE = 'FETCH_NEIGHBORS_FAILURE'

export function fetchNeighbors (params) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_NEIGHBORS })
    const { lat, lon } = params
    const shouldRequireLandlord = selectShouldRequireLandlord(getState(), {})

    /** @TODOTHISCOMMIT Landlord filters */
    const payload = {}
    return fetch('/parcels/v1/listbuilder/seller',
      {
        method: 'POST',
        dataType: 'json',
        body: JSON.stringify({
          limit: 25,
          location: {
            neighborhood: [lat, lon]
          },
          filters: shouldRequireLandlord ? requireLandLordOnlyForParams({}) : {}
        })
      })
      .then(res => res.json())
      .then(json => {
        dispatch({ type: FETCH_NEIGHBORS_SUCCESS, payload: json.items })
      })
      .catch(_error => dispatch({ type: FETCH_NEIGHBORS_FAILURE, payload }))
  }
}

const callApi = async (dispatch, { url, body, method = 'get', types, meta, schema, payload = f => f }) => {
  if (types.start)
    dispatch({ type: types.start, meta })

  const result = await api[method.toLowerCase()](url, body)
  if (result.error && types.error)
    return dispatch({ type: types.error, payload: result.error, meta })

  const transformed = schema ? normalize(result, schema) : payload(result)
  if (types.success)
    dispatch({ type: types.success, meta, payload: transformed })

  return transformed
}

const endpoints = {
  /** @TODO */
  CashSales: '/national-leads/v1/cashbuyers',
  Custom: '/parcels/v1/listbuilder/seller'
}

export const FETCH_CUSTOM_NEIGHBORS = 'FETCH_CUSTOM_NEIGHBORS'
export const FETCH_CUSTOM_NEIGHBORS_SUCCESS = 'FETCH_CUSTOM_NEIGHBORS_SUCCESS'
export const FETCH_CUSTOM_NEIGHBORS_FAILURE = 'FETCH_CUSTOM_NEIGHBORS_FAILURE'

export const fetchCustomNeighbors = (
  {
    offset = 0,
    limit = 50,
    ...params
  },
  fetchSource
) => async (dispatch, getState) => {
  const cashSalesQuery = qs.stringify({ ...params, limit })
  return callApi(dispatch, {
    url: `${endpoints[fetchSource]}?${fetchSource === 'CashSales' ? cashSalesQuery : ''}`,
    method: fetchSource === 'CashSales' ? 'GET' : 'POST',
    dataType: 'json',
    body: legacyToParcelParams(
      { limit, offset, ...params },
      { isLandLordRequired: fetchSource !== 'CashSales' && selectShouldRequireLandlord(getState(), params) }
    ),
    meta: { params: { offset, limit, ...params }, fs: fetchSource, ts: Date.now() },
    types: {
      start: FETCH_CUSTOM_NEIGHBORS,
      success: FETCH_CUSTOM_NEIGHBORS_SUCCESS,
      error: FETCH_CUSTOM_NEIGHBORS_FAILURE
    }
  })
}

export const FETCH_CUSTOM_NEIGHBORS_COUNT = 'FETCH_CUSTOM_NEIGHBORS_COUNT'
export const FETCH_CUSTOM_NEIGHBORS_COUNT_SUCCESS = `${FETCH_CUSTOM_NEIGHBORS_COUNT}_SUCCESS`
export const FETCH_CUSTOM_NEIGHBORS_COUNT_FAILURE = `${FETCH_CUSTOM_NEIGHBORS_COUNT}_FAILURE`

export const TOGGLE_LANDLORD = 'propertydata/shared_filters/TOGGLE_LANDLORD'
export const toggleLandlord = () => ({ type: TOGGLE_LANDLORD })

export const SET_LANDLORD = 'propertydata/shared_filters/SET_LANDLORD'
export const setLandlord = isLandlord =>
  ({ type: SET_LANDLORD, payload: { isLandlord } })
