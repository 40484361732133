import React, { useState } from 'react'
import { arrayOf, shape, string, oneOfType, object, func, bool } from 'prop-types'
import moment from 'moment'
import { Box, Text, IconButton } from '@realsoftworks/decor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const Image = ({ children, onDelete, isDeletable, ...props }) => {
  if (!children || !children.length) return null

  const [currIndex, setCurrIndex] = useState(0)
  const curr = children[currIndex]
  const isMany = children.length > 1

  return (
    <Box py={2} px={1} {...props}>
      <Box display='flex' justifyContent='center' alignItems='center'>

        {/* Left Button */}
        {isMany && (
          <IconButton
            // Workaround for ClickToEdit's clickable container
            onMouseUp={ev => { ev.stopPropagation() }}
            onClick={() => {
              setCurrIndex(curr => (curr - 1) < 0 ? children.length - 1 : curr - 1)
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        )}

        {/* Image */}
        <Box flex={1} display='flex' flexDirection='column' alignItems='center'>
          <Box
            borderWidth={2}
            borderStyle='solid'
            borderColor='neutral.200'
            src={curr.link}
            as='img'
          />

          {isMany && (
            <Box flex='1' mt={2}>
              <Text fontSize={0} color='neutral.500'>{currIndex + 1} of {children.length}</Text>
            </Box>
          )}
        </Box>

        {/* Right Button */}
        {isMany && (
          <IconButton
            // Workaround for ClickToEdit's clickable container
            onMouseUp={ev => { ev.stopPropagation() }}
            onClick={() => {
              setCurrIndex(curr => (curr + 1) > (children.length - 1) ? 0 : curr + 1)
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        )}
      </Box>

      {/* Info Section */}
      <Box mt={isMany ? 3 : 4} display='flex' alignItems='center'>
        <Box flex='1'>
          <Text fontSize={1} color='blue.900'>{curr.name}</Text>
          <Box mt={1}>
            <Text fontSize={0} color='neutral.500'>
              Uploaded by {curr.createdBy} {moment(curr.createdAt).fromNow()}
            </Text>
          </Box>
        </Box>

        {isDeletable && (
          <IconButton variant='danger' onClick={() => { onDelete(curr.id) }}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

Image.propTypes = {
  children: arrayOf(shape({
    link: string.isRequired,
    name: string.isRequired,
    createdBy: string,
    createdAt: oneOfType([string, object]),
    id: string
  })).isRequired,
  onDelete: func,
  isDeletable: bool
}

export default Image
