import { number } from 'prop-types'

const Duration = ({ children: dur }) => {
  if (typeof dur !== 'number') return '--'

  let result = ''
  const { trunc, round } = Math
  const hours = trunc(dur / 3600)
  const minutes = round((dur % 3600) / 60)

  if (hours) result += `${hours}h${NBSP}`

  if (hours && minutes < 10) result += '0'

  result += `${minutes}m`

  return result
}

export default Duration

Duration.propTypes = { children: number }

// This is a non breaking space, not a regular space
const NBSP = ' '
