/* eslint "no-prototype-builtins": "warn", "eqeqeq": "warn", "camelcase": "warn", "react/no-deprecated": "warn", "react/jsx-key": "warn" */
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import get from 'lodash/object/get'

import { CMAMap } from 'cma'
import CMACriteria from './CMACriteria.react'
import CMAAddCriteria from './CMAAddCriteria.react'
import CMASummary from './CMASummary.react'
import CompModal from './CompModal.react'
import { Disclosure as CMADisclosure } from 'mls/components'
import CMALastUpdated from './CMALastUpdated.react'
import cloneDeep from 'lodash/lang/cloneDeep'

import BigLoader from 'common/BigLoader'
import { Box, Text, Card, toast } from '@realsoftworks/decor'
import { CardTitle } from 'common/Card'
import LoadingMask from 'common/LoadingMask'
import omit from 'lodash/object/omit'
import partition from 'lodash/collection/partition'
import { Monetary } from 'common/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import Button from '@realsoftworks/decor/dist/components/Button'
import CompsCategoryGroups from './CompsCategoryGroups'
import styled from 'styled-components'
import MLSNextDisclosures from './MLSNextDisclosures'

const PrintStatus = styled.div`
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  background-color: #EBF8FF;
  font-weight: bold;

  @media (min-width: 576px) {
    margin: 8px 0;
    margin-left: 4px;
    margin-right: 4px;
  }

  @media print {
    display: none;
  }
`

const PrintStatusContainer = styled(Card)`
  @media print {
    dispaly: none;
  }
`

const PRINT_NOT_SUPPORTED = {
  title: 'Print failed',
  content: 'Sorry, your browser doesn’t seem to support printing. Please try again using a latest browser.'
}

class CMAResultsPage extends React.Component {
  static propTypes = {
    cma: PropTypes.object,
    metadata: PropTypes.object,
    onSearch: PropTypes.func
  };

  constructor (props, context) {
    super(props, context)
    const addFilters = this.getAddFilters(props.cma.params)
    const filterParams = this.reduceObjParams(props.cma.params)

    this.state = {
      selections: [],
      excludedShown: {},
      selectedTab: 0,
      hasAdditionalFilters: addFilters.length > 0,
      showAddCriteria: !this.isAddFiltersEmpty(addFilters, filterParams),
      params: Object.assign({}, filterParams),
      addFilters,
      printNow: false,
      openPrintDialog: false
    }

    this.handleBrowserDefaultPrintDialog = this.handleBrowserDefaultPrintDialog.bind(this)
  }

  handleBrowserDefaultPrintDialog (event) {
    if (event.metaKey && event.keyCode === 80 || event.ctrlKey && event.keyCode === 80) {
      event.stopPropagation()
      event.preventDefault()
      this.print()
    }
  }

  // make meta data consistent for rendering
  cleanMetaAddFilters = filters => filters.map(filter => {
    filter.isAddFilter = true
    if (filter.id === 'property_type')
      filter.label = 'Property Type'

    return filter
  });

  getAddFilters = params => {
    const excludedFields = ['beds', 'baths_full', 'baths_half', 'year_built', 'sqft']
    let fields = this.props.metadata.fields.filter(field => {
      if (excludedFields.indexOf(field.id) > -1)
        // ignore excluded field
        return false

      if (field.searchable)
        return true

      return false
    })
    fields = this.cleanMetaAddFilters(fields, params)
    return fields
  };

  isAddFiltersEmpty = (filters, params) => {
    let isEmpty = true
    filters.forEach(filter => {
      for (var param in params)
        if (param === 'address') {
          if (params[param].subdivision)
            isEmpty = false
        } else if (params.hasOwnProperty(param) && param === filter.id) { isEmpty = false }
    })
    return isEmpty
  };

  reduceObjParams = params => {
    for (var param in params)
      if (params.hasOwnProperty(param))
        if (param === 'address') {
          if (params[param].subdivision)
            params['address.subdivision'] = params[param].subdivision

          if (!params[param])
            delete params.address
        }

    return params
  };

  print = () => {
    if (!this.state.printNow) {
      if (!window && typeof window.print !== 'function')
        return toast.error(PRINT_NOT_SUPPORTED)

      this.setState({ printNow: true }, () => {
        setTimeout(() => this.setState({ openPrintDialog: true }), 3000)
      })
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleBrowserDefaultPrintDialog, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleBrowserDefaultPrintDialog, false)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.openPrintDialog !== this.state.openPrintDialog)
      if (this.state.openPrintDialog) {
        window.print()
        this.setState({ printNow: false, openPrintDialog: false })
      }
  }

  render () {
    const { cma, lead, metadata, loading } = this.props
    const { states, data } = cma
    const { sales, leases } = data

    const params = this.state.params
    const mapList = this.state.selectedTab == 0 ? sales : leases
    const mapComps = partition(mapList, c => states[c.key] !== 'excluded')[0] // included+unknown only

    const stats = getStats(cma)

    return (
      <div className='cma-results'>
        <Box
          display='flex'
          flexDirection='row'
          mx={-1}
          flexWrap='wrap'
          justifyContent='stretch'
        >
          <Box
            px={1}
            py={[1, 2]}
            flex='9999 1 480px'
            alignSelf='stretch'
            minHeight='520px'
            css={`
              position: relative;
            `}
          >
            <Card
              className='print-normalize-map-container'
              height='100%'
            >
              <CMAMap printNow={this.state.printNow} comps={mapComps} states={states} subject={lead} onMarkerClick={this.onCompClick}/>
            </Card>
            {this.state.printNow && <PrintStatusContainer
              className='print-normalize-map-container'
              height='100%'
            >
              <PrintStatus>Printing...</PrintStatus>
            </PrintStatusContainer>}
          </Box>

          <Box
            px={1}
            py={[1, 2]}
            flex='1 0 400px'
            className={this.state.printNow ? 'hide-while-printing' : ''}
          >
            <Card
              variant='basic'
              p={[2, 3, 4, 5]}
              pt={[3, null, null, 5]}
            >
              <Box mb={3} display='flex' flexWrap='wrap' alignItems='flex-start'>
                <Text flexGrow={2} fontSize={0} color='secondary'>
                  {stats.totalPropsCount === 0 ? (
                    <Fragment>
                      No Comps In Current Search&nbsp;Criteria
                      <Box pt={1}>
                        {stats.includedCompsCount} of {stats.totalPropsCount} comps included
                      </Box>
                    </Fragment>
                  ) : stats.includedCompsCount === 0 ? (
                    <Fragment>
                      Select properties below to&nbsp;include&nbsp;in&nbsp;CMA
                      <Box pt={1}>
                        {stats.includedCompsCount} of {stats.totalPropsCount} comps included
                      </Box>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Box pb='2px' display='block'>
                        Suggested ARV Avg Price:
                      </Box>
                      <Text fontSize={1} color='text'>
                        <Monetary decimals={0}>{stats.avgPrice || '--'}</Monetary>
                      </Text>
                      <Box pl={2} display='inline-block'>
                        {stats.includedCompsCount}&nbsp;of&nbsp;{stats.totalPropsCount}&nbsp;comps&nbsp;included
                      </Box>
                    </Fragment>
                  )}
                </Text>

                <Box flexGrow={1} display='flex' justifyContent='flex-end'>
                  <Button onClick={this.print} css={{ opacity: this.state.printNow ? '.5' : '1', cursor: this.state.printNow ? 'not-allowed' : 'pointer' }}>
                    <FontAwesomeIcon icon={faPrint} /> {this.state.printNow ? 'Printing...' : 'Print'}
                  </Button>
                </Box>
              </Box>
              <Box mb={4}>
                <CardTitle>Criteria</CardTitle>
              </Box>
              <CMACriteria
                loading={loading}
                metadata={metadata}
                setDefaultCriteria={this.setDefaultCriteria}
                showAddCriteria={this.state.showAddCriteria}
                toggleAddCriteria={this.toggleAddCriteria}
                onChange={this.onChange}
                params={params}
                onSearch={this.onSearch}
              />
            </Card>
          </Box>
        </Box>

        {this.state.hasAdditionalFilters && this.state.showAddCriteria &&
          <Card variant='padded' className={this.state.printNow ? 'cma-add-criteria-card hide-before-printing' : 'cma-add-criteria-card'}>
            <h1>Additional Criteria</h1>
            <CMAAddCriteria filters={this.state.addFilters} onChange={this.onChange} params={params} onSearch={this.onSearch}/>
          </Card>
        }

        <Card variant='basic' p={[2, 3, 4, 5]} className={this.state.printNow ? 'cma-summary-card hide-before-printing' : 'cma-summary-card'} mt={5}>
          <h1>CMA Summary</h1>
          <CMAPrintableCriteria params={params} metadata={metadata}/>
          <CMASummary cma={cma} isSavingArv={this.props.isSavingArv} subject={lead.values} onSaveArv={this.saveArv}/>
        </Card>

        <Card variant='basic' p={[1, 2]} className={this.state.printNow ? 'cma-list-card hide-before-printing' : 'cma-list-card'} mt={5}>
          {loading ? <LoadingMask><BigLoader/></LoadingMask> : '' }

          <CompsCategoryGroups
            leadId={lead.id}
            onCompClick={this.onCompClick}
            onCompStateChange={this.onStateChange}
            onTabChange={this.onTabChange}
            rerunCma={() => this.onSearch(params)}
          />

          <div className={this.state.printNow ? 'cma-footer hide-before-printing' : 'cma-footer'}>
            {cma.updatedAt ? <CMALastUpdated date={cma.updatedAt}/> : null }
            {cma.source === 'mls-next' && Array.isArray(cma.organizations) ? (
              <MLSNextDisclosures organizations={cma.organizations} startDate={cma.start_dt} asOfDate={cma.as_of_dt} />
            ) : cma.source !== 'mls-next' ? (
              <CMADisclosure source={cma.source} startDate={cma.start_dt} asOfDate={cma.as_of_dt}/>
            ) : null}
          </div>

        </Card>

        {this.state.compDetails
          ? <CompModal comp={this.state.compDetails}
            fields={this.props.metadata.fields}
            state={cma.states[this.state.compDetails.key]}
            onStateChange={this.onStateChange.bind(this, this.state.compDetails.key)}
            show={this.state.modalShown}
            onRequestClose={this.onModalClose}
          />
          : ''}
      </div>
    )
  }

  toggleAddCriteria = () => {
    const showCriteria = !this.state.showAddCriteria
    this.setState({
      showAddCriteria: showCriteria
    })
  };

  onChange = (name, value) => {
    var delta = { [name]: value }
    var params = Object.assign({}, this.state.params, delta)
    if (value === '' || value === null) {
      delete params[name]
      if (name === 'address.subdivision') // may want to refactor
        delete params.address
    } else if (typeof value === 'object' && (value.min === null || value.min === undefined) && (value.max === null || value.max === undefined)) { delete params[name] }

    this.setState({ params })
  };

  setDefaultCriteria = () => {
    const params = { ...this.state.params }
    const { beds, baths_full, year_built, sqft } = this.props.lead.values

    if (beds)
      params.beds = { min: beds - 1, max: beds + 1 }

    if (baths_full)
      params.baths_full = { min: baths_full - 1, max: baths_full + 1 }

    if (year_built)
      params.year_built = { min: year_built - 5, max: year_built + 5 }

    if (year_built)
      params.sqft = { min: Math.floor(sqft * 0.8), max: Math.floor(sqft * 1.2) }

    this.setState({ params }, () => {
      this.onSearch(params)
    })
  };

  prepareSearchParams = params => {
    let searchParams = cloneDeep(params)

    searchParams = Object.keys(searchParams).reduce((ret, v) => {
      const value = searchParams[v]
      if (value !== null)
        if (typeof value === 'object')
          // remove nulls from objects
          ret[v] = omit(value, v => v === null)
        else
          ret[v] = value

      return ret
    }, {})

    this.state.addFilters.forEach(filter => {
      for (var param in searchParams)
        if (searchParams.hasOwnProperty(param))
          if (filter.id === param) {
            if (filter.type === 'boolean') {
              const value = searchParams[param] ? searchParams[param] : null
              if (value === '1')
                searchParams[param] = 1
              else if (value === '0')
                searchParams[param] = 0
              else
                delete searchParams[param]
            } else if (filter.type === 'number') {
              const value = searchParams[param]
              if (value.min === null)
                delete searchParams[param].min

              if (value.max === null)
                delete searchParams[param].max
            }
            // expand objects - corollary to 'reduceObjParams' method
            if (filter.id === 'address.subdivision') {
              searchParams.address = { subdivision: params[param] }
              if (searchParams['address.subdivision'])
                delete searchParams['address.subdivision']
            }
          }
    })
    return searchParams
  };

  onSearch = params => {
    const searchParams = this.prepareSearchParams(params)
    this.props.onSearch(searchParams)
  };

  onCompClick = clicked => {
    var key = clicked

    // arg could be comp object instead so if it is,
    if (clicked.key || clicked.id)
      key = clicked.key || clicked.id

    var { data } = this.props.cma

    var comp = data.sales.find(el => (el.key === key))
    if (!comp)
      comp = data.leases.find(el => (el.key === key))

    this.setState({
      compDetails: comp,
      modalShown: true
    })
  };

  onStateChange = (key, state) => {
    this.props.onStateChange(key, state)
  };

  onModalClose = () => {
    this.setState({
      modalShown: false
    })
  };

  onTabChange = selectedTab => {
    this.setState({ selectedTab })
  };

  onSelectionChange = selected => {
    this.setState({ selections: selected })
  };

  saveArv = (arv, arvType) => {
    this.props.onSaveArv(arv, arvType)
  };
}

const CMAPrintableCriteria = ({ params, metadata }) => {
  const values = Object.keys(params).reduce((ret, k) => {
    const param = params[k]

    if (param == null || k == 'source')
      return ret

    let label = ''
    const field = metadata.fields.find(v => v.id == k)
    if (field && field.label)
      label = field.label
    else
      label = k.split('_').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ')

    const NULL = 'any'
    let value = ''
    if (typeof param === 'object') {
      const { min, max } = param
      value = (min == null ? NULL : min) + ' - ' + (max == null ? NULL : max)
    } else { value = param }

    ret.push(`${label}: ${value}`)

    return ret
  }, [])

  return (
    <div className='cma-printable-criteria'>
      <h3 className='title'>Criteria</h3>
      {values.join(', ')}
    </div>
  )
}

export default CMAResultsPage

const getStats = cma => {
  const states = get(cma, 'states', {})
  const sales = get(cma, 'data.sales', [])

  const partialStats = sales.reduce((acc, comp) => {
    const totalPropsCount = acc.totalPropsCount + 1
    if (states[comp.key] !== 'included') return { ...acc, totalPropsCount }

    return {
      totalPropsCount,
      includedCompsCount: acc.includedCompsCount + 1,
      compsTotalPrice: acc.compsTotalPrice + (comp.close_price || comp.list_price)
    }
  }, { includedCompsCount: 0, compsTotalPrice: 0, totalPropsCount: 0 })

  const avgPriceRaw = partialStats.compsTotalPrice / partialStats.includedCompsCount
  const avgPrice = Number.isFinite(avgPriceRaw) ? avgPriceRaw : null
  const stats = { avgPrice, ...partialStats }

  return stats
}
