/* eslint "react/no-find-dom-node": "warn", "react/no-deprecated": "warn" */
import React from 'react'
import ReactDOM from 'react-dom'

import $ from 'jquery'
import Router from '../../core/Router'
import 'jquery-ui/widget'
import 'blueimp-file-upload/js/jquery.iframe-transport'
import 'blueimp-file-upload/js/jquery.fileupload'
import 'blueimp-file-upload/js/jquery.fileupload-process'
import 'blueimp-file-upload/js/jquery.fileupload-validate'

const bytesToMb = n => n / 1048576

const noop = () => {}

class FileInput extends React.Component {
  static defaultProps = {
    autoUpload: true,
    formData: {},
    paramName: '',
    onAdd: noop,
    onStart: noop,
    onProgress: noop,
    onError: noop,
    onFinish: noop
  };

  render () {
    return (
      <span className={(this.props.hidden ? 'hide' : '') + ' fileinput'}></span>
    )
  }

  componentDidMount () {
    this.resetField(this.props)
  }

  resetField = inputProps => {
    var me = this
    var $d = $(ReactDOM.findDOMNode(this))
    var $field = $d.find('input[type=file]')
    $field.remove && $field.remove()
    // TODO alter to use destroy instead of remove -- possible memory leak.

    var $input = $('<input type="file" tabindex="-1"/>').appendTo($d)
    var filesize = App.maxFileSize || (64 * 1048576)

    var formData = Object.assign({ 'data[_Token][key]': App.token }, inputProps.formData)

    var props = {
      url: inputProps.url || Router.getUrl('upload'),
      formData: formData,
      dataType: 'json',
      maxFileSize: filesize,
      autoUpload: inputProps.autoUpload,
      paramName: inputProps.paramName,

      messages: {
        maxFileSize: 'File too large. Max upload size is ' + bytesToMb(filesize) + 'mb'
      },

      send: function () {
        me.onStart()
      },
      done: function (e, data) {
        var file = data.result.result || data.result
        me.onFinish(file)
      },

      fail: function (e, data) {
        me.onError({ message: props.messages.maxFileSize })
      }
    }

    $input.fileupload(props).bind('fileuploadprocessfail', function (e, data) {
      var f = data.files[0]
      me.onError(f.error)
    }).bind('fileuploadprogress', function (e, data) {
      var progress = parseInt(data.loaded / data.total * 100, 10)
      me.onProgress(progress)
    }).bind('fileuploadadd', function (e, data) {
      me.onAdd(data)
    })

    this.$input = $input
  };

  shouldComponentUpdate (nextProps, nextState) {
    return false
  }

  componentWillReceiveProps (newProps) {
    if (newProps.paramName && newProps.paramName !== this.props.paramName)
      this.resetField(newProps)
  }

  onProgress = p => {
    this.props.onProgress(p)
  };

  onStart = () => {
    this.props.onStart()
  };

  onAdd = data => {
    this.props.onAdd(data.files)

    this.fileuploaddata = data
  };

  onFinish = file => {
    if (file.error)
      this.props.onError(file.error)
    else
      this.props.onFinish(file)
  };

  onError = msg => {
    this.props.onError(msg)
  };

  start = () => {
    this.fileuploaddata.submit()
  };
}

export default FileInput
