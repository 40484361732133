import React from 'react'

import FormInput from './FormInput.react'
import DisplayField from './DisplayField.react'
import classNames from 'classnames'

class FormField extends React.Component {
  render () {
    const {
      name,
      label,
      type,
      errorText,
      value,
      className,
      fieldElement,
      helpElement,
      display,
      attachToForm,
      ...otherProps
    } = this.props

    var classes = classNames({
      'form-field': true,
      valid: errorText,
      ['field-' + name]: true
    }, className)

    const labelElement = label ? <label htmlFor=''>{label}</label> : null
    const errorElement = errorText ? <span className='error-text'>{errorText}</span> : null

    const FieldElement = fieldElement || (display ? DisplayField : FormInput)

    return (
      <div className={classes} key={name}>
        {labelElement}
        <div className='field-element'>
          <FieldElement {...otherProps} key='field' name={name} type={type} value={value} onChange={this.onChange}/>
          {helpElement}
        </div>
        {errorElement}
      </div>
    )
  }

  onChange = e => {
    var value = e.target ? e.target.value : e
    this.props.onChange && this.props.onChange(value)
  };

  componentDidMount () {
    this.props.attachToForm && this.props.attachToForm(this)
  }
}

export default FormField
