/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import isEqual from 'lodash/lang/isEqual'
import set from 'lodash/object/set'

import FontIcon from 'decor/FontIcon'
import Button from 'decor/Button'
import Dialog from 'decor/Dialog'
import { Label } from 'decor/form'

import LoadingButton from 'common/LoadingButton'

import BillingInfo from 'billing/components/BillingInfo'

const INITIALSTATE = {
  tld: null,
  duration: '',
  chosenDuration: null,
  error: {},
  tried: false,
  purchasing: false,
  done: false,
  orderId: null,
  order: null
}

class DomainRenewalDialog extends Component {
  static defaultProps = {
    products: {}
  };

  state = INITIALSTATE;

  componentWillReceiveProps ({ open, domain }) {
    if (open !== this.props.open || !isEqual(domain, this.props.domain))
      this.reset()
  }

  reset () {
    this.setState(INITIALSTATE)
  }

  changeDuration = e => {
    const duration = e.target.value
    const durationError = duration >= 1 ? null : 'Duration is invalid. Please specify number of years to renew.'

    this.setState(prevState => ({
      duration,
      error: {
        ...prevState.error,
        duration: durationError
      }
    }))
  }

  chooseDuration = () => this.setState({ chosenDuration: this.state.duration });

  onRequestClose = () => {
    if (this.state.purchasing)
      return

    this.props.onRequestClose(this.state.done)
  };

  purchase = () => {
    this.setState({ tried: true, purchasing: true })

    const { duration } = this.state
    const domain = this.props.domain.id

    let orderProm = null
    if (this.state.order)
      orderProm = Promise.resolve(this.state.order)
    else
      orderProm = this.props.createOrder(domain, [], {}, duration)

    orderProm
      .then(order => {
        this.setState({ order })
        return this.props.payOrder(order.id)
          .then(res => {
            this.setState({ done: true, purchasing: false })
          })
          .catch(paymentError => this.setState({ error: { card: paymentError } }))
          .then(() => this.setState({ purchasing: false }))
      })
      .catch(e => {
        if (e.type == 'invalid_request_error') {
          const { param } = e
          const error = set({}, param, true)
          this.setState({ error })
        }
      })
      .then(() => this.setState({ purchasing: false }))
  };

  render () {
    const { domain, hasBillingInfo, products, ...rest } = this.props
    const { duration, chosenDuration, error, done, purchasing } = this.state

    const tld = domain && domain.id.split('.').reverse().shift()
    const cost = tld && products[tld]
    const totalPrice = parseFloat(cost, 10) * duration
    const service = duration === 1 ? '1 year' : `${duration} years`

    const toolbar = []
    let title = 'Renew Domain'

    if (!chosenDuration) { toolbar.push(<Button onClick={this.chooseDuration} disabled={!duration || error.duration} appearance='secondary'>Next</Button>) } else if (!done) {
      title = 'Completing Renewal Purchase'
      toolbar.push(<LoadingButton onClick={this.purchase} disabled={!hasBillingInfo} loadingText='Purchasing...' loading={this.state.purchasing} appearance='secondary'>Purchase</LoadingButton>)
    } else if (done) {
      toolbar.push(<Button onClick={this.props.onRequestClose} appearance='secondary'>Close</Button>)
      title = null
    }

    return (
      <Dialog
        {...rest}
        title={title}
        className='domain-dialog domain-renewal-dialog'
        onRequestClose={this.onRequestClose}
        toolbar={toolbar}
      >
        {!chosenDuration &&
          <div className='duration-section'>
            <Label>Number of Years:</Label>
            <select name='duration' required error={error.duration} className='form-control renewal-years' onChange={this.changeDuration} placeholder='1-9'>
              <option value=''>1-9</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => <option key={v} value={v}>{v}</option>)}
            </select>
          </div>
        }
        {
          (chosenDuration && !error.duration && !purchasing && !done) &&
          <Provider store={this.context.store}>
            <div className='billing-section'>
              <div className='item'>
                <p>{domain.id}</p>
                {service} - ${totalPrice}
              </div>
              <div className='billing-info-wrap'>
                <h3>Secure Billing Info</h3>
                <BillingInfo />
                {(error && error.card) && error.card.message}
              </div>
            </div>
          </Provider>
        }

        {done &&
          <div className='completed-message'>
            <FontIcon className='fa-3x fa fa-thumbs-up' />
            <h3>
              Awesome!
            </h3>
            <p>Your domain is being renewed. You should receive an email which we'll use to confirm your renewal details. </p>
          </div>
        }

      </Dialog>
    )
  }

  static contextTypes = {
    store: PropTypes.any
  };
}

export default DomainRenewalDialog
