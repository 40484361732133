/* eslint "eqeqeq": "warn" */
import React from 'react'
import CMAField from './CMAField'
import CMARangeField from './CMARangeField'
import CMAStringField from './CMAStringField'
import CMAListField from './CMAListField'

class CMAAddCriteria extends React.Component {
  render () {
    var { params, filters } = this.props

    const filterFields = filters.map(filter => {
      if (filter.type === 'string')
        return (<div key={filter.id} className='col-md-4'>
          <CMAStringField name={filter.id} label={filter.label || filter.id} type='text' params={params} onChange={this.onChange}/>
        </div>)
      else if (filter.type === 'number')
        return (<div key={filter.id} className='col-md-4'>
          <CMARangeField name={filter.id} label={filter.label || filter.id} decimals={filter.decimals} type='number' params={params} onChange={this.onChange}/>
        </div>)
      else if (filter.type === 'boolean')
        return (<div key={filter.id} className='col-md-4'>
          <CMAField name={filter.id} label={filter.label || filter.id} params={params} onChange={this.onChange}>
            <select className='form-control'>
              <option>Any</option>
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </CMAField>
        </div>)
      else if (filter.type == 'list')
        return (
          <div key={filter.id} className='col-md-4'>
            <CMAListField name={filter.id} label={filter.label || filter.id} values={filter.values} params={params} onChange={this.onChange}/>
          </div>
        )
      else
        return (<div></div>)
    })

    return (
      <div className='row columns-form cma-criteria'>
        {filterFields}
      </div>
    )
  }

  onChange = (name, value) => {
    this.props.onChange(name, value)
  };
}
export default CMAAddCriteria
