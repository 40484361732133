/* eslint "react/no-string-refs": "warn", "eqeqeq": "warn", "react/no-find-dom-node": "warn" */
import React from 'react'
import ReactDOM from 'react-dom'

import './addressinput.less'

import cn from 'classnames'

import BasicInput from '../internal/BasicInput'
import Field from '../Field'

var stateList = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY']

class AddressInput extends React.Component {
  static defaultProps = {
    value: {
      line1: '',
      city: '',
      state: '',
      zip: ''
    },

    autocomplete: false,
    statePicker: false
  };

  focus = () => {
    this.refs.line1.focus()
  };

  getValue = () => this.props.value || {};

  onFieldChange = e => {
    var v = Object.assign({}, this.getValue())
    var name = e.target.name
    var value = e.target.value
    v[name] = value

    if (this.props.onChange)
      this.props.onChange(v)
  };

  onBlur = e => {
    if (!this.blurring) {
      e.ignoreBlur = true
      if (e.target.name == 'state') {
        var dom = this.refs.state
        $(dom).typeahead('close')
      }
    } else { this.blurring = false }
  };

  onKeyDown = e => {
    if (e.key == 'Tab')
      if (e.shiftKey == true && e.target.name == 'line1')
        this.blurring = true
      else if (e.target.name == 'zip')
        this.blurring = true
  };

  render () {
    const { statePicker } = this.props
    var value = this.getValue()

    return (
      <div className={cn('decor', 'decorAddress', 'address-field')} onBlur={this.onBlur} onKeyDown={this.onKeyDown}>
        <Field>
          <BasicInput type='text' name='line1' ref='line1' value={value.line1} onChange={this.onFieldChange} placeholder='Street Address'/>
        </Field>
        <Field className='decorAddress-line2'>
          <div className='decorAddress-line2-cityct'>
            <BasicInput type='text' name='city' value={value.city} onChange={this.onFieldChange} placeholder='City'/>
          </div>
          <div className='decorAddress-line2-statect'>
            {statePicker
              ? <select name='state' placeholder='State' className='form-control' onChange={this.onFieldChange}>
                <option value=''>State</option>
                {stateList.map(v => <option key={v} value={v}>{v}</option>)}
              </select>
              : <BasicInput type='text' ref='state' name='state' value={value.state} onChange={this.onFieldChange} placeholder='State'/>
            }

          </div>
          <div className='decorAddress-line2-zipct'>
            <BasicInput type='text' name='zip' value={value.zip} onChange={this.onFieldChange} placeholder='Zip'/>
          </div>
        </Field>
      </div>
    )
  }

  componentDidMount () {
    if (this.props.statePicker)
      return

    var states = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // `states` is an array of state names defined in "The Basics"
      local: $.map(stateList, function (state) { return { value: state } })
    })

    // kicks off the loading/processing of `local` and `prefetch`
    states.initialize()

    var dom = ReactDOM.findDOMNode(this.refs.state)
    $(dom).typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    },
    {
      name: 'states',
      displayKey: 'value',
      source: states.ttAdapter()
    })
  }
}

export default AddressInput
