import * as Schema from './schema'

import fetch from 'common/fetch'

import api from '../common/api'
import { CALL_API_LEGACY } from '../../middleware/api'
import throwAny from 'common/util/throwAny'

export const MLS_METADATA_FETCH = 'MLS_METADATA_FETCH'
export const MLS_METADATA_FETCH_SUCCESS = 'MLS_METADATA_FETCH_SUCCESS'
export const MLS_METADATA_FETCH_FAILURE = 'MLS_METADATA_FETCH_FAILURE'

export function fetchMetadata () {
  return (dispatch, getState) => {
    if (getState().mls.metadata.data)
      return

    dispatch({
      [CALL_API_LEGACY]: {
        types: [MLS_METADATA_FETCH, MLS_METADATA_FETCH_SUCCESS, MLS_METADATA_FETCH_FAILURE],
        endpoint: '/mls/metadata'
      }
    })
  }
}

export const SOURCE_REQUIREMENTS = 'mls/SOURCE_REQUIREMENTS'
export const setSourceRequirements = resp => ({ type: SOURCE_REQUIREMENTS, payload: resp.error.message })

export const MLS_ACCEPT_TOS = 'mls/ACCEPT_TOS'
export const MLS_ACCEPT_TOS_SUCCESS = 'mls/ACCEPT_TOS_SUCCESS'
export const MLS_ACCEPT_TOS_FAILURE = 'mls/ACCEPT_TOS_FAILURE'

export function acceptTermsOfService () {
  return dispatch => {
    dispatch({ type: MLS_ACCEPT_TOS })

    return api
      .post('/mls/auth/tos')
      .then(response => {
        if (response.error)
          return dispatch({
            type: MLS_ACCEPT_TOS_FAILURE,
            payload: response,
            error: true
          })

        return dispatch({
          type: MLS_ACCEPT_TOS_SUCCESS,
          payload: response
        })
      })
  }
}

export const MLS_ACCEPT_BROKER_TERMS = 'mls/ACCEPT_BROKER_TERMS'
export const MLS_ACCEPT_BROKER_TERMS_SUCCESS = 'mls/ACCEPT_BROKER_TERMS_SUCCESS'
export const MLS_ACCEPT_BROKER_TERMS_FAILURE = 'mls/ACCEPT_BROKER_TERMS_FAILURE'

export function acceptBrokerTerms (id) {
  const meta = { id }
  return dispatch => {
    dispatch({ type: MLS_ACCEPT_BROKER_TERMS, meta })

    return api
      .post(`/mls/auth/terms/${id}`)
      .then(response => {
        if (response.error)
          return dispatch({
            type: MLS_ACCEPT_BROKER_TERMS_FAILURE,
            payload: response,
            error: true,
            meta
          })

        return dispatch({
          type: MLS_ACCEPT_BROKER_TERMS_SUCCESS,
          payload: response,
          meta
        })
      })
  }
}

export const SIGN_DOCUMENT = 'mls/SIGN_DOCUMENT'
export const SIGN_DOCUMENT_SUCCESS = 'mls/SIGN_DOCUMENT_SUCCESS'
export const SIGN_DOCUMENT_CANCELLED = 'mls/SIGN_DOCUMENT_CANCELLED'
export const SIGN_DOCUMENT_FAILURE = 'mls/SIGN_DOCUMENT_FAILURE'

export const signDocument = documentId => async dispatch => {
  dispatch({ type: SIGN_DOCUMENT, meta: { documentId } })
  try {
    var { id, url } = await fetchSigningRequest(documentId)

    try {
      await openDocument(url)
    } catch (e) {
      return dispatch({ type: SIGN_DOCUMENT_CANCELLED, payload: e, meta: { documentId } })
    }

    var verified = await verifySignature(documentId, id)
    if (verified)
      dispatch({ type: SIGN_DOCUMENT_SUCCESS, meta: { documentId } })
    else
      dispatch({ type: SIGN_DOCUMENT_FAILURE, payload: 'Signature verification failed', meta: { documentId } })
  } catch (e) {
    dispatch({ type: SIGN_DOCUMENT_FAILURE, payload: e, meta: { documentId } })
  }
}

export const fetchSigningRequest = async documentId => {
  const res = await fetch('/mls/documentstart/' + documentId)
  var json = await res.json()
  if (res.status !== 200)
    throwAny(json)

  return json
}

const openDocument = url => new Promise((resolve, reject) => {
  HelloSign.open({
    url: url,
    uxVersion: 2,
    allowCancel: true,
    skipDomainVerification: process.env.NODE_ENV === 'development',
    messageListener: eventData => {
      switch (eventData.event) {
        case HelloSign.EVENT_SIGNED:
          resolve()
          break

        case HelloSign.EVENT_CANCELED:
        case HelloSign.EVENT_DECLINED:
          reject(new Error('Open document failed'))
          break
      }
    }
  })
})

const verifySignature = (templateId, signatureId) => fetch(`/mls/documentconfirm/${templateId}/${signatureId}`)
  .then(res => res.json().then(json => {
    if (res.status !== 200)
      throwAny(json)

    return json.success
  }))

export const AUTH_START = 'mls/AUTH_START'
export const AUTH_CANCELLED = 'mls/AUTH_CANCELLED'

export const startAuth = (source, broker, user) => ({
  meta: { source, broker, user },
  type: AUTH_START
})

export const cancelAuth = (source, broker, user) => ({
  meta: { source, broker, user },
  type: AUTH_CANCELLED
})

export const AUTH_CONNECT = 'mls/AUTH_CONNECT'
export const AUTH_CONNECT_SUCCESS = 'mls/AUTH_CONNECT_SUCCESS'
export const AUTH_CONNECT_FAILURE = 'mls/AUTH_CONNECT_FAILURE'

export const authConnect = (source, broker) => ({
  type: AUTH_CONNECT, meta: { source, broker }
})

export const authConnectSuccess = (source, broker) => ({
  type: AUTH_CONNECT_SUCCESS, meta: { source, broker }
})

export const authConnectFailure = (source, broker, error) => ({
  type: AUTH_CONNECT_FAILURE, payload: error, meta: { source, broker }
})

export const FETCH_MY_BROKERS = 'mls/FETCH_MY_BROKERS'
export const FETCH_MY_BROKERS_SUCCESS = 'mls/FETCH_MY_BROKERS_SUCCESS'
export const FETCH_MY_BROKERS_FAILURE = 'mls/FETCH_MY_BROKERS_FAILURE'

export const fetchMyBrokers = () => ({
  [CALL_API_LEGACY]: {
    endpoint: '/mls/brokers/my',
    types: [FETCH_MY_BROKERS, FETCH_MY_BROKERS_SUCCESS, FETCH_MY_BROKERS_FAILURE],
    schema: Schema.myBrokers
  }
})

export const VIEWED_SOURCE = 'mls/VIEWED_SOURCE'
export const viewedSource = source => dispatch => {
  dispatch({ type: VIEWED_SOURCE, payload: source })

  dispatch({
    [CALL_API_LEGACY]: {
      endpoint: '/mls/lastsource',
      method: 'POST',
      data: { source }
    }
  })
}
