import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Dialog, Box, Paragraph, Text, Tooltip, Button } from '@realsoftworks/decor'
import LoadingButton from 'common/LoadingButton'
import BigLoader from 'common/BigLoader'
import BillingInfo from 'billing/components/BillingInfo'
import { selectServices, selectAvailable, selectPrice, selectMinimum } from 'marketing/selectors'
import { fetchBalance } from 'marketing/actions'
import { hasBillingInfo } from 'billing/selectors'
import { fetchSkippableCounts, submitSkiptraceOrder, pollSkiptraceOrder, clearSkippableCount, fetchSkippedCount, clearSkippedCount } from '../../../actions'
import { selectSkippableCounts, selectSkippedCount } from '../../../selectors'
import SkiptraceProcessingDialog from './SkiptraceProcessingDialog'
import ShoppingCart from './ShoppingCart'
import OrderSummary from './OrderSummary'
import AcceptTerms from './AcceptTerms'
import AvailableBalance from 'marketing/components/AvailableBalance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import isEqual from 'lodash/lang/isEqual'
import SkipGenieOptionsForm from './SkipGenieOptionsForm/SkipGenieOptionsForm'
import { PROVIDER_SKIPGENIE } from 'const'

const ErrorMessage = ({ error, ...props }) => {
  let text = error.message
  if (error.type === 'api_error')
    text = 'There was a problem submitting your request'

  return (
    <Text color='red.500' {...props}>{text}</Text>
  )
}

const OPTION_HINT = 'Choose a skiptrace provider above'

const STEPS = {
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SKIP_GENIE_OPTIONS: 'SKIP_GENIE_OPTIONS',
  CONFIRM: 'CONFIRM'
}

const SkiptraceOrderModal = ({
  list,
  members,
  providers,
  fetchBalance,
  skippableCounts,
  fetchSkippableCounts,
  submitSkiptraceOrder,
  hasBillingInfo,
  pollSkiptraceOrder,
  minimum,
  creditPrice,
  availableBalance,
  skippedCount,
  onClose: propsOnClose,
  clearSkippableCount,
  fetchSkippedCount,
  clearSkippedCount,
  open,
  ...props
}) => {
  const [accepted, setTermsAccepted] = useState(false)
  const [processingOpen, setProcessingOpen] = useState(false)
  const [error, setError] = useState(null)
  const [enteringInfo, setEnteringInfo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [step, setStep] = useState(STEPS.SELECT_PROVIDER)
  const [skipOpts, setSkipOpts] = useState()
  const [providerOpts, setProviderOpts] = useState({ skipGenie: { searchType: 'narrow' } })
  const isSkipOptsSet = skipOpts !== null && skipOpts !== undefined
  const closeProcessing = useCallback(() => setProcessingOpen(false))
  const onBeforeClose = useCallback(() => !loading, [loading])
  const isFormComplete = !!(selectedProvider && isSkipOptsSet)

  useEffect(() => {
    if (!isFormComplete) return

    const reskip = isSkipOptsSet ? skipOpts : undefined

    setTermsAccepted(false)
    setReady(false)
    fetchSkippableCounts(list.id, { provider: selectedProvider, members, reskip })
  }, [isFormComplete, selectedProvider, skipOpts])

  const onClose = () => {
    propsOnClose()
    setEnteringInfo(false)
    setSelectedProvider(null)
    setSkipOpts(undefined)
    setStep(STEPS.SELECT_PROVIDER)
    clearSkippableCount(list.id)
    clearSkippedCount(list.id)
    setProviderOpts(null)
  }

  const selectProvider = option => {
    setSelectedProvider(option)
    // If none is skipped yet (i.e. nothing to reskip), user doesn't need to specify reskip options
    if (skippedCount === 0) setSkipOpts(false)
  }

  const submitOrder = () => {
    if (!hasBillingInfo && isBalanceInsufficient) { setEnteringInfo(true) } else {
      const providerOptions = providerOpts?.[selectedProvider]
        ? providerOpts
        : undefined

      setError(null)
      setLoading(true)
      submitSkiptraceOrder(
        list.id,
        {
          provider: selectedProvider,
          members,
          reskip: skipOpts || undefined,
          providerOptions
        }
      )
        .then(resp => {
          setLoading(false)
          if (resp.error) { setError(resp.error) } else {
            setTimeout(() => setProcessingOpen(true), 1000)
            pollSkiptraceOrder(list.id)
            onClose()
            fetchBalance()
          }
        })
    }
  }

  const onTermsAcceptChange = useCallback((_e, v) => setTermsAccepted(v), [])

  // Prefetch balance on mount even if the modal is not open yet
  useEffect(() => {
    fetchBalance()
  }, [])

  // Update balance and skipped count every time the modal opens
  useEffect(() => {
    if (!open) return
    fetchBalance()
    fetchSkippedCount(list.id, { members })
  }, [open])

  if (skippableCounts[selectedProvider] && skippableCounts[selectedProvider].count && !ready)
    setReady(true)

  const totalCount = members?.length || list.memberStats.total
  let skippableCount = null
  if (skippableCounts[selectedProvider] && isEqual(skippableCounts[selectedProvider].members?.sort(), members.sort()))
    skippableCount = skippableCounts[selectedProvider].count

  let hint = null
  if (!selectedProvider)
    hint = OPTION_HINT
  else if (skippableCount && !accepted)
    hint = 'Please agree to the terms above'

  const isBalanceInsufficient = selectedProvider &&
    providers[selectedProvider] &&
    availableBalance < providers[selectedProvider]

  const toolbar = step === STEPS.SELECT_PROVIDER ? (
    <>
      {!isFormComplete && <Text color='secondary'>Please select</Text>}
      {error && <ErrorMessage error={error} />}
      <Box ml={3}>
        <Button
          variant='primary'
          disabled={!isFormComplete || !skippableCount}
          onClick={() =>
            setStep(selectedProvider === PROVIDER_SKIPGENIE
              ? STEPS.SKIP_GENIE_OPTIONS
              : STEPS.CONFIRM)}
        >
          Next
        </Button>
      </Box>
    </>
  ) : step === STEPS.SKIP_GENIE_OPTIONS ? (
    <>
      {!providerOpts?.skipGenie?.searchType && (
        <Text color='secondary'>Please select</Text>
      )}
      {error && <ErrorMessage error={error} />}
      <Button ml={3} variant='secondary' onClick={() => { setStep(STEPS.SELECT_PROVIDER); setEnteringInfo(false) }}>
        Back
      </Button>
      <Box ml={3}>
        <Button
          variant='primary'
          disabled={!providerOpts?.skipGenie?.searchType}
          onClick={() => setStep(STEPS.CONFIRM)}
        >
          Next
        </Button>
      </Box>
    </>
  ) : step === STEPS.CONFIRM ? (
    <>
      {(hint && providers) && <Text color='secondary'>{hint}</Text>}
      {error && <ErrorMessage error={error} />}
      <Button
        ml={3}
        variant='secondary'
        onClick={() => {
          setStep(selectedProvider === PROVIDER_SKIPGENIE
            ? enteringInfo ? STEPS.CONFIRM : STEPS.SKIP_GENIE_OPTIONS
            : STEPS.SELECT_PROVIDER); setEnteringInfo(false)
        }}
      >
        Back
      </Button>
      <Box ml={3}>
        <LoadingButton
          variant='primary'
          disabled={!ready || (!hasBillingInfo && enteringInfo) || !accepted}
          onClick={submitOrder}
          loading={loading}
          loadingText='Submitting...'
        >
          Submit Order
        </LoadingButton>
      </Box>
    </>
  ) : null

  return (
    <>
      <SkiptraceProcessingDialog
        open={processingOpen}
        onClose={closeProcessing}
      />
      <Dialog
        title='Skiptrace Order'
        scroll='body'
        toolbar={toolbar}
        onBeforeClose={onBeforeClose}
        onClose={onClose}
        open={open}
        {...props}
      >
        <BigLoader loading={!providers}>
          {
            !isBalanceInsufficient || !enteringInfo
              ? <>

                {step !== STEPS.SKIP_GENIE_OPTIONS && (
                  <ShoppingCart
                    mb={5}
                    provider={selectedProvider}
                    shouldForceHideOptions={step !== STEPS.SELECT_PROVIDER}
                    shouldForceHideProviders={step !== STEPS.SELECT_PROVIDER}
                    onProviderChange={selectProvider}
                    totalCount={totalCount}
                    skippableCount={skippableCount}
                    skippedCount={skippedCount}
                    skipOption={skipOpts}
                    onSkipOptionChange={setSkipOpts}
                  />
                )}

                {step === STEPS.CONFIRM && skippableCount > 0 ? (
                  <>
                    {availableBalance > 0 &&
                      <Box display='flex' bg='neutral.100' py={4} px={5} my={5} mx={-5}>
                        <Tooltip
                          content={
                            <>
                            Your Propelio Wallet balance.<br />
                            Any unused credit will be returned here and you can add to your balance in your billing settings.
                            </>
                          }
                        >
                          <Text fontSize={3}>Current Balance: <Text color='neutral.300'><FontAwesomeIcon icon={faQuestionCircle} /></Text></Text>
                        </Tooltip>
                        <Box ml='auto'>
                          <Text fontSize={3} textAlign='right'>$<AvailableBalance /></Text>
                        </Box>
                      </Box>}

                    <OrderSummary
                      mt={5}
                      count={skippableCount}
                      servicePrice={providers[selectedProvider]}
                      creditPrice={creditPrice}
                      availableCredits={availableBalance}
                      minimum={minimum}
                    />

                    <AcceptTerms
                      mt={5}
                      checked={accepted}
                      onChange={onTermsAcceptChange}
                      count={skippableCount}
                      servicePrice={providers[selectedProvider]}
                      creditPrice={creditPrice}
                      availableCredits={availableBalance}
                      minimum={minimum}
                    />
                  </>
                ) : null}

                {step === STEPS.SKIP_GENIE_OPTIONS && (
                  <SkipGenieOptionsForm
                    value={providerOpts?.skipGenie}
                    onChange={v => setProviderOpts({ skipGenie: v }) }
                  />
                )}

              </>
              : <>
                <Paragraph>Enter your billing info to continue with your order.</Paragraph>
                <BillingInfo />
              </>
          }
        </BigLoader>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const services = selectServices(state)
  return ({
    minimum: selectMinimum(state),
    providers: services ? services.skiptrace : null,
    skippableCounts: selectSkippableCounts(state, ownProps.list.id) || {},
    skippedCount: selectSkippedCount(state, {
      id: ownProps.list.id,
      selectedCount: ownProps.members?.length || 0
    }),
    hasBillingInfo: hasBillingInfo(state),
    creditPrice: selectPrice(state),
    availableBalance: selectAvailable(state)
  })
}

const mapDispatchToProps = {
  fetchBalance,
  fetchSkippableCounts,
  fetchSkippedCount,
  submitSkiptraceOrder,
  pollSkiptraceOrder,
  clearSkippableCount,
  clearSkippedCount
}

export default connect(mapStateToProps, mapDispatchToProps)(SkiptraceOrderModal)
