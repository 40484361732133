/* eslint "eqeqeq": "warn", "react/no-string-refs": "warn" */

import React from 'react'
import classnames from 'classnames'
import pick from 'lodash/object/pick'
import FormInput from '../../../../views/form/FormInput.react'

class TaskForm extends React.Component {
  static defaultProps = {
    collapsed: true
  };

  getEmptyState = () => {
    var vals = pick(this.props, 'due_date', 'title', 'description', 'owner', 'reference')

    return {
      due_date: vals.due_date || '',
      title: vals.title || '',
      owner: vals.owner || '',
      description: vals.description || '',
      reference: vals.reference || null,
      contact: vals.contact || null
    }
  };

  onChange = (field, value) => {
    if (value && value.target)
      value = value.target.value

    var state = {}
    state[field] = value
    this.setState(state)
  };

  onFocus = () => {

  };

  clear = () => {
    this.setState(this.getEmptyState())
  };

  onSubmit = e => {
    e.preventDefault()
    var values = this.state
    this.props.onSubmit(values)
    this.clear()
  };

  state = this.getEmptyState();

  render () {
    var bodyClasses = classnames({
      'form-body': true,
      collapse: this.props.collapsed && this.state.title == ''
    })

    return (
      <form onSubmit={this.onSubmit} className='clearfix task-form'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-md-8'>
              <FormInput type='text' name='title' autoComplete='off' ref='title' className='form-control input' placeholder='What needs doing?' onChange={this.onChange.bind(this, 'title')} value={this.state.title}/>
            </div>
            <div className='col-md-4'>
              <FormInput
                type='picker'
                name='contact'
                placeholder='Add Contact'
                model='contact'
                onChange={this.onChange.bind(this, 'contact')}
                value={this.state.contact}
                limit='1'/>
            </div>
          </div>
        </div>
        <div ref='body' className={bodyClasses}>
          <div className='form-group'>
            <div className='row'>
              <div className='col-md-3'>
                <FormInput type='date' smart='true' name='due_date' ref='datefield' value={this.state.due_date} onChange={this.onChange.bind(this, 'due_date')}/>
              </div>
              <div className='col-md-5'>
                <FormInput
                  type='picker'
                  name='owner_id'
                  placeholder='Who should do it?'
                  model='task'
                  action='possibleowners'
                  displayKey='name'
                  limit='1'
                  value={this.state.owner}
                  onChange={this.onChange.bind(this, 'owner')}
                />
              </div>
              <div className='col-md-4'>
                {!this.props.reference
                  ? <FormInput
                    type='picker'
                    name='reference_id'
                    placeholder='Attach to Lead'
                    model='task'
                    action='references'
                    displayKey='name'
                    limit='1'
                    value={this.state.reference}
                    onChange={this.onChange.bind(this, 'reference')}
                  />
                  : <div className={'task-reference task-reference-' + this.props.reference.type}>{this.props.reference.name}</div>}
              </div>
            </div>

          </div>
          {
          /* <div className="form-group">
            <FormInput type="text" multiline={true} name="description" className="form-control input" value={this.state.description} placeholder="How should it be done? Anything else you want to add?" onChange={this.onChange.bind(this,"description")}/>
          </div> */
          }

          <div className='form-group'>
            <button className='btn btn-primary pull-right' type='submit'>Save</button>
          </div>
        </div>
      </form>
    )
  }
}

export default TaskForm
