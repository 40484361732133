import React from 'react'
import { Dialog, Button } from '@realsoftworks/decor'
import LoadingButton from 'common/LoadingButton'
const DeleteAlert = ({ open, processing, onClose, onDelete, ...rest }) =>
  <Dialog
    open={open}
    toolbar={[
      <Button disabled={processing} key='cancel' onClick={onClose} mr={2}>Cancel</Button>,
      <LoadingButton loading={processing} text='Delete' className='btn-danger' loadingText='Deleting...' key='delete' onClick={onDelete} />
    ]}
    {...rest}
  >
    <p className='text-danger'>Are you sure you want to delete this?</p>
  </Dialog>

export default DeleteAlert
