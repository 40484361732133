import React, { useCallback } from 'react'
import { IconButton, Text, Box } from '@realsoftworks/decor'
import FontIcon from 'common/FontIcon'

const ListItem = ({ value, label, icon, onClick }) => {
  const memoClick = useCallback(() => onClick(value), [value, onClick])
  return (
    <Box display='flex' alignItems='center'>
      <Text as='div' flex='1'>{label}</Text>
      {
        icon &&
          <IconButton onClick={memoClick}>
            <FontIcon icon={icon} />
          </IconButton>
      }
    </Box>
  )
}

export default React.memo(ListItem)
