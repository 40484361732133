/* eslint "react/no-string-refs": "warn" */
import React from 'react'
import PickerField from '../../../views/form/PickerField.react'
import DisplayField from '../../../views/form/DisplayField.react'

class LeadOwners extends React.Component {
  static defaultProps = {
    value: []
  };

  state = {
    open: false,
    pickerValue: []
  };

  render () {
    var body = null

    if (this.state.open)
      body = (
        <div className='picker-wrap' key='wrap'>
          <PickerField
            placeholder='Search Users'
            model='lead'
            action='possibleowners'
            displayKey='name'
            argName='name'
            value={this.state.pickerValue}
            onChange={this.onPickerChange}
            onBlur={this.close}
            ref='picker'
          />
        </div>
      )
    else if (this.props.editable !== false)
      body = (
        <button type='button' key='button' className='btn btn-default btn-add' onClick={this.onButtonClick}>Change</button>
      )

    let list = []

    if (this.props.value) {
      var values = this.props.value

      list = values.map(function (v, i) {
        return (<OwnerCard key={v.id} user={v}/>)
      }, this)
    }

    return (
      <div className='user-picker lead-owners'>
        {list.length > 0
          ? <div className='user-picker-list lead-owners-list'>
            {list}
          </div>
          : <DisplayField value='No owners' editable={false}/>}
        {body}
      </div>
    )
  }

  onButtonClick = e => {
    this.open()
  };

  onPickerChange = v => {
    this.setState({ pickerValue: v })
  };

  open = () => {
    this.setState({ open: true, pickerValue: this.props.value || [] })
  };

  close = () => {
    this.setState({ open: false })
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState.open && !this.state.open) // did close
      this.props.onChange([].concat(this.state.pickerValue))
    else if (this.state.open && !prevState.open) // did open
      this.refs.picker.focus()
  }
}

class OwnerCard extends React.Component {
  render () {
    var u = this.props.user
    return (
      <div className='user-picker-item owner-card'>
        {u.name}
      </div>
    )
  }
}

export default LeadOwners
