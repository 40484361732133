/* eslint "brace-style": "warn" */
import { getContact, getContactsObj } from '../contacts/selectors.js'
import cloneDeep from 'lodash/lang/cloneDeep'
import get from 'lodash/object/get'
import { createSelector } from 'reselect'

export const getIsLoaded = state => {
  if (state.leads && state.leads.ui)
    return state.leads.ui.isLoaded ? state.leads.ui.isLoaded : false

  return false
}

export const getSortOrder = state => {
  const defaultSortOrder = 'next_follow_up-asc'
  if (state.leads && state.leads.ui)
    return state.leads.ui.sortOrder || defaultSortOrder

  return defaultSortOrder
}

export const getStateFilter = state => {
  const defaultStateFilter = 'active'
  if (state.leads && state.leads.ui)
    return state.leads.ui.stateFilter || defaultStateFilter

  return defaultStateFilter
}

export const getLeadsObj = state => state?.leads?.leads
const getOrderingIds = state =>
  state &&
  state.leads &&
  state.leads.ordering &&
  state.leads.ordering.ids

export const createGetLeadsBySortOrder = ({ includeContacts } = {}) => createSelector(
  getLeadsObj,
  getOrderingIds,
  getContactsObj,
  (leadsObj, orderingIds, contactsObj) => {
    if (!orderingIds) return null

    const leads = orderingIds.map(leadId => leadsObj[leadId])

    if (!includeContacts || !contactsObj) return leads

    const leadsWithContacts = leads.map(l => {
      const mappedContacts = get(l, 'values.contacts', [])
        .map(id => contactsObj[id])
        .filter(c => c)

      const leadWithMappedContacts = {
        ...l,
        values: {
          ...get(l, 'values', {}),
          contacts: mappedContacts
        }
      }

      return leadWithMappedContacts
    })

    return leadsWithContacts
  }
)

export const getLeadsBySortOrder = createGetLeadsBySortOrder()

export const getLeadsPagingParams = state => {
  if (state.leads.ordering) {
    const { offset, limit, count, length } = state.leads.ordering
    return { offset, limit, count, length }
  }
  return null
}

export const getLead = (state, id) => {
  let lead = null
  if (state.leads && state.leads.leads)
    lead = state.leads.leads[id] ? state.leads.leads[id] : null

  return lead
}

export const getEntityTemplate = (state, type) => {
  let template = {}
  if (state.leads && state.leads.entityTemplates) {
    template = state.leads.entityTemplates[type] ? cloneDeep(state.leads.entityTemplates[type]) : {}
    if (template.fields)
      template.fields = template.fields.map(field => {
        const fieldObj = state.leads.entityFields ? state.leads.entityFields[field] : {}
        return cloneDeep(fieldObj)
      })
  }
  return template
}

export const getContactsByLead = (state, leadId) => {
  const lead = getLead(state, leadId)
  if (lead && lead.values) {
    const contactsMeta = lead.values.leadcontactmeta ? lead.values.leadcontactmeta : {}
    const contactIds = (lead.values.contacts && lead.values.contacts.length > 0) ? lead.values.contacts : []
    const contactsByLead = contactIds.map(contactId => {
      const contact = getContact(state, contactId)
      if (contact && contact.id) {
        const meta = contactsMeta[contact.id]
        return { contact: contact, meta: meta }
      } else {
        return null
      }
    })
    return contactsByLead
  }
  return []
}

export const getTags = state => {
  const tags = []
  if (state.leads && state.leads.tags)
    return tags
}

export const getUsers = (state, userIds) => {
  if (state.leads && state.leads.users && userIds && userIds.length > 0) {
    const users = userIds.map(userId => state.leads.users[userId] ? state.leads.users[userId] : {})
    return users
  }
  return []
}

export const getLeadOwners = (state, leadId) => {
  if (state.leads && state.leads.users && leadId) {
    const lead = getLead(state, leadId)
    const userIds = lead && lead.values ? lead.values.leadowner : []
    return getUsers(state, userIds)
  }
  return []
}

export const getIsSavingLead = state => {
  if (state.leads && state.leads.ui)
    return state.leads.ui.isSavingLead ? state.leads.ui.isSavingLead : false

  return false
}

export const getProperty = state => {
  if (state.leads && state.leads.properties)
    return state.leads.properties.property ? cloneDeep(state.leads.properties.property) : null

  return null
}
