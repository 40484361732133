import React from 'react'
import styled from 'styled-components'
import { Link as RRLink, withRouter } from 'react-router-dom'
import flow from 'lodash/function/flow'
import withLists from 'drivingfordollars/utils/withLists'
import FetchHandler from 'common/FetchHandler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import {
  Box,
  Card,
  Text,
  Button
} from '@realsoftworks/decor'

const ITEM_LIMIT = 50

const ListsListSection = ({ lists, isLoading, error, match, ...props }) =>
  (
    <Card variant='basic' {...props}>
      <FetchHandler
        isLoading={isLoading}
        error={error}
        isEmpty={lists && lists.length === 0}
        emptyText='You don’t have any lists. Use the mobile app to create one.'
      >
        <>
          <Container m={0} p={0} as='ul'>
            {lists && lists.map((list, i) => (
              <Card
                key={list.id}
                as='li'
                display='flex'
                alignItems='center'
                borderTop={i !== 0 && '1px solid'}
                borderColor='neutral.200'
                pl={4}
                pr={5}
                py={4}
              >
                <Text flex='1' fontSize={2}>
                  {list.name}
                </Text>

                <Text fontSize={1}>
                  {list.memberStats.sources.drive}
                  {' '}<Text color='secondary'>leads</Text>
                </Text>

                <Button ml={6} as={RRLink} to={`${match.url}/lists/${list.id}`}>
                  View Leads <FontAwesomeIcon icon={faChevronRight} />
                </Button>

              </Card>
            ))}
          </Container>
          <Box display='flex' justifyContent='center' mt={6} pb={5}>
            <Button to={`${match.url}/lists`} variant='secondary' sizeVariant='large' as={RRLink}>See All Leads</Button>
          </Box>
        </>
      </FetchHandler>
    </Card>
  )

export default flow(
  withLists({ params: { limit: ITEM_LIMIT } }),
  withRouter
)(ListsListSection)

const Container = styled(Box)`
  list-style-type: none;
`
