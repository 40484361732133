
export function isComplete (address) {
  return address && address.line1 && address.city && address.state && address.zip
}

export function isGeoComplete (address) {
  return address && address.lat && address.lon
}

export function isStreetviewComplete (address) {
  return address && address.streetview_lat && address.streetview_lon
}

export function toKey (address) {
  if (!address)
    return ''

  var parts = [];
  ['line1', 'city', 'state', 'zip'].map(p => {
    if (address[p])
      parts.push(address[p])
  })

  return parts.join('--')
}

export function toSingleLine (v) {
  if (!v)
    return ''

  var commad = []
  v.line1 && commad.push(v.line1)
  v.city && commad.push(v.city)
  v.state && commad.push(v.state)

  var spaced = [commad.join(', ')]
  v.zip && spaced.push(v.zip)
  return spaced.join(' ')
}
