import logError from 'common/logError'
import { useEffect } from 'react'

export const useLogError = (
  { error, shouldLog }: { error: string, shouldLog: boolean },
  deps: unknown[]
): void => {
  useEffect(() => {
    if (shouldLog) logError(error)
  }, [...deps, shouldLog])
}
