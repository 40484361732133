import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Link, Route, Switch } from 'react-router-dom'

import { LegacyPageLayout, DefaultLayout } from 'common/layouts'
import { Title } from 'common/Page'
import Breadcrumbs, { Crumb } from '../../../views/common/Breadcrumbs'

import DealAlertIndexHandler from './DealAlertIndexHandler'
import DealAlertDetailHandler from './DealAlertDetailHandler'
import DealAlertAddHandler from './DealAlertAddHandler'

/**
 * Main deal alerts route handler. Displays the header with tools.
 * The actually content is rendered by a child component and is gated by 1) Pro Plan and 2) MLS Terms.
 */

class DealAlertsHandler extends Component {
  render () {
    const { match } = this.props
    return (

      <LegacyPageLayout
        className='deal-alerts'
        title={
          <Title>
            <Breadcrumbs />
          </Title>
        }
        content={
          <DocumentTitle title='Deal Alerts'>
            <DefaultLayout className='deal-alerts-body'>
              <Crumb
                title='Deal Alerts'
                link={<Link to='/dealalerts' />}
              />
              <Switch>
                <Route path={`${match.path}/`} exact component={DealAlertIndexHandler} />
                <Route path={`${match.path}/add`} component={DealAlertAddHandler} />
                <Route path={`${match.path}/:id`} component={DealAlertDetailHandler} />
              </Switch>
            </DefaultLayout>
          </DocumentTitle>
        }
      />
    )
  }
}

DealAlertsHandler.defaultProps = {
  showTools: true
}

export default DealAlertsHandler
