'use strict'

import moment from 'moment'

var DateHelper = {

  formats: {
    SERVER_DATE: 'YYYY-MM-DD',
    SERVER_DATETIME: 'YYYY-MM-DD HH:mm:ss',
    UI_DATE: 'MM/DD/YYYY',
    SERVER_TIME: 'HH:mm:ss',
    UI_TIME: 'hh:mm A',
    UI_DATETIME: 'MM/DD/YYYY hh:mm A'
  },

  parse: function (string, format) {
    return moment(string, format).toDate()
  },

  format: function (date, format) {
    return moment(date).format(format || DateHelper.formats.UI_DATE)
  },

  timeAgo: function (date) {
    if (!DateHelper.isDate(date))
      date = DateHelper.parse(date, DateHelper.formats.SERVER_DATETIME)

    return moment(date).fromNow()
  },

  calendarDays (date, daysOnly) {
    if (!DateHelper.isDate(date))
      date = DateHelper.parse(date, DateHelper.formats.SERVER_DATETIME)

    var dt = moment(date)
    var sod = moment().startOf('day')
    var diff = dt.diff(sod, 'days', true)
    var ret = diff < -6 ? DateHelper.timeAgo(date)
      : diff < -1 ? 'Last ' + dt.format('dddd')
        : diff < 0 ? 'Yesterday'
          : diff < 1 ? 'Today'
            : diff < 2 ? 'Tomorrow'
              : diff < 7 ? 'Next ' + dt.format('dddd') : DateHelper.timeAgo(date)

    return ret
  },

  isDate: function (value) {
    return value instanceof Date
  },

  isBefore: function (date, otherdate) {
    return moment(date).isBefore(moment(otherdate))
  },

  isBeforeNow: function (date) {
    return DateHelper.isBefore(date, moment())
  },

  isBeforeToday: function (date) {
    return DateHelper.isBefore(date, moment().startOf('day'))
  },

  getMoment: function (str) {
    return moment(str, DateHelper.formats.SERVER_DATE)
  },

  formatDateStr: function (str) {
    return moment(str, DateHelper.formats.SERVER_DATE).format(DateHelper.formats.UI_DATE)
  },

  formatTimeStr: function (str) {
    return moment(str, DateHelper.formats.SERVER_TIME).format(DateHelper.formats.UI_TIME)
  },

  convertToServerTime: function (str) {
    return moment(str, DateHelper.formats.UI_TIME).format(DateHelper.formats.SERVER_TIME)
  }
}

export default DateHelper
