/* eslint "react/no-unescaped-entities": "warn", "camelcase": "warn", "eqeqeq": "warn", "react/no-string-refs": "warn" */
import React, { Component } from 'react'

import DealAlertCMA from './DealAlertCMA'
import DealAlertDisclosure from './DealAlertDisclosure'

import CompModal from '../../../../views/leads/cma/CompModal.react'
import MLSLogo from 'mls/components/Logo'
import { Disclosure as CMADisclosure } from '../../../../modules/mls/components'
import ListingSlideshow from '../../../../views/leads/cma/ListingSlideshow'
import { toK } from 'common/util/number'
import { Form, FormField } from '../../../../views/form'

import { FontIcon, Tooltip } from '../../../../views/common'

const getBedRenderer = bedsdef => {
  if (bedsdef)
    return v => `${v[bedsdef.above]}+${v[bedsdef.below]}`

  return v => v.beds
}

class DealAlertListingDetail extends Component {
  constructor (props) {
    super(props)

    this.onListingClick = this.onListingClick.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.state = {
      listingDetail: null
    }
  }

  render () {
    const { deal, metadata } = this.props
    const { valuation, listing } = deal

    const estimatedEquityDisplay = toK(valuation.equity_dollars) + ' (' + (valuation.equity_percent * 100).toFixed(1) + '%)'
    const bedsBathsGarDisplay = formatFacts(listing, metadata.fields)
    const dom = listing.calculated_dom || listing.dom

    const agent = listing.listing_agent || getDeprecatedAgent(listing)

    var tooltip = (
      <div style={{ width: 200, whiteSpace: 'normal' }}>
        Subject Sqft multiplied by price of highest comparable sales.
        <br />
        This is an estimated potential sales price and not an accurate prediction of a property's value.
      </div>
    )
    var helpIcon = <HelpIcon tooltip={tooltip} />

    return (
      <div className='deal-alert-detail'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='listing-photos' style={{ border: '1px solid #ddd', padding: 20 }}>
              <ListingSlideshow listing={listing} />
            </div>
          </div>
          <div className='col-md-8'>

            <div className='row'>
              <div className='col-md-6'>
                <div className='property-facts'>

                  <div className='section'>
                    <h2>Financials</h2>
                    <Form initialValues={listing} defaultProps={{ display: true }}>
                      <FormField type='monetary' name='estimated_value' value={valuation.estimated_value} label='Estimated Value' helpElement={helpIcon} />
                      <FormField type='monetary' name='list_price' label='List Price' />
                      <FormField type='text' label='Estimated Equity' value={estimatedEquityDisplay} />
                    </Form>
                  </div>

                  <div className='section'>
                    <h2>Property Facts</h2>
                    <Form initialValues={listing} defaultProps={{ display: true }}>
                      <FormField type='text' name='mls' label='MLS' />
                      <FormField type='address' name='address' label='Address' />
                      <FormField type='text' name='address_subdivision' label='Subdivision' />
                      <FormField type='text' label='Beds / Baths / Garage' value={bedsBathsGarDisplay} />
                      <FormField type='number' name='sqft' label='Sqft' />
                      <FormField type='text' name='year_built' label='Year Built' />
                      <FormField type='text' name='lot_size' label='Lot Size' value={listing.lot_size_acres ? formatLotSize(listing) : '--'} />
                    </Form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <h2>Listing Info</h2>
                <Form initialValues={listing} defaultProps={{ display: true }}>
                  <FormField type='text' name='calculated_dom' label='Days on Market' value={dom} />
                </Form>
                <div className='form-group'>
                  <blockquote>
                    <p>{listing.remarks}</p>
                  </blockquote>
                </div>

                <div className='listing-office'>
                  <h2>Listing Office</h2>
                  <div className='form'>
                    <div className='form-field'>
                      <label>Agent</label>
                      <div className='field-element'>
                        <div className='display-field'>
                          {blankIfEmpty(agent.name)}<br />Phone: {blankIfEmpty(agent.phone)} <br />Email: {blankIfEmpty(agent.email)}
                        </div>

                        <MLSLogo source={listing.source} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='cma'>
          <DealAlertCMA subject={listing} metadata={metadata} listings={deal.listings} meta={valuation.listings} onListingClick={this.onListingClick} />
        </div>
        <DealAlertDisclosure />
        <CMADisclosure source={listing.source} />

        {this.state.listingDetail
          ? <CompModal
            comp={this.state.listingDetail}
            fields={metadata.fields}
            showStates={false}
            show={this.state.modalShown}
            onRequestClose={this.closeModal}
          />
          : ''}
      </div>
    )
  }

  onListingClick (key) {
    var { listings } = this.props.deal

    var comp = listings.find(el => (el.id === key))

    this.setState({
      listingDetail: comp,
      modalShown: true
    })
  }

  closeModal () {
    this.setState({
      modalShown: false
    })
  }
}

function formatFacts (property, fields) {
  const { baths_full, baths_half, garage } = property
  const baths_formatted = formatBaths(baths_full, baths_half)

  const bedRenderer = getBedRenderer(fields.find(v => v.id == 'property_facts').beds)

  return [blankIfEmpty(bedRenderer(property)), baths_formatted, blankIfEmpty(garage)].join(' / ')
}

function formatBaths (baths_full, baths_half) {
  var half = (baths_half !== null && baths_half !== undefined ? ('.' + baths_half) : '')
  return blankIfEmpty(baths_full) + half
}

function blankIfEmpty (value) {
  return (value !== null && value !== undefined) ? value : '--'
}

const getDeprecatedAgent = listing => ({ name: listing.listing_agent_name, phone: listing.listing_agent_phone, email: listing.listing_agent_email })

export default DealAlertListingDetail
class HelpIcon extends Component {
  constructor (props) {
    super(props)

    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)

    this.state = {
      tooltipShown: false
    }
  }

  showTooltip () {
    if (this.props.tooltip)
      this.setState({ tooltipShown: true })
  }

  hideTooltip () {
    if (this.props.tooltip) this.setState({ tooltipShown: false })
  }

  handleMouseLeave (event) {
    this.hideTooltip()
    if (this.props.onMouseLeave) this.props.onMouseLeave(event)
  }

  handleMouseOut (event) {
    if (this.props.onMouseOut) this.props.onMouseOut(event)
  }

  handleMouseEnter (event) {
    this.showTooltip()
    if (this.props.onMouseEnter) this.props.onMouseEnter(event)
  }

  render () {
    const { tooltip } = this.props

    const tooltipElement = tooltip ? (
      <Tooltip
        ref='tooltip'
        show={this.state.tooltipShown}
        horizontalPosition='center'
      >
        {tooltip}
      </Tooltip>
    ) : null

    return (
      <div
        className='help-icon'
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
        onMouseOut={this.handleMouseOut}
        onKeyboardFocus={this.handleKeyboardFocus}
      >
        <div>
          <FontIcon className='icon icon-help' />
          {tooltipElement}
        </div>
      </div>
    )
  }
}

const formatLotSize = listing => `${listing.lot_size_acres} acres (${listing.lot_size_sqft} sqft)`
