/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { string } from 'prop-types'
import withLists from 'drivingfordollars/utils/withLists'
import createMatchParamsPropTypes from 'drivingfordollars/utils/createMatchParamsPropTypes'
import Container from 'drivingfordollars/components/Container'
import { ListList, ListDetail } from 'lists'

import { Heading, Box } from '@realsoftworks/decor'

const goBack = url => {
  const parts = url.split('/')
  parts.pop()
  return parts.join('/')
}

const ListDetails = ({ lists, match }) => {
  if (lists.length == 0)
    return <Redirect to={goBack(match.url)} />

  return (
    <Container flexDirection='column' mx='auto'>
      <Heading size='5'>Driving for Dollars – Marketing Lists</Heading>
      <Box flex='1' display='flex' mt={4}>
        <Box width={360}>
          <ListList path={match.path} lists={lists} />
        </Box>
        <Box flex='1' pl='7'>

          <Switch>
            <Route path={`${match.path}`} exact render={routeProps => <RedirectToFirstAfterLoad lists={lists} {...routeProps} />} />
            <Route path={`${match.path}/:id`} component={ListDetail} />
          </Switch>
        </Box>

      </Box>
    </Container>

  )
}

const RedirectToFirstAfterLoad = ({ match, lists }) => <Redirect to={`${match.url}/${lists[0].id}`} />

ListDetails.propTypes = createMatchParamsPropTypes({
  id: string
})

export default withLists(null, ListDetails)
