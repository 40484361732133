/* eslint "react/display-name": "warn" */
import React, { forwardRef, useRef, useEffect } from 'react'
import { arrayOf, object, func, bool, string, elementType } from 'prop-types'
import { Card, Button } from '@realsoftworks/decor'
import useOnClickOutside from 'common/util/hooks/useOnClickOutside'
import Attachment from './Attachment'

const AttachmentInput = forwardRef(({
  value: valueFromProps,
  onChange,
  onClick = noop,
  onFocus = noop,
  onBlur = noop,
  allowMultiple = true,
  attachmentInputProps = {},
  label,
  name,
  display: Display = Attachment
}, ref) => {
  const value = valueFromProps || []
  const inputRef = useRef()
  const buttonRef = useRef()

  const clickAreaRef = useOnClickOutside(() => {
    onBlur()
  }, [value])

  useEffect(() => {
    const syntheticDom = {
      focus: () => {
        buttonRef.current.focus()
      }
    }

    if (ref && typeof ref === 'object')
      ref.current = syntheticDom
    else if (typeof ref === 'function')
      ref(syntheticDom)
  }, [])

  return (
    <Card
      ref={clickAreaRef}
      display='flex'
      flexDirection='column'
      alignItems='center'
      p={value.length ? 2 : 0}
      bg={value.length ? 'white' : 'transparent'}
      boxShadow={value.length ? 2 : 0}
    >
      {!!value.length && (
        <Display
          isDeletable
          onDelete={toDelete => {
            const syntheticEvent = { target: { name } }
            onChange(
              syntheticEvent,
              value.map(v => v === toDelete ? { ...v, isDeleted: true } : v)
            )
          }}
          mb={3}
        >
          {value}
        </Display>
      )}

      <input
        hidden
        tabIndex={-1}
        style={{ display: 'none' }}
        ref={inputRef}
        type='file'
        multiple={allowMultiple}
        name={name}
        onChange={ev => {
          const newAttachments = Array.from(ev.target.files)
            .map(createAttachment)
            .map(a => ({ ...a, isNew: true }))

          const existingAttachments = allowMultiple
            ? value
            : value.map(a => ({ ...a, isDeleted: true }))

          onChange(ev, [...existingAttachments, ...newAttachments])
        }}
        {...attachmentInputProps}
      />

      <Button
        ref={buttonRef}
        mb={value.length ? 2 : 0}
        variant='secondary'
        width={200}
        onFocus={onFocus}
        onClick={ev => {
          inputRef.current.value = null
          inputRef.current.click()
          onClick(ev)
        }}
      >
        {label || `Upload Attachment${allowMultiple ? 's' : ''}`}
      </Button>
    </Card>
  )
})

AttachmentInput.propTypes = {
  value: arrayOf(object),
  onChange: func.isRequired,
  onClick: func,
  onFocus: func,
  onBlur: func,
  allowMultiple: bool,
  attachmentInputProps: object,
  label: string,
  name: string,
  display: elementType
}

export default AttachmentInput

const noop = () => {}

const createAttachment = file =>
  ({
    name: file.name,
    mimetype: file.type,
    extension: file.name.split('.')
      .find((v, i, src) => i === src.length - 1),
    size: file.size
  })
