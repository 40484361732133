/* eslint "react/no-deprecated": "warn", "react/no-find-dom-node": "warn", "eqeqeq": "warn" */
'use strict'

import React from 'react'
import ReactDOM from 'react-dom'

import $ from 'jquery'
import classnames from 'classnames'

class ModalPortal extends React.Component {
  state = {
    open: false,
    opening: false,
    closing: false
  };

  componentDidMount () {
    if (this.props.show)
      this.open()
  }

  componentWillUnmount () {
    $(document.body).removeClass('no-scroll')
  }

  componentWillReceiveProps (newProps) {
    if (newProps.show === true && this.props.show === false)
      this.open()
    else if (newProps.show === false && this.props.show === true)
      this.close()
  }

  open = () => {
    this.setState({ open: true, closing: false }, function () {
      /* eslint-disable-next-line no-unused-vars */
      var h = ReactDOM.findDOMNode(this).offsetHeight // force reflow
      var me = this

      me.setState({ opening: true })

      $(document.body).addClass('no-scroll')
    })
  };

  close = () => {
    this.setState({ open: false, closing: true, opening: false }, function () {
      /* eslint-disable-next-line no-unused-vars */
      var h = ReactDOM.findDOMNode(this).offsetHeight // force reflow
      var me = this
      setTimeout(function () {
        me.setState({ closing: false })
      }, 300)
      $(document.body).removeClass('no-scroll')
    })
  };

  requestClose = () => {
    if (this.props.onRequestClose)
      this.props.onRequestClose()
  };

  checkClose = e => {
    if ($(e.target).closest('.modal-dialog').length == 0)
      this.requestClose()
  };

  render () {
    var { open, opening, closing } = this.state
    var classes = classnames({
      modal: true,
      fade: true,
      in: opening
    })
    return (
      <div className='' onClick={this.checkClose}>
        <div className={classes} tabIndex='-1' role='dialog' style={{ display: (open || closing) ? 'block' : 'none' }}>
          {this.props.children}
        </div>
        {(open || closing)
          ? <div className={'modal-backdrop ' + ((opening || open || closing) ? 'fade in' : '')} onClick={this.requestClose}></div>
          : ''
        }

      </div>
    )
  }
}

export default ModalPortal
