/* eslint "no-case-declarations": "warn" */

import * as ActionTypes from '../actions'

export default function (state = null, action) {
  switch (action.type) {
    case ActionTypes.USER_CHECK_EMAIL_CONFIRM_SUCCESS:
      const { payload } = action
      if (payload.email_confirmed !== state.email_confirmed)
        return {
          ...state,
          email_confirmed: payload.email_confirmed
        }

      break
  }

  return state
}
