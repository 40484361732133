/* eslint "eqeqeq": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardTitle } from 'common/Card'
import BigLoader from 'common/BigLoader'
import BigFatalError from 'common/BigFatalError'
import Button from 'decor/Button'
import FontIcon from 'decor/FontIcon'
import Checkbox from 'decor/Checkbox'

import { formatDate } from 'common/util/date'

import './domains.less'

import DomainPurchaseDialog from './DomainPurchaseDialog'
import DomainConsoleDialog from './DomainConsoleDialog'
import DomainForwardingDialog from './DomainForwardingDialog'
import DomainMailDialog from './DomainMailDialog'
import DomainRenewalDialog from './DomainRenewalDialog'

import { Tooltip, Box, Text, Button as DecorButton } from '@realsoftworks/decor'

import { getDomains, getDomainProducts } from '../../selectors'
import { fetchDomains, fetchOrders, fetchDomainProducts, checkAvailability, fetchAgreements, createOrder, payOrder, updateSiteDomain, updateDomain, manageDomain, updateMail, fetchMail } from '../../actions'

import { hasBillingInfo } from 'billing/selectors'
import { toast } from '@realsoftworks/decor/dist/components/Toast'
import { withRouter } from 'react-router-dom'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DomainsPanel extends Component {
  state = {
    loading: true,
    dialog: false,
    console: false,
    forwarding: false,
    renewal: false,
    mail: false,
    mailId: null
  };

  showDialog = () => this.setState({ dialog: true });
  hideDialog = done => {
    this.setState({ dialog: false })
    if (done)
      this.loadData()
  };

  showConsole = () => this.setState({ console: true });
  hideConsole = () => this.setState({ console: false });

  showMail = id => this.setState({ mail: true, mailId: id });
  hideMail = () => this.setState({ mail: false });

  showForwarding = id => this.setState({ forwarding: true, forwardingId: id });
  hideForwarding = () => this.setState({ forwarding: false, forwardingId: null });

  showRenewal = id => this.setState({ renewal: true, renewalId: id });
  hideRenewal = () => this.setState({ renewal: false, renewalId: null });

  componentDidMount () {
    this.loadData()
  }

  loadData () {
    this.setState({ loading: true })
    Promise.all([
      this.props.fetchDomains(),
      this.props.fetchOrders(),
      this.props.fetchDomainProducts()
    ])
      .then(([domains, orders, products]) => {
        if (domains.error && orders.error && products.error)
          this.setState({ error: true })

        this.setState({ loading: false })
      })
  }

  render () {
    const {
      loading,
      dialog,
      console,
      forwarding,
      forwardingId,
      renewal,
      renewalId,
      mail,
      mailId,
      error,
      orderId
    } = this.state

    const { domains, orders, products } = this.props

    const hasDomains = domains && domains.length > 0
    const hasOrders = orders && orders.length > 0
    const showEmpty = !hasDomains && !hasOrders

    return (
      <div className='domains-panel'>

        <DomainPurchaseDialog
          open={dialog}
          products={products}
          fetchAgreements={this.props.fetchAgreements}
          checkAvailability={this.props.checkAvailability}
          onRequestClose={this.hideDialog}
          hasBillingInfo={this.props.hasBillingInfo}
          createOrder={this.props.createOrder}
          payOrder={this.props.payOrder}
          orderId={orderId}
        />

        <DomainForwardingDialog
          open={forwarding}
          domain={domains && domains.find(v => v.id == forwardingId)}
          updateSiteDomain={this.props.updateSiteDomain}
          onRequestClose={this.hideForwarding}
        />

        <DomainRenewalDialog
          open={renewal}
          products={products}
          domain={domains && domains.find(v => v.id == renewalId)}
          onRequestClose={this.hideRenewal}
          hasBillingInfo={this.props.hasBillingInfo}
          createOrder={this.props.createOrder}
          payOrder={this.props.payOrder}
          orderId={orderId}
        />

        <DomainConsoleDialog
          open={console}
          onRequestClose={this.hideConsole}
        />

        <DomainMailDialog
          open={mail}
          domain={domains && domains.find(v => v.id == mailId)}
          onRequestClose={this.hideMail}
        />

        <Card p={5}>
          <div className='domain-card-title'>
            <CardTitle>Domains</CardTitle>
            {hasDomains && <Button className='domain-console-button' onClick={this.showConsole}>Domain Console</Button>}
          </div>
          {loading ? <BigLoader/>
            : error ? <BigFatalError/>
              : showEmpty ? <EmptyMessage/>
                : <div>
                  { (domains && domains.length > 0) && <DomainList onForwardingClick={this.showForwarding} onRenewalClick={this.showRenewal} onMailClick={this.showMail} onManageClick={this.props.manageDomain} onAutoRenewClick={this.props.updateDomain} domains={domains}/> }

                  {(orders && orders.length > 0) && <DomainOrderList orders={orders}/>}
                </div>
          }
          {(!loading && !error) && <Button onClick={this.showDialog} appearance='primary' className='btn-purchase'>Purchase Domains</Button> }

        </Card>
      </div>
    )
  }
}

DomainsPanel = connect(
  state => ({
    domains: getDomains(state),
    products: getDomainProducts(state),
    hasBillingInfo: hasBillingInfo(state)
  }),
  {
    fetchDomains,
    fetchOrders,
    fetchDomainProducts,
    checkAvailability,
    fetchAgreements,
    createOrder,
    payOrder,
    updateSiteDomain,
    updateDomain,
    manageDomain,
    updateMail,
    fetchMail
  }
)(DomainsPanel)

const MANAGE_FAILED_NOTIF = {
  title: 'Failed to manage domain in Propelio',
  content: 'We’re sorry. Please try checking your connection, refreshing the page or getting in touch with us by clicking the floating button on lower right.'
}

class DomainList extends Component {
  state = { manageTip: false, isRequestingManagement: false };

  showManageTip = () => this.setState({ manageTip: true });
  hideManageTip = () => this.setState({ manageTip: false });

  render () {
    const { isRequestingManagement } = this.state
    const { domains, onAutoRenewClick, onForwardingClick, onRenewalClick, onManageClick, history } = this.props

    return (
      <div className='domain-list'>
        {domains.map(domain => (
          <div className='domain-list-item' key={domain.id}>
            <div className='domain-list-id'>
              <div className='domain-list-status'>
                <DomainStatus status={domain.status} />
              </div>
              <span className='domain-list-name'>{domain.id}</span>

              {domain.status === 'FAILED_TRANSFER_IN_BAD_STATUS' &&
                  <Text color='secondary' display='block'>Transfer has failed. Domain transfer is no longer supported.</Text>}
            </div>

            {domain.renewals &&
              <div className='domain-list-renewals'>
                <div className='domain-list-expiry'>
                  <span className='domain-list-expiry-caption'>Expires</span>
                  {formatDate(domain.expires)} <a onClick={() => onRenewalClick(domain.id)}>Renew Now</a>
                </div>

                <div className='domain-list-auto'>
                  <div className='domain-list-auto-caption'>Auto Renew</div>
                  <Checkbox
                    className='domain-list-auto-checkbox'
                    checked={domain.renewals.autoRenew}
                    onChange={(e, checked) => onAutoRenewClick(domain.id, checked)}
                  />
                </div>
              </div>
            }

            {domain.status == 'ACTIVE' &&
              (domain.managed
                ? <Box
                  className='domain-list-options-managed'
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <Box flex={1} px={2} display='flex' alignItems='center' flexDirection='column'>
                    <div>{domain.site ? `Forward to: ${domain.site.name}` : <em className='secondary'>No forwarding</em>}</div>
                    <Button onClick={() => onForwardingClick(domain.id)}>
                      <FontAwesomeIcon icon={faCog} />
                      {' '}Modify
                    </Button>
                  </Box>

                  <Box flex={1} px={2}>
                    <Button
                      onClick={() =>
                        history.push(`/sites/domains/${domain.id}/manage`)}
                      appearance='secondary'
                    >
                      <FontAwesomeIcon icon={faCog} />
                      {' '}Advanced DNS
                    </Button>
                  </Box>
                </Box>
                : <div className='domain-list-options-unmanaged'>
                  <div className='domain-list-manage'>
                    <Tooltip content='The mail and forwarding settings for this domain are managed somewhere else. To edit these settings inside Propelio, click the Manage button'>
                      <DecorButton
                        disabled={isRequestingManagement}
                        onClick={() => {
                          this.setState({ isRequestingManagement: true })
                          onManageClick(domain.id)
                            .catch(() => toast.error(MANAGE_FAILED_NOTIF))
                            .finally(() =>
                              this.setState({ isRequestingManagement: false }))
                        }}
                        variant='secondary'
                      >
                        {isRequestingManagement
                          ? 'Requesting…'
                          : 'Manage in Propelio'}
                      </DecorButton>
                    </Tooltip>
                  </div>

                </div>)
            }
          </div>

        ))}
      </div>
    )
  }
}

DomainList = withRouter(DomainList)

const DomainStatus = ({ status }) => {
  const display = status.toLowerCase().split('_').join(' ')

  if (status == 'PENDING_TRANSFER_IN_UNDERAGE')
    return (
      <Tooltip content="This domain is less than 60 days old and can't be transfered. Transfer will complete when it's 60 days old.">
        <span>waiting for domain to age <FontIcon className='fa fa-question-circle'/></span>
      </Tooltip>
    )

  if (status == 'AWAITING_VERIFICATION_ICANN' || status == 'PARKED_VERIFICATION_ICANN')
    return (
      <Tooltip content='Please check your email for a verification link'>
        <span>awaiting email verification <FontIcon className='fa fa-question-circle'/></span>
      </Tooltip>
    )

  return <span>{display}</span>
}

class DomainOrderList extends Component {
  render () {
    return (
      <div className='domain-orders'>
        <h3>Pending Orders</h3>

      </div>
    )
  }
}

class EmptyMessage extends Component {
  render () {
    return (
      <div className='empty-message'>
        <p>Brand your site with a custom domain!</p>
      </div>
    )
  }
}

export default DomainsPanel
