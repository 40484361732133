import { OwnerInfoFilters } from 'leadlists/components/Sellers/components/SellerFilters/OwnerInfoSection'

export const getProperty = state => state.propertydata.property
export const getNeighbors = state => state.propertydata.neighbors
export const getCustomNeighbors = state => state.propertydata.customNeighbors
export const getCustomNeighborsCount = state => state.propertydata.customNeighborsCount
export const getCustomNeighborsPaging = state => state.propertydata.customNeighborsPaging
export const selectIsLandlordTicked = state =>
  state.propertydata.sharedFilters.isLandlord

/**
 * Note that `isLandlord: true` translates to this in terms for filters:
 * `{ ownerType: 'absentee', isVacant: false }`
 * In other words, landlords are owners that are absent in their properties,
 * yet their properties are occupied (not vacant)
 */
export const selectShouldRequireLandlord = (state, { ownerType }: OwnerInfoFilters) => {
  const isLandlordTicked = selectIsLandlordTicked(state)
  const ownerIsSetButNotAbsentee = ownerType?.individual?.ownerOccupied ||
    ownerType?.individual?.military ||
    ownerType?.isTrust ||
    ownerType?.isBank ||
    ownerType?.isCompany
  const shouldRequireLandlord = isLandlordTicked && !ownerIsSetButNotAbsentee
  return shouldRequireLandlord
}
