import React from 'react'
import { Link as RRLink } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/object/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { DateTime, RelativeDateTime, Numeric } from 'common/format'
import UserAvatar from 'users/components/UserAvatar'
import { DRIVES } from 'drivingfordollars/propTypes'
import Duration from './Duration'

import {
  Box,
  Card,
  Text,
  Button
} from '@realsoftworks/decor'

const DriveList = ({ drives }) => (
  <Container m={0} p={0} as='ul'>
    {drives.map((drive, i) => (
      <Card
        key={drive.id}
        as='li'
        display='flex'
        alignItems='center'
        borderTop={i !== 0 && '1px solid'}
        borderColor='neutral.200'
        pl={4}
        pr={5}
        py={3}
      >
        <UserAvatar userId={drive.createdBy} />

        <Box display='flex' flexDirection='column' pl={4} width={190}>
          <Text fontSize={2}>
            <DateTime format='MMMM Do, YYYY'>{drive.createdAt}</DateTime>
          </Text>
          <Text pt={1} fontSize={0} color='neutral.500'>
            <RelativeDateTime hasTooltip={false}>{drive.createdAt}</RelativeDateTime>
          </Text>
        </Box>

        <Box display='flex' flexDirection='column' alignItems='flex-end' width={100}>
          <Text fontSize={1}>
            {get(drive, 'distance.miles')
              ? (<Numeric decimals={0}>{drive.distance.miles}</Numeric>)
              : '--'}
          </Text>
          <Text pt={1} fontSize={1}>
            <Duration>
              {drive.duration}
            </Duration>
          </Text>
        </Box>

        <Box display='flex' flexDirection='column' flex='1' pl={1}>
          <Text fontSize={1} color='neutral.500'>
            miles
          </Text>
          <Text pt={1} fontSize={1} color='neutral.500'>
            duration
          </Text>
        </Box>

        {typeof drive.leadsCount === 'number' && (
          <Text fontSize={1}>
            {drive.leadsCount}
            {' '}
            <Text color='secondary'>
              {drive.leadsCount === 1 ? 'lead' : 'leads'}
            </Text>
          </Text>
        )}

        <Button ml={6} as={RRLink} to={`/drivingfordollars/drives/${drive.id}`}>
          View Details <FontAwesomeIcon icon={faChevronRight} />
        </Button>

      </Card>
    ))}
  </Container>
)

const Container = styled(Box)`
  list-style-type: none;
`

export default DriveList

DriveList.propTypes = { drives: DRIVES }
