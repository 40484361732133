/* eslint "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import Dialog from 'decor/Dialog'
import Progress from 'decor/Progress'
import LoadingButton from 'common/LoadingButton'

import FontIcon from 'decor/FontIcon'
import Form, { FormField, Error } from 'decor/form'
import RouterForwarder from 'common/util/RouterForwarder'

import SiteLinks from './SiteLinks'
import { create as createSite } from '../actions'

import './createsitedialog.less'
import ReactPlayer from 'react-player'

import { connect } from 'react-redux'
import { GENERIC_ERROR_NOTIF_CONTENT } from 'const'
import { toast } from '@realsoftworks/decor/dist/components/Toast'
import throwAny from 'common/util/throwAny'

const CREATE_SITE_FAILED = {
  title: 'Failed to create site',
  content: GENERIC_ERROR_NOTIF_CONTENT
}

class CreateSiteDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func,
    createSite: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {
      values: {},
      finished: props.finished || false,
      site: props.site || false
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.open == this.props.open) return

    if (nextProps.open && !this.props.open)
      this.setState({
        values: {},
        finished: nextProps.finished || false,
        site: nextProps.finished || false
      })
  }

  onCreate = () => {
    const { values } = this.state
    this.setState({ saving: true })
    this.props.createSite(values)
      .then(result => {
        this.setState({ saving: false })
        if (!result.error) {
          const site = result.payload
          if (this.props.redirect)
            SiteLinks.openSite(site)

          this.props.onFinish(site)
          this.setState({ site, finished: true })
        } else { throwAny(result.error) }
      })
      .catch(error => {
        this.setState({ error, saving: false })
        toast.error(CREATE_SITE_FAILED)

        if (typeof this.props.onRequestClose === 'function') this.props.onRequestClose()
      })
  };

  onFormChange = values => {
    this.setState({ values, error: false })
  };

  render () {
    const { onFinish, createSite, redirect, showVideo, ...rest } = this.props
    const { saving, finished, values, site, error } = this.state

    return (
      <Dialog
        {...rest}
        title={!finished ? 'Create New Site' : <div style={{ textAlign: 'center' }}>Your new site is ready!</div>}
        toolbar={!finished && [<LoadingButton key='create' loadingText='Creating...' loading={saving} onClick={this.onCreate} appearance='primary'>{redirect ? 'Preview Site' : 'Create'}</LoadingButton>]}
        className={classnames('create-site-dialog', { finished })}
      >
        {(!finished || redirect)

          ? <div>
            <div style={{ marginBottom: 20 }}>
              <Progress percent={50}>Step 1/1</Progress>
            </div>
            <Form onChange={this.onFormChange} value={values}>
              <FormField label='Site Name' name='name' hint='Usually your company name'/>
              {(error && error.param == 'name') && <Error>This field is required</Error>}
              <hr/>
              <FormField label='Company Name' name='settings.propelio_company_name'/>
              <FormField label='Phone Number' name='settings.propelio_phone'/>
              <FormField label='Primary Market' name='settings.propelio_market' hint='A general name for the area you operate in (ex: "Dallas/Fort Worth"). "We buy houses all over [primary_market]"'/>
              <FormField label='State' name='settings.propelio_state' hint='The state your market is in (ex: "Texas"). "We buy houses any where in the great state of [state]"'/>
            </Form>
          </div>
          : <RouterForwarder context={this.context}>

            <div className='post-create-options'>
              {showVideo &&
              <div>
                <p>Now it's time to really make it yours: you can add/edit your own content, upload your logo and change colors, add your social media links and more!</p>
                <p>This short video will show you how:</p>
                <ReactPlayer url='https://www.youtube.com/watch?v=wshTl2j18fA' width='100%' style={{ margin: '30px auto 30px auto' }} />
              </div>
              }

              <h3 style={{ textAlign: 'center', margin: '16px 0' }}>Next Steps:</h3>
              <div className='row'>
                <div className='col-md-3 col-xs-2'>
                  <SiteLinks.Settings site={site} className='primary'>
                    <div className='icon-wrap'>
                      <FontIcon className='fa fa-envelope-o fa-5x'/>
                    </div>
                  Domain and Email
                  </SiteLinks.Settings>
                </div>
                <div className='col-md-3 col-xs-2'>
                  <SiteLinks.Front site={site}>
                    <div className='icon-wrap'>
                      <FontIcon className='fa fa-external-link fa-5x'/>
                    </div>
                  Visit Site
                  </SiteLinks.Front>
                </div>
                <div className='col-md-3 col-xs-2'>
                  <SiteLinks.Content site={site}>
                    <div className='icon-wrap'>
                      <FontIcon className='fa fa-pencil fa-5x'/>
                    </div>
                  Edit Content
                  </SiteLinks.Content>
                </div>
                <div className='col-md-3 col-xs-2'>
                  <SiteLinks.Design site={site}>
                    <div className='icon-wrap'>
                      <FontIcon className='fa fa-paint-brush fa-5x'/>
                    </div>
                  Customize Design
                  </SiteLinks.Design>
                </div>
              </div>
            </div>
          </RouterForwarder>
        }
      </Dialog>
    )
  }
}

CreateSiteDialog.contextTypes = {
  router: PropTypes.object
}

CreateSiteDialog = connect(null, {
  createSite
})(CreateSiteDialog)

export default CreateSiteDialog
