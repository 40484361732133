import React, { ComponentProps, FC, memo } from 'react'
import { Text, Box } from '@realsoftworks/decor'
import UnderlineField from 'common/UnderlineField'

const EquityField: FC<
  Omit<ComponentProps<typeof UnderlineField>, 'isNumberOnly'>
  & { [key: string]: unknown }
  & {
    label: string
    value?: string
    inputProps?: object
  }
> = memo(({
  label,
  prefix,
  postfix,
  value,
  onChange,
  inputProps,
  ...props
}) => (
  <Text
    display='flex'
    flexDirection='row'
    fontSize={1}
    {...props}
  >
    <Box pr={2}>{label}</Box>
    <UnderlineField
      prefix={prefix}
      postfix={postfix}
      value={value}
      onChange={onChange}
      {...inputProps}
    />
  </Text>
))

export default EquityField
