import React from 'react'
import { Box, Heading } from '@realsoftworks/decor'
import { PageTitle } from 'common/layouts'
import Card from 'common/Card'
import PlanInfo from './SettingsPlanInfo'
import BillingInfo from './BillingInfo'
import MarketingCredits from './MarketingCredits'
import DocumentTitle from 'react-document-title'
import { TrialText, EndTrialButton } from 'lists/components/ListDetail/components/TrialNotice'

const BillingIndexHandler = () => (
  <DocumentTitle title='Billing | Propelio'>
    <Box display='flex' alignItems='center' flexDirection='column'>
      <Box width='100%' maxWidth={650}>
        <PageTitle>Billing</PageTitle>
      </Box>
      <Box className='billing-page' m={0}>
        <Card mb={5}>
          <Heading size={4} mb={2}>Secure Payment Info</Heading>
          <BillingInfo />
          <TrialText pt={3} />
          <Box pt={3} display='flex' justifyContent='flex-end'>
            <EndTrialButton />
          </Box>
        </Card>
        <Card mb={5}>
          <MarketingCredits/>
        </Card>
        <Card mb={5}>
          <PlanInfo/>
        </Card>
      </Box>
    </Box>
  </DocumentTitle>
)

export default BillingIndexHandler
