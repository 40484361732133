/* eslint "eqeqeq": "warn" */
import React from 'react'
import styled from 'styled-components'
import { Card, Menu } from '@realsoftworks/decor'
import useOnClickOutside from './useOnClickOutside'

import Input from './components/Input'
import CategoryList from './components/CategoryList'
import SearchResults from './components/SearchResults'
import ValuePill from './components/ValuePill'

const extractCategory = (text, categories) => {
  if (text.indexOf(':') === -1)
    return [null, text]

  const parts = text.split(':')
  const potential = parts[0]
  const rest = text.substring(text.indexOf(':') + 1)

  for (let i = 0; i < categories.length; i += 1)
    if (categories[i].id === potential) return [potential, rest]

  return [null, rest]
}

const initialResultState = {}
const resultReducer = (state = initialResultState, action) => {
  const { type, categoryId, results, searchText, ts } = action
  switch (type) {
    case 'reset': return {
      ...state,
      [categoryId]: null
    }
    case 'start':
      return {
        ...state,
        [categoryId]: {
          loading: true,
          results: null,
          ts
        }
      }
    case 'finish':
      // prevent race conditions
      if (state[categoryId] && state[categoryId].ts > ts)
        return state

      return {
        ...state,
        [categoryId]: {
          results,
          ts,
          searchText
        }
      }
    default: throw new Error()
  }
}

const Wrapper = styled(Card)`
  min-height: 36px;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
`

const CategoryPicker = ({
  value,
  showCategories,
  preFetch,
  categories,
  onChange,
  placeholder,
  ...props
}) => {
  const menuRef = React.useRef()
  const wrapperRef = React.useRef()
  const inputRef = React.useRef()
  const [typedValue, setTypedValue] = React.useState('')
  const [category, setCategory] = React.useState(null)
  const [searchText, setSearchText] = React.useState('')
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [searchResults, dispatch] = React.useReducer(resultReducer)
  const onInputFocus = () => open(true)

  const updateSearchResults = textValue => {
    const [category, searchText] = extractCategory(textValue, categories)
    setCategory(category)
    setSearchText(searchText)

    let searchCategories
    if (category)
      searchCategories = [category]
    else
      searchCategories = categories.map(v => v.id)

    categories.forEach(c => {
      // reset any unused categories
      if (searchCategories.indexOf(c.id) === -1) {
        dispatch({ type: 'reset', categoryId: c.id })
        return
      }

      // short circut - if this search is a continuation of the last 0-results one
      if (c.filter && searchResults && searchResults[c.id]) {
        const prevResults = searchResults[c.id]
        const { searchText: prevSearchText, results: prevRecords } = prevResults

        if (prevSearchText && prevRecords.length == 0 && searchText.startsWith(prevSearchText))
          return
      }

      dispatch({ type: 'reset', categoryId: c.id })

      const ts = new Date().getTime()

      if (c.filter) {
        dispatch({ type: 'start', categoryId: c.id, ts })

        c.filter(searchText).then(results => {
          dispatch({ type: 'finish', searchText, categoryId: c.id, results, ts })
        })
      } else if (c.matches) {
        const isMatch = c.matches(searchText)
        if (isMatch)
          dispatch({ type: 'finish', searchText, categoryId: c.id, results: [searchText], ts })
      }
    })
  }

  const onInputChange = e => {
    setTypedValue(e.target.value)
    updateSearchResults(e.target.value)
  }

  const onInputKeyUp = e => {
    if (e.key === 'Enter') {
      const allResults = categories.reduce((acc, category) => {
        const results =
          (searchResults &&
          searchResults[category.id] &&
          searchResults[category.id].results) ||
          []

        const values = results.map(v => ({
          category: category.id,
          id: category.getId ? category.getId(v) : v,
          details: v
        }))

        return acc.concat(values)
      }, [])

      if (allResults.length == 1) {
        addValue(allResults[0])
        setTypedValue('')
      }
    }
  }

  const onClickOutside = e => {
    if (menuRef.current && menuRef.current.contains(e.target))
      return

    open(false)
  }
  useOnClickOutside(wrapperRef, onClickOutside)

  const onCategoryClick = categoryValue => {
    const newValue = `${categoryValue}:`
    setTypedValue(newValue)
    updateSearchResults(newValue)
    inputRef.current.focus()
  }

  const addValue = itemValue => {
    if (value.find(v => v.id === itemValue.id)) return
    const newValue = [...(value || []), itemValue]
    fireChange(newValue)
  }

  const onItemClick = itemValue => {
    addValue(itemValue)
    setTypedValue('')
    inputRef.current.focus()
  }

  const removeValue = itemValue => {
    const newValue = (value || []).filter(v => !(v.category === itemValue.category && v.id === itemValue.id))
    fireChange(newValue)
  }

  const fireChange = value => {
    const emptyGroups = categories.reduce((acc, v) => {
      acc[v.id] = []
      return acc
    }, {})

    const categoryValueMap = value.reduce((acc, v) => {
      const { category } = v
      if (!acc[category]) acc[category] = []
      acc[category].push(v)
      return acc
    }, emptyGroups)

    onChange({/* e */}, value, categoryValueMap)
  }

  const open = value => {
    setMenuOpen(value)
  }

  const shouldShowMenu = menuOpen && (
    (!category && showCategories) ||
    (category && preFetch) ||
    (searchText)
  )

  return (
    <Wrapper ref={wrapperRef} border='medium' bg='white' display='flex' flexWrap='wrap' alignItems='top' p='5px 4px' {...props}>
      {value && value.map(
        v => <ValuePill key={`${v.category}|${v.id}`} value={v} categories={categories} onClickClose={() => removeValue(v)} />
      )}
      <Input
        ref={inputRef}
        value={typedValue}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onKeyUp={onInputKeyUp}
        placeholder={placeholder}
      />
      <Menu
        px={3}
        ref={menuRef}
        target={wrapperRef.current}
        open={shouldShowMenu}
        width={wrapperRef.current ? wrapperRef.current.offsetWidth : null}
      >
        {(!typedValue)
          ? <CategoryList
            categories={categories}
            onCategoryClick={onCategoryClick}
          />
          : <SearchResults
            categories={categories}
            results={searchResults}
            onItemClick={onItemClick}
          />}
      </Menu>
    </Wrapper>
  )
}

CategoryPicker.defaultProps = {
  placeholder: 'Start typing...',
  showCategories: true
}

export default CategoryPicker
