import React, { FC } from 'react'
import Switch from 'react-switch'
import { Box, Text } from '@realsoftworks/decor'
import theme from 'theme'

const ConditionTypeField: FC<{
  [key: string]: unknown
  onChange: (condType: 'AND' | 'OR') => void
  value?: 'AND' | 'OR'
}> = ({
  onChange,
  value,
  ...props
}) => (
  <Box
    display='flex'
    alignItems='center'
    {...props}
  >
    <Text pr={3}>Allow Any</Text>
    <Switch
      onChange={() => onChange((!value || value === 'OR') ? 'AND' : 'OR')}
      checked={value === 'AND'}
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={18}
      width={40}
      height={24}
      onColor={theme.colors.primary[500]}
    />
    <Text pl={3}>Require All</Text>
  </Box>
)

export default ConditionTypeField
