/* eslint "react/jsx-key": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@realsoftworks/decor'
import { connect, Provider } from 'react-redux'

import BillingInfo from './BillingInfo'
import { getBillingInfo } from '../selectors'

class BillingDialog extends Component {
  static propTypes = {
    onRequestClose: PropTypes.func
  };

  static contextTypes = {
    store: PropTypes.any
  };

  state = {
    upgrading: false,
    error: false
  };

  // deny closing if we're in the middle of the sale
  onRequestClose = () => {
    if (!this.state.upgrading)
      this.props.onRequestClose()
  };

  render () {
    const { billinginfo, listId, ...rest } = this.props
    const { error } = this.state

    return (
      <Dialog
        {...rest}
        onClose={this.onRequestClose}
        title='Secure Payment Info'
        maxWidth='640px'
        width='100%'
        contentProps={{ pb: 3 }}
      >
        <Provider store={this.context.store}>
          <div className='billing-dialog-info'>
            <BillingInfo isUpgradeCta listId={listId} />
          </div>
        </Provider>

        {error && <div className='upgrade-dialog-error'>There was a problem changing your plan. Please update your billing info.</div>}

      </Dialog>
    )
  }
}

BillingDialog = connect(
  state => ({
    billinginfo: getBillingInfo(state)
  })
)(BillingDialog)

export default BillingDialog
