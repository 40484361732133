/* eslint "eqeqeq": "warn" */
import React from 'react'
import { Box, toast } from '@realsoftworks/decor'
import uniq from 'lodash/array/uniq'

import { PropertyMarker } from 'common/Map'
import NeighborhoodOptionsAndResults from './NeighborhoodOptionsAndResults'
import getCoordinatesFromProperty from 'common/util/getCoordinatesFromProperty'
import MapboxReact from '../../common/Map/components/MapboxReact'
import NeighborhoodFilters from './NeighborhoodFilters'
import { FETCH_CUSTOM_NEIGHBORS_FAILURE } from 'propertydata/actions'
import extractErrorMessage from 'common/extractErrorMessage'

class Neighborhood extends React.Component {
  state = {
    mode: 'index', // index || custom_search
    neighborType: 'Neighbors',
    filters: {},
    map: null
  }

  setFilters = (newFilters = {}) => {
    this.setState(({ filters = {}, ...state }) =>
      ({ ...state, filters: { ...filters, ...newFilters } }))
  }

  goToCustom = () => {
    this.setState({ mode: 'index' })
    this.setState({ neighborType: 'Custom' })
  }

  search = params => {
    if (params === null)
      this.goToCustom()

    const coords = getCoordinatesFromProperty(this.props.property?.result)
    if (!coords) return null

    const radius = [coords.lat, coords.lng, 1]
    params.radius = radius

    this.props.fetchCustomNeighbors(params, 'Custom')
      .then(action => {
        if (action.type === FETCH_CUSTOM_NEIGHBORS_FAILURE)
          toast.error({ title: 'Failed to search with custom filters', content: extractErrorMessage(action.payload) })
      })

    this.setState({ filters: params })
    this.goToCustom()
  }

  renderHouseMarker = () => {
    const coords = getCoordinatesFromProperty(this.props.property?.result)
    if (!coords) return null

    return <PropertyMarker
      lat={coords.lat}
      lng={coords.lng}
      ownerType='home'
      loanToValue={null}
    />
  }

  renderCustomSearch = () => (
    <Box display='flex' width='100%'>
      <NeighborhoodFilters
        filters={this.state.filters}
        search={filters => this.search(filters)}
        cancel={() => this.goToCustom()}
        onChange={filters => { this.setState({ filters: { ...this.state.filters, ...filters } }) }}
      />
    </Box>
  )

  onGoogleApiLoaded = ({ map }) =>
    this.setState({ map })

  renderMap = () => {
    let neighbors = []
    const coords = getCoordinatesFromProperty(this.props.property?.result)

    if (!coords) return null

    if (this.props.customNeighbors.result != undefined && this.state.neighborType == 'Custom' && this.props.customNeighbors.fetchSource == 'Custom')
      neighbors = this.props.customNeighbors.result
    else if (this.props.customNeighbors.result != undefined && this.state.neighborType == 'CashSales' && this.props.customNeighbors.fetchSource == 'CashSales')
      neighbors = this.props.customNeighbors.result
        .map(record =>
          record.deals
            .filter(deal => deal.property) // deal.property is sometimes null
            .map(deal => ({ recordId: record.id, ...deal.property })))
        .reduce((acc, val) => acc.concat(val), [])
    else if (this.props.neighbors != undefined && this.state.neighborType == 'Neighbors')
      neighbors = this.props.neighbors

    /**
     * Filter out null values that seems to be occuring when processing
     * `cashsales.result[].deals[].property`
     */
    neighbors = neighbors.filter(n => n)

    neighbors = uniq(neighbors, 'id')

    return (
      <Box style={{ height: '100%', position: 'relative' }}>
        <MapboxReact multipleResults={neighbors} neighbor={true} selectedProperty={this.props.property.result}></MapboxReact>
      </Box>
    )
  }

  renderIndex = () => (
    <NeighborhoodOptionsAndResults
      setMode={mode => this.setState({ mode })}
      mode={this.state.neighborType}
      onChangeMode={neighborType => this.setState({ neighborType })}
      filters={this.state.filters}
      property={this.props.property}
      setFilters={this.setFilters}
      renderMap={this.props.property.result && this.renderMap}
    />
  )

  render = () => {
    if (this.state.mode === 'index')
      return this.renderIndex()
    else if (this.state.mode === 'custom_search')
      return this.renderCustomSearch()
  }
}

export default Neighborhood
