/* eslint "no-useless-escape": "warn", "react/no-deprecated": "warn", "eqeqeq": "warn", "react/no-unescaped-entities": "warn", "react/jsx-key": "warn", "no-class-assign": "warn" */
import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'
import PropTypes from 'prop-types'

import BigLoader from 'common/BigLoader'
import LoadingButton from 'common/LoadingButton'
import Button from 'decor/Button'
import FontIcon from 'decor/FontIcon'
import Dialog from 'decor/Dialog'
import Mask from 'decor/Mask'
import { Form, FormField, Label, Field, FormInput } from 'decor/form'
import union from 'lodash/array/union'
import without from 'lodash/array/without'
import set from 'lodash/object/set'

import BillingInfo from 'billing/components/BillingInfo'

import { getDomainProducts } from '../../selectors'
import { hasBillingInfo } from 'billing/selectors'
import { fetchDomainProducts, checkAvailability, fetchAgreements, createOrder, payOrder } from '../../actions'

import AddressInput from 'decor/form/AddressInput'
import Error from 'decor/form/Error'
import { toast } from '@realsoftworks/decor'
import extractErrorMessage from 'common/extractErrorMessage'

const INITIALSTATE = {
  domain: '',
  tld: null,
  suggestions: null,
  availableDomain: '',
  available: null,
  warning: null,
  chosen: false,
  contact: null,
  contactComplete: null,
  chosenContact: null,
  loadingAgreements: false,
  agreements: null,
  checkedAgreements: [],
  chosenAgreements: null,
  error: {},
  tried: false,
  purchasing: false,
  done: false,
  orderId: null
}

const formatDomain = domain => domain.replace(/http[s+]:\/\//, '').replace('www\.', '')

class DomainPurchaseDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...INITIALSTATE,
      loading: !props.products
    }
  }

  static defaultProps = {
    products: null
  };

  componentDidMount () {
    if (!this.props.products)
      this.props.fetchDomainProducts().then(() => this.setState({ loading: false }))
  }

  componentWillReceiveProps ({ open }) {
    if (open !== this.props.open)
      this.reset()
  }

  checkAvailable = () => {
    const validation = this.validateDomain()
    const domain = formatDomain(this.state.domain)
    this.setState({ available: null, availableDomain: domain, domain })

    if (validation !== true) {
      this.setState({ warning: validation })
      return
    } else { this.setState({ warning: false }) }

    this.setState({ checking: true })
    this.props.checkAvailability(domain)
      .then(result => {
        const tld = domain.split('.').reverse().shift()
        this.setState({ checking: false, available: result.available, tld, suggestions: result.suggestions })
      })
  };

  validateDomain = () => {
    const { domain } = this.state
    const { products } = this.props

    const domainParts = formatDomain(domain).split('.')

    if (domainParts.length != 2)
      if (domainParts[0] == 'www')
        domainParts.shift()
      else
        return "We're not able to understand this domain. Please check and try again"

    const tld = domainParts[1]
    if (!Object.keys(products).includes(tld))
      return "We don't support this type of domain at this time."

    return true
  };

  reset () {
    this.setState(INITIALSTATE)
  }

  onDomainChange = domain => {
    if (domain.target)
      domain = domain.target.value

    this.setState({ domain })
  };

  onContactChange = contact => this.setState({ contact });
  onContactCompete = contactComplete => this.setState({ contactComplete });

  onCheckAgreement = (e, checked) => {
    let { checkedAgreements } = this.state
    const value = e.target.value

    if (checked)
      checkedAgreements = union(checkedAgreements, [value])
    else
      checkedAgreements = without(checkedAgreements, value)

    this.setState({ checkedAgreements })
  };

  chooseDomain = chosen => {
    this.setState({ chosen })
    this.loadAgreements(chosen)
  };

  loadAgreements = domain => {
    this.setState({ loadingAgreements: true })
    this.props.fetchAgreements(domain)
      .then(({ items }) => this.setState({ agreements: items, loadingAgreements: false }))
  };

  chooseContact = cb => {
    const { contact, ...error } = this.state.error
    if (typeof cb === 'function')
      this.setState({ chosenContact: this.state.contact, error }, cb)
    else
      this.setState({ chosenContact: this.state.contact, error })
  };

  chooseAgreements = () => this.setState({ chosenAgreements: this.state.agreements.map(v => v.agreementKey) });

  onRequestClose = () => {
    if (this.state.purchasing)
      return

    this.props.onRequestClose(this.state.done)
  };

  purchase = () => {
    const doPurchase = () => {
      this.setState({ tried: true, purchasing: true })
      const { chosen, chosenAgreements, chosenContact } = this.state

      let orderProm = null
      if (this.state.order)
        orderProm = Promise.resolve(this.state.order)
      else
        orderProm = this.props.createOrder(chosen, chosenAgreements, chosenContact)

      orderProm
        .then(order => {
          this.setState({ order })
          return this.props.payOrder(order.id)
            .then(paidOrder => {
              this.setState({ done: true, purchasing: false })
              if (this.props.onFinish)
                this.props.onFinish(this.state.chosen, order)
            })
            .catch(paymentError => this.setState({ error: { card: paymentError } }))
            .then(() => this.setState({ purchasing: false }))
        })
        .catch(e => {
          toast.error({
            title: 'Error encountered while processing your purchase',
            content: extractErrorMessage(e)
          })

          if (e.type == 'invalid_request_error') {
            const { param } = e
            const error = set({}, param, true)
            this.setState({ error })
          }
        })
        .then(() => this.setState({ purchasing: false }))
    }

    if (this.state.error.contact)
      this.chooseContact(doPurchase)
    else
      doPurchase()
  };

  render () {
    const { products, hasBillingInfo, ...rest } = this.props
    const { available, suggestions, domain, warning, chosen, availableDomain, contact, chosenContact, contactComplete, agreements, chosenAgreements, loadingAgreements, error, done, purchasing, loading } = this.state

    const mask1 = this.state.checking

    const toolbar = []
    let title = 'Check Availability'

    if (available && !this.state.chosen) { toolbar.push(<Button onClick={e => this.chooseDomain(availableDomain)} appearance='primary'>I want it!</Button>) } else if (chosen && !chosenContact) {
      title = 'Registration Info'
      toolbar.push(<Button onClick={this.chooseContact} disabled={!contactComplete} appearance='primary'>Next</Button>)
    } else if (chosenContact && !chosenAgreements) {
      title = 'Standard Domain Agreements'
      toolbar.push(<Button onClick={this.chooseAgreements} appearance='primary'>I agree!</Button>)
    } else if (chosenAgreements && !done) {
      title = 'Completing Purchase'
      toolbar.push(<LoadingButton onClick={this.purchase} disabled={!hasBillingInfo} loadingText='Purchasing...' loading={this.state.purchasing} appearance='primary'>Purchase</LoadingButton>)
    } else if (done) {
      toolbar.push(<Button onClick={this.props.onRequestClose} appearance='primary'>Close</Button>)
      title = null
    }

    return (
      <Dialog
        {...rest}
        title={title}
        className='domain-dialog'
        onRequestClose={this.onRequestClose}
        toolbar={toolbar}
      >

        {loading ? <BigLoader/>
          : <div>
            {!chosen &&
              <div className='selection-section'>
                <div className='search-section'>
                  {mask1 && <Mask/>}
                  <FormInput placeholder='Find your perfect domain' className='search-input' onChange={this.onDomainChange} value={domain}/>
                  <div className='search-button-wrap'>
                    <Button onClick={this.checkAvailable} appearance='primary'>Search</Button>
                  </div>
                </div>
                <p>Type in your full domain (ex: "properties.com" - no "www" and no "http") and we'll see if it's available.</p>
                <p>We support the following types of domains: <strong>{Object.keys(products || {}).join(', ')}</strong> </p>

                {warning && <p className='search-warning'>{warning}</p>}

                {(available !== null) &&
                <div className='availability-message'>
                  {available
                    ? <div className='available'>
                      <p className='domain'><strong>{availableDomain}</strong> is available!</p>
                    </div>
                    : <div className='not-available'>
                      <p>Unfortunately this domain is not available. </p>
                      {suggestions && suggestions.length > 0 &&
                        <div className='suggestions'>
                          <p>However, the following domains can be yours:</p>
                          {suggestions.map((v, i) => <div className='suggestion'><span><strong>{v.domain}</strong> is available!</span> <Button appearance='secondary' onClick={e => this.chooseDomain(v.domain)}>I want it!</Button></div>)}
                        </div>
                      }
                    </div>
                  }
                </div>
                }
              </div>
            }

            {
              ((chosen && !chosenContact) || (error.contact)) &&
                <RegistrantInfo value={contact} error={error.contact} onComplete={this.onContactCompete} onChange={this.onContactChange}/>
            }

            {(chosenContact && !chosenAgreements && !error.contact) &&
              <div className='agreement-section'>
                {loadingAgreements && <BigLoader/>}
                {agreements && agreements.map(agreement => <AgreementPanel key={agreement.agreementKey} agreement={agreement} onChange={this.onCheckAgreement}/>)}
              </div>
            }

            {
              (chosenAgreements && !error.contact && !purchasing && !done) && // tried prevents flashing billing info after correcting registrant info
              <Provider store={this.context.store}>
                <div className='billing-section'>
                  <div className='item'>
                    <p>{chosen}</p>
                    1 year - ${products[this.state.tld]}
                  </div>
                  <div className='billing-info-wrap'>
                    <h3>Secure Billing Info</h3>
                    <BillingInfo/>
                    {(error && error.card) && <Error>{error.card.message}</Error>}
                  </div>
                </div>
              </Provider>
            }

            {done &&
              <div className='completed-message'>
                <FontIcon className='fa-3x fa fa-thumbs-up'/>
                <h3>
                  Awesome!
                </h3>
                <p>We're getting your domain ready (it could take a bit). You should receive an email which we'll use to confirm your registration details before your domain can be activated. </p>
              </div>
            }
          </div>
        }
      </Dialog>
    )
  }

  static contextTypes = {
    store: PropTypes.any
  };
}

class RegistrantInfo extends Component {
  onChange = value => {
    this.props.onChange(value)

    if (!value.name || !value.name.first || !value.name.last || !value.email || !value.address || !value.phone || !value.phone.number) {
      this.props.onComplete(false)
      return
    }

    this.props.onComplete(true)
  };

  render () {
    const error = this.props.error || {}

    return (
      <Form onChange={this.onChange} value={this.props.value}>
        <p>All fields are required</p>
        <Field>
          <Label>Name</Label>
          <div className='row'>
            <div className='col-md-6'>
              <FormInput name='name.first' placeholder='First' required/>
            </div>
            <div className='col-md-6'>
              <FormInput name='name.last' placeholder='Last' required/>
            </div>
          </div>
        </Field>
        <div className='row'>
          <div className='col-md-6'>
            <FormField name='email' label='Email' required error={error.email && 'This field is invalid'}/>
          </div>
          <div className='col-md-6'>
            <FormField name='phone.number' label='Phone' required error={error.phone && 'This field is invalid'}/>
          </div>
        </div>

        <FormField name='address' label='Address' type={<AddressInput statePicker={true}/>} required error={error.address && 'This field is invalid'}/>
      </Form>
    )
  }
}

const AgreementPanel = ({ agreement, onChange }) => (
  <div className='agreement-panel'>
    <div className='agreement' dangerouslySetInnerHTML={{ __html: agreement.content }}></div>
  </div>
)

DomainPurchaseDialog = connect(
  state => ({
    products: getDomainProducts(state),
    hasBillingInfo: hasBillingInfo(state)
  }),
  {
    fetchDomainProducts,
    checkAvailability,
    fetchAgreements,
    createOrder,
    payOrder
  }
)(DomainPurchaseDialog)

export default DomainPurchaseDialog
