/* eslint "no-class-assign": "warn", "react/no-deprecated": "warn", "eqeqeq": "warn" */
import React from 'react'
import withSideEffect from 'react-side-effect'

var DEFAULT_CRUMB_ID = 'default'

class Crumb extends React.Component {
  render () {
    return null
  }
}

var crumbHandlers = {

}
var crumbList = {}
let crumbHandlerId = 0

Crumb = withSideEffect(
  propList => propList.reduce((v, props) => {
    var crumbId = props.crumbId || DEFAULT_CRUMB_ID
    v[crumbId] = v[crumbId] || []

    if (props.title) { v[crumbId].push(Object.assign({}, props)) } else {
      var list = v[crumbId]
      if (list && list.length > 0) {
        var last = list[list.length - 1]
        Object.assign(last, props)
      }
    }
    return v
  }, {}),
  byId => {
    crumbList = byId
    Object.keys(byId).forEach(key => {
      var props = byId[key]
      if (crumbHandlers[key])
        Object.keys(crumbHandlers[key]).forEach(id => crumbHandlers[key][id](props))
    })
  }
)(Crumb)

Crumb.subscribe = (handler, crumbId = DEFAULT_CRUMB_ID) => {
  if (!crumbHandlers[crumbId])
    crumbHandlers[crumbId] = {}

  crumbHandlers[crumbId][++crumbHandlerId] = handler
}

Crumb.unsubscribe = (token, crumbId = DEFAULT_CRUMB_ID) => {
  const handlerMap = crumbHandlers[crumbId]
  if (handlerMap)
    delete handlerMap[token]
}

class Breadcrumbs extends React.Component {
  static defaultProps = {
    crumbId: DEFAULT_CRUMB_ID
  };

  state = {
    crumbs: crumbList[this.props.crumbId] || []
  };

  updateCrumbs = crumbs => {
    this.setState({ crumbs })
  };

  componentWillMount () {
    var { crumbId } = this.props
    this.token = Crumb.subscribe(this.updateCrumbs, crumbId)
  }

  componentWillUnmount () {
    var { crumbId } = this.props
    Crumb.unsubscribe(this.token, crumbId)
  }

  render () {
    var { crumbs } = this.state
    var count = crumbs.length

    if (count == 0)
      return null

    let tools = null
    for (let i = count - 1; i >= 0; i--) {
      tools = crumbs[i].tools
      if (tools !== false) break
    }

    return (
      <div className='breadcrumbs'>

        <ul className='breadcrumbs'>
          {
            crumbs.map((item, i) => {
              var El = (i == count - 1) ? CrumbTitle : CrumbLink
              return <li key={item.title}><El {...item}/></li>
            })
          }
        </ul>

        <div className='tools'>{tools}</div>
      </div>

    )
  }
}

var CrumbTitle = props => {
  var { title, link, tools, ...rest } = props
  return <span {...rest}>{title}</span>
}

var CrumbLink = props => {
  var { title, link, tools, ...rest } = props
  return React.cloneElement(link || <span/>, rest, [title])
}

export default Breadcrumbs
export { Crumb }
