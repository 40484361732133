import React from 'react'
import { Pill } from '@realsoftworks/decor'

const ValuePill = ({
  value,
  categories,
  onClickClose,
  ...props
}) => {
  const { category } = value
  const [label, setLabel] = React.useState(null)

  const categoryDef = categories.find(v => v.id === category)
  const { getLabel = v => v.id, get = async v => v, icon } = categoryDef

  React.useEffect(() => {
    setLabel(null)
    get(value).then(v => {
      if (v) {
        const l = getLabel(v)
        setLabel(l)
      }
    })
  }, [value])

  let renderedIcon
  if (label)
    renderedIcon = React.cloneElement(icon, { color: 'teal.500' })

  return (
    <Pill size='small' mr='4px' onDelete={onClickClose}>
      {renderedIcon}
      {renderedIcon ? ' ' : ''}
      {label}
    </Pill>
  )
}

export default ValuePill
