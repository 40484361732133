import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { func, bool, object } from 'prop-types'
import { Box, Heading, Button } from '@realsoftworks/decor'
import FetchHandler from 'common/FetchHandler'
import { fetchSettings, updateSettings } from 'users/actions'
import { getSettingInfo, checkModelPermission } from 'users/selectors'
import IntroSection from './IntroSection'
import StepsCard from './StepsCard'
import StepsConnector from './StepConnector'
import { STEPS } from 'onboarding/const'

import { hasFeature } from 'billing/selectors'

const Onboarding = ({
  fetchSettings,
  steps,
  isLoading,
  error,
  completeOnboarding: passedCompleteOnboarding
}) => {
  const { hasLoaded } = fetchSettingsHook({ fetchSettings })
  const { isCompleting, completionError, completeOnboarding } = useStatefulCompleteCallback({
    completeOnboarding: passedCompleteOnboarding
  })

  return (
    <FetchHandler
      error={error || completionError}
      isLoading={!hasLoaded || isLoading}
      isEmpty={false}
      emptyText=''
    >
      <Box mx='auto' maxWidth={720}>
        <Heading size={3} textAlign='center'>
          Getting Started
        </Heading>
        <IntroSection />

        {steps.map((step, i) => (
          <Fragment key={i}>
            {i !== 0 && (<StepsConnector />)}
            <StepsCard
              {...step}
              css={`
                position: relative;
                z-index: 1;
              `}
            />
          </Fragment>
        ))}

        <Box display='flex' justifyContent='center' mt={7}>
          <Button
            onClick={completeOnboarding}
            variant='secondary'
            size='large'
            disabled={isCompleting}
          >
            {isCompleting ? 'Completing...' : 'I’m Done'}
          </Button>
        </Box>
      </Box>
    </FetchHandler>
  )
}

Onboarding.propTypes = {
  fetchSettings: func.isRequired,
  isLoading: bool.isRequired,
  error: object,
  completeOnboarding: func.isRequired
}

const checkItem = (state, item) => {
  const hasPlanFeature = item.feature ? hasFeature(state, item.feature) : true
  const hasPermission = item.permission ? checkModelPermission(state, item.permission.model, item.permission.action) : true
  return hasPlanFeature && hasPermission
}

const processSteps = state => {
  const ret = []
  for (let s = 0; s < STEPS.length; s++) {
    const step = STEPS[s]

    if (checkItem(state, step)) {
      const tasks = []
      for (let t = 0; t < step.tasks.length; t++) {
        const task = step.tasks[t]
        if (checkItem(state, task))
          tasks.push(task)
      }
      if (tasks.length)
        ret.push({
          ...step,
          tasks
        })
    }
  }

  return ret
}

const withState = connect(
  state => {
    const { value, ...info } = getSettingInfo(state)
    const steps = processSteps(state, STEPS)
    return { steps, ...info }
  },
  { fetchSettings, updateSettings },
  (stateProps, { updateSettings, ...dispatchProps }, ownProps) => {
    const completeOnboarding = () =>
      updateSettings({ 'onboarding.complete': true }).then(r => {
        ownProps.history.replace('/homeredirect')
      })

    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      completeOnboarding
    }
  }
)

export default withState(Onboarding)

const fetchSettingsHook = () => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        await fetchSettings()
      } catch (e) {
      } finally {
        setHasLoaded(true)
      }
    })()
  }, [])

  return { hasLoaded }
}

const useStatefulCompleteCallback = ({ completeOnboarding }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const wiredCompleteOnboarding = useCallback(() => {
    setIsLoading(true)
    completeOnboarding()
      .catch(e => {
        setError(e)
        setIsLoading(false)
      })
  }, [])

  return {
    isCompleting: isLoading,
    completionError: error,
    completeOnboarding: wiredCompleteOnboarding
  }
}
