/* eslint "import/no-named-default": "warn" */
import { combineReducers } from 'redux'

import entities from './reducers/entities'

import { reducer as users } from 'users'
import { reducer as enterprises } from 'enterprises'

import { reducer as dealalerts } from 'dealalerts'
import { reducer as mls } from 'mls'
import { reducer as cma } from 'cma'

import { reducer as billing } from 'billing'
import { reducer as tasks } from 'tasks'
import { reducer as leads } from 'leads'
import { reducer as contacts } from 'contacts'
import { reducer as premiumleads } from 'premiumleads'
import { reducer as leadlists } from 'leadlists'
import { reducer as sites } from 'sites'
import { reducer as lists } from 'lists'
import { reducer as dfd } from 'drivingfordollars'
import { reducer as marketing } from 'marketing'
import { reducer as notifications } from 'notifications'
import { reducer as propertydata } from 'propertydata'
import { default as propertypreviews } from 'propertypreviews/reducer'

export default combineReducers({
  dealalerts,
  entities,
  enterprises,
  users,
  cma,
  mls,
  billing,
  tasks,
  leads,
  contacts,
  leadlists,
  premiumleads,
  sites,
  lists,
  dfd,
  marketing,
  notifications,
  propertydata,
  propertypreviews
})
