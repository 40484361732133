import React from 'react'
import { Card, Box, Text } from '@realsoftworks/decor'
import { Numeric, Monetary } from 'common/format'
import { orDash } from 'common/util/renderHelpers'
import DataView from 'search/components/DataView'
import formatSituations from 'common/util/formatSituations'
import countSituations from 'common/util/countSituations'
import PropertyPill from 'search/components/PropertyPill'
import SituationsPill from 'leadlists/components/Sellers/components/SituationsPill'
import totalBaths from 'common/util/totalBaths'
import Equity from './Equity'
import StreetViewModalImageButton from './StreetViewModalImageButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import ensureArray from 'common/util/ensureArray'
import { isComplete } from 'common/util/address'
import doesContactAndSkiptraceMatch from './doesContactAndSkiptraceMatch'
import MapboxReact from '../../../../../common/Map/components/MapboxReact'

const UpperDetails = ({ property, onOpenSkiptraceTab }) => {
  const addr = property?.address
  const contactAddr = property?.contactAddress
  const summary = property?.summary || {}
  const buildingDetail = property?.buildingDetail || {}
  const landDetail = property?.landDetail || {}
  const rawContacts = summary?.owners
  const crit = summary.distressedCriteria || {}
  const situations = formatSituations(summary)
  const occupancy = crit.ownerSituation
  const loanToValue = summary?.loanToValue
  const { latitude, longitude } = addr?.coordinates || {}
  const areCoordinatesSet = typeof latitude === 'number' && typeof longitude === 'number'
  const contacts = ensureContacts(rawContacts)
  const hasAnySkiptrace = property?.skiptraces?.length > 0
  const isSkiptracePossible = isComplete(contactAddr)

  const isNameSkiptraced = c => {
    const hasAnyNamelessSkiptrace = ensureArray(property?.skiptraces)
      .some(s => !s?.request?.firstName && !s?.request?.lastName)

    // Is skiptrace has been run without name, consider all names skipped
    if (hasAnyNamelessSkiptrace) return true

    const hasMatchingNameSkiptrace = !!ensureArray(property?.skiptraces)
      .find(s => doesContactAndSkiptraceMatch(c, s.request))

    return hasMatchingNameSkiptrace
  }

  return (
    <Card
      bg='white'
      p={1}
      display='flex'
      flexWrap='wrap'
      boxShadow={1}
      borderRadius={3}
    >
      <Box
        flex={`1 0 ${5 / 12}%`}
        maxWidth='480px'
        minWidth='180px'
        alignItems='stretch'
        display='flex'
        css={{ overflow: 'auto' }}
      >
        {areCoordinatesSet ? (
          <Box flex={1} p={2} pt={1} alignItems='stretch' display='flex'>
            <Box flex={1} css={{ position: 'relative' }}>
              <MapboxReact singleResult={[property]} isPropertyDetails={true} />

              {addr && (
                <Box css={{ position: 'absolute', left: 10, bottom: 24 }}>
                  <StreetViewModalImageButton />
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Text
            flex={1}
            bg='neutral.100'
            m={2}
            p={5}
            minHeight='180px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            color='neutral.400'
            fontSize={0}
          >
            We don’t have the exact location for this property
          </Text>
        )}
      </Box>

      {/* Text Column */}
      <Box p={2} flex={`1 1 ${7 / 12}%`} minWidth='280px'>
        {/* Owner Section */}
        <Box as='section'>
          <Equity loanToValue={loanToValue} />
          <Box mx='-2px' display='flex' flexWrap='wrap' alignItems='flex-end'>
            <Text as='h2' p='2px' color='primary.500' fontSize={1}>
              Owner
            </Text>

            {crit.isIndividual !== null && (
              <Box p='2px'>
                <PropertyPill
                  type={crit.isIndividual ? 'individual' : 'entity'}
                />
              </Box>
            )}

            {occupancy && (
              <Box p='2px'><PropertyPill type={occupancy} /></Box>
            )}
          </Box>
          <Text pt={1} as='section' fontSize={1}>
            {contacts?.length > 0 ? contacts.map((c, i) => (
              <Box
                as='a'
                key={i}
                mt={i === 0 ? 0 : 1}
                display='flex'
                flexDirection='row'
                alignItems='center'
                onClick={ev => {
                  ev.preventDefault()
                  onOpenSkiptraceTab()
                }}
                css={{
                  cursor: 'pointer',
                  color: isSkiptracePossible
                    ? undefined // use default color for links
                    : 'secondary'
                }}
              >
                <Text
                  as={FontAwesomeIcon}
                  icon={faPhoneSquareAlt}
                  color={isNameSkiptraced(c) ? 'teal.500' : 'red.500'}
                  fontSize={4}
                />

                <Text
                  ml={2}
                  display='inline-block'
                >
                  {c.name}
                </Text>
              </Box>
            )) : (
              <Box
                as='a'
                display='flex'
                flexDirection='row'
                alignItems='center'
                onClick={ev => {
                  ev.preventDefault()
                  onOpenSkiptraceTab()
                }}
                css={{
                  cursor: 'pointer',
                  color: isSkiptracePossible
                    ? undefined // use default color for links
                    : 'secondary'
                }}
              >
                <Text
                  as={FontAwesomeIcon}
                  icon={faPhoneSquareAlt}
                  color={hasAnySkiptrace ? 'teal.500' : 'red.500'}
                  fontSize={4}
                />

                <Text
                  ml={2}
                  display='inline-block'
                >
                  [Name Unknown]
                </Text>
              </Box>
            )}
          </Text>
          {isComplete(contactAddr) ? (
            <Text pt={2} as='section' fontSize={0} lineHeight='1.45'>
              {contactAddr.line1}<br />
              {contactAddr.city}, {contactAddr.state} {contactAddr.zip}
            </Text>
          ) : (
            <Text pt={2} as='section' fontSize={1} lineHeight='1.45' color='secondary'>
              [Address Unknown]
            </Text>
          )}
        </Box>

        {/* Property Info Section */}
        <Box pt={4} as='section'>
          <Text color='primary.500' fontSize={1} display='block'>Property Info</Text>
          <Small>
            {orDash(summary.beds)} <Gray>beds</Gray>
            {' '}/{' '}
            {orDash(totalBaths(property))} <Gray>baths</Gray>
            {' '}/{' '}
            {orDash(buildingDetail.garageSpaces)} <Gray>garage</Gray>
          </Small>

          <br />

          <Small>
            {orDash(summary.sqft, v => <Numeric>{v}</Numeric>)} <Gray>sqft</Gray>
          </Small>
          &ensp;
          <Small>
            {orDash(summary.yearBuilt)} <Gray>build</Gray>
          </Small>

          <br />

          <Small>
            {orDash(landDetail.lotAreaAcres, v => <Numeric decimals={3}>{parseFloat(v).toFixed(3)}</Numeric>)}
            {' '}<Gray>lot acres</Gray>
          </Small>
          &ensp;
          <Small>
            {orDash(summary.lotSqft, v => <Numeric>{parseFloat(v).toFixed(0)}</Numeric>)}
            {' '}<Gray>lot sqft</Gray>
          </Small>
        </Box>

        {/* Misc Data Section */}
        <Box pt={4}>
          <DataView data={{
            'Distressed / Situations': !countSituations(situations) ? (
              'None'
            ) : (
              <SituationsPill
                property={situations}
                pillProps={{
                  size: 'small',
                  style: {
                    minHeight: '20px',
                    borderRadius: '9999px',
                    fontSize: '13px',
                    border: '1px solid #2B959C'
                  }
                }}
              />
            ),
            APN: orDash(summary.APN),
            'Property Type': orDash(summary.propertyType),
            'Owner Type': orDash(summary.ownerType),
            'Owner Status': orDash(summary.ownerStatus),
            Occupancy: orDash(summary.occupancy),
            'Length of Ownership': orDash(summary.lengthOfOwnership),
            'Last Sale Price': orDash(summary.lastSalePrice, v => (
              <Monetary>{v}</Monetary>
            )),
            'Purchase Type': orDash(summary.purchaseMethod),
            County: orDash(addr.county)
          }}
          />
        </Box>
      </Box>
    </Card>
  )
}

export default UpperDetails

const Gray = p => <Text color='secondary' fontSize={0} {...p} />
const Small = p => <Text fontSize={0} {...p} />

const ensureContacts = cs =>
  ensureArray(cs)
    .map(c => ({
      ...c,
      name: String(c.name).trim() ||
        [c.firstName, c.lastName]
          .filter(v => typeof v === 'string')
          .join(' ')
          .trim()
    }))
    .filter(c => c.name && typeof c.name === 'string')
